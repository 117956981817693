//@flow
import moment from "moment"
import momentTz, { tz } from "moment-timezone"
import {
  __,
  any,
  compose,
  curry,
  divide,
  equals,
  filter,
  includes,
  isNil,
  join,
  length,
  multiply,
  pluck,
  range,
  reject,
  startsWith,
  toString,
  unless,
  when,
} from "ramda"

import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils/localization/autocomplete"

export const getBrowserTimeZone = () => momentTz.tz.guess()

export const toMilliseconds = multiply(1000)
const toSeconds = divide(__, 1000)
const seconds = equals(10)
const getDecimalLength = compose(length, toString, Math.trunc)
const inSeconds = compose(seconds, getDecimalLength)
const format = when(inSeconds, toMilliseconds)
const formatMoment = compose(momentTz, format)
const localTime = (t: number): momentTz => formatMoment(t).tz(getBrowserTimeZone())
const localeData = () => moment().localeData()

const DATE_THRESHOLDS = {
  s: 60,
  m: 60,
  h: 24,
  d: 30,
  M: 12,
}

export const dateToFormat = curry((outputFormat: string, date: number): string =>
  compose(
    m => (m.isValid() ? m.format(outputFormat) : ""),
    unless(momentTz.isMoment, compose(localTime, Math.round)),
  )(date),
)

type dateTimeFunc = (date: number) => string

export const time = dateToFormat("LT")
export const date = dateToFormat("L")
export const dateTime: dateTimeFunc = dateToFormat("L LT")
export const dateTimeTz = dateToFormat("L LT z")
export const dateTimeLong = dateToFormat("lll")
export const dayDateTime = dateToFormat("llll")
export const dateUtc = (date: number, outputFormat: string) =>
  momentTz
    .unix(date)
    .utc()
    .format(outputFormat)
export const dateTz = (date: number, outputFormat = "", tz: string) =>
  date && tz
    ? momentTz(date)
        .tz(tz)
        .format(outputFormat)
    : momentTz(date).format(outputFormat)
export const getTzAbbreviation = () =>
  new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2] || "UTC"

export const localeIsMeridian = () =>
  localeData()
    ._longDateFormat.LT.toLowerCase()
    .includes("a")
export const calendarTime = (t: number, showDateTimeForDefaultCases: boolean): momentTz => {
  const formatParams = showDateTimeForDefaultCases
    ? {
        sameElse: "L LT",
      }
    : {}
  return formatMoment(t).calendar(formatParams)
}
export const livestamp = (t: number, omitSuffix): string => momentTz(format(t)).fromNow(omitSuffix)

export const initializeMomentThresholds = (): string => {
  momentTz.relativeTimeRounding(Math.floor)
  for (const [unit, threshold] of Object.entries(DATE_THRESHOLDS)) {
    momentTz.relativeTimeThreshold(unit, threshold)
  }
}

export const dateSince = (t: number, unit: string, delimiter: string): string => {
  const duration = moment.duration(t, unit)
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  const time = [
    duration_string(days, localized("Day"), localized("Days")),
    duration_string(hours, localized("Hour"), localized("Hours")),
    duration_string(minutes, localized("Minute"), localized("Minutes")),
    ...(!minutes ? [duration_string(seconds, localized("Second"), localized("seconds"))] : []),
  ]

  return moment.isDuration(duration) ? compose(join(delimiter || " "), reject(isNil))(time) : ""
}

const duration_string = (unit, singular, plural) => {
  if (!unit) return null

  return unit > 1 ? `${unit} ${plural}` : `${unit} ${singular}`
}

export function getTimeFromSplit(timeHours, timeMinutes, format) {
  const minutes = timeMinutes.toString().length === 1 ? `0${timeMinutes}` : timeMinutes
  return moment(`${timeHours}${minutes}`, "hmm").format(format)
}

export const timeZone = () =>
  moment()
    .tz(window.application.get("timeZone").get("value"))
    .format("z")

export const getDivisionTimeZone = () => window.application.get("timeZone").get("value")

export const getDivisionDateTimeZone = timestamp => moment.unix(timestamp).tz(getDivisionTimeZone())

export const getDivisionTimeZoneAbbreviation = () => {
  if (getDivisionTimeZone() === "_Local") {
    return localized("Local Device Time")
  }
  const abbreviation = timeZone()
  return `${any(startsWith(__, abbreviation))(["+", "-"]) ? "UTC " : ""}${abbreviation}`
}

export const dateToFormatWithDivisionTz = curry((outputFormat, date) =>
  compose(
    m => (m.isValid() ? m.tz(getDivisionTimeZone()).format(outputFormat) : ""),
    unless(momentTz.isMoment, compose(formatMoment, Math.round)),
  )(date),
)

export const dateTimeWithDivisionTz = dateToFormatWithDivisionTz("L LT")
export const serverDateWithDivisionTz = dateToFormatWithDivisionTz("MMM D, YYYY")

export const monthDayYearDateFormat = "MM/DD/YYYY"
export const serverScheduleDateFormat = "YYYY-MM-DD"
export const dateTimeFormat = "MM/DD/YYYY hh:mm A"
export const dateAtTimeFormat = "L [at] hh:mm A"
export const timeFormat = "hh:mm A"

export const getDateTimeLocale = datetime => moment(datetime, "L LT")
export const getDateLocale = datetime => moment(datetime, "L")
export const getBrowserTimeZoneReadable = () => tz(tz.guess()).format("z")
export const getSeconds = milliseconds => toSeconds(milliseconds)

export const periodAsText = (amount, unit) => {
  let modifier = ""
  switch (unit) {
    case "second":
      modifier = localized("second(s)")
      break
    case "minute":
      modifier = localized("minute(s)")
      break
    case "hour":
      modifier = localized("hour(s)")
      break
    case "day":
      modifier = localized("day(s)")
      break
    case "week":
      modifier = localized("week(s)")
      break
    case "month":
      modifier = localized("month(s)")
      break
    case "year":
      modifier = localized("year(s)")
      break
    default:
      break
  }
  return unit && amount ? ` ${localized("for at least")} ${amount} ${modifier}` : ""
}

export const workingDayList = ["MON", "TUE", "WED", "THU", "FRI"]

export const weekDayList = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

export const daysTokensMapper = {
  /*  SUNDAY: localizationKey("Sunday"),
  MONDAY: localizationKey("Monday"),
  TUESDAY: localizationKey("Tuesday"),
  WEDNESDAY: localizationKey("Wednesday"),
  THURSDAY: localizationKey("Thursday"),
  FRIDAY: localizationKey("Friday"),
  SATURDAY: localizationKey("Saturday"),*/
  SUNDAY: "Sunday",
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
}

export const shortDaysTokensMapper = {
  /*  SUN: localizationKey("Sunday"),
  MON: localizationKey("Monday"),
  TUE: localizationKey("Tuesday"),
  WED: localizationKey("Wednesday"),
  THU: localizationKey("Thursday"),
  FRI: localizationKey("Friday"),
  SAT: localizationKey("Saturday"),*/
  SUN: "Sunday",
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
}

export const weekDaysOptions = () => [
  { id: "SUN", value: localized("Sunday") },
  { id: "MON", value: localized("Monday") },
  { id: "TUE", value: localized("Tuesday") },
  { id: "WED", value: localized("Wednesday") },
  { id: "THU", value: localized("Thursday") },
  { id: "FRI", value: localized("Friday") },
  { id: "SAT", value: localized("Saturday") },
]

export const weekDaysSelectOptions = [
  { value: "SUNDAY", labelToken: localizationKey("Sunday") },
  { value: "MONDAY", labelToken: localizationKey("Monday") },
  { value: "TUESDAY", labelToken: localizationKey("Tuesday") },
  { value: "WEDNESDAY", labelToken: localizationKey("Wednesday") },
  { value: "THURSDAY", labelToken: localizationKey("Thursday") },
  { value: "FRIDAY", labelToken: localizationKey("Friday") },
  { value: "SATURDAY", labelToken: localizationKey("Saturday") },
]

export const weekNumberOptions = [
  { value: "1", labelToken: localizationKey("First") },
  { value: "2", labelToken: localizationKey("Second") },
  { value: "3", labelToken: localizationKey("Third") },
  { value: "4", labelToken: localizationKey("Fourth") },
  { value: "5", labelToken: localizationKey("Fifth") },
  { value: "6", labelToken: localizationKey("Last") },
]

export const periodOptions = [
  { value: "STARTUP", labelToken: localizationKey("On system startup") },
  { value: "DAILY", labelToken: localizationKey("Daily") },
  { value: "WEEKLY", labelToken: localizationKey("Weekly") },
  { value: "MONTHLY", labelToken: localizationKey("Monthly") },
  { value: "CUSTOM", labelToken: localizationKey("Custom") },
  { value: "NONE", labelToken: localizationKey("None") },
]

export const monthOptions = [
  { value: "JAN", labelToken: localizationKey("January") },
  { value: "FEB", labelToken: localizationKey("February") },
  { value: "MAR", labelToken: localizationKey("March") },
  { value: "APR", labelToken: localizationKey("April") },
  { value: "MAY", labelToken: localizationKey("May") },
  { value: "JUN", labelToken: localizationKey("June") },
  { value: "JUL", labelToken: localizationKey("July") },
  { value: "AUG", labelToken: localizationKey("August") },
  { value: "SEP", labelToken: localizationKey("September") },
  { value: "OCT", labelToken: localizationKey("October") },
  { value: "NOV", labelToken: localizationKey("November") },
  { value: "DEC", labelToken: localizationKey("December") },
]

export const monthOptionsDefault = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

export const dayOfMonthOptions = () => [
  ...range(1, 32).map(num => ({ value: num.toString(), labelText: num.toString() })),
  { value: "0", labelToken: localizationKey("Last Day") },
]

export const getWeekDaysOptionsIds = () => pluck("id", weekDaysOptions())

export const isSameDay = (day1, day2) => moment(date(day1)).isSame(date(day2))

export const isBetweenDates = (dateToCheck, from, to, inclusive = false, scope = "day") => {
  const day = formatMoment(dateToCheck)
  const inclusivityToken = inclusive ? "[]" : "()"

  return day.isBetween(from, to, scope, inclusivityToken)
}

export const dateDifference = (date1, date2, unit) => {
  const a = moment(date1)
  const b = moment(date2)
  return b.diff(a, unit)
}

export const isDateBefore = (date1, date2) => {
  const a = moment(date1)
  const b = moment(date2)
  return a.isBefore(b)
}

export const isDateAfter = (date1, date2) => {
  const a = moment(date1)
  const b = moment(date2)
  return a.isAfter(b)
}

const secondsToDurationUnitFormat = unit => (unit < 10 ? `0${unit}` : unit)
export const secondsToDuration = seconds => {
  const duration = moment.duration(seconds, "seconds")
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()

  return {
    days: secondsToDurationUnitFormat(days),
    hours: secondsToDurationUnitFormat(hours),
    minutes: secondsToDurationUnitFormat(minutes),
  }
}

export const getReadableMonTime = monTime => {
  const adjustedMonTime = moment(new Date(monTime * 1000))
  return adjustedMonTime.format("hh:mm A")
}

export const hoursToSeconds = hours => 3600 * hours

export const secondsToMs = seconds => 1000 * seconds

export const isDateInFuture = date => moment(date).isAfter(moment())

export const isDateInPast = date => moment(date).isBefore(moment())

export const isDateInFutureWithTimeZone = (date, timezone) => {
  const selectedDate = new Date(date).toLocaleString("en-US")
  const timeZoneDate = new Date().toLocaleString("en-US", { timeZone: timezone })

  return moment(selectedDate).isAfter(moment(timeZoneDate))
}

export const getPreviousDay = (daysBack = 1) => moment().subtract(daysBack, "day")

export const getToday = ({ asDate = false, useDivisionTimeZone = false }) => {
  const today = useDivisionTimeZone ? moment().tz(getDivisionTimeZone()) : moment()

  return asDate ? today.toDate() : today
}

export const getNMonthsDateFromNow = (months = 1, format = serverScheduleDateFormat) =>
  moment()
    .add(months, "month")
    .format(format)

export const getFormatDateTimeForServer = ({ d, dFormat, isEndTime = false, format = dateTimeFormat }) => {
  const date = isEndTime ? moment(d, dFormat).endOf("day") : moment(d, dFormat).startOf("day")
  return date.format(format)
}

export const getFormatDateForUI = (d, format = serverScheduleDateFormat) => {
  return date(moment(d, format))
}

export const getWeekDaysText = (weekDays, separator = ", ") =>
  compose(
    join(separator),
    pluck("value"),
    filter(d => includes(d.id, weekDays)),
  )(weekDaysOptions())

export const getMonthText = (months, separator = ", ") =>
  compose(
    join(separator),
    pluck("labelToken"),
    filter(d => includes(d.value, months)),
  )(monthOptions)

export const getValueFromMinutes = (minutes, unit = "Minutes") =>
  ({
    Days: Math.floor(minutes / (24 * 60)),
    Hours: minutes / 60,
    Minutes: minutes,
  }[unit])

export const getMinutesFromValue = (value, unit = "Minutes") =>
  ({
    Days: Math.floor(value * 24 * 60),
    Hours: value * 60,
    Minutes: value,
  }[unit])
export const isToday = momentDate => {
  const REFERENCE = moment({ h: 0, m: 0, s: 0, ms: 0 })
  const TODAY = REFERENCE.clone().startOf("day")
  const date = moment(momentDate * 1000)

  return date.isSame(TODAY, "d")
}

// TODO - for now the EXCLUDE date needs: 'from' and 'to'
export const getDisabledExcludedDatesFormat = selected =>
  selected.map(dateString => {
    const formatDate = moment(dateString, "YYYY-MM-DD Z").toDate()
    return { from: formatDate, to: formatDate }
  })

export const getDisabledPastDatesFormat = () => ({ after: moment().toDate() })

export const getDisabledFutureDatesFormat = () => ({
  before: moment()
    .add(1, "days")
    .toDate(),
})

export const getDisabledDatesOutsideRangeFormat = selected => [
  { before: moment(selected[0], "YYYY-MM-DD Z").toDate(), after: moment(selected[1], "YYYY-MM-DD Z").toDate() },
]

export const convertTo12HourFormat = hour => ((hour + 11) % 12) + 1

export const convertTo12HourTextFormat = hour => `${convertTo12HourFormat(hour)}${hour >= 12 ? "pm" : "am"}`

export const timeUnits = amount => {
  return amount === 1
    ? [
        { labelToken: localizationKey("Day"), value: "day" },
        { labelToken: localizationKey("Week"), value: "week" },
        { labelToken: localizationKey("Month"), value: "month" },
        { labelToken: localizationKey("Year"), value: "year" },
      ]
    : [
        { labelToken: localizationKey("Days"), value: "day" },
        { labelToken: localizationKey("Weeks"), value: "week" },
        { labelToken: localizationKey("Months"), value: "month" },
        { labelToken: localizationKey("Years"), value: "year" },
      ]
}

export const getRemainingTimeFromUnix = (unixTimestamp, unit = "days") => {
  const expirationDate = moment.unix(unixTimestamp).tz(getDivisionTimeZone())
  const currentDate = moment().tz(getDivisionTimeZone())
  const remaining = expirationDate.diff(currentDate, unit)
  return remaining > 0 ? remaining : 0
}
