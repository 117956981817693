import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { any, whereEq } from "ramda"
import { faHistory, faStar, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { localized } from "js/includes/common/utils"
import { removeRecentTab } from "js/state/actions/general/recentTabs"
import { addFavoriteTab } from "js/state/actions/general/favoriteTabs"
import { getIcon, getOverviewLink, isNewTicket } from "./common"
import SubNavLink from "./SubNavLink"
import SubNavDrawer from "./SubNavDrawer"
import SubNav from "./SubNav"
import SubNavNotice from "js/includes/application/SideNavBar/SubNavNotice"
import SubNavHoverDropdownItem from "js/includes/application/SideNavBar/SubNavHoverDropdownItem"
import { selectFilteredFavoriteTabs } from "js/state/selectors/general/favoriteTabs"
import { selectFilteredRecentTabs } from "js/state/selectors/general/recentTabs"

const RecentTabs = memo(({ collapsed }) => {
  const dispatch = useDispatch()
  const favoriteTabs = useSelector(selectFilteredFavoriteTabs)
  const recentTabs = useSelector(selectFilteredRecentTabs)
  const hasNoRecents = !recentTabs.length
  const actionIconTooltip = localized("Add to Favorites")
  const emptyNotice = <SubNavNotice text={localized("No recents")} />

  const getRecentItem = recentTab => {
    const { id, nodeClass, name, type } = recentTab
    const isFavorite = any(whereEq({ id, type }))(favoriteTabs)
    const href = getOverviewLink(recentTab)
    const icon = getIcon(recentTab)
    const onClickAction = () =>
      dispatch(
        addFavoriteTab({
          type: type,
          item: {
            id,
            name,
            nodeClass,
            ...recentTab,
          },
        }),
      )
    const shouldRenderActionIcon = !isFavorite && !isNewTicket(recentTab)
    const actionIcons = [
      ...(shouldRenderActionIcon
        ? [
            {
              onClick: onClickAction,
              tooltip: actionIconTooltip,
              sideOffset: collapsed ? 56 : 60,
              element: <FontAwesomeIcon icon={faStar} size="sm" />,
            },
          ]
        : []),
      {
        sideOffset: collapsed ? 32 : 36,
        tooltip: localized("Remove from Recents"),
        onClick: () => dispatch(removeRecentTab(recentTab)),
        element: <FontAwesomeIcon icon={faTimes} />,
      },
    ]

    return { isFavorite, href, icon, name, actionIcons }
  }

  const navRenderer = () => (
    <SubNav ariaLabel={localized("Recent items navigation")}>
      <div>
        {hasNoRecents
          ? emptyNotice
          : recentTabs.map(recentTab => {
              const { name, href, icon, actionIcons } = getRecentItem(recentTab)

              return (
                <SubNavLink
                  {...{
                    key: href,
                    href,
                    icon,
                    name,
                    actionIcons,
                    usesNavLink: false,
                  }}
                />
              )
            })}
      </div>
    </SubNav>
  )

  const hoverNavItemsRenderer = () => (
    <div>
      {hasNoRecents
        ? emptyNotice
        : recentTabs.map(recentTab => {
            const { name, href: route, icon, actionIcons } = getRecentItem(recentTab)

            return (
              <SubNavHoverDropdownItem
                {...{
                  key: route,
                  route,
                  name,
                  icon,
                  collapsed,
                  actionIcons,
                  usesActiveClass: false,
                }}
              />
            )
          })}
    </div>
  )

  return (
    <div id="recent" data-testid="recent">
      <SubNavDrawer
        {...{
          collapsed,
          navRenderer,
          hoverNavItemsRenderer,
          icon: faHistory,
          label: localized("Recents"),
          id: "recents",
        }}
      />
    </div>
  )
})

export default RecentTabs
