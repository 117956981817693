import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"

export default memo(({ values, onChange, validation, unitOptions }) => (
  <FormGroup controlId="unit">
    <Col componentClass={ControlLabel} sm={4}>
      {localized("Unit")}
    </Col>
    <Col sm={8}>
      <Dropdown options={unitOptions} initialValue={values.unit} onChange={value => onChange(value)} />
    </Col>
  </FormGroup>
))
