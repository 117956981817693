import { map } from "ramda"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import {
  fetchJson,
  ninjaReportError,
  showErrorMessage,
  showSuccessMessage,
  localized,
  localizationKey,
} from "js/includes/common/utils"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { askConfirmationActions, VM_GUEST_DELETE_ACTION } from "js/includes/vmDashboard/actions"
import ShowMessageDialog from "js/includes/components/MessageDialog"

export const fetchVMGuests = (nodeId, showLoader = true) => async dispatch => {
  showLoader && dispatch(startLoading("FETCH_VM_GUESTS")())

  try {
    const response = await fetchJson(`/node/${nodeId}/vmGuests`)
    const data = map(
      item => ({
        ...item,
        displayName: item.friendlyName || item.netbiosName,
        ipv4s: item.ipv4s.filter(ipv4 => ipv4.length),
        macAddresses: item.macAddresses.filter(macAddress => macAddress.length),
      }),
      response,
    )

    dispatch({
      type: "FETCH_VM_GUESTS",
      data,
    })
  } catch (error) {
    showErrorMessage(localized("Error fetching virtual machines"))
    ninjaReportError(error)
  } finally {
    showLoader && dispatch(stopLoading("FETCH_VM_GUESTS")())
  }
}

export const submitVMAction = (uid, nodes) => async dispatch => {
  if (askConfirmationActions.includes(uid)) {
    const confirmation = await ShowMessageDialog({
      icon: { icon: faInfoCircle, type: "info" },
      title: localizationKey("Are you sure you want to proceed?"),
      buttons: [
        { id: true, label: localizationKey("Yes") },
        { id: false, label: localizationKey("No") },
      ],
    })
    if (!confirmation) {
      return
    }
  }

  dispatch(startLoading("VM_RUNNING_ACTION")())

  try {
    await fetchJson(`/nodes/action${uid === VM_GUEST_DELETE_ACTION.identifier ? "/mfa" : ""}`, {
      options: {
        method: "POST",
        body: JSON.stringify({
          uid,
          nodes,
        }),
      },
    })

    showSuccessMessage(localized("Success"))
  } catch (error) {
    showErrorMessage(localized("Failure"))
    ninjaReportError(error)
  } finally {
    dispatch(stopLoading("VM_RUNNING_ACTION")())
  }
}
