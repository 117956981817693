const faUninstallFailed = {
  prefix: "fac",
  iconName: "uninstall-failed",
  icon: [
    512,
    512,
    [],
    "0007",
    `M404.946,512L106.565,512C94.754,512 85.252,502.498 85.252,490.687C85.252,478.876 94.754,469.374 106.565,469.374L194.214,469.374L210.732,419.821C212.153,415.47 216.238,412.54 220.855,412.54L290.744,412.54C295.362,412.54 299.447,415.47 300.868,419.821L317.385,469.374L404.946,469.374C416.758,469.375 426.259,478.877 426.259,490.687C426.259,502.497 416.758,511.999 404.946,512ZM55.759,99.95L47.954,99.95C45.023,99.95 42.626,102.348 42.626,105.278L42.626,350.377C42.626,352.346 46.322,363.807 49.203,366.688L81.604,398.331L42.626,398.331C19.093,398.331 0,379.238 0,355.705L0,99.95C0,84.823 7.889,71.531 19.774,63.965L55.759,99.95ZM354.14,398.331L163.68,398.331L206.305,355.705L311.514,355.705L354.14,398.331ZM468.885,398.331L434.57,398.331L460.125,374.497C464.192,370.431 468.885,352.366 468.885,350.377L468.885,105.278C468.885,102.347 466.487,99.95 463.557,99.95L462.061,99.95L495.418,66.592C505.224,74.403 511.511,86.445 511.511,99.95L511.511,355.705C511.511,379.238 492.419,398.33 468.885,398.331ZM449.744,82.063L333.144,198.664L449.744,315.264C458.555,324.074 458.555,338.321 449.744,347.132L407.378,389.498C398.567,398.308 384.32,398.308 375.51,389.498L258.91,272.898L142.31,389.498C133.499,398.308 119.252,398.308 110.441,389.498L68.076,347.132C59.265,338.321 59.265,324.074 68.076,315.264L184.676,198.664L68.076,82.063C59.265,73.253 59.265,59.006 68.076,50.195L110.441,7.829C119.252,-0.981 133.499,-0.981 142.31,7.829L258.91,124.429L375.51,7.829C384.32,-0.981 398.567,-0.981 407.378,7.829L449.744,50.195C458.554,59.006 458.555,73.252 449.744,82.063ZM258.91,94.226L222.007,57.324L295.813,57.324L258.91,94.226Z`,
  ],
}

export default faUninstallFailed
