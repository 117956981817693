import { always, compose, evolve, filter, groupBy, includes, mergeLeft, pluck } from "ramda"
import { filterTypes } from "@ninjaone/components/src/DataTable"
import {
  isNilOrEmpty,
  isNotEmpty,
  isNotNilOrEmpty,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { errorCodeShortMessageTokenMap, getShortErrorCodeMessage } from "./common"
import {
  getDestinationsFilter,
  getDevicesFilter,
  getLocationsFilter,
  getOrganizationsFilter,
  dateValues,
  getDataTypesFilter,
} from "js/includes/systemDashboard/backups/common/options"
import { getErrorCodes } from "js/includes/common/backup"

export const statusValues = {
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
}

const logValues = {
  FILTER_WITH_WARNINGS: "filterWithWarnings",
  FILTER_WITH_ERRORS: "filterWithError",
  FILTER_WITHOUT_WARNINGS_OR_ERRORS: "filterWithoutWarningsOrError",
}

export const SYNC_FAILURE_VALUE = "SYNC_FAILURE"

const getDateOptions = () => [
  {
    label: localized("All time"),
    value: dateValues.ALL_TIME,
  },
  {
    label: localized("Today"),
    value: dateValues.TODAY,
  },
  {
    label: localized("Last {{days}} Days", { days: 7 }),
    value: dateValues.LAST_7_DAYS,
  },
  {
    label: localized("Last {{days}} Days", { days: 30 }),
    value: dateValues.LAST_30_DAYS,
  },
  {
    label: localized("Last {{days}} Days", { days: 90 }),
    value: dateValues.LAST_90_DAYS,
  },
]

export const getStatusOptions = () => [
  {
    label: localized("Completed"),
    value: statusValues.COMPLETED,
  },
  {
    label: localized("Failed"),
    value: statusValues.FAILED,
  },
]

const initialStatusValueMap = {
  completed: [statusValues.COMPLETED],
  failed: [statusValues.FAILED],
}

const getLogOptions = () => [
  { label: localized("Has Warnings"), value: logValues.FILTER_WITH_WARNINGS },
  { label: localized("Has Errors"), value: logValues.FILTER_WITH_ERRORS },
  { label: localized("None"), value: logValues.FILTER_WITHOUT_WARNINGS_OR_ERRORS },
]

const initialLogsValueMap = {
  none: [logValues.FILTER_WITHOUT_WARNINGS_OR_ERRORS],
  warnings: [logValues.FILTER_WITH_WARNINGS],
}

const updateFailureTypeOptions = async ({ client, node, updateFilterProps }) => {
  try {
    const errorCodeOptions = await getFailureTypeOptions({ client, node })
    updateFilterProps(
      "failureType",
      evolve({
        componentProps: {
          options: () => errorCodeOptions,
        },
      }),
    )
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching failure type filters"))
  }
}

export const getFailureTypeOptions = async ({ client, node }) => {
  const errorCodes = await getErrorCodes({ client, node })
  return compose(
    ({ internalErrorCodes = [], errorCodes = [] }) => [
      { label: localized("Sync Failure"), value: SYNC_FAILURE_VALUE },
      ...(isNotEmpty(internalErrorCodes) ? [{ label: localized("Internal Error"), value: internalErrorCodes }] : []),
      ...errorCodes.map(errorCode => ({
        label: getShortErrorCodeMessage(errorCode),
        value: errorCode,
      })),
    ],
    groupBy(errorCode => (errorCodeShortMessageTokenMap[errorCode] ? "errorCodes" : "internalErrorCodes")),
  )(errorCodes)
}

const onOrgFilterChange = ({ value: orgValue = [], updateFilterProps }) => {
  const selectedOrgs = pluck("value", orgValue)
  const orgSelected = isNotNilOrEmpty(selectedOrgs)
  updateFilterProps(
    "devices",
    evolve({
      value: (value = []) =>
        orgSelected ? filter(({ organizationId }) => includes(organizationId, selectedOrgs), value) : [],
      componentProps: mergeLeft({
        searchParams: ({ query: name }) => ({
          clientId: selectedOrgs,
          historyMode: true,
          ...(name && { name }),
        }),
      }),
    }),
  )
  updateFilterProps(
    "locations",
    evolve({
      hidden: always(!orgSelected),
      value: (value = []) =>
        orgSelected ? filter(({ organizationId }) => includes(organizationId, selectedOrgs), value) : [],
      componentProps: mergeLeft({
        searchParams: ({ query: name }) => ({
          clientId: selectedOrgs,
          historyMode: true,
          ...(name && { name }),
        }),
      }),
    }),
  )
}

export const getBackupHistoryFilters = ({ client, node, status, logs, dateRange, failureTypeOptions }) => {
  const statusInitialValue = initialStatusValueMap[status] ?? []

  return {
    primary: [
      ...(client ? [getLocationsFilter({ client, historyMode: true })] : []),
      ...(!client && !node ? [getOrganizationsFilter(onOrgFilterChange, true)] : []),
      ...(!node ? [getDevicesFilter(client, true)] : []),
      {
        name: "date",
        type: filterTypes.SINGLE_SELECT,
        defaultValue: dateValues.LAST_90_DAYS,
        initialValue: dateValues[dateRange] ?? dateValues.LAST_90_DAYS,
        labelToken: localizationKey("Date"),
        componentProps: {
          options: getDateOptions(),
        },
      },
      getDataTypesFilter({ name: "planTypes", labelToken: localizationKey("Plan type") }),
    ],
    secondary: [
      getDestinationsFilter(),
      ...(!node && !client ? [getLocationsFilter({ client, historyMode: true })] : []),
      {
        name: "logs",
        type: filterTypes.MULTISELECT,
        labelToken: localizationKey("Log"),
        initialValue: initialLogsValueMap[logs] ?? [],
        componentProps: {
          options: getLogOptions(),
        },
      },
      {
        name: "status",
        type: filterTypes.MULTISELECT,
        labelToken: localizationKey("Status"),
        initialValue: statusInitialValue,
        onFilterChange: ({ value: statusValueList, updateFilterProps }) => {
          const failedSelected = statusValueList.includes(statusValues.FAILED)
          if (failedSelected) updateFailureTypeOptions({ client, node, updateFilterProps })

          updateFilterProps(
            "failureType",
            evolve({
              hidden: () => !failedSelected,
              value: value => (failedSelected ? value : []),
            }),
          )
        },
        componentProps: {
          options: getStatusOptions(),
        },
      },
      {
        name: "failureType",
        type: filterTypes.MULTISELECT,
        labelToken: localizationKey("Failure type"),
        hidden: isNilOrEmpty(statusInitialValue),
        componentProps: {
          options: failureTypeOptions,
        },
      },
    ],
  }
}
