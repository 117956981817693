import { useEffect } from "react"
import { COMMAND_PRIORITY_LOW, createCommand } from "lexical"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

export const ALLOW_SOURCE_PLUGIN_COMMAND = createCommand("ALLOW_SOURCE_PLUGIN_COMMAND")
export const SET_IS_EDITING_SOURCE_COMMAND = createCommand("SET_IS_EDITING_SOURCE_COMMAND")

export const SourcePlugin = ({ setIsEditingSource }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.dispatchCommand(ALLOW_SOURCE_PLUGIN_COMMAND, true)
  }, [editor])

  useEffect(() => {
    return editor.registerCommand(
      SET_IS_EDITING_SOURCE_COMMAND,
      payload => {
        setIsEditingSource?.(payload)
      },
      COMMAND_PRIORITY_LOW,
    )
  }, [editor, setIsEditingSource])

  return null
}
