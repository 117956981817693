import { Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"

export const ProxyDescriptionRow = ({ label, value }) => (
  <Flex gap={16} marginTop={tokens.spacing[2]}>
    <Box width="126px">
      <Text type="body">{label}</Text>
    </Box>
    <Text type="body" color="colorTextStrong">
      {value}
    </Text>
  </Flex>
)
