import React, { memo, useEffect } from "react"
import { always, cond, equals, includes, startsWith, T } from "ramda"
import { Link, NavLink, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import styled from "@emotion/styled"

import { OpenArrowIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { Body } from "@ninjaone/components"
import { typography } from "@ninjaone/tokens"

import { Box, Flex } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import { buildAppInfoCustomData, getAdministrationNavItems } from "js/includes/configuration/utils"
import { containsAny, localized } from "js/includes/common/utils"

const StyledNav = styled.nav`
  background-color: ${({ theme }) => theme.colorBackground};
  width: 300px;
  height: 100%;
  overflow: auto;
  flex: 0 0 auto;
  border-right: 1px solid ${({ theme }) => theme.colorBorderWeakest};

  & a {
    color: ${({ theme }) => theme.colorTextStrong};
    font-size: ${typography.fontSize.headingM};
    font-weight: ${typography.fontWeight.medium};
  }
`

const StyledNavLink = styled(NavLink)`
  width: 100%;
  cursor: pointer;
  padding: ${sizer(4, 4, 4, 6)};
`

const StyledIconButton = styled.button`
  display: flex;
  border: none;
  background: none;
  padding: ${sizer(1)};
  cursor: pointer;
  margin-right: ${sizer(6)};
`

export const StyledSubtab = styled.div`
  padding: ${({ padding }) => padding ?? sizer(2, 6, 2, 9)};
  min-width: ${({ minWidth }) => minWidth};
  cursor: pointer;
  background-color: ${({ theme, isActive }) => isActive && theme.colorForegroundSelected};

  & p {
    color: ${({ theme, isActive }) => isActive && theme.colorTextAction};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundSelected};
  }
`

const checkIfActiveTab = (tab, location) => {
  const excludedTabs = tab.excludedTabs ?? []
  const baseRoute = tab.baseRoute ?? null

  return cond([
    [equals(tab.route), always(true)],
    [path => tab.aliases && tab.aliases.some(alias => !!path.match(alias)), T],
    [containsAny(excludedTabs), always(false)],
    [() => baseRoute && startsWith(baseRoute)(location.pathname), always(true)],
    [includes(tab.route), always(true)],
    [T, always(false)],
  ])(location.pathname)
}

function AdministrationTabs({ appInfoCustomData }) {
  return (
    <StyledNav ariaLabel={localized("Administration navigation")}>
      {getAdministrationNavItems({ appInfoCustomData }).map(
        ({ to, isActive, name, showWhen = true, isBeta, subtabs, baseUrl }) => (
          <MenuItem {...{ to, isActive, name, showWhen, isBeta, subtabs, baseUrl }} key={name} />
        ),
      )}
    </StyledNav>
  )
}

const MenuItem = memo(({ to, isActive, name, showWhen = true, isBeta, subtabs, baseUrl }) => {
  const [showDropdown, setShowDropdown] = useMountedState(false)
  const location = useLocation()

  useEffect(() => {
    if ((baseUrl && startsWith(baseUrl)(location.pathname)) || startsWith(to)(location.pathname)) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }, [to, setShowDropdown, location, baseUrl])

  return showWhen ? (
    <Box
      borderBottomWidth={1}
      borderBottomStyle="solid"
      borderBottomColor="colorBorderWeakest"
      width="100%"
      data-test-wrapper="subtabs-group-wrapper"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <StyledNavLink {...{ key: to, to, isActive }} data-test-subtab="subtab-title">
          <Body
            type="headingS"
            color={!subtabs && equals(to)(location.pathname) ? "colorTextAction" : "colorTextStrong"}
            fontWeight={typography.fontWeight.medium}
          >
            {name}
          </Body>
        </StyledNavLink>
        {!!subtabs?.length && (
          <StyledIconButton data-test-icons="open-arrow" onClick={() => setShowDropdown(!showDropdown)}>
            <OpenArrowIcon inverse={showDropdown} color="colorTextWeakest" />
          </StyledIconButton>
        )}
      </Flex>
      {showDropdown && !!subtabs?.length && (
        <Box paddingBottom={sizer(3)}>
          {subtabs.map(({ route, name, isBeta, show = true, ...tab }) => {
            return show ? (
              <Link to={route} key={`tab-${route}`} data-test-subtab="subtab-links">
                <StyledSubtab isActive={checkIfActiveTab({ route, ...tab }, location)}>
                  <Body>
                    {name}
                    {isBeta && <sup>Beta</sup>}
                  </Body>
                </StyledSubtab>
              </Link>
            ) : null
          })}
        </Box>
      )}
    </Box>
  ) : null
})

export default connect(({ psa }) => ({
  ...buildAppInfoCustomData(psa),
}))(AdministrationTabs)
