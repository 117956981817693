//@flow
import {
  always,
  complement,
  compose,
  concat,
  curry,
  defaultTo,
  either,
  head,
  includes,
  invoker,
  isEmpty,
  isNil,
  join,
  last,
  map,
  prop,
  replace,
  slice,
  split,
  splitAt,
  toLower,
  toUpper,
  trim,
  when,
} from "ramda"

import { maxDefaultEllipsisLength } from "js/includes/common/utils/ssrAndWebUtils"

const normalizeCaseForPair = pair => concat(toUpper(head(pair)), toLower(last(pair)))

export const capitalizeFirstLetter = ([first, ...rest]) => (first ? first.toUpperCase() + rest.join("") : "")

export const capitalize = value => {
  if (value) {
    const [first, ...rest] = value
    return first.toUpperCase() + rest.join("").toLowerCase()
  }
  return ""
}

export const capitalizeFirstLetterOfEveryWord = compose(join(" "), map(capitalizeFirstLetter), split(/\s+/))

export const joinAndCapitalizeFirstLetterOfEveryWord = (elements, separator = ", ") =>
  compose(join(separator), map(capitalize))(elements)

export const splitAndNormalizeCase = compose(map(normalizeCaseForPair), map(splitAt(1)), split("_"))

export const hypenCaseToCamelCase = (str: string) =>
  split("-", str)
    .map((val, i) => (i === 0 ? val : capitalize(val)))
    .join("")

export const underscoreToCamelCase = (type: string) =>
  type
    .toLowerCase()
    .split("_")
    .map((val, i) => (i === 0 ? val : capitalize(val)))
    .join("")

export const spaceToCamelCase = compose(
  join(""),
  list => list.map((val, i) => (i === 0 ? val.toLowerCase() : capitalize(val))),
  split(/\s+/),
  trim,
)

export const camelCaseToSnakeCase = replace(/[A-Z]/g, w => `_${w.toLowerCase()}`)

export const isNilOrEmpty = either(isNil, isEmpty)
export const isNotNilOrEmpty = complement(isNilOrEmpty)
const isBlank = value => typeof value === "string" && !value.trim()
export const isNilOrEmptyOrBlank = either(isNilOrEmpty, isBlank)

export const strEndsWith = curry((searchString: string, input: string): boolean => input.endsWith(searchString))

export const propContainsCaseInsensitive = curry((name, val, obj) =>
  compose(includes(trim(val.toLowerCase())), toLower, defaultTo(""), prop(name))(obj),
)

export const padStart = invoker(2, "padStart")
export const padEnd = invoker(2, "padEnd")

export const getPlural = amount => (amount > 1 ? "s" : "")

export const tokenized = (str, vals = {}) =>
  compose(
    result => {
      if (result.includes("{{")) {
        console.warn(`Localized string was not provided values for all tokens - '${result}'`)
      }
      return result
    },
    join(""),
    map(substr => {
      const dictVal = substr.includes("{{") ? vals[substr.replace("{{", "").replace("}}", "")] : substr
      return dictVal === undefined ? substr : dictVal
    }),
    split(/({{.*?}})/),
  )(str)

export const ellipsis = (str, len = maxDefaultEllipsisLength) => compose(concat, trim, slice)(0, len, str)("...")
export const hasLineBreak = str => str.trim().match(/\r|\n/)?.index

export const defaultToDash = compose(when(isEmpty, always("-")), defaultTo("-"))

const uidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i
export const isUid = uid => uidRegex.test(uid)

export const removeSpaces = str => str.replace(/\s/g, "")
export const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
