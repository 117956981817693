import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { LockhartDataAckMessageComponent } from "js/includes/components/LockhartDataAckMessageComponent"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import ShowMessageDialog from "js/includes/components/MessageDialog"

const defaultBtnOptions = [
  { id: "YES", label: localizationKey("Yes"), validate: true },
  { id: "NO", label: localizationKey("No") },
]

export default async function DisableLockhartWarningAck({
  title,
  message,
  lockhartInfoMessage,
  requireEmail,
  buttons = defaultBtnOptions,
}) {
  let deleteData = false
  const buttonPressed = await ShowMessageDialog({
    icon: { icon: faExclamation, type: "critical" },
    title: () => title,
    message,
    MessageComponent: ({ setValidation }) => (
      <LockhartDataAckMessageComponent
        setValidation={setValidation}
        lockhartInfoMessage={localized(lockhartInfoMessage)}
        setPermanentDataDeletionState={deletionState => (deleteData = deletionState)}
        requireEmail={requireEmail}
      />
    ),
    buttons,
  })
  return { buttonPressed, deleteData }
}
