import React from "react"
import ReactDOM from "react-dom"
import { logout } from "js/includes/common/utils"
import $ from "jquery"
import _ from "underscore"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import StrictMode from "js/includes/components/StrictMode"
import WindowThemeProvider from "js/includes/components/WindowThemeProvider"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const sanitizeHTML = str => (str && typeof str === "string" ? _.escape(str) : "")

export const setupAppVisibilityHook = () => {
  // Set the name of the hidden property and the change event for visibility
  var hidden, visibilityChange
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden"
    visibilityChange = "visibilitychange"
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden"
    visibilityChange = "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden"
    visibilityChange = "webkitvisibilitychange"
  }

  function handleVisibilityChange() {
    if (document[hidden]) {
      window.application.set("pauseTimers", true)
    } else {
      window.application.set("pauseTimers", false)
    }
  }

  // Warn if the browser doesn't support addEventListener or the Page Visibility API
  if (typeof document.addEventListener === "undefined" || typeof document[hidden] === "undefined") {
    console.error("Your browser does not support the Page Visibility API.")
  } else {
    // Handle page visibility change
    window.application.set("pauseTimers", document[hidden])
    document.addEventListener(visibilityChange, handleVisibilityChange, false)
  }
}

export function setupReadOnlyEditorView(context) {
  context.find(".ninja-editor").addClass("read-only")

  context.find("input").attr("disabled", true)
  context.find("textarea").attr("disabled", true)
  context.find("select").attr("disabled", true)
  context.find("input[data-switchery=true]").each(function(index, switchery) {
    $(switchery)
      .data("rivets.binders.switchery.element")
      .disable()
  })

  context.find("[rv-on-click]").each(function(index, el) {
    if ($(el).is("button")) {
      if ($(el).attr("type") === "submit") {
        $(el).attr("disabled", true)
        $(el).off("click")
      }
    } else if (!$(el).hasClass("keep-enabled")) {
      $(el).off("click")
    }
  })

  // iCheckbox seems to require this
  _.defer(function() {
    context
      .find("input[type=radio]")
      .parent()
      .addClass("disabled")
  })
}

export function addIndicator(context, message, color) {
  // If there is not an indicator on the screen
  if (context?.find("div.indicator").length === 0) {
    context.find("> :not(.indicator)").css({ opacity: 0 })

    // Add the indicator message with appropriate messaging and coloring
    var $loading = $(
      '<div class="indicator"><div class="shift"><div class="sk-spinner sk-spinner-fading-circle"><div class="sk-circle1 sk-circle"></div><div class="sk-circle2 sk-circle"></div><div class="sk-circle3 sk-circle"></div><div class="sk-circle4 sk-circle"></div><div class="sk-circle5 sk-circle"></div><div class="sk-circle6 sk-circle"></div><div class="sk-circle7 sk-circle"></div><div class="sk-circle8 sk-circle"></div><div class="sk-circle9 sk-circle"></div><div class="sk-circle10 sk-circle"></div><div class="sk-circle11 sk-circle"></div><div class="sk-circle12 sk-circle"></div></div><div class="text-muted indicator-message">' +
        message +
        "...</div></div></div>",
    )
    context.addClass("indicating").append($loading)
    if (color) {
      $loading.find(".text-muted").css("color", color)
    }
  }
}

export function removeIndicator(context) {
  context.removeClass("indicating")
  context.find(".indicator").remove()
  context.find("> :not(.indicator)").css({ opacity: 1 })
}

export function setupPopoverHide() {
  // Keep only 1 active popover per trigger - also check and hide active popover if user clicks on document
  $("body").on("click", function(e) {
    $('[rel="popover"],[rv-popover]').each(function() {
      //the 'is' for buttons that trigger popups
      //the 'has' for icons within a button that triggers a popup
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
        $(this).popover("hide")
      }
    })
  })
}

export function blendColors(c0, c1, p) {
  // eslint-disable-next-line
  var f = parseInt(c0.slice(1), 16),
    t = parseInt(c1.slice(1), 16),
    R1 = f >> 16,
    G1 = (f >> 8) & 0x00ff,
    B1 = f & 0x0000ff,
    R2 = t >> 16,
    G2 = (t >> 8) & 0x00ff,
    B2 = t & 0x0000ff
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((R2 - R1) * p) + R1) * 0x10000 +
      (Math.round((G2 - G1) * p) + G1) * 0x100 +
      (Math.round((B2 - B1) * p) + B1)
    )
      .toString(16)
      .slice(1)
  )
}

export async function showApplicationFailedToLoadDialog() {
  const buttonClicked = await ShowMessageDialog({
    icon: { icon: faExclamation, type: "critical" },
    title: localizationKey("Failed to load the application"),
    buttons: [
      { id: "RELOAD", label: localizationKey("Reload") },
      { id: "LOGOUT", label: localizationKey("Logout") },
    ],
  })

  switch (buttonClicked) {
    case "RELOAD":
      window.location.reload()
      break
    case "LOGOUT":
      logout()
      break
    default:
  }
}

export const closePreviousFullScreenModal = () => {
  const $fullScreenModal = $("#full-screen-modal")
  if ($fullScreenModal.data("editor-view")) {
    const editorView = $fullScreenModal.data("editor-view")
    $fullScreenModal.data("navigatedAway", true)
    editorView.model.closeModal()
  }
}

export function renderReactComponentIfElementExists(component, element, callBack) {
  const _element = typeof element === "string" ? document.getElementById(element) : element

  if (!_element) return

  let Component
  if (window.theme) {
    Component = (
      <WindowThemeProvider>
        <StrictMode>{component}</StrictMode>
      </WindowThemeProvider>
    )
  } else {
    Component = <StrictMode>{component}</StrictMode>
  }

  return ReactDOM.render(Component, _element, callBack)
}

export function unmountReactComponentIfElementExists(element) {
  if (!element) return

  ReactDOM.unmountComponentAtNode(element)
}
