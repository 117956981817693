const prefix = "fab"
const iconName = "adobe"
const width = 512
const height = 512
const ligatures = []
const unicode = "f778"
const svgPathData =
  "M315.5 64h170.9v384L315.5 64zm-119 0H25.6v384L196.5 64zM256 206.1L363.5 448h-73l-30.7-76.8h-78.7L256 206.1z"

const faAdobe = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export default faAdobe
