import { Body, UnstyledButton } from "@ninjaone/components"
import styled from "@emotion/styled"
import { spacing, borderRadius, typography } from "@ninjaone/tokens"
import { PlayIcon } from "@ninjaone/icons"
import { Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

const StyledWrapper = styled(UnstyledButton)`
  &:hover {
    box-shadow: ${({ theme }) => `2px 2px 10px ${theme.colorBackgroundAccentNeutralWeak}`};
  }
`

const StyledContainer = styled(Flex)`
  position: relative;
  align-item: center;
  justify-content: center;
  padding: ${spacing[10]};
  border-radius: ${borderRadius[2]} ${borderRadius[2]} 0 0;
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};

  svg {
    width: 32px;
    height: 32px;
  }
`

const StyledTextContainer = styled(Flex)`
  align-content: center;
  align-item: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
  border-radius: 0 0 ${borderRadius[2]} ${borderRadius[2]};
  padding: ${spacing[3]};
`

const StyledDurationBadge = styled(Flex)`
  position: absolute;
  bottom: ${spacing[2]};
  right: ${spacing[2]};
  align-items: center;
  gap: ${spacing[2]};
  max-height: 21px;
  border-radius: ${borderRadius[1]};
  padding: calc(${spacing[1]} / 2) ${spacing[1]};

  svg {
    max-height: 17px;
    max-width: 12px;
  }
`

const VideoCard = ({ duration, onClick, titleToken, Icon, videoUrl }) => {
  return (
    <StyledWrapper {...{ onClick }}>
      <StyledContainer>
        <Icon size="xl" color="colorTextWeak" fill="colorTextWeak" />

        <StyledDurationBadge backgroundColor="colorBackgroundAccentNeutralDark">
          <PlayIcon fontSize={typography.fontSize.body} color="colorTextStrongInverse" />
          <Body
            color="colorTextStrongInverse"
            fontSize={typography.fontSize.bodyXs}
            fontWeight={typography.fontWeight.semiBold}
          >
            {duration}
          </Body>
        </StyledDurationBadge>
      </StyledContainer>

      <StyledTextContainer>
        <Body type="headingS" color="colorTextStrong">
          {localized(titleToken)}
        </Body>
      </StyledTextContainer>
    </StyledWrapper>
  )
}

export default VideoCard
