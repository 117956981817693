import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { daemonStateOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData/options"
import { localized } from "js/includes/common/utils"

const DaemonStateSelect = ({ values, onChange }) => (
  <FormGroup controlId="type">
    <Col componentClass={ControlLabel} sm={4}>
      {localized("State")}
    </Col>
    <Col sm={8}>
      <Dropdown options={daemonStateOptions} initialValue={values.state} onChange={value => onChange("state", value)} />
    </Col>
  </FormGroup>
)

export default DaemonStateSelect
