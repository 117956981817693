import PropTypes from "prop-types"
import { Body, Modal } from "@ninjaone/components"
import { localizationKey, localized } from "js/includes/common/utils"
import { scheduledUpdatedTabs } from "./util"

const getTitleToken = updateType => {
  switch (updateType) {
    case scheduledUpdatedTabs.REJECTED:
      return localizationKey("Delete rejected update?")
    case scheduledUpdatedTabs.ENFORCED:
      return localizationKey("Delete enforced update?")
    default:
      return localizationKey("Delete approved update?")
  }
}

function DeleteModal({ unmount, onDelete, version, updateType }) {
  return (
    <Modal
      titleGroup={{
        titleToken: getTitleToken(updateType),
      }}
      size="md"
      unmount={unmount}
      buttons={[
        {
          labelToken: localizationKey("Delete"),
          onClick: () => {
            onDelete(version)
            unmount()
          },
          variant: "danger",
        },
      ]}
      cancelable
    >
      <Body>{localized("Version {{version}}", { version: `iOS ${version}` })}</Body>
    </Modal>
  )
}

export default DeleteModal

DeleteModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  updateType: PropTypes.oneOf(Object.values(scheduledUpdatedTabs)),
}
