export const initialState = ""

export default function hostname(state = initialState, action) {
  switch (action.type) {
    case "INIT_HOSTNAME":
      return action.payload
    case "SET_HOSTNAME":
      return action.hostname
    default:
      return state
  }
}
