import { Input, Modal, Switch } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import {
  isIntegerWithinRange,
  isValidDnsOrIp,
  isValidDomain,
  localizationKey,
  localized,
  validations,
} from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"

export const ProxyTypes = {
  auto: "AUTO",
  manual: "MANUAL",
}

const formValidations = {
  server: value => {
    const success = isValidDnsOrIp(value) || isValidDomain(value)
    return { success, message: success ? "" : localized("Please enter a valid proxy address") }
  },
  port: value => {
    const success = isIntegerWithinRange(value, 0)
    return { success, message: success ? "" : localized("Please enter a valid numeric port") }
  },
  pacUrl: validations.required,
}

export default function ManualProxySetup({ globalProxy, updateProxy, isProxyActive, unmount }) {
  const [manualProxyEnabled, setManualProxyEnabled] = useMountedState(globalProxy?.type === ProxyTypes.manual)
  const [pacProxyEnabled, setPacProxyEnabled] = useMountedState(globalProxy?.type === ProxyTypes.auto)
  const {
    values,
    onChange,
    validateForm,
    validation: { message },
  } = useForm({
    fields: globalProxy,
    validate: formValidations,
  })

  const handleProxyEnabled = value => {
    setManualProxyEnabled(value)
    if (value) {
      setPacProxyEnabled(false)
    }
  }

  const handlePacProxyEnabled = value => {
    setPacProxyEnabled(value)
    if (value) {
      setManualProxyEnabled(false)
    }
  }

  const handleSave = () => {
    const { server, port, username, password, pacUrl } = values

    if (manualProxyEnabled && validateForm(["server", "port", "username", "password"])) {
      updateProxy({
        server,
        port,
        username,
        password,
        type: ProxyTypes.manual,
        active: true,
      })
      unmount()
    }
    if (pacProxyEnabled && validateForm(["pacUrl"])) {
      updateProxy({
        pacUrl,
        type: ProxyTypes.auto,
        active: true,
      })
      unmount()
    }
  }

  return (
    <Modal
      {...{ unmount }}
      size="sm"
      titleGroup={{ titleToken: localizationKey("Manual Proxy Setup") }}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Save"),
          onClick: handleSave,
          disabled: !pacProxyEnabled && !manualProxyEnabled,
        },
      ]}
      cancelable
    >
      <Switch labelToken={localizationKey("Manual Proxy")} checked={manualProxyEnabled} onChange={handleProxyEnabled} />
      <Box marginTop={3}>
        <Input
          value={values.server ?? ""}
          labelToken={localizationKey("Host")}
          onChange={event => onChange("server", event.target.value)}
          disabled={!manualProxyEnabled}
          errorMessage={message.server}
        />
      </Box>
      <Box marginTop={3} color="ninjaGray">
        <Input
          type="number"
          value={values.port ?? ""}
          labelToken={localizationKey("Port")}
          onChange={event => onChange("port", event.target.value)}
          disabled={!manualProxyEnabled}
          errorMessage={message.port}
        />
      </Box>
      <Box marginTop={3} color="ninjaGray">
        <Input
          value={values.username ?? ""}
          labelToken={localizationKey("Username")}
          onChange={event => onChange("username", event.target.value)}
          disabled={!manualProxyEnabled}
          errorMessage={message.username}
        />
      </Box>
      <Box marginTop={3} color="ninjaGray">
        <Input
          type="password"
          value={values.password ?? ""}
          labelToken={localizationKey("Password")}
          onChange={event => onChange("password", event.target.value)}
          disabled={!manualProxyEnabled}
          errorMessage={message.password}
        />
      </Box>
      <Box marginTop={4}>
        <Switch
          labelToken={localizationKey("Proxy auto-configuration (PAC)")}
          checked={pacProxyEnabled}
          onChange={handlePacProxyEnabled}
        />
        <Box marginTop={3}>
          <Input
            labelToken={localizationKey("PAC URI")}
            value={values.pacUrl}
            onChange={event => onChange("pacUrl", event.target.value)}
            disabled={!pacProxyEnabled}
            errorMessage={message.pacUrl}
          />
        </Box>
      </Box>
    </Modal>
  )
}
