import { defaultConditionUids } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"

export const triggerDurationConditions = [
  defaultConditionUids.CPU,
  defaultConditionUids.Memory,
  defaultConditionUids.NetworkUtilization,
]

export const conditionParametersFields = {
  [defaultConditionUids.CPU]: ["duration", "operator", "threshold", "unit"],
  [defaultConditionUids.Memory]: ["duration", "operator", "threshold", "unit"],
  [defaultConditionUids.DiskUsage]: ["duration", "operator", "threshold", "unit"],
  [defaultConditionUids.DataStoreFreeSpace]: ["duration", "operator", "threshold", "unit"],
  [defaultConditionUids.VMHostUptime]: ["days"],
  [defaultConditionUids.BadSensorCount]: ["operator", "threshold", "unit"],
  [defaultConditionUids.DeviceDown]: ["duration", "unit"],
  [defaultConditionUids.SensorHealth]: ["name", "state"],
  [defaultConditionUids.ProcessorUsage]: ["duration", "operator", "threshold", "unit"],
  [defaultConditionUids.GuestOperationMode]: ["mode"],
  [defaultConditionUids.SnapshotSize]: ["operator", "threshold", "unit"],
  [defaultConditionUids.SnapshotLifeSpan]: ["duration", "operator", "unit"],
  [defaultConditionUids.CheckpointSize]: ["operator", "threshold", "unit", "duration"],
  [defaultConditionUids.CheckpointLifeSpan]: ["duration", "operator", "unit"],
  [defaultConditionUids.GuestToolsNotRunning]: [],
}

/**
 * TODO: Implement the new localization approach to address TODOs on lines 230 and 273 in the 'generateConditionName' function
 * It will be like this: 
 * export const conditionAbrevToWord = {
      KB: localizationKey("KiloByte"),
      MB: localizationKey("MegaByte"),
      GB: localizationKey("GigaByte"),
      TB: localizationKey("TeraByte"),
    }
 */
export const conditionAbrevToWord = {
  KB: "Kilo",
  MB: "Mega",
  GB: "Giga",
  TB: "Tera",
}

export * from "./options"
