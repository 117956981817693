import { ConfirmationModal, Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { localizationKey } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

const {
  spacing,
  typography: { fontWeight },
} = tokens

export const ProxyTypeTitle = ({ token }) => (
  <Box marginBottom={spacing[3]}>
    <Text {...{ token }} type="body" color="colorTextStrong" fontWeight={fontWeight.medium} />
  </Box>
)

export const ConfirmRemoveProxy = ({ unmount, onRemoveProxy }) => (
  <ConfirmationModal
    {...{ unmount }}
    type="danger"
    titleToken={localizationKey("Remove the configured proxy?")}
    actionToken={localizationKey("Remove")}
    onConfirm={() => {
      unmount()
      onRemoveProxy()
    }}
  />
)
