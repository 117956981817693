import { useCallback, useEffect, useReducer } from "react"
import { omit } from "ramda"
import momentTz from "moment-timezone"
import { useMountedState } from "@ninjaone/utils"
import { dateTime, get, isNilOrEmpty, isNotNilOrEmpty, ninjaReportError, set } from "js/includes/common/utils"

const STORAGE_NAME = "documentation-content-storage"

const SET_INITIAL_STATE = "SET_INITIAL_STATE"
const STATE_UPDATE_COMPLETED = "STATE_UPDATE_COMPLETED"

const SAVE_KB_ARTICLE = "SAVE_KB_ARTICLE"
const REMOVE_KB_ARTICLE = "REMOVE_KB_ARTICLE"

const initialState = {
  appsAndServices: {
    newDocuments: {},
    editDocuments: {},
  },
  checklists: {},
  customFields: {
    organizations: {},
    devices: {},
  },
  kbArticles: {
    newArticles: {},
    editArticles: {},
  },
  updating: false,
}

function reducer(state, action) {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return { ...state, ...action.payload }
    }
    case STATE_UPDATE_COMPLETED: {
      return {
        ...state,
        updating: false,
      }
    }

    case SAVE_KB_ARTICLE: {
      const key = action.payload.key
      const content = action.payload.content
      const isNewArticle = action.payload.isNewArticle
      const kbArticles = state.kbArticles
      const newArticles = kbArticles?.newArticles ?? {}
      const editArticles = kbArticles?.editArticles ?? {}

      return {
        ...state,
        kbArticles: {
          ...kbArticles,
          ...(isNewArticle && {
            newArticles: {
              ...newArticles,
              [key]: newArticles[key] ? { ...newArticles[key], ...content } : content,
            },
          }),
          ...(!isNewArticle && {
            editArticles: {
              ...editArticles,
              [key]: editArticles[key] ? { ...editArticles[key], ...content } : content,
            },
          }),
        },
        updating: true,
      }
    }
    case REMOVE_KB_ARTICLE: {
      const key = action.payload.key
      const isNewArticle = action.payload.isNewArticle
      return {
        ...state,
        kbArticles: {
          ...state.kbArticles,
          ...(isNewArticle && {
            newArticles: { ...omit([key], state.kbArticles.newArticles) },
          }),
          ...(!isNewArticle && {
            editArticles: { ...omit([key], state.kbArticles.editArticles) },
          }),
        },
        updating: true,
      }
    }

    default:
      return state
  }
}

const useUpdateStorage = ({ state, dispatch }, ...values) => {
  useEffect(() => {
    ;(async () => {
      if (state.updating || values.some(value => isNotNilOrEmpty(value))) {
        await set(STORAGE_NAME, state)

        if (state.updating) {
          dispatch({ type: STATE_UPDATE_COMPLETED })
        }
      }
    })()
  }, [dispatch, state, values])
}

const useCacheKBArticle = ({ state, dispatch }) => {
  const getArticleContent = useCallback(
    ({ key, isNewArticle }) => {
      if (isNewArticle) {
        return state.kbArticles.newArticles[key] ?? null
      }

      return state.kbArticles.editArticles[key] ?? null
    },
    [state.kbArticles],
  )

  const saveArticleContent = useCallback(
    ({ key, content, isNewArticle = false }) =>
      dispatch({
        type: SAVE_KB_ARTICLE,
        payload: { key, content: { ...content, updatedAt: dateTime(momentTz().valueOf()) }, isNewArticle },
      }),
    [dispatch],
  )

  const removeArticleContent = useCallback(
    ({ key, isNewArticle }) => dispatch({ type: REMOVE_KB_ARTICLE, payload: { key, isNewArticle } }),
    [dispatch],
  )

  useUpdateStorage({ state, dispatch }, state.kbArticles.editArticles, state.kbArticles.newArticles)

  return {
    getArticleContent,
    saveArticleContent,
    removeArticleContent,
  }
}

export const useCacheDocumentationContent = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const kbArticleActions = useCacheKBArticle({ state, dispatch })
  const [loading, setLoading] = useMountedState(true)

  const initializeStorage = useCallback(async () => {
    const _initialState = (await get(STORAGE_NAME)) || null

    if (isNilOrEmpty(_initialState)) {
      await set(STORAGE_NAME, initialState)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const _initialState = (await get(STORAGE_NAME)) || null

        if (isNotNilOrEmpty(_initialState)) {
          dispatch({ type: SET_INITIAL_STATE, payload: _initialState })
        }
      } catch (e) {
        ninjaReportError(e)
      } finally {
        setLoading(false)
      }
    })()
  }, [setLoading])

  return {
    loading,
    initializeStorage,
    ...kbArticleActions,
  }
}
