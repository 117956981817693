import { conditionControlCode } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"
import { localized } from "js/includes/common/utils"

export const conditionUidValidation = (value, values) => {
  if (!value) {
    return {
      success: false,
      message: localized("Select a condition"),
    }
  } else {
    return {
      success: true,
      message: "",
    }
  }
}

export const sensorNameValidation = (values, state) => {
  const { selected } = state
  if (!values.length && selected === conditionControlCode.SensorHealth) {
    return {
      success: false,
      message: localized("Select sensor"),
    }
  } else {
    return {
      success: true,
      message: "",
    }
  }
}

export const sensorStateValidation = (values, state) => {
  const { selected } = state
  if (!values.length && selected === conditionControlCode.SensorHealth) {
    return {
      success: false,
      message: localized("Select sensor state"),
    }
  } else {
    return {
      success: true,
      message: "",
    }
  }
}

export const guestModeValidation = (values, state) => {
  const { selected } = state
  if (!values.length && selected === conditionControlCode.GuestOperationMode) {
    return {
      success: false,
      message: localized("Select a guest operation mode"),
    }
  } else {
    return {
      success: true,
      message: "",
    }
  }
}
