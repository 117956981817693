import { hasDocumentationKnowledgeBaseManagementAccess } from "js/includes/common/services/userPermissions"
import { VIEW } from "js/includes/editors/User/Permissions/common"
import { isFeatureEnabled, ninjaReportError } from "js/includes/common/utils"
import { getDocumentationDivisionConfig } from "js/includes/common/client/customFields"

const isDocumentationDivisionConfigEnabled = async () => {
  try {
    const result = await getDocumentationDivisionConfig()
    return result?.content?.enabled
  } catch (err) {
    ninjaReportError(err)
    return false
  }
}

export const showKnowledgeBaseDocumentation = () =>
  isFeatureEnabled("documentation_knowledge_base") && hasDocumentationKnowledgeBaseManagementAccess(VIEW)

export const isKnowledgeBaseDocumentEnabled = async () =>
  showKnowledgeBaseDocumentation() && isDocumentationDivisionConfigEnabled()

export const documentationTabTypes = ["KB_DOCUMENT", "CHECKLIST", "DOCUMENT"]

export const isDocumentationTab = tab => documentationTabTypes.includes(tab.type)
