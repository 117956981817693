export const vmHostDataspecs = [
  {
    dataspec: "vm-host-system",
    limit: 1,
  },
  {
    dataspec: "vm-host-service",
    limit: 1,
  },
  {
    dataspec: "vm-host-license",
    limit: 1,
  },
  {
    dataspec: "vm-host-cpu",
    limit: 60,
  },
  {
    dataspec: "vm-host-nic",
    limit: 60,
  },
  {
    dataspec: "vm-host-datastore",
    limit: 60,
  },
  {
    dataspec: "vm-host-memory",
    limit: 60,
  },
  {
    dataspec: "vm-host-sensor",
    limit: 60,
  },
]

export const vmGuestDataspecs = [
  {
    dataspec: "vm-guest-system",
    limit: 1,
  },
  {
    dataspec: "vm-guest-cpu",
    limit: 60,
  },
  {
    dataspec: "vm-guest-nic",
    limit: 60,
  },
  {
    dataspec: "vm-guest-storage",
    limit: 60,
  },
  {
    dataspec: "vm-guest-memory",
    limit: 60,
  },
  {
    dataspec: "vm-guest-snapshot",
    limit: 60,
  },
]
