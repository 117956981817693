import React, { useMemo } from "react"
import ConditionListItem from "./ConditionListItem"
import { getPathToCondition } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import { Box } from "js/includes/components/Styled"
import { useFetch } from "js/includes/common/hooks"

const ConditionsList = ({ policy, deletePolicyItem, selectCondition, toggleEnabled }) => {
  const [channelList, loadingChannelList] = useFetch("/channel/list/brief", { initialValue: [] })

  const {
    content: { conditions = [] },
  } = policy

  const conditionList = useMemo(
    () =>
      Object.entries(conditions).map(([conditionUid, condition]) => {
        const pathToItem = getPathToCondition(conditionUid)
        return {
          key: conditionUid,
          inheritableItem: condition,
          pathToItem,
          channelList,
          loadingChannelList,
          onSelect: () => selectCondition(conditionUid),
          onDelete: () => deletePolicyItem(pathToItem),
          onToggleEnabled: () => toggleEnabled(conditionUid, condition),
        }
      }),
    [conditions, channelList, loadingChannelList, selectCondition, deletePolicyItem, toggleEnabled],
  )

  if (!policy.content) return null

  return (
    <Box padding={0} as="ul" overflowY="auto">
      {conditionList.map(condition => (
        <ConditionListItem {...condition} />
      ))}
    </Box>
  )
}

export default ConditionsList
