const faUninstallStarted = {
  prefix: "fac",
  iconName: "uninstall-started",
  icon: [
    512,
    512,
    [],
    "0013",
    `M467.926,511.563L42.777,511.563C30.591,511.563 20.787,501.759 20.787,489.572L20.787,386.95C20.787,374.764 30.59,364.96 42.777,364.96L169.969,364.96L169.969,387.444C169.969,405.418 184.43,419.879 202.405,419.879L310.525,419.879C328.5,419.879 342.961,405.418 342.961,387.444L342.961,364.96L467.926,364.96C480.113,364.96 489.917,374.764 489.917,386.95L489.917,489.572C489.917,501.758 480.113,511.562 467.926,511.563ZM93.804,448.85C83.466,448.85 75.072,457.244 75.072,467.582C75.072,477.921 83.466,486.315 93.804,486.315L93.925,486.315C104.209,486.25 112.537,477.881 112.537,467.582C112.537,457.284 104.209,448.915 93.925,448.85L93.804,448.85ZM170.657,463.786C169.01,455.281 161.517,448.85 152.533,448.85C142.344,448.85 134.072,457.122 134.072,467.311C134.072,474.48 138.167,480.7 144.143,483.757C146.708,485.128 149.632,485.907 152.73,485.907C162.809,485.907 171.055,477.661 171.055,467.582C171.055,466.282 170.918,465.012 170.657,463.786ZM219.793,394.212L293.094,394.212C305.281,394.212 315.085,384.407 315.085,372.221L315.085,175.674L395.441,175.674C411.751,175.674 419.906,155.974 408.361,144.429L268.996,4.973C262.124,-1.899 250.854,-1.899 243.982,4.973L104.434,144.429C92.889,155.974 101.044,175.674 117.354,175.674L197.802,175.674L197.802,372.221C197.802,384.407 207.606,394.212 219.793,394.212Z`,
  ],
}

export default faUninstallStarted
