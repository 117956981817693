export const windowsUserProfileOptions = ["desktop", "myDocuments", "myPictures", "localAppData", "applicationData"]

export const windowsPublicProfileOptions = ["commonDesktop", "sharedDocuments", "sharedPictures"]

export const windowsGeneralPublicOptions = ["commonAppData", "startMenuPrograms"]

export const cloudberryWindowsCommonFolderOptions = [
  "userProfile",
  ...windowsUserProfileOptions,

  "allUsersProfile",
  ...windowsPublicProfileOptions,

  ...windowsGeneralPublicOptions,
]

const windowsCommonFolderTestOptions = [
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Documents/i.test(path),
    code: "sharedDocuments",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Pictures/i.test(path),
    code: "sharedPictures",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Desktop/i.test(path),
    code: "commonDesktop",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public/i.test(path),
    code: "allUsersProfile",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Desktop/i.test(path),
    code: "desktop",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Pictures/i.test(path),
    code: "myPictures",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Documents/i.test(path),
    code: "myDocuments",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}AppData[/|\\]{1}Local/i.test(path),
    code: "localAppData",
  },
  {
    test: path =>
      /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}AppData[/|\\]{1}Roaming[/|\\]{1}Microsoft[/|\\]{1}Windows[/|\\]{1}Start Menu[/|\\]{1}Programs/i.test(
        path,
      ),
    code: "startMenuPrograms",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}AppData[/|\\]{1}Roaming/i.test(path),
    code: "applicationData",
  },
  {
    test: path => /C:[/|\\]{1}Users/i.test(path),
    code: "userProfile",
  },
  {
    test: path => /C:[/|\\]{1}ProgramData/i.test(path),
    code: "commonAppData",
  },
]

export const getCloudberryWindowsCommonFolderCode = path =>
  windowsCommonFolderTestOptions.find(r => r.test(path.trim()))?.code
