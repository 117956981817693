import { find, isNil, pluck, propEq } from "ramda"
import { Input, Modal, Switch, Text } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import { localizationKey, validations } from "js/includes/common/utils"
import { useForm } from "js/includes/common/hooks"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { StyledLabel } from "js/includes/components/Styled/Form"

export default function ManualProxySetup({ proxy, updateProxyConfiguration, isProxyActive, unmount }) {
  const handleProxyEnabled = (field, value) => {
    onChange(field, value)

    if (value) {
      if (field === "directProxyEnabled") {
        onChange("pacProxyEnabled", false)
      } else {
        onChange("directProxyEnabled", false)
      }
    }
  }

  const handleSave = () => {
    const { host, port, excludedHosts, pacUri } = values

    if (validateForm()) {
      if (values.directProxyEnabled) {
        updateProxyConfiguration({
          direct: { host, port, excludedHosts: pluck("value", excludedHosts), active: isProxyActive },
        })
      } else if (values.pacProxyEnabled) {
        updateProxyConfiguration({ pac: { pacUri, active: isProxyActive } })
      }
      unmount()
    }
  }

  const { values, onChange, validateForm, validation } = useForm({
    fields: {
      directProxyEnabled: !!proxy?.direct?.host,
      pacProxyEnabled: !!proxy?.pac?.pacUri,
      host: proxy?.direct?.host ?? "",
      port: proxy?.direct?.port ?? "",
      excludedHosts: proxy?.direct?.excludedHosts
        ? proxy.direct.excludedHosts.map(hostItem => ({
            value: hostItem,
            label: hostItem,
          }))
        : [],
      pacUri: proxy?.pac?.pacUri ?? "",
    },
    validate: {
      host: (value, values) => (values.directProxyEnabled ? validations.required(value) : { success: true }),
      pacUri: (value, values) => (values.pacProxyEnabled ? validations.required(value) : { success: true }),
    },
  })

  const optionExist = option => !isNil(find(propEq("value", option))(values.excludedHosts))

  return (
    <Modal
      size="sm"
      titleGroup={{
        titleToken: localizationKey("Manual Proxy Setup"),
      }}
      unmount={unmount}
      cancelable
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Save"),
          onClick: handleSave,
          disabled: !values.pacProxyEnabled && !values.directProxyEnabled,
        },
      ]}
    >
      <Box>
        <Switch
          labelToken={localizationKey("Direct Proxy")}
          checked={values.directProxyEnabled}
          onChange={value => handleProxyEnabled("directProxyEnabled", value)}
        />
        <Box marginTop={3}>
          <Input
            value={values.host}
            labelToken={localizationKey("Host")}
            onChange={event => onChange("host", event.target.value)}
            required
            disabled={!values.directProxyEnabled}
            errorMessage={validation.message.host}
          />
        </Box>
        <Box marginTop={3} color="ninjaGray">
          <Input
            value={values.port}
            labelToken={localizationKey("Port")}
            onChange={event => onChange("port", event.target.value)}
            disabled={!values.directProxyEnabled}
            type="number"
          />
        </Box>
        <Box marginTop={3}>
          <StyledLabel>
            <Text
              {...{ size: "sm", color: { code: "black", shade: "100" }, token: localizationKey("Excluded host") }}
            />
          </StyledLabel>
          <SearchableDropDown
            width="100%"
            value={values.excludedHosts}
            options={[]}
            onSelect={values => onChange("excludedHosts", values)}
            searchPlaceholderToken={localizationKey("Enter excluded host")}
            shouldCreate={value => !optionExist(value)}
            disabled={!values.directProxyEnabled}
            isMulti
            isCreatable
          />
        </Box>
      </Box>
      <Box marginTop={4}>
        <Switch
          labelToken={localizationKey("Proxy auto-configuration (PAC)")}
          checked={values.pacProxyEnabled}
          onChange={value => handleProxyEnabled("pacProxyEnabled", value)}
        />
        <Box marginTop={3}>
          <Input
            labelToken={localizationKey("PAC URI")}
            value={values.pacUri}
            onChange={event => onChange("pacUri", event.target.value)}
            type="text"
            required
            disabled={!values.pacProxyEnabled}
            errorMessage={validation.message.pacUri}
          />
        </Box>
      </Box>
    </Modal>
  )
}
