import { assoc, compose, head, map, prop, propEq, toPairs } from "ramda"
import { Checkbox, InfoTooltip, Input, Modal, Select } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { isNotNil, localizationKey, localized, validations } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { useForm } from "js/includes/common/hooks"
import { toInt } from "js/includes/common/_conversions"

export const settingNameTokenMapper = {
  applications: localizationKey("Applications"),
  keyguardDisabled: localizationKey("Keyguard disabled"),
  permittedAccessibilityServices: localizationKey("Permitted accessibility services"),
  permittedInputMethods: localizationKey("Permitted input methods"),
  minimumApiLevel: localizationKey("Minimum API level"),
  recommendedGlobalProxy: localizationKey("Recommended global proxy"),
  locationMode: localizationKey("Location mode"),
  alwaysOnVpnPackage: localizationKey("Always On VPN Package"),
  bluetoothConfigDisabled: localizationKey("Bluetooth config disabled"),
  encryptionPolicy: localizationKey("Encryption policy"),
  permissionGrants: localizationKey("Permission grants"),
  passwordPolicies: localizationKey("Password policies"),
  advancedSecurityOverrides: localizationKey("Advanced security overrides"),
  personalUsagePolicies: localizationKey("Personal usage policies"),
  crossProfilePolicies: localizationKey("Cross-profile policies"),
  openNetworkConfiguration: localizationKey("Open network configuration"),
  dataRoamingDisabled: localizationKey("Data roaming disabled"),
  systemUpdate: localizationKey("System update"),
  vpnConfigDisabled: localizationKey("VPN config disabled"),
  cameraDisabled: localizationKey("Camera disabled"),
  wifiConfigDisabled: localizationKey("Wi-Fi config disabled"),
  mountPhysicalMediaDisabled: localizationKey("Mount physical media disabled"),
  factoryResetDisabled: localizationKey("Factory reset disabled"),
  playStoreMode: localizationKey("Play Store mode"),
}

export const blockScopesOptions = [
  { value: "BLOCK_SCOPE_UNSPECIFIED", labelToken: localizationKey("Unspecified") },
  { value: "BLOCK_SCOPE_WORK_PROFILE", labelToken: localizationKey("Work Profile") },
  { value: "BLOCK_SCOPE_DEVICE", labelToken: localizationKey("Device") },
]

const nestRuleObj = ({ settingName, blockAfterDays, blockScope, wipeAfterDays, preserveFrp }) => ({
  settingName,
  blockAction: { blockAfterDays, blockScope },
  wipeAction: { wipeAfterDays, preserveFrp: !!preserveFrp },
})

const unnestRuleObj = ({ settingName, blockAction, wipeAction }) => ({
  settingName,
  blockAfterDays: blockAction?.blockAfterDays,
  blockScope: blockAction?.blockScope,
  wipeAfterDays: wipeAction?.wipeAfterDays,
  preserveFrp: wipeAction?.preserveFrp,
})

export const rulesToArray = compose(
  map(([uuid, rule]) => {
    return { ...rule, uuid, labelToken: settingNameTokenMapper[rule.settingName] }
  }),
  toPairs,
)

const getSettingOptions = (rules, rule, settingNameOptions) => {
  const rulesArray = rulesToArray(rules)
  const filtered = settingNameOptions.filter(({ key }) => !rulesArray.find(({ settingName }) => settingName === key))
  const mapSettingOptionsList = items =>
    map(option => compose(assoc("labelToken", settingNameTokenMapper[option.key]), assoc("value", option.key))(option))(
      items,
    )

  if (rule) {
    const selectedOption = settingNameOptions.find(propEq("key", rule.settingName))
    return mapSettingOptionsList([selectedOption, ...filtered])
  }

  return mapSettingOptionsList(filtered)
}

const firstValue = (values, propName) => compose(prop(propName), head)(values)

const getInitialFields = settingOptions => ({
  settingName: firstValue(settingOptions, "key"),
  blockScope: firstValue(blockScopesOptions, "value"),
  blockAfterDays: "0",
  wipeAfterDays: "0",
  preserveFrp: false,
})

const formValidations = {
  settingName: validations.required,
  blockAfterDays: value => {
    const { success } = validations.isValidIntegerWithinRange(-1, 30, toInt(value))
    return { success, message: success ? null : localized("Must be up to 30 days") }
  },
  wipeAfterDays: (value, { blockAfterDays }) => {
    const { success } = validations.isValidIntegerWithinRange(toInt(blockAfterDays) + 1, 30, toInt(value))
    return {
      success,
      message: success ? null : localized("Must be up to 30 days and greater than block after days"),
    }
  },
}

export default function PolicyEnforcementModal({ unmount, onSave, rule, rules, settingNameOptions }) {
  const settingOptions = getSettingOptions(rules, rule, settingNameOptions)
  const { values, validation, onChange, validateForm } = useForm({
    validate: formValidations,
    fields: rule ? unnestRuleObj(rule) : getInitialFields(settingOptions),
  })

  const isEditAction = !!rule

  const handleSave = e => {
    e.preventDefault()
    if (!validateForm()) return
    const newRule = {
      ...rules[values.settingName],
      ...nestRuleObj(values),
    }
    onSave?.(newRule)
    unmount()
  }

  return (
    <form onSubmit={handleSave}>
      <Modal
        {...{ unmount }}
        titleGroup={{
          titleToken: isEditAction
            ? localizationKey("Edit policy enforcement")
            : localizationKey("Add new policy enforcement"),
        }}
        buttons={[
          {
            submit: true,
            type: "save",
            labelToken: isEditAction ? localizationKey("Update") : localizationKey("Add"),
          },
        ]}
      >
        <Select
          onChange={value => onChange({ settingName: value })}
          labelId="settingName"
          defaultValue={values.settingName}
          labelToken={localizationKey("Setting Name")}
          placeholder={localizationKey("Select an option")}
          options={settingOptions}
          errorMessage={validation.message.settingName}
          disabled={isNotNil(rule) || settingOptions.length === 0}
        />
        <Box marginTop={sizer(4)}>
          <Select
            onChange={value => onChange({ blockScope: value })}
            labelId="blockScope"
            defaultValue={values.blockScope}
            labelToken={localizationKey("Block scope")}
            placeholder={localizationKey("Select an option")}
            options={blockScopesOptions}
            errorMessage={validation.message.blockScope}
          />
          <Box marginTop={sizer(4)}>
            <Input
              {...{ onChange }}
              labelText={localized("Block after days")}
              name="blockAfterDays"
              value={values.blockAfterDays}
              type="number"
              min={0}
              errorMessage={validation.message.blockAfterDays}
            />
          </Box>
        </Box>
        <Box>
          <Box marginTop={sizer(4)} marginBottom={sizer(5)}>
            <Input
              {...{ onChange }}
              labelText={localized("Wipe after days")}
              name="wipeAfterDays"
              value={values.wipeAfterDays}
              type="number"
              min={0}
              errorMessage={validation.message.wipeAfterDays}
            />
          </Box>
          <Flex>
            <Checkbox
              onChange={({ isChecked }) => onChange("preserveFrp", isChecked)}
              name="preserveFrp"
              checked={values.preserveFrp}
              label={localized("Preserve Frp")}
            />
            <Box marginLeft={sizer(2)}>
              <InfoTooltip
                token={localizationKey(
                  "Whether the factory-reset protection data is preserved on the device. This setting doesn't apply to work profiles",
                )}
              />
            </Box>
          </Flex>
        </Box>
      </Modal>
    </form>
  )
}
