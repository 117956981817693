import { useState, useRef, useEffect } from "react"
import styled from "@emotion/styled"
import { Box, StyledSpan } from "js/includes/components/Styled"
import { colors } from "js/includes/common/theme"

const StyledSliderTooltip = styled(StyledSpan)`
  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${colors.ninjaBlueSaturated} transparent transparent transparent;
  }
`

const thumbHalfWidth = 8

const sliderLabelProperties = {
  color: "ninjaMedium",
  fontSize: "10px",
  lineHeight: "1.333em",
  textShadow: "none",
  top: "-2px",
  padding: "1px 3px",
  backgroundColor: "ninjaLight",
  borderRadius: "4px",
  position: "absolute",
  display: "block",
  cursor: "default",
}

export default function Slider({ min = 1, max = 100, initialValue = 1, onChange }) {
  const ref = useRef(null)
  const [value, setValue] = useState(initialValue)
  const [tooltipLeftPosition, setTooltipLeftPosition] = useState(0)

  useEffect(() => {
    const totalInputWidth = ref.current ? ref.current.offsetWidth : 0
    const left = ((value - min) / (max - min)) * (totalInputWidth - thumbHalfWidth - thumbHalfWidth) + thumbHalfWidth
    setTooltipLeftPosition(left)
  }, [value, max, min])

  return (
    <Box height="35px" display="flex" alignItems="flex-end" position="relative">
      <StyledSpan {...sliderLabelProperties} left={0}>
        {min}
      </StyledSpan>
      <StyledSpan {...sliderLabelProperties} right={0}>
        {max}
      </StyledSpan>
      <StyledSliderTooltip
        {...{
          ...sliderLabelProperties,
          backgroundColor: "ninjaBlueSaturated",
          color: "ninjaWhite",
          marginLeft: "-10px",
          padding: "1px 5px !important",
          left: `${tooltipLeftPosition}px`,
        }}
      >
        {value}
      </StyledSliderTooltip>
      <input
        type="range"
        ref={ref}
        {...{ min, max, value }}
        onChange={e => {
          setValue(e.target.value)
          onChange && onChange(e.target.value)
        }}
      />
    </Box>
  )
}
