import FullModel from "./FullModel"
import { showErrorMessage, localized } from "js/includes/common/utils"

export const CustomerModel = FullModel.extend(
  {
    urlRoot: "/client",
    parseParam: "client",

    onSaveError(xhr) {
      if (!xhr.responseJSON || xhr.responseJSON.resultCode !== "CLIENT_NAME_ALREADY_IN_USE") {
        showErrorMessage(localized("Error saving customer"))
      }
    },
  },
  {
    getModel(id) {
      return CustomerModel.findOrCreate({ id: id })
    },
  },
)
