import { useDispatch, useSelector } from "react-redux"

import { keys, map, path } from "ramda"

import styled from "@emotion/styled"
import { AlertMessage, Button, IconButton, Modal, Text } from "@ninjaone/components"
import { CloseIcon, PlusLightIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

import { updatePolicyItem, deletePolicyItem } from "js/state/actions/policyEditor/editor"
import ReduxInheritable from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/ReduxInheritable"
import { defaultValues as SecurityFormDefaultValues } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/SecurityForm"

import { defaultToDash, isNilOrEmpty, localizationKey, localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import {
  defaultInheritance,
  getAvailableLanguages,
  getLabelTokenFromValue,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import showModal from "js/includes/common/services/showModal"
import AddOrEditLanguageModal from "./AddOrEditLanguageModal"
import EditDefaultMessageModal from "./EditDefaultMessageModal"
import ConfirmDeleteMessage from "./ConfirmDeleteMessage"

const {
  typography: { fontWeight },
  spacing,
  borderRadius,
} = tokens

const InheritableTag = ReduxInheritable(({ inheritanceElement, disableRevert, disableRevertText, width }) => (
  <Flex flexShrink="0" justifyContent="end" width={width} paddingRight={spacing[2]}>
    <Box>{inheritanceElement}</Box>
  </Flex>
))

const StyledTable = styled.table`
  width: 100%;

  border-collapse: separate;
  border-spacing: 0 ${spacing[3]};

  table-layout: auto;

  th {
    padding: ${spacing[1]} ${spacing[3]};
    border-bottom: ${({ theme }) => `1px solid ${theme.colorBorderWeak}`};

      &:nth-of-type(2) {
        width: 100%;
      }

      &:nth-of-type(3) {
        width: 100%;
        padding: ${spacing[1]} 0;
    }

  }

  td {
    padding: ${spacing[3]};
    background-color: ${({ theme }) => theme.colorBackgroundSubtle};
    border-radius: ${borderRadius[1]}

    &:nth-of-type(2) {
      width: auto;
    }

    &:nth-of-type(1) {
      min-width: 180px;
      width: auto;
      @media (max-width: 800px) {
        min-width: 100px;
        width: auto;
      }
    }

    &:nth-of-type(3) {
      width: 60px;
    }

    &:last-of-type {
      background: transparent;
      width: 100px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;

    th,
    td {
      padding: ${spacing[1]};
    }
  }
`

const root = `security`

const mapEntryLanguages = languageEntries =>
  map(key => ({
    language: key,
    languageToken: getLabelTokenFromValue(key, getAvailableLanguages()),
    message: languageEntries[key].message,
    inheritance: languageEntries[key].inheritance,
  }))(keys(languageEntries))

export default function CustomMessaging({ entryKey, entryValue }) {
  const parentPolicy = useSelector(state => state.policyEditor.parentPolicy)
  const dispatch = useDispatch()

  const defaultMessage = entryValue?.defaultMessage || {}
  const languageItems = mapEntryLanguages(entryValue?.localizedMessages)

  const parentDefaultMessage = path(["content", "security", entryKey, "defaultMessage", "message"], parentPolicy)

  const editDefaultMessage = () => {
    showModal(
      <EditDefaultMessageModal
        updateMessage={updateDefaultMessage}
        defaultMessage={defaultMessage}
        isRequired={languageItems.length > 0}
      />,
    )
  }

  const updateDefaultMessage = newDefaultMessage =>
    dispatch(updatePolicyItem(`${root}.${entryKey}.defaultMessage`, parentPolicy, newDefaultMessage))

  const addNewLanguage = () => {
    if (isNilOrEmpty(defaultMessage?.message)) {
      showModal(
        <Modal heading={localized("Default message is empty")} size="sm">
          <AlertMessage
            variant="info"
            titleToken={localizationKey("Default message is required")}
            labelToken={localizationKey(
              "A default message must be provided in order to add a custom message in other languages.",
            )}
          />
        </Modal>,
      )
    } else {
      showModal(
        <AddOrEditLanguageModal
          {...{
            updateLanguageItem,
            languageKeys: Object.keys(entryValue?.localizedMessages || {}),
          }}
        />,
      )
    }
  }
  const editLanguage = editLanguageItem => {
    showModal(<AddOrEditLanguageModal {...{ updateLanguageItem, editLanguageItem }} />)
  }
  const updateLanguageItem = (key, value) =>
    dispatch(
      updatePolicyItem(`${root}.${entryKey}.localizedMessages.${key}`, parentPolicy, {
        message: value.message,
        inheritance: value.inheritance,
      }),
    )

  const showDeleteLocaleModal = item => {
    showModal(
      <ConfirmDeleteMessage
        {...{ languageToken: item.languageToken, languageKey: item.language, entryKey, removeLanguageItem }}
      />,
    )
  }
  const removeLanguageItem = key => dispatch(deletePolicyItem(`${root}.${entryKey}.localizedMessages.${key}`))

  return (
    <Box width="1000px">
      <StyledTable>
        <thead>
          <tr>
            <th>
              <Text
                color="colorTextStrong"
                type="body"
                fontWeight={fontWeight.medium}
                token={localizationKey("Language")}
              />
            </th>
            <th>
              <Text
                color="colorTextStrong"
                type="body"
                fontWeight={fontWeight.medium}
                token={localizationKey("Message")}
              />
            </th>
            <th>
              <Button
                type="tertiary"
                onClick={addNewLanguage}
                labelToken={localizationKey("Add language")}
                transparent
                Icon={PlusLightIcon}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Text
                type="body"
                color="colorTextWeak"
                token={localizationKey("Default")}
                fontWeight={fontWeight.medium}
              />
            </td>
            <td>
              <Text type="body" color="colorTextWeak" textWrapLineLimit={4} textWrap>
                {defaultToDash(defaultMessage.message)}
              </Text>
            </td>
            <td>
              <Flex justifyContent="flex-end">
                <Button
                  type="tertiary"
                  onClick={() => editDefaultMessage()}
                  labelToken={localizationKey("Edit")}
                  transparent
                />
              </Flex>
            </td>
            <td>
              {!!defaultMessage.inheritance && (
                <InheritableTag
                  pathToItem={`${root}.${entryKey}.defaultMessage`}
                  inheritableItem={defaultMessage || SecurityFormDefaultValues[entryKey].defaultMessage}
                  disableRevert={isNilOrEmpty(parentDefaultMessage) && languageItems.length > 0}
                  disableRevertText={
                    isNilOrEmpty(parentDefaultMessage) && languageItems.length > 0
                      ? localized("Default message can not be reverted to an empty message.")
                      : null
                  }
                  width={defaultMessage.inheritance.inherited ? "200px" : "0"}
                />
              )}
            </td>
          </tr>
          {languageItems.map(item => (
            <tr key={item.key}>
              <td>
                <Text
                  type="body"
                  color="colorTextWeak"
                  token={item.languageToken}
                  fontWeight={fontWeight.medium}
                  textWrap
                />
              </td>
              <td>
                <Text
                  type="body"
                  color="colorTextWeak"
                  token={localizationKey(item.message)}
                  textWrapLineLimit={4}
                  textWrap
                />
              </td>
              <td>
                <Flex
                  flexDirection="column"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  height="100%"
                  gap={spacing[4]}
                >
                  {!item.inheritance?.inherited && (
                    <IconButton
                      size="sm"
                      onClick={() => showDeleteLocaleModal(item)}
                      tooltip={localized("Delete {{language}}", { language: localized(item.languageToken) })}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Button
                    type="tertiary"
                    onClick={() => editLanguage(item)}
                    labelToken={localizationKey("Edit")}
                    transparent
                  />
                </Flex>
              </td>
              <td>
                {!!item.inheritance && (
                  <InheritableTag
                    pathToItem={`${root}.${entryKey}.localizedMessages.${item.language}`}
                    inheritableItem={entryValue?.localizedMessages[item.language] ?? { ...defaultInheritance }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Box>
  )
}
