import { append, compose, equals, ifElse, includes, reject } from "ramda"
import { user } from "js/includes/common/utils"

//TODO investigate why are we sending categories ids as strings only for Install/Run Applications, standardize it if possible, post 5.4
const UNCATEGORIZED_ID = "1"

export const removeUncategorizedOption = scriptCategories =>
  scriptCategories?.filter(({ value, id }) => `${value}` !== UNCATEGORIZED_ID && `${id}` !== UNCATEGORIZED_ID)

const getUserPermittedScriptCategories = scriptCategories =>
  scriptCategories?.filter(
    ({ value, id }) => (value && user("canUpdateScripts", [+value])) || (id && user("canUpdateScripts", [+id])),
  )

/**
 * Removes the "Uncategorized" and options that user does not have update permissions
 * from scriptCategories list
 * @param {{value: string, labelText: string}[]} scriptCategories
 */
export const getCategoriesOptions = scriptCategories =>
  compose(getUserPermittedScriptCategories, removeUncategorizedOption)(scriptCategories)

export const removeUncategorizedValue = values => {
  return values.filter(v => v !== UNCATEGORIZED_ID)
}
export const uncategorizedIfNoCategories = categories => (categories.length ? categories : [UNCATEGORIZED_ID])

export const uncategorizedIfNoOptionsSelected = ({ selected, categories }) => {
  const selectedValues = ifElse(includes(selected), reject(equals(selected)), append(selected))(categories) || []
  return selectedValues.length ? removeUncategorizedValue(selectedValues) : [UNCATEGORIZED_ID]
}
