import React, { memo } from "react"
import { DurationStepper } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export default memo(({ values, onChange }) => (
  <DurationStepper
    {...{
      values,
      onChange,
      maxValue: 120,
      valueKey: "days",
      durationToken: localizationKey("Days"),
    }}
  />
))
