import { fetchJson, showErrorMessage, localized } from "js/includes/common/utils"

export function getAllLocations() {
  return async dispatch => {
    try {
      const locations = await fetchJson(`/client/list/locations`)
      dispatch({ type: "SET_ORGANIZATION_LOCATIONS_LIST", locations })
    } catch (err) {
      throw err
    }
  }
}

export function updateLocationInNodes(nodeIds, organizationId, locationId) {
  return async dispatch => {
    try {
      const body = JSON.stringify({
        clientId: organizationId,
        locationId: locationId,
        nodeIds: nodeIds,
      })
      return await fetchJson("/node/updateLocationList", { options: { method: "POST", body } })
    } catch (err) {
      showErrorMessage(localized("Error updating location"))
      throw err
    }
  }
}

export function updateOrganizationAndLocationInNodes(nodeIds, organizationId, locationId) {
  return async dispatch => {
    try {
      const body = JSON.stringify({
        clientId: organizationId,
        locationId: locationId,
        nodeIdList: nodeIds,
      })
      return await fetchJson("/webapp/migrateagent", { options: { method: "POST", body } })
    } catch (err) {
      showErrorMessage(localized("Error updating organization and location"))
      throw err
    }
  }
}

export async function getNodeLocation(locationId) {
  if (locationId === 0) {
    return { name: localized("Unassigned") }
  }

  try {
    return await fetchJson(`/location/${locationId}`)
  } catch (err) {
    return { name: localized("Unassigned") }
  }
}
