const faBitdefenderLogo = {
  prefix: "fac",
  iconName: "bitdefender-logo",
  icon: [
    254,
    253,
    [],
    "0081",
    `M21.237,3.476c-8.381,4.191 -14.285,10.286 -18.285,18.858c-2.953,6.19 -2.953,6.19 -2.953,104.761c0,98.667 0,98.667 3.524,105.619c3.905,7.905 9.81,13.905 17.333,17.715c5.048,2.571 5.048,2.571 106.095,2.571c101.143,0 101.143,0 106.191,-2.571c7.524,-3.81 13.428,-9.81 17.333,-17.715c3.524,-6.952 3.524,-6.952 3.524,-105.619c0,-98.571 0,-98.571 -2.952,-104.761c-4,-8.572 -9.905,-14.667 -18.286,-18.858c-7.048,-3.523 -7.048,-3.523 -105.81,-3.523c-98.666,0 -98.666,0 -105.714,3.523Zm132.381,50c21.048,4.953 31.81,16.953 31.81,35.524c0.095,7.619 -0.286,9.238 -3.238,15.238c-3.524,7.238 -9.905,13.334 -17.239,16.667c-4.285,1.905 -4.285,1.905 0.096,3.143c19.904,5.238 31.428,27.714 25.047,48.762c-3.809,12.761 -12.381,20.571 -28.095,25.809c-8.476,2.762 -8.476,2.762 -45.238,3.143c-36.762,0.381 -36.762,0.381 -36.762,-63.81c0,-74.095 0.857,-67.618 -10.667,-77.333c-3.999,-3.333 -7.333,-6.666 -7.333,-7.333c0,-2 83.143,-1.81 91.619,0.19Zm-44.19,83.81c-0.286,0.666 -0.381,10 -0.191,20.762c0.286,19.428 0.286,19.428 18.286,19.428c21.238,-0.095 26.762,-1.524 32.857,-8.666c10.667,-12.381 2.667,-28.286 -15.809,-31.715c-8.667,-1.619 -34.477,-1.428 -35.143,0.191Zm0,-60.095c-0.286,0.762 -0.381,8.762 -0.191,17.809c0.286,16.476 0.286,16.476 17.81,16.476c17.238,0 17.524,0 22.762,-2.762c12.381,-6.476 12.19,-23.142 -0.286,-29.619c-4.762,-2.476 -6,-2.571 -22.286,-2.952c-13.905,-0.286 -17.333,0 -17.809,1.048Z`,
  ],
}

export default faBitdefenderLogo
