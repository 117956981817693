import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"

const StyledTickText = styled.text`
  fill: ${({ theme }) => theme.colorTextWeak};
  font-size: ${tokens.typography.fontSize.bodyXs};
  line-height: ${tokens.typography.lineHeight};
`

const AreaChartTick = ({ x, y, value, textAnchor }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <StyledTickText x={0} y={0} dy={16} textAnchor={textAnchor}>
        {value}
      </StyledTickText>
    </g>
  )
}

export default AreaChartTick
