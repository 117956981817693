import { useEffect } from "react"
import { useMountedState } from "js/includes/common/hooks"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"

export const useUsersByType = (skipRequest = false, userType = "TECHNICIAN") => {
  const [users, setUsers] = useMountedState([])
  const [loadingUsers, setLoadingUsers] = useMountedState(false)

  useEffect(() => {
    if (skipRequest) {
      return
    }

    const fetchUsers = async () => {
      setLoadingUsers(true)
      try {
        const response = await fetchJson(`/appuser?userType=${userType}`)
        return setUsers(response)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoadingUsers(false)
      }
    }

    fetchUsers()
  }, [skipRequest, userType, setLoadingUsers, setUsers])

  return { users, loadingUsers }
}
