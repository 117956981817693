import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Select from "react-select"
import { modeOptions, modeKeysToObject } from "../../../conditionData"
import { keysFromSeleted, arrayFromKeys } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import { map } from "ramda"

export default memo(({ values, onChange, validation, validateForm }) => (
  <FormGroup controlId="modeSelect" validationState={validation.success.mode === false ? "error" : null}>
    <Col componentClass={ControlLabel} sm={4}>
      {localized("Mode")}
    </Col>
    <Col sm={8}>
      <Select
        defaultValue={arrayFromKeys(values.mode, modeKeysToObject)}
        className="flex-full"
        isClearable={false}
        isSearchable={false}
        options={map(option => {
          return { ...option, label: option.label() }
        }, modeOptions)}
        onChange={selected => onChange("mode", keysFromSeleted(selected || []))}
        onBlur={() => validateForm(["mode"])}
        isMulti
        closeMenuOnSelect={false}
      />
      <em className="invalid m-t-none">{validation.message.mode}</em>
    </Col>
  </FormGroup>
))
