import { memo, useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { dissoc, map } from "ramda"
import { AlertMessage, Modal, StyledButtonLink, Text } from "@ninjaone/components"
import { spacing, typography } from "@ninjaone/tokens"
import { getMdmManagedConfigurationsWebToken } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { Box } from "js/includes/components/Styled"
import {
  localizationKey,
  localized,
  localizedWith,
  reportErrorAndShowMessage,
  showSuccessMessage,
  showWarningMessage,
} from "js/includes/common/utils"
import { loadGoogleIframe } from "js/includes/common/utils/mdmGoogleIframe"

export const OldManagedConfigsAlert = memo(({ connectionId, packageName, templateId, setManagedConfigurations }) => {
  const openManagedConfigurationIframe = () => {
    showModal(
      <ManagedConfigurationsIframeModal
        {...{
          connectionId,
          packageName,
          templateId,
          onOldSettingDeleted: () =>
            setManagedConfigurations(
              map(config => (config.connectionId === connectionId ? dissoc("templateId", config) : config)),
            ),
        }}
      />,
      { withProvider: true },
    )
  }

  return (
    <AlertMessage>
      {localizedWith(
        "This is the new Managed Configuration experience. <%boldText>Any changes made below will overwrite previous configurations saved for this app<%>. If you make no changes to the options below, the current configuration will stay active. To see the current managed configuration settings applied to this app, <%linkButton>click here<%>.",
        {
          boldText: ({ localizedText }) => (
            <Text as="strong" type="body" fontWeight={typography.fontWeight.medium} textWrap>
              {localizedText}
            </Text>
          ),
          linkButton: ({ localizedText }) => (
            <StyledButtonLink
              onClick={openManagedConfigurationIframe}
              aria-label={localized("View current managed configuration settings")}
            >
              {localizedText}
            </StyledButtonLink>
          ),
        },
      )}
    </AlertMessage>
  )
})

const containerId = "google-play-iframe-package-container"

const ManagedConfigurationsIframeModal = ({ unmount, connectionId, packageName, templateId, onOldSettingDeleted }) => {
  const hostname = useSelector(({ websiteBranding: { hostname } }) => hostname)

  const loadManagedConfigIframe = useCallback(async () => {
    try {
      const { tokenValue } = await getMdmManagedConfigurationsWebToken(hostname, connectionId)
      const url = new URL("https://play.google.com/managed/mcm")
      url.searchParams.append("token", tokenValue)
      url.searchParams.append("packageName", packageName)
      url.searchParams.append("mcmId", templateId)
      url.searchParams.append("canDelete", true)

      const iframe = await loadGoogleIframe({
        url: url.toString(),
        containerId,
        attributes: { scrolling: "no", style: "height:700px" },
      })
      iframe.register(
        "onconfigupdated",
        configured => {
          if (configured?.mcmId) {
            showWarningMessage(
              localized(
                "Settings have been saved, but changes won't appear in the new configuration interface. Please use the new interface for future updates.",
              ),
            )
          }
        },
        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
      )
      iframe.register(
        "onconfigdeleted",
        deleted => {
          if (deleted?.mcmId) {
            unmount()
            showSuccessMessage(localized("Old settings have been deleted"))
            onOldSettingDeleted()
          }
        },
        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER,
      )
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Managed configurations could not be loaded"))
    }
  }, [hostname, connectionId, packageName, templateId, onOldSettingDeleted, unmount])

  useEffect(() => {
    loadManagedConfigIframe()
  }, [loadManagedConfigIframe])

  return (
    <Modal
      {...{ unmount }}
      titleGroup={{ titleText: localized("Current managed configurations"), size: "sm" }}
      size="md"
      scrollableMaxHeight={800}
      withCloseX
      scrollable
    >
      <Box id={containerId} marginTop={spacing[8]} />
    </Modal>
  )
}
