import moment from "moment"
import { useState } from "react"
import tokens from "@ninjaone/tokens"
import { AlertMessage, DatePickerStartEndModal, Text } from "@ninjaone/components"
import { Box, Flex } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import {
  convertDateRangeToFreezePeriod,
  DAYS_THRESHOLD,
  getDateFromPeriod,
  getDiffDatesinDays,
  MAX_FREEZE_PERIOD,
} from "../../util"
import FreezePeriodCustomDropdown from "./FreezePeriodCustomDropdown"

function FreezePeriodModal({ validateFreezePeriod, onClickAdd, freezePeriods, unmount, startDateSelectedDay }) {
  const currentYear = new Date().getFullYear()
  const [isValid, setIsValid] = useState(false)

  const TopRender = () => {
    return (
      <Box marginTop={`-${tokens.spacing[5]}`}>
        <AlertMessage variant="info">
          <Flex gap={1}>
            <Text
              textWrap
              type="headingXs"
              token={localizationKey("Freeze periods can last up to 90 days, with a minimum 60-day gap between them.")}
            />
          </Flex>
        </AlertMessage>
      </Box>
    )
  }

  const BottomRender = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return
    const validation = validateFreezePeriod(startDate, endDate)
    const dateRangeToFreezePeriod = convertDateRangeToFreezePeriod({ from: startDate, to: endDate })
    const diffDays = getDiffDatesinDays(dateRangeToFreezePeriod.startDate, dateRangeToFreezePeriod.endDate)
    return (
      <Box>
        <Text type="headingXs">
          {localized("Current selected range: {{diffDays}} {{days}}", {
            diffDays: diffDays,
            days: diffDays > 1 ? localized("Days").toLowerCase() : localized("Day").toLowerCase(),
          })}
        </Text>

        {!validation.valid && (
          <Box marginTop={4}>
            <AlertMessage variant="danger">
              <Flex gap={1}>
                <Text textWrap type="headingXs" token={localizationKey(validation.message)} />
              </Flex>
            </AlertMessage>
          </Box>
        )}
      </Box>
    )
  }

  const handleChange = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      setIsValid(validateFreezePeriod(startDate, endDate).valid)
    }
  }

  const resolveDisabledDays = () => {
    return freezePeriods.flatMap(p => {
      const disableDateRangeFromCurrentYear = {
        from: getDateFromPeriod({ ...p.startDate, year: new Date().getFullYear() }, -DAYS_THRESHOLD),
        to: getDateFromPeriod({ ...p.endDate, year: new Date().getFullYear() }, DAYS_THRESHOLD + 1),
      }
      const disableDateRangeFromNextYear = {
        from: getDateFromPeriod({ ...p.startDate, year: new Date().getFullYear() + 1 }, -DAYS_THRESHOLD),
        to: getDateFromPeriod({ ...p.endDate, year: new Date().getFullYear() + 1 }, DAYS_THRESHOLD + 1),
      }
      return [disableDateRangeFromCurrentYear, disableDateRangeFromNextYear]
    })
  }

  return (
    <DatePickerStartEndModal
      modalTitleText={localized("Add freeze periods")}
      modalDescriptionText={localized("Select a start and end date for the freeze period.")}
      topRenderer={TopRender}
      bottomRenderer={BottomRender}
      fromMonth={new Date(currentYear, 0, 31)}
      toMonth={new Date(currentYear + 1, 2, 31)}
      onEndDayChange={handleChange}
      onStartDayChange={handleChange}
      startDateRequired
      endDateRequired
      excludeYear
      updateDisabledDays={({ startDate, endDate }) => {
        if (startDate)
          return moment(startDate)
            .add(MAX_FREEZE_PERIOD - 1, "days")
            .toDate()
        if (endDate)
          return moment(endDate)
            .subtract(MAX_FREEZE_PERIOD - 1, "days")
            .toDate()
        else return null
      }}
      disabledDays={resolveDisabledDays()}
      onSave={onClickAdd}
      saving={!isValid}
      renderCustomDropdownNavigation={({ date, locale, onChange, toMonth }) => (
        <FreezePeriodCustomDropdown {...{ date, locale, onChange, toMonth }} />
      )}
      unmount={unmount}
      startDateSelectedDay={startDateSelectedDay}
    />
  )
}

export default FreezePeriodModal
