export const conditionControlCode = {
  SelectCondition: 0,
  CPU: 1,
  Memory: 2,
  DiskUsage: 4,
  DataStoreFreeSpace: 5,
  VMHostUptime: 6,
  BadSensorCount: 7,
  DeviceDown: 8,
  SensorHealth: 9,
  ProcessorUsage: 10,
  GuestOperationMode: 11,
  SnapshotSize: 12,
  SnapshotLifeSpan: 13,
  GuestToolsNotRunning: 14,
  CheckpointSize: 15,
  CheckpointLifeSpan: 16,
  BatteryMonitoring: 42,
  AVHealth: 43,
  RebootPending: 44,
}

export const defaultConditionUids = {
  AVHealth: "e8e937ed-1c11-454e-9ba8-e6a52174abd4",
  CPU: "61147e9c-c5bd-4f3a-ac98-b5ecfc9ab579",
  BatteryMonitoring: "6a626396-d869-43d9-b813-e21422f6bbea",
  Memory: "223bbcc1-83d4-48a0-8ae5-546573b61fea",
  DiskUsage: "916dfc35-2a97-460c-ae26-99d175d76330",
  DataStoreFreeSpace: "88c40df8-39e6-4515-8480-b59135544b9e",
  VMHostUptime: "5b05ab7b-89de-4187-87bd-07dbc0184ee0",
  BadSensorCount: "a9b92077-0559-4dfd-8938-382d9f5d9159",
  DeviceDown: "ce4aef53-49ec-44ad-88b9-93592bed0db8",
  SensorHealth: "61db8326-2381-40b5-9e7d-ef5d0a527ac7",
  ProcessorUsage: "7f1bc352-0716-4f06-8b71-b6187ff2bd82",
  GuestOperationMode: "861540de-3d6a-4390-9aa3-48dcf362f788",
  SnapshotSize: "6b85fad6-576c-4278-b3ea-858907360ecb",
  SnapshotLifeSpan: "572b40b2-fd8a-44e4-94a8-a60861ae4b3b",
  CheckpointSize: "8eaafe10-953d-48c2-9f12-eb1d38b6ba8c",
  CheckpointLifeSpan: "69da5fcc-28a3-46fb-9618-75661c8763b0",
  GuestToolsNotRunning: "8be9b4fd-4767-4bc8-aa3b-a9e34e816a11",
  PatchCVSSScore: "a4f64d2f-6cb6-4ad0-b101-e7be7e496b59",
  RebootPending: "0ef5238c-0bb5-4bb9-9656-fbc467c70d47",
}

let defaultConditionUidsReverse = {}
let defaultConditionNames = {}
let defaultConditionUidsByCode = {}

for (var key in defaultConditionUids) {
  if (defaultConditionUids.hasOwnProperty(key)) {
    defaultConditionUidsReverse[defaultConditionUids[key]] = key
    defaultConditionNames[conditionControlCode[key]] = key
    defaultConditionUidsByCode[conditionControlCode[key]] = defaultConditionUids[key]
  }
}

export { defaultConditionUidsReverse, defaultConditionNames, defaultConditionUidsByCode }
