import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Flex } from "js/includes/components/Styled"

export const StyledRow = styled(Flex)`
  padding: ${({ padding }) => padding ?? `${tokens.spacing[4]} ${tokens.spacing[3]}`};
  width: 100%;
  &:hover {
    background: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  }
`
