// TODO - move these into their own JS files and namespace appropriately
import Backbone from "backbone"

const DataspecModel = Backbone.Model.extend({
  urlRoot: "/webapp/dataspec",

  getDataType: function() {
    return this.get("dataspecType")
  },

  getKeyColumns: function() {
    return this.get("keyColumns")
  },

  getStaticColumns: function() {
    return this.get("staticColumns")
  },

  getIndependentColumns: function() {
    return this.get("independentColumns")
  },
})

export const DataspecCollection = Backbone.Collection.extend({
  model: DataspecModel,

  parse: function(response) {
    if (response.resultCode === "SUCCESS") {
      return response.dataspecs
    } else {
      console.log("Error parsing dataspecs")
    }
  },
})

export const TeamViewerConfig = Backbone.Model.extend({
  urlRoot: "/webapp/teamviewerconfig",

  parse: function(response) {
    if (response.resultCode === "SUCCESS") {
      return response.config
    } else if (window.teamViewerSystemConfig.get("teamViewerDivisionConfig").get("configSource")) {
      console.log("Error parsing TeamViewer config")
    }
  },
})

export const AvailableLocales = Backbone.Collection.extend()
