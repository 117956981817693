import { commonFileOptionsType } from "./constants"

export const windowsUserProfileOptions: Array<commonFileOptionsType> = [
  "myDesktop",
  "myDocuments",
  "myDownloads",
  "myMusic",
  "myPictures",
  "myVideos",
]

export const windowsPublicProfileOptions: Array<commonFileOptionsType> = [
  "sharedDesktop",
  "sharedDocuments",
  "sharedDownloads",
  "sharedMusic",
  "sharedPictures",
  "sharedVideos",
]

export const windowsGeneralPublicOptions: Array<commonFileOptionsType> = ["sharedAppData", "startMenuPrograms"]

export const windowsGeneralUserProfileOptions: Array<commonFileOptionsType> = ["myLocalAppData", "applicationData"]

export const windowsCommonFolderOptions = [
  "userProfile",
  ...windowsUserProfileOptions,

  "allUsersProfile",
  ...windowsPublicProfileOptions,

  ...windowsGeneralPublicOptions,
]

const windowsCommonFolderTestOptions: Array<{ test: (path: string) => boolean; code: commonFileOptionsType }> = [
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Documents/i.test(path),
    code: "sharedDocuments",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Pictures/i.test(path),
    code: "sharedPictures",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Desktop/i.test(path),
    code: "sharedDesktop",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public/i.test(path),
    code: "sharedUsersProfile",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Desktop/i.test(path),
    code: "myDesktop",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Pictures/i.test(path),
    code: "myPictures",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Documents/i.test(path),
    code: "myDocuments",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}AppData[/|\\]{1}Local/i.test(path),
    code: "myLocalAppData",
  },
  {
    test: path =>
      /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}AppData[/|\\]{1}Roaming[/|\\]{1}Microsoft[/|\\]{1}Windows[/|\\]{1}Start Menu[/|\\]{1}Programs/i.test(
        path,
      ),
    code: "startMenuPrograms",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}AppData[/|\\]{1}Roaming/i.test(path),
    code: "applicationData",
  },
  {
    test: path => /C:[/|\\]{1}Users/i.test(path),
    code: "allUsersProfile",
  },
  {
    test: path => /C:[/|\\]{1}ProgramData/i.test(path),
    code: "sharedAppData",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Downloads/i.test(path),
    code: "sharedDownloads",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Music/i.test(path),
    code: "sharedMusic",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}Public[/|\\]{1}Videos/i.test(path),
    code: "sharedVideos",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Downloads/i.test(path),
    code: "myDownloads",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Music/i.test(path),
    code: "myMusic",
  },
  {
    test: path => /C:[/|\\]{1}Users[/|\\]{1}(?!Public)\w[\w\s*]+[/|\\]{1}Videos/i.test(path),
    code: "myVideos",
  },
]

export const getWindowsCommonFolderCode = (path: string) =>
  windowsCommonFolderTestOptions.find(r => r.test(path.trim()))?.code
export const getWindowsCommonFolderTest = (code: commonFileOptionsType) =>
  windowsCommonFolderTestOptions.find(r => r.code === code)?.test
