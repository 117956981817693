import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Flex } from "js/includes/components/Styled"
import { localizationKey } from "js/includes/common/utils"
import SectionTitle from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/SectionTitle.js"
import ManuallyScheduledUpdates from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/enforcedUpdatesComponents/ManuallyScheduledUpdates"

const enforcedUpdatesPath = "patchOverrides"

const EnforcedUpdates = ({ patchOverrides, onPolicyChange }) => {
  const onSettingsChange = setting => {
    onPolicyChange(enforcedUpdatesPath, setting)
  }

  return (
    <Flex padding={tokens.spacing[6]}>
      <Flex width="100%" flexDirection="column" gap={tokens.spacing[2]}>
        <ManuallyScheduledUpdates
          {...{
            updates: patchOverrides,
            onChange: onSettingsChange,
            LabelComponent: () => (
              <SectionTitle
                titleToken={localizationKey("Enforced updates")}
                tooltipToken={localizationKey(
                  "Control when and how updates are enforced on devices. When a deadline is reached, device may be forced to restart.",
                )}
              />
            ),
          }}
        />
      </Flex>
    </Flex>
  )
}

EnforcedUpdates.propTypes = {
  patchOverrides: PropTypes.object.isRequired,
}

export default EnforcedUpdates
