import { fetchJson } from "js/includes/common/utils"

export const deleteRecentTab = tab =>
  fetchJson(`/appuser/tabs/recents/${tab.id}/${tab.type}`, {
    options: {
      method: "DELETE",
    },
  })

export const createRecentTabItem = ({ entityId, type }) =>
  fetchJson(`/appuser/tabs/recents/${entityId}/${type}`, {
    options: {
      method: "POST",
    },
  })
