import $ from "jquery"
import _ from "underscore"
import Singleton from "./Singleton"
import { getDefaultSystemActivitiesJSON } from "../../includes/configuration/activities/DefaultSystemActivitiesJSON"
import { showErrorMessage, localized } from "js/includes/common/utils"

const DivisionConfig = Singleton.extend({
  isNoInstanceSingletonBaseClass: true,

  initialize: function() {
    _.bindAll(this, "save")
  },

  parse: function(response) {
    if (response.resultCode === "SUCCESS") {
      return response.content
    } else {
      console.log("Error fetching division config: " + this.url)
    }
  },

  save: function() {
    var self = this
    return $.ajax({
      url: self.url,
      type: "PUT",
      dataType: "json",
      contentType: "application/json",
      data: '{"content": ' + JSON.stringify(self) + "}",
      success: function(response, textStatus, jqXHR) {
        if (response.resultCode === "SUCCESS") {
        } else {
          showErrorMessage(`${localized("Error updating division config:")} ${this.url}`)
        }
      },
      error: function(jqXHR, textStatus, errorThrown) {
        showErrorMessage(`${localized("Error updating division config:")} ${this.url}`)
      },
    })
  },
})

export const TeamViewerDivisionConfig = DivisionConfig.extend({
  singletonId: "TeamViewerDivisionConfig",

  // The idAttribute is necessary for DELETE requests to be made
  idAttribute: "configSource",

  // We can't use a urlRoot. This is to prevent the idAttribute getting appeded to the url upon a DELETE.
  url: "/divisionconfig/teamviewer",

  initialize: function() {
    this.constructor.__super__.initialize.apply(this, undefined)
  },
})

export const ActivitiesDivisionConfig = DivisionConfig.extend({
  singletonId: "ActivitiesDivisionConfig",

  url: "/divisionconfig/system_conditions",

  parse: function(response) {
    if (response.resultCode === "SUCCESS") {
      if (response.content) {
        return response.content
      } else {
        // If system activities aren't setup, show the defaults
        return getDefaultSystemActivitiesJSON()
      }
    } else {
      console.log("Error fetching system activities")
    }
  },
})
