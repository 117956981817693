import styled from "@emotion/styled"
import { FilterIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import Text from "./Text"
import Select from "./Selects/Select"
import { localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

const StyledFilterText = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: ${sizer(2)};
  > * {
    display: flex;
  }
`

const StyledFilterIcon = styled(FilterIcon)`
  color: ${({ theme }) => theme.color.gray};
`

export default function MultiSelectFilter({ ariaLabel, isFiltering, noFilterToken, onChange, options, value = [] }) {
  return (
    <Select
      isMulti
      size="sm"
      triggerAriaLabel={ariaLabel}
      labelRenderer={() => (
        <StyledFilterText>
          <StyledFilterIcon />
          <span>
            {isFiltering ? (
              <Text size="sm" tokenArgs={{ count: value.length }} token={localizationKey("{{count}} Filter(s)")} />
            ) : (
              <Text size="sm" token={noFilterToken} />
            )}
          </span>
        </StyledFilterText>
      )}
      {...{
        options,
        value,
        onChange,
      }}
    />
  )
}
