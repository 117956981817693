import moment from "moment"

export const NINETY_ONE_DAYS_THRESHOLD = 91
export const DAYS = "days"

export const isOldAccount = (accountCreationTime, unit, threshold) => {
  const midnightDate = moment.unix(accountCreationTime).startOf("day")
  return (
    moment()
      .startOf("day")
      .diff(midnightDate, unit) >= threshold
  )
}
