import { compose, filter, join, map, propEq } from "ramda"
import { Tooltip } from "@ninjaone/components"
import { ellipsis, isNilOrEmpty, isNotNilOrEmpty } from "js/includes/common/utils"
import { getChannelTypeName } from "js/includes/components/Notifications/common"
import { Box } from "js/includes/components/Styled"

const NotificationChannelSummary = ({ channelIds, channelList, maxTextLength = 100 }) => {
  const channelSummary = compose(
    join(", "),
    filter(isNotNilOrEmpty),
    map(channelId => {
      const channel = channelList.find(propEq("id", channelId))
      return isNilOrEmpty(channel) ? "" : `${channel.name} (${getChannelTypeName(channel.type)})`
    }),
  )(channelIds)

  return channelSummary.length > maxTextLength ? (
    <Tooltip label={channelSummary} className="white-space-pre-line">
      {ellipsis(channelSummary, maxTextLength)}
    </Tooltip>
  ) : (
    <Box>{channelSummary}</Box>
  )
}

export default NotificationChannelSummary
