import { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import NumberStepper from "js/includes/editors/Policy/PolicyEditor/formComponents/NumberStepper"

const MAX_VALUE = 10.0
const MIN_VALUE = 0.0
const STEPPER = 0.1
const DIGITS = 1

const ThresholdStepperCVSSScore = memo(({ values, onChange, minValue = MIN_VALUE, maxValue = MAX_VALUE }) => (
  <FormGroup controlId="thresholdCVSSScore">
    <Col componentClass={ControlLabel} sm={4}>
      {localized("Threshold CVSS Score")}
    </Col>
    <Col sm={8}>
      <NumberStepper
        minValue={MIN_VALUE}
        maxValue={MAX_VALUE}
        stepper={STEPPER}
        digits={DIGITS}
        initialValue={parseFloat(values.threshold).toFixed(DIGITS)}
        onChange={value => onChange("threshold", parseFloat(value))}
        isFloat
      />
    </Col>
  </FormGroup>
))

export default ThresholdStepperCVSSScore
