import { Suspense } from "react"
import { $applyNodeReplacement, DecoratorNode } from "lexical"

import { ImageComponent } from "./Component"

function convertImageElement(domNode) {
  const img = domNode
  if (img.src.startsWith("file:///")) return null

  const { src, width, height } = img
  const node = $createImageNode({ height, src, width })
  return { node }
}

export class ImageNode extends DecoratorNode {
  __cid
  __src
  __width
  __height
  __maxWidth

  constructor(cid, src, maxWidth, width, height, key) {
    super(key)
    this.__cid = cid
    this.__src = src
    this.__maxWidth = maxWidth
    this.__width = width || "inherit"
    this.__height = height || "inherit"
  }

  static getType() {
    return "image"
  }

  static clone(node) {
    return new ImageNode(node.__cid, node.__src, node.__maxWidth, node.__width, node.__height, node.__key)
  }

  static importJSON(serializedNode) {
    const { height, width, maxWidth, src, cid } = serializedNode
    const node = $createImageNode({
      cid,
      height,
      maxWidth,
      src,
      width,
    })
    return node
  }

  exportDOM() {
    const element = document.createElement("img")
    element.setAttribute("data-cid", this.__cid)
    // TODO: Figure out if we could remove "src" and have the server use "data-cid" instead.
    element.setAttribute("src", this.getSrc())
    element.setAttribute("width", this.getWidth().toString())
    element.setAttribute("height", this.getHeight().toString())
    return { element }
  }

  static importDOM() {
    return {
      img: node => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    }
  }

  exportJSON() {
    return {
      src: this.getSrc(),
      cid: this.getCid(),
      maxWidth: this.getMaxWidth(),
      height: this.getHeight() === "inherit" ? 0 : this.getHeight(),
      width: this.getWidth() === "inherit" ? 0 : this.getWidth(),
      type: "image",
      version: 1,
    }
  }

  getSrc() {
    const self = this.getLatest()
    return self.__src
  }

  getCid() {
    const self = this.getLatest()
    return self.__cid
  }

  getWidth() {
    const self = this.getLatest()
    return self.__width
  }

  getHeight() {
    const self = this.getLatest()
    return self.__height
  }

  getMaxWidth() {
    const self = this.getLatest()
    return self.__maxWidth
  }

  setCid(cid) {
    const self = this.getWritable()
    self.__cid = cid
  }

  setSrc(src) {
    const self = this.getWritable()
    self.__src = src
  }

  setMaxWidth(maxWidth) {
    const self = this.getWritable()
    self.__maxWidth = maxWidth
  }

  setWidthAndHeight(width, height) {
    const self = this.getWritable()
    self.__width = width
    self.__height = height
  }

  // View

  createDOM(config) {
    const span = document.createElement("span")
    const className = config.theme.image
    if (className) {
      span.className = className
    }
    return span
  }

  updateDOM() {
    return false
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          cid={this.getCid()}
          maxWidth={this.getMaxWidth()}
          nodeKey={this.getKey()}
          src={this.getSrc()}
          width={this.getWidth()}
          height={this.getHeight()}
          resizable={true}
        />
      </Suspense>
    )
  }
}

export function $createImageNode({ key, cid, src, height, width, maxWidth = 1000 }) {
  return $applyNodeReplacement(new ImageNode(cid, src, maxWidth, width, height, key))
}

export function $isImageNode(node) {
  return node instanceof ImageNode
}
