import moment from "moment"
import { map, omit } from "ramda"
import { fetchJson, ninjaReportError, showErrorMessage, localized, showSuccessMessage } from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { addRecentTab } from "js/state/actions/general/recentTabs"
import { getOrganizationAndLocation } from "js/includes/common/client"

export const clearNodeData = () => dispatch =>
  dispatch({
    type: "CLEAR_NODE_DATA",
  })

export const fetchNodeData = async (deviceId, dispatch, actionName) => {
  const [nodeResponse] = await Promise.all([
    await fetchJson(`/node/${deviceId}`),
    window.deviceList.fetchById(deviceId),
  ])

  if (nodeResponse.resultCode === "SUCCESS") {
    const { node, healthStatus } = nodeResponse

    const { organization, location } = await getOrganizationAndLocation(node)
    const isPolicySetFromDeviceSettings = node.policyIdSource === "NODE"

    dispatch(addRecentTab({ item: node, type: "DEVICE" }))

    dispatch({
      type: actionName,
      node: {
        ...node,
        healthStatus,
        isPolicySetFromDeviceSettings,
      },
      organization,
      location,
    })
  } else {
    throw new NinjaResponseError(nodeResponse)
  }
}

export const fetchNodeDash = async (deviceId, datasets, dispatch, nodeActionName, datasetsActionName) => {
  try {
    dispatch(startLoading("loadingVmDashboardData")())
    dispatch({ type: "CLEAR_STATUS" })
    dispatch({ type: "CLEAR_NODE_DATA" })

    await fetchNodeData(deviceId, dispatch, nodeActionName)
    await fetchNodeDataspecs(deviceId, datasets, dispatch, datasetsActionName)
  } catch (error) {
    showErrorMessage(localized("Error while fetching data"))
    ninjaReportError(error)
  } finally {
    dispatch(stopLoading("loadingVmDashboardData")())
  }
}

export const fetchNodeDataspecs = async (deviceId, datasets, dispatch, datasetsActionName) => {
  const response = await fetchJson(`/webapp/nodedash/${deviceId}`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        since: moment().unix() - 3600,
        dataspecs: datasets,
      }),
    },
  })

  const { resultCode, node } = response
  if (resultCode === "SUCCESS") {
    const { datasets, agentVersion } = node

    dispatch({
      type: datasetsActionName,
      datasets: dataspecHash(datasets),
      agentVersion,
    })
  } else {
    throw new NinjaResponseError(response)
  }
}

export const fetchDeviceStatus = async (deviceId, dispatch, actionName) => {
  try {
    const response = await fetchJson(`/query/nodestatus/${deviceId}`, { options: { method: "GET" } })

    if (response.resultCode === "SUCCESS") {
      const { status } = response
      const { triggeredConditions, activeJobs, maintenanceMode } = status
      dispatch({
        type: actionName,
        triggeredConditions,
        activeJobs,
        maintenanceMode,
      })
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    throw error
  }
}

export const actionCall = async (deviceId, actionId, parameters) => {
  await fetchJson(`/node/${deviceId}/action`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        uid: actionId,
        parameters,
      }),
    },
  })
}

export const clearDevicePolicyOverrides = async (deviceId, node, dispatch, actionName) => {
  showSuccessMessage(localized("Saving..."))
  const data = omit(["policyContent"], node)
  try {
    const response = await fetchJson(`/node/${deviceId}`, {
      options: {
        method: "PUT",
        body: JSON.stringify({
          ...data,
        }),
      },
      useMfaUrl: true,
    })
    if (response.resultCode === "SUCCESS") {
      await fetchNodeData(deviceId, dispatch, actionName)
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localized("Error removing override"))
    ninjaReportError(error)
  }
}

export const updateDevicePolicy = async (
  deviceId,
  node,
  policyId,
  revertToOrganizationPolicy,
  dispatch,
  actionName,
) => {
  showSuccessMessage(localized("Saving..."))
  let omitValues = ["policyContent"]
  let data = { ...node, policyId }

  if (revertToOrganizationPolicy) {
    omitValues.push("policyId")
  }

  data = omit(omitValues, data)

  try {
    const response = await fetchJson(`/node/${deviceId}`, {
      options: {
        method: "PUT",
        body: JSON.stringify({
          ...data,
        }),
      },
      useMfaUrl: true,
    })
    if (response.resultCode === "SUCCESS") {
      await window.deviceList.fetchById(deviceId)

      await fetchNodeData(deviceId, dispatch, actionName)
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localized("Error saving/updating"))
    ninjaReportError(error)
  }
}

export const dataspecHash = datasets => {
  let _datasets = {}
  map(d => (_datasets[d.dataspecName] = d), datasets)
  return _datasets
}
