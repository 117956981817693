import styled from "@emotion/styled"

import { colors } from "js/includes/common/theme"
import { useLongPress } from "js/includes/editors/Policy/PolicyEditor/formComponents/useLongPress"

const StyledAction = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  font-size: 20px;
  color: ${colors.ninjaWhite};
  background-color: ${colors.ninjaLight};

  &:hover {
    cursor: pointer;
  }
`

export const StepperButton = ({ children, onClick, onLongPress, onLongPressEnd }) => {
  const { onClick: handleClick, onLongPress: handleLongPress, onLongPressEnd: handleLongPressEnd } = useLongPress(
    onClick,
    onLongPress,
    onLongPressEnd,
  )

  return (
    <StyledAction
      onClick={handleClick}
      onMouseDown={handleLongPress}
      onMouseUp={handleLongPressEnd}
      onMouseLeave={handleLongPressEnd}
      onTouchStart={handleLongPress}
      onTouchEnd={handleLongPressEnd}
    >
      {children}
    </StyledAction>
  )
}
