import { isOnExclusionList } from "js/includes/editors/Policy/Sections/Backups/common/validation"
import { reject, groupBy } from "ramda"
import { containsAny } from "../utils"

export const normalizeInt = int => {
  const num = parseInt(int, 10)
  return num > 0 ? num : 0
}

export const normalizeSchedule = (newSchedule, { schedule }) => ({
  ...newSchedule,
  hour: parseInt(newSchedule.hour, 10),
  minute: parseInt(newSchedule.minute, 10),
  dayOfMonth: parseInt(newSchedule.dayOfMonth, 10),
  weekDays: !newSchedule.weekDays.length ? schedule.weekDays : newSchedule.weekDays,
})

export const isMaskInvalid = mask =>
  mask === "*" || mask === "*.*" || containsAny(["\\", "/", ":", "?", '"', "<", ">", "|"], mask)

export const normalizeExclusions = ({ includeMasks, excludeMasks, ...rest }) => ({
  ...rest,
  excludeMasks: reject(isMaskInvalid, excludeMasks),
  includeMasks: reject(isMaskInvalid, includeMasks),
})

export const normalizeRevisions = (revisions, { type }) => ({
  ...revisions,
  limitRevisionsTo: {
    ...revisions.limitRevisionsTo,
    value: normalizeInt(revisions.limitRevisionsTo.value),
  },
  removeVersionsOlderThan: {
    ...revisions.removeVersionsOlderThan,
    value: normalizeInt(revisions.removeVersionsOlderThan.value),
  },
  ...(type === "image" && {
    takeFullEvery: {
      ...revisions.takeFullEvery,
      value: normalizeInt(revisions.takeFullEvery.value),
    },
  }),
  ...(type === "fileFolder" && {
    removeDeletedLocalFilesOlderThan: {
      ...revisions.removeDeletedLocalFilesOlderThan,
      value: normalizeInt(revisions.removeDeletedLocalFilesOlderThan.value),
    },
  }),
})

export const normalizeLockhartFolderPaths = ({ common, paths = [] }, nodeRole) => {
  const { includedRegularPaths = [], exclusionOverridePaths = [] } = groupBy(
    path => (isOnExclusionList(path, nodeRole) ? "exclusionOverridePaths" : "includedRegularPaths"),
    paths,
  )

  return {
    common,
    paths: [...includedRegularPaths, ...exclusionOverridePaths],
  }
}
