import { isFeatureEnabled } from "js/includes/common/utils"

export const getDefaultSystemActivitiesJSON = () => ({
  systemConditions: [
    {
      notificationAction: "NONE",
      activityStatus: "CLIENT_CREATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CLIENT_UPDATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CLIENT_DELETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "POLICY_CREATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "POLICY_UPDATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "POLICY_DELETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "NODE_CREATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "NODE_UPDATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "NODE_RE_ENROLLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "NODE_DELETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "APP_USER_CREATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "APP_USER_UPDATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "APP_USER_DELETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "APP_USER_LOGGED_IN",
    },
    {
      notificationAction: "NONE",
      activityStatus: "ATTACHMENT_FILE_SUSPICIOUS",
    },
    {
      notificationAction: "NONE",
      activityStatus: "END_USER_CREATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "END_USER_UPDATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "END_USER_DELETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "END_USER_LOGGED_IN",
    },
    {
      notificationAction: "NONE",
      activityStatus: "PSA_TICKET_CREATION_TEST",
    },
    {
      notificationAction: "NONE",
      activityStatus: "PSA_TICKET_CREATION_FAILED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "PSA_TICKET_CREATION_SUCCEEDED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "RESET_BY_PSA_TICKET_CALLBACK",
    },
    {
      notificationAction: "NONE",
      activityStatus: "PSA_CREDENTIALS_FAILED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "PSA_STATUS_UNHEALTHY",
    },
    {
      notificationAction: "NONE",
      activityStatus: "PSA_STATUS_HEALTHY",
    },
    {
      notificationAction: "NONE",
      activityStatus: "PSA_STATUS_DEGRADED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CONNECTWISE_AGREEMENTS_SYNC_COMPLETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CONNECTWISE_NODE_SYNC_COMPLETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "NINJA_TICKETING_PENDING_EMAIL_RECEIVED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "SMTP_SEND_ERROR",
    },
    {
      notificationAction: "NONE",
      activityStatus: "LOCKHART_REVISIONS_DELETION_SCHEDULED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "LOCKHART_REVISIONS_DELETION_CANCELLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "LOCKHART_REVISIONS_DELETION_FORCED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "LOCKHART_REVISIONS_DELETION_COMPLETED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "API_WEBHOOK_FAILURE",
    },
    {
      notificationAction: "NONE",
      activityStatus: "API_WEBHOOK_AUTOMATICALLY_DISABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "SENTINEL_ONE_INTEGRATION_ENABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "SENTINEL_ONE_INTEGRATION_DISABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "SENTINEL_ONE_INTEGRATION_CREDENTIALS_UPDATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "SENTINEL_ONE_INTEGRATION_RESYNC_REQUIRED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "SENTINEL_ONE_INTEGRATION_RESYNC_INITIATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CROWDSTRIKE_ENABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CROWDSTRIKE_DISABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CROWDSTRIKE_CREDENTIALS_UPDATED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CROWDSTRIKE_CREDENTIAL_INVALID",
    },
    {
      notificationAction: "NONE",
      activityStatus: "CROWDSTRIKE_MISSING_API_CLIENT_SCOPE",
    },
    ...(isFeatureEnabled("ninja_psa")
      ? [
          {
            notificationAction: "NONE",
            activityStatus: "NINJA_PSA_INVOICE_CREATED",
          },
          {
            notificationAction: "NONE",
            activityStatus: "NINJA_PSA_INVOICE_CREATED_BY_TRIGGER",
          },
          {
            notificationAction: "NONE",
            activityStatus: "NINJA_PSA_INVOICE_EXPORTED_TO_QB",
          },
          {
            notificationAction: "NONE",
            activityStatus: "NINJA_PSA_INVOICE_ERROR_EXPORTING_TO_QB",
          },
          {
            notificationAction: "NONE",
            activityStatus: "NINJA_PSA_INVOICE_APPROVED",
          },
          {
            notificationAction: "NONE",
            activityStatus: "NINJA_PSA_INVOICE_UPDATED",
          },
          {
            notificationAction: "NONE",
            activityStatus: "NINJA_PSA_INVOICE_STATUS_RESET",
          },
        ]
      : []),
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_ENABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_DISABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_RAPID7_ENABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_RAPID7_DISABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_TENABLE_ENABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_TENABLE_DISABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_QUALYS_ENABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_QUALYS_DISABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_SPOTLIGHT_ENABLED",
    },
    {
      notificationAction: "NONE",
      activityStatus: "VULNERABILITY_MANAGEMENT_SPOTLIGHT_DISABLED",
    },
  ],
})
