import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { revertPolicySection as _revertPolicySection } from "js/state/actions/policyEditor/editor"
import InheritanceBox from "js/includes/components/InheritanceBox"

const Inheritable = Component =>
  class extends PureComponent {
    revert = e => {
      e.stopPropagation()
      const { revertPolicySection, pathToItem, inheritableItem } = this.props
      revertPolicySection(pathToItem, inheritableItem)
    }

    render() {
      const { inheritableItem } = this.props

      const {
        inheritance: { overridden, inherited },
      } = inheritableItem

      const inheritanceComponent = inherited ? <InheritanceBox {...{ overridden, revert: this.revert }} /> : null

      return (
        <Component
          {...{
            inheritanceComponent,
            ...this.props,
          }}
        />
      )
    }
  }

export default function ReduxInheritable(Component) {
  return connect(
    ({ policyEditor: { policy } }) => ({
      policy,
    }),
    {
      revertPolicySection: _revertPolicySection,
    },
  )(Inheritable(Component))
}
