import { localizationKey } from "js/includes/common/utils"

export const qcDeviceTypes = {
  ANDROID: "ANDROID",
  IOS: "IOS",
  LINUX: "LINUX",
  MAC: "MAC",
  WINDOWS: "WINDOWS",
}

export const qcDeviceTypeTokenMap = {
  [qcDeviceTypes.ANDROID]: localizationKey("Android mobile"),
  [qcDeviceTypes.IOS]: localizationKey("iOS mobile"),
  [qcDeviceTypes.LINUX]: localizationKey("Linux desktop"),
  [qcDeviceTypes.MAC]: localizationKey("Mac laptop"),
  [qcDeviceTypes.WINDOWS]: localizationKey("Windows desktop"),
}
