import { fetchJson } from "js/includes/common/utils"

export const getRelatedItems = (hostEntityType, hostEntityId) => {
  return fetchJson(`/related-items/${hostEntityType}/${hostEntityId}`)
}

export const saveRelatedItem = item => {
  return fetchJson(`/related-items`, {
    options: {
      method: item.id ? "PUT" : "POST",
      body: JSON.stringify(item),
    },
  })
}

export const getRelatedItemSecureValue = item => {
  return fetchJson(`/related-items/${item.id}/secure/value`)
}
