import { memo } from "react"
import styled from "@emotion/styled"
import { NativeTypes } from "react-dnd-html5-backend"
import { faPaperclip } from "@fortawesome/pro-light-svg-icons"
import { colors } from "js/includes/common/theme"
import DropZone from "js/includes/components/DropZone"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { faInlineImage } from "media/icons"
import { twentyMiB, acceptedAttachmentFiles, acceptedInlineFiles } from "js/includes/ticketing/editor/shared/constants"

const StyledDropContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 10000;
`

const StyledHoverTarget = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin: 20px;
`

const DragInline = ({ addInlineImage }) => {
  return (
    <StyledHoverTarget>
      <DropZone
        className="display-flex flex-full full-width"
        dropMessageToken={localizationKey("Drop as inline image here. Only the following types will be allowed:")}
        accepts={[NativeTypes.FILE]}
        validFileTypes={acceptedInlineFiles}
        icon={faInlineImage}
        alwaysShow
        light
        onFileRead={({ file }) => addInlineImage(file)}
        maxSize={twentyMiB}
      />
    </StyledHoverTarget>
  )
}

const DragAsAttachment = ({ addAttachment }) => {
  return (
    <StyledHoverTarget>
      <DropZone
        className="display-flex flex-full full-width"
        dropMessageToken={localizationKey("Drop as attachments here. Only the following types will be allowed:")}
        accepts={[NativeTypes.FILE]}
        validFileTypes={acceptedAttachmentFiles}
        icon={faPaperclip}
        alwaysShow
        light
        onFileRead={({ file }) => addAttachment(file)}
        maxSize={twentyMiB}
      />
    </StyledHoverTarget>
  )
}

export const AttachmentDragAndDrop = memo(({ addInlineImage, addAttachment }) => {
  return (
    <StyledDropContainer>
      <DragInline addInlineImage={addInlineImage} />
      <DragAsAttachment addAttachment={addAttachment} />
    </StyledDropContainer>
  )
})
