import { FORMAT_ELEMENT_COMMAND, INDENT_CONTENT_COMMAND, OUTDENT_CONTENT_COMMAND } from "lexical"

import {
  AlignCenterLightIcon,
  AlignJustifyLightIcon,
  AlignLeftLightIcon,
  AlignRightLightIcon,
  IndentLightIcon,
  OutdentLightIcon,
} from "@ninjaone/icons"
import { localizationKey, localized, localizedF } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../../../Text"

import { Dropdown, DROPDOWN_ICON_COLOR, DROPDOWN_TEXT_COLOR, DropdownItem } from "../../../Components"

import { StyledToolbarItem } from "../styled"

function getFormatOptions(value) {
  switch (value) {
    case "center":
      return {
        Icon: AlignCenterLightIcon,
        IconRTL: AlignCenterLightIcon,
        label: localizedF("Center Align"),
      }
    case "justify":
      return {
        Icon: AlignJustifyLightIcon,
        IconRTL: AlignJustifyLightIcon,
        label: localizedF("Justify Align"),
      }
    case "end":
      return {
        Icon: AlignRightLightIcon,
        IconRTL: AlignLeftLightIcon,
        label: localizedF("End Align"),
      }
    case "start":
      return {
        Icon: AlignLeftLightIcon,
        IconRTL: AlignRightLightIcon,
        label: localizedF("Start Align"),
      }
    case "right":
      return {
        Icon: AlignRightLightIcon,
        IconRTL: AlignRightLightIcon,
        label: localizedF("Right Align"),
      }
    case "left":
    default:
      return {
        Icon: AlignLeftLightIcon,
        IconRTL: AlignLeftLightIcon,
        label: localizedF("Left Align"),
      }
  }
}

export const ElementFormatSection = ({ dropdownClassName, editor, value, isRTL, disabled = false }) => {
  const formatOption = getFormatOptions(value)

  return (
    <Dropdown
      dropdownClassName={dropdownClassName}
      disabled={disabled}
      ButtonComponent={StyledToolbarItem}
      ButtonIcon={isRTL ? formatOption?.IconRTL : formatOption?.Icon}
      buttonAriaLabel={localized("Formatting options for text alignment")}
    >
      <DropdownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")
        }}
      >
        <AlignLeftLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Left Align")} />
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")
        }}
      >
        <AlignCenterLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Center Align")} />
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")
        }}
      >
        <AlignRightLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Right Align")} />
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")
        }}
      >
        <AlignJustifyLightIcon color={DROPDOWN_ICON_COLOR} />
        <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Justify Align")} />
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
        }}
      >
        {isRTL ? <IndentLightIcon color={DROPDOWN_ICON_COLOR} /> : <OutdentLightIcon color={DROPDOWN_ICON_COLOR} />}
        <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Outdent")} />
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
        }}
      >
        {isRTL ? <OutdentLightIcon color={DROPDOWN_ICON_COLOR} /> : <IndentLightIcon color={DROPDOWN_ICON_COLOR} />}
        <Text size="sm" color={DROPDOWN_TEXT_COLOR} token={localizationKey("Indent")} />
      </DropdownItem>
    </Dropdown>
  )
}
