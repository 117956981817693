import { useMemo } from "react"
import { $createCodeNode } from "@lexical/code"
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list"
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text"
import { $setBlocksType } from "@lexical/selection"
import { $createParagraphNode, $getSelection, $isRangeSelection } from "lexical"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../../../Text"

import { Dropdown, DROPDOWN_ICON_COLOR, DROPDOWN_TEXT_COLOR, DropdownItem } from "../../../Components"

import { getBlockTypeOptions } from "../utils"
import { StyledToolbarItem } from "../styled"

export const BlockFormatSection = ({ dropdownClassName, editor, blockType, rootType, disabled = false }) => {
  const blockTypeOptions = useMemo(() => getBlockTypeOptions(), [])

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = headingSize => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createHeadingNode(headingSize))
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph()
    }
  }

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      formatParagraph()
    }
  }

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createQuoteNode())
      })
    }
  }

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        let selection = $getSelection()

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode())
          } else {
            const textContent = selection.getTextContent()
            const codeNode = $createCodeNode()
            selection.insertNodes([codeNode])
            selection = $getSelection()
            if ($isRangeSelection(selection)) selection.insertRawText(textContent)
          }
        }
      })
    }
  }

  const formatFunctions = {
    paragraph: formatParagraph,
    h1: () => formatHeading("h1"),
    h2: () => formatHeading("h2"),
    h3: () => formatHeading("h3"),
    h4: () => formatHeading("h4"),
    bullet: formatBulletList,
    number: formatNumberedList,
    quote: formatQuote,
    code: formatCode,
  }

  return (
    <Dropdown
      dropdownClassName={dropdownClassName}
      disabled={disabled}
      ButtonComponent={StyledToolbarItem}
      ButtonIcon={blockTypeOptions[blockType].Icon}
      buttonAriaLabel={localized("Formatting options for text style")}
    >
      {Object.keys(blockTypeOptions).map(key => {
        const { label, Icon } = blockTypeOptions[key]
        return (
          <DropdownItem key={key} onClick={formatFunctions[key]}>
            <Icon color={DROPDOWN_ICON_COLOR} />
            <Text color={DROPDOWN_TEXT_COLOR} size="sm">
              {label}
            </Text>
          </DropdownItem>
        )
      })}
    </Dropdown>
  )
}
