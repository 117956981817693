import { assoc, assocPath, isNil, path, propEq } from "ramda"
import { isNotNil } from "js/includes/common/utils"

export const ManagedConfigType = {
  BOOL: "BOOL",
  STRING: "STRING",
  INTEGER: "INTEGER",
  CHOICE: "CHOICE",
  MULTISELECT: "MULTISELECT",
  HIDDEN: "HIDDEN",
  BUNDLE: "BUNDLE",
  BUNDLE_ARRAY: "BUNDLE_ARRAY",
}

export const getManagedConfigByConnection = (managedConfigurations = [], connectionId) =>
  managedConfigurations.find(propEq("connectionId", connectionId))

export const applyDefaultValue = (values, { keyPath, defaultValue }) => {
  const isConfigSet = isNotNil(path(keyPath, values))
  return isConfigSet ? values : assocPath(keyPath, defaultValue, values)
}

export const applyDefaultManagedConfigValues = (managedConfigurations = [], connectionId, propertiesList = []) => {
  const _managedConfigurations = getManagedConfigByConnection(managedConfigurations, connectionId)
    ? managedConfigurations
    : [{ connectionId, managedConfiguration: {} }, ...managedConfigurations]

  const propsWithDefault = flattenManagedProperties(propertiesList, {
    excludeItem: ({ type, defaultValue }) =>
      type === ManagedConfigType.BUNDLE_ARRAY || (isNil(defaultValue) && type !== ManagedConfigType.BUNDLE),
  })

  return _managedConfigurations.map(config => {
    const newValues = propsWithDefault.reduce(applyDefaultValue, config.managedConfiguration)
    return assoc("managedConfiguration", newValues, config)
  })
}

export const flattenManagedProperties = (properties = [], { excludeItem = () => {} } = {}) => {
  const result = []

  function flatten({ nestedProperties: _nestedProperties, keyPath: _keyPath, ...rest }) {
    if (excludeItem?.(rest)) {
      return
    }

    const keyPath = _keyPath ? [..._keyPath, rest.key] : [rest.key]
    _nestedProperties
      ? _nestedProperties.forEach(item => flatten({ ...item, keyPath }))
      : result.push({ ...rest, keyPath })
  }

  properties.forEach(flatten)

  return result
}
