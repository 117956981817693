import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faKeyboard, faArrowDownToLine, faTimes, faUserGroup } from "@fortawesome/pro-light-svg-icons"
import { faUserShield } from "@fortawesome/pro-solid-svg-icons"
import styled from "@emotion/styled"
import { withTheme } from "@emotion/react"
import tokens from "@ninjaone/tokens"
import { Text, Tooltip, Body } from "@ninjaone/components"
import {
  localized,
  localizationKey,
  ninjaReportError,
  capitalize,
  isWindowsDevice,
  isMacDevice,
  isLinuxDevice,
} from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import Terminal, { handleTerminalError } from "js/includes/components/Terminal"
import { Box, Flex } from "js/includes/components/Styled"
import { ConfirmationModal } from "js/includes/components/ConfirmationModal"
import icon from "media/img/cmd.png"
import "xterm/css/xterm.css"

const StyledIconButtonWrapper = styled.button`
  background-color: transparent;
  border: none;
  margin-right: ${tokens.spacing[2]};
`

const StyledTerminalContent = styled.div`
  .xterm-screen {
    margin: ${tokens.spacing[2]} ${tokens.spacing[3]};
  },
`

const IconButton = ({ icon, action, tooltipLabel, ...props }) => (
  <Tooltip label={tooltipLabel} portal={false} triggerClassName="float-right" hideOnTriggerClick={false}>
    <StyledIconButtonWrapper type="button" onClick={action} aria-label={tooltipLabel} {...props}>
      <FontAwesomeIcon icon={icon} size="lg" />
    </StyledIconButtonWrapper>
  </Tooltip>
)

const StyledDialog = styled.dialog`
  display: block;
  z-index: 2040;
  border: none;
  background: transparent;
  .modal-content {
    background: ${({ theme }) => theme.colorBackgroundAccentNeutralDarkest};
    border: none;
  }
`

// this works along with css styles,
// if you modify these values, you should modify command-line.less too
const terminalBreakpoints = [
  { minWidth: 0, maxWidth: 1280, columnNumber: 110 }, // 990px console width
  { minWidth: 1280, maxWidth: Infinity, columnNumber: 120 }, // 1080px console width
]

// Below values are based on terminalBreakpoints
// we add +15px for terminal scrollbar
const terminalWidthPerColumns = {
  110: 1005,
  120: 1095,
}

const getTerminalInfo = ({ shellPath, terminalType, nodeClass }) => {
  if (isMacDevice(nodeClass) || isLinuxDevice(nodeClass)) {
    return terminalType === "SYSTEM" ? localized("Terminal: Root") : localized("Terminal")
  }
  if (isWindowsDevice(nodeClass)) {
    if (shellPath?.startsWith("DEFAULT")) {
      return terminalType === "SYSTEM" ? localized("CMD: System") : localized("CMD: Logged-in user")
    } else if (shellPath?.startsWith("POWERSHELL")) {
      return terminalType === "SYSTEM" ? localized("Powershell: System") : localized("Powershell: Logged-in user")
    }
  }
  return localized("Terminal")
}

class CommandLineModal extends Component {
  constructor(props) {
    super(props)
    const { windowsMode, terminalType, unmount, shellPath, node, protocol, theme } = this.props

    this.term = new Terminal({
      cursorBlink: true,
      cols: this.getBreakpointColumns(window.innerWidth),
      rows: 30,
      scrollback: 2000,
      tabStopWidth: 4,
      terminalType,
      nodeId: node?.id,
      hideModal: unmount,
      windowsMode,
      shellPath,
      protocol,
      delegateNodeId: parseInt(node?.parentNodeId) || undefined,
      background: theme.colorBackgroundAccentNeutralDarkest,
      fontFamily: `${tokens.typography.fontFamily.code}, monospace`,
      lineHeight: 1.3,
      fontSize: 14,
      fontWeight: tokens.typography.fontWeight.regular,
      cursorAccent: theme.colorBackgroundAccentNeutralDarkest,
    })

    this.position = {
      x: 0,
      y: 0,
      initX: 0,
      initY: 0,
      offsetX: 0,
      offsetY: 0,
    }
    this.dragRef = React.createRef()
    this.dragImg = new Image(0, 0)
    this.dragImg.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
  }

  state = {
    modalVisible: false,
  }

  getBreakpointColumns = screenWidth =>
    terminalBreakpoints.find(b => screenWidth >= b.minWidth && screenWidth < b.maxWidth).columnNumber

  async componentDidMount() {
    if (window.wamp.session) {
      try {
        await this.term.getUserEndpointUrl(this.props?.isElevated)
        this.setState({
          modalVisible: true,
        })
      } catch (err) {
        this.props.unmount()
        if (!err.isHandledMfaError) {
          ninjaReportError(err)
          handleTerminalError(err)
        }
      }
    } else {
      this.props.unmount()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.modalVisible && this.state.modalVisible) {
      this.term.initialize(this.terminalContainerNode)
    }
  }

  componentWillUnmount() {
    this.term.destroy()
  }

  onDragOver(event) {
    event.preventDefault()
    const { clientX, clientY } = event

    const x = clientX - this.position.initX
    const y = clientY - this.position.initY
    this.position.x = x
    this.position.y = y
    this.position.offsetX = x
    this.position.offsetY = y

    this.updateCurrentPosition()
    event.stopPropagation()
    return true
  }

  onDragEnd(event) {
    this.position.initX = this.position.x
    this.position.initY = this.position.y
  }

  onDragStart(event) {
    event.dataTransfer.effectAllowed = "move"
    event.dataTransfer.setDragImage(this.dragImg, 0, 0)

    const { clientX, clientY } = event

    this.position.initX = clientX - this.position.offsetX
    this.position.initY = clientY - this.position.offsetY
    event.stopPropagation()
  }

  onDragLeave(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  updateCurrentPosition() {
    this.dragRef.current.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`
  }

  shouldShowToggleElevationOption() {
    const { node, terminalType } = this.props

    return isWindowsDevice(node?.nodeClass) && terminalType === "USER" && this.term.isElevatable
  }

  getTitle() {
    const { node, protocol, isElevated = true } = this.props

    const adminMode = this.shouldShowToggleElevationOption() && isElevated ? ` (${localized("Admin mode")})` : ""

    const nodeDisplayName = node ? `${node?.displayName}` : localized("Terminal")

    const protocolSubTitle = protocol
      ? ` (${this.props.protocol === "SSH" ? this.props.protocol : capitalize(this.props.protocol)} - ${localized(
          "Beta",
        )})`
      : ""

    return `${nodeDisplayName}${adminMode}${protocolSubTitle}`
  }

  handleAccessLevel() {
    const { unmount: commandLineUnmount, node, terminalType, shellPath, isElevated = true } = this.props

    const switchToAdminBodyText = localized(
      "You are about to open a new session in Admin mode. This is the Ninja default and provides access to a command prompt which is elevated with Local Admin permissions. Your command history from this session and directory location will be discarded.",
    )
    const switchToNonAdminBodyText = localized(
      "You are about to open a new session in Non-admin mode. This provides access to a command prompt which is not elevated with Local Admin permissions. Your command history from this session and directory location will be discarded.",
    )

    showModal(
      <ConfirmationModal
        {...{
          titleToken: isElevated
            ? localizationKey("Switch to Non-admin mode?")
            : localizationKey("Switch to Admin mode?"),
          actionToken: localizationKey("Continue"),
          onConfirm: ({ unmount }) => {
            unmount()
            this.term.toggleAccessLevel({
              node,
              terminalType,
              shellPath,
              unmount: commandLineUnmount,
              isElevated: !(isElevated ?? true),
            })
          },
        }}
      >
        <Body type="body" textWrap>
          {isElevated ? switchToNonAdminBodyText : switchToAdminBodyText}
        </Body>
      </ConfirmationModal>,
      { withProvider: true },
    )
  }

  render() {
    const { terminalType, shellPath, node, theme, isElevated = true } = this.props
    const { modalVisible } = this.state
    const width = terminalWidthPerColumns[this.getBreakpointColumns(window.innerWidth)]
    const toggleElevationButtonText = isElevated
      ? localized("Switch to Non-admin mode")
      : localized("Switch to Admin mode")

    if (!modalVisible) {
      return null
    }

    return (
      // TODO: Remove the lint disable when third party issue with this plugin is fixed
      // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/932
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <StyledDialog className="disable-background" onDragOver={this.onDragOver} onDragLeave={this.onDragLeave}>
        <div
          ref={this.dragRef}
          id="maccommand-line-modal"
          className="modal modal-dialog modal-sm maccommand-line-modal"
          draggable
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          style={{
            width,
          }}
          role="toolbar"
        >
          <div className="modal-content">
            <Box className="modal-header" padding={tokens.spacing[2]}>
              <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
                <Flex alignItems="center" gap={tokens.spacing[2]}>
                  <img alt="terminal-icon" src={icon} />
                  <Text fontWeight={tokens.typography.fontWeight.medium} type="body">
                    {getTerminalInfo({ shellPath, terminalType, nodeClass: node.nodeClass })}
                  </Text>
                </Flex>
                <Flex justifyContent="center" alignItems="center" gap={tokens.spacing[1]} width="550px">
                  <Text fontWeight={tokens.typography.fontWeight.medium} type="body">
                    {this.getTitle()}
                  </Text>
                  {this.shouldShowToggleElevationOption() && isElevated && (
                    <FontAwesomeIcon
                      icon={faUserShield}
                      size="lg"
                      color={theme.colorTextWeak}
                      aria-label={localized("Admin mode icon")}
                    />
                  )}
                </Flex>
                <Flex justifyContent="end" alignItems="center">
                  {this.shouldShowToggleElevationOption() && (
                    <IconButton
                      tooltipLabel={toggleElevationButtonText}
                      icon={faUserGroup}
                      action={() => this.handleAccessLevel()}
                    />
                  )}
                  <IconButton
                    tooltipLabel={localized("Allow keyboard commands")}
                    icon={faKeyboard}
                    action={this.term.sendControlCommand}
                  />
                  <IconButton
                    tooltipLabel={localized("Export log (TXT)")}
                    icon={faArrowDownToLine}
                    action={this.term.downloadAll}
                  />
                  <IconButton tooltipLabel={localized("Copy content")} icon={faCopy} action={this.term.copyAll} />
                  <Box width="1px" backgroundColor="colorBorderStrong" marginRight={tokens.spacing[2]} height="100%" />
                  <IconButton
                    tooltipLabel={localized("Terminate session")}
                    icon={faTimes}
                    action={() => this.term.close(this.props.unmount)}
                  />
                </Flex>
              </Box>
            </Box>
            <StyledTerminalContent
              ref={el => {
                this.terminalContainerNode = el
              }}
              onMouseUp={this.term.mouseUp}
              role="none"
            />
          </div>
        </div>
      </StyledDialog>
    )
  }
}

export default withTheme(CommandLineModal)

CommandLineModal.propTypes = {
  terminalType: PropTypes.string.isRequired,
  shellPath: PropTypes.string.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  protocol: PropTypes.string,
}
