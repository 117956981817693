import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Slider from "js/includes/components/Slider"

export default memo(({ values, onChange, validation, thresholdType, maxValue }) => (
  <FormGroup controlId="threshold">
    <Col componentClass={ControlLabel} sm={4}>
      {thresholdType === "number" ? localized("Threshold") : localized("Threshold Percent")}
    </Col>
    <Col sm={8}>
      <Slider initialValue={values.threshold} onChange={onChange} max={maxValue} />
    </Col>
  </FormGroup>
))
