import styled from "@emotion/styled"
import PropTypes from "prop-types"

import Body from "./Body"

const StyledCode = styled(Body)`
  text-wrap: ${({ textWrap }) => (textWrap ? "wrap" : "nowrap")};
`

const Code = ({ children, color, hideTooltip, textWrap, textWrapLineLimit, tooltipPosition }) => {
  return (
    <StyledCode
      as="code"
      code
      type="body"
      {...{
        color,
        hideTooltip,
        textWrap,
        textWrapLineLimit,
        tooltipPosition,
      }}
    >
      {children}
    </StyledCode>
  )
}

Code.propTypes = {
  /**
   * Text content for the component.
   */
  children: PropTypes.node.isRequired,
  /**
   * Determines if tooltip should be hidden.
   */
  hideTooltip: PropTypes.bool,
  /**
   * Determines if text should wrap.
   */
  textWrap: PropTypes.bool,
  /**
   * The number of lines text should wrap
   */
  textWrapLineLimit: PropTypes.number,
  /**
   * Position of the tooltip.
   */
  tooltipPosition: PropTypes.func,
}

export default Code
