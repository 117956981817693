import ModelBase from "../../includes/editors/Common/ModelBase"

var singletonConstructors = []
var singletonInstances = []

const Singleton = ModelBase.extend(
  {
    defaults: {
      preventDeallocation: true,
    },
  },
  {
    getInstance: function(singletonId) {
      var classDefinition = singletonConstructors[singletonId]
      if (!classDefinition) {
        throw new Error("Cannot find a class definition for " + singletonId)
      }
      var instance = singletonInstances[singletonId]
      if (!instance) {
        // eslint-disable-next-line
        instance = new singletonConstructors[singletonId]()
        singletonInstances[singletonId] = instance
      }
      if (instance instanceof classDefinition === false) {
        throw new Error("Cannot find a Singleton type for " + singletonId)
      }
      return instance
    },
  },
)

Singleton.extend = function(protoProps, classProps) {
  if (protoProps.isNoInstanceSingletonBaseClass === true) {
    return ModelBase.extend.call(this, protoProps, classProps)
  }

  if (!protoProps.singletonId) {
    throw new Error("Plase include a singletonId property in your Singleton class definition")
  }

  var constructor = ModelBase.extend.call(this, protoProps, classProps)
  singletonConstructors[protoProps.singletonId] = constructor

  var getInstance = function() {
    return Singleton.getInstance(protoProps.singletonId)
  }

  // Create a simple constructor which returns the already created instance
  // This is necessary as Backbone relations will try to create an instance via new
  var singletonConstructor = getInstance

  // Override Backbone.RelationalModel.findOrCreate
  singletonConstructor.findOrCreate = getInstance

  // Add the static getInstance function
  singletonConstructor.getInstance = getInstance

  // Since we're returning a non-Backbone.RelationalModel function,
  // adding a prototype manually as a hack to get past Backbone Relational checks
  singletonConstructor.prototype = constructor.prototype

  return singletonConstructor
}

export default Singleton
