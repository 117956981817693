//@flow
import { sort, ifElse, take, compose, dec, cond, isNil, always, gte, T, inc, __, isEmpty, both, lte } from "ramda"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"

export const sortAsc = sort((a, b) => a - b)

export const incrementNegativeNumberOfSet = (input: number) => {
  const isGreaterThanZero = i => i >= 0
  const returnNegativeOne = () => -1

  return compose(ifElse(isGreaterThanZero, returnNegativeOne, dec), take(1), sortAsc)(input)
}

export const isPositiveInteger = compose(
  i => Number.isInteger(i) && i > 0,
  i => !isNaN(i) && parseFloat(i),
)

export const isWholeNumber = str => /^[0-9]*$/g.test(str)

export const roundRobin = (limit: number) =>
  cond([
    [isNil, always(0)],
    [gte(__, limit), always(0)],
    [T, inc],
  ])

export const parsesToNumber = value => !isNaN(parseInt(value, 10))

export const parseNumber = value => (isNaN(value) ? 0 : Number(value))

export const numberToHex = number => (isNaN(number) ? "" : (+number).toString(16))

export const hexToNumber = hex => (isEmpty(hex) ? 0 : parseInt(hex, 16))

const ordinalTypesMapper = {
  few: localizationKey("{{number}}rd"),
  one: localizationKey("{{number}}st"),
  two: localizationKey("{{number}}nd"),
  other: localizationKey("{{number}}th"),
}

export const addOrdinal = i => {
  const lang = window.store.getState().application.language
  const rule = new Intl.PluralRules(lang, { type: "ordinal" })
  const type = rule.select(i)
  return localized(ordinalTypesMapper[type] ?? "{{number}}", { number: i })
}

export const ordinal = i => {
  if (typeof i !== "number") throw new TypeError("Expected Number, got " + typeof i + " " + i)
  return !Number.isFinite(i) ? i : addOrdinal(i)
}

export const precisionRound = (value, precision) => {
  if (value) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  }

  return 0
}

export const twoSignificantFigures = num => num.toFixed(2)

export const normalizeInt = (value, defaultValue = 0) => {
  const num = value.replace(/\D|^0+/g, "")
  return !num || parseInt(num, 10) < 0 ? defaultValue : num
}

export const normalizeDecimal = (value, precision, defaultValue = 0) => {
  if (isEmpty(value)) {
    return null
  }

  if (+value === 0) {
    return 0
  }

  const reg = new RegExp(`(^\\d+[.,]{1}\\d{${precision}})(\\d+)`, "g")
  const num = value.replace(reg, (a, b) => b)
  return parseFloat(num, 10)
}

export const betweenInclusive = (a, b) => both(gte(__, a), lte(__, b))

/**
 * Decimal adjustment of a number from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || +exp === 0) {
    return Math[type](value)
  }
  value = +value
  exp = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  value = value.toString().split("e")
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)))
  // Shift back
  value = value.toString().split("e")
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
}

// Decimal round
export const round10 = (value, exp) => decimalAdjust("round", value, exp)
// Decimal floor
export const floor10 = (value, exp) => decimalAdjust("floor", value, exp)
// Decimal ceil
export const ceil10 = (value, exp) => decimalAdjust("ceil", value, exp)
