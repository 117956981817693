/** @jsxImportSource @emotion/react */
import { memo } from "react"
import { css } from "@emotion/react"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import PropTypes from "prop-types"
import { colors } from "js/includes/common/theme"

const messageContainerStyle = css`
  padding: 7px 10px;
  color: ${colors.ninjaMedium};
`

const NoOptionsMessage = memo(({ messageToken }) => <div css={messageContainerStyle}>{localized(messageToken)}</div>)

NoOptionsMessage.propTypes = {
  messageToken: PropTypes.string.isRequired,
}

NoOptionsMessage.defaultProps = {
  messageToken: localizationKey("No options available"),
}

export default NoOptionsMessage
