import { Text, Modal } from "@ninjaone/components"

const GettingStartedVideoModal = ({
  modalDescriptionToken,
  modalDescriptionRenderer,
  modalSize,
  modalTitleToken,
  modalTitleRenderer,
  unmount,
  video,
}) => {
  return (
    <Modal
      unmount={unmount}
      titleGroup={{
        TitleComponent: () => modalTitleRenderer?.() ?? <Text size="lg" token={modalTitleToken} bold />,
        DescriptionComponent: () => modalDescriptionRenderer?.() ?? <Text size="sm" token={modalDescriptionToken} />,
      }}
      size={modalSize}
      buttonRenderer={() => <></>}
      withCloseX
    >
      {video}
    </Modal>
  )
}

export default GettingStartedVideoModal
