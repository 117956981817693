import { AppleAutomationIcon, LinuxAutomationIcon, WindowsAutomationIcon } from "@ninjaone/icons"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"
import { compose, join, pluck, reject, map, memoizeWith, identity, isEmpty } from "ramda"
import { findScriptCategories } from "js/includes/common/utils"
import { LEGACY_INSTALL_RUN_SCRIPTS_UIDS } from "js/state/selectors/scripting/scriptsSelectorList"

export const fetchResources = async ({ requestResources, errorKey }) => {
  try {
    return await Promise.all(requestResources.map(request => request()))
  } catch (error) {
    const _errorKey = error?.response?.status === 403 ? localizationKey("Access denied") : errorKey
    reportErrorAndShowMessage(error, _errorKey)
  }
  return []
}

export const automationOsIcons = {
  windows: WindowsAutomationIcon,
  mac: AppleAutomationIcon,
  "mac server": AppleAutomationIcon,
  linux: LinuxAutomationIcon,
}

const getCategoriesProps = memoizeWith(identity, (categoriesIds, automationCategories) => {
  const categories = findScriptCategories(automationCategories, { categoriesIds })
  return {
    categoriesNames: compose(join(", "), pluck("name"))(categories),
    categoriesTags: {
      labels: map(
        ({ id, name }) => ({
          id: `${id}`,
          label: name,
        }),
        categories,
      ),
      maxItemsDisplayed: 4,
    },
  }
})

export const getAutomationsWithCategories = ({
  automations,
  categories,
  allowLegacyInstallRunScripts = false,
  filterNewInstallRunScripts = false,
}) =>
  compose(
    map(automation => ({
      ...automation,
      ...getCategoriesProps(automation.categoriesIds, categories),
    })),
    reject(
      ({ uid, language }) =>
        (!allowLegacyInstallRunScripts && LEGACY_INSTALL_RUN_SCRIPTS_UIDS.includes(uid)) ||
        (filterNewInstallRunScripts && ["binary_run", "binary_install"].includes(language)),
    ),
    _ => (isEmpty(categories) ? [] : _),
  )(automations)
