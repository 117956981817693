import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"

export default memo(({ onChange, thresholdOptions, initialValue }) => (
  <FormGroup controlId="thresholdSelect">
    <Col componentClass={ControlLabel} sm={4}>
      {localized("Threshold")}
    </Col>
    <Col sm={8}>
      <Dropdown options={thresholdOptions} initialValue={initialValue} onChange={onChange} />
    </Col>
  </FormGroup>
))
