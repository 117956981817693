import { memo } from "react"
import tokens from "@ninjaone/tokens"
import { Select } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import withInheritance from "js/includes/components/withInheritance"
import { betaEnrollmentOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/formCommons.js"

const BetaInstallSelector = memo(({ inheritableItem: allowBetaProgramEnrollmentSettings, onChange }) => {
  const { allowBetaProgramEnrollment = "ALLOWED" } = allowBetaProgramEnrollmentSettings

  return (
    <Box marginBottom={tokens.spacing[2]}>
      <Select
        labelId="install-beta-updates"
        labelText={localized("Allow user to install beta OS versions (iOS 18+)")}
        value={allowBetaProgramEnrollment}
        onChange={enrollment =>
          onChange({ ...allowBetaProgramEnrollmentSettings, allowBetaProgramEnrollment: enrollment })
        }
        options={betaEnrollmentOptions}
      />
    </Box>
  )
})

export default withInheritance(BetaInstallSelector)
