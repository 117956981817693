import { useEffect } from "react"
import { useLocation, Route } from "react-router-dom"
import qs from "qs"
import { any, anyPass, startsWith, __, compose, prop, equals, map } from "ramda"
import { faSignOut } from "@fortawesome/pro-solid-svg-icons"
import { pathToRegexp } from "path-to-regexp"

import {
  fetchJson,
  getRmmserviceDomainFromRegion,
  isProdEnvironment,
  isRmmServiceLocation,
  localizationKey,
  localized,
  ninjaHostNames,
  ninjaOneDefaultTitle,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { DAYS, isOldAccount, NINETY_ONE_DAYS_THRESHOLD } from "js/includes/GetStartedPage/utils/accountCreation"

export const NINJAONE_ZENDESK_BASE_URL = "https://ninjarmm.zendesk.com"
export const ZENDESK_DOJO_URL = `${NINJAONE_ZENDESK_BASE_URL}/hc/en-us`
export const DOJO_ARTICLES_PREFIX = `${ZENDESK_DOJO_URL}/articles`
export const DOJO_SECTION_PREFIX = `${ZENDESK_DOJO_URL}/sections`
export const DOJO_COMMUNITY_PREFIX = `${ZENDESK_DOJO_URL}/community/topics`
export const DOJO_CATEGORIES_PREFIX = `${ZENDESK_DOJO_URL}/categories`
export const getDojoSearchUrl = query => `${ZENDESK_DOJO_URL}/search?commit=Search&query=${query}`

export const changeAppUrl = ({
  protocol = window.location.protocol,
  host = window.location.host,
  search = window.location.search,
  hash = window.location.hash,
  replace = false,
}) => {
  if (window.history.pushState) {
    const _search = !search ? "" : search.includes("?") ? search : `?${search}`
    var newurl = `${protocol}//${host}${_search}${hash}`
    if (replace) {
      window.history.replaceState({ path: newurl }, "", newurl)
    } else {
      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
}

export const logout = async ({ message, redirectToLogin: _redirectToLogin = true, fromAllSessions = false } = {}) => {
  try {
    await fetchJson(`/ws/account/logout${fromAllSessions ? "?sessions=all" : ""}`, { useSessionPrefix: false })
    if (_redirectToLogin) {
      redirectToLogin({ message })
    }
  } catch (e) {
    setTimeout(() => {
      logout({ message })
    }, 3000)
  }
}

export const logoutWithMessage = async () => {
  const buttonClicked = await ShowMessageDialog({
    icon: { icon: faSignOut, type: "critical" },
    title: localizationKey("Logout"),
    message: localizationKey("Are you sure you want to logout?"),
    buttons: [
      { id: "YES_LOGOUT_FROM_CURRENT_SEESION", label: localizationKey("Log me out - this browser only") },
      { id: "YES_LOGOUT_FROM_ALL_SESSIONS", label: localizationKey("Log me out - all active sessions") },
      { id: "NO", label: localizationKey("Cancel") },
    ],
  })

  if (buttonClicked.startsWith("YES")) {
    if (window.wamp?.connection.isOpen) {
      window.wamp.connection.close()
    }
    logout({ fromAllSessions: buttonClicked === "YES_LOGOUT_FROM_ALL_SESSIONS" })
  }
}

export function getRequestParameter(name: string) {
  var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, " "))
}

export const redirectToApp = () => {
  const paths = window.location.hash.split("/")
  const search = window.location.search

  let returnTo = ""
  let query = {}

  if (search.length) {
    query = qs.parse(search, { ignoreQueryPrefix: true })
    returnTo = query.return_to
    delete query.return_to
    delete query.message
  }

  if (returnTo) {
    if (returnTo.startsWith("https")) {
      if (returnTo.startsWith(NINJAONE_ZENDESK_BASE_URL)) {
        const zendeskUrl = new URL(returnTo)
        window.location.href = zendeskUrl.href
        return false
      } else {
        throw new Error("You cannot redirect to an external domain")
      }
    } else if (returnTo.startsWith("http")) {
      throw new Error("You cannot redirect to an unsecured domain")
    } else {
      if (returnTo.startsWith("/ws/oauth")) {
        window.location.href = returnTo
        return false
      }

      const queryString = qs.stringify(query)
      window.history.pushState({ key: Date.now() + "" }, null, `/${queryString ? `?${queryString}` : ""}${returnTo}`)
    }
  } else {
    const isRootRoute = ["", "#", "index.html"].includes(paths[paths.length - 1])
    if (isRootRoute) {
      const {
        session: {
          user: {
            content: { onboardingPerformed },
            userInvitationAcceptedTime,
          },
        },
      } = window.store.getState()
      const isOldUserAccount = isOldAccount(userInvitationAcceptedTime, DAYS, NINETY_ONE_DAYS_THRESHOLD)
      const shouldRedirectToDashboard = isOldUserAccount || onboardingPerformed

      window.history.pushState(
        { key: Date.now() + "" },
        null,
        `/${search}#/${shouldRedirectToDashboard ? "systemDashboard/overview" : "getStarted"}`,
      )
    }
  }
  return true
}

const passThroughRoutes = [/^\/auth\//]

export function isCurrentRoutePassThrough() {
  const currentPath = window.location.pathname
  return passThroughRoutes.some(r => r.test(currentPath))
}

export const redirectToLogin = ({ message = "", returnToCurrentPage = true } = {}) => {
  if (isCurrentRoutePassThrough()) return

  let query = ""

  if (
    ((window.location.hash && window.location.hash !== "#/") ||
      window.location.search ||
      message ||
      window.location.pathname?.startsWith?.("/superAdmin")) &&
    returnToCurrentPage
  ) {
    const queryMapping = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    if (
      !window.location.pathname.startsWith("/auth") &&
      !window.location.pathname.startsWith("/superAdmin") &&
      !window.location.pathname.startsWith("/eus") &&
      !window.location.hash.startsWith("#/noAuth") &&
      !window.location.hash.startsWith("#/systemDashboard")
    ) {
      queryMapping.return_to = window.location.hash
    }

    if (window.location.pathname.startsWith("/superAdmin")) {
      queryMapping.return_to = `superAdmin/${window.location.hash}`
    }

    if (message) {
      queryMapping.message = message
    }

    const queryString = qs.stringify(queryMapping)

    if (queryString.length) {
      query = `?${queryString}`
    }
  }

  const newUrl = `${window.location.origin}/auth/${query}`
  window.location.href = newUrl
}

const uploadCdnUrls = ["https://ninjauploads.ninjarmm.com", "https://ninjauploads.s3.amazonaws.com"]

export function isNinjaUploadsUrl(path = "") {
  if (!path) return false

  return compose(any(equals(true)), map(startsWith(__, path)))(uploadCdnUrls)
}

export function isValidCors(origin) {
  const securedFormHostnames = ninjaHostNames.map(i => `https://secureforms.${i}`)
  return [...securedFormHostnames, window.location.origin].includes(origin)
}

export function getSecuredFormsOrigin() {
  const isProductionReactBuild = process.env.NODE_ENV === "production"
  if (isRmmServiceLocation() && isProductionReactBuild) {
    return `https://secureforms.${getRmmserviceDomainFromRegion()}`
  }
  const isEngineeringEnv = window.location.origin.includes("engineering-env.ninja")
  if (isEngineeringEnv) {
    return "https://secureforms.engineering-env.ninja"
  }
  return isProductionReactBuild ? "https://secureforms.ninjarmm.com" : window.location.origin
}

export function isAppInQaMode() {
  return getRequestParameter("mode") === "qa" && !isProdEnvironment(window.store.getState().application.environment)
}

export function isAppInDevMode() {
  return window.location.hostname === "localhost"
}

export function isAppInDevDesignInstance() {
  return window.store.getState().application.environment.includes("dev-design")
}

export function isAppInEUInstance() {
  return window.store.getState().application.region.startsWith("eu-")
}

export const isAppInOCInstance = () => window.store.getState().application.environment === "oc"

export function isAppInAustraliaInstance() {
  return window.store.getState().application.region === "ap-southeast-2"
}

export function isAppInUSInstance() {
  const app = window.store.getState().application
  return app.environment === "app" || app.region.startsWith("us-")
}

export function isAppInUS2Instance() {
  const app = window.store.getState().application
  return app.region === "us-east-2" || app.environment === "us2"
}

export function isAppInCanadaInstance() {
  return window.store.getState().application.region === "ca-central-1"
}

export const isFullScreenMode = () => {
  const fullScreenModeRoutes = ["#/superAdmin"]
  return any(startsWith(__, window.location.hash), fullScreenModeRoutes)
}

export const isSuperAdmin = () => window.location.hash.startsWith("#/superAdmin")

export const isAppInAlphaMode = () =>
  getRequestParameter("version") === "alpha" || window.location.hostname === "alpha.ninjarmm.com"

export const isAppInBetaMode = () =>
  getRequestParameter("version") === "beta" || window.location.hostname === "beta.ninjarmm.com"

export const isDemoApp = () =>
  window.location.hostname === "demo.ninjarmm.com" ||
  [
    "b330b935-e744-408e-b0bd-82d25272429d",
    "d4237b26-1a4a-46c5-a9b5-759c3fb5f18f",
    "a8a7b535-7dbf-44e0-a820-92f3c8311249",
  ].includes(window.store.getState()?.session?.divisionUid)

export const routeStartsWith = target => (match, location) => {
  const stringOrAnyStartsWith = Array.isArray(target)
    ? pathname => any(startsWith(__, pathname), target)
    : startsWith(target)

  return compose(stringOrAnyStartsWith, prop("pathname"))(location)
}

export const getCleanPathFromHash = () => window.location.hash.split("#")[1].split("?")[0]

export const useQueryParams = () => new URLSearchParams(useLocation().search)

export const isExpectedEnvironment = env => window.store.getState().application.environment === env

export const isAppOnSearchPage = ({ includeGroupSearchPage = true } = {}) => {
  const currentPath = getCleanPathFromHash()
  const isDeviceSearchPage = startsWith("/deviceSearch")
  const isGroupSearchPage = startsWith("/group")
  return anyPass([isDeviceSearchPage, ...(includeGroupSearchPage ? [isGroupSearchPage] : [])])(currentPath)
}

export function findRoute(sourceRoutes) {
  return sourceRoutes.find(route => pathToRegexp(route).test(getCleanPathFromHash()))
}

// webappDefaultDomTitle could be "NinjaOne" or "BrandedSite" (for branded sites - branded site name)
// please use setWebappDefaultDomTitleValue or getWebappDefaultDomTitle for setting/getting webappDefaultDomTitle
let webappDefaultDomTitle = ninjaOneDefaultTitle
export const setWebappDefaultDomTitleValue = title => (webappDefaultDomTitle = title)
export const getWebappDefaultDomTitle = () => webappDefaultDomTitle

const generateDomTitle = title => (!!title ? `${title} | ${getWebappDefaultDomTitle()}` : getWebappDefaultDomTitle())
const setDomTitle = title => {
  if (document.title !== title) {
    document.title = title
  }
}

// setWebappTitle is our main function for setting document DOM title.
// That title is displayed in browsers tabs.
// The title is created in form of: "Title | NinjaOne"
// or "Title | BrandedSite" for branded sites.
// If title is not specified, the default title "NinjaOne" or "BrandedSite" will be set.
export const setWebappTitle = title => {
  setDomTitle(generateDomTitle(title))
}

/**
 * Custom Hook for setting document DOM title
 * @example
 * useDomTitle(deviceDisplayName)
 */
export const useDomTitle = title => {
  useEffect(() => {
    if (title) {
      setWebappTitle(title)
    }
  }, [title])
}

/**
 * Custom Hook for setting editors DOM titles in view/edit or new modes.
 * - Editor mode (isEditMode==true) - DOM title will be set only if editModeTitle not empty,
 * which is a common scenario when we need to fetch data from the server, and only after that to set the DOM title.
 * - New mode (isEditMode==false) - DOM title will be set only if newModeTitleToken not empty.
 *
 * @example
 * useDomTitleForEditor(isEditing, cachedName, localizationKey("New Agreement"))
 */
export const useDomTitleForEditor = (isEditMode, editModeTitle, newModeTitleToken) => {
  useEffect(() => {
    isEditMode
      ? editModeTitle && setWebappTitle(editModeTitle)
      : newModeTitleToken && setWebappTitle(localized(newModeTitleToken))
  }, [isEditMode, editModeTitle, newModeTitleToken])
}

/**
 * A simple wrapper component around react <Route> component with additional title property.
 * Used in places where <Route> is used and “Page static” title is needed
 *
 * @example
 * <TitledRoute exact path={Object.values(deviceSearchRoutes)} title={localized("Search")}>
 *    <DeviceSearch />
 * </TitledRoute>
 */
export const TitledRoute = ({ title, children, ...routeProps }) => {
  useDomTitle(title)
  return <Route {...routeProps}>{children}</Route>
}

/**
 * A simple wrapper component with title property and setting DOM title functionality.
 * - Can be used as a wrapper around other components or as a standalone component
 *
 * @example
 *  <DomTitle title={policy.name}>
 *    <Header {...{ policyEditorNodeRole }} />
 *    <Text token={someToken} />
 *  </DomTitle>
 *
 * @example
 *  <DomTitle title={policy.name} />
 * */
export const DomTitle = ({ title, children }) => {
  useDomTitle(title)
  return children ?? null
}
