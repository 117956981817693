import { showSuccessMessage } from "./alert"
import { reportErrorAndShowMessage } from "./errors"
import { localizationKey, localized } from "./ssrAndWebUtils"

export async function clipboardWrite(data) {
  try {
    if (!data) return

    await navigator.clipboard.write(data)
    showSuccessMessage(localized("Copied to clipboard"))
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Failed to copy to clipboard"))
  }
}

export async function clipboardWriteText(data) {
  try {
    if (!data) return

    await navigator.clipboard.writeText(data)
    showSuccessMessage(localized("Copied to clipboard"))
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Failed to copy to clipboard"))
  }
}
