//@flow
import { curry } from "ramda"

export const selectItem = curry((name, item) => ({
  type: "SELECT_ITEM",
  item,
  name,
}))

export const selectThisItemOnly = curry((name, item) => ({
  type: "SELECT_THIS_ITEM_ONLY",
  item,
  name,
}))

export const clearItem = curry((name, item) => ({
  type: "CLEAR_ITEM",
  item,
  name,
}))

export const selectAllItems = curry((name, items) => ({
  type: "SELECT_ALL_ITEMS",
  items,
  name,
}))

export const clearAllItems = (name: string) => () => ({
  type: "CLEAR_ALL_ITEMS",
  name,
})
