import { useState } from "react"
import { nanoid } from "nanoid"
import { not } from "ramda"
import { OpenArrowIcon } from "@ninjaone/icons"
import Text from "../Text"
import {
  StyledEditorSidebarNavigationItem,
  StyledEditorSidebarNavigationDropdown,
  StyledEditorSidebarNavigationDropdownList,
} from "../Styled"

export default function EditorSidebarNavigationDropdown({ defaultShowDropdown = false, Icon, token, Components }) {
  const [showDropdown, setShowDropdown] = useState(defaultShowDropdown)

  return (
    <StyledEditorSidebarNavigationDropdown>
      <StyledEditorSidebarNavigationItem onClick={() => setShowDropdown(not)} hasIcon={!!Icon}>
        <Icon color="darkGray" />
        <Text token={token} bold />
        <OpenArrowIcon inverse={showDropdown} />
      </StyledEditorSidebarNavigationItem>

      {showDropdown && (
        <StyledEditorSidebarNavigationDropdownList>
          {Components?.map(EditorNavigationItem => (
            <EditorNavigationItem key={nanoid()} />
          ))}
        </StyledEditorSidebarNavigationDropdownList>
      )}
    </StyledEditorSidebarNavigationDropdown>
  )
}
