import { compose, reverse, find, curry, startsWith, anyPass, equals } from "ramda"
import { typography, spacing, spacingBase } from "@ninjaone/tokens"

// spacingBase is our design grid step size
const step = spacingBase

export * from "./hooks"
export * from "./helpers"
export * from "./isRequiredIf"

export function sizer(...props) {
  const unit = "px"
  return props.map((factor = 0) => (isNaN(factor) ? factor : factor * step + unit)).join(" ")
}

export function sizerNumber(num) {
  return num * step
}

export const noop = () => {}

export function getTextSize(size) {
  switch (size) {
    case "xs":
      // 12px
      return typography.fontSize.bodyXs
    case "sm":
      // 14px
      return typography.fontSize.body
    case "md":
      // 16px
      return "1rem"
    case "lg":
      // 24px
      return "1.5rem"
    case "xl":
      // 32px
      return "2rem"
    default:
      // 16px
      return "1rem"
  }
}

export function getLineHeight(size) {
  switch (size) {
    case "xs":
      // 16px
      return "1rem"
    case "sm":
      // 16px
      return "1rem"
    case "md":
      // 24px
      return "1.5rem"
    case "lg":
      // 27px
      return "1.6875rem"
    case "xl":
      // 36px
      return "2.25rem"
    default:
      return "normal"
  }
}

export function fullScreenEditorCloseHandler() {
  const lastNonEditorRoute = compose(
    // TODO: once backbone is gone, add in fallback routes to editors.
    // With backbone, the system dashboard route is always present, so fallback doesn't work.
    // defaultTo(onCloseFallbackRoute),
    find(hash => !hash.includes("#/editor")),
    reverse,
  )(window.routeHistory)

  window.location.hash = lastNonEditorRoute
  return lastNonEditorRoute
}

export const getStatusColor = (status, theme) => {
  switch (status) {
    case "HEALTHY":
      return theme.color.success["100"]
    case "UNHEALTHY":
      return theme.color.error["100"]
    case "NEEDS_ATTENTION":
      return theme.color.warning["100"]
    case "UNKNOWN":
    default:
      return theme.color.border
  }
}

export const getColorFromProps = curry(({ defaultTo }, { color, theme }) => {
  if (!color) {
    return getColorFromProps({}, { color: defaultTo ?? "unset", theme })
  }

  if (
    typeof color === "string" &&
    anyPass([equals("unset"), startsWith("#"), startsWith("rgb"), startsWith("hsl")])(color)
  ) {
    return color
  }

  if (!theme) throw new Error(`getColorFromProps: No theme provided for color: ${JSON.stringify(color)}.`)

  return (
    (typeof color === "string" ? theme.color?.[color] || theme[color] : theme.color?.[color.code]?.[color.shade]) ??
    "unset"
  )
})

export const getTopCenterBetweenTwoRects = (rectA, rectB) => {
  const middle = (rectA.height - rectB.height) / 2

  return rectA.top + middle + window.scrollY
}

export const focusOnDomElement = element => {
  if (!element) return

  const hasNoTabIndex = element.tabIndex === -1

  if (hasNoTabIndex) {
    element.tabIndex = 0
    element.style.outline = "none"
  }

  element.focus()

  // There is a race condition in Safari where this will run before `element.focus` executes causing `tabIndex` to be equal to `-1`.
  if (hasNoTabIndex) {
    setTimeout(() => {
      element.removeAttribute("tabIndex")
      element.style.outline = ""
    }, 1000)
  }
}

export const getMouseSpeed = (event, prevMouseEvent) => {
  const movementX = Math.abs(event.pageX - prevMouseEvent.pageX)
  const movementY = Math.abs(event.pageY - prevMouseEvent.pageY)
  const movement = Math.sqrt(movementX * movementX + movementY * movementY)

  return 10 * movement
}

export const getSlopeBetweenTwoPoints = (x1, y1, x2, y2) => {
  if (x2 - x1 !== 0) {
    return (y2 - y1) / (x2 - x1)
  }

  return Infinity
}

export const formatFileSize = sizeInBytes => {
  const size = sizeInBytes

  if (size >= 1e9) {
    return (size / 1e9).toFixed(2) + " GB"
  } else if (size >= 1e6) {
    return (size / 1e6).toFixed(2) + " MB"
  } else if (size >= 1e3) {
    return (size / 1e3).toFixed(2) + " KB"
  } else {
    return size + " B"
  }
}

export const hexToHexA = (color, transparency) => {
  const alpha = Math.round(transparency * 255).toString(16)
  return `${color}${alpha}`
}

export const deprecated = (validator, message) => {
  return (props, propName, ...rest) => {
    if (props[propName] != null) {
      console.warn(`'${propName}' prop is deprecated. ${message}`)
    }
    return validator(props, propName, ...rest)
  }
}

// taken from https://github.com/mui/material-ui
export default function chainPropTypes(propType1, propType2) {
  if (process.env.NODE_ENV === "production") {
    return () => null
  }

  return function validate(...args) {
    return propType1(...args) || propType2(...args)
  }
}
