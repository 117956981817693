import qs from "qs"
import { fetch, fetchJson, upload } from "js/includes/common/utils"

export const fetchReportSchedulesHistory = () => {
  return fetchJson("/report-schedule-history")
}

export const fetchReportLibrary = ({ isSchedule = false }) => {
  return fetchJson(`/report-template/?isSchedule=${isSchedule}`)
}

export const getRunbooksTemplates = () => fetchJson("/attributes/document/templates")

export const saveReportTemplate = (data, id) => {
  return fetchJson(`/report-template${id ? `/${id}` : ""}`, {
    options: {
      method: id ? "PUT" : "POST",
      body: JSON.stringify(data),
    },
  })
}

export const generateReport = ({ templateId, sendToAppUserList, languageTag }) => {
  return fetchJson("/report-runbook/generate", {
    options: {
      method: "POST",
      body: JSON.stringify({
        notifyTo: sendToAppUserList,
        reportTemplateId: templateId,
        languageTag,
      }),
    },
  })
}

export const deleteReportTemplates = ids => {
  return fetchJson(`/report-template?${qs.stringify({ id: ids }, { arrayFormat: "repeat" })}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const copyReportTemplate = data => {
  return fetchJson("/report-template/copy", {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}

export const fetchReportSchedulesList = () => {
  return fetchJson("/report-schedule")
}

export const fetchReportSchedule = id => {
  return fetchJson(`/report-schedule/${id}`)
}

export const deleteReportSchedules = ids => {
  return fetchJson(`/report-schedule?${qs.stringify({ id: ids }, { arrayFormat: "repeat" })}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const patchReportSchedules = data => {
  return fetchJson("/report-schedule", {
    options: {
      method: "PATCH",
      body: JSON.stringify(data),
    },
  })
}

export const saveReportSchedule = (data, id) => {
  return fetchJson(`/report-schedule${id ? `/${id}` : ""}`, {
    options: {
      method: id ? "PUT" : "POST",
      body: JSON.stringify(data),
    },
  })
}

export const copyReportSchedule = data => {
  return fetchJson("/report-schedule/copy", {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}

export const updateReportScheduleOwnership = id => {
  return fetchJson(`/report-schedule/${id}/claim`, {
    options: {
      method: "PATCH",
    },
  })
}

export const getReportTemplateConfig = id => {
  return fetchJson(`/report-template/${id}`)
}

export const getDashboardReportSampleData = ({ id, targetType }) => {
  const sampleReportFileName =
    targetType === "ORGANIZATION" ? "organizationDashboardReport.json" : "globalDashboardReport.json"
  return require(`./reportsSamples/${sampleReportFileName}`)
}

export const getDashboardReportData = ({ id, refresh, filters }) => {
  const { organizationId, ...restFilters } = filters
  return fetchJson(
    `/report-dashboard/view/${id}${organizationId ? `/entity/${organizationId}` : ""}${qs.stringify(
      { refresh, ...restFilters },
      { addQueryPrefix: true, skipNulls: true },
    )}`,
  )
}

export const uploadFile = ({ url, file, callback }) => {
  const { promise } = upload(url, {
    body: { file },
    onSuccessHandler: callback,
    U2fRequired: true,
  })

  return promise
}

export const getReportDivisionConfig = () => {
  return fetchJson("/divisionconfig/reporting")
}

export const updateReportingConfig = data =>
  fetch("/divisionconfig/reporting", {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content: data,
      }),
    },
  })

export const reportClient = {
  getDashboardReportSampleData,
  getDashboardReportData,
}
