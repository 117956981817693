import React, { memo, useMemo, useState } from "react"
import { compose, filter, map, propEq } from "ramda"
import { Col, ControlLabel } from "react-bootstrap"
import { useQuery } from "urql"
import {
  localized,
  localizationKey,
  WithGraphql,
  reportErrorAndShowMessage,
  isNotNilOrEmpty,
  hasTicketingAdministrativeOnlyAccess,
} from "js/includes/common/utils"
import Select from "js/includes/components/Select"
import { getTicketRuleset } from "js/includes/configuration/integrations/ticketing/ticketAutomation/ticketRuleset/graphql"
import Loading from "js/includes/components/Loading"

const getRulesetList = compose(
  map(({ id, name, enabled }) => ({
    id,
    value: `${localized("Create with ")} ${name}${enabled ? "" : ` (${localized("Disabled")})`}`,
  })),
  filter(isNotNilOrEmpty),
)

const TicketRulesetOptions = memo(
  ({ setTicketRuleId, ticketRuleId, colSize, ruleType = "CONDITION_RULE", NewSelectComponent }) => {
    const [value, setValue] = useState(ticketRuleId)
    const isAdmin = useMemo(() => hasTicketingAdministrativeOnlyAccess(), [])

    const [{ fetching, error, data }] = useQuery({
      query: getTicketRuleset,
      pause: !isAdmin,
    })

    if (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching ruleset"))
    }

    const ticketRuleset = filter(propEq("type", ruleType), data?.ticketRuleset ?? [])

    //TODO when everything is standardized to the new designs this component coming from Compound Conditions - NotifiationsTab should be used
    if (NewSelectComponent) {
      return <NewSelectComponent {...{ isAdmin, value, ticketRuleset, setTicketRuleId, setValue, fetching }} />
    }

    return fetching ? (
      <Loading />
    ) : (
      <div>
        <Col sm={4} componentClass={ControlLabel}>
          {localized("Ticketing Rule")}
        </Col>
        <Col sm={colSize || 8}>
          <Select
            {...{
              disabled: !isAdmin,
              value: isAdmin ? value || "NONE" : "NO_PERMISSIONS",
              options: isAdmin
                ? [{ id: "NONE", value: localized("Off") }, ...getRulesetList(ticketRuleset)]
                : [{ id: "NO_PERMISSIONS", value: localized("No Permissions") }],
              onChange: e => {
                const targetValue = e.target.value
                const value = targetValue !== "NONE" ? parseInt(targetValue, 10) : null
                setTicketRuleId(value)
                setValue(value)
              },
            }}
          />
        </Col>
      </div>
    )
  },
)

export default WithGraphql(TicketRulesetOptions)
