import { ConfirmationModal } from "@ninjaone/components"
import { localizationKey, localized, localizedWith, showSuccessMessage } from "js/includes/common/utils"
import { BoldText } from "js/includes/configuration/integrations/mdm/apple/common/textUtils"

const getTitleLanguageLabel = entryKey => {
  switch (entryKey) {
    case "shortSupportMessage":
      return localized("short support message")
    case "longSupportMessage":
      return localized("long support message")
    case "deviceOwnerLockScreenInfo":
      return localized("lock screen message")
    default:
      return localized("custom messaging")
  }
}

const ConfirmDeleteMessage = ({ unmount, languageToken, languageKey, entryKey, removeLanguageItem }) => {
  const titleTokenMessage = getTitleLanguageLabel(entryKey)

  const handleConfirm = ({ unmount }) => {
    removeLanguageItem(languageKey)
    unmount()
    showSuccessMessage(localized("Language removed successfully"))
  }

  return (
    <ConfirmationModal
      type="danger"
      titleText={localized("Delete language for {{customMessage}}", { customMessage: titleTokenMessage })}
      descriptionText={localizedWith("Are you sure you want to delete the <%language> <%> language support?", {
        language: () => <BoldText>{localized(languageToken)}</BoldText>,
      })}
      actionToken={localizationKey("Delete")}
      onConfirm={handleConfirm}
      unmount={unmount}
    />
  )
}

export default ConfirmDeleteMessage
