/** @jsxImportSource @emotion/react */
import { Component } from "react"
import { css } from "@emotion/react"
import { localized } from "js/includes/common/utils"
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap"
import $ from "jquery"

const fixBootstrapToggleButtonGroupIssue = () => $(document).off("click.bs.button.data-api", '[data-toggle^="button"]')

const toggleButtonStyle = css`
  z-index: 0 !important;
`

export default class WeekDaysCheckboxes extends Component {
  days = [
    { value: "SUN", text: localized("Sunday").charAt(0) },
    { value: "MON", text: localized("Monday").charAt(0) },
    { value: "TUE", text: localized("Tuesday").charAt(0) },
    { value: "WED", text: localized("Wednesday").charAt(0) },
    { value: "THU", text: localized("Thursday").charAt(0) },
    { value: "FRI", text: localized("Friday").charAt(0) },
    { value: "SAT", text: localized("Saturday").charAt(0) },
  ]

  onChangeSelection = days => {
    const { changeSelection } = this.props
    changeSelection && changeSelection(days)
  }

  componentDidMount() {
    fixBootstrapToggleButtonGroupIssue()
  }

  render() {
    const { selectedDays = [], disabled = false, disabledDays = [], singleSelect } = this.props

    return (
      <ButtonToolbar>
        <ToggleButtonGroup
          type={singleSelect ? "radio" : "checkbox"}
          value={selectedDays}
          onChange={this.onChangeSelection}
          name="weekdays-toggle-buttons"
        >
          {this.days.map(({ value, text, isSelected }, index) => (
            <ToggleButton
              disabled={disabled || disabledDays.includes(value)}
              key={value}
              value={value}
              css={toggleButtonStyle}
            >
              {text}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </ButtonToolbar>
    )
  }
}
