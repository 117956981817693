import { fullNameOrEmail, localized } from "js/includes/common/utils"

import { getFullNameOrEmailInitials } from "./parsers"

export const getUserNameFromLogEntry = ({ system, user }) => {
  return user
    ? fullNameOrEmail(user) + (user.deleted ? ` (${localized("Deleted")})` : "")
    : system
    ? localized("System")
    : localized("Deleted User")
}

export const getUserNameInitialsFromLogEntry = ({ system, user }) => {
  return user ? getFullNameOrEmailInitials(user) : system ? localized("System").charAt(0) : localized("N/A")
}
