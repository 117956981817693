import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"
import { ascend, compose, sortWith, toLower } from "ramda"
import { pathOr } from "ramda"

import { getBackupJob, submitJob } from "js/includes/common/backup"
import {
  localizationKey,
  localized,
  ninjaReportError,
  runDeviceSearchToolbarAction,
  showErrorMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import NoOptionsMessage from "js/includes/components/NoOptionsMessage"

export const sortBackupPlans = sortWith([
  ascend(compose(toLower, ({ name, general: { name: arrowPlanName } = {} }) => name ?? arrowPlanName)),
])

export const createBackupPlanOption = ({ name, identifier: value, general: { name: arrowPlanName } = {} }) => ({
  label: name ?? arrowPlanName,
  value,
})

const RunBackupModal = ({ unmount, device, commonPolicy, fromDeviceSearch = false }) => {
  const mounted = useRef(true)
  const [disabled, setDisabled] = useState(false)
  const [backupPlan, setBackupPlan] = useState(null)

  const backupPlans = pathOr([], ["backup", "backupPlans"], commonPolicy).filter(
    ({ productCode, type, general }) => productCode === "LOCKHART" && !(type === "arrowImage" && !general?.enabled),
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const runBackupPlan = async () => {
    setDisabled(true)
    try {
      const { value: planId, label: planName } = backupPlan
      const { id: nodeId, name: nodeName } = device
      const backupJob = getBackupJob({ nodeId, nodeName, planId, planName })

      if (fromDeviceSearch) {
        await runDeviceSearchToolbarAction({
          action: "BACKUP_RUN_PLAN",
          data: { job: backupJob.jobs[0] },
        })
      } else {
        await submitJob([backupJob])
      }

      showSuccessMessage(localized("Backup job created successfully"))
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        showErrorMessage(localized("Error running backup job"))
        ninjaReportError(error)
      }
    } finally {
      mounted.current && setDisabled(false)
    }
  }

  return (
    <Modal
      title={localizationKey("Run Backup Plan")}
      disabledSaveBtn={!backupPlan || disabled}
      saveText={localizationKey("Run")}
      save={runBackupPlan}
      close={unmount}
      dialogClassName="no-overflow-modal run-backup-plan-modal"
    >
      <div className="text-left">
        <Select
          value={backupPlan}
          placeholder={localized("Select backup plan")}
          components={{
            NoOptionsMessage: () => <NoOptionsMessage messageToken={localizationKey("No backup plans available")} />,
          }}
          onChange={selected => setBackupPlan(selected)}
          options={sortBackupPlans(backupPlans).map(createBackupPlanOption)}
        />
      </div>
    </Modal>
  )
}

export default RunBackupModal
