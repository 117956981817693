import { memo, useCallback, useEffect } from "react"
import { Breadcrumb } from "@ninjaone/components"
import {
  isNumber,
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  useQueryParams,
} from "js/includes/common/utils"
import { useMountedState } from "js/includes/common/hooks"
import {
  allTicketsBoardId,
  devicesBoardId,
  organizationsBoardId,
} from "js/includes/configuration/integrations/ticketing/utils"
import { fetchDeviceById, getOrganization } from "js/includes/common/client"

const getBoardName = (id, boardTabs) => boardTabs.find(board => board.id === id)?.name || ""
const extraBoardIds = new Set([devicesBoardId, organizationsBoardId])

const getDeviceTicketingDashboardHistory = async (deviceId: number, ticketId: number) => {
  try {
    const { node } = await fetchDeviceById(deviceId)
    return node
      ? [
          { name: localized("Home"), path: "#/systemDashboard/overview" },
          { name: node.clientName, path: `#/customerDashboard/${node.clientId}/overview` },
          { name: node.name, path: `#/deviceDashboard/${deviceId}/overview` },
          { name: localized("Ticketing"), path: `#/deviceDashboard/${deviceId}/ticketing` },
          { name: `#${ticketId}`, path: "" },
        ]
      : []
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching device"))
  }
  return []
}

const getClientTicketingDashboardHistory = async (clientId: number, ticketId: number) => {
  try {
    const client = await getOrganization(clientId)
    return client
      ? [
          { name: localized("Home"), path: "#/systemDashboard/overview" },
          { name: client.name, path: `#/customerDashboard/${clientId}/overview` },
          { name: localized("Ticketing"), path: `#/customerDashboard/${clientId}/ticketing` },
          { name: `#${ticketId}`, path: "" },
        ]
      : []
  } catch (error) {
    reportErrorAndShowMessage(error, "ticketing.errorFetchingClient")
  }
  return []
}

export const TicketingBreadcrumb = memo(({ boardTabs, boardId, ticketId }) => {
  const [history, setHistory] = useMountedState([])
  const query = useQueryParams()
  const _boardIdQuery = parseInt(query.get("boardId"))
  const deviceIdQuery = parseInt(query.get("deviceId"))
  const clientIdQuery = parseInt(query.get("clientId"))

  const setExtraBoardsHistory = useCallback(
    async ticketId => {
      // Currently, the deviceId and clientId query parameters are set only from the Device and Organization Ticketing Dashboard, respectively.
      if (isNumber(deviceIdQuery)) {
        return setHistory(await getDeviceTicketingDashboardHistory(deviceIdQuery, ticketId))
      }

      isNumber(clientIdQuery) && setHistory(await getClientTicketingDashboardHistory(clientIdQuery, ticketId))
    },
    [deviceIdQuery, clientIdQuery, setHistory],
  )

  useEffect(() => {
    if (extraBoardIds.has(_boardIdQuery)) {
      return setExtraBoardsHistory(ticketId)
    }

    setHistory([
      { name: localized("Home"), path: "#/systemDashboard/overview" },
      { name: localized("Ticketing"), path: `#/ticketing/board/${allTicketsBoardId}` },
      ...(boardId
        ? [
            {
              name: getBoardName(boardId, boardTabs),
              path: `#/ticketing/board/${boardId}`,
            },
          ]
        : [
            {
              name: _boardIdQuery ? getBoardName(_boardIdQuery, boardTabs) : localized("All tickets"),
              path: `#/ticketing/board/${_boardIdQuery || allTicketsBoardId}`,
            },
            {
              name: `#${ticketId}`,
              path: "",
            },
          ]),
    ])
  }, [boardTabs, setHistory, boardId, ticketId, _boardIdQuery, setExtraBoardsHistory])

  return (
    <Breadcrumb
      {...{
        history,
        setHistory,
        disableLastLink: true,
        width: "50%",
      }}
    />
  )
})
