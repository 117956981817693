import { fetchJson, reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"

const url = "/discovery-job"

export const getDiscoveryJobs = async clientId => {
  try {
    return await fetchJson(`${url}/${clientId}/jobs`)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error loading discovery jobs list"))
    return []
  }
}

export const getDiscoveryJobsHistory = async clientId => {
  try {
    return await fetchJson(`${url}/${clientId}/jobs/history`)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error loading discovery jobs history"))
    return []
  }
}

export const saveDiscoveryJob = (job, id) =>
  fetchJson(`${url}${id ? `/${id}` : ""}`, {
    options: {
      method: id ? "PUT" : "POST",
      body: JSON.stringify(job),
    },
  })

export const deleteDiscoveryJob = id =>
  fetchJson(`${url}/${id}`, {
    options: {
      method: "DELETE",
    },
  })

export const getOuPathTreeData = (domainControllerId, ouPath, recursiveOuPath) =>
  fetchJson(
    `${url}/domain-controller/${domainControllerId}/tree?recursiveOuPath=${recursiveOuPath}${
      ouPath ? `&OUPath=${encodeURIComponent(ouPath)}` : ""
    }`,
  )

export const validateOUPath = (domainControllerId, ouPath) =>
  fetchJson(`${url}/domain-controller/${domainControllerId}/validate?OUPath=${encodeURIComponent(ouPath)}`)

export const validateDCCredentials = (domainControllerId, credentialId) =>
  fetchJson(`${url}/domain-controller/${domainControllerId}/credential/${credentialId}/validate`)
