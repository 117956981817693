import React from "react"
import styled from "@emotion/styled"
import { Form } from "react-bootstrap"
import { Radio } from "js/includes/components/NinjaReactICheck"
import { localized } from "js/includes/common/utils"
import { position, border, layout, flexbox, space, color, typography } from "../system"

export const Editor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 1024px;
`

export const Title = styled.div`
  font-size: 20px;
  margin: 25px 20px 0px;
  color: #000;
  ${position} 
  ${border}
  ${layout}
  ${flexbox} 
  ${space}
  ${color} 
  ${typography}
`

export const Content = styled.div`
  padding: 20px 10px 20px 20px;
  display: flex;
  flex: 1;
  ${position} 
  ${border}
  ${layout}
  ${flexbox} 
  ${space}
  ${color} 
  ${typography}
`

export const Aside = styled.aside`
  ${position} 
  ${border}
  ${layout}
  ${flexbox} 
  ${space}
  ${color} 
  ${typography}
`

export const Nav = styled.nav`
  ${position} 
  ${border}
  ${layout}
  ${flexbox} 
  ${space}
  ${color} 
  ${typography}
`

export const StyledContainer = styled.div`
  height: 100%;
  padding: 15px 15px 15px 30px;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const StyledRadio = styled(Radio)`
  margin: 10px 0;
`

export const StyledRadioSpan = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
`

export const StyledCheckboxSpan = styled.span`
  font-weight: normal;
`

export const StyledParagraph = styled.p`
  font-size: 12px;
  font-weight: normal;
  margin-left: 33px;
`

export const label = (title, description) => (
  <>
    <StyledRadioSpan>{localized(title)}</StyledRadioSpan>
    {description && <StyledParagraph>{localized(description)}</StyledParagraph>}
  </>
)

export const StyledFullScreenEditor = styled(Form)`
  min-width: 1024px;
  position: fixed;
  z-index: 2050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  > header {
    z-index: 1;
    height: 50px;
    background-color: ${({ theme }) => theme.colorThemeBackground};
    align-items: center;
    display: grid;
    grid-template-columns: 10px auto 1fr auto auto 10px;
    grid-column-gap: 10px;

    img {
      grid-column-start: 2;
      grid-column-end: 3;
      height: 24px;
    }

    button {
      &:first-of-type {
        grid-column-start: 4;
        grid-column-end: 5;
      }
      &:last-of-type {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }
  }

  > div {
    padding-bottom: 50px;
    overflow-y: auto;
    height: 100%;
    > h2 {
      display: flex;
      margin-left: calc(50% - 400px);
      font-size: 20px;
      color: ${({ theme }) => theme.palette.contrast};

      &.report-name {
        padding-bottom: 30px;
        position: sticky;
        top: 0;
        margin-top: 0;
        padding-top: 20px;
        background-color: #ffffff;
        z-index: 1;
      }
    }

    > .form-group,
    > section {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(.has-error) > label {
        color: ${({ theme }) => theme.palette.contrast};
      }

      > label {
        width: 300px;
        font-weight: 600;
        padding-left: 15px;

        &.align-top {
          align-self: start;
        }
      }
    }
  }
`
