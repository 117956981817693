import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Select from "react-select"
import { stateOptions, stateKeysToObject } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"
import { keysFromSeleted, arrayFromKeys } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import { map } from "ramda"

export default memo(({ values, onChange, validation, validateForm, isMulti = true }) => (
  <FormGroup controlId="stateSelect" validationState={validation.success.state === false ? "error" : null}>
    <Col componentClass={ControlLabel} sm={4}>
      {localized("State")}
    </Col>
    <Col sm={8}>
      <Select
        defaultValue={arrayFromKeys(values.state, stateKeysToObject)}
        className="flex-full"
        isClearable={false}
        isSearchable={false}
        options={map(option => {
          return { ...option, label: option.label() }
        }, stateOptions)}
        onChange={selected => onChange("state", keysFromSeleted(selected || []))}
        onBlur={() => validateForm(["state"])}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
      />
      <em className="invalid m-t-none">{validation.message.state}</em>
    </Col>
  </FormGroup>
))
