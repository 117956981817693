import { memo, useEffect } from "react"
import { isNil } from "ramda"
import { connect } from "react-redux"
import { useTheme } from "@emotion/react"
import { sizer } from "@ninjaone/utils"
import { Text, SearchInput } from "@ninjaone/components"
import { useMountedState } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"

import {
  localized,
  localizationKey,
  actionDialog,
  noop,
  sortByFieldNameCaseInsensitive,
  reportErrorAndShowMessage,
  dateTime,
} from "js/includes/common/utils"
import { getTicketingFormById } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import {
  getAttributeInitialValues,
  getStatusOptions,
  getTicketFormsOptions,
  getTypeOptions,
} from "js/includes/ticketing/shared/utils"
import { AssignIncidentModal, ButtonSelect } from "js/includes/ticketing/editor/shared/components"
import { Box, Flex, StyledSpan } from "js/includes/components/Styled"
import { getFilteredList } from "js/includes/ticketing/editor/shared/utils"
import { defaultTimerSettings } from "js/state/reducers/ticketing/configurations"
import { fetchTicketStatuses as _fetchTicketStatuses } from "js/state/actions/ticketing"
import { localizeSource } from "js/includes/ticketing/commonMethods"

import { StatusRowRenderer } from "./StatusRowRenderer"

export const _HeaderActions = memo(
  ({
    ticketNumber,
    type,
    status,
    ticketFormId,
    statusList,
    isEditable,
    hasIncidents,
    hasProblem,
    onChange = noop,
    onSave = noop,
    ticketForms = [],
    onUpdateTicketForm,
    timerMode,
    setTimerSettings,
    onClearTimer,
    fetchTicketStatuses,
    ticketSource,
    createTime,
  }) => {
    const hasIncidentsOrProblem = hasIncidents || hasProblem
    const isTicketUpdate = !isNil(ticketNumber)
    const timerWasNotAvailableBefore = isNil(timerMode)
    const theme = useTheme()

    useEffect(fetchTicketStatuses, [fetchTicketStatuses])

    const ticketFormsOptions = sortByFieldNameCaseInsensitive(
      "labelText",
      "ASC",
      getTicketFormsOptions(ticketForms, ticketFormId),
    )
    const [formsQuery, setFormsQuery] = useMountedState("")

    async function handleTypeChange(value) {
      if (type === value) return

      if (type === "INCIDENT") {
        const shouldChangeType = await actionDialog({
          title: localizationKey("Before you continue"),
          actionButtonLabel: localizationKey("Continue"),
          message: localizationKey("Changing the type will result in the ticket being detached from it's parent."),
          type: "warning",
        })
        if (!shouldChangeType) return
      }

      onChange({
        type: value === "NONE" ? null : value,
        ...(type === "INCIDENT" && value !== "INCIDENT" && { parentTicketId: null }),
      })

      if (value === "INCIDENT") {
        showModal(<AssignIncidentModal previousType={type} onSave={onSave} onChange={onChange} />)
      }
    }

    const filteredFormOptions = getFilteredList(formsQuery, ticketFormsOptions, "name")

    function updateTicketFormValues({ newFormId, isTimerEnabled, timerMode, attributes }) {
      setTimerSettings?.({
        isTimerEnabled,
        timerMode,
      })

      const attributeValues = getAttributeInitialValues(attributes)

      onChange({ ticketFormId: newFormId, attributeValues })
      onClearTimer && !isTimerEnabled && onClearTimer()
    }

    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding={`${tokens.spacing[4]} ${tokens.spacing[6]}`}
        backgroundColor={theme.colorBackground}
        borderBottom={`1px solid ${theme.colorBorderWeakest}`}
        {...(!hasIncidentsOrProblem && {
          borderTop: `1px solid ${theme.colorBorderWeakest}`,
        })}
        overflow="hidden"
      >
        <Box display="grid" gridAutoFlow="column" gridAutoColumns="min-content" gridGap={tokens.spacing[2]}>
          <ButtonSelect
            value={isNil(status) ? status : `${status}`}
            options={getStatusOptions(statusList, { hideClose: isEditable, valueAsString: true })}
            onChange={value => onChange({ status: parseInt(value, 10) })}
            valueRenderer={({ value, valueLabel }) => (
              <StatusRowRenderer {...{ value, valueLabel, isValueRenderer: true, maxWidth: "200px" }} />
            )}
            optionRenderer={({ value, valueLabel }) => (
              <StatusRowRenderer {...{ value, valueLabel, padding: sizer(0, 1) }} />
            )}
            disabled={!isEditable}
          />
          <ButtonSelect
            aria-label={localized("Type")}
            value={type || "NONE"}
            onChange={handleTypeChange}
            options={getTypeOptions({ showNone: true })}
            disabled={!isEditable || (type === "PROBLEM" && hasIncidents)}
            valueRenderer={({ value, valueLabel }) => (
              <span data-value={value}>
                <Text type="body">{localized("Type: {{type}}", { type: valueLabel })}</Text>
              </span>
            )}
          />
          <ButtonSelect
            value={ticketFormId?.toString()}
            options={filteredFormOptions}
            disabled={!isEditable}
            listboxHeaderRenderer={
              <Box paddingLeft={3} paddingRight={3}>
                <SearchInput
                  placeholder={localized("Search forms")}
                  flexDirection="row-reverse"
                  hasIcon
                  onChange={e => setFormsQuery(e.target.value)}
                  onKeyDown={e => e.stopPropagation()}
                />
              </Box>
            }
            valueRenderer={({ value, valueLabel }) => (
              <StyledSpan data-value={value} maxWidth="200px">
                <Text size="sm" key={value}>
                  {`${localized("Form")}: ${ticketFormsOptions?.find(formOption => formOption.id === ticketFormId)
                    ?.name ?? localized("n/a")}`}
                </Text>
              </StyledSpan>
            )}
            onChange={async val => {
              try {
                const newFormId = parseInt(val, 10)

                const ticketForm = await getTicketingFormById(newFormId)

                onUpdateTicketForm?.(ticketForm)

                const { enabled: isTimerEnabledFromTicketForm, mode: timerModeFromTicketForm } =
                  ticketForm?.content?.timerSettings ?? defaultTimerSettings

                const attributes = ticketForm.fields

                if (!isTicketUpdate) {
                  if (!isTimerEnabledFromTicketForm && !timerWasNotAvailableBefore) {
                    const shouldChangeForm = await actionDialog({
                      title: localizationKey("Before you continue"),
                      actionButtonLabel: localizationKey("Continue"),
                      message: localizationKey(
                        "You are changing to a ticket form with no timers. All timer data will be deleted.",
                      ),
                      type: "warning",
                    })

                    if (!shouldChangeForm) return
                  }

                  return updateTicketFormValues({
                    newFormId,
                    isTimerEnabled: isTimerEnabledFromTicketForm,
                    timerMode: timerModeFromTicketForm,
                    attributes,
                  })
                }

                if (isTimerEnabledFromTicketForm && timerWasNotAvailableBefore) {
                  const shouldChangeForm = await actionDialog({
                    title: localizationKey("Before you continue"),
                    actionButtonLabel: localizationKey("Continue"),
                    message: localizationKey("Timer controls will become available once this ticket has been saved."),
                    type: "warning",
                  })

                  if (!shouldChangeForm) return
                }

                if (!isTimerEnabledFromTicketForm && !timerWasNotAvailableBefore) {
                  const shouldChangeForm = await actionDialog({
                    title: localizationKey("Before you continue"),
                    actionButtonLabel: localizationKey("Continue"),
                    message: localizationKey(
                      "Changing this form with timers disabled will permanently delete all times entered for this ticket when the ticket is saved.",
                    ),
                    type: "warning",
                  })

                  if (!shouldChangeForm) return
                }

                updateTicketFormValues({
                  newFormId,
                  attributes,
                  isTimerEnabled: timerWasNotAvailableBefore ? false : isTimerEnabledFromTicketForm,
                  timerMode: timerModeFromTicketForm,
                })
              } catch (error) {
                reportErrorAndShowMessage(error, localizationKey("Failed to change form"))
              }
            }}
          />
        </Box>
        {ticketSource && (
          <Text type="body" color="colorTextWeakest" fontWeight={400}>
            {localized("Created by {{source}} on {{dateTime}}", {
              source: localizeSource(ticketSource),
              dateTime: dateTime(createTime),
            })}
          </Text>
        )}
      </Flex>
    )
  },
)

export const HeaderActions = connect(
  ({ ticketing }) => ({
    statusList: ticketing.status.list,
  }),
  {
    fetchTicketStatuses: _fetchTicketStatuses,
  },
)(_HeaderActions)
