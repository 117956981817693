import { useCallback, useEffect, useState } from "react"
import { mergeLeft } from "ramda"
import styled from "@emotion/styled"
import { COMMAND_PRIORITY_EDITOR, createCommand } from "lexical"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"

import tokens from "@ninjaone/tokens"

import AlertMessage from "../../AlertMessage"

const StyledContainer = styled.div`
  padding: ${tokens.spacing[4]};
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-top: none;
  border-bottom: none;
`

export const SET_MESSAGE_COMMAND = createCommand("SET_MESSAGE_COMMAND")
export const UPDATE_MESSAGE_COMMAND = createCommand("UPDATE_MESSAGE_COMMAND")
export const CLEAR_MESSAGE_COMMAND = createCommand("CLEAR_MESSAGE_COMMAND")

export const MessagePlugin = () => {
  const [editor] = useLexicalComposerContext()

  const [state, setState] = useState(null)

  const handleSetMessage = useCallback(payload => {
    setState(payload)
  }, [])

  const handleUpdateMessage = useCallback(payload => {
    setState(prevState => mergeLeft(payload, prevState))
  }, [])

  const handleClearMessage = useCallback(() => {
    setState(null)
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(SET_MESSAGE_COMMAND, handleSetMessage, COMMAND_PRIORITY_EDITOR),
      editor.registerCommand(UPDATE_MESSAGE_COMMAND, handleUpdateMessage, COMMAND_PRIORITY_EDITOR),
      editor.registerCommand(CLEAR_MESSAGE_COMMAND, handleClearMessage, COMMAND_PRIORITY_EDITOR),
    )
  }, [editor, handleSetMessage, handleUpdateMessage, handleClearMessage])

  return state ? (
    <StyledContainer>
      <AlertMessage {...state} />
    </StyledContainer>
  ) : null
}
