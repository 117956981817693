import PropTypes from "prop-types"
import { map } from "ramda"
import { Tags, Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { isNilOrEmpty, localizationKey, localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { ProxyDescriptionRow } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/descriptionItems"
import GlobalProxySwitchActivation from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/GlobalProxySwitchActivation"

const {
  spacing,
  typography: { fontWeight },
} = tokens

const getExcludedHosts = map(host => ({ id: host, label: host }))

function ProxyConfig({ proxy, type, isProxyEnabled, setIsProxyEnabled, isGlobalProxyInherited, updateActiveProxy }) {
  return (
    <Box>
      {type === "direct" && (
        <Box>
          <Text
            type="body"
            color="colorTextStrong"
            token={localizationKey("Direct Proxy")}
            fontWeight={fontWeight.medium}
          />
          {isGlobalProxyInherited && (
            <GlobalProxySwitchActivation {...{ isProxyEnabled, setIsProxyEnabled, updateActiveProxy }} />
          )}
          <Box marginTop={spacing[3]}>
            <ProxyDescriptionRow label={localized("Host")} value={proxy.host} />
            <ProxyDescriptionRow label={localized("Port")} value={proxy.port} />
            <ProxyDescriptionRow
              label={localized("Excluded Host")}
              value={
                isNilOrEmpty(proxy.excludedHosts) ? "-" : <Tags labels={getExcludedHosts(proxy.excludedHosts ?? [])} />
              }
            />
          </Box>
        </Box>
      )}
      {type === "pac" && (
        <Box>
          <Text
            type="body"
            color="colorTextStrong"
            token={localizationKey("Proxy auto-configuration (PAC)")}
            fontWeight={fontWeight.medium}
          />
          {isGlobalProxyInherited && (
            <GlobalProxySwitchActivation {...{ isProxyEnabled, setIsProxyEnabled, updateActiveProxy }} />
          )}
          <Box marginTop={spacing[3]}>
            <ProxyDescriptionRow label={localized("PAC URI")} value={proxy.pacUri} />
          </Box>
        </Box>
      )}
    </Box>
  )
}

ProxyConfig.propTypes = {
  proxy: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["direct", "pac"]).isRequired,
  setIsProxyEnabled: PropTypes.func.isRequired,
  isProxyEnabled: PropTypes.bool,
  isGlobalProxyInherited: PropTypes.bool,
}

export default ProxyConfig
