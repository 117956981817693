import { Tooltip, Text } from "@ninjaone/components"
import { RegularInfoCircleIconLight } from "@ninjaone/icons"

import { localizationKey, localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { PasswordQuality } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/enums"

const OptionRenderer = ({ labelToken, tooltipMessageToken }) => (
  <Flex justifyContent="space-between" width="100%" minWidth="250px" alignItems="baseline">
    <Text size="sm" token={labelToken} />
    <Box>
      <Tooltip label={localized(tooltipMessageToken)}>
        <RegularInfoCircleIconLight />
      </Tooltip>
    </Box>
  </Flex>
)

const passwordQualityLabels = {
  [PasswordQuality.PASSWORD_QUALITY_UNSPECIFIED]: {
    labelToken: localizationKey("Unspecified"),
    tooltipMessageToken: localizationKey("There are no password requirements."),
  },
  [PasswordQuality.BIOMETRIC_WEAK]: {
    labelToken: localizationKey("Biometric weak"),
    tooltipMessageToken: localizationKey(
      "The device must be secured with a low-security biometric recognition technology, at minimum. This includes technologies that can recognize the identity of an individual that are roughly equivalent to a 3-digit PIN (false detection is less than 1 in 1,000).",
    ),
  },
  [PasswordQuality.SOMETHING]: {
    labelToken: localizationKey("Something"),
    tooltipMessageToken: localizationKey(
      "A password is required, but there are no restrictions on what the password must contain.",
    ),
  },
  [PasswordQuality.NUMERIC]: {
    labelToken: localizationKey("Numeric"),
    tooltipMessageToken: localizationKey("The password must contain numeric characters."),
  },
  [PasswordQuality.NUMERIC_COMPLEX]: {
    labelToken: localizationKey("Numeric complex"),
    tooltipMessageToken: localizationKey(
      "The password must contain numeric characters with no repeating (4444) or ordered (1234, 4321, 2468) sequences.",
    ),
  },
  [PasswordQuality.ALPHABETIC]: {
    labelToken: localizationKey("Alphabetic"),
    tooltipMessageToken: localizationKey("The password must contain alphabetic (or symbol) characters."),
  },
  [PasswordQuality.ALPHANUMERIC]: {
    labelToken: localizationKey("Alphanumeric"),
    tooltipMessageToken: localizationKey(
      "The password must contain both numeric and alphabetic (or symbol) characters.",
    ),
  },
  [PasswordQuality.COMPLEX]: {
    labelToken: localizationKey("Complex"),
    tooltipMessageToken: localizationKey(
      "The password must meet the minimum requirements specified in Minimum Length, Minimum Letters, Minimum Symbols, etc.",
    ),
  },
  [PasswordQuality.COMPLEXITY_LOW]: {
    labelToken: localizationKey("Low complexity"),
    tooltipMessageToken: localizationKey(
      "Define the low password complexity band as pattern, PIN with repeating (4444) or ordered (1234, 4321, 2468) sequences. This sets the minimum complexity band which the password must meet.",
    ),
  },
  [PasswordQuality.COMPLEXITY_MEDIUM]: {
    labelToken: localizationKey("Medium complexity"),
    tooltipMessageToken: localizationKey(
      "Define the medium password complexity band as PIN with no repeating (4444) or ordered (1234, 4321, 2468) sequences, length at least 4, alphabetic. This sets the minimum complexity band which the password must meet.",
    ),
  },
  [PasswordQuality.COMPLEXITY_HIGH]: {
    labelToken: localizationKey("High complexity"),
    tooltipMessageToken: localizationKey(
      "Define the high password complexity band as (On Android 12 and above) PIN with no repeating (4444) or ordered (1234, 4321, 2468) sequences, length at least 8, alphanumeric. This sets the minimum complexity band which the password must meet.",
    ),
  },
}

export const passwordQualityOptions = Object.keys(passwordQualityLabels).map(value => {
  const { labelToken, tooltipMessageToken } = passwordQualityLabels[value]

  return {
    value,
    labelToken,
    LabelComponent: () => <OptionRenderer {...{ labelToken, tooltipMessageToken }} />,
  }
})
