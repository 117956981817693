import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import NumberStepper from "js/includes/editors/Policy/PolicyEditor/formComponents/NumberStepper"
import { uptimeDaysOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData/options"

export default memo(({ values, onChange, isDropdown = true, minValue = 1, maxValue = 120, stepper = 1 }) => (
  <FormGroup controlId="duration">
    <Col componentClass={ControlLabel} sm={4}>
      {localized("Days")}
    </Col>
    <Col sm={8}>
      {isDropdown ? (
        <Dropdown
          options={uptimeDaysOptions}
          initialValue={values.days}
          onChange={value => onChange("days", parseInt(value))}
        />
      ) : (
        <NumberStepper
          minValue={minValue}
          maxValue={maxValue}
          stepper={stepper}
          initialValue={values.days}
          onChange={value => onChange("days", parseInt(value))}
        />
      )}
    </Col>
  </FormGroup>
))
