import { groupBy, values } from "ramda"
import { isDateInPast, localized } from "js/includes/common/utils"

const APPROVED = "approved"
const REJECTED = "rejected"
const ENFORCED = "enforced"

const getGroupedUpdatesByStatus = manuallyScheduledUpdates =>
  groupBy(update => {
    if (update.rejectionDate) {
      return REJECTED
    }
    if (isDateInPast(update.enforcementDate)) {
      return ENFORCED
    }
    return APPROVED
  }, manuallyScheduledUpdates)

const statusUpdateTextGetters = {
  [APPROVED]: updateStatusLength =>
    updateStatusLength === 1
      ? localized("1 approved update")
      : localized("{{updateStatusLength}} approved updates", { updateStatusLength }),
  [REJECTED]: updateStatusLength =>
    updateStatusLength === 1
      ? localized("1 rejected update")
      : localized("{{updateStatusLength}} rejected updates", { updateStatusLength }),
  [ENFORCED]: updateStatusLength =>
    updateStatusLength === 1
      ? localized("1 enforced update")
      : localized("{{updateStatusLength}} enforced updates", { updateStatusLength }),
}

export const getCurrentUpdatesByStatus = updates => {
  const manuallyScheduledUpdates = values(updates)
  if (!manuallyScheduledUpdates.length) return []
  const groupedUpdatesByStatus = getGroupedUpdatesByStatus(manuallyScheduledUpdates)
  const placeholderByStatus = Object.keys(groupedUpdatesByStatus).reduce((acc, status) => {
    const updateStatusLength = groupedUpdatesByStatus[status].length
    const getStatusUpdateText = statusUpdateTextGetters[status]
    return {
      ...acc,
      [status]: getStatusUpdateText?.(updateStatusLength),
    }
  }, {})
  return [placeholderByStatus[APPROVED], placeholderByStatus[REJECTED], placeholderByStatus[ENFORCED]].filter(Boolean)
}
