import PropTypes from "prop-types"
import { Modal, Select } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { useForm } from "js/includes/common/hooks"
import { localizationKey, localized, validations } from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import { Box } from "js/includes/components/Styled"
import useAvailableVersions from "./hooks/useAvailableVersions"
import { getIdentifierByVersion } from "./util"

function SkipUpdateModal({ unmount, onSave, takenVersions }) {
  const { versions, isLoading } = useAvailableVersions(takenVersions)
  const { values, validation, validateForm, onChange } = useForm({
    fields: {
      version: "",
    },
    validate: {
      version: validations.required,
    },
  })
  return (
    <Modal
      titleGroup={{
        titleToken: localizationKey("Skip update to specific iOS version"),
      }}
      size="md"
      unmount={unmount}
      buttons={[
        {
          labelToken: localizationKey("Add"),
          onClick: () => {
            if (validateForm()) {
              const newUpdate = {
                ...values,
                identifier: getIdentifierByVersion(values.version, versions),
              }
              onSave(newUpdate)
              unmount()
            }
          },
          variant: "primary",
        },
      ]}
      cancelable
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
      ) : (
        <Box marginTop={spacing[2]}>
          <Select
            labelId="edit-version"
            placeholderToken={localizationKey("Select version")}
            labelText={localized("Version")}
            value={values.version}
            onChange={value => onChange("version", value)}
            options={versions}
            disabled={isLoading}
            errorMessage={validation.message.version}
            required
          />
        </Box>
      )}
    </Modal>
  )
}
export default SkipUpdateModal

SkipUpdateModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  takenVersions: PropTypes.array.isRequired,
}
