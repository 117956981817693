const faWebroot = {
  prefix: "fac",
  iconName: "webroot",
  icon: [
    200,
    200,
    [],
    "0002",
    `M100.017,198.864C45.884,199.555,0.842,154.109,1.06,99.631C1.275,45.857,46.012,0.791,100.692,1.13 c53.586,0.333,98.628,44.996,98.246,99.687C198.565,154.376,154.103,199.504,100.017,198.864z M136.969,118.01 c-0.268-0.016-0.535-0.032-0.803-0.048c-2.043-4.451-4.104-8.894-6.126-13.354c-5.567-12.275-11.138-24.547-16.649-36.847 c-0.716-1.598-1.721-2.148-3.432-2.118c-5.055,0.089-10.114,0.084-15.17,0.002c-1.642-0.027-2.585,0.524-3.267,2.045 c-6.818,15.185-13.688,30.347-20.555,45.509c-0.734,1.62-1.543,3.207-2.546,5.283c-0.576-1.168-1.006-1.832-1.248-2.559 c-2.981-8.952-6.139-17.852-8.845-26.887c-3.422-11.428-6.481-22.964-9.666-34.463c-0.309-1.118-0.797-1.782-2.051-1.778 c-7.754,0.019-15.508,0.015-23.262,0.009c-1.491-0.001-1.889,0.703-1.494,2.078c0.465,1.619,0.817,3.271,1.289,4.888 c4.773,16.372,9.348,32.806,14.406,49.09c4.897,15.768,10.292,31.381,15.477,47.059c1.007,3.044,3.2,4.792,6.285,5.35 c1.914,0.346,3.873,0.615,5.812,0.619c5.258,0.012,8.847-2.127,11.111-7.293c8.275-18.887,16.897-37.622,25.401-56.408 c0.173-0.383,0.5-0.696,0.832-1.146c0.287,0.369,0.493,0.552,0.597,0.781c5.322,11.72,10.638,23.443,15.95,35.167 c3.56,7.857,7.127,15.711,10.66,23.58c0.968,2.156,2.502,3.726,4.718,4.434c4.168,1.332,8.383,1.162,12.54-0.111 c2.339-0.716,3.917-2.331,4.762-4.618c1.238-3.351,2.462-6.707,3.643-10.078c9.404-26.867,17.041-54.276,25.013-81.583 c1.108-3.794,2.086-7.626 3.142-11.504c-0.536-0.151-0.786-0.283-1.036-0.284c-8.091-0.014-16.182-0.007-24.273-0.027 c-1.286-0.003-1.687,0.757-2,1.838c-4.994,17.218-9.974,34.441-15.065,51.631C139.942,110.243,138.363 114.099,136.969,118.01z`,
  ],
}

export default faWebroot
