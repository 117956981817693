import qs from "qs"
import { pick, defaultTo, find, propEq } from "ramda"
import {
  billingTypes,
  agreementOriginTypes,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { unassignedTicketsBoardUrl } from "js/includes/configuration/integrations/ticketing/utils"

export function createTicketTimeEntry() {
  return {
    remote: true,
    agreementOriginType: agreementOriginTypes.TICKET,
    startDate: Date.now(),
    agreementId: null,
    billingType: billingTypes.BILLABLE,
  }
}

export const getEditTicketPagePath = ({ ticketId, withHashTag = false }) => {
  return `${withHashTag ? "#" : ""}/ticketing/ticket/${ticketId}`
}

export const getNewTicketPagePath = ({ queryParams, withHashTag = false } = {}) => {
  const goBackPath = `#${unassignedTicketsBoardUrl}`
  window.history.pushState({ path: goBackPath }, "", goBackPath)

  const definedQueryParams = pick(["deviceId", "jobUid", "clientId", "formId"], queryParams || {})

  const queryString = qs.stringify(definedQueryParams, { addQueryPrefix: true })
  return `${withHashTag ? "#" : ""}/ticketing/ticket/new${queryString}`
}

export const findDefaultAgreement = (agreements = []) => defaultTo(null, find(propEq("timeDefault", true), agreements))
