import { PropTypes } from "prop-types"
import { T, always, compose, cond, equals, filter, gt, lte, pluck } from "ramda"

import { Modal, Text } from "@ninjaone/components"
import { VARIANTS } from "@ninjaone/components/src/Button"
import { sizer } from "@ninjaone/utils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import styled from "@emotion/styled"

import { localizationKey, localized, localizedWith } from "js/includes/common/utils"
import { AndroidPolicyAppsApplicationSources as ApplicationSources } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

const APPS_LIST_LIMIT = 20

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.color.black["005"]};
  border-radius: ${sizer(1)};
  color: ${({ theme }) => theme.color.black["100"]};
  margin-top: ${sizer(4)};
  max-height: 240px;
  overflow: auto;
  padding: ${sizer(2)};
`

const getModalTitle = (appsLength, totalInstalled) => {
  const titleToken = cond([
    [equals(1), always(localizationKey("Remove app?"))],
    [equals(totalInstalled), always(localizationKey("Remove all {{length}} apps?"))],
    [T, always(localizationKey("Remove {{length}} apps?"))],
  ])(appsLength)
  return localized(titleToken, {
    length: appsLength,
  })
}

export const getRemoveConfirmationMessage = (apps, totalInstalled) => {
  const appsLength = apps.length
  if (appsLength === 1) {
    const { name, packageName, applicationSource } = apps[0]
    const isSystemApp = applicationSource === ApplicationSources.SYSTEM_APP
    const getFinalMessage = text =>
      localizedWith(text, {
        appName: () => (name || packageName ? <strong>{name || packageName}</strong> : localized("one application")),
        bold: ({ localizedText }) => <strong>{localizedText}</strong>,
      })

    if (isSystemApp) {
      return getFinalMessage(
        "You are about to remove <%appName>appName<%>. Once removed and the policy is saved, the system app <%bold>will remain in the assignment state currently configured<%>, so blocked apps will remain blocked, and preinstalled apps will remain available. Please ensure the desired assignment type is set before continuing.",
      )
    }

    // Public / Private / Web app
    return getFinalMessage(
      "You are about to remove <%appName>appName<%>. Once removed and the policy is saved, the app <%bold>will be uninstalled from all devices associated with this policy<%>. This action can not be undone.",
    )
  }
  const messageToken = cond([
    [
      equals(totalInstalled),
      always(localized("all {{length}} apps associated with this policy", { length: totalInstalled })),
    ],
    [compose(gt(1), lte(APPS_LIST_LIMIT)), always(localized("the following {{length}} apps", { length: appsLength }))],
    [compose(gt(APPS_LIST_LIMIT), lte(totalInstalled)), always(localized("{{length}} apps", { length: appsLength }))],
  ])
  const message = messageToken(appsLength)
  const getFinalMessage = text =>
    localizedWith(text, {
      msg: () => <strong>{message}</strong>,
      bold: ({ localizedText }) => <strong>{localizedText}</strong>,
    })

  const systemAppsCount = apps.filter(({ applicationSource }) => applicationSource === ApplicationSources.SYSTEM_APP)
    .length

  // Only system apps selected
  if (appsLength === systemAppsCount) {
    return getFinalMessage(
      "You are about to remove <%msg>message<%>. Once removed and the policy is saved, these system apps <%bold>will remain in the assignment state currently configured<%>, so blocked apps will remain blocked, and preinstalled apps will remain available. Please ensure the desired assignment type is set before continuing.",
    )
  }

  // Only public / private / web app apps selected
  if (systemAppsCount === 0) {
    return getFinalMessage(
      "You are about to remove <%msg>message<%>. Once removed and the policy is saved, the apps <%bold>will be uninstalled from all devices associated with this policy<%>. This action can not be undone.",
    )
  }

  // Both system and public / private / web app apps selected
  return getFinalMessage(
    "You are about to remove <%msg>message<%>. Once removed and the policy is saved, system apps will remain <%bold>in their current assignment state<%>, while public, web, and private apps from Google Play <%bold>will be uninstalled from all devices associated with this policy<%>. Please ensure the desired assignment states are set for system apps before continuing. This action cannot be undone. ",
  )
}

function DeleteAppModal({ selectedApps, updateApplicationsPolicy, appPolicy, osType, unmount }) {
  const updateSelectedApplicationPolicies = (selectedApps, applications) => {
    return compose(
      selectedAppsIds => filter(app => !selectedAppsIds.includes(app.packageName), applications),
      pluck("packageName"),
    )(selectedApps)
  }
  const handleRemove = () => {
    const selectedAppsPolicyUpdate = updateSelectedApplicationPolicies(selectedApps, appPolicy?.applications || [])
    updateApplicationsPolicy({ ...appPolicy, applications: selectedAppsPolicyUpdate })
    unmount()
  }
  const isAppsListVisible = selectedApps.length > 1 && selectedApps.length <= APPS_LIST_LIMIT

  return (
    <Modal
      size="sm"
      titleGroup={{
        titleToken: getModalTitle(selectedApps.length, appPolicy.applications.length),
      }}
      unmount={unmount}
      cancelable
      buttons={[
        {
          type: "save",
          labelToken: selectedApps.length === 1 ? localizationKey("Remove app") : localizationKey("Remove apps"),
          onClick: handleRemove,
          variant: VARIANTS.DANGER,
        },
      ]}
    >
      <Text size="sm" color={{ code: "black", shade: "100" }} textWrap>
        {getRemoveConfirmationMessage(selectedApps, appPolicy.applications.length)}
      </Text>
      {isAppsListVisible && (
        <StyledBox>
          {selectedApps.map(({ id, name, packageName }) => (
            <Text key={id} color={{ code: "black", shade: "100" }} size="sm" textWrap>
              {name || packageName}
            </Text>
          ))}
        </StyledBox>
      )}
    </Modal>
  )
}

DeleteAppModal.propTypes = {
  appPolicy: PropTypes.shape({
    applications: PropTypes.array,
    inheritance: PropTypes.object,
  }),
  selectedApps: PropTypes.array,
  osType: PropTypes.oneOf(["Apple", "Android"]),
  unmount: PropTypes.func,
  updateApplicationsPolicy: PropTypes.func,
}

DeleteAppModal.defaultProps = {
  selectedApps: [],
  osType: "Apple",
  appPolicy: {
    applications: [],
    inheritance: { inherited: false, overridden: false, sourcePolicyId: null },
  },
}

export default DeleteAppModal
