import { map } from "ramda"

export const formatAdhocTaskIntoCompatibleScripts = map(task => ({
  ...task,
  uid: task.id,
  jobStatus: "START_REQUESTED",
  jobType: "ACTIONSET",
  message: task.name,
  content: {
    scripts: task.scripts.actionList,
  },
  isAdHocTask: true,
  nodeId: task.targetId,
  nodeName: task.targetName,
}))
