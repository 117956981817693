import { filter } from "ramda"

import { Modal, Select, TextArea } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

import { useForm } from "js/includes/common/hooks"
import { isNotNilOrEmpty, localizationKey, localized, showSuccessMessage, validations } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import { defaultInheritance, getAvailableLanguages } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

const languageOptions = getAvailableLanguages()

export default function AddOrEditLanguageModal({
  unmount,
  updateLanguageItem,
  languageKeys = [],
  editLanguageItem = {},
}) {
  const {
    values: { language, localeMessage },
    validation,
    onChange,
    validateForm,
  } = useForm({
    fields: {
      language: editLanguageItem?.language ?? "",
      localeMessage: editLanguageItem?.message ?? "",
    },
    validate: {
      language: validations.required,
      localeMessage: validations.required,
    },
  })

  const filteredOptions = !!languageKeys
    ? filter(({ value }) => !languageKeys.includes(value))(languageOptions)
    : languageOptions

  const isEditModal = isNotNilOrEmpty(editLanguageItem)

  const handleSave = () => {
    if (!validateForm()) return
    updateLanguageItem(language, {
      message: localeMessage.trim(),
      language,
      ...(isEditModal ? { inheritance: editLanguageItem.inheritance } : defaultInheritance),
    })
    showSuccessMessage(
      isEditModal ? localized("Language updated successfully.") : localized("Language added successfully."),
    )
    unmount()
  }

  return (
    <Modal
      buttons={[
        {
          type: "primary",
          onClick: handleSave,
          labelToken: isEditModal ? localizationKey("Update") : localizationKey("Add"),
        },
      ]}
      titleGroup={{
        titleToken: isEditModal ? localizationKey("Edit message") : localizationKey("Add language"),
      }}
      unmount={unmount}
      cancelable
    >
      <Flex flexDirection="column" gap={spacing[4]}>
        <Select
          labelText={localized("Language")}
          options={filteredOptions}
          value={language}
          placeholderToken={localizationKey("Select language")}
          onChange={value => onChange("language", value)}
          disabled={isEditModal}
          errorMessage={validation.message.language}
          labelId="languageOptions"
          required
        />
        <TextArea
          labelToken={localizationKey("Message")}
          value={localeMessage}
          rows={2}
          resize="none"
          onChange={e => onChange("localeMessage", e.target.value)}
          useCharacterCount={false}
          errorMessage={validation.message.localeMessage}
          required
        />
      </Flex>
    </Modal>
  )
}
