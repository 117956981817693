import { memo } from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled/macro"
import tokens from "@ninjaone/tokens"

import NavigationTab from "./NavigationTab"
import GuideTooltip from "./GuideTooltip"
import { getTabListStyles } from "./Styled"
import HoverDropdown from "./HoverDropdown/HoverDropdown"

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledTabList = styled.nav`
  ${({ theme }) => getTabListStyles({ theme })}

  [data-ninja-hover-dropdown-button] button.active {
    position: relative;
    font-weight: ${tokens.typography.fontWeight.medium};
  }
`

const NavigationTabs = ({ activeLink, links, wrapTabs }) => {
  return (
    <StyledTabList {...(wrapTabs && { className: "wrapped-tabs" })}>
      {links.map(({ id, labelToken, labelText, hide, route, onClick, DropdownComponent, guideTooltipProps }) => {
        const hasDropdown = !!DropdownComponent

        const isHidden = typeof hide === "function" ? hide() : hide
        const isActive = activeLink?.id === id

        const key = id || labelToken || labelText

        const guideTooltip = isActive && guideTooltipProps && (
          <GuideTooltip
            {...{
              ...guideTooltipProps,
              anchorRenderer: () => <div />,
              position: "bottom",
              align: "start",
              sideOffset: 0,
            }}
          />
        )

        return (
          !isHidden && (
            <StyledTabContainer key={key}>
              {hasDropdown ? (
                <>
                  <HoverDropdown
                    {...{
                      route,
                      labelText,
                      labelToken,
                      dropdownMargin: true,
                      dropdownRenderer: ({ tabRoute }) => <DropdownComponent {...{ tabRoute }} />,
                      buttonRenderer: props => (
                        <NavigationTab
                          {...{ ...props, isActive, labelText, labelToken, showArrow: hasDropdown, guideTooltip }}
                        />
                      ),
                    }}
                  />
                  {guideTooltip}
                </>
              ) : (
                <>
                  <NavigationTab {...{ isActive, labelToken, labelText, route, onClick, guideTooltip }} />
                  {guideTooltip}
                </>
              )}
            </StyledTabContainer>
          )
        )
      })}
    </StyledTabList>
  )
}

export default memo(NavigationTabs)

NavigationTabs.propTypes = {
  activeLink: PropTypes.object,
  links: PropTypes.array.isRequired,
}
