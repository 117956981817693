import { useEffect, useRef } from "react"
import { $createTextNode, $getRoot, $insertNodes } from "lexical"
import { $createCodeNode } from "@lexical/code"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { isNilOrEmptyOrBlank } from "@ninjaone/webapp/src/js/includes/common/utils"
import { getPrettierOptions, loadPrettierFormat, loadPrettierParser } from "../utils/prettier"

export const loadXMLFormat = async () => {
  const format = await loadPrettierFormat()
  const options = getPrettierOptions("xml")
  const xmlPlugin = await loadPrettierParser("xml")
  options.plugins = [xmlPlugin.default ?? xmlPlugin]
  return xmlString => {
    return format(xmlString, options)
  }
}

/**
 * Check if xmlString has a valid XML format.
 * @param {String} xmlString
 * @returns {String} validated XML
 */
export const validateXML = xmlString => {
  if (isNilOrEmptyOrBlank(xmlString)) return ""
  const xmlDoc = new window.DOMParser().parseFromString(xmlString, "application/xml")
  if (xmlDoc.querySelector("parsererror")) {
    throw Error("Error parsing XML")
  }
  return new window.XMLSerializer().serializeToString(xmlDoc)
}

// TODO: replace with loadXMLFormat using prettier.js witn xml plugin
// Using this solution for now given that an error accurs with the production build when using prettier:
// `Error: ES Modules may not assign module.exports or exports.*, Use ESM export syntax, instead`
/**
 * @param {String} xmlString
 * @returns {String} Indented XML string
 */
export const indentXMLString = xmlString => {
  let formatted = ""
  let indent = ""
  for (const node of xmlString.split(/>\s*</)) {
    if (node.match(/^\/\w/)) indent = indent.substring(1)
    formatted += indent + "<" + node + ">\n"
    if (node.match(/^<?\w[^>]*[^/]$/)) indent += "\t"
  }
  return formatted.substring(1, formatted.length - 2)
}

export const XMLParserPlugin = ({ initialXML, onParseError }) => {
  const parsed = useRef(false)
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (initialXML && !parsed.current) {
      const setInitialXML = async () => {
        let resultXML = initialXML
        try {
          resultXML = indentXMLString(validateXML(initialXML))
        } catch (error) {
          onParseError?.(error)
        }
        editor.update(() => {
          parsed.current = true
          const codeNode = $createCodeNode("xml")

          const textNode = $createTextNode(resultXML)
          codeNode.append(textNode)

          const rootNode = $getRoot().clear()
          rootNode.selectStart()
          $insertNodes([codeNode])
        })
      }
      setInitialXML()
    }
  }, [initialXML, editor, onParseError])

  return null
}
