import styled from "@emotion/styled"
import { colors, spacing } from "js/includes/common/theme"
import {
  position,
  border,
  layout,
  flexbox,
  space,
  color,
  typography,
  shadow,
  shouldForwardProp,
  hover,
  grid,
} from "./system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const StyledSpan = styled.span`
  ${space}
  ${position}
  ${border}
  ${flexbox}
  ${layout}
  ${color}
  ${typography}
  ${hover}
  ${grid}
`

export const BorderTile = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 10px;
  border-left: 7px solid ${({ color }) => color || colors.ninjaLight};
  ${border}
  ${flexbox}
  ${layout}
`

export const FlexFull = {
  display: "flex",
  flex: 1,
}

export const StyledImage = styled.img`
  width: ${({ width }) => width || "100%"};
  ${space}
  ${position}
  ${border}
  ${flexbox}
  ${layout}
  ${shadow}
`

export const Box = styled.div`
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${color}
  ${typography}
  ${shadow}
  ${hover}
  ${grid}
`

export const Flex = styled(Box)`
  display: flex;
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon, { shouldForwardProp })`
  ${space}
  ${layout}
  ${typography}
  ${color}
`

export const StyledSeparator = styled.span`
  width: 100%;
  border-top: 1px dashed ${colors.ninjaLight};
  margin-top: ${({ top }) => spacing(top || 3)};
  margin-bottom: ${({ bottom }) => spacing(bottom || 3)};
`

export const StyledTruncatedTextContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const StyledAnchor = styled.a`
  color: ${({ color, theme }) => colors[color] || color || theme.colorTextAction};
  ${position}
  ${border}
  ${layout}
  ${flexbox}
  ${space}
  ${typography}

  &:hover {
    color: ${({ theme, hoverTextColor }) => colors[hoverTextColor] || hoverTextColor || theme.colorTextActionStrong};
    background-color: ${({ hoverBackgroundColor }) => colors[hoverBackgroundColor] ?? "rgba(0,0,0,0)"};
  }

  &:disabled {
    color: ${colors.ninjaLight};
  }
`

export const StyledHeadingGroup = styled.hgroup`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
`

export const StyledHeading = styled.h1`
  ${space}
  ${layout}
  ${color}
  ${typography}
`

export const ShowChildOnContainerHover = styled(Box)`
  ${({ hoverActionClass, display }) =>
    hoverActionClass &&
    `
    .${hoverActionClass} { 
      display: none;
    } 
    &:hover {
      .${hoverActionClass} { 
        display: ${display ?? "none"};
      }
    }
  `}
`

export const FetchingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colorBackground}CC;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
`

export const visuallyHiddenStyles = `
  position: absolute;
  border: 0px;
  width: 1px;
  height: 1px;
  padding: 0px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  overflow-wrap: normal;
`
