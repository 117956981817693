import styled from "@emotion/styled"
import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable"

import tokens from "@ninjaone/tokens"

const StyledLexicalContentEditable = styled(LexicalContentEditable)`
  border: 0;
  display: block;
  position: relative;
  outline: 0;
  padding: ${tokens.spacing[2]} ${tokens.spacing[4]} ${tokens.spacing[9]};
  min-height: 150px;
  background-color: ${({ theme }) => theme.colorBackgroundWidget};

  hr {
    /* Reset Bootstrap */
    height: auto;

    border: none;
    margin: ${tokens.spacing[4]} 0;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      height: 2px;
      background-color: ${({ theme }) => theme.colorBorderWeak};
      line-height: ${tokens.typography.lineHeight};
    }
  }

  /* Class selected is injected via Lexical */
  hr.selected {
    outline: 2px solid ${({ theme }) => theme.colorBorderDecorativeWeak};
    user-select: none;
  }
`

export const ContentEditable = props => {
  return <StyledLexicalContentEditable className="editor-root" {...props} />
}
