import { useState } from "react"
import PropTypes from "prop-types"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"
import DataTable from "../../DataTable/DataTable"
import DashboardWidget, { widgetPropTypes } from "./DashboardWidget"

const DataTableWidget = ({ dataTableProps, errorMessage, ...widgetProps }) => {
  const [searchBarVisible, setSearchBarVisible] = useState(false)

  return (
    <DashboardWidget onSearchButtonClick={() => setSearchBarVisible(state => !state)} {...widgetProps}>
      <Box flex={1} minHeight={1}>
        <DataTable
          {...{
            ...dataTableProps,
            showSearchBar: searchBarVisible,
            searchBarFullWidth: true,
            searchBarAutoFocus: true,
            error: errorMessage,
          }}
        />
      </Box>
    </DashboardWidget>
  )
}

DataTableWidget.propTypes = {
  ...widgetPropTypes,
  dataTableProps: PropTypes.object,
}

export default DataTableWidget
