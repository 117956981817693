import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import ReduxInheritable from "./ReduxInheritable"
import styled from "@emotion/styled"

export const INHERITANCE_WIDTH = "200px"

export const StyledFlex = styled(Flex)`
  padding: ${({ padding }) => padding ?? `${tokens.spacing[5]} ${tokens.spacing[2]}`};
  ${({ noBorder, theme, borderColor }) => !noBorder && `border-bottom: 1px solid ${theme[borderColor]};`}
  .revertButton {
    visibility: hidden;
  }
  &:hover .revertButton {
    visibility: visible;
  }
`

const InheritableRowPolicyItem = ReduxInheritable(
  ({
    inheritanceElement,
    children,
    testId,
    wrapperProps = {},
    inheritanceComponentWrapperProps = { width: INHERITANCE_WIDTH },
    hasMultipleConfigs = false,
    className,
    borderColor = "colorBorderWeakest",
    childrenWrapperProps,
    noBorder = false,
    flexChildrenItem = false,
  }) => (
    <StyledFlex
      {...{ className, borderColor, noBorder }}
      data-testid={testId}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      {...wrapperProps}
    >
      {flexChildrenItem ? (
        <Flex justifyContent="start" width="100%" {...childrenWrapperProps}>
          {children}
        </Flex>
      ) : (
        <Box width="100%" {...childrenWrapperProps}>
          {children}
        </Box>
      )}

      <Flex
        flexShrink="0"
        justifyContent="end"
        {...(hasMultipleConfigs && { alignSelf: "flex-start", marginTop: tokens.spacing[2] })}
        marginRight={tokens.spacing[2]}
        {...inheritanceComponentWrapperProps}
      >
        <Box>{inheritanceElement}</Box>
      </Flex>
    </StyledFlex>
  ),
)

InheritableRowPolicyItem.propTypes = {
  inheritanceElement: PropTypes.shape({
    inheritance: PropTypes.shape({
      inherited: PropTypes.bool,
      overridden: PropTypes.bool,
      sourcePolicyId: PropTypes.number,
    }),
  }),
  testId: PropTypes.string,
  wrapperProps: PropTypes.object,
  inheritanceComponentWrapperProps: PropTypes.object,
  noBorder: PropTypes.bool,
}

export default InheritableRowPolicyItem
