import React, { memo } from "react"
import { DurationWithUnit } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"

export default memo(({ values, onChange, minValue }) => (
  <DurationWithUnit
    {...{
      values,
      onChange,
      minValue,
    }}
  />
))
