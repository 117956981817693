import { memo } from "react"
import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { batteryStatusOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  text-align: left;
  align-items: center;

  @media (max-width: 767px) {
    grid-gap: ${sizer(1)};
    grid-template-columns: 100%;
    text-align: center;
  }
`

export default memo(({ values, onChange, validation }) => (
  <StyledContainer>
    <Text size="sm" extraBold>
      {localized("Battery Status")}
    </Text>
    <Dropdown
      options={batteryStatusOptions}
      initialValue={values.status}
      onChange={value => onChange("status", parseInt(value))}
    />
  </StyledContainer>
))
