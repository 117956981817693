import { localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization/autocomplete"

export const TOKEN_ERROR_MSG_REQUIRED = localizationKey("Required input")
export const TOKEN_ERROR_MSG_INVALID = localizationKey("Invalid date")
export const TOKEN_ERROR_MSG_INVALID_RANGE = localizationKey("Invalid date range")
export const TOKEN_ERROR_MSG_OUTSIDE = localizationKey("Date out of range")
export const TOKEN_ERROR_MSG_DATETIME_REQUIRED = localizationKey("Date & time required")
export const TOKEN_ERROR_MSG_DATE_REQUIRED = localizationKey("Date required")
export const TOKEN_ERROR_MSG_TIME_REQUIRED = localizationKey("Time required")
export const TOKEN_ERROR_MSG_SAME =  localizationKey("Date range can not be same day")
