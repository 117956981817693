import { fetchJson } from "js/includes/common/utils"

export const getItamTags = async (detailed = false) => {
  return fetchJson(`/itam-tags?detailed=${detailed}`)
}

export const createItamTag = itamTag => {
  return fetchJson("/itam-tags", {
    options: {
      method: "POST",
      body: JSON.stringify(itamTag),
    },
  })
}

export const updateItamTag = (itamTagId, updatedItamTag) => {
  return fetchJson(`/itam-tags/${itamTagId}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(updatedItamTag),
    },
  })
}

export const deleteItamTag = itamTagId => {
  return fetchJson(`/itam-tags/${itamTagId}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const deleteItamTags = itamTagIds => {
  return fetchJson("/itam-tags/delete", {
    options: {
      method: "POST",
      body: JSON.stringify(itamTagIds),
    },
  })
}

export const mergeItamTags = mergeTagsRequest => {
  return fetchJson("/itam-tags/merge", {
    options: {
      method: "POST",
      body: JSON.stringify(mergeTagsRequest),
    },
  })
}

export const saveItamTagsForEntity = (assetType, assetId, itamTagIds) => {
  return fetchJson(`/itam-tags/${assetType}/${assetId}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(itamTagIds),
    },
  })
}
