import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { localized } from "js/includes/common/utils"

const DirectionSelect = ({ values, onChange, directionOptions }) => {
  return (
    <FormGroup controlId="type">
      <Col componentClass={ControlLabel} sm={4}>
        {localized("Direction")}
      </Col>
      <Col sm={8}>
        <Dropdown options={directionOptions} initialValue={values.type} onChange={value => onChange("type", value)} />
      </Col>
    </FormGroup>
  )
}

export default DirectionSelect
