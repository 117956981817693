import { useTheme } from "@emotion/react"
import tokens from "@ninjaone/tokens"
import { getColorFromProps } from "@ninjaone/utils"
import Body from "../Typography/Body"

export default function PercentageBar({ barHeight = "20px", barColor, labelText, percentage }) {
  const theme = useTheme()

  return (
    <div>
      {labelText && (
        <div style={{ marginBottom: tokens.spacing[1] }}>
          <Body fontWeight={tokens.typography.fontWeight.medium}>{labelText}</Body>
        </div>
      )}
      <div
        style={{
          height: barHeight,
          backgroundColor: theme.colorAlertNeutralWeak,
          borderRadius: tokens.borderRadius[1],
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={barHeight}
          width="100%"
          style={{ borderRadius: tokens.borderRadius[1] }}
        >
          <rect
            width={`${percentage}%`}
            height={barHeight}
            fill={getColorFromProps({ defaultTo: theme.colorAlertInfo }, { color: barColor, theme })}
          />
        </svg>
      </div>
    </div>
  )
}
