export const addSelectedPolicyScript = script => ({
  type: "ADD_CONDITION_SCRIPT",
  script,
})

export const removeSelectedPolicyScript = script => ({
  type: "REMOVE_CONDITION_SCRIPT",
  script,
})

export const editSelectedPolicyScript = script => ({
  type: "EDIT_CONDITION_SCRIPT",
  script,
})

export const setSelectedPolicyScripts = scripts => ({
  type: "SET_CONDITION_SCRIPTS",
  scripts,
})

export const reorderSelectedPolicyScripts = scripts => ({
  type: "REORDER_CONDITION_SCRIPTS",
  scripts,
})
