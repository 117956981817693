import { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled"
import tokens, { spacingBase } from "@ninjaone/tokens"

export const resizerSeparatorWidth = tokens.spacing[2]

const StyledResizeSeparator = styled.hr`
  margin: -${tokens.spacing[4]} -${tokens.spacing[1]} -${tokens.spacing[8]};
  height: calc(100% + 48px);
  cursor: col-resize;
  border: 0;
  &::before,
  &:hover::before {
    content: "";
    display: block;
    height: 100%;
    margin: 0 6px;
    ${({ theme, active }) => active && `background-color: ${theme.colorForegroundFocus}`};
  }
  &:hover::before {
    background-color: ${({ theme }) => theme.colorForegroundFocus};
  }
`

function clearSelection() {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange()
    range.collapse()
    range.select()
  } else if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges()
    }
  } else if (document.selection) {
    document.selection.empty()
  }
}

export const ResizerSeparator = ({ minSize = 0, maxSize, onResize, containerRef, onDragEnd, onDragStart }) => {
  const [resizing, setResizing] = useState(false)

  const computeNewSize = useCallback(
    ({ containerRect, mousePosition }) => {
      const offset = mousePosition - containerRect.left - spacingBase * 2
      return Math.min(Math.max(offset | 0, minSize), maxSize)
    },
    [maxSize, minSize],
  )

  const handleMouseDown = () => {
    clearSelection()
    setResizing(true)
    onDragStart?.()
  }

  const handleMouseUp = useCallback(() => {
    setResizing(false)
    onDragEnd?.()
  }, [onDragEnd, setResizing])

  const handleMouseMove = useCallback(
    mouseEvent => {
      if (resizing) {
        const newSize = computeNewSize({
          containerRect: containerRef.current.getBoundingClientRect(),
          mousePosition: mouseEvent.clientX,
        })
        onResize(newSize)
        clearSelection()
      }
    },
    [computeNewSize, containerRef, resizing, onResize],
  )

  const handleTouchMove = useCallback(touchEvent => handleMouseMove(touchEvent.changedTouches[0]), [handleMouseMove])

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp)
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("touchend", handleMouseUp)
    document.addEventListener("touchmove", handleTouchMove)

    return () => {
      document.removeEventListener("mouseup", handleMouseUp)
      document.removeEventListener("mousemove", handleMouseMove)
      document.removeEventListener("touchend", handleMouseUp)
      document.removeEventListener("touchmove", handleTouchMove)
    }
  }, [handleMouseMove, handleMouseUp, handleTouchMove])

  return <StyledResizeSeparator active={resizing} onMouseDown={handleMouseDown} onTouchStart={handleMouseDown} />
}

ResizerSeparator.propTypes = {
  minSize: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired,
  onResize: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(HTMLElement) }).isRequired,
}
