import { useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import styled from "@emotion/styled"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useLexicalEditable } from "@lexical/react/useLexicalEditable"
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getNodeTriplet,
  $getTableCellNodeFromLexicalNode,
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  $isTableCellNode,
  $isTableRowNode,
  $isTableSelection,
  $unmergeCell,
  getTableObserverFromTableElement,
  TableCellHeaderStates,
  TableCellNode,
} from "@lexical/table"
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
} from "lexical"

import { ChevronDownLightIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../Text"

import { invariant } from "../utils"
import { StyledDropdown, StyledDropdownItem } from "../Components"
import { StyledHr } from "../styled"

function computeSelectionCount(selection) {
  const selectionShape = selection.getShape()

  return {
    columns: selectionShape.toX - selectionShape.fromX + 1,
    rows: selectionShape.toY - selectionShape.fromY + 1,
  }
}

// This is important when merging cells as there is no good way to re-merge weird shapes (a result of selecting merged cells and non-merged)
function isTableSelectionRectangular(selection) {
  const nodes = selection.getNodes()
  const currentRows = []
  let currentRow = null
  let expectedColumns = null
  let currentColumns = 0

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if ($isTableCellNode(node)) {
      const row = node.getParentOrThrow()
      invariant($isTableRowNode(row), "Expected CellNode to have a RowNode parent")
      if (currentRow !== row) {
        if (expectedColumns !== null && currentColumns !== expectedColumns) return false

        if (currentRow !== null) {
          expectedColumns = currentColumns
        }
        currentRow = row
        currentColumns = 0
      }
      const colSpan = node.__colSpan
      for (let j = 0; j < colSpan; j++) {
        if (currentRows[currentColumns + j] === undefined) {
          currentRows[currentColumns + j] = 0
        }
        currentRows[currentColumns + j] += node.__rowSpan
      }
      currentColumns += colSpan
    }
  }
  return (
    (expectedColumns === null || currentColumns === expectedColumns) && currentRows.every(v => v === currentRows[0])
  )
}

function $canUnmerge() {
  const selection = $getSelection()
  if (
    ($isRangeSelection(selection) && !selection.isCollapsed()) ||
    ($isTableSelection(selection) && !selection.anchor.is(selection.focus)) ||
    (!$isRangeSelection(selection) && !$isTableSelection(selection))
  )
    return false

  const [cell] = $getNodeTriplet(selection.anchor)
  return cell.__colSpan > 1 || cell.__rowSpan > 1
}

function $cellContainsEmptyParagraph(cell) {
  if (cell.getChildrenSize() !== 1) return false

  const firstChild = cell.getFirstChildOrThrow()
  if (!$isParagraphNode(firstChild) || !firstChild.isEmpty()) return false

  return true
}

function $selectLastDescendant(node) {
  const lastDescendant = node.getLastDescendant()
  if ($isTextNode(lastDescendant)) {
    lastDescendant.select()
  } else if ($isElementNode(lastDescendant)) {
    lastDescendant.selectEnd()
  } else if (lastDescendant !== null) {
    lastDescendant.selectNext()
  }
}

const TableActionMenu = ({
  containerClassName,
  onClose,
  setIsMenuOpen,
  contextRef,
  cellMerge,
  tableCellNode: _tableCellNode,
}) => {
  const [editor] = useLexicalComposerContext()
  const dropDownRef = useRef(null)
  const [tableCellNode, updateTableCellNode] = useState(_tableCellNode)
  const [selectionCounts, updateSelectionCounts] = useState({
    columns: 1,
    rows: 1,
  })
  const [canMergeCells, setCanMergeCells] = useState(false)
  const [canUnmergeCell, setCanUnmergeCell] = useState(false)

  useEffect(() => {
    return editor.registerMutationListener(
      TableCellNode,
      nodeMutations => {
        const nodeUpdated = nodeMutations.get(tableCellNode.getKey()) === "updated"

        if (nodeUpdated) {
          editor.getEditorState().read(() => {
            updateTableCellNode(tableCellNode.getLatest())
          })
        }
      },
      { skipInitialization: true },
    )
  }, [editor, tableCellNode])

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()
      // Merge cells
      if ($isTableSelection(selection)) {
        const currentSelectionCounts = computeSelectionCount(selection)
        updateSelectionCounts(computeSelectionCount(selection))
        setCanMergeCells(
          isTableSelectionRectangular(selection) &&
            (currentSelectionCounts.columns > 1 || currentSelectionCounts.rows > 1),
        )
      }
      // Unmerge cell
      setCanUnmergeCell($canUnmerge())
    })
  }, [editor])

  useEffect(() => {
    const menuButtonElement = contextRef.current
    const dropDownElement = dropDownRef.current
    const rootElement = editor.getRootElement()

    if (menuButtonElement != null && dropDownElement != null && rootElement != null) {
      const rootEleRect = rootElement.getBoundingClientRect()
      const menuButtonRect = menuButtonElement.getBoundingClientRect()
      dropDownElement.style.opacity = "1"
      const dropDownElementRect = dropDownElement.getBoundingClientRect()
      const margin = 4
      let leftPosition = menuButtonRect.right + margin
      if (
        leftPosition + dropDownElementRect.width > window.innerWidth ||
        leftPosition + dropDownElementRect.width > rootEleRect.right
      ) {
        const position = menuButtonRect.left - dropDownElementRect.width - margin
        leftPosition = (position < 0 ? margin : position) + window.pageXOffset
      }
      dropDownElement.style.left = `${leftPosition + window.pageXOffset}px`

      let topPosition = menuButtonRect.top
      if (topPosition + dropDownElementRect.height > window.innerHeight) {
        const position = menuButtonRect.bottom - dropDownElementRect.height
        topPosition = (position < 0 ? margin : position) + window.pageYOffset
      }
      dropDownElement.style.top = `${topPosition + +window.pageYOffset}px`
    }
  }, [contextRef, dropDownRef, editor])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropDownRef.current != null &&
        contextRef.current != null &&
        !dropDownRef.current.contains(event.target) &&
        !contextRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false)
      }
    }

    window.addEventListener("click", handleClickOutside)

    return () => window.removeEventListener("click", handleClickOutside)
  }, [setIsMenuOpen, contextRef])

  const clearTableSelection = useCallback(() => {
    editor.update(() => {
      if (tableCellNode.isAttached()) {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
        const tableElement = editor.getElementByKey(tableNode.getKey())

        if (!tableElement) throw new Error("Expected to find tableElement in DOM")

        const tableSelection = getTableObserverFromTableElement(tableElement)
        if (tableSelection !== null) {
          tableSelection.clearHighlight()
        }

        tableNode.markDirty()
        updateTableCellNode(tableCellNode.getLatest())
      }

      const rootNode = $getRoot()
      rootNode.selectStart()
    })
  }, [editor, tableCellNode])

  const mergeTableCellsAtSelection = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isTableSelection(selection)) {
        const { columns, rows } = computeSelectionCount(selection)
        const nodes = selection.getNodes()
        let firstCell = null
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i]
          if ($isTableCellNode(node)) {
            if (firstCell === null) {
              node.setColSpan(columns).setRowSpan(rows)
              firstCell = node
              const isEmpty = $cellContainsEmptyParagraph(node)
              let firstChild
              if (isEmpty && $isParagraphNode((firstChild = node.getFirstChild()))) {
                firstChild.remove()
              }
            } else if ($isTableCellNode(firstCell)) {
              const isEmpty = $cellContainsEmptyParagraph(node)
              if (!isEmpty) {
                firstCell.append(...node.getChildren())
              }
              node.remove()
            }
          }
        }
        if (firstCell !== null) {
          if (firstCell.getChildrenSize() === 0) {
            firstCell.append($createParagraphNode())
          }
          $selectLastDescendant(firstCell)
        }
        onClose()
      }
    })
  }

  const unmergeTableCellsAtSelection = () => {
    editor.update(() => {
      $unmergeCell()
    })
  }

  const insertTableRowAtSelection = useCallback(
    shouldInsertAfter => {
      editor.update(() => {
        $insertTableRow__EXPERIMENTAL(shouldInsertAfter)
        onClose()
      })
    },
    [editor, onClose],
  )

  const insertTableColumnAtSelection = useCallback(
    shouldInsertAfter => {
      editor.update(() => {
        for (let i = 0; i < selectionCounts.columns; i++) {
          $insertTableColumn__EXPERIMENTAL(shouldInsertAfter)
        }
        onClose()
      })
    },
    [editor, onClose, selectionCounts.columns],
  )

  const deleteTableRowAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableRow__EXPERIMENTAL()
      onClose()
    })
  }, [editor, onClose])

  const deleteTableAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
      tableNode.remove()

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableColumn__EXPERIMENTAL()
      onClose()
    })
  }, [editor, onClose])

  const toggleTableRowIsHeader = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

      const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode)

      const tableRows = tableNode.getChildren()

      if (tableRowIndex >= tableRows.length || tableRowIndex < 0)
        throw new Error("Expected table cell to be inside of table row.")

      const tableRow = tableRows[tableRowIndex]

      if (!$isTableRowNode(tableRow)) throw new Error("Expected table row")

      tableRow.getChildren().forEach(tableCell => {
        if (!$isTableCellNode(tableCell)) throw new Error("Expected table cell")

        tableCell.toggleHeaderStyle(TableCellHeaderStates.ROW)
      })

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  const toggleTableColumnIsHeader = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

      const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode)

      const tableRows = tableNode.getChildren()
      const maxRowsLength = Math.max(...tableRows.map(row => row.getChildren().length))

      if (tableColumnIndex >= maxRowsLength || tableColumnIndex < 0)
        throw new Error("Expected table cell to be inside of table row.")

      for (let r = 0; r < tableRows.length; r++) {
        const tableRow = tableRows[r]

        if (!$isTableRowNode(tableRow)) throw new Error("Expected table row")

        const tableCells = tableRow.getChildren()
        if (tableColumnIndex >= tableCells.length) {
          // if cell is outside of bounds for the current row (for example various merge cell cases) we shouldn't highlight it
          continue
        }

        const tableCell = tableCells[tableColumnIndex]

        if (!$isTableCellNode(tableCell)) throw new Error("Expected table cell")

        tableCell.toggleHeaderStyle(TableCellHeaderStates.COLUMN)
      }

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  let mergeCellButton = null

  if (cellMerge) {
    if (canMergeCells) {
      mergeCellButton = (
        <StyledDropdownItem onClick={() => mergeTableCellsAtSelection()}>
          <Text size="sm" color="colorTextWeak">
            {localized("Merge cells")}
          </Text>
        </StyledDropdownItem>
      )
    } else if (canUnmergeCell) {
      mergeCellButton = (
        <StyledDropdownItem onClick={() => unmergeTableCellsAtSelection()}>
          <Text size="sm" color="colorTextWeak">
            {localized("Unmerge cells")}
          </Text>
        </StyledDropdownItem>
      )
    }
  }

  return createPortal(
    <StyledDropdown ref={dropDownRef} className={containerClassName} onClick={e => e.stopPropagation()}>
      {mergeCellButton && (
        <>
          {mergeCellButton}
          <StyledHr />
        </>
      )}
      <StyledDropdownItem onClick={() => insertTableRowAtSelection(false)}>
        <Text size="sm" color="colorTextWeak">
          {selectionCounts.rows === 1
            ? localized("Insert row above")
            : localized("Insert {{amount}} rows above", { amount: selectionCounts.rows })}
        </Text>
      </StyledDropdownItem>
      <StyledDropdownItem onClick={() => insertTableRowAtSelection(true)}>
        <Text size="sm" color="colorTextWeak">
          {selectionCounts.rows === 1
            ? localized("Insert row below")
            : localized("Insert {{amount}} rows below", { amount: selectionCounts.rows })}
        </Text>
      </StyledDropdownItem>
      <StyledHr />
      <StyledDropdownItem onClick={() => insertTableColumnAtSelection(false)}>
        <Text size="sm" color="colorTextWeak">
          {selectionCounts.columns === 1
            ? localized("Insert column left")
            : localized("Insert {{amount}} columns left", { amount: selectionCounts.columns })}
        </Text>
      </StyledDropdownItem>
      <StyledDropdownItem onClick={() => insertTableColumnAtSelection(true)}>
        <Text size="sm" color="colorTextWeak">
          {selectionCounts.columns === 1
            ? localized("Insert column right")
            : localized("Insert {{amount}} columns right", { amount: selectionCounts.columns })}
        </Text>
      </StyledDropdownItem>
      <StyledHr />
      <StyledDropdownItem onClick={() => deleteTableColumnAtSelection()}>
        <Text size="sm" color="colorTextWeak">
          {localized("Delete column")}
        </Text>
      </StyledDropdownItem>
      <StyledDropdownItem onClick={() => deleteTableRowAtSelection()}>
        <Text size="sm" color="colorTextWeak">
          {localized("Delete row")}
        </Text>
      </StyledDropdownItem>
      <StyledDropdownItem onClick={() => deleteTableAtSelection()}>
        <Text size="sm" color="colorTextWeak">
          {localized("Delete table")}
        </Text>
      </StyledDropdownItem>
      <StyledHr />
      <StyledDropdownItem onClick={() => toggleTableRowIsHeader()}>
        <Text size="sm" color="colorTextWeak">
          {(tableCellNode.__headerState & TableCellHeaderStates.ROW) === TableCellHeaderStates.ROW
            ? localized("Remove row header")
            : localized("Add row header")}
        </Text>
      </StyledDropdownItem>
      <StyledDropdownItem onClick={() => toggleTableColumnIsHeader()}>
        <Text size="sm" color="colorTextWeak">
          {(tableCellNode.__headerState & TableCellHeaderStates.COLUMN) === TableCellHeaderStates.COLUMN
            ? localized("Remove column header")
            : localized("Add column header")}
        </Text>
      </StyledDropdownItem>
    </StyledDropdown>,
    document.body,
  )
}

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
`

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${tokens.borderRadius[1]};
  display: flex;
  height: ${tokens.spacing[4]};
  cursor: pointer;
  border: 0;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`

const TableCellActionMenuContainer = ({ anchorElem, cellMerge, containerClassName }) => {
  const [editor] = useLexicalComposerContext()

  const menuButtonRef = useRef(null)
  const menuRootRef = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [tableCellNode, setTableMenuCellNode] = useState(null)

  const $moveMenu = useCallback(() => {
    const menu = menuButtonRef.current
    const selection = $getSelection()
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (selection == null || menu == null) {
      setTableMenuCellNode(null)
      return
    }

    const rootElement = editor.getRootElement()

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(selection.anchor.getNode())

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null)
        return
      }

      const tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey())

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null)
        return
      }

      setTableMenuCellNode(tableCellNodeFromSelection)
    } else if (!activeElement) {
      setTableMenuCellNode(null)
    }
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        $moveMenu()
      })
    })
  })

  useEffect(() => {
    const menuButtonDOM = menuButtonRef.current

    if (menuButtonDOM != null && tableCellNode != null) {
      const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey())

      if (tableCellNodeDOM != null) {
        const tableCellRect = tableCellNodeDOM.getBoundingClientRect()
        const menuRect = menuButtonDOM.getBoundingClientRect()
        const anchorRect = anchorElem.getBoundingClientRect()

        const top = tableCellRect.top - anchorRect.top + 4
        const left = tableCellRect.right - menuRect.width - 4 - anchorRect.left

        menuButtonDOM.style.opacity = "1"
        menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`
      } else {
        menuButtonDOM.style.opacity = "0"
        menuButtonDOM.style.transform = "translate(-10000px, -10000px)"
      }
    }
  }, [menuButtonRef, tableCellNode, editor, anchorElem])

  const prevTableCellDOM = useRef(tableCellNode)

  useEffect(() => {
    if (prevTableCellDOM.current !== tableCellNode) {
      setIsMenuOpen(false)
    }

    prevTableCellDOM.current = tableCellNode
  }, [prevTableCellDOM, tableCellNode])

  return (
    <StyledContainer ref={menuButtonRef}>
      {tableCellNode != null && (
        <>
          <StyledButton
            ref={menuRootRef}
            type="button"
            onClick={e => {
              e.stopPropagation()
              setIsMenuOpen(prevState => !prevState)
            }}
          >
            <ChevronDownLightIcon size="xs" color="colorTextStrong" />
          </StyledButton>
          {isMenuOpen && (
            <TableActionMenu
              containerClassName={containerClassName}
              contextRef={menuRootRef}
              setIsMenuOpen={setIsMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              tableCellNode={tableCellNode}
              cellMerge={cellMerge}
            />
          )}
        </>
      )}
    </StyledContainer>
  )
}

export const TableActionMenuPlugin = ({ anchorElem = document.body, cellMerge = false, containerClassName }) => {
  const isEditable = useLexicalEditable()
  return createPortal(
    isEditable ? <TableCellActionMenuContainer {...{ anchorElem, cellMerge, containerClassName }} /> : null,
    anchorElem,
  )
}
