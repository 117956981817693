import { always, cond, T, __, gte, lt, both, compose, is, toString, when, anyPass, equals } from "ramda"
import { colors } from "js/includes/common/theme"

export const statusNameMapper = {
  NEW: "NEW",
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  WAITING: "WAITING",
  PAUSED: "PAUSED",
  RESOLVED: "RESOLVED",
}

export const systemStatusMapper = {
  NEW: 1000,
  OPEN: 2000,
  WAITING: 3000,
  PAUSED: 4000,
  RESOLVED: 5000,
  CLOSED: 6000,
}

export const isNewStatus = both(gte(__, systemStatusMapper.NEW), lt(__, 2000))
export const isOpenStatus = both(gte(__, systemStatusMapper.OPEN), lt(__, 3000))
export const isWaitingStatus = both(gte(__, systemStatusMapper.WAITING), lt(__, 4000))
export const isPausedStatus = both(gte(__, systemStatusMapper.PAUSED), lt(__, 5000))
export const isResolvedStatus = both(gte(__, systemStatusMapper.RESOLVED), lt(__, 6000))
export const isClosedStatus = both(gte(__, systemStatusMapper.CLOSED), lt(__, 7000))

export const getStatusType = cond([
  [isNewStatus, always(statusNameMapper.NEW)],
  [isOpenStatus, always(statusNameMapper.OPEN)],
  [isWaitingStatus, always(statusNameMapper.WAITING)],
  [isPausedStatus, always(statusNameMapper.PAUSED)],
  [isResolvedStatus, always(statusNameMapper.RESOLVED)],
  [isClosedStatus, always(statusNameMapper.CLOSED)],
  [T, always(null)],
])

export const isTicketStatus = compose(
  anyPass([isNewStatus, isOpenStatus, isWaitingStatus, isPausedStatus, isResolvedStatus, isClosedStatus]),
  when(is(Number), toString),
)

export const getStatusBackgroundFromType = cond([
  [equals(statusNameMapper.NEW), always(colors.ninjaRed)],
  [equals(statusNameMapper.OPEN), always(colors.ninjaYellow)],
  [equals(statusNameMapper.WAITING), always(colors.ninjaBlueSaturated)],
  [equals(statusNameMapper.PAUSED), always(colors.ninjaMedium)],
  [equals(statusNameMapper.RESOLVED), always(colors.ninjaGreen)],
  [equals(statusNameMapper.CLOSED), always(colors.ninjaWhite)],
])

export const getStatusBackgroundFromId = cond([
  [isNewStatus, always(colors.ninjaRed)],
  [isOpenStatus, always(colors.ninjaYellow)],
  [isWaitingStatus, always(colors.ninjaBlueSaturated)],
  [isPausedStatus, always(colors.ninjaMedium)],
  [isResolvedStatus, always(colors.ninjaGreen)],
  [isClosedStatus, always(colors.ninjaWhite)],
])

export const statusShortHand = status => status[0]
