import { all, always, any, compose, cond, filter, pluck, T } from "ramda"
import { createSelector } from "reselect"

import {
  isAndroidDevice,
  isCloudMonitor,
  isDownWindowsDevice,
  isIosDevice,
  isLinuxDevice,
  isLinuxServer,
  isLinuxWorkstation,
  isMac,
  isMacDevice,
  isMacServer,
  isRmmDevice,
  isUpWindowsDevice,
  isUpWindowsOrMacDevice,
  isUpWindowsOrMacOrLinuxDevice,
  isVmHyperVGuest,
  isVmHyperVHost,
  isVmMonitorDelegate,
  isVmWareGuest,
  isVmWareHost,
  isWindowsDevice,
  isWindowsServer,
  isWindowsWorkstation,
} from "js/includes/common/utils"

const allAreDownWindowsAgents = all(isDownWindowsDevice)

const getAllDevices = state => state.devices
const getAllSelectedDevices = state => state.selectedDevices

export const getSelectedFilteredDevices = createSelector(
  [getAllDevices, getAllSelectedDevices],
  (devices, selectedDevices) => devices.filter(({ id }) => selectedDevices.includes(id)),
)

export const getSelectedFilteredDevicesIds = createSelector([getSelectedFilteredDevices], pluck("id"))

export const getSelectedFilteredDevicesNodeClasses = createSelector([getSelectedFilteredDevices], pluck("nodeClass"))

export const getSelectedFilteredDevicesNodeRoleIds = createSelector([getSelectedFilteredDevices], pluck("nodeRoleId"))

export const anyMonitorDelegateSelected = createSelector([getSelectedFilteredDevices], selectedDevices => {
  return !!selectedDevices.length && any(isVmMonitorDelegate, selectedDevices)
})

export const allSelectedDevicesInSameDeviceType = createSelector(
  [getSelectedFilteredDevices],
  compose(
    cond([
      [all(isWindowsServer), always("WINDOWS_SERVER")],
      [all(isWindowsWorkstation), always("WINDOWS_WORKSTATION")],
      [all(isMac), always("MAC")],
      [all(isMacServer), always("MAC_SERVER")],
      [all(isLinuxServer), always("LINUX_SERVER")],
      [all(isLinuxWorkstation), always("LINUX_WORKSTATION")],
      [all(isVmWareHost), always("VMWARE_VM_HOST")],
      [all(isVmWareGuest), always("VMWARE_VM_GUEST")],
      [all(isVmHyperVHost), always("HYPERV_VMM_HOST")],
      [all(isVmHyperVGuest), always("HYPERV_VMM_GUEST")],
      [all(isAndroidDevice), always("ANDROID")],
      [all(isIosDevice), always("APPLE_IOS")],
      [T, () => ""],
    ]),
    pluck("nodeClass"),
  ),
)

export const allSelectedDevicesInSameOSFamily = createSelector(
  [getSelectedFilteredDevices],
  compose(
    cond([
      [all(isWindowsDevice), always("Windows")],
      [all(isMacDevice), always("MAC")],
      [all(isLinuxDevice), always("Linux")],
      [all(isVmWareHost), always("VMWARE_VM_HOST")],
      [all(isVmHyperVHost), always("HYPERV_VMM_HOST")],
      [all(isAndroidDevice), always("ANDROID")],
      [all(isIosDevice), always("APPLE_IOS")],
      [T, () => ""],
    ]),
    pluck("nodeClass"),
  ),
)

export const anySelectedDeviceisDownWindowsOrMacAgent = createSelector(
  [getSelectedFilteredDevices],
  selectedDevices => !!selectedDevices.length && allAreDownWindowsAgents(selectedDevices),
)

export const allSelectedDevicesAreRmmDevices = createSelector(
  [getSelectedFilteredDevices],
  selectedDevices =>
    !!selectedDevices.length && compose(all(isRmmDevice), pluck("nodeClass"))(selectedDevices) && selectedDevices,
)

export const allSelectedDevicesHaveAlerts = createSelector([getSelectedFilteredDevices], selectedDevices =>
  selectedDevices.every(d => !!d.alertMessage),
)

export const singleSelectedDeviceIsMonitor = createSelector([getSelectedFilteredDevices], selectedDevices =>
  selectedDevices.length === 1 && isCloudMonitor(selectedDevices[0].nodeClass) ? selectedDevices[0] : null,
)

export const getOnlyUpWindowsOrLinuxOrMacFilteredSelectedDevices = createSelector(
  [getSelectedFilteredDevices],
  filter(isUpWindowsOrMacOrLinuxDevice),
)

export const anySelectedDeviceIsAgent = createSelector([getSelectedFilteredDevices], selectedDevices =>
  selectedDevices.every(device => device.nodeType === "AGENT"),
)

export const allUpDevicesAreWindows = createSelector([getSelectedFilteredDevices], all(isUpWindowsDevice))

export const allUpDevicesAreWindowsOrMac = createSelector([getSelectedFilteredDevices], all(isUpWindowsOrMacDevice))
