import React, { memo } from "react"
import {
  NameSelect,
  StateSelect,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"

export default memo(({ values, onChange, validation, validateForm }) => (
  <>
    <NameSelect
      {...{
        values,
        onChange,
        validation,
        validateForm,
      }}
    />
    <StateSelect
      {...{
        values,
        onChange,
        validation,
        validateForm,
      }}
    />
  </>
))
