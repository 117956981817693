import { connect } from "react-redux"
import PropTypes from "prop-types"
import { assocPath, compose, pathEq, when, path } from "ramda"
import { Button, Tags, Text } from "@ninjaone/components"
import { UndoLightIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "./Styled"
import { localizationKey, localized } from "js/includes/common/utils"

const overrideIfInherited = when(
  pathEq(["inheritance", "inherited"], true),
  assocPath(["inheritance", "overridden"], true),
)

const DefaultLabelComponent = ({ children }) => <>{children}</>

export default function withInheritance(Component) {
  const WrappedComponent = props => {
    const {
      onChange,
      inheritableItem,
      labelToken,
      disabledRevert,
      LabelComponent = DefaultLabelComponent,
      gridColumns = `2fr ${tokens.spacing[6]} 5fr 1fr 2fr`,
      parentPolicySettings,
    } = props

    const revert = () => {
      const { inheritance } = inheritableItem
      const currentParentPolicySettings = inheritance.parentPolicySettings ?? parentPolicySettings
      const revertedObject = {
        ...currentParentPolicySettings,
        ...{
          inheritance: {
            ...inheritance,
            overridden: false,
          },
        },
      }
      onChange(revertedObject)
    }

    const { inheritance } = inheritableItem || {}
    const { inherited, overridden } = inheritance || {}

    return (
      <Box display="grid" gridTemplateColumns={gridColumns}>
        <Box gridColumn="1/2">
          <LabelComponent>
            <Text
              textWrap
              color="colorTextStrong"
              type="body"
              token={labelToken}
              fontWeight={tokens.typography.fontWeight.medium}
            />
          </LabelComponent>
        </Box>
        <Box gridColumn="3/4">
          <Component
            {...{
              ...props,
              onChange: compose(onChange, overrideIfInherited),
            }}
          />
        </Box>
        <Box gridColumn="5/6" height={25}>
          {inherited && (
            <Flex justifyContent="end">
              {overridden && (
                <Box marginRight={tokens.spacing[2]}>
                  <Button
                    disabled={disabledRevert}
                    variant="tertiary"
                    labelToken={localizationKey("Revert")}
                    Icon={UndoLightIcon}
                    onClick={revert}
                    compact
                  />
                </Box>
              )}
              <Tags
                labels={[
                  {
                    id: "inheritedTag",
                    variant: overridden ? "alternate" : "default",
                    label: overridden ? localized("Overridden") : localized("Inherited"),
                  },
                ]}
              />
            </Flex>
          )}
        </Box>
      </Box>
    )
  }

  WrappedComponent.propTypes = withInheritance.propTypes

  return connect(({ policyEditor }, { itemPath }) => {
    if (!policyEditor.parentPolicy || !itemPath) return {}
    const parentPolicySettings = path(itemPath.split("."), policyEditor.parentPolicy)
    return {
      parentPolicySettings,
    }
  })(WrappedComponent)
}

withInheritance.propTypes = {
  labelToken: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  LabelComponent: PropTypes.elementType,
  gridColumns: PropTypes.string,
  inheritableItem: PropTypes.shape({
    inheritance: PropTypes.shape({
      inherited: PropTypes.bool.isRequired,
      overridden: PropTypes.bool.isRequired,
      parentPolicySettings: PropTypes.object,
    }).isRequired,
  }).isRequired,
}
