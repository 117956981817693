import { fetch, fetchJson, ninjaReportError } from "js/includes/common/utils"

export const isPromotionOpen = promotionName => {
  const promotions = window.application.get("session").attributes.user.content.promotions || []
  const currentPromotion = promotions.find(promotion => promotion.name === promotionName)
  return !currentPromotion?.closed
}

export const notifySales = async divisionUid => {
  try {
    await Promise.all([
      fetch(`/division/trial-banner-request-call/${divisionUid}`, { options: { method: "POST" } }),
      fetch(`/division/trial-banner/buy-now/${divisionUid}`),
    ])
  } catch (error) {
    ninjaReportError(error)
  }
}

export const fetchReferralProgramPulse = async () => {
  try {
    return await fetchJson("/division/referral-program/")
  } catch (error) {
    ninjaReportError(error)
  }
}

export const sendTrackingToSalesforce = async () => {
  try {
    await fetchJson("/division/referral-program/", {
      options: {
        method: "POST",
      },
    })
  } catch (error) {
    ninjaReportError(error)
  }
}

export const storeProductSpecialist = productSpecialist => {
  if (productSpecialist) {
    localStorage.setItem("productSpecialist", JSON.stringify(productSpecialist))
  }
}

export const updatePromotionBannerToNotShow = async ids => {
  const promosList = ids.map(name => ({ name, closed: true }))
  try {
    await fetchJson("/appuser/content/promotions", {
      options: {
        method: "PATCH",
        body: JSON.stringify({
          additionPromotions: promosList,
        }),
      },
    })
  } catch (error) {
    ninjaReportError(error)
  }
}

export const sendAdditionalFeedbackToSalesforce = async feedbackMessage => {
  await fetchJson("/division/getting-started/additionalFeedback", {
    options: {
      method: "POST",
      body: feedbackMessage,
    },
  })
}

export const sendFeedbackToSalesforce = async thumbsUpOrThumbsDown => {
  await fetchJson("/division/getting-started/feedback", {
    options: {
      method: "POST",
      body: thumbsUpOrThumbsDown ? "Yes" : "No",
    },
  })
}
