import { color as deprecatedBaseColor } from "@ninjaone/themes/base"
import { color as lightColor } from "@ninjaone/themes/light"
import { colors } from "../colors"
import spacing from "../spacing"

const brandingColor = {
  colorThemeText: "#CAD0D6",
  colorThemeTextSelected: "#FFFFFF",
  colorThemeTextWeak: "#B3BCC1",
  colorThemeTextStrong: "#FFFFFF",
  colorThemeBackground: "#002A42",
  colorThemeBackgroundHover: "#002033",
  colorThemeBackgroundSelected: "#002033",
  colorThemeBorder: "#053856",
}

const themeNinjaBlueLight = {
  color: deprecatedBaseColor,
  palette: {
    base: colors.white,
    contrast: colors.ninjaDark,

    primary: {
      light: colors.ninjaLight,
      base: colors.ninjaMedium,
      dark: colors.ninjaDark,
    },
    secondary: {
      light: colors.ninjaLight,
      base: colors.ninjaMedium,
      dark: colors.ninjaDark,
    },
    accent: {
      light: colors.ninjaLightBlue,
      base: colors.ninjaBlueSaturated,
      dark: "#2F4050",
    },
  },
  spacing,
  ...lightColor,
  ...brandingColor,
}

export default themeNinjaBlueLight
