import { memo } from "react"
import { Heading } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import { GetStartedVideo } from "./media/GetStartedMedia"

const GettingStartedStepOne = ({ activeStep, onTaskCompleted }) => (
  <>
    <Heading level={2} type="headingS" marginBottom={spacing[4]}>
      {localized("Watch intro video")}
    </Heading>
    <GetStartedVideo {...{ activeStep, onTaskCompleted }} />
  </>
)

export default memo(GettingStartedStepOne)
