import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import SubNavItemContent from "./SubNavItemContent"

const StyledNotice = styled.div`
  padding-left: ${sizer(3)};
  padding-right: ${sizer(3)};
  margin-bottom: 0;

  && {
    color: ${({ theme }) => theme.colorThemeTextWeak};
  }
`

const SubNavNotice = ({ text }) => {
  return (
    <StyledNotice>
      <SubNavItemContent name={text} textSize="xs" />
    </StyledNotice>
  )
}

export default SubNavNotice
