import { createSelector } from "reselect"
import { selectIsDocumentationEnabledFromSettings } from "js/state/selectors/session/divisionConfig"
import filterTabs from "js/state/helpers/filterTabs"
import { documentationTabTypes } from "js/includes/common/utils"

export const selectFavoriteTabs = state => state.general.favoriteTabs

export const selectFilteredFavoriteTabs = createSelector(
  [selectFavoriteTabs, selectIsDocumentationEnabledFromSettings],
  (favoriteTabs, isDocumentationEnabledFromSettings) =>
    favoriteTabs?.filter(filterTabs({ isDocumentationEnabledFromSettings })) ?? [],
)

export const selectDocumentationFavoriteTabs = createSelector(
  [selectFilteredFavoriteTabs],
  favoriteTabs => favoriteTabs?.filter(tab => documentationTabTypes.includes(tab?.type ?? "")) ?? [],
)
