import { map } from "ramda"

import { findById } from "js/includes/common/utils"

export function getSortedFormFields(ticketForm) {
  if (!ticketForm?.content?.fields || !ticketForm?.fields) {
    return []
  }

  return map(fieldId => findById(fieldId, ticketForm.fields), ticketForm.content.fields)
}
