import { createSelector } from "reselect"
import { identity, map, find, propEq } from "ramda"
import { getActiveCriteriaNotDeletedForSearchSetForEdit } from "js/state/selectors/deviceSearch/criteria"

const getCriteriaDefinitions = state => state.criteriaDefinitions

export const nameListToSelectOptions = createSelector(
  [identity],
  map(({ name }) => ({ label: name, value: name })),
)

export const idNameListToSelectOptions = createSelector(
  [identity],
  map(({ id, name }) => ({ label: name, value: id })),
)

export const getCriteriaDefinition = name =>
  createSelector([getCriteriaDefinitions], criteriaDefinitions => find(propEq("name", name), criteriaDefinitions))

export const getCriteriaFromFilters = type =>
  createSelector([getActiveCriteriaNotDeletedForSearchSetForEdit], activeGroupsFilters =>
    find(propEq("type", type), activeGroupsFilters),
  )
