import moment from "moment"

import { isPromotionOpen } from "js/includes/common/client"
import { ninjaReportError, user } from "js/includes/common/utils"
import { showMarketingPromo } from "js/state/actions/marketingPromo/marketingPromo"

import { backupMarketingPromo } from "./helpers"

export const setupBackupMarketingPromo = async () => {
  try {
    const {
      salesforcePurchaseDate: purchaseDateUtcSeconds,
      totalLicensedNinjaBackup: totalLicensedBackup,
    } = window.application.get("session").attributes
    const marketingPromo = window.application.get("marketingPromo")
    if (!user("canViewAndManageBackupData") || !purchaseDateUtcSeconds || marketingPromo) return
    const accountCreatedDate = new Date(0).setUTCSeconds(purchaseDateUtcSeconds)
    const currentDate = moment()
    const hasMoreThanSeventyFiveDays = moment(currentDate).diff(accountCreatedDate, "days", true) > 75
    const totalLicensedNinjaBackup = parseInt(totalLicensedBackup)
    const currentViewState = isPromotionOpen(backupMarketingPromo.id)
    if (!totalLicensedNinjaBackup && hasMoreThanSeventyFiveDays && currentViewState) {
      window.store.dispatch(showMarketingPromo([backupMarketingPromo]))
    }
  } catch (error) {
    ninjaReportError(error)
  }
}
