import { compose, keys, map, join, find, propEq, replace } from "ramda"
import { getFileExtension, localizationKey, isValidIpAddress, isValidInteger } from "js/includes/common/utils"
import { languages } from "js/state/initialStates/scripting"

const getLanguageById = language => find(propEq("id", language))(languages)

const languageMap = {
  bat: getLanguageById("batchfile"),
  cmd: getLanguageById("batchfile"),
  js: getLanguageById("javascript"),
  ps1: getLanguageById("powershell"),
  vbs: getLanguageById("vbscript"),
  sh: getLanguageById("sh"),
}

const extensions = compose(
  join(","),
  map(ext => "." + ext),
  keys,
)

export const acceptedFiles = extensions(languageMap)
export const getScriptLanguageFromFileName = fileName => languageMap[getFileExtension(fileName)]

export const SCRIPT_TYPE_LABELS = {
  TEXT: localizationKey("String/Text"),
  INTEGER: localizationKey("Integer"),
  DECIMAL: localizationKey("Decimal"),
  CHECKBOX: localizationKey("CheckBox"),
  DATE: localizationKey("Date"),
  DATETIME: localizationKey("Date and Time"),
  DROPDOWN: localizationKey("Drop-Down"),
  IPADDRESS: localizationKey("IP Address"),
}

export const DECIMAL_INPUT_EXCLUDED_SYMBOLS = ["+", "-", "e", "E"]

export const INTEGER_INPUT_EXCLUDED_SYMBOLS = [...DECIMAL_INPUT_EXCLUDED_SYMBOLS, ".", ","]

export const clearIllegalCharacters = value => replace(/[\u007F-\uFFFF&|;$><`!]/g, "")(value || "")

export const notRequiredScriptVariablesValidations = {
  IPADDRESS: value => {
    const success = value ? isValidIpAddress(value) : true
    return {
      success,
      message: success ? "" : CUSTOM_ERROR_MESSAGES.ipAddress,
    }
  },
}

export const scriptVariablesValidations = {
  INTEGER: value => {
    const success = isValidInteger(parseInt(value))
    return {
      success,
      message: success ? "" : CUSTOM_ERROR_MESSAGES.integer,
    }
  },
  DECIMAL: value => {
    const success = isValidInteger(parseInt(value)) || value % 1 > 0 || value.split(".").length === 2
    return {
      success,
      message: success ? "" : CUSTOM_ERROR_MESSAGES.decimal,
    }
  },
  IPADDRESS: value => {
    const success = isValidIpAddress(value)
    return {
      success,
      message: success ? "" : CUSTOM_ERROR_MESSAGES.ipAddress,
    }
  },
}

export const formatIntegerValue = (number = "") => {
  return number.replace(/\D/g, "")
}

export const formatDecimalValue = (number = "") => {
  const [int, ...decimalArray] = number.replace(/[^0-9.]/g, "").split(".")
  const decimal = decimalArray.join("")
  return decimal.length ? `${int}.${decimal}` : int
}

export const CUSTOM_ERROR_MESSAGES = {
  integer: localizationKey("Input must be of integer value"),
  decimal: localizationKey("Input must be of decimal value"),
  ipAddress: localizationKey("Value must be a valid IP Address"),
}
