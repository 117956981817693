import { always, applySpec, prop } from "ramda"

import { searchAppUserContacts } from "js/includes/common/client"
import { fullNameOrEmail, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

const emailProp = prop("email")
const firstNameProp = prop("firstName")
const lastNameProp = prop("lastName")
const idProp = prop("id")
const uidProp = prop("uid")
const userTypeProp = prop("userType")
const clientIdProp = prop("clientId")

export const mapAppUserToRequester = applySpec({
  email: emailProp,
  entityType: userTypeProp,
  firstName: firstNameProp,
  id: idProp,
  lastName: lastNameProp,
  uid: uidProp,
  clientId: clientIdProp,
})

export const mapRequesterToAppUser = applySpec({
  email: emailProp,
  displayName: fullNameOrEmail,
  userType: prop("entityType"),
  firstName: firstNameProp,
  id: idProp,
  lastName: lastNameProp,
  uid: uidProp,
  clientId: clientIdProp,
})

export const mapContactToRequester = applySpec({
  email: emailProp,
  entityType: always("CONTACT"),
  firstName: firstNameProp,
  id: idProp,
  lastName: lastNameProp,
  uid: uidProp,
  clientId: clientIdProp,
})

export const mapSessionUserToRequester = applySpec({
  email: emailProp,
  entityType: userTypeProp,
  firstName: firstNameProp,
  id: idProp,
  lastName: lastNameProp,
  uid: uidProp,
  clientId: prop("organizationId"),
})

export const resetRequesterIfNeeded = async ({ onChange, requester, clientId }) => {
  if (requester?.uid) {
    try {
      // TODO call dedicated endpoint when it's implemented.
      // For the moment we are using the same endpoint used in the requesters dropdown.
      const searchResult = await searchAppUserContacts({
        clientId,
        searchCriteria: fullNameOrEmail(requester),
        userType: requester.entityType,
      })
      if (!searchResult.some(user => user.uid === requester.uid)) {
        onChange("requester", null)
      }
    } catch (error) {
      onChange("requester", null)
      reportErrorAndShowMessage(
        error,
        localizationKey("Could not verify the requester has access to the selected organization"),
      )
    }
  }
}
