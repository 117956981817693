export const getTicketRuleset = `
  query GetTicketRuleset {
    ticketRuleset {
      id
      name
      type
      enabled
      conditionTriggerSettings {
        ticketFormId
        status
        tags
        priority
        severity
      }
      conditionResetSettings {
        status
        time {
          value
          unit
        }
      }
      conditionReTriggerSettings {
        createNewTicket
        status
        tags
        priority
        severity
      }
      isDefault
      createTime
      updateTime
    }
  }
`

export const ticketRulesetOptions = `
  query GetTicketRuleset {
    ticketRuleset {
      id
      name
      type
    }
  }
`

export const ticketRuleById = `
  query TicketRuleById($id: Int!) {
    ticketRulesetById(id: $id) {
      id
      name
      enabled
      conditionTriggerSettings {
        ticketFormId
        status
        tags
        priority
        severity
        useOwnerAsRequester
      }
      conditionResetSettings {
        status
        time {
          value
          unit
        }
      }
      conditionReTriggerSettings {
        createNewTicket
        status
        tags
        priority
        severity
      }
      isDefault
      createTime
      updateTime
    }
  }
`

export const createTicketRuleset = `
  mutation CreateTicketRuleset($ruleset: NewTicketRuleset!) {
    createTicketRuleset(ruleset: $ruleset) {
      id
    }
  }
`

export const updateTicketRuleset = `
  mutation UpdateTicketRuleset($ruleset: UpdateTicketRuleset!) {
    updateTicketRuleset(ruleset: $ruleset) {
      id
    }
  }
`

export const deleteTicketRuleset = `
  mutation DeleteTicketRuleset($rulesetId: Int!) {
    deleteTicketRuleset(rulesetId: $rulesetId)
  }
`
