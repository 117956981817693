import { idNameListToSelectOptions } from "js/state/selectors/deviceSearch/filters"
import { fetchJson, localized, showErrorMessage } from "js/includes/common/utils"

export async function getLocationOptions(organizationId) {
  const locations = await getLocationsByOrganizationId(organizationId)
  return idNameListToSelectOptions(locations)
}

export async function getLocationsByOrganizationId(organizationId) {
  try {
    return await fetchJson(`/api/v2/organization/${organizationId}/locations`)
  } catch (error) {
    showErrorMessage(localized("Error loading locations"))
    throw error
  }
}
