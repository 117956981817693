import {
  fetchJson,
  ninjaReportError,
  showSuccessMessage,
  showErrorMessage,
  localized,
  fetch,
} from "js/includes/common/utils"
import { omit } from "ramda"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { removeRecentTab } from "js/state/actions/general/recentTabs"
import { removeFavoriteTab } from "js/state/actions/general/favoriteTabs"

export const fetchVirtualizationHosts = clientId => async dispatch => {
  try {
    dispatch(startLoading("loadingVirtualizationHosts")())
    const hosts = await fetchJson(`/client/${clientId}/vmhosts`, { options: { method: "GET" } })
    dispatch({
      type: "SET_VIRTUALIZATION_HOST_LIST",
      hosts,
    })
  } catch (error) {
    showErrorMessage(localized("Error fetching hosts"))
    ninjaReportError(error)
  } finally {
    dispatch(stopLoading("loadingVirtualizationHosts")())
  }
}

export const addVirtualizationHost = host => async dispatch => {
  try {
    showSuccessMessage(localized("Saving Host"))
    const response = await fetchJson(`/node/vmmtarget`, {
      options: { method: "POST", body: JSON.stringify(host) },
    })
    dispatch({
      type: "ADD_VIRTUALIZATION_HOST",
      host: response,
    })
  } catch (error) {
    if (error.resultCode === "delegate_already_has_existing_host") {
      showErrorMessage(localized("VM Host already exists under the same Monitor Delegate"))
    } else {
      showErrorMessage(localized("Error saving host"))
    }
    ninjaReportError(error)
  }
}

export const removeVirtualizationHost = host => async dispatch => {
  try {
    const { id } = host
    showSuccessMessage(localized("Removing host"))
    await fetchJson(`/node/${id}`, { options: { method: "DELETE" } })
    dispatch({
      type: "REMOVE_VIRTUALIZATION_HOST",
      host,
    })
    window.deviceList.delete(id)
    //TODO remove these two lines if server handles them - 5.2 possibly
    dispatch(removeRecentTab({ id, type: "DEVICE" }))
    dispatch(removeFavoriteTab({ id, type: "DEVICE" }))
  } catch (error) {
    showErrorMessage(localized("Error removing host"))
    ninjaReportError(error)
  }
}

export const updateVirtualizationHost = (host, omitFields = []) => async dispatch => {
  try {
    showSuccessMessage(localized("Updating host"))
    const payload = omit(omitFields, host)
    await fetch(`/node/vmmtarget/${host.id}`, { options: { method: "PUT", body: JSON.stringify(payload) } })
    dispatch({
      type: "UDPATE_VIRTUALIZATION_HOST",
      host,
    })
  } catch (error) {
    showErrorMessage(localized("Error updating host"))
    ninjaReportError(error)
  }
}
