import { useCallback, useEffect, useRef } from "react"
import { css } from "@emotion/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getTextSize, sizer } from "@ninjaone/utils"

const textStyle = css`
  font-size: ${getTextSize("xs")};
  line-height: 1.5;
  white-space: nowrap;
`

const navItemContentStyle = css`
  display: flex;
  align-items: center;
  height: ${sizer(7)};
  overflow: hidden;
  gap: ${sizer(3)};

  svg {
    font-size: ${getTextSize("xs")};
    flex-shrink: 0;
  }

  [data-ninja-tooltip-trigger] {
    overflow: hidden;
  }
`

const containerStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SubNavItemContent = ({ icon, name, collapsed, contentStyle, actionIcons, onTextOverflow }) => {
  const containerEl = useRef(null)
  const textNodeEl = useRef(null)

  const checkIfTextOverflows = useCallback(() => {
    const width = textNodeEl?.current?.getBoundingClientRect().width ?? 0
    const containerWidth = containerEl?.current?.getBoundingClientRect().width ?? 0

    onTextOverflow?.(width > containerWidth)
  }, [onTextOverflow])

  useEffect(() => {
    checkIfTextOverflows()
  }, [checkIfTextOverflows])

  const content = (
    <div ref={containerEl} css={containerStyle}>
      <span ref={textNodeEl} css={textStyle}>
        {name}
      </span>
    </div>
  )

  return (
    <span onKeyDown={checkIfTextOverflows} onMouseOver={checkIfTextOverflows} css={[navItemContentStyle, contentStyle]}>
      {icon && <FontAwesomeIcon {...{ icon, fixedWidth: true }} />}
      {content}
    </span>
  )
}

export default SubNavItemContent
