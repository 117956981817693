import { fetchJson, upload } from "js/includes/common/utils"
import { always, cond, equals, isNil, T } from "ramda"

const urlEntityPathMap = {
  NODE: "/node",
  DOCUMENT: "/client/document",
}

export const getDocumentationDivisionConfig = () => {
  return fetchJson(`/divisionconfig/documentation`)
}

export const saveDocumentationDivisionConfig = config => {
  return fetchJson(`/divisionconfig/documentation`, {
    options: {
      method: "PUT",
      body: JSON.stringify(config),
    },
  })
}

export const saveCustomField = (field, entity, id) => {
  return fetchJson(`/attributes/${entity}${id ? `/${id}` : ""}`, {
    options: {
      method: id ? "PUT" : "POST",
      body: JSON.stringify(field),
    },
  })
}

export const deleteCustomField = (entity, id) => {
  return fetchJson(`/attributes/${entity}/delete/item/${id}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const saveCustomFieldsForm = ({ entity, entityId, attributeScope, definitionScope, items }) => {
  return fetchJson(
    `/attributes/${entity}${attributeScope ? `/${attributeScope}` : ""}${entityId ? `/${entityId}` : ""}/fields${
      definitionScope ? `?definitionScope=${definitionScope.toUpperCase()}` : ""
    }`,
    {
      options: {
        method: "PUT",
        body: JSON.stringify(items),
      },
    },
  )
}

export const getCustomFields = ({ entity, attributeScope, entityId, definitionScope }) => {
  const _definitionScope = cond([
    [isNil, always("")],
    [equals("all"), always("/all")],
    [T, ds => `?definitionScope=${ds.toUpperCase()}`],
  ])(definitionScope)

  return fetchJson(
    `/attributes/${entity}${attributeScope ? `/${attributeScope}` : ""}${
      entityId ? `/${entityId}` : ""
    }${_definitionScope}`,
  )
}

export const getCustomFieldSecureValue = (entity, valueId) => {
  return fetchJson(`/attributes${urlEntityPathMap[entity]}/secure/value/${valueId}`)
}

export const getCustomFieldValues = ({ entity, nodeId, locationId, clientId }) => {
  return fetchJson(
    `/attributes/${entity}${nodeId ? `/${nodeId}` : ""}${locationId ? `/location/${locationId}` : ""}${
      !locationId && clientId ? `/client/${clientId}` : ""
    }/values`,
  )
}

export const getClientLocationsCustomFieldValues = ({ clientId }) => {
  return fetchJson(`/attributes/node/client/${clientId}/locations/values`)
}

export const saveCustomFieldValues = ({ entity, nodeId, locationId, clientId, values }) => {
  return fetchJson(
    `/attributes/${entity}${nodeId ? `/${nodeId}` : ""}${locationId ? `/location/${locationId}` : ""}${
      !locationId && clientId ? `/client/${clientId}` : ""
    }/values`,
    {
      options: {
        method: "PUT",
        body: JSON.stringify(values),
      },
    },
  )
}

export const getDocumentTemplates = ({ includeArchived = false } = {}) => {
  return fetchJson(`/attributes/document/templates?includeArchived=${includeArchived}`)
}

export const getDocumentTemplatesWithDocumentsCount = ({ clientId, includeArchived = true }) => {
  return fetchJson(`/attributes/client/${clientId}/document/templates${includeArchived ? "?includeArchived=true" : ""}`)
}

export const getDocumentTemplateWithFields = (templateId, archived) => {
  return fetchJson(`/attributes/document${archived ? "/archived" : ""}/template/${templateId}`)
}

export const getArchivedDocumentTemplateWithFields = archivedTemplateId => {
  return fetchJson(`/attributes/document/archived/template/${archivedTemplateId}`)
}

export const saveDocumentTemplateWithFields = (form, id) => {
  return fetchJson(`/attributes/document/template${id ? `/${id}` : ""}`, {
    options: {
      method: id ? "PUT" : "POST",
      body: JSON.stringify(form),
    },
  })
}

export const archiveDocumentTemplate = id => {
  return fetchJson(`/attributes/document/template/${id}/archive`, {
    options: {
      method: "POST",
    },
  })
}

export const archiveClientDocument = id => {
  return fetchJson(`/attributes/client/document/${id}/archive`, {
    options: {
      method: "POST",
    },
  })
}

export const restoreDocumentTemplate = id => {
  return fetchJson(`/attributes/document/template/${id}/restore`, {
    options: {
      method: "POST",
    },
  })
}

export const deleteDocumentTemplate = id => {
  return fetchJson(`/attributes/document/template/${id}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const deleteClientDocument = id => {
  return fetchJson(`/attributes/client/document/${id}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const archiveClientDocuments = ids => {
  return fetchJson(`/attributes/client/documents/archive`, {
    options: {
      method: "POST",
      body: JSON.stringify(ids),
    },
  })
}

export const restoreClientDocument = id => {
  return fetchJson(`/attributes/client/document/${id}/restore`, {
    options: {
      method: "POST",
    },
  })
}

export const restoreClientDocuments = ids => {
  return fetchJson(`/attributes/client/documents/restore`, {
    options: {
      method: "POST",
      body: JSON.stringify(ids),
    },
  })
}

export const deleteClientDocuments = ids => {
  return fetchJson(`/attributes/client/document/delete`, {
    options: {
      method: "POST",
      body: JSON.stringify(ids),
    },
  })
}

export const getDocumentTemplateInstances = (templateId, clientId, archived) => {
  return fetchJson(
    `/attributes/client/${clientId}${archived ? "/archived" : ""}/template/${templateId}${
      archived ? "" : "?includeArchived=true"
    }`,
  )
}

export const getDocumentInstanceFieldValues = (documentId, archived) => {
  return fetchJson(`/attributes/client/document/${documentId}${archived ? "/archived" : ""}/values`)
}

export const saveCustomerDocumentWithValues = (templateId, clientId, customerDocumentWithValues) => {
  const url = customerDocumentWithValues.id
    ? `/attributes/client/document/${customerDocumentWithValues.id}`
    : `/attributes/client/${clientId}/template/${templateId}`

  return fetchJson(url, {
    options: {
      method: customerDocumentWithValues.id ? "PUT" : "POST",
      body: JSON.stringify(customerDocumentWithValues),
    },
  })
}

export const uploadTempAttachment = (entity, file, handleProgress, handleSuccess) => {
  const body = {
    file: file,
  }

  const { promise } = upload(`/attachments/tmp/upload-file/${entity}/cache-metadata`, {
    body,
    onProgressHandler: _progress => {
      const progress = (_progress.loaded * 100) / _progress.total
      handleProgress?.(progress)
    },
    onSuccessHandler: handleSuccess,
  })

  return promise
}

export const uploadTempAttachments = (entity, files, handleProgress, handleSuccess) => {
  const body = files
  const { promise } = upload(`/attachments/tmp/upload-multiple-files/${entity}`, {
    body,
    isMultipleFileUpload: true,
    onProgressHandler: _progress => {
      const progress = (_progress.loaded * 100) / _progress.total
      handleProgress?.(progress)
    },
    onSuccessHandler: handleSuccess,
  })

  return promise
}

export const deleteTempAttachment = (entity, resourceId) => {
  return fetchJson(`/attachments/tmp/delete-file/${entity}?fileName=${resourceId}`, {
    options: {
      method: "DELETE",
    },
  })
}

export const getDocumentRevisionSecretValue = ({ documentId, revision, attributeId }) => {
  return fetchJson(`/attributes/client/document/${documentId}/revision/${revision}/secure/field/${attributeId}`)
}

export const getClientDocumentRevisions = documentId => {
  return fetchJson(`/attributes/client/document/${documentId}/revisions`)
}

export const getClientDocument = documentId => {
  return fetchJson(`/attributes/client/document/${documentId}`)
}

export const getDocumentTemplateRevision = (templateId, templateRevision) => {
  return fetchJson(`/attributes/document/template/${templateId}/revision/${templateRevision}`)
}

export const deleteDocumentRevisionLogs = (documentId, clientDocumentRevisions) => {
  return fetchJson(`/attributes/client/document/${documentId}/delete/revisions`, {
    options: {
      method: "POST",
      body: JSON.stringify({ clientDocumentRevisions }),
    },
  })
}

export const restoreDocumentRevision = (documentId, revisionId, attributeIds) => {
  return fetchJson(`/attributes/client/document/${documentId}/revision/${revisionId}/restore`, {
    options: {
      method: "POST",
    },
  })
}

export const getDocumentationDelegates = clientId => {
  return fetchJson(`/attributes/client/${clientId}/documentation/delegate/nodes`)
}

export const addDocumentationDelegates = nodeIds => {
  return fetchJson(`/attributes/client/documentation/delegate/nodes/add`, {
    options: {
      method: "POST",
      body: JSON.stringify(nodeIds),
    },
  })
}

export const removeDocumentationDelegates = nodeIds => {
  return fetchJson(`/attributes/client/documentation/delegate/nodes/remove`, {
    options: {
      method: "POST",
      body: JSON.stringify(nodeIds),
    },
  })
}

export const getClientFormFields = clientId => {
  return fetchJson(`/attributes/client/${clientId}/form/fields`)
}

export const saveClientCustomFields = ({ method = "POST", fields, clientId, locationId }) => {
  return fetchJson(`/attributes/client/${clientId}${locationId ? `/location/${locationId}` : ""}/form/fields`, {
    options: {
      method,
      body: JSON.stringify(fields),
    },
  })
}

export const getDocumentationImportHistory = () => {
  return fetchJson("/attributes/client/document/importHistory")
}

export const uploadCSVPreviewFile = ({ templateId, file }) => {
  return fetchJson(`/attributes/client/document/${templateId}/csvUploadPreview`, {
    headers: {
      "Content-Type": "text/csv",
      "Accept-Encoding": "gzip",
    },
    options: {
      method: "POST",
      body: file,
    },
  })
}
