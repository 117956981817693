import {
  isValidWindowsPath,
  isValidUncPath,
  isNotNilOrEmpty,
  isWindowsWorkstation,
  isMac,
  isMacServer,
  isWindowsServer,
} from "js/includes/common/utils"
import { isArrowImagePlan } from "./services"

export const isValidDestination = destination =>
  destination === "original" || isValidWindowsPath(destination) || isValidUncPath(destination)

export const validateBackupPlans = (backupPlans = {}) => {
  const arrowPlan = Object.values(backupPlans).find(({ type }) => isArrowImagePlan(type))
  if (!arrowPlan) return true

  return isNotNilOrEmpty(arrowPlan?.general?.destination)
}

export const getNodeBackupStatus = ({ nodeId, nodeClass, customer }) => {
  const { deployTo, nodeIds, excludedNodeIds } = customer?.content?.backup?.lockhart ?? {}

  const isWorkstation = isWindowsWorkstation(nodeClass) || isMac(nodeClass)
  const isServer = isWindowsServer(nodeClass) || isMacServer(nodeClass)

  const isAutoDeployed =
    (isWorkstation && !!deployTo?.includes("WORKSTATIONS")) || (isServer && !!deployTo?.includes("SERVERS"))

  const isManuallyIncluded = nodeIds?.includes(nodeId)
  const isManuallyExcluded = excludedNodeIds?.includes(nodeId)
  const isInherited = !isManuallyIncluded && !isManuallyExcluded

  const isBackupEnabledForNode = (isAutoDeployed && !isManuallyExcluded) || isManuallyIncluded
  return { isBackupEnabledForNode, isAutoDeployed, isInherited }
}
