import moment from "moment"
import {
  saveDiscoveryJob as _saveDiscoveryJob,
  deleteDiscoveryJob as _deleteDiscoveryJob,
} from "js/includes/common/client"

export const setDiscoveryJobsList = list => ({
  type: "SET_DISCOVERY_JOBS_LIST",
  list,
})

export const setDiscoveryJobsHistory = list => ({
  type: "SET_DISCOVERY_JOBS_HISTORY_LIST",
  list,
})

export const setDiscoveryJobsFilter = discoveryJobsFilter => ({
  type: "SET_DISCOVERY_JOBS_FILTER",
  discoveryJobsFilter,
})

export const saveDiscoveryJob = (job, id) => async dispatch => {
  try {
    const response = await _saveDiscoveryJob(job, id)
    if (!job.runOnce) {
      dispatch({
        type: `${id ? "UPDATE" : "ADD"}_DISCOVERY_JOBS_ITEM`,
        payload: response,
      })
    }

    if (job.jobType === "AD_HOC" && job.candidateNodes.length) {
      const user = window.application.get("user")
      const history = {
        appUserId: user.id,
        appUserName: user.getName(),
        discoveryJob: response,
        runDate: moment().unix(),
        candidateNodes: job.candidateNodes,
      }
      dispatch({
        type: "ADD_DISCOVERY_JOBS_HISTORY_ITEM",
        payload: history,
      })
    }
  } catch (error) {
    throw error
  }
}

export const deleteDiscoveryJob = id => async dispatch => {
  try {
    await _deleteDiscoveryJob(id)
    dispatch({
      type: "REMOVE_DISCOVERY_JOBS_ITEM",
      payload: id,
    })
  } catch (error) {
    throw error
  }
}
