import { includes, cond, always, T, isNil } from "ramda"
import { ninjaResourceCdn } from "js/includes/common/services/ninjaResourcesCdn"
import { containsAny } from "js/includes/common/utils"

const dashboardImageSource = `${ninjaResourceCdn}/webapp/img/device_dashboard`
const imageSource = `${ninjaResourceCdn}/webapp/img/integration_apps`
const patchingImageSource = `${ninjaResourceCdn}/webapp/img/software_patching`

export const WEBHOOK_LOGO = "/img/vendors/webhook_logo.png"

export const APP_LOGOS = {
  NINJA: `${imageSource}/ninja.png`,
  AUTOTASK: `${imageSource}/autotask.png`,
  ACCELO: `${imageSource}/accelo.png`,
  CONNECTWISE: `${imageSource}/connectwise.png`,
  REPAIR_SHOPR: `${imageSource}/repairshopr.png`,
  IT_GLUE: `${imageSource}/itglue.png`,
  TEAM_VIEWER: `${imageSource}/team_viewer.jpg`,
  SPLASHTOP: `${imageSource}/splashtop.png`,
  BITDEFENDER: `${imageSource}/bitdefender.png`,
  CLOUDBERRY: `${imageSource}/cloudberry.png`,
  WEBROOT: `${imageSource}/webroot.png`,
  SENTINEL_ONE: `${imageSource}/sentinelone.png`,
  CROWDSTRIKE: `${imageSource}/crowdstrike.png`,
  SERVICE_NOW: `${imageSource}/servicenow.png`,
  WINGET: `${patchingImageSource}/winget_360.png`,
  ZENDESK: `${imageSource}/zendesk.png`,
  RAPID7: `${imageSource}/rapid7.png`,
  QUALYS: `${imageSource}/qualys.png`,
  TENABLE: `${imageSource}/tenable.png`,
  SPOTLIGHT: `${imageSource}/crowdstrike_spotlight.png`,
}

export const DEVICE_DASHBOARD_LOGOS = {
  WINDOWS_7: `${dashboardImageSource}/7.png`,
  WINDOWS_8: `${dashboardImageSource}/8.png`,
  WINDOWS_10: `${dashboardImageSource}/10.png`,
  WINDOWS_DEFAULT: `${dashboardImageSource}/default.png`,
  WINDOWS_2008: `${dashboardImageSource}/2008.png`,
  WINDOWS_2012: `${dashboardImageSource}/2012.png`,
  WINDOWS_SERVER: `${dashboardImageSource}/server.png`,
  MAC_DEFAULT: `${dashboardImageSource}/macDefault.png`,
  MAC_OLDER: `${dashboardImageSource}/macOlder.png`,
  ACER: `${dashboardImageSource}/acer.png`,
  APPLE: `${dashboardImageSource}/apple.png`,
  ASUS: `${dashboardImageSource}/asus.png`,
  COMPAQ: `${dashboardImageSource}/compaq.png`,
  DELL: `${dashboardImageSource}/dell.png`,
  GATEWAY: `${dashboardImageSource}/gateway.png`,
  HCL: `${dashboardImageSource}/hcl.png`,
  HP: `${dashboardImageSource}/hp.png`,
  IBM: `${dashboardImageSource}/ibm.png`,
  LENOVO: `${dashboardImageSource}/lenovo.png`,
  TOSHIBA: `${dashboardImageSource}/toshiba.png`,
  WIPRO: `${dashboardImageSource}/wipro.png`,
  CISCO: `${dashboardImageSource}/cisco.png`,
  XEN: `${dashboardImageSource}/xen.png`,
  PARALLELS: `${dashboardImageSource}/parallels.png`,
  VMWARE: `${dashboardImageSource}/vmware.png`,
  HYPER: `${dashboardImageSource}/hyper.png`,
  JUNIPER: `${dashboardImageSource}/juniper.png`,
  HUAWEI: `${dashboardImageSource}/huawei.png`,
  EXTREME: `${dashboardImageSource}/extreme.png`,
  ENTERASYS: `${dashboardImageSource}/enterasys.png`,
  DLINK: `${dashboardImageSource}/d-link.png`,
  LINKSYS: `${dashboardImageSource}/linksys.png`,
  NETGEAR: `${dashboardImageSource}/netgear.png`,
  ERICSSON: `${dashboardImageSource}/ericsson.png`,
  BELKIN: `${dashboardImageSource}/belkin.png`,
  RIVERBED: `${dashboardImageSource}/riverbed.png`,
  HARDWARE_DEFAULT: `${dashboardImageSource}/hardwareDefault.png`,
  UBUNTU: `${dashboardImageSource}/ubuntu.png`,
  DEBIAN: `${dashboardImageSource}/debian.png`,
  FEDORA: `${dashboardImageSource}/fedora.png`,
  RED_HAT: `${dashboardImageSource}/red-hat.png`,
  CENTOS: `${dashboardImageSource}/centos.png`,
  LINUX_DEFAULT: `${dashboardImageSource}/linuxDefault.png`,
  ROUTER: `${dashboardImageSource}/router.png`,
  SWITCH: `${dashboardImageSource}/switch.png`,
  FIREWALL: `${dashboardImageSource}/firewall.png`,
  NMS_DEFAULT: `${dashboardImageSource}/nmsDefault.png`,
  RASPBERRY: `${dashboardImageSource}/raspberry.png`,
  FORTINET: `${dashboardImageSource}/fortinet.png`,
  SYNOLOGY: `${dashboardImageSource}/synology.png`,
  QNAP: `${dashboardImageSource}/qnap.png`,
  H3C: `${dashboardImageSource}/h3c.png`,
}

export const getOSLogo = os => {
  if (!os) return null
  const osNameLowerCase = os.toLowerCase()
  return cond([
    [containsAny(["vista", "2008"]), always(DEVICE_DASHBOARD_LOGOS.WINDOWS_2008)],
    [includes("windows 7"), always(DEVICE_DASHBOARD_LOGOS.WINDOWS_7)],
    [includes("windows 8"), always(DEVICE_DASHBOARD_LOGOS.WINDOWS_8)],
    [includes("windows 10"), always(DEVICE_DASHBOARD_LOGOS.WINDOWS_10)],
    [includes("2012"), always(DEVICE_DASHBOARD_LOGOS.WINDOWS_2012)],
    [containsAny(["2016", "2019", "2022"]), always(DEVICE_DASHBOARD_LOGOS.WINDOWS_SERVER)],
    [containsAny(["windows 11", "windows"]), always(DEVICE_DASHBOARD_LOGOS.WINDOWS_DEFAULT)],
    [includes("vmware"), always("img/vendors/vm/vmware.png")],
    [includes("linux"), always("img/vendors/linux/default.png")],
    [containsAny(["10.8.", "10.9.", "10.10."]), always(DEVICE_DASHBOARD_LOGOS.MAC_OLDER)],
    [containsAny(["os x", "macos"]), always(DEVICE_DASHBOARD_LOGOS.MAC_DEFAULT)],
    [T, always(null)],
  ])(osNameLowerCase)
}

export const getHardwareLogo = manufacturer =>
  cond([
    [isNil, always(DEVICE_DASHBOARD_LOGOS.HARDWARE_DEFAULT)],
    [includes("acer"), always(DEVICE_DASHBOARD_LOGOS.ACER)],
    [includes("asus"), always(DEVICE_DASHBOARD_LOGOS.ASUS)],
    [includes("compaq"), always(DEVICE_DASHBOARD_LOGOS.COMPAQ)],
    [includes("dell"), always(DEVICE_DASHBOARD_LOGOS.DELL)],
    [includes("gateway"), always(DEVICE_DASHBOARD_LOGOS.GATEWAY)],
    [includes("hcl"), always(DEVICE_DASHBOARD_LOGOS.HCL)],
    [includes("hp"), always(DEVICE_DASHBOARD_LOGOS.HP)],
    [includes("hewlett"), always(DEVICE_DASHBOARD_LOGOS.HP)],
    [includes("hpe"), always(DEVICE_DASHBOARD_LOGOS.HP)],
    [includes("ibm"), always(DEVICE_DASHBOARD_LOGOS.IBM)],
    [includes("lenovo"), always(DEVICE_DASHBOARD_LOGOS.LENOVO)],
    [includes("toshiba"), always(DEVICE_DASHBOARD_LOGOS.TOSHIBA)],
    [includes("wipro"), always(DEVICE_DASHBOARD_LOGOS.WIPRO)],
    [includes("cisco"), always(DEVICE_DASHBOARD_LOGOS.CISCO)],
    [includes("xen"), always(DEVICE_DASHBOARD_LOGOS.XEN)],
    [includes("hyper"), always(DEVICE_DASHBOARD_LOGOS.HYPER)],
    [includes("raspberry"), always(DEVICE_DASHBOARD_LOGOS.RASPBERRY)],
    [includes("fortinet"), always(DEVICE_DASHBOARD_LOGOS.FORTINET)],
    [includes("synology"), always(DEVICE_DASHBOARD_LOGOS.SYNOLOGY)],
    [includes("qnap"), always(DEVICE_DASHBOARD_LOGOS.QNAP)],
    [includes("h3c"), always(DEVICE_DASHBOARD_LOGOS.H3C)],
    [T, always(DEVICE_DASHBOARD_LOGOS.HARDWARE_DEFAULT)],
  ])(manufacturer)
