import { clone, isNil, mean, splitWhen } from "ramda"
import { localized, secondsToMs } from "js/includes/common/utils/ssrAndWebUtils"
import { units } from "js/includes/common/_constants"

export function toInt(value) {
  if (!isNil(value)) {
    return parseInt(value, 10)
  } else {
    return null
  }
}

export function toFloat(value) {
  if (!isNil(value)) {
    return parseFloat(value)
  } else {
    return null
  }
}

export function toGhz(hz) {
  if (!isNil(hz)) {
    return (hz / units.hz_in_ghz).toFixed(2)
  } else {
    return null
  }
}

export function getReadableHz(hz) {
  if (!isNil(hz)) {
    if (hz > units.hz_in_ghz) {
      return (hz / units.hz_in_ghz).toFixed(2) + " GHz"
    } else {
      return parseInt(hz / units.hz_in_mhz, 10) + " MHz"
    }
  } else {
    return null
  }
}

export function MHzToGhz(mhz) {
  if (!isNil(mhz)) {
    return (mhz / 1000).toFixed(2)
  } else {
    return null
  }
}

export function bpsToKbps(bps) {
  if (!isNil(bps)) {
    return (bps / 1000).toFixed(2)
  } else {
    return null
  }
}

export function bytesToBits(bytes) {
  return bytes * 8
}

export function toKB(bytes, decimalLength) {
  if (!isNil(bytes)) {
    if (decimalLength) {
      return (bytes / units.bytes_in_kb).toFixed(decimalLength)
    } else {
      return parseInt(bytes / units.bytes_in_kb, 10)
    }
  } else {
    return null
  }
}

export function toMB(bytes, decimalLength) {
  if (!isNil(bytes)) {
    if (decimalLength) {
      return (bytes / units.bytes_in_mb).toFixed(decimalLength)
    } else {
      return (bytes / units.bytes_in_mb).toFixed(1)
    }
  } else {
    return null
  }
}

export function toGB(bytes, decimalLength = null, base10 = false) {
  if (!isNil(bytes)) {
    const base = base10 ? units.bytes_in_gb_base10 : units.bytes_in_gb
    if (decimalLength) {
      return (bytes / base).toFixed(decimalLength)
    } else {
      return (bytes / base).toFixed(1)
    }
  } else {
    return null
  }
}

export function formatReadableBytes(bytes, decimals = 2) {
  const result = {
    size: 0,
    unit: localized("Bytes"),
  }

  if (isNil(bytes) || toInt(bytes) === 0) return result

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  result.size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  result.unit = units[i]

  return result
}

export function formatReadableBytesToBase10(bytes, asBits) {
  const unitsBytes = ["Bytes", "KB", "MB", "GB", "TB"]
  const unitsBits = ["b", "Kb", "Mb", "Gb", "Tb"]
  const units = asBits ? unitsBits : unitsBytes
  const base10ConversionRate = 1000
  let value = bytes
  let scale = 1
  let unit = units[0]

  for (let i = 0; i < units.length; i++) {
    if (value < base10ConversionRate) {
      unit = units[i]
      break
    }
    value = value / base10ConversionRate
    scale = scale * base10ConversionRate
  }

  return { scale, value, unit }
}

export function getReadableBytes(bytes, eliminateUnit, asText = true) {
  const { size, unit } = formatReadableBytes(bytes)
  const localizedSize = localized(size)

  if (isNil(bytes)) return null

  if (asText) {
    return eliminateUnit ? localizedSize : localizedSize + " " + unit
  }

  return { size, unit }
}

export function getHashCode(str) {
  var hash = 0,
    i,
    l,
    char
  if (!str) return hash
  for (i = 0, l = str.length; i < l; i++) {
    char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export function getFreePercetange(capacity, free) {
  return capacity ? `${Math.round((free / capacity) * 100)}%` : ""
}

export function getGraphLabels(maxValue, useBits) {
  let divider
  let yAxisMax

  const yAxisMaxSuffix = useBits ? "bps" : "B/s"
  if (maxValue <= units.bytes_in_kb) {
    divider = 10.24
    yAxisMax = "1 K" + yAxisMaxSuffix
  } else if (maxValue <= 10 * units.bytes_in_kb) {
    divider = 10 * 10.24
    yAxisMax = "10 K" + yAxisMaxSuffix
  } else if (maxValue <= 50 * units.bytes_in_kb) {
    divider = 50 * 10.24
    yAxisMax = "50 K" + yAxisMaxSuffix
  } else if (maxValue <= 100 * units.bytes_in_kb) {
    divider = 100 * 10.24
    yAxisMax = "100 K" + yAxisMaxSuffix
  } else if (maxValue <= 250 * units.bytes_in_kb) {
    divider = 250 * 10.24
    yAxisMax = "250 K" + yAxisMaxSuffix
  } else if (maxValue <= 500 * units.bytes_in_kb) {
    divider = 500 * 10.24
    yAxisMax = "500 K" + yAxisMaxSuffix
  } else if (maxValue <= units.bytes_in_mb) {
    divider = 1024 * 10.24
    yAxisMax = "1 M" + yAxisMaxSuffix
  } else if (maxValue <= 5 * units.bytes_in_mb) {
    divider = 5 * 1024 * 10.24
    yAxisMax = "5 M" + yAxisMaxSuffix
  } else if (maxValue <= 10 * units.bytes_in_mb) {
    divider = 10 * 1024 * 10.24
    yAxisMax = "10 M" + yAxisMaxSuffix
  } else if (maxValue <= 50 * units.bytes_in_mb) {
    divider = 50 * 1024 * 10.24
    yAxisMax = "50 M" + yAxisMaxSuffix
  } else if (maxValue <= 100 * units.bytes_in_mb) {
    divider = 100 * 1024 * 10.24
    yAxisMax = "100 M" + yAxisMaxSuffix
  } else if (maxValue <= 250 * units.bytes_in_mb) {
    divider = 250 * 1024 * 10.24
    yAxisMax = "250 M" + yAxisMaxSuffix
  } else if (maxValue <= 500 * units.bytes_in_mb) {
    divider = 500 * 1024 * 10.24
    yAxisMax = "500 M" + yAxisMaxSuffix
  } else if (maxValue <= units.bytes_in_gb) {
    divider = 1024 * 1024 * 10.24
    yAxisMax = "1 G" + yAxisMaxSuffix
  } else {
    console.log("Transfer rate is higher than 1 Gigs per second... " + maxValue)
    divider = 1024 * 1024 * 10.24
    yAxisMax = "1 G" + yAxisMaxSuffix
  }

  return {
    divider,
    yAxisMax,
  }
}

export function scaleTrendData(initialData, multiplier, maxValue, useBits) {
  const data = clone(initialData)
  // The multiplier helps ensure that our data is in bytes per sec
  multiplier = multiplier ? multiplier : 1
  maxValue = maxValue ? maxValue * multiplier : 0
  for (let i = 0; i < data.length; ++i) {
    data[i][1] *= multiplier
    if (data[i][1] > maxValue) {
      maxValue = data[i][1]
    }
  }

  const { divider, yAxisMax } = getGraphLabels(maxValue, useBits)

  for (let i = 0; i < data.length; ++i) {
    data[i][1] = parseInt(data[i][1] / divider, 10)
  }

  return [data, yAxisMax, maxValue]
}

/**
 * @typedef {object} TrendDataSettings
 * @property {number=} interval interval that will create a sample. Defaults to 60000 (1 point each 60s)
 * @property {number=} samplesAmount amount of samples to have in the chart. Defaults to 60
 * @property {number=} periodEnd Last sample's. Defaults to current system timestamp
 * @property {number=} defaultSampleValue value to display in case there are no values at some interval
 */

/** @typedef {Array<[x: number, y: number]>} TrendData */

/**
 * Transforms given trendData into a periodic trend data series of `samplesAmount` datapoints. The series
 * continues subtracting `interval` for each datapoint **backwards**, starting from
 * `periodEnd - (samplesAmount * interval)`, and ending at `periodEnd`.
 *
 * There will be one sample for each interval, and the sample's value will be the mean of the
 * values inside this interval. If there are no values inside an interval, the sample's value
 * will be `defaultSampleValue`
 *
 * For example, if `trendData` has only values for each 10 minutes in the last hour (in milliseconds),
 * the periodic trend data, using the default settings (described below), will return a chart with
 * values for every minute between _1h ago_ and _now_. Since we only have data at minute 10, we will
 * display 6 datapoints with their respective `y` values, and the other 54 minutes with `0`.
 *
 *
 * ```
 * const defaultSettings = {
 *   interval: 60000, // sample every minute
 *   samplesAmount: 60, // 60 samples, means we are displaying data for an Hour
 *   periodEnd: Date.now(), // current system time in miliseconds
 *   defaultSampleValue: 0 // when there are no data in some interval, display 0
 * }
 * ```
 *
 *
 * @param {TrendData} trendData
 * @param {TrendDataSettings} settings
 * @returns {TrendData}
 */
export function getPeriodicTrendData(
  trendData,
  {
    interval = secondsToMs(units.secs_in_min),
    samplesAmount = 60,
    periodEnd = Date.now(),
    defaultSampleValue = 0,
  } = {},
) {
  const pointsTimestamps = Array(samplesAmount)
    .fill(periodEnd)
    .map((t, i) => t - i * interval)
    .reverse()

  if (!trendData) return pointsTimestamps.map(p => [p, defaultSampleValue])

  const periodStart = periodEnd - samplesAmount * interval + interval
  let auxTrendData = trendData.filter(([timestamp]) => timestamp >= periodStart && timestamp <= periodEnd)
  const periodicTrendData = []

  for (const pointTimestamp of pointsTimestamps) {
    const [head, tail] = splitWhen(([timestamp]) => timestamp > pointTimestamp, auxTrendData)
    const value = head.length ? mean(head.map(d => d[1])) : defaultSampleValue
    periodicTrendData.push([pointTimestamp, value])
    auxTrendData = tail
  }

  return periodicTrendData
}
