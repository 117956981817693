import { showErrorMessage, localized, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

const handledErrorsMapper = {
  ticket_updated_by_another_user: localizationKey("This ticket was updated by another user. Please refresh the page."),
  ticket_parent_can_not_be_equal_ticket_child: localizationKey("Ticket parent cannot be equal to Ticket child"),
  cannot_delete_ticket_parent_in_incidents: localizationKey("Cannot delete a Ticket that has Incidents"),
}

export const showUpdateErrors = (error, token = localizationKey("Error updating ticket")) => {
  const { graphQLErrors } = error

  graphQLErrors.forEach(graphQLError => {
    const handledErrorToken = handledErrorsMapper[graphQLError?.extensions?.errorCode]
    if (handledErrorToken) {
      showErrorMessage(localized(handledErrorToken))
    } else {
      reportErrorAndShowMessage(graphQLError, token)
    }
  })
}
