import PropTypes from "prop-types"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import EditorRow from "./EditorRow"
import Select from "../Selects/Select"

export default function EditorSelectionRow({
  ariaLabel,
  labelId,
  onChange,
  options,
  value,
  defaultValue,
  placeholder,
  disabled,
  errorMessage,
  onFocus,
  titleToken,
  labelRenderer,
  triggerTooltipLabel,
  ...rest
}) {
  return (
    <EditorRow titleToken={titleToken} {...rest}>
      <Select
        {...{
          labelId,
          options,
          value,
          defaultValue,
          onChange,
          placeholder,
          disabled,
          alignRight: true,
          errorMessage,
          onFocus,
          triggerAriaLabel: ariaLabel || localized(titleToken),
          labelRenderer,
          triggerTooltipLabel,
        }}
      />
    </EditorRow>
  )
}

EditorSelectionRow.propTypes = {
  labelId: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.any,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onFocus: PropTypes.func,
}
