export const loadGoogleIframe = ({ url, containerId, attributes }) =>
  new Promise((resolve, reject) => {
    const loadIframe = () => {
      const options = {
        url,
        where: document.getElementById(containerId),
        attributes,
      }
      return window.gapi.iframes.getContext().openChild(options)
    }
    if (window.gapi) {
      window.gapi.load("gapi.iframes", () => {
        const iframe = loadIframe()
        if (iframe) {
          return resolve(iframe)
        } else {
          return reject(new Error("Iframe could not be loaded"))
        }
      })
    } else {
      const script = document.createElement("script")
      script.src = "https://apis.google.com/js/api.js"
      script.onload = () => {
        window.gapi.load("gapi.iframes", () => {
          const iframe = loadIframe()
          if (iframe) {
            return resolve(iframe)
          } else {
            return reject(new Error("Iframe could not be loaded"))
          }
        })
      }
      script.onerror = error => {
        return reject(error)
      }
      document.head.appendChild(script)
    }
  })
