import { reduce, keys, either, compose, includes, pluck } from "ramda"
import qs from "qs"
import { localized, isUserAllowedToUpdateTicket, arrayToMapWithKey } from "js/includes/common/utils"
import { attributeValueRenderer } from "js/includes/configuration/integrations/ticketing/ticketAttributes/common"

export const optionsReducer = reduce((acc, { label, value }) => {
  acc[value] = label
  return acc
}, {})

export const buildTicketAttributesMapper = (ticketAttributes = {}) =>
  reduce(
    (acc, id) => {
      const attributeId = `attribute:${id}`

      acc[attributeId] = {
        id: attributeId,
        Header: ticketAttributes[id].name,
        disableSortBy: true,
        maxWidth: "300px",
        accessor: rowData => {
          return attributeValueRenderer[ticketAttributes[id].type]?.({ value: rowData[attributeId] }) || null
        },
      }

      return acc
    },
    {},
    keys(ticketAttributes),
  )

export const getNotDefinedColumn = columnId => ({
  id: columnId,
  Header: localized("general.notApplicable"),
  accessor: columnId,
})

export const doesSelectionIncludeClosedOrDeletedTickets = either(
  compose(includes(true), pluck("deleted")),
  compose(includes("CLOSED"), pluck("status")),
)

const thereAreNoTicketsWithConditionSource = tickets => !tickets.some(ticket => ticket.source === "CONDITION")

export const showReinstateOption = ({ tickets, isDeletedBoard }) =>
  !!tickets.length && isDeletedBoard && thereAreNoTicketsWithConditionSource(tickets) && isUserAllowedToUpdateTicket()

const mapTooltipUserToLegacyStructure = user =>
  user
    ? {
        firstName: user.userName || "",
        deleted: user.deleted,
      }
    : null

const mapTooltipToLegacyStructure = tooltip => {
  const lastComment = tooltip.comments[0]
  return {
    ...tooltip,
    description: {
      ...tooltip.description,
      user: mapTooltipUserToLegacyStructure(tooltip.user),
    },
    lastComment: lastComment
      ? {
          ...lastComment,
          user: mapTooltipUserToLegacyStructure(lastComment.user),
        }
      : null,
  }
}

export const buildTooltipCache = ({ tooltips, isFullTextSearchEnabled }) => {
  if (isFullTextSearchEnabled) {
    return arrayToMapWithKey("id", tooltips)
  }
  return reduce(
    (acc, tooltip) => {
      acc[tooltip.id] = mapTooltipToLegacyStructure(tooltip)
      return acc
    },
    {},
    tooltips,
  )
}

export const buildTicketEditorRoute = ({ boardId, deviceId, clientId, withHash = false }) => ticketId => {
  return `${withHash ? "#" : ""}/ticketing/ticket/${ticketId}${qs.stringify(
    { boardId, deviceId, clientId },
    { addQueryPrefix: true, skipNulls: true },
  )}`
}
