import { fetchJson, isNotNil } from "js/includes/common/utils"

export const getSystrayDivisionConfig = () => fetchJson("/divisionconfig/tray_icon")
export const updateSystrayDivisionConfig = ({ systrayId, enable }) =>
  fetchJson("/systray-config/set-default", {
    options: {
      method: "POST",
      body: JSON.stringify({
        ...(isNotNil(systrayId) ? { systrayId } : {}),
        ...(isNotNil(enable) ? { enable } : {}),
      }),
    },
  })

export const copySystrayConfig = ({ id: sourceSystrayId, name: newSystrayName }) =>
  fetchJson("/systray-config/copy", {
    options: {
      method: "POST",
      body: JSON.stringify({
        sourceSystrayId,
        newSystrayName,
      }),
    },
  })

export const getAllSystrays = () => fetchJson("/systray-config")
export const getSystrayById = id => fetchJson(`/systray-config/${id}`)

export const createSystray = systray =>
  fetchJson("/systray-config", {
    options: {
      method: "POST",
      body: JSON.stringify(systray),
    },
  })

export const updateSystray = systray => {
  const { id, updated, created, ...rest } = systray

  return fetchJson(`/systray-config/${id}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(rest),
    },
  })
}

export const deleteSystray = id =>
  fetchJson(`/systray-config/${id}`, {
    options: {
      method: "DELETE",
    },
  })

export const customFieldValidation = templates =>
  fetchJson("/template/HELP_REQUEST/validate", {
    options: {
      method: "POST",
      body: JSON.stringify(templates),
    },
  })

export const getOrgsBySystrayId = id => fetchJson(`/client/client-using-systray/${id}`)
