import React from "react"
import { propEq } from "ramda"
import showModal from "js/includes/common/services/showModal"
import {
  showSuccessMessage,
  fetchJson,
  fetch,
  showErrorMessage,
  ninjaReportError,
  localized,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import SupportFolderModal from "js/includes/remoteSupport/SupportFolderModal"

export const divisionConfigEndpoint = "/divisionconfig/remote_support"
const supportFolderEndpoint = "/remote-support/remote-support-folder"

export const getExpirationOptions = () => [
  { label: localized("Never"), value: 0 },
  { label: localized("{{count}} Hour(s)", { count: 4 }), value: 240 },
  { label: localized("{{count}} Hour(s)", { count: 8 }), value: 480 },
  { label: localized("{{count}} Hour(s)", { count: 12 }), value: 720 },
  { label: localized("{{count}} Day(s)", { count: 1 }), value: 1440 },
]

export const defaultRemoteSupportConfig = {
  enabled: false,
  expirationTTL: 240,
  connections: {
    windows: [],
    mac: [],
  },
  installMessage: "",
}

export const handleUpgradeSupportFolder = id => async (dispatch, getState) => {
  try {
    await fetch(`${supportFolderEndpoint}/${id}/upgrade-folder`, { options: { method: "PUT" } })

    dispatch(getRemoteSupportFolders())

    showSuccessMessage(localized("Saving..."))
  } catch (error) {
    if (error?.response?.status === 409) {
      const folder = getState().remoteSupport.supportFolders?.find(propEq("id", id))

      showModal(
        <SupportFolderModal
          nameCollisionError={true}
          values={folder}
          handleSave={async values => {
            await dispatch(handleUpdateSupportFolder(values))
            dispatch(handleUpgradeSupportFolder(values.id))
          }}
        />,
      )
    } else {
      showErrorMessage(localized("Unable to update support folders"))
      ninjaReportError(error)
    }
  }
}

export const handleDeleteSupportFolder = id => async dispatch => {
  try {
    await fetch(`${supportFolderEndpoint}/${id}`, { options: { method: "DELETE" } })

    dispatch(getRemoteSupportFolders())
  } catch (error) {
    showErrorMessage(localized("Unable to remove support folder"))
    ninjaReportError(error)
  }
}

export const handleUpdateSupportFolder = ({ id, name, description, expirationTTL }) => async dispatch => {
  try {
    const body = JSON.stringify({
      name,
      expirationTTL,
      description: description?.length ? description : null,
    })

    await fetch(`${supportFolderEndpoint}/${id}`, { options: { method: "PUT", body } })

    dispatch(getRemoteSupportFolders())

    showSuccessMessage(localized("Saving..."))
  } catch (error) {
    showErrorMessage(localized("Unable to update support folders"))
    ninjaReportError(error)
    throw error
  }
}

export const handleCreateSupportFolder = ({ name, description, expirationTTL }) => async dispatch => {
  try {
    const body = JSON.stringify({
      name,
      expirationTTL,
      description: description?.length ? description : null,
    })

    await fetch(supportFolderEndpoint, { options: { method: "POST", body } })

    dispatch(getRemoteSupportFolders())

    showSuccessMessage(localized("Saving..."))
  } catch (error) {
    if (error?.response?.status === 409) {
      showErrorMessage(localized("Name must be unique to Support Folders and Organizations"))
    } else {
      showErrorMessage(localized("Unable to create support folders"))
      ninjaReportError(error)
    }
    throw error
  }
}

export const getRemoteSupportFolders = () => async dispatch => {
  try {
    const response = await fetchJson(supportFolderEndpoint)
    dispatch({
      type: "REMOTE_SUPPORT_SET_SUPPORT_FOLDERS",
      payload: response,
    })
  } catch (error) {
    ninjaReportError(error)
    showErrorMessage(localized("Unable to get support folders"))
  }
}

export const requestRemoteSupportConfig = () => async dispatch => {
  const response = await fetchJson(divisionConfigEndpoint)

  if (response.resultCode === "SUCCESS") {
    dispatch({
      type: "REMOTE_SUPPORT_SET_SETTINGS",
      payload: response.content ?? {},
    })
  } else {
    throw new NinjaResponseError(response)
  }
}

export const getRemoteSupportConfig = () => async dispatch => {
  try {
    dispatch(requestRemoteSupportConfig())
  } catch (e) {
    reportErrorAndShowMessage(e)
  }
}

export const updateRemoteSupportConfig = content => async (dispatch, getState) => {
  const { enabled, expirationTTL, connections, installMessage } = getState().remoteSupport

  const response = await fetchJson(divisionConfigEndpoint, {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content: {
          enabled,
          expirationTTL,
          connections,
          installMessage,
          ...content,
        },
      }),
    },
  })

  if (response.resultCode === "SUCCESS") {
    showSuccessMessage(localized("Saving..."))

    return dispatch(requestRemoteSupportConfig())
  } else {
    throw new NinjaResponseError(response)
  }
}
