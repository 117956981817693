import { useState } from "react"
import PropTypes from "prop-types"
import { useTheme } from "@emotion/react"
import tokens from "@ninjaone/tokens"
import { getColorFromProps, isRequiredIf } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import DashboardWidget, { widgetPropTypes } from "./DashboardWidget"
import Body from "../../Typography/Body"
import Text from "../../Text"
import Tooltip from "../../Tooltip"
import Skeleton from "../../Skeleton/Skeleton"

/* This widget should not use styled components or flexbox to be used by the new report generator NJ-52179*/
const MetricsWidget = ({ metrics = [], isLoading, ...widgetProps }) => {
  const theme = useTheme()
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null)

  return (
    <DashboardWidget height={tokens.grid.itemHeight.XS} {...widgetProps}>
      <table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "fixed" }}>
        <tbody>
          <tr>
            {metrics.map((metric, index) => {
              const content = (
                <div
                  style={{
                    padding: `0 ${tokens.spacing[4]} 0 ${tokens.spacing[3]}`,
                    borderLeft: metric.color
                      ? `4px solid ${
                          isLoading
                            ? theme.colorAlertNeutralWeak
                            : getColorFromProps({}, { color: metric.color, theme })
                        }`
                      : `1px solid ${theme.colorBorderWeakest}`,
                  }}
                >
                  <div style={{ marginBottom: tokens.spacing[1] }}>
                    <Body color="colorTextWeak">{metric.titleText}</Body>
                  </div>
                  {isLoading ? (
                    <div style={{ height: "33px", paddingRight: tokens.spacing[1] }}>
                      <Skeleton width="100%" marginTop={tokens.spacing[1]} />
                    </div>
                  ) : (
                    <Text type="headingL" color="colorTextStrong" fontWeight={tokens.typography.fontWeight.medium}>
                      {localized(metric.value)}
                    </Text>
                  )}
                  <div style={{ minHeight: "18px" }}>
                    {metric.descriptionText && !isLoading && (
                      <Body type="bodyXs" color="colorTextWeakest">
                        {metric.descriptionText}
                      </Body>
                    )}
                  </div>
                </div>
              )
              return (
                <td
                  key={metric.titleText}
                  style={{
                    width: `${100 / metrics.length}%`,
                    padding: 0,
                  }}
                >
                  {metric.action ? (
                    <button
                      style={{
                        cursor: "pointer",
                        background: hoveredItemIndex === index ? theme.colorForegroundHover : "transparent",
                        width: "100%",
                        padding: 0,
                        border: 0,
                        textAlign: "left",
                        fontFamily: "inherit",
                        outlineColor: theme.colorForegroundFocus,
                      }}
                      disabled={isLoading}
                      onClick={metric.action}
                      onMouseEnter={() => setHoveredItemIndex(index)}
                      onMouseLeave={() => setHoveredItemIndex(null)}
                    >
                      <Tooltip label={metric.actionTooltipText}>{content}</Tooltip>
                    </button>
                  ) : (
                    content
                  )}
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>
    </DashboardWidget>
  )
}

MetricsWidget.propTypes = {
  ...widgetPropTypes,
  isLoading: PropTypes.bool,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      titleText: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      descriptionText: PropTypes.string,
      action: PropTypes.func,
      actionTooltipText: isRequiredIf(
        PropTypes.string,
        props => props.hasOwnProperty("action"),
        "`actionTooltipText` is required if passing a value for `action`",
      ),
    }),
  ).isRequired,
}

export default MetricsWidget
