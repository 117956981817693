import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { css } from "@emotion/react"
import { getTextSize, sizer } from "@ninjaone/utils"
import { useAccessibleActionIcon } from "@ninjaone/components"

export const getMainNavStyle = ({ collapsed }) => theme => css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: ${sizer(8)};

  && {
    color: ${theme.colorThemeText};

    svg {
      &.main-icon {
        font-size: ${getTextSize("md")};
        flex-shrink: 0;
      }

      &.secondary-icon {
        font-size: ${getTextSize("sm")};
      }
    }
  }

  ${!collapsed &&
    `
    padding-right: ${sizer(4)};
    padding-left: ${sizer(4)};
  `}
`

export const mainNavTextStyle = css`
  font-size: ${getTextSize("sm")};
  flex: 1;
  margin-left: ${sizer(3)};
`

const actionIconStyle = theme => css`
  font-size: ${getTextSize("sm")};
  margin-right: ${sizer(2)};

  &:focus-visible {
    color: ${theme.colorForegroundFocus};
  }
`

const getButtonStyle = ({ active }) => theme => css`
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;

  ${active &&
    `
    color: ${theme.colorThemeTextStrong};
    background-color: ${theme.colorThemeBackgroundSelected};
  `}

  ${!active &&
    `
    &:hover {
      background-color: ${theme.colorThemeBackgroundSelected};
    }
  `}

  &:focus-visible {
    background-color: ${theme.colorThemeBackgroundSelected};
    outline: 2px solid ${theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

const MainNavButton = ({ icon, actionIcons, secondaryIcon, label, collapsed, onClick, onClickAction, active }) => {
  const { actionIconsRenderer, parentElementProps } = useAccessibleActionIcon({
    actionIcons,
    actionIconStyle,
  })

  const mainNavStyle = getMainNavStyle({ collapsed })
  const buttonStyle = getButtonStyle({ active })

  return (
    <button
      {...{
        onClick,
        "data-test-sidebar-link": "dashboard-title-link",
        "aria-label": label,
        css: [mainNavStyle, buttonStyle],
        ...parentElementProps,
      }}
    >
      <FontAwesomeIcon icon={icon} className="main-icon" data-test-sidebar-link="dashboard-title-icon" fixedWidth />
      {!collapsed && (
        <>
          <span css={mainNavTextStyle}>{label}</span>
          {actionIconsRenderer?.()}
          {secondaryIcon && <FontAwesomeIcon className="secondary-icon" icon={secondaryIcon} />}
        </>
      )}
    </button>
  )
}

export default MainNavButton
