import { useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import styled from "@emotion/styled"
import { $createLinkNode, $isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $findMatchingParent, mergeRegister } from "@lexical/utils"
import {
  $getSelection,
  $isRangeSelection,
  $isLineBreakNode,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical"

import { CheckLightIcon, XMarkLightIcon, ChainSlashLightIcon, PenLightIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

import Body from "../../Typography/Body"
import { sanitizeUrl, getSelectedNode, setFloatingElemPositionForLinkEditor } from "../utils"

const StyledLinkEditor = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: ${({ theme }) => theme.colorBackground};
  transition: opacity 0.5s;
  will-change: transform;
  box-shadow: ${({ theme }) => theme.elevationWeak};

  ${({ withBorder }) =>
    withBorder &&
    `
    border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
    border-radius: ${tokens.spacing[1]};
  `}
`

const StyledLinkEditorInput = styled.input`
  display: block;
  overflow: auto;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackground};
  padding: 0 ${tokens.spacing[3]};
  height: ${tokens.spacing[9]};
  border-radius: ${tokens.spacing[1]};
  font-size: ${tokens.typography.fontSize.body};
  font-family: ${tokens.typography.fontFamily.primary};
  border: 0;
  outline: 0;
  position: relative;
  flex: 1;
`

const StyledLinkEditorView = styled.div`
  height: ${tokens.spacing[9]};
  gap: ${tokens.spacing[2]};
  display: flex;
  align-items: center;
  width: 100%;
  border: 0;
  outline: 0;
  position: relative;
`

const StyledLinkEditorActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing[1]};
  padding-right: ${tokens.spacing[1]};
`

const StyledLinkEditorActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${tokens.spacing[8]};
  width: ${tokens.spacing[8]};
  border-radius: ${tokens.spacing[1]};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }
`

const StyledLinkEditorViewAnchor = styled.a`
  width: 100%;
  display: block;
  word-break: break-word;
  overflow: auto;
  padding-left: ${tokens.spacing[3]};
  text-decoration: underline;

  /* Override base.less */
  &:hover {
    text-decoration: underline;
  }
`

const FloatingLinkEditor = ({ editor, isLink, setIsLink, anchorElem, isLinkEditMode, setIsLinkEditMode }) => {
  const editorRef = useRef(null)
  const inputRef = useRef(null)
  const [linkUrl, setLinkUrl] = useState("")
  const [editedLinkUrl, setEditedLinkUrl] = useState("https://")
  const [lastSelection, setLastSelection] = useState(null)

  const $updateLinkEditor = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const linkParent = $findMatchingParent(node, $isLinkNode)

      if (linkParent) {
        setLinkUrl(linkParent.getURL())
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL())
      } else {
        setLinkUrl("")
      }
      if (isLinkEditMode) {
        setEditedLinkUrl(linkUrl)
      }
    }
    const editorElem = editorRef.current
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (editorElem === null) return

    const rootElement = editor.getRootElement()

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      const domRect = nativeSelection.focusNode?.parentElement?.getBoundingClientRect()
      if (domRect) {
        domRect.y += 40
        setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem)
      }
      setLastSelection(selection)
    } else if (!activeElement || activeElement.className !== "link-input") {
      if (rootElement !== null) {
        setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem)
      }
      setLastSelection(null)
      setIsLinkEditMode(false)
      setLinkUrl("")
    }

    return true
  }, [anchorElem, editor, setIsLinkEditMode, isLinkEditMode, linkUrl])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        $updateLinkEditor()
      })
    }

    window.addEventListener("resize", update)

    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update)
    }

    return () => {
      window.removeEventListener("resize", update)

      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update)
      }
    }
  }, [anchorElem.parentElement, editor, $updateLinkEditor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateLinkEditor()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateLinkEditor()
          return true
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false)
            return true
          }
          return false
        },
        COMMAND_PRIORITY_HIGH,
      ),
    )
  }, [editor, $updateLinkEditor, setIsLink, isLink])

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateLinkEditor()
    })
  }, [editor, $updateLinkEditor])

  useEffect(() => {
    if (isLinkEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isLinkEditMode, isLink])

  const monitorInputInteraction = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleLinkSubmission()
    } else if (event.key === "Escape") {
      event.preventDefault()
      setIsLinkEditMode(false)
    }
  }

  const handleLinkSubmission = () => {
    if (lastSelection !== null) {
      if (linkUrl !== "") {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl))
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const parent = getSelectedNode(selection).getParent()
            if ($isAutoLinkNode(parent)) {
              const linkNode = $createLinkNode(parent.getURL(), {
                rel: parent.__rel,
                target: parent.__target,
                title: parent.__title,
              })
              parent.replace(linkNode, true)
            }
          }
        })
      }
      setEditedLinkUrl("https://")
      setIsLinkEditMode(false)
    }
  }

  return (
    <StyledLinkEditor ref={editorRef} withBorder={isLink}>
      {!isLink ? null : isLinkEditMode ? (
        <>
          <StyledLinkEditorInput
            ref={inputRef}
            className="link-input"
            value={editedLinkUrl}
            onChange={event => {
              setEditedLinkUrl(event.target.value)
            }}
            onKeyDown={event => {
              monitorInputInteraction(event)
            }}
          />
          <StyledLinkEditorActions>
            <StyledLinkEditorActionButton
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={() => setIsLinkEditMode(false)}
            >
              <XMarkLightIcon />
            </StyledLinkEditorActionButton>

            <StyledLinkEditorActionButton
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={handleLinkSubmission}
            >
              <CheckLightIcon />
            </StyledLinkEditorActionButton>
          </StyledLinkEditorActions>
        </>
      ) : (
        <StyledLinkEditorView>
          <StyledLinkEditorViewAnchor href={sanitizeUrl(linkUrl)} target="_blank" rel="noopener noreferrer">
            <Body color="colorTextAction">{linkUrl}</Body>
          </StyledLinkEditorViewAnchor>
          <StyledLinkEditorActions>
            <StyledLinkEditorActionButton
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                setEditedLinkUrl(linkUrl)
                setIsLinkEditMode(true)
              }}
            >
              <PenLightIcon />
            </StyledLinkEditorActionButton>
            <StyledLinkEditorActionButton
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
              }}
            >
              <ChainSlashLightIcon />
            </StyledLinkEditorActionButton>
          </StyledLinkEditorActions>
        </StyledLinkEditorView>
      )}
    </StyledLinkEditor>
  )
}

function useFloatingLinkEditor(editor, anchorElem, isLinkEditMode, setIsLinkEditMode) {
  const [activeEditor, setActiveEditor] = useState(editor)
  const [isLink, setIsLink] = useState(false)

  useEffect(() => {
    function updateToolbar() {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const focusNode = getSelectedNode(selection)
        const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode)
        const focusAutoLinkNode = $findMatchingParent(focusNode, $isAutoLinkNode)

        if (!(focusLinkNode || focusAutoLinkNode)) {
          setIsLink(false)
          return
        }

        const badNode = selection
          .getNodes()
          .filter(node => !$isLineBreakNode(node))
          .find(node => {
            const linkNode = $findMatchingParent(node, $isLinkNode)
            const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode)
            return (
              (focusLinkNode && !focusLinkNode.is(linkNode)) ||
              (linkNode && !linkNode.is(focusLinkNode)) ||
              (focusAutoLinkNode && !focusAutoLinkNode.is(autoLinkNode)) ||
              (autoLinkNode && (!autoLinkNode.is(focusAutoLinkNode) || autoLinkNode.getIsUnlinked()))
            )
          })

        if (!badNode) {
          setIsLink(true)
        } else {
          setIsLink(false)
        }
      }
    }
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar()
          setActiveEditor(newEditor)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        CLICK_COMMAND,
        payload => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection)
            const linkNode = $findMatchingParent(node, $isLinkNode)
            if ($isLinkNode(linkNode) && (payload.metaKey || payload.ctrlKey)) {
              window.open(linkNode.getURL(), "_blank")
              return true
            }
          }
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor])

  return createPortal(
    <FloatingLinkEditor
      editor={activeEditor}
      isLink={isLink}
      anchorElem={anchorElem}
      setIsLink={setIsLink}
      isLinkEditMode={isLinkEditMode}
      setIsLinkEditMode={setIsLinkEditMode}
    />,
    anchorElem,
  )
}

export const FloatingLinkEditorPlugin = ({ anchorElem = document.body, isLinkEditMode, setIsLinkEditMode }) => {
  const [editor] = useLexicalComposerContext()
  return useFloatingLinkEditor(editor, anchorElem, isLinkEditMode, setIsLinkEditMode)
}
