import { always, cond, equals, T, pathOr, find, propEq, compose, includes, either } from "ramda"
import { localized, localizationKey, contains, validations } from "js/includes/common/utils"
import {
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faFilePdf,
  faFileCsv,
  faFileArchive,
  faFileCode,
  faFileAlt,
  faFileImage,
} from "@fortawesome/pro-solid-svg-icons"
import { faFileVisio } from "media/icons"
import {
  IMAGE_EXTENSIONS,
  WORD_EXTENSIONS,
  EXCEL_EXTENSIONS,
  POWER_POINT_EXTENSIONS,
  VISIO_EXTENSIONS,
  MISCELLANEOUS_EXTENSIONS,
  WEB_EXTENSIONS,
  COMPRESSED_FILE_EXTENSIONS,
  PDF,
  CSV,
} from "js/includes/ticketing/editor/shared/constants"

export * from "js/includes/common/utils/ssrAndWebUtils/ticketing"

export const localizeTicketType = type => {
  switch (type) {
    case "PROBLEM":
      return localized("Problem")
    case "QUESTION":
      return localized("Question")
    case "INCIDENT":
      return localized("Incident")
    case "TASK":
      return localized("Task")
    default:
      return "-"
  }
}

export const getIconProps = (extension, fontSize = "40px") =>
  cond([
    [equals(PDF), always({ icon: faFilePdf, fontSize, color: "ninjaRed" })],
    [equals(CSV), always({ icon: faFileCsv, fontSize, color: "ninjaGreen" })],
    [contains(IMAGE_EXTENSIONS), always({ icon: faFileImage, fontSize, color: "ninjaBlueSaturated" })],
    [contains(WORD_EXTENSIONS), always({ icon: faFileWord, fontSize, color: "ninjaBlueSaturated" })],
    [contains(POWER_POINT_EXTENSIONS), always({ icon: faFilePowerpoint, fontSize, color: "lightOrange" })],
    [contains(EXCEL_EXTENSIONS), always({ icon: faFileExcel, fontSize, color: "ninjaGreen" })],
    [contains(VISIO_EXTENSIONS), always({ icon: faFileVisio, fontSize, color: "ninjaBlueSaturated" })],
    [contains(WEB_EXTENSIONS), always({ icon: faFileCode, fontSize, color: "ninjaBlueSaturated" })],
    [contains(COMPRESSED_FILE_EXTENSIONS), always({ icon: faFileArchive, fontSize, color: "ninjaBlue" })],
    [contains(MISCELLANEOUS_EXTENSIONS), always({ icon: faFileAlt, fontSize, color: "ninjaLight" })],
  ])(extension)

export const isTicketFieldRequired = (technicianOption, isEditingTicket) =>
  technicianOption === (isEditingTicket ? "REQUIRED_TO_RESOLVE" : "REQUIRED_TO_CREATE")

export const findNew = ({ isNew }) => isNew

const getKeysAndEmails = (selected, keyName = "keys") =>
  selected.reduce(
    (acc, option) => {
      const { value } = option
      const { success: isEmail } = validations.email(value)
      if (isEmail) {
        acc.emails = [...acc.emails, value]
      } else {
        acc[keyName] = [...acc[keyName], value]
      }
      return acc
    },
    { [keyName]: [], emails: [] },
  )

export const handleKeysAndEmailsChange = (values, onChange, keyName = "keys") => {
  const newTag = find(findNew, values || [])
  if (newTag) {
    //Make sure is an email
    const { success } = validations.email(newTag.value)
    if (!success) return
  }
  onChange(getKeysAndEmails(values || [], keyName))
}

export const getTicketFormTimerSettings = (ticketForms = [], ticketFormId) =>
  compose(pathOr({}, ["content", "timerSettings"]), find(propEq("id", ticketFormId)))(ticketForms)

const includesSpaces = includes(" ")
const includesQuotes = either(includes('"'), includes("'"))
const includesComma = includes(",")

export const getTagErrors = tag => {
  const hasSpaces = includesSpaces(tag)
  const hasQuotes = includesQuotes(tag)
  const hasComma = includesComma(tag)
  const isTooLong = tag.length > 25

  const isInvalidTag = hasSpaces || hasQuotes || isTooLong || hasComma

  return (
    isInvalidTag && [
      ...(hasSpaces ? [localized("Tag cannot contain spaces")] : []),
      ...(hasQuotes ? [localized("Tag cannot contain quotes")] : []),
      ...(hasComma ? [localized("Tag cannot contain commas")] : []),
      ...(isTooLong ? [localized("Exceeds max character limit of {{limit}}", { limit: 25 })] : []),
    ]
  )
}

export const getTitleToken = cond([
  [equals("BOARD"), always(localizationKey("Ticket Board"))],
  [equals("EVENT_BASED"), always(localizationKey("Event Based"))],
  [equals("TIME_BASED"), always(localizationKey("Time Based"))],
  [equals("CONDITION_RULE"), always(localizationKey("Condition Rule"))],
  [equals("SCRIPT_RULE"), always(localizationKey("Script Rule"))],
  [equals("RESPONSE_TEMPLATE"), always(localizationKey("Response Template"))],
  [equals("ACTIVITY_RULE"), always(localizationKey("Activity Rule"))],
  [
    T,
    t => {
      throw new Error(`Invalid triggerType: ${t}`)
    },
  ],
])

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK"
  }, callback)
}

export function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === "IMAGE"
  }, callback)
}

export const findMentionEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === "MENTION"
  }, callback)
}
