import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"
import { localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../Text"

const StyledPlaceholder = styled.div`
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: ${tokens.spacing[2]};
  left: ${tokens.spacing[4]};
  right: ${tokens.spacing[4]};
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
`

export const Placeholder = ({ token = localizationKey("Enter some text...") }) => {
  return (
    <StyledPlaceholder>
      <Text size="sm" color="colorTextWeakest" token={token} />
    </StyledPlaceholder>
  )
}
