import { useMemo } from "react"
import { connect } from "react-redux"
import { compose, path, assoc, map, when } from "ramda"

import { AlertMessage, Select, Text, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

import { Box, Flex } from "js/includes/components/Styled"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { localized, localizationKey } from "js/includes/common/utils"
import { StyledTitleLabel } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/MobileApplicationsForm.jsx"
import {
  KioskPowerButtonActions,
  KioskSystemErrorWarnings,
  KioskSystemNavigation,
  KioskStatusBar,
  KioskDeviceSettings,
  checkKioskAppExist,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import InheritableRowPolicyItem from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"

const OptionRenderer = ({ tooltipToken, labelToken }) => (
  <Tooltip label={localized(tooltipToken)}>
    <Flex justifyContent="space-between" width="100%" minWidth="275px" alignItems="baseline">
      <Text type="body" token={labelToken} />
      <Box>
        <RegularInfoCircleIcon size="sm" />
      </Box>
    </Flex>
  </Tooltip>
)

const FieldRow = ({
  labelToken,
  helpTextToken,
  select,
  category,
  fieldId,
  disabled,
  onHandleChange,
  kioskCustomization,
  isLast,
}) => {
  return (
    <InheritableRowPolicyItem
      pathToItem={`applications.kioskCustomization.${category}`}
      inheritableItem={kioskCustomization[category]}
      wrapperProps={{ padding: `${tokens.spacing[6]} ${tokens.spacing[2]}` }}
      noBorder={isLast}
    >
      <Flex key="PowerButton" justifyContent="space-between" alignItems="center">
        <Box>
          <Text type="body" color="colorTextStrong" token={labelToken} />
          <Text type="body" token={helpTextToken} maxWidth="576px" textWrap />
        </Box>
        <Select
          {...{
            options: select.options,
            value: select.value,
            onChange: value => onHandleChange(category, fieldId, value),
            alignRight: false,
            popoverProps: {
              portal: false,
            },
            labelId: fieldId,
            disabled: disabled,
          }}
        />
      </Flex>
    </InheritableRowPolicyItem>
  )
}

const kioskSettingsAlertMessageTokens = {
  title: localizationKey("Settings disabled"),
  message: localized("Enable native multi-app kiosk launcher or add an app and set it to single app kiosk."),
}

export const powerButtonOptions = [
  {
    value: KioskPowerButtonActions.POWER_BUTTON_ACTIONS_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: KioskPowerButtonActions.POWER_BUTTON_AVAILABLE,
    labelToken: localizationKey("Available"),
  },
  {
    value: KioskPowerButtonActions.POWER_BUTTON_BLOCKED,
    labelToken: localizationKey("Blocked"),
  },
]

export const systemErrorWarningsOptions = [
  {
    value: KioskSystemErrorWarnings.SYSTEM_ERROR_WARNINGS_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: KioskSystemErrorWarnings.ERROR_AND_WARNINGS_ENABLED,
    labelToken: localizationKey("Enabled"),
  },
  {
    value: KioskSystemErrorWarnings.ERROR_AND_WARNINGS_MUTED,
    labelToken: localizationKey("Muted"),
  },
]

export const systemNavigationOptions = [
  {
    value: KioskSystemNavigation.SYSTEM_NAVIGATION_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: KioskSystemNavigation.NAVIGATION_ENABLED,
    labelToken: localizationKey("Available"),
  },
  {
    value: KioskSystemNavigation.HOME_BUTTON_ONLY,
    labelToken: localizationKey("Home button only"),
  },
  {
    value: KioskSystemNavigation.NAVIGATION_DISABLED,
    labelToken: localizationKey("Blocked"),
  },
]

export const statusBarOptions = [
  {
    value: KioskStatusBar.STATUS_BAR_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: KioskStatusBar.NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED,
    labelToken: localizationKey("Enabled"),
  },
  {
    value: KioskStatusBar.SYSTEM_INFO_ONLY,
    labelToken: localizationKey("System information only"),
  },
  {
    value: KioskStatusBar.NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED,
    labelToken: localizationKey("Disabled"),
  },
]

export const deviceSettingsOptions = [
  {
    value: KioskDeviceSettings.DEVICE_SETTINGS_UNSPECIFIED,
    labelToken: localizationKey("Unspecified"),
  },
  {
    value: KioskDeviceSettings.SETTINGS_ACCESS_ALLOWED,
    labelToken: localizationKey("Allowed"),
  },
  {
    value: KioskDeviceSettings.SETTINGS_ACCESS_BLOCKED,
    labelToken: localizationKey("Blocked"),
  },
]

const MobileApplicationsKioskSettings = ({ kioskCustomization, isMultiOrSingleKioskModeSet, updatePolicyItem }) => {
  const {
    powerButtonActionsSettings,
    systemErrorWarningsSettings,
    systemNavigationSettings,
    statusBarSettings,
    deviceSettings,
  } = kioskCustomization

  const kioskCustomizationProperties = useMemo(
    () => ({
      powerButtonActions:
        powerButtonActionsSettings?.powerButtonActions || KioskPowerButtonActions.POWER_BUTTON_ACTIONS_UNSPECIFIED,
      systemErrorWarnings:
        systemErrorWarningsSettings?.systemErrorWarnings || KioskSystemErrorWarnings.SYSTEM_ERROR_WARNINGS_UNSPECIFIED,
      systemNavigation:
        systemNavigationSettings?.systemNavigation || KioskSystemNavigation.SYSTEM_NAVIGATION_UNSPECIFIED,
      statusBar: statusBarSettings?.statusBar || KioskStatusBar.STATUS_BAR_UNSPECIFIED,
      deviceSettingsValue: deviceSettings?.deviceSettings || KioskDeviceSettings.DEVICE_SETTINGS_UNSPECIFIED,
    }),
    [
      powerButtonActionsSettings,
      systemErrorWarningsSettings,
      systemNavigationSettings,
      statusBarSettings,
      deviceSettings,
    ],
  )

  const isSystemNavSetToUnspecifiedOrDisabled =
    kioskCustomizationProperties.systemNavigation === KioskSystemNavigation.SYSTEM_NAVIGATION_UNSPECIFIED ||
    kioskCustomizationProperties.systemNavigation === KioskSystemNavigation.NAVIGATION_DISABLED

  const isStatusBarEnabled =
    kioskCustomizationProperties.statusBar === KioskStatusBar.NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED

  const disableOption = (option, fieldId) =>
    compose(
      assoc("disabled", true),
      assoc("LabelComponent", () => (
        <OptionRenderer
          labelToken={option.labelToken}
          tooltipToken={
            fieldId === "systemNavigation"
              ? localizationKey("Status bar configuration prevents disabling navigation")
              : localizationKey("System navigation configurations prevents enabling status bar")
          }
        />
      )),
    )(option)

  const kioskCustomizationSettingsList = useMemo(
    () => [
      {
        category: "powerButtonActionsSettings",
        fieldId: "powerButtonActions",
        labelToken: localizationKey("Power button"),
        helpTextToken: localizationKey("Determines the long-press button behaviour in kiosk mode."),
        select: {
          options: powerButtonOptions,
          value: kioskCustomizationProperties.powerButtonActions,
        },
      },
      {
        category: "systemErrorWarningsSettings",
        fieldId: "systemErrorWarnings",
        labelToken: localizationKey("System error warnings"),
        helpTextToken: localizationKey(
          "Determines if system error warnings are blocked, unresponsive apps will force-close automatically.",
        ),
        select: {
          options: systemErrorWarningsOptions,
          value: kioskCustomizationProperties.systemErrorWarnings,
        },
      },
      {
        category: "systemNavigationSettings",
        fieldId: "systemNavigation",
        labelToken: localizationKey("System navigation"),
        helpTextToken: localizationKey("Determines enabling navigation buttons(e.g., Home, Overview) in kiosk mode."),
        select: {
          options: isStatusBarEnabled
            ? map(
                when(
                  option =>
                    option.value === KioskSystemNavigation.SYSTEM_NAVIGATION_UNSPECIFIED ||
                    option.value === KioskSystemNavigation.NAVIGATION_DISABLED,
                  option => disableOption(option, "systemNavigation"),
                ),
                systemNavigationOptions,
              )
            : systemNavigationOptions,
          value: kioskCustomizationProperties.systemNavigation,
        },
      },
      {
        category: "statusBarSettings",
        fieldId: "statusBar",
        labelToken: localizationKey("Status bar"),
        helpTextToken: localizationKey("Determines if system info and notifications are disabled in kiosk mode."),
        select: {
          options: isSystemNavSetToUnspecifiedOrDisabled
            ? map(
                when(
                  option => option.value === KioskStatusBar.NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED,
                  option => disableOption(option, "statusBar"),
                ),
                statusBarOptions,
              )
            : statusBarOptions,
          value: kioskCustomizationProperties.statusBar,
        },
      },
      {
        category: "deviceSettings",
        fieldId: "deviceSettings",
        labelToken: localizationKey("Device settings"),
        helpTextToken: localizationKey("Determines if a user can access device's settings app in kiosk mode."),
        select: {
          options: deviceSettingsOptions,
          value: kioskCustomizationProperties.deviceSettingsValue,
        },
      },
    ],
    [kioskCustomizationProperties, isSystemNavSetToUnspecifiedOrDisabled, isStatusBarEnabled],
  )

  const onKioskSettingUpdated = (category, field, value) => {
    if (value === path([category, field], kioskCustomization)) return
    const updatedKioskCustomization = assoc(field, value, kioskCustomization[category])
    updatePolicyItem(`applications.kioskCustomization.${category}`, null, updatedKioskCustomization)
  }

  return (
    <Box margin={[tokens.spacing[4], 0, tokens.spacing[2]]}>
      <Box margin={[tokens.spacing[1], 0, tokens.spacing[2]]}>
        {!isMultiOrSingleKioskModeSet && (
          <AlertMessage variant="info" titleToken={kioskSettingsAlertMessageTokens.title}>
            {kioskSettingsAlertMessageTokens.message}
          </AlertMessage>
        )}
      </Box>
      <StyledTitleLabel token={localizationKey("Kiosk mode settings")} />
      {kioskCustomizationSettingsList.map((kioskSetting, index) => (
        <FieldRow
          key={kioskSetting.fieldId}
          onHandleChange={onKioskSettingUpdated}
          {...{ ...kioskSetting, kioskCustomization, disabled: !isMultiOrSingleKioskModeSet }}
          isLast={index === kioskCustomizationSettingsList.length - 1}
        />
      ))}
    </Box>
  )
}

export default connect(
  ({
    policyEditor: {
      policy: {
        content: { applications },
      },
    },
  }) => ({
    kioskCustomization: applications.kioskCustomization,
    isMultiOrSingleKioskModeSet:
      applications.kioskCustomLauncherSettings?.kioskCustomLauncherEnabled ||
      checkKioskAppExist(applications.settingsByApplication),
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(MobileApplicationsKioskSettings)
