import { reduce } from "ramda"
import { useMemo } from "react"

import { reverseBytes } from "js/includes/ticketing/editor/shared/components/WYSIWYGEditor/attachmentUtils"

import { Attachment } from "./Attachment"

const extensionToMimeTypeFallback = {
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  gif: "image/gif",
}

const buildNonInlineAttachments = attachments =>
  reduce(
    (acc, attachment) => {
      if (!attachment.metadata.inline) {
        acc.push({
          ...attachment,
          file: {
            name: attachment.metadata.name,
            size: reverseBytes(attachment.metadata.size),
            type:
              attachment.metadata.mimeType || extensionToMimeTypeFallback[attachment.metadata.extension.toLowerCase()],
          },
        })
      }
      return acc
    },
    [],
    attachments || [],
  )

export const TicketAttachments = ({ attachments }) => {
  const nonInlineAttachments = useMemo(() => buildNonInlineAttachments(attachments), [attachments])

  return nonInlineAttachments.length > 0
    ? nonInlineAttachments.map(attachment => <Attachment {...{ key: attachment.id, attachment }} />)
    : null
}
