import styled from "@emotion/styled"
import { borderRadius, spacing, typography } from "@ninjaone/tokens"
import { Body } from "@ninjaone/components"
import { StyledEmptyCard } from "@ninjaone/components/src/Card"
import { localized } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import Link from "js/includes/components/Link"

const StyledCard = styled(StyledEmptyCard)`
  display: flex;
  flex: 1;
  align-items: center;
  padding: ${spacing[3]};
  gap: ${spacing[4]};
  cursor: pointer;
  &:hover {
    box-shadow: ${({ theme }) => `2px 2px 10px ${theme.colorBackgroundAccentNeutralWeak}`};
  }
`

const StyledIconContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colorBackgroundProduct};
  border-radius: ${borderRadius[1]};
  height: 48px;
  width: 48px;
`
export const ResourceCard = ({
  LeftIcon,
  RightIcon,
  titleToken,
  onClick,
  isAnchor,
  isAllowedUserForResource = true,
  dojoUrl,
}) => {
  if (!isAllowedUserForResource) return null

  const ResourceCardContent = () => {
    return (
      <>
        <StyledIconContainer>
          <LeftIcon size="md" color="white" />
        </StyledIconContainer>
        <Flex gap={spacing[1]} alignItems="center">
          <Body color="colorTextWeak" fontWeight={typography.fontWeight.medium} textWrap>
            {localized(titleToken)}
          </Body>
          {RightIcon && <RightIcon color="colorTextWeak" size="sm" />}
        </Flex>
      </>
    )
  }

  return dojoUrl ? (
    <Link href={dojoUrl} increaseSpacing>
      <StyledCard>
        <ResourceCardContent />
      </StyledCard>
    </Link>
  ) : (
    <StyledCard as="button" {...{ onClick }}>
      <ResourceCardContent />
    </StyledCard>
  )
}
