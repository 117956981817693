import { compose, curry, defaultTo, find, isEmpty, map, prop, propEq, test, xor } from "ramda"
import { isNilOrEmpty, isNumber } from "js/includes/common/utils"
import { isValidPhoneNumber } from "react-phone-number-input"
import { defaultToEmpty, localized } from "./"
import {
  EMAIL_REGEX,
  MAX_FLOAT_VALUE,
  MAX_INTEGER_VALUE,
  MIN_FLOAT_VALUE,
  MIN_INTEGER_VALUE,
} from "js/includes/common/_constants"

const pattern = {
  serverPath: /^ftp:\/\/(?:(?:[a-zA-Z0-9._~!$&'()*+,;=%-]+:[^\s@]*)?@)?[a-zA-Z0-9._~!$&'()*+,;=:%-]+(?:\.[a-zA-Z]{2,})(?::\d+)?(?:\/[a-zA-Z0-9._~!$&'()*+,;=:@%/?-]*)?$/,

  extensionType: /^%*[a-zA-Z0-9\s_.()-:]+(?:\.[a-zA-Z0-9]{1,254})?(?<!\.)$/,

  processType: /^%?([a-zA-Z0-9\s_\\.\-():%!]+\.exe)$/,

  volumeOrfolderPathwithWildCards: /^(?:[a-zA-Z]:(\\.*)?|%[^%]+%(\\.*)?|[*?][^\\]*(\\.*)?)$/,

  networkPathwithWildCards: /^\\\\(?:[^\\]+\\)*[^\\]+\\?$|^(%.+%\\(?:[^%\\]+\\)*[^%\\]+\\?)$/,

  email: EMAIL_REGEX,

  //DEPRECATED (use secureUrl instead)
  url: /^https?:\/\/+[A-Z0-9]+\.[A-Z]{2,}/i,

  secureValidUrl: /^https:\/\/(?:www\.)?(?:[a-zA-Z0-9-]+\.){0,4}[a-zA-Z0-9-]+\.(?:[\p{L}]{2,})(?:\.[\p{L}]{2,})?(?::\d{1,5})?(?:\/[-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/iu,

  secureUrl: /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{1,15}(\.[a-zA-Z]{1,15}){0,1}(\/[-a-zA-Z0-9()@:%_+.~#?&//=]*)?/i,

  secureIp: /https:\/\/(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:(6553[0-5]|655[0-2][0-9]|65[0-4][0-9]{2}|6[0-4][0-9]{3}|[1-5][0-9]{4}|[0-9]{1,4}))?(\/[-a-zA-Z0-9()@:%_+.~#?&//=]*)?$/i,

  browserCall: /^tel:[0-9]+$/i,

  dns: /^[A-Z0-9]+[\\.-][A-Z]*[\\.-]*[A-Z]{2,}/i,

  ip: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i,

  CIDRBlock: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/(([0-9])|([1-2][0-9])|(3[0-2]))$/i,

  subdomain: /^(?!.*-$)(?!-)(?!.*--)[a-z0-9-]+$/,

  // Source: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s15.html
  domain: /^((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/,

  pipe: /^(.*?\|)/,

  uncPath: /^\\\\[a-zA-Z0-9.\-_]{1,}(\\[a-zA-Z0-9\-_]{1,}){1,}[$]{0,1}/i,

  windowsRoot: /^([a-zA-Z]:)[/|\\]*$/,

  windowsPath: /^([a-z]:)[/|\\]{1}(?:[./\\ ](?![./\\\n])|[^<>:"|?*./\\ \n])*$/,

  macPath: /^[/|\\]{1}(?:[./\\ ](?![./\\\n])|[^<>:"|?*./\\ \n])*$/,

  ipv4Address: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,

  ipv4Range: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\s*[-]\s*[0-9]+$/,

  ipv6Address: /^((?:[0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(?=(?:[0-9A-Fa-f]{0,4}:){0,6}(?:[0-9A-Fa-f]{1,4})(?:::[0-9A-Fa-f]{1,4}){0,1})(?:[0-9A-Fa-f]{1,4}:){0,6}(?:::[0-9A-Fa-f]{1,4})?|(?=(?:[0-9A-Fa-f]{0,4}:){0,6}(?:[0-9A-Fa-f]{1,4}))(?:[0-9A-Fa-f]{1,4}:){0,6}(?:::[0-9A-Fa-f]{1,4})?)$/,

  kbNumber: /^KB[0-9]{6,}$/,

  pagerDutyIntegrationKey: /^[A-Za-z0-9]{32}$/,

  oid: /^[.]([0-2])((\.0)|(\.[1-9][0-9]*))*$/,

  decimalOrFraction: /^(\d+([/.]\d+)?)?$/,

  topicAction: /((\/ws\/\w+)|(mailto:\w+)|(tel:\w+)|(https:\/\/\w+)).+/,

  positiveInteger: /^[1-9]\d*$/,

  sentinelOneApiToken: /^[a-zA-Z0-9-_.]+$/,

  alphanumeric: /^[a-zA-Z0-9]+$/,

  windowsVolumeOrLabel: /^(([A-Za-z]:)[/|\\]{1}|[A-Za-z0-9 _-]{0,32})$/,
}

export const emailValidation = test(pattern.email)

export const phoneValidation = phone => isNilOrEmpty(phone) || isValidPhoneNumber(phone.replace(/ |-/g, ""))

export const oidValidation = test(pattern.oid)

export const required = message => value => {
  return value ? undefined : localized(message)
}

export const isValidUncPath = url => pattern.uncPath.test(url)

export const isValidDnsOrIp = url => pattern.dns.test(url) || pattern.ip.test(url)

//DEPRECATED (use isValidSecureURL instead)
export const isValidURL = url => pattern.url.test(url)

/*
  Current secureUrl pattern allow the input of invalid characteres in the URL, use 
  preventIllegalCharacters = true to prevent that
*/
export const isValidSecureURL = (url, preventIllegalCharacters = false) => {
  const { secureUrl, secureValidUrl } = pattern
  return (preventIllegalCharacters ? secureValidUrl : secureUrl).test(url)
}

export const isValidSecureIp = url => pattern.secureIp.test(url)

export const isValidTopicAction = topicAction => pattern.topicAction.test(topicAction)

export const isValidBrowserCall = url => pattern.browserCall.test(url)

export const isServerPath = serverPath => {
  return pattern.serverPath.test(serverPath)
}

export const isProcessType = processType => {
  return pattern.processType.test(processType)
}

export const isExtensionType = extensionType => {
  return pattern.extensionType.test(extensionType)
}

export const isNetworkPathwithWildCards = networkPathwithWildCards => {
  return pattern.networkPathwithWildCards.test(networkPathwithWildCards)
}

export const isVolumeOrfolderPathwithWildCards = volumeOrfolderPathwithWildCards => {
  return pattern.volumeOrfolderPathwithWildCards.test(volumeOrfolderPathwithWildCards)
}

export const isValidSubdomain = subdomain => pattern.subdomain.test(subdomain)

export const isValidDomain = domain => pattern.domain.test(domain)

export const hasPipe = test(pattern.pipe)

export const isValidWindowsPath = path => {
  const pathRegExp = new RegExp(pattern.windowsPath, "gi")
  return pathRegExp.test(path)
}

export const isValidMacPath = path => {
  const pathRegExp = new RegExp(pattern.macPath, "gi")
  return pathRegExp.test(path)
}

const isWindowsRoot = path => {
  const pathRegExp = new RegExp(pattern.windowsRoot, "gi")
  return pathRegExp.test(path)
}

export const isRootPath = path => {
  const formattedPath = path.replace(/\/+|\\+/g, "\\")
  return isWindowsRoot(formattedPath)
}

export const getRootPath = path => {
  const matchData = pattern.windowsRoot.exec(path)
  return matchData && defaultToEmpty(matchData[1])
}

export const isValidIpAddress = (ipAddress, type) => {
  switch (type) {
    case "IPV4":
      return pattern.ipv4Address.test(ipAddress)
    case "IPV6":
      return pattern.ipv6Address.test(ipAddress)
    default:
      return pattern.ipv4Address.test(ipAddress) || pattern.ipv6Address.test(ipAddress)
  }
}

export const isValidIpRange = ipAddress => pattern.ipv4Range.test(ipAddress)

export const isValidInteger = integer => {
  return !isNaN(integer) && Number.isInteger(integer)
}

export const isValidPositiveInteger = integer => {
  return !isNaN(integer) && Number.isInteger(integer) && Number(integer) >= 0
}

export const isIntegerWithinRange = (integer, min, max) => {
  return integer >= (min ?? MIN_INTEGER_VALUE) && integer <= (max ?? MAX_INTEGER_VALUE)
}

export const isDecimalWithinRange = (float, min, max) => {
  return float >= (min ?? MIN_FLOAT_VALUE) && float <= (max ?? MAX_FLOAT_VALUE)
}

export const isValidNumber = float => {
  return !isNaN(float)
}

export const isValidWildcard = ({ value, delimiter = "*", minLength = 3 }) => {
  if (!value.includes(delimiter)) return value.length >= minLength

  // +1 because delimiter doesn't count
  if (value.length < minLength + 1) return false

  const startsWith = value.startsWith(delimiter)
  const endsWith = value.endsWith(delimiter)
  return xor(startsWith, endsWith)
}

/*
  useForm hook formatted validations
*/
export const validations = {
  password(value) {
    let success = false
    if (value.length >= 8) {
      var hasUpperCase = /[A-Z]/.test(value)
      var hasLowerCase = /[a-z]/.test(value)
      var hasNumbers = /\d/.test(value)
      var hasSpecialCharacter = /[^\s\w]/.test(value)
      success = hasUpperCase + hasLowerCase + hasNumbers + hasSpecialCharacter >= 3
    }

    return {
      type: "password",
      success,
      message: success
        ? ""
        : localized(
            "Password must be between (8) and (72) characters and meet 3 of the following criteria: lower case letter, upper case letter, special character, number.",
          ),
    }
  },

  passwordConfirm(password, passwordConfirm) {
    const success = !!password.length && !!passwordConfirm.length && password === passwordConfirm

    return {
      success,
      message: success ? "" : localized("Passwords do not match"),
    }
  },

  email(value) {
    const success = EMAIL_REGEX.test(value)

    return {
      type: "email",
      success,
      message: success ? "" : localized("Invalid email address"),
    }
  },

  required(value) {
    let success
    switch (typeof value) {
      case "string":
        success = !!value.trim()
        break
      case "number":
        success = isNumber(value)
        break
      case "object":
        success = !!value && !isEmpty(value)
        break
      default:
        break
    }
    return { type: "required", success, message: success ? "" : localized("Required") }
  },

  phone(value) {
    const success = isValidPhoneNumber(value)
    return { type: "phone", success, message: success ? "" : localized("Invalid") }
  },

  phoneLastFour(value) {
    return { success: /^\d{4}$/.test(value) }
  },

  mfaCodeOnlySixDigits(value) {
    return { success: /^\d{6}$/.test(value) }
  },

  url(value) {
    const success = isValidSecureURL(value)
    return { type: "url", success, message: success ? "" : localized("Invalid") }
  },

  installApplicationUrlAutomation(url) {
    const success = isValidSecureURL(url, true)
    return { success, message: success ? "" : localized("Invalid") }
  },

  isValidDnsOrIpPath(path) {
    const success = isValidDnsOrIp(path)
    return { success, message: success ? "" : localized("Invalid") }
  },

  isValidUncPath(path) {
    const success = isValidUncPath(path)
    return { success, message: success ? "" : localized("Invalid") }
  },

  maxLength: curry((lengthLimit, stringValue) => {
    const success = defaultTo(0, stringValue?.length) <= lengthLimit
    return {
      success,
      message: success
        ? ""
        : localized("Field exceeds the maximum character count of {{maxCount}}.", { maxCount: lengthLimit }),
    }
  }),

  maxItems: curry((lengthLimit, items) => {
    const success = defaultTo(0, items.length) <= lengthLimit
    return {
      success,
      message: success ? "" : localized("No more than {{maxCount}} items can be added.", { maxCount: lengthLimit }),
    }
  }),

  pagerDutyIntegrationKey(key) {
    const success = pattern.pagerDutyIntegrationKey.test(key)
    return { success, message: success ? "" : localized("Invalid") }
  },

  oid(value) {
    const getMessage = value => {
      if (!value) {
        return localized("Required")
      } else if (!value.startsWith(".")) {
        return localized("OID must begin with a decimal")
      } else if (!pattern.oid.test(value)) {
        return localized("Invalid")
      } else {
        return ""
      }
    }

    const message = getMessage(value)

    return { success: !message, message }
  },

  decimalOrFraction(value) {
    const success = pattern.decimalOrFraction.test(value)
    return { success, message: success ? "" : localized("Must be a fraction or decimal") }
  },
  isValidIntegerWithinRange: curry((min, max, value) => {
    const success = isValidNumber(value) && isIntegerWithinRange(value, min, max)
    return { success, message: success ? "" : localized("Invalid") }
  }),
  isValidDecimalWithinRange: curry((min, max, value) => {
    const success = isValidNumber(value) && isDecimalWithinRange(value, min, max)
    return { success, message: success ? "" : localized("general.invalid") }
  }),
  positiveInteger(value) {
    const success = pattern.positiveInteger.test(value)
    return { success, message: success ? "" : localized("Invalid") }
  },
  sentinelOneApiToken(value) {
    const success = pattern.sentinelOneApiToken.test(value)
    return { success, message: success ? "" : localized("Invalid") }
  },
  alphanumeric(value) {
    const success = pattern.alphanumeric.test(value)
    return {
      success,
      message: success ? "" : localized("Invalid"),
    }
  },
}

export const getValidationSuccess = () => ({
  success: true,
  message: "",
})

export const getValidationError = (token, data) => ({
  success: false,
  message: token ? localized(token, data) : "",
})

export const withCustomError = (errorToken, validation) => (...params) => {
  const validate = validation(...params)
  return validate.success ? getValidationSuccess() : getValidationError(errorToken)
}

export const getMultiValueValidation = curry((validation, searchableKey, values) => {
  const validationResults = map(compose(validation, prop(searchableKey)), values)
  return find(propEq("success", false), validationResults) ?? getValidationSuccess()
})

export function isValidCIDRBlock(value) {
  return pattern.CIDRBlock.test(value)
}

export const applyMultipleValidations = curry((validationArray, value) => {
  for (const validation of validationArray) {
    const result = validation(value)
    if (!result.success) return result
  }
  return getValidationSuccess()
})

export function containsOneInteger(str) {
  return /[0-9]/.test(str)
}

export function containsOneLowercaseLetter(str) {
  return /[a-z]/.test(str)
}

export function containsOneUppercaseLetter(str) {
  return /[A-Z]/.test(str)
}

export function isValidKbNumber(str) {
  return pattern.kbNumber.test(str)
}

export function isValidMFACode(str) {
  return /^\d{6}$/.test(str)
}

export function isValidWindowsVolumeOrLabel(str) {
  return pattern.windowsVolumeOrLabel.test(str)
}
