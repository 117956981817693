const faDownloadComplete = {
  prefix: "fac",
  iconName: "download-completed",
  icon: [
    512,
    512,
    [],
    "0011",
    `M467.926,511.563L42.777,511.563C30.591,511.563 20.787,501.759 20.787,489.572L20.787,386.95C20.787,374.764 30.59,364.96 42.777,364.96L117.44,364.96L169.432,416.952C181.313,428.833 200.575,428.833 212.456,416.953L264.449,364.96L467.926,364.96C480.113,364.96 489.917,374.764 489.917,386.95L489.917,489.572C489.917,501.758 480.113,511.562 467.926,511.563ZM93.804,448.85C83.466,448.85 75.072,457.244 75.072,467.582C75.072,477.921 83.466,486.315 93.804,486.315L93.925,486.315C104.209,486.25 112.537,477.881 112.537,467.582C112.537,457.284 104.209,448.915 93.925,448.85L93.804,448.85ZM170.657,463.786C169.01,455.281 161.517,448.85 152.533,448.85C142.344,448.85 134.072,457.122 134.072,467.311C134.072,474.48 138.167,480.7 144.143,483.757C146.708,485.128 149.632,485.907 152.73,485.907C162.809,485.907 171.055,477.661 171.055,467.582C171.055,466.282 170.918,465.012 170.657,463.786ZM173.898,385.404L7.498,219.004C-2.499,209.007 -2.499,192.798 7.498,182.8L43.701,146.596C53.698,136.598 69.908,136.598 79.905,146.596L192,258.69L432.095,18.596C442.092,8.599 458.302,8.599 468.299,18.596L504.502,54.8C514.499,64.797 514.499,81.006 504.502,91.004L210.102,385.405C200.104,395.402 183.896,395.402 173.898,385.404Z`,
  ],
}

export default faDownloadComplete
