const faDownloadFailed = {
  prefix: "fac",
  iconName: "download-failed",
  icon: [
    512,
    512,
    [],
    "0022",
    `M467.926,511.563L42.777,511.563C30.591,511.563 20.787,501.759 20.787,489.572L20.787,386.95C20.787,374.764 30.59,364.96 42.777,364.96L47.487,364.96L108.32,425.793C118.128,435.601 133.988,435.601 143.796,425.793L204.63,364.96L310.29,364.96L372.574,427.243C382.382,437.051 398.242,437.051 408.05,427.243L470.216,365.076C481.318,366.214 489.917,375.537 489.917,386.95L489.917,489.572C489.917,501.758 480.113,511.562 467.926,511.563ZM169.739,474.382C170.611,472.245 171.091,469.908 171.091,467.459C171.091,457.32 162.86,449.089 152.722,449.089C142.583,449.089 134.352,457.32 134.352,467.459C134.352,471.092 135.41,474.481 137.233,477.333C140.488,482.475 146.227,485.907 152.73,485.907C160.409,485.907 167.025,481.12 169.739,474.382ZM96.647,485.728C105.882,484.765 113.091,476.947 113.091,467.459C113.091,457.32 104.86,449.089 94.722,449.089C92.924,449.089 91.187,449.348 89.544,449.83C81.645,451.865 75.763,459.07 75.763,467.582C75.763,477.661 84.009,485.907 94.088,485.907C94.956,485.907 95.81,485.846 96.647,485.728ZM449.744,82.063L333.144,198.664L449.744,315.264C458.555,324.074 458.555,338.321 449.744,347.132L407.378,389.498C398.567,398.308 384.32,398.308 375.51,389.498L258.91,272.898L142.31,389.498C133.499,398.308 119.252,398.308 110.441,389.498L68.076,347.132C59.265,338.321 59.265,324.074 68.076,315.264L184.676,198.664L68.076,82.063C59.265,73.253 59.265,59.006 68.076,50.195L110.441,7.829C119.252,-0.981 133.499,-0.981 142.31,7.829L258.91,124.429L375.51,7.829C384.32,-0.981 398.567,-0.981 407.378,7.829L449.744,50.195C458.554,59.006 458.555,73.252 449.744,82.063Z`,
  ],
}

export default faDownloadFailed
