import { Transforms } from "slate"
import { jsx } from "slate-hyperscript"

import { hasTicketingAdministrativeOnlyAccess, isNotEmpty } from "js/includes/common/utils"
import { deserializeToSlate, richTextEditorTagTypeMapper } from "js/includes/components/RichTextEditor/serializer"

import { AutomationLogEntryElement } from "./AutomationLogEntryElement"
import { MentionElement } from "./MentionElement"

const { P, H1, H2, LI, TD } = richTextEditorTagTypeMapper

const mentionKey = "mention"
const automationUpdateKey = "automation-update"

export const customElementRenderers = {
  [mentionKey]: MentionElement,
  [automationUpdateKey]: AutomationLogEntryElement,
}

export const customSerializeToHtml = {
  [mentionKey]: node => `<span class="technician-tagged">@user:${node.mention.id}</span>`,
}

const createSlateValueFromAutomation = ({ html, automation }) => {
  const indexOfFirstQuote = html.indexOf("'")
  const indexOfSecondQuote = html.lastIndexOf("'")

  if (indexOfSecondQuote > indexOfFirstQuote) {
    const contentBeforeFirstQuote = html.substring(0, indexOfFirstQuote)
    const automationNameInHTML = html.substring(indexOfFirstQuote + 1, indexOfSecondQuote)
    const contentAfterLastQuote = html.substring(indexOfSecondQuote + 1, html.length)
    const paragraphChildren = [
      ...(isNotEmpty(contentBeforeFirstQuote) ? [jsx("text", contentBeforeFirstQuote)] : []),
      jsx("element", { type: automationUpdateKey, automation }, [jsx("text", automationNameInHTML)]),
      ...(isNotEmpty(contentAfterLastQuote) ? [jsx("text", contentAfterLastQuote)] : []),
    ]
    return [jsx("element", { type: "paragraph" }, paragraphChildren)]
  }

  return null
}

export const deserializeLogEntryToSlate = ({ html, automation, ...rest }) => {
  if (!html) {
    return null
  }

  if (automation?.system === false && hasTicketingAdministrativeOnlyAccess()) {
    const slateValue = createSlateValueFromAutomation({ html, automation })
    if (slateValue) {
      return slateValue
    }
  }
  return deserializeToSlate({
    html,
    ...rest,
  })
}

function genLink(link) {
  return {
    type: "link",
    url: "/#/ticketing/ticket/" + link.replace("#", ""),
    children: [{ text: link }],
  }
}

function genTextNode(text) {
  return {
    text,
  }
}

function splitTextByLinksAndCreateNodes(text, links, list = []) {
  if (!text.length && !links?.length) return list

  if (text && !links?.length) {
    list.push(genTextNode(text))
    return list
  }

  const [link, ...restOfLinks] = links
  const [before, ...after] = text.split(link)
  const remainingText = after.length > 1 ? after.join(link) : after[0]

  if (!before.length && !remainingText.length) {
    list.push(genLink(link))
    return list
  }

  before.length && list.push(genTextNode(before))
  list.push(genLink(link))
  after.length && splitTextByLinksAndCreateNodes(remainingText, restOfLinks, list)

  return list
}

function parseHashTagsToTicketIds(type) {
  return function(element, children) {
    const align = element.style.textAlign
    const possibleHashtag = element.textContent.includes("#")

    if (possibleHashtag) {
      return jsx(
        "element",
        { type, align },
        children.map(child => {
          if (child?.text && /#[1-9][0-9]{3,}/.test(child.text)) {
            const linkMatches = child.text.match(/#\d{4,}/g)
            return splitTextByLinksAndCreateNodes(child.text, linkMatches)
          } else {
            return child
          }
        }),
      )
    }

    return jsx("element", { type, align }, children)
  }
}

export const customDeserializeToHtml = {
  P: parseHashTagsToTicketIds(P),
  H1: parseHashTagsToTicketIds(H1),
  H2: parseHashTagsToTicketIds(H2),
  LI: parseHashTagsToTicketIds(LI),
  TD: parseHashTagsToTicketIds(TD),
  SPAN: (element, children, additionalConfigs) => {
    if (element.classList.contains("technician-tagged")) {
      const mention = additionalConfigs?.techniciansTaggedMetadata?.find(
        technicianTagged => technicianTagged.id.toString() === element.innerText.split(":")[1],
      )
      return jsx("element", { type: mentionKey, mention }, children)
    }

    return children
  },
}

export const insertMention = (editor, mention) => {
  Transforms.insertNodes(editor, {
    mention,
    type: mentionKey,
    children: [{ text: mention.displayName }],
  })
  Transforms.move(editor)
}

export function isHtmlWrappedInElement(html) {
  const div = document.createElement("div")

  div.innerHTML = html.trim()

  const firstChild = div.firstChild
  const lastChild = div.lastChild

  if (!firstChild || !lastChild) return false
  if (firstChild === lastChild) return firstChild.nodeType === Node.ELEMENT_NODE

  return (
    firstChild.nextSibling === lastChild &&
    firstChild.nodeType === Node.ELEMENT_NODE &&
    lastChild.nodeType === Node.ELEMENT_NODE
  )
}

export function wrapHtmlInParagraphIfNotWrapped(html) {
  return isHtmlWrappedInElement(html) ? html : `<p>${html}</p>`
}

export function cleanHtml(html) {
  return html
    .replace(/\n/g, "")
    .replace(/>(\s|\\n)+</g, "><")
    .replace(/<div><\/div>/g, "")
}

export function removeAllEmptyDiv(html) {
  return html.replace(/<div><\/div>/g, "")
}
