import React, { PureComponent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { map, find } from "ramda"
import styled from "@emotion/styled"
import { localized } from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"

const StyledNavLink = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  &.active {
    color: ${colors.ninjaBlue};
    font-weight: 700;
    svg path {
      fill: ${colors.ninjaBlue};
    }
  }
  &:hover {
    cursor: pointer;
  }
`

const Tab = ({ tab, onChangeRoute, active }) => (
  <StyledNavLink
    className={active}
    onClick={() => {
      onChangeRoute(tab.route)
      tab.action?.()
    }}
  >
    {tab.labelRenderer?.(active)}

    {tab.icon && !tab.labelRenderer && <FontAwesomeIcon icon={tab.icon} fixedWidth />}
    {!tab.labelRenderer && <span className="m-l">{localized(tab.label)}</span>}
  </StyledNavLink>
)

export default class EditorContent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      route: "",
    }
    this.initRoute = this.initRoute.bind(this)
    this.onChangeRoute = this.onChangeRoute.bind(this)
    this.renderActiveTab = this.renderActiveTab.bind(this)
  }

  componentDidMount() {
    this.initRoute()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedTab && this.props.selectedTab !== prevProps.selectedTab) {
      this.onChangeRoute(this.props.selectedTab)
    }
  }

  initRoute() {
    const { tabs } = this.props
    this.setState({ route: tabs[0].route })
  }

  onChangeRoute(route) {
    this.setState({ route })
  }

  renderActiveTab() {
    const { tabs } = this.props
    const { route } = this.state

    if (!route) {
      return null
    }

    const activeTab = find(tab => route === tab.route, tabs)
    const { Component } = activeTab

    return (
      <div className="display-flex flex-full flex-column not-scrollable">
        <Component />
      </div>
    )
  }

  render() {
    const { tabs } = this.props
    const { route } = this.state
    return (
      <div className="editor-content">
        <aside>
          <nav id="sidebar" className="editor-sidebar">
            <ul className="nav">
              {map(tab => {
                const active = route === tab.route ? "active" : ""
                return (
                  <Tab
                    {...{
                      onChangeRoute: this.onChangeRoute,
                      key: tab.route,
                      active,
                      tab,
                    }}
                  />
                )
              }, tabs)}
            </ul>
          </nav>
        </aside>
        <div className="content-container">{this.renderActiveTab()}</div>
      </div>
    )
  }
}
