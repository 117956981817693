import { vmGuestDataspecs } from "js/state/actions/virtualization/dataspecs"
import {
  fetchDeviceStatus,
  actionCall,
  fetchNodeDash,
  clearDevicePolicyOverrides,
  updateDevicePolicy,
  fetchNodeDataspecs,
} from "js/state/actions/virtualization/common"
import { ninjaReportError, showErrorMessage, localized } from "js/includes/common/utils"

export const fetchGuestDashboardData = deviceId => async dispatch => {
  fetchNodeDash(deviceId, vmGuestDataspecs, dispatch, "INIT_GUEST_NODE_DATA", "INIT_GUEST_DASHBOARD_DATASETS")
}

export const fetchVMGuestDataspecs = deviceId => async dispatch => {
  fetchNodeDataspecs(deviceId, vmGuestDataspecs, dispatch, "INIT_GUEST_DASHBOARD_DATASETS")
}

export const fetchGuestDeviceStatus = deviceId => async dispatch => {
  try {
    await fetchDeviceStatus(deviceId, dispatch, "SET_GUEST_NODE_STATUS")
  } catch (error) {
    throw error
  }
}

//This method can be used to run any of the guest device actions [ start, stop, suspend, reset ... ]
export const runGuestAction = (deviceId, actionId) => async dispatch => {
  try {
    await actionCall(deviceId, actionId)
    fetchDeviceStatus(deviceId, dispatch, "SET_GUEST_NODE_STATUS")
  } catch (error) {
    showErrorMessage(localized("Error creating/updating jobs"))
    ninjaReportError(error)
  }
}

export const clearGuestPolicyOverrides = (deviceId, node) => async dispatch => {
  clearDevicePolicyOverrides(deviceId, node, dispatch, "INIT_GUEST_NODE_DATA")
}

export const updateGuestPolicy = (deviceId, node, policyId, revertToOrganizationPolicy) => async dispatch => {
  updateDevicePolicy(deviceId, node, policyId, revertToOrganizationPolicy, dispatch, "INIT_GUEST_NODE_DATA")
}

export const updateGuestOwner = owner => dispatch =>
  dispatch({
    type: "UPDATE_GUEST_NODE_OWNER",
    owner,
  })

export const setVmGuestItamTags = tags => dispatch =>
  dispatch({
    type: "SET_VM_GUEST_ITAM_TAGS",
    tags,
  })
