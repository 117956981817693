import styled from "@emotion/styled"
import { RegularInfoCircleIconLight } from "@ninjaone/icons"
import { localizationKey } from "js/includes/common/utils"

export const updateCadenceOptions = [
  {
    value: "ALL",
    labelToken: localizationKey("All available updates"),
  },
  {
    value: "NEWEST",
    labelToken: localizationKey("Highest available update"),
  },
  {
    value: "OLDEST",
    labelToken: localizationKey("Lowest available update"),
  },
]

export const betaEnrollmentOptions = [
  {
    value: "ALLOWED",
    labelToken: localizationKey("Allowed"),
  },
  {
    value: "ALWAYS_ON",
    labelToken: localizationKey("Always on"),
  },
  {
    value: "ALWAYS_OFF",
    labelToken: localizationKey("Always off"),
  },
]

export const StyledInfoIcon = styled(RegularInfoCircleIconLight)`
  color: ${({ theme }) => theme.colorTextWeakest};
  margin-top: 2px;
`

export const additionalScheduledUpdatesToken = localizationKey(
  "If enabled, users will receive daily notifications for updates scheduled in the future. If disabled, users will only receive notifications beginning one hour before the scheduled deadline.",
)

export const defaultInheritance = {
  inheritance: {
    inherited: false,
    overridden: false,
    sourcePolicyId: null,
  },
}

export const osTabGridColumns = `2fr 0fr 7fr 1fr 150px`
