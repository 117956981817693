export const conditionControlCode = {
  SelectCondition: 0,
  CPU: 1,
  Memory: 2,
  DiskUsage: 3,
  NetworkUtilization: 4,
  Process: 5,
  WindowsService: 6,
  ProcessResources: 7, //This is only for UI
  ProcessResourcesCPU: 8, //This is only for JSON
  ProcessResourcesMemory: 9, //This is only for JSON
  SystemReboot: 10,
  DiskFreeSpace: 11,
  EventLogCondition: 12,
  CriticalEventsCondition: 13,
  DiskVolumeIO: 14, //This is only for JSON
  DiskActiveTime: 15, //This is only for UI
  DiskTransferRate: 16, //This is only for UI
  DeviceDown: 17,
  MacProcess: 18,
  MacDaemon: 19,
  MacProcessResources: 20, //This is only for UI
  MacProcessResourcesCPU: 21, //This is only for JSON
  MacProcessResourcesMemory: 22, //This is only for JSON
  WindowsSMARTStatusDegraded: 23,
  Software: 24,
  MacDiskFreeSpace: 25,
  MacDiskUsage: 26,
  RaidHealthStatus: 27,
  ScriptResultCondition: 28,
  CPUTemperature: 29,
  GPUTemperature: 30,
  BitlockerStatus: 31,
  FileVaultStatus: 32,
  LinuxProcess: 33,
  LinuxDaemon: 34,
  LinuxProcessResources: 35, //This is only for UI
  LinuxProcessResourcesCPU: 36, //This is only for JSON
  LinuxProcessResourcesMemory: 37, //This is only for JSON
  LinuxDiskFreeSpace: 38,
  LinuxDiskUsage: 39,
  CustomFields: 40,
  PatchLastInstalled: 41,
  BatteryMonitoring: 42,
  AVHealth: 43,
  RebootPending: 44,
  PatchCVSSScore: 45,
  BackupJobDurationAlert: 46,
  BackupJobLastSuccess: 47,
}

export const defaultConditionUids = {
  CPU: "61147e9c-c5bd-4f3a-ac98-b5ecfc9ab579",
  Memory: "223bbcc1-83d4-48a0-8ae5-546573b61fea",
  DiskUsage: "ccb1d38c-3ae0-45f7-b98c-6baacb24225e",
  DiskVolumeIO: "451545dc-2c26-40f4-a666-3af6b43d3154",
  NetworkUtilization: "80f60ef4-4918-4d1a-80ef-64a3b5bc263b",
  Process: "69a87e2e-9115-4a83-bad2-3d10cbd03dee",
  WindowsService: "347443f1-c019-423c-8ab0-2f8ece3908d6",
  ProcessResourcesCPU: "30884d1c-78b7-44bd-a14c-8defd354247c",
  ProcessResourcesMemory: "ca16b363-8ffa-46a5-b228-c5a5532aa7d7",
  SystemReboot: "7f19f7b2-b2ff-42fb-8a8a-7e0c1fb67ff1",
  DiskFreeSpace: "1045af6c-c6b8-4049-bbf4-5a92e422ca6d",
  EventLogCondition: "b979707b-b370-40bc-b3bf-d498953ed8a2",
  CriticalEventsCondition: "62952506-1004-4edb-a358-8339e8efa672",
  DeviceDown: "25e58713-df15-4c52-b79d-2988986d5cd0",
  MacProcess: "9b62b476-38d0-45d5-8572-55a31091b392",
  MacDaemon: "13025ce9-4fc4-4fb7-a1c3-bfd0aac19068",
  MacProcessResourcesCPU: "76d7f827-9643-4a99-9e21-f5009c577e93",
  MacProcessResourcesMemory: "ea1676b4-2715-4c30-99fb-bbd1ce177b51",
  WindowsSMARTStatusDegraded: "0ffc1334-2ced-45f5-bec2-4ddba88a5ff6",
  MacDiskFreeSpace: "MAC1045af6c-c6b8-4049-bbf4-5a92e422ca6d",
  MacDiskUsage: "MACccb1d38c-3ae0-45f7-b98c-6baacb24225e",
  Software: "e3745938-a9c2-472c-a5a5-afa229624120",
  RaidHealthStatus: "d6341a13-d81f-4b15-8949-7b3a9b1379bb",
  ScriptResultCondition: "6bdd6bc5-64fc-4058-9aeb-46ebd1efbf3d",
  CPUTemperature: "6B49E445-3FC1-497D-8F10-E51BCE44E28A",
  GPUTemperature: "31F5760C-5BC6-4A43-899D-CA95DA5C5E70",
  BitlockerStatus: "31cd4994-62b0-4b22-89ab-6ce126e9afe1",
  FileVaultStatus: "e1994b02-886b-466f-9f42-180809c66b63",
  LinuxProcess: "44ee32f7-403a-4583-a821-6ab0c9453cdd",
  LinuxDaemon: "43149a01-8f9f-4ffe-a652-12328002ef15",
  LinuxProcessResources: "fd457faa-09dc-4e7b-8551-82f40e3c9051",
  LinuxProcessResourcesCPU: "65ecba5d-ff7b-4ccc-b810-4e32391b7c7d",
  LinuxProcessResourcesMemory: "1c535cc5-1230-4858-9da4-42e34e3a6962",
  LinuxDiskFreeSpace: "273994ef-3f44-4be5-b21d-239d0f3cd889",
  LinuxDiskUsage: "c4bd0a28-7b1e-4260-8bfc-c22f231923a0",
  CustomFields: "36bcfc38-519d-4efb-9995-35cf4848f268",
  PatchLastInstalled: "2bf6e786-ac1b-441b-9bc1-867a0288107f",
  PatchCVSSScore: "a4f64d2f-6cb6-4ad0-b101-e7be7e496b59",
  BatteryMonitoring: "6a626396-d869-43d9-b813-e21422f6bbea",
  AVHealth: "e8e937ed-1c11-454e-9ba8-e6a52174abd4",
  RebootPending: "0ef5238c-0bb5-4bb9-9656-fbc467c70d47",
  CompoundCondition: "2633d2a1-49c5-4a82-bfce-30d626bf9bb3",
  BackupJobDurationAlert: "5b363fc9-e7db-4d75-b6fe-6fde53456691",
  BackupJobLastSuccess: "abf80fca-7ef7-4a68-8986-93409c7c0409",
}

let defaultConditionUidsReverse = {}
let defaultConditionNames = {}
let defaultConditionUidsByCode = {}

for (var key in defaultConditionUids) {
  if (defaultConditionUids.hasOwnProperty(key)) {
    defaultConditionUidsReverse[defaultConditionUids[key]] = key
    defaultConditionNames[conditionControlCode[key]] = key
    defaultConditionUidsByCode[conditionControlCode[key]] = defaultConditionUids[key]
  }
}

export { defaultConditionUidsReverse, defaultConditionNames, defaultConditionUidsByCode }
