import {
  any,
  compose,
  when,
  equals,
  type,
  call,
  curry,
  transduce as ramdaTransduce,
  flip,
  append,
  complement,
  isNil,
  includes,
  eqProps,
  memoizeWith,
  toString,
  isEmpty,
} from "ramda"

export const callWhenFunction = when(compose(equals("Function"), type), call)

export const getFirstWithValue = curry((arrayOfFunctions, data) => {
  for (const func of arrayOfFunctions) {
    const ret = func(data)
    if (ret) return ret
  }
  return undefined
})

export const transduce = curry((logic, data) => ramdaTransduce(logic, flip(append), [], data))

export const noop = () => {}

export const isNotNil = complement(isNil)

export const isNotEmpty = complement(isEmpty)

export const contains = flip(includes)

export const notEqProps = complement(eqProps)

export const memoize = memoizeWith(toString)

export const containsAny = curry((containsList, data) => any(contains(data), containsList))
