import { conditionControlCode } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"
import { localizedF, localized, localizationKey } from "js/includes/common/utils"
import { forEach } from "ramda"

const hostConditionOptions = {
  nullValue: conditionControlCode.SelectCondition,
  requiredMessage: localizedF("Condition is required"),
  value: conditionControlCode.SelectCondition,
  items: [
    {
      value: conditionControlCode.CPU,
      text: localizedF("Aggregate CPU Usage"),
    },
    {
      value: conditionControlCode.DiskUsage,
      text: localizedF("Disk Usage"),
    },
    {
      value: conditionControlCode.DataStoreFreeSpace,
      text: localizedF("Datastore Free Space"),
    },
    {
      value: conditionControlCode.VMHostUptime,
      text: localizedF("VM Host Uptime"),
    },
    {
      value: conditionControlCode.DeviceDown,
      text: localizedF("Device Down"),
    },
  ],
}

export const vmWareHostConditionOptions = {
  ...hostConditionOptions,
  items: [
    ...hostConditionOptions.items,
    {
      value: conditionControlCode.Memory,
      text: localizedF("Memory Usage"),
    },
    {
      value: conditionControlCode.BadSensorCount,
      text: localizedF("Bad Sensor Count"),
    },
    {
      value: conditionControlCode.SensorHealth,
      text: localizedF("Sensor Health"),
    },
  ],
}

export const hyperVHostConditionOptions = hostConditionOptions

const vmConditionOptions = {
  nullValue: conditionControlCode.SelectCondition,
  requiredMessage: localizedF("Condition is required"),
  value: conditionControlCode.SelectCondition,
  items: [
    {
      value: conditionControlCode.ProcessorUsage,
      text: localizedF("Processor Usage"),
    },
    {
      value: conditionControlCode.GuestOperationMode,
      text: localizedF("Guest Operation Mode"),
    },
    {
      value: conditionControlCode.GuestToolsNotRunning,
      text: localizedF("Guest Tools not running"),
    },
  ],
}

export const vmVMWareConditionOptions = {
  ...vmConditionOptions,
  items: [
    ...vmConditionOptions.items,
    {
      value: conditionControlCode.Memory,
      text: localizedF("Memory"),
    },
    {
      value: conditionControlCode.SnapshotSize,
      text: localizedF("Snapshot Size"),
    },
    {
      value: conditionControlCode.SnapshotLifeSpan,
      text: localizedF("Snapshot Lifespan"),
    },
  ],
}

export const vmHyperVConditionOptions = {
  ...vmConditionOptions,
  items: [
    ...vmConditionOptions.items,
    {
      value: conditionControlCode.CheckpointSize,
      text: localizedF("Checkpoint Size"),
    },
    {
      value: conditionControlCode.CheckpointLifeSpan,
      text: localizedF("Checkpoint Lifespan"),
    },
  ],
}

export const operatorOptions = {
  value: "GE",
  items: [
    { value: "GE", text: localizedF("greater than or equal to") },
    { value: "LE", text: localizedF("less than or equal to") },
    { value: "LT", text: localizedF("less than") },
    { value: "GT", text: localizedF("greater than") },
    { value: "EQ", text: localizedF("equal to") },
    { value: "NE", text: localizedF("not equal to") },
  ],
}

export const operatorOptionsKeys = {
  GE: localizedF("greater than or equal to"),
  LE: localizedF("less than or equal to"),
  LT: localizedF("less than"),
  GT: localizedF("greater than"),
  EQ: localizedF("equal to"),
  NE: localizedF("not equal to"),
}

export const durationOptions = {
  value: 5,
  items: [
    { value: 5, text: () => "5 " + localized("Minutes").toLowerCase() },
    { value: 15, text: () => "15 " + localized("Minutes").toLowerCase() },
    { value: 30, text: () => "30 " + localized("Minutes").toLowerCase() },
    { value: 60, text: () => "60 " + localized("Minutes").toLowerCase() },
  ],
}

export const batteryStatusOptions = {
  value: 1,
  items: [
    { value: 0, text: () => localized("Bad") },
    { value: 1, text: () => localized("Good") },
  ],
}

export const severityOptions = {
  value: null,
  items: [
    { value: "CRITICAL", text: localizedF("Critical") },
    { value: "MAJOR", text: localizedF("Major") },
    { value: "MODERATE", text: localizedF("Moderate") },
    { value: "MINOR", text: localizedF("Minor") },
    { value: null, text: localizedF("None") },
  ],
}

export const priorityOptions = {
  value: null,
  items: [
    { value: "HIGH", text: localizedF("High") },
    { value: "MEDIUM", text: localizedF("Medium") },
    { value: "LOW", text: localizedF("Low") },
    { value: null, text: localizedF("None") },
  ],
}

export const unitOptions = {
  value: "PERCENT",
  items: [
    { value: "PERCENT", text: "%" },
    { value: "BYTES", text: localizedF("Byte") },
  ],
}

export const unitOptionsDWM = {
  value: "DAY",
  items: [
    { value: "DAY", text: localizedF("Days") },
    { value: "WEEK", text: localizedF("Weeks") },
    { value: "MONTH", text: localizedF("Months") },
  ],
}

export const unitBytesOptions = {
  value: "GB",
  items: [
    { value: "KB", text: "Kilo" },
    { value: "MB", text: "Mega" },
    { value: "GB", text: "Giga" },
    { value: "TB", text: "Tera" },
  ],
}

export const unitBpsOptions = {
  value: "GB",
  items: [
    { value: "KB", text: "KiBps" },
    { value: "MB", text: "MiBps" },
    { value: "GB", text: "GiBps" },
    { value: "TB", text: "TiBps" },
  ],
}

export const unitBpsOptionsKeys = {
  KB: "KiBps",
  MB: "MiBps",
  GB: "GiBps",
  TB: "TiBps",
}

export const byteOptions = {
  value: "OUT_BYTES",
  items: [
    { value: "IN_BYTES", text: localizedF("In Bytes") },
    { value: "OUT_BYTES", text: localizedF("Out Bytes") },
    { value: "IO_BYTES", text: localizedF("In & Out Bytes") },
  ],
}

export const byteOptionsKeys = {
  IN_BYTES: localizedF("In Bytes"),
  OUT_BYTES: localizedF("Out Bytes"),
  IO_BYTES: localizedF("In & Out Bytes"),
}

export const rWOptions = {
  value: "OUT_BYTES",
  items: [
    { value: "IN_BYTES", text: localizedF("Read speed") },
    { value: "OUT_BYTES", text: localizedF("Write speed") },
    { value: "IO_BYTES", text: localizedF("Read & Write speed") },
  ],
}

export const rwOptionsKeys = {
  IN_BYTES: localizedF("Read speed"),
  OUT_BYTES: localizedF("Write speed"),
  IO_BYTES: localizedF("Read & Write speed"),
}

export const unitBytesOptionsNapshotShize = {
  value: "GB",
  items: [
    { value: "MB", text: "Mega" },
    { value: "GB", text: "Giga" },
  ],
}

export const containOptions = {
  value: "CONTAINS",
  items: [
    { value: "CONTAINS", text: localizedF("Contains") },
    { value: "DOES_NOT_CONTAIN", text: localizedF("Doesn't contain") },
  ],
}

export const includeOptions = {
  value: "ALL",
  items: [
    { value: "ALL", text: localizedF("All") },
    { value: "SOME", text: localizedF("Any") },
  ],
}

export const windowsEventContainOptions = {
  value: "do",
  items: [
    { value: "do", text: localizedF("editors.policy.conditions.Contains") },
    { value: "dont", text: localizedF("editors.policy.conditions.DoesntContain") },
  ],
}

export const windowsEventIncludeOptions = {
  value: "all",
  items: [
    { value: "all", text: localizedF("general.all") },
    { value: "some", text: localizedF("general.Any") },
  ],
}

export const thresholdOptions = {
  value: 1,
  items: [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
    { value: 3, text: "3" },
    { value: 4, text: "4" },
    { value: 5, text: "5" },
    { value: 6, text: "6" },
    { value: 7, text: "7" },
    { value: 8, text: "8" },
    { value: 9, text: "9" },
    { value: 10, text: "10" },
    { value: 11, text: "11" },
    { value: 12, text: "12" },
    { value: 13, text: "13" },
    { value: 14, text: "14" },
    { value: 15, text: "15" },
    { value: 16, text: "16" },
    { value: 17, text: "17" },
    { value: 18, text: "18" },
    { value: 19, text: "19" },
    { value: 20, text: "20" },
    { value: 21, text: "21" },
    { value: 22, text: "22" },
    { value: 23, text: "23" },
    { value: 24, text: "24" },
    { value: 25, text: "25" },
  ],
}

export const thresholdBytesOptions = {
  value: 1,
  items: [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
    { value: 4, text: "4" },
    { value: 8, text: "8" },
    { value: 16, text: "16" },
    { value: 32, text: "32" },
    { value: 64, text: "64" },
    { value: 128, text: "128" },
    { value: 256, text: "256" },
    { value: 512, text: "512" },
  ],
}

export const daysOptions = {
  value: 1,
  items: [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
    { value: 3, text: "3" },
    { value: 4, text: "4" },
    { value: 5, text: "5" },
    { value: 6, text: "6" },
    { value: 7, text: "7" },
    { value: 8, text: "8" },
    { value: 9, text: "9" },
    { value: 10, text: "10" },
    { value: 11, text: "11" },
    { value: 12, text: "12" },
    { value: 13, text: "13" },
    { value: 14, text: "14" },
    { value: 15, text: "15" },
    { value: 16, text: "16" },
    { value: 17, text: "17" },
    { value: 18, text: "18" },
    { value: 19, text: "19" },
    { value: 20, text: "20" },
    { value: 21, text: "21" },
    { value: 22, text: "22" },
    { value: 23, text: "23" },
    { value: 24, text: "24" },
    { value: 25, text: "25" },
    { value: 26, text: "26" },
    { value: 27, text: "27" },
    { value: 28, text: "28" },
    { value: 29, text: "29" },
    { value: 30, text: "30" },
    { value: 31, text: "31" },
  ],
}

export const uptimeDaysOptions = {
  value: 30,
  items: [
    { value: 1, text: "1" },
    { value: 7, text: "7" },
    { value: 15, text: "15" },
    { value: 30, text: "30" },
    { value: 60, text: "60" },
    { value: 90, text: "90" },
    { value: 120, text: "120" },
  ],
}

export const softwarePresenceOptions = {
  value: "exclude",
  items: [
    { value: "include", text: localizedF("editors.policy.conditions.exists") },
    { value: "exclude", text: localizedF("editors.policy.conditions.doesntExist") },
  ],
}

export const daemonStateOptions = {
  value: "STOPPED",
  items: [
    { value: "EXIST", text: localizedF("Exists") },
    { value: "NOTEXIST", text: localizedF("Doesn't Exist") },
    { value: "STARTED", text: localizedF("Up") },
    { value: "STOPPED", text: localizedF("Down") },
  ],
}

export const raidHealthStatusOptions = {
  value: "DEGRADED",
  items: [
    { value: "IGNORE", text: localizedF("editors.policy.conditions.ignore") },
    { value: "DEGRADED", text: localizedF("editors.policy.conditions.criticalOnly") },
    { value: "NOT_OK", text: localizedF("editors.policy.conditions.criticalAndNot") },
  ],
}

export const everyUnitPicker = {
  value: "MINUTE",
  items: [
    { value: "MINUTE", text: localizedF("Minutes") },
    { value: "HOUR", text: localizedF("Hours") },
    { value: "DAY", text: localizedF("Days") },
  ],
}

export const unitOptionsNmsUptime = {
  value: "DAY",
  items: [
    { value: "HOUR", text: localizedF("Hours") },
    { value: "DAY", text: localizedF("Days") },
    { value: "WEEK", text: localizedF("Weeks") },
    { value: "MONTH", text: localizedF("Months") },
  ],
}

export const rebootPendingEveryUnitPicker = {
  value: "Minutes",
  items: [
    { value: "Minutes", text: localizedF("Minutes") },
    { value: "Hours", text: localizedF("Hours") },
    { value: "Days", text: localizedF("Days") },
  ],
}

export const resourceOptions = {
  value: "CPU",
  items: [
    { value: "CPU", text: localizedF("general.cpu") },
    { value: "MEMORY", text: localizedF("general.memory") },
  ],
}

export const minuteHourUnitPicker = {
  value: "MINUTE",
  items: [
    { value: "MINUTE", text: localizedF("Minutes") },
    { value: "HOUR", text: localizedF("Hours") },
  ],
}

export const minuteHourUnitPickers = [
  { value: "MINUTE", labelToken: localizationKey("minutes") },
  { value: "HOUR", labelToken: localizationKey("hours") },
]

export const processStateMapper = {
  EXIST: localizationKey("exists"),
  NOTEXIST: localizationKey("doesn't exist"),
  STARTED: localizationKey("up"),
  STOPPED: localizationKey("down"),
}

export const minuteHourUnitPickerOptionsKeys = {
  MINUTE: localizedF("minutes"),
  HOUR: localizedF("hours"),
}

export const nameOptions = [
  { value: "FAN", label: localizedF("Fan") },
  { value: "BATTERY", label: localizedF("Battery") },
  { value: "CHASSIS", label: localizedF("Chassis") },
  { value: "STORAGE", label: localizedF("Storage") },
  { value: "TEMPERATURE", label: localizedF("Temperature") },
  { value: "POWER", label: localizedF("Power") },
  { value: "WATCHDOG", label: localizedF("Watchdog") },
  { value: "VOLTAGE", label: localizedF("Voltage") },
  { value: "SYSTEM", label: localizedF("System") },
  { value: "SYSTEMBOARD", label: localizedF("System Board") },
  { value: "PROCESSORS", label: localizedF("Processors") },
  { value: "PROCESSOR", label: localizedF("Processor") },
  { value: "MEMORY", label: localizedF("Memory") },
  { value: "MANAGEMENT_SUBSYSTEM_HEALTH", label: localizedF("Management Subsystem Health") },
  { value: "CABLE_INTERCONNECT", label: localizedF("Cable Interconnect") },
  { value: "OTHER", label: localizedF("Other") },
]

export const stateOptions = [
  { value: "red", label: localizedF("Red") },
  { value: "yellow", label: localizedF("Yellow") },
  { value: "green", label: localizedF("Green") },
  { value: "unknown", label: localizedF("Unknown") },
]

export const modeOptions = [
  { value: "ON", label: localizedF("ON") },
  { value: "OFF", label: localizedF("OFF") },
  { value: "SUSPENDED", label: localizedF("Suspended") },
]

export const patchTypeOptions = {
  value: "OS",
  items: [
    { value: "OS", text: localizedF("Operating System") },
    { value: "3PP", text: localizedF("3rd Party Software") },
  ],
}

let nameKeysToObject = {}
let stateKeysToObject = {}
let modeKeysToObject = {}

forEach(option => {
  nameKeysToObject[option.value] = option
}, nameOptions)

forEach(option => {
  stateKeysToObject[option.value] = option
}, stateOptions)

forEach(option => {
  modeKeysToObject[option.value] = option
}, modeOptions)

export { nameKeysToObject, stateKeysToObject, modeKeysToObject }
