import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import fastDeepEqual from "fast-deep-equal"
import { keys, omit } from "ramda"

import { isNewTicket } from "js/includes/application/SideNavBar/common"
import { createRecentTabItem, deleteRecentTab } from "js/includes/common/client"
import { localizationKey, localized, ninjaReportError, reportErrorAndShowMessage } from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { unassignedTicketsBoardUrl } from "js/includes/configuration/integrations/ticketing/utils"

export const setRecentTabs = (tabs = []) => ({
  type: "SET_RECENT_TABS",
  tabs,
})

export const addRecentTab = tab => ({
  type: "ADD_RECENT_TAB",
  tab: {
    type: tab.type,
    item: {
      id: tab.item.id,
      nodeClass: tab.item.nodeClass,
      name: tab.item.displayName || tab.item.name,
      ...tab.item,
    },
  },
})

export const addDocumentToRecentsTab = tab => async (dispatch, getState) => {
  if (!tab.item) return

  const {
    general: { recentTabs },
  } = getState()

  const mostRecentTab = recentTabs?.[0]
  const isMostRecentTab = mostRecentTab?.type === tab.type && mostRecentTab?.id === tab.item.id

  if (isMostRecentTab) {
    return
  }

  try {
    await createRecentTabItem({ entityId: tab.item.id, type: tab.type })
    dispatch(addRecentTab(tab))
  } catch (err) {
    reportErrorAndShowMessage(err)
  }
}

export const addTicketToRecentsTab = (ticket, isNew = false, usesNameFromRecents) => async (dispatch, getState) => {
  if (ticket.deleted) {
    return
  }
  const {
    ticketing: {
      editor: { newTickets },
    },
    general: { recentTabs },
  } = getState()
  const count = keys(newTickets).length
  const isTicketMostRecentTab = recentTabs?.[0]?.type === "TICKET" && recentTabs?.[0]?.id === ticket.id

  if (!isTicketMostRecentTab) {
    const newTicketName = `${localized("New Ticket")} (${count})`
    const existingTicketName = `#${ticket.id}`
    const recentsTicketName = usesNameFromRecents ? recentTabs.find(({ id }) => id === ticket.id)?.name : ""

    dispatch({
      type: "ADD_RECENT_TAB",
      tab: {
        type: "TICKET",
        item: {
          id: ticket.id,
          name: recentsTicketName ? recentsTicketName : isNew ? newTicketName : existingTicketName,
        },
      },
    })
  }
}

export const updateTicketFromRecentTab = ticket => dispatch => {
  const { id, newTicketId } = ticket
  dispatch({
    type: "UPDATE_RECENT_TAB",
    tab: {
      id,
      type: "TICKET",
      payload: {
        id: newTicketId,
        name: `#${newTicketId}`,
      },
    },
  })
}

export const updateRecentTab = tab => dispatch => {
  dispatch({
    type: "UPDATE_RECENT_TAB",
    tab: {
      id: tab.id,
      type: tab.type,
      payload: {
        name: tab.name,
      },
    },
  })
}

const isUserViewingTicket = ticketId => window.location.hash === `#/ticketing/ticket/${ticketId}`
const goToUnassignedTickets = () => (window.location.hash = `#${unassignedTicketsBoardUrl}`)

const closingTicketAlert = async () =>
  ShowMessageDialog({
    icon: { icon: faTrash, type: "critical" },
    title: localizationKey("You have unsaved changes"),
    message: localizationKey("You have unsaved changes in your ticket. Are you sure you want to continue?"),
    buttons: [
      { id: "YES", label: localizationKey("Yes") },
      { id: "NO", label: localizationKey("No") },
    ],
  })

const removeTicketFromRecentsTab = async ({ dispatch, tab, getState }) => {
  try {
    if (isNewTicket(tab)) {
      const buttonPressed = await closingTicketAlert()

      if (buttonPressed === "YES") {
        dispatch({ type: "REMOVE_NEW_TICKET", tempTicketId: tab.id })
        dispatch({ type: "REMOVE_RECENT_TAB", tab })
      }
    } else {
      const {
        ticketing: {
          editor: { openTickets },
        },
      } = getState()
      const { ticket, original, editorData } = openTickets[tab.id] ?? {}

      if (ticket) {
        const omitNonEditableFields = omit(["deleted"])
        const isDifferent = !fastDeepEqual(omitNonEditableFields(ticket), omitNonEditableFields(original))
        const hasEditorData = editorData?.body?.trim()?.length || editorData?.uploads?.length

        if (isDifferent || hasEditorData) {
          const buttonPressed = await closingTicketAlert()

          if (buttonPressed === "YES") {
            dispatch({ type: "REMOVE_OPEN_TICKET", ticketId: tab.id })
          }

          if (buttonPressed === "NO") return
        }
      }

      await deleteRecentTab(tab)
      dispatch({ type: "REMOVE_RECENT_TAB", tab })

      if (isUserViewingTicket(tab.id)) {
        goToUnassignedTickets()
      }
    }
  } catch (error) {
    reportErrorAndShowMessage(error)
  }
}

export const removeRecentTab = tab => async (dispatch, getState) => {
  if (tab.type === "TICKET") {
    return removeTicketFromRecentsTab({ dispatch, tab, getState })
  }

  try {
    await deleteRecentTab(tab)
    dispatch({
      type: "REMOVE_RECENT_TAB",
      tab,
    })
  } catch (error) {
    ninjaReportError(error)
  }
}
