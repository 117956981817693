import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { raidHealthStatusOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData/options"

const HealthStatusSelect = ({ values, onChange, healthStatusParameter, label }) => (
  <FormGroup controlId="type">
    <Col componentClass={ControlLabel} sm={4}>
      {label}
    </Col>
    <Col sm={8}>
      <Dropdown
        options={raidHealthStatusOptions}
        initialValue={values[healthStatusParameter]}
        onChange={value => onChange(healthStatusParameter, value)}
      />
    </Col>
  </FormGroup>
)

export default HealthStatusSelect
