import React, { Component } from "react"
import { compose, join, map } from "ramda"
import styled from "@emotion/styled"
import { Modal } from "react-bootstrap"
import moment from "moment"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import { debugLog, padStart } from "js/includes/common/utils"
import { logout, deriveUserInactivityTimeout, user } from "js/includes/common/utils"

const MINUTES_TO_LOGOUT = 5

const StyledHeading = styled.h1`
  font-variant-numeric: tabular-nums;
`

export default class UserInactivityModal extends Component {
  state = {
    durationToLogout: moment.duration(MINUTES_TO_LOGOUT, "minutes"),
  }

  countdownToLogOut = () => {
    const { durationToLogout } = this.state
    const now = moment()
    const diff = now.diff(this.initialTime, "minutes")
    if (diff >= MINUTES_TO_LOGOUT) {
      this.clearCountdown()
      debugLog("logging out at", moment().format("HH:mm:ss"))
      logout({ message: localized("You were logged out due to inactivity") })
      this.modalDismiss()
    } else {
      this.setState({ durationToLogout: durationToLogout.subtract(1, "seconds") })
      this.timeout = setTimeout(() => this.countdownToLogOut(), 1000)
    }
  }

  clearCountdown = () => {
    clearTimeout(this.timeout)
  }

  componentDidMount = () => {
    this.initialTime = moment()
    this.countdownToLogOut()
    this.continueWorkingWhenPressingEnter()
  }

  componentWillUnmount = () => {
    this.clearCountdown()
    window.onkeydown = undefined
    this.props.reset()
  }

  continueWorkingWhenPressingEnter = () => {
    window.onkeydown = e => {
      if (e.keyCode === 13) this.modalDismiss()
    }
  }

  formatUserInactivityTimeoutDuration = () => {
    const userInactivityTimeout = deriveUserInactivityTimeout(user("getUserInactivityTimeout"))
    return moment.duration(userInactivityTimeout, "minutes").humanize()
  }

  formatDurationToLogout = () => {
    const { durationToLogout } = this.state
    const minutes = durationToLogout.minutes().toString()
    const seconds = durationToLogout.seconds().toString()
    return compose(join(":"), map(padStart(2, "0")))([minutes, seconds])
  }

  modalDismiss = () => {
    this.props.unmount()
  }

  render() {
    return (
      <Modal.Dialog bsClass="inmodal modal">
        <Modal.Header>
          <Modal.Title>{localized("User Inactivity")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{localized("You will be logged out due to inactivity in")}</p>
          <StyledHeading>{this.formatDurationToLogout()}</StyledHeading>
          <div>
            {localized("Your idle timeout is set to:")} {this.formatUserInactivityTimeoutDuration()}
          </div>
          <div>{localized("You can change the setting in the user configuration editor.")}</div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={this.modalDismiss}>
            {localized("Continue Working")}
          </button>
          <button type="button" className="btn btn-white" onClick={logout}>
            {localized("Logout")}
          </button>
        </Modal.Footer>
      </Modal.Dialog>
    )
  }
}
