import React, { memo, useState } from "react"
import { connect } from "react-redux"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { values, propEq, find, compose, sort, assoc, prop } from "ramda"
import { isVmHost, localized, isVmHyperVGuest, isVmHyperVHost } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import conditionDefaults, {
  conditionControlCode,
  defaultConditionNames,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"
import {
  vmWareHostConditionOptions,
  hyperVHostConditionOptions,
  vmVMWareConditionOptions,
  vmHyperVConditionOptions,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"
import { defaultConditionUidsByCode } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"

import { defaultConditionUids } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults/conditionCodes"

const sortConditions = options =>
  compose(
    _ =>
      assoc(
        "items",
        [...[{ value: conditionControlCode.SelectCondition, text: localized("Select a condition") }], ..._],
        options,
      ),
    sort((a, b) => a.text().localeCompare(b.text())),
    prop("items"),
  )(options)

const ConditionSelector = memo(
  ({
    selected,
    onChange,
    validation,
    validateForm,
    disabled,
    policy: {
      nodeClass,
      content: { conditions },
    },
  }) => {
    const [error, setError] = useState("")
    const vmConditionOptions = isVmHyperVGuest(nodeClass) ? vmHyperVConditionOptions : vmVMWareConditionOptions
    const hostConditionOptions = isVmHyperVHost(nodeClass) ? hyperVHostConditionOptions : vmWareHostConditionOptions

    const options = isVmHost(nodeClass) ? hostConditionOptions : vmConditionOptions

    return (
      <FormGroup controlId="conditionName">
        <Col componentClass={ControlLabel} sm={4}>
          {localized("Condition")}
        </Col>
        <Col sm={8}>
          <Dropdown
            options={sortConditions(options)}
            disabled={disabled}
            onChange={value => {
              setError("")
              const conditionId = defaultConditionUidsByCode[value]

              if (conditionId === defaultConditionUids.GuestToolsNotRunning) {
                const guestToolsCondition = compose(
                  find(propEq("conditionUid", defaultConditionUids.GuestToolsNotRunning)),
                  values,
                )(conditions)

                if (guestToolsCondition) {
                  onChange({
                    selected: 0,
                  })
                  return setError(localized("Condition already exists!"))
                }
              }

              onChange({
                conditionId: defaultConditionUidsByCode[value],
                selected: parseInt(value),
                ...conditionDefaults[defaultConditionNames[value]],
              })
            }}
            onBlur={() => validateForm(["selected"])}
            initialValue={selected}
          />
          <em className="invalid">{error || validation.message.selected}</em>
        </Col>
      </FormGroup>
    )
  },
)

export default connect(({ policyEditor }) => ({
  policy: policyEditor.policy,
}))(ConditionSelector)
