// Passcode
export const PasswordPolicyScope = {
  SCOPE_UNSPECIFIED: "SCOPE_UNSPECIFIED",
  SCOPE_DEVICE: "SCOPE_DEVICE",
  SCOPE_PROFILE: "SCOPE_PROFILE",
}

export const PasswordQuality = {
  PASSWORD_QUALITY_UNSPECIFIED: "PASSWORD_QUALITY_UNSPECIFIED",
  BIOMETRIC_WEAK: "BIOMETRIC_WEAK",
  SOMETHING: "SOMETHING",
  NUMERIC: "NUMERIC",
  NUMERIC_COMPLEX: "NUMERIC_COMPLEX",
  ALPHABETIC: "ALPHABETIC",
  ALPHANUMERIC: "ALPHANUMERIC",
  COMPLEX: "COMPLEX",
  COMPLEXITY_LOW: "COMPLEXITY_LOW",
  COMPLEXITY_MEDIUM: "COMPLEXITY_MEDIUM",
  COMPLEXITY_HIGH: "COMPLEXITY_HIGH",
}

// Security
export const EncryptionPolicy = {
  ENCRYPTION_POLICY_UNSPECIFIED: "ENCRYPTION_POLICY_UNSPECIFIED",
  ENABLED_WITHOUT_PASSWORD: "ENABLED_WITHOUT_PASSWORD",
  ENABLED_WITH_PASSWORD: "ENABLED_WITH_PASSWORD",
}

export const KeyguardDisabledFeature = {
  KEYGUARD_DISABLED_FEATURE_UNSPECIFIED: "KEYGUARD_DISABLED_FEATURE_UNSPECIFIED",
  CAMERA: "CAMERA",
  NOTIFICATIONS: "NOTIFICATIONS",
  UNREDACTED_NOTIFICATIONS: "UNREDACTED_NOTIFICATIONS",
  TRUST_AGENTS: "TRUST_AGENTS",
  DISABLE_FINGERPRINT: "DISABLE_FINGERPRINT",
  DISABLE_REMOTE_INPUT: "DISABLE_REMOTE_INPUT",
  FACE: "FACE",
  IRIS: "IRIS",
  BIOMETRICS: "BIOMETRICS",
  SHORTCUTS: "SHORTCUTS",
  ALL_FEATURES: "ALL_FEATURES",
}

export const BatteryPluggedMode = {
  BATTERY_PLUGGED_MODE_UNSPECIFIED: "BATTERY_PLUGGED_MODE_UNSPECIFIED",
  AC: "AC",
  USB: "USB",
  WIRELESS: "WIRELESS",
}

export const DeveloperSettings = {
  DEVELOPER_SETTINGS_UNSPECIFIED: "DEVELOPER_SETTINGS_UNSPECIFIED",
  DEVELOPER_SETTINGS_DISABLED: "DEVELOPER_SETTINGS_DISABLED",
  DEVELOPER_SETTINGS_ALLOWED: "DEVELOPER_SETTINGS_ALLOWED",
}

export const MtePolicySettings = {
  MTE_POLICY_UNSPECIFIED: "MTE_POLICY_UNSPECIFIED",
  MTE_USER_CHOICE: "MTE_USER_CHOICE",
  MTE_ENFORCED: "MTE_ENFORCED",
  MTE_DISABLED: "MTE_DISABLED",
}

export const ShowWorkContactsInPersonalProfile = {
  SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED: "SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_UNSPECIFIED",
  SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED: "SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED",
  SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED: "SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_ALLOWED",
  SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED_EXCEPT_SYSTEM:
    "SHOW_WORK_CONTACTS_IN_PERSONAL_PROFILE_DISALLOWED_EXCEPT_SYSTEM",
}

export const CrossProfileCopyPaste = {
  CROSS_PROFILE_COPY_PASTE_UNSPECIFIED: "CROSS_PROFILE_COPY_PASTE_UNSPECIFIED",
  COPY_FROM_WORK_TO_PERSONAL_DISALLOWED: "COPY_FROM_WORK_TO_PERSONAL_DISALLOWED",
  CROSS_PROFILE_COPY_PASTE_ALLOWED: "CROSS_PROFILE_COPY_PASTE_ALLOWED",
}

export const CrossProfileDataSharing = {
  CROSS_PROFILE_DATA_SHARING_UNSPECIFIED: "CROSS_PROFILE_DATA_SHARING_UNSPECIFIED",
  CROSS_PROFILE_DATA_SHARING_DISALLOWED: "CROSS_PROFILE_DATA_SHARING_DISALLOWED",
  DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED: "DATA_SHARING_FROM_WORK_TO_PERSONAL_DISALLOWED",
  CROSS_PROFILE_DATA_SHARING_ALLOWED: "CROSS_PROFILE_DATA_SHARING_ALLOWED",
}

export const SystemUpdateType = {
  SYSTEM_UPDATE_TYPE_UNSPECIFIED: "SYSTEM_UPDATE_TYPE_UNSPECIFIED",
  AUTOMATIC: "AUTOMATIC",
  WINDOWED: "WINDOWED",
  POSTPONE: "POSTPONE",
}

export const AssistContentPolicyType = {
  ASSIST_CONTENT_POLICY_UNSPECIFIED: "ASSIST_CONTENT_POLICY_UNSPECIFIED",
  ASSIST_CONTENT_DISALLOWED: "ASSIST_CONTENT_DISALLOWED",
  ASSIST_CONTENT_ALLOWED: "ASSIST_CONTENT_ALLOWED",
}

export const PlayStoreMode = {
  PLAY_STORE_MODE_UNSPECIFIED: "PLAY_STORE_MODE_UNSPECIFIED",
  BLOCKLIST: "BLOCKLIST",
  ALLOWLIST: "ALLOWLIST",
}

// Location Tracking
export const LocationTrackingAccuracyType = {
  BALANCED: "BALANCED",
  HIGH_ACCURACY: "HIGH_ACCURACY",
}

export const LocationTrackingFrequencyType = {
  DISTANCE: "DISTANCE",
  TIMED: "TIMED",
}
