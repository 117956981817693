import { useCallback, useMemo, useState } from "react"
import qs from "qs"
import { Editor, Range, Transforms } from "slate"

import { debounce, fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"

import { insertMention } from "./utils"

export function useMentions({ ticketId, clientId, editor, editorData, updateEditorData }) {
  const [mentionSuggestions, setMentionSuggestions] = useState([])
  const [mentionSuggestionTarget, setMentionSuggestionTarget] = useState()
  const [mentionSuggestionIndex, setMentionSuggestionIndex] = useState(0)

  const debouncedSearch = useMemo(
    () =>
      debounce(async searchCriteria => {
        try {
          const _qs = qs.stringify({
            searchCriteria,
            pageSize: 20,
            userType: "TECHNICIAN",
            ...(clientId && { clientId }),
          })
          const response = await fetchJson(`/app-user-contact/search?${_qs}`)
          setMentionSuggestions(response)
        } catch (error) {
          reportErrorAndShowMessage(error)
        }
      }, 250),
    [clientId],
  )

  const onAddMention = useCallback(
    (index, target) => {
      Transforms.select(editor, target)
      const mention = mentionSuggestions[index]
      insertMention(editor, mention)
      updateEditorData({
        ticketId,
        techniciansTagged: [...(editorData?.techniciansTagged ?? []), mention.id],
      })
      setMentionSuggestionTarget(null)
    },
    [ticketId, editor, editorData, updateEditorData, mentionSuggestions],
  )

  const onKeyDown = useCallback(
    event => {
      if (mentionSuggestionTarget) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault()
            const prevIndex = mentionSuggestionIndex >= mentionSuggestions.length - 1 ? 0 : mentionSuggestionIndex + 1
            setMentionSuggestionIndex(prevIndex)
            break
          case "ArrowUp":
            event.preventDefault()
            const nextIndex = mentionSuggestionIndex <= 0 ? mentionSuggestions.length - 1 : mentionSuggestionIndex - 1
            setMentionSuggestionIndex(nextIndex)
            break
          case "Tab":
          case "Enter":
            event.preventDefault()
            onAddMention(mentionSuggestionIndex, mentionSuggestionTarget)
            break
          case "Escape":
            event.preventDefault()
            setMentionSuggestionTarget(null)
            break
          default:
            break
        }
      }
    },
    [onAddMention, mentionSuggestions, mentionSuggestionIndex, mentionSuggestionTarget],
  )

  const onChange = useCallback(
    ({ html, text }) => {
      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection)
        const wordBefore = Editor.before(editor, start, { unit: "word" })
        const before = wordBefore && Editor.before(editor, wordBefore)
        const beforeRange = before && Editor.range(editor, before, start)
        const beforeText = beforeRange && Editor.string(editor, beforeRange)
        const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
        const after = Editor.after(editor, start)
        const afterRange = Editor.range(editor, start, after)
        const afterText = Editor.string(editor, afterRange)
        const afterMatch = afterText.match(/^(\s|$)/)

        if (beforeMatch && afterMatch) {
          setMentionSuggestionTarget(beforeRange)
          debouncedSearch(beforeMatch[1])
          setMentionSuggestionIndex(0)
          return
        }
      }

      setMentionSuggestionTarget(null)

      updateEditorData({
        ticketId,
        htmlBody: html,
        body: text,
      })
    },
    [editor, ticketId, updateEditorData, debouncedSearch],
  )

  return {
    mentionSuggestions,
    mentionSuggestionIndex,
    mentionSuggestionTarget,
    onKeyDown,
    onChange,
    onAddMention,
  }
}
