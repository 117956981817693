import { createSelector } from "reselect"
import { SortDirection } from "react-virtualized"
import {
  path,
  assoc,
  identity,
  filter,
  propEq,
  compose,
  when,
  equals,
  reverse,
  sortBy,
  length,
  is,
  toLower,
  prop,
} from "ramda"
import { localized } from "js/includes/common/utils"

const getCredentials = state => state.credentials
const getDefaultCredentialRoleMapping = state => state.roles

export function getDefaultDisplayName(key) {
  switch (key) {
    case "SR_MAC_SCRIPT":
      return localized("Mac Script")
    case "SR_LINUX_SCRIPT":
      return localized("Linux Script")
    case "SR_WINDOWS_OS_PATCHING":
      return localized("Windows OS Patching Preferred Credential")
    case "SR_WINDOWS_THIRD_PARTY_PATCHING":
      return localized("Windows 3rd Party Patching Preferred Credential")
    case "SR_RDP_ACCESS":
      return localized("Automatic RDP Technician Access")
    case "SR_LOCAL_ADMINISTRATOR":
      return localized("Windows Script Local Admin")
    case "SR_DOMAIN_ADMINISTRATOR":
      return localized("Windows Script Domain Admin")
    case "SR_ACTIVE_DIRECTORY_DISCOVERY":
      return localized("AD Discovery")
    case "SR_MAC_OS_PATCHING":
      return localized("macOS Patching")
    default:
      return ""
  }
}

export const getUsernamePasswordCredentials = createSelector([identity], filter(propEq("credentialType", "BASIC")))

export const getOrganizationCredentialsWithDefaults = createSelector(
  [getCredentials, getDefaultCredentialRoleMapping],
  (credentialsList, defaults) =>
    credentialsList.map(credential => {
      credential = assoc("defaults", [], credential)
      for (let key in defaults) {
        if (path([key, "id"], defaults) === credential.id) {
          let defaults = [...credential.defaults, key]
          credential = assoc("defaults", defaults, credential)
        }
      }
      return credential
    }),
)

export const tableSortSelector = createSelector(
  [state => state.data, state => state.sortBy, state => state.sortDirection],
  (list, sortByProp, sortDirection) =>
    compose(
      when(_ => equals(sortDirection, SortDirection.ASC), reverse),
      when(_ => equals(sortByProp, "defaults"), reverse),
      sortBy(compose(when(Array.isArray, length), when(is(String), toLower), prop(sortByProp))),
    )(list),
)
