import { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { patchTypeOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData/options"

const PatchTypeSelect = memo(({ values, onChange }) => {
  const { patchType } = values
  return (
    <FormGroup controlId="type">
      <Col componentClass={ControlLabel} sm={4}>
        {localized("Patch Type")}
      </Col>
      <Col sm={8}>
        <Dropdown
          options={patchTypeOptions}
          initialValue={patchType}
          onChange={value => onChange("patchType", value)}
        />
      </Col>
    </FormGroup>
  )
})

export default PatchTypeSelect
