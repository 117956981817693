import { HoverDropdownItem } from "@ninjaone/components"
import SubNavItemContent from "./SubNavItemContent"
import { actionIconWrapperStyle, actionIconStyle } from "./SubNavLink"

const SubNavHoverDropdownItem = ({ route, name, icon, collapsed, actionIcons, usesActiveClass }) => {
  return (
    <HoverDropdownItem
      {...{
        ...(actionIcons?.length && { key: actionIcons.toString() }),
        route,
        textRenderer: () => <SubNavItemContent {...{ name, icon, collapsed, actionIcons }} />,
        variant: "branded",
        usesActiveClass,
        strictActiveCheck: true,
        ...(actionIcons && {
          actionIcons,
          showActionIconOnHover: true,
          actionIconStyle,
          actionIconWrapperStyle,
        }),
      }}
    />
  )
}

export default SubNavHoverDropdownItem
