import { assocPath } from "ramda"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Box } from "js/includes/components/Styled"
import OSUpdatesHeader from "./OSUpdatesHeader"
import UserBehaviorSection from "./UserBehaviorSection"
import EnforcedUpdatesSection from "./EnforcedUpdatesSection"
import { defaultInheritance } from "./formCommons"

const Separator = styled.hr`
  border-top: solid 1px ${({ theme }) => theme.colorBorderWeakest};
  width: 100%;
  height: 1px;
  margin: 0 0 0 ${tokens.spacing[6]};
`
const OSUpdatesForm = ({ patchManagement, updatePolicyItem, parentPolicy }) => {
  const onPolicyChange = (path, value) => {
    const updatedOSForm = assocPath(path.split("."), value, { ...patchManagement, ...defaultInheritance })
    updatePolicyItem(`patchManagement`, parentPolicy, updatedOSForm)
  }

  const { userBehavior, patchOverrides } = patchManagement

  return (
    <Box flex={1} flexDirection="column" overflowY="auto" overflowX="hidden">
      <OSUpdatesHeader />
      <Separator />
      <UserBehaviorSection {...{ userBehavior, onPolicyChange }} />
      <Separator />
      <EnforcedUpdatesSection {...{ patchOverrides, onPolicyChange }} />
    </Box>
  )
}

export default connect(
  ({ policyEditor: { policy, parentPolicy } }) => ({
    patchManagement: policy.content.patchManagement,
    parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(OSUpdatesForm)

OSUpdatesForm.propTypes = {
  patchManagement: PropTypes.object.isRequired,
  updatePolicyItem: PropTypes.func.isRequired,
}
