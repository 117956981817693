import { always, cond, T } from "ramda"
import {
  localized,
  isWindowsPolicy,
  isMacPolicy,
  isValidWindowsPath,
  isValidMacPath,
  isWindowsDevice,
  isMacDevice,
} from "js/includes/common/utils"
import { replaceSeparatorAndRemoveTrailingSlash } from "js/includes/components/Browsers"
import { getWindowsCommonFolderCode } from "js/includes/editors/Policy/Sections/Backups/common/windowsCommonFolderOptions"
import {
  cloudberryWindowsCommonFolderOptions,
  getCloudberryWindowsCommonFolderCode,
} from "js/includes/editors/Policy/Sections/Backups/Cloudberry/cloudberryCommonFolderOptions"
import { macExclusionsList } from "js/includes/editors/Policy/Sections/Backups/common/exclusionsList"

export const validateName = (name, values, backupPlans) => {
  const isNameTaken = backupPlans.some(plan => {
    const planName = plan.name || plan.planName || plan.general?.name
    return (
      plan.productCode === values.productCode &&
      planName.toLowerCase() === name.toLowerCase() &&
      plan.identifier !== values.identifier
    )
  })

  const success = !!name.trim() && !isNameTaken
  let message = ""

  if (!name.trim()) {
    message = localized("A backup plan name is required")
  }

  if (isNameTaken) {
    message = localized("This backup plan name is already being used")
  }

  return { success, message }
}

export const validateLockhartBackupFolders = ({ common, paths }) => {
  const success = !!paths?.length || !!common?.length
  const message = !success ? localized("You must select or add at least one folder") : ""

  return { success, message }
}

export const validateFilterOptions = (filtersOptions, { excludeMask, includeMask, planType }) => {
  const success = planType === "image" || filtersOptions === "ALL_FILES" || excludeMask.length || includeMask.length
  const message = !success ? localized("A filter mask is required for exclusions or inclusions") : ""

  return { success, message }
}

export const validateIncludeMask = (masks, { filtersOptions }) => {
  const success = !["INCLUDE_MASK", "INCLUDE_FILE_MASK"].includes(filtersOptions) || !!masks.length
  const message = !success ? localized("A filter mask is required for exclusions or inclusions") : ""

  return { success, message }
}

export const validateExcludeMask = (masks, { filtersOptions }) => {
  const success = !["EXCLUDE_MASK", "EXCLUDE_FILE_MASK"].includes(filtersOptions) || !!masks.length
  const message = !success ? localized("A filter mask is required for exclusions or inclusions") : ""

  return { success, message }
}

export const validateCloudberryPaths = (paths, values) => {
  const isFileFolder = values.planType === "fileFolder"
  const fileFolderValidation =
    isFileFolder &&
    (paths.length || cloudberryWindowsCommonFolderOptions.some(commonFolderName => values[commonFolderName]))
  const isImage = values.planType === "image"
  const imageValidation =
    isImage && (["ALL_PARTITIONS", "SYSTEM_PARTITIONS"].includes(values.partitionsOptions) || paths.length)

  const success = !!fileFolderValidation || !!imageValidation
  const message = !success ? localized("You must select or add at least one folder") : ""

  return { success, message }
}

export const getComparablePath = (path = "") => replaceSeparatorAndRemoveTrailingSlash(path.trim().toLowerCase())

//TODO: Refactor this when macFF comes back around
export const getExclusionsList = () => {
  const { folder = [], filename = [], common = [] } =
    window.store?.getState().policyEditor?.backups?.serverDefaults?.systemExclusions ?? {}
  return [...folder, ...filename, ...common].map(({ pattern }) => pattern)
}
export const getExclusionsListByNodeRole = nodeRole =>
  cond([
    [isWindowsPolicy, always(getExclusionsList())],
    [isMacPolicy, always(macExclusionsList)],
    [T, always([])],
  ])(nodeRole)

export const isExcludedPathChild = (path, nodeRole) => {
  const exclusionsList = getExclusionsListByNodeRole(nodeRole)
  return exclusionsList.some(exclusionListPath => {
    const exclusionPathRegex = new RegExp(`(${exclusionListPath})(/[a-z]+)*`, "i")
    const normalizedUserPath = getComparablePath(path)
    const pathMatch = normalizedUserPath.match(exclusionPathRegex)
    return !!pathMatch?.[2]
  })
}

export const isOnExclusionList = (path, nodeRole, disableExclusionListValidation) => {
  if (disableExclusionListValidation) return false

  const exclusionsList = getExclusionsListByNodeRole(nodeRole)
  return exclusionsList.some(exclusionListPath => {
    const exclusionPathRegex = new RegExp(exclusionListPath, "i")
    const normalizedUserPath = getComparablePath(path)
    return exclusionPathRegex.test(normalizedUserPath)
  })
}

export const isValidPathByOsType = ({ nodeRole, nodeClass, path }) => {
  return cond([
    [() => (isWindowsPolicy(nodeRole) || isWindowsDevice(nodeClass)) && isValidWindowsPath(path), always(true)],
    [() => (isMacPolicy(nodeRole) || isMacDevice(nodeClass)) && isValidMacPath(path), always(true)],
    [T, always(false)],
  ])()
}

export const validatePath = ({
  _path,
  paths,
  isNew,
  nodeRole,
  common,
  disableExclusionListValidation,
  nodeClass,
  isLockhart = true,
}) => {
  const path = _path.trim()
  const isRequired = !path

  if (isRequired) {
    return {
      success: false,
      message: localized("Required"),
    }
  }

  if (!isValidPathByOsType({ nodeClass, nodeRole, path })) {
    return {
      success: false,
      message: localized("Invalid"),
    }
  }

  const isDuplicate =
    isNew &&
    paths.some(fPath => {
      const pathA = getComparablePath(fPath)
      const pathB = getComparablePath(path)
      return pathA === pathB
    })

  if (isDuplicate) {
    return {
      success: false,
      message: localized("That path was already added"),
    }
  }

  const shouldExclude = isOnExclusionList(path, nodeRole, disableExclusionListValidation)

  const isChildOfExcludedPath = shouldExclude && isExcludedPathChild(path, nodeRole)

  if (shouldExclude && !isChildOfExcludedPath) {
    return {
      success: false,
      message: localized("Excluded path"),
    }
  }

  const commonFolderCode = isLockhart ? getWindowsCommonFolderCode(path) : getCloudberryWindowsCommonFolderCode(path)
  const isCommonFolderAlreadyAdded =
    commonFolderCode && common.includes(commonFolderCode) && !isExcludedPathChild(path, nodeRole)

  if (isCommonFolderAlreadyAdded) {
    return {
      success: false,
      message: localized("A common folder was already added matching this path"),
    }
  }

  return {
    success: true,
    message: "",
  }
}

export const validateImageVolumes = ({ type, volumes }, { imageType }) => {
  const success = type === "CUSTOM" ? !!volumes.length : true
  const message = !success ? localized("Required") : ""

  return { success, message }
}

export const validateDestinationsOptions = (destinationsOptions, { selectedNetworkLocation }) => {
  const success =
    destinationsOptions === "CLOUD" || (destinationsOptions === "LOCAL" && !!selectedNetworkLocation.length)
  const message = !success ? localized("Required") : ""

  return { success, message }
}

export const validatePurgeOlderThanDays = (purgeOlderThanDays, { purgeOlderThan }) => {
  const success = !purgeOlderThan || (purgeOlderThan && purgeOlderThanDays > 0)
  const message = !success ? localized("Value must be greater than or equal to") + " 1" : ""

  return { success, message }
}

export const validateKeepVersionsNumber = (keepVersionsNumber, { keepVersions }) => {
  const success = !keepVersions || (keepVersions && keepVersionsNumber > 0)
  const message = !success ? localized("Value must be greater than or equal to") + " 1" : ""

  return { success, message }
}

export const validateDeleteFilesDeletedLocallyDays = (deleteFilesDeletedLocallyDays, { deleteFilesDeletedLocally }) => {
  const success = !deleteFilesDeletedLocally || (deleteFilesDeletedLocally && deleteFilesDeletedLocallyDays > 0)
  const message = !success ? localized("Value must be greater than or equal to") + " 1" : ""

  return { success, message }
}
