import { useState } from "react"
import { faTrash, faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { Text } from "@ninjaone/components"

import { ConfirmationModal } from "js/includes/components/ConfirmationModal"
import { addAlert, removeAlert } from "js/state/actions/alerts/alertMessages"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import showModal from "js/includes/common/services/showModal"

const iconMapper = {
  critical: faTrash,
  warning: faInfoCircle,
}

window.showSuccessMessage = function(message = localized("Success")) {
  window.store.dispatch(
    addAlert({
      duration: 5000,
      type: "success",
      message,
    }),
  )
}

window.showErrorMessage = function(message) {
  window.store.dispatch(
    addAlert({
      duration: 5000,
      type: "error",
      message,
    }),
  )
}

window.showWarningMessage = function(message) {
  window.store.dispatch(
    addAlert({
      duration: 5000,
      type: "warning",
      message,
    }),
  )
}

window.showInfoMessage = function(message) {
  window.store.dispatch(
    addAlert({
      duration: 5000,
      type: "info",
      message,
    }),
  )
}

window.showPermanentMessage = function(message, type = "info") {
  const alertId = window.store.dispatch(
    addAlert({
      type,
      message,
    }),
  )

  return () => window.store.dispatch(removeAlert(alertId))
}

export const showSuccessMessage = message => window.showSuccessMessage(message)
export const showErrorMessage = message => window.showErrorMessage(message)
export const showWarningMessage = message => window.showWarningMessage(message)
export const showInfoMessage = message => window.showInfoMessage(message)
export const showPermanentMessage = (message, type) => window.showPermanentMessage(message, type)

function NinjaActionDialog({
  type,
  title,
  message,
  actionButtonLabel,
  unmount,
  onConfirm,
  onCancel,
  MessageComponent,
  validate,
}) {
  const [isValid, setIsValid] = useState(false)

  return (
    <ConfirmationModal
      {...{
        type: type === "critical" ? "danger" : "default",
        titleToken: title,
        actionToken: actionButtonLabel,
        onConfirm,
        onCancel,
        unmount,
        disabled: validate && !isValid,
      }}
    >
      <Text size="sm" textWrap token={message} />
      {MessageComponent && <MessageComponent isValid={isValid} setValidation={setIsValid} />}
    </ConfirmationModal>
  )
}

function showNewDialog(props) {
  return new Promise(resolve => {
    showModal(
      <NinjaActionDialog
        {...props}
        onConfirm={({ unmount }) => {
          unmount()
          resolve(true)
        }}
        onCancel={({ unmount }) => {
          unmount()
          resolve(false)
        }}
      />,
      { withProvider: true },
    )
  })
}

export async function actionDialog({
  icon,
  type = "critical",
  title = localizationKey("Delete"),
  message = localizationKey("Are you sure you want to delete your selection?"),
  actionButtonLabel = localizationKey("Delete"),
  actionButtonType = "critical",
  isLegacy = true,
  validate = false,
  MessageComponent,
} = {}) {
  return isLegacy
    ? await ShowMessageDialog({
        icon: { icon: icon ?? iconMapper[type], type },
        title,
        message,
        buttons: [
          { id: true, label: actionButtonLabel, type: actionButtonType, validate },
          { id: false, label: localizationKey("Cancel"), validate },
        ],
        MessageComponent,
      })
    : await showNewDialog({
        type,
        title,
        message,
        actionButtonLabel,
        validate,
        MessageComponent,
      })
}
