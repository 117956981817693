import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { HoverTooltip } from "@ninjaone/components"
import { QuestionCircleIconLight } from "@ninjaone/icons"
import { Text } from "@ninjaone/components"
import { Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

const SectionTitle = ({ titleToken, tooltipToken }) => (
  <Flex height="fit-content" alignItems="center">
    <Text color="colorTextStrong" fontWeight={tokens.typography.fontWeight.medium}>
      {localized(titleToken)}
    </Text>
    <HoverTooltip triggerColor="colorTextWeakest" text={localized(tooltipToken)}>
      <QuestionCircleIconLight />
    </HoverTooltip>
  </Flex>
)

SectionTitle.propTypes = {
  titleToken: PropTypes.string.isRequired,
  tooltipToken: PropTypes.string.isRequired,
}

export default SectionTitle
