import { useCallback, useEffect, useRef } from "react"

export function useDataPolling({ pollingIntervalDuration, fetchData }) {
  const pollingIntervalRef = useRef()

  const startPollingData = useCallback(() => {
    fetchData()

    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
    }

    pollingIntervalRef.current = setInterval(() => {
      fetchData(true)
    }, pollingIntervalDuration)
  }, [fetchData, pollingIntervalDuration])

  useEffect(() => {
    startPollingData()

    return () => {
      clearInterval(pollingIntervalRef.current)
    }
  }, [startPollingData])

  return { restart: startPollingData }
}
