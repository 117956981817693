import React, { useCallback, useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { isNotNilOrEmpty, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import DragableElement from "./DragableElement"
import { noRowsRenderer } from "js/includes/common/utils"

const getElementKey = ({ keyProp, keyProps, element }) => {
  if (keyProp) {
    return element[keyProp]
  }

  if (keyProps?.length) {
    let key

    keyProps.forEach(_key => {
      if (isNotNilOrEmpty(element[_key])) {
        key = element[_key]
      }
    })

    return key
  }

  return element
}

const DragableList = ({
  elements,
  onRemoveElement,
  onChange,
  renderElement,
  keyProp,
  keyProps,
  isDisabled,
  noRowsLabel,
  loading,
}) => {
  const [elementsLocal, setElementsLocal] = useState(elements)

  const moveElement = useCallback((dragIndex, hoverIndex) => {
    setElementsLocal(prevElements => {
      const newElements = prevElements.slice()
      const element = newElements[dragIndex]
      newElements.splice(dragIndex, 1)
      newElements.splice(hoverIndex, 0, element)
      return newElements
    })
  }, [])

  const endDrag = useCallback(() => {
    onChange(elementsLocal)
  }, [onChange, elementsLocal])

  useEffect(() => {
    setElementsLocal(elements)
  }, [elements])

  const noRowsLabelRenderer =
    typeof noRowsLabel === "function" ? noRowsLabel(loading) : noRowsRenderer(noRowsLabel, loading, "no-padding")()

  return !!elementsLocal.length ? (
    <DndProvider backend={HTML5Backend}>
      {elementsLocal.map((element, index) => (
        <DragableElement
          {...{
            element,
            key: getElementKey({ keyProp, keyProps, element }),
            index,
            moveElement,
            onRemoveElement,
            renderElement,
            isDisabled,
            endDrag,
          }}
        />
      ))}
    </DndProvider>
  ) : (
    noRowsLabelRenderer
  )
}

DragableList.defaultProps = {
  elements: [],
  loading: false,
  noRowsLabel: localizationKey("No data available"),
}

export default DragableList
