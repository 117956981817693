import { ConfirmationModal, Dropdown, IconButton, Text } from "@ninjaone/components"
import { spacing, typography } from "@ninjaone/tokens"
import showModal from "js/includes/common/services/showModal"
import { localizationKey, localized, localizedWith } from "js/includes/common/utils"
import { StyledBox as WidgetStyledBox } from "js/includes/configuration/apps/components/ConfigurationWidget"
import { AddBundleConfigModal } from "./AddBundleConfigModal"
import { BundleArrayGroupValues } from "./BundleArrayGroupValues"
import { BundleSection } from "./ManagedConfigurations"
import { EllipsisHIcon } from "@ninjaone/icons"
import { Box, Flex } from "js/includes/components/Styled"

export const BundleArrayItem = ({
  configKeys,
  labelText,
  descriptionText,
  nestedProperties,
  onChange,
  valueList = [],
  level,
}) => {
  const onAddGroup = () => {
    showModal(
      <AddBundleConfigModal
        {...{ nestedProperties }}
        bundleTitle={labelText}
        onSave={groupValue => {
          onChange(valueList.concat(groupValue))
        }}
      />,
    )
  }

  const onEdit = index => {
    showModal(
      <AddBundleConfigModal
        {...{ nestedProperties }}
        groupValue={valueList[index]}
        bundleTitle={labelText}
        onSave={newGroupValue => {
          onChange(valueList.toSpliced(index, 1, newGroupValue))
        }}
      />,
    )
  }

  const onDelete = index => {
    showModal(
      <ConfirmationModal
        type="danger"
        titleText={localized("Delete this item?")}
        descriptionText={localizedWith("Are you sure you want to delete this item of <%configurationName> <%>?", {
          configurationName: () => (
            <Text type="body" as="strong" fontWeight={typography.fontWeight.medium} textWrap>
              {labelText}
            </Text>
          ),
        })}
        actionToken={localizationKey("Delete")}
        onConfirm={({ unmount }) => {
          unmount()

          onChange(valueList.toSpliced(index, 1))
        }}
      />,
    )
  }

  return (
    <BundleSection
      {...{ labelText, descriptionText }}
      level={level ?? 1}
      addAction={onAddGroup}
      childrenGap={spacing[3]}
      isArrayBundle
    >
      {valueList.map((groupValue, index) => {
        return (
          <WidgetStyledBox
            data-testid={`array-bundle-${index}`}
            key={`${configKeys}-${index}`}
            padding={[spacing[3], spacing[4]]}
          >
            <Flex flexDirection="row-reverse">
              <Dropdown
                variant="compact"
                options={[
                  {
                    labelToken: localizationKey("Edit"),
                    action: () => onEdit(index),
                    splitAfter: true,
                  },
                  {
                    labelToken: localizationKey("Delete"),
                    action: () => onDelete(index),
                    isRed: true,
                  },
                ]}
                alignRight
                childIsButton
              >
                <IconButton tooltipAlignRight size="sm" tooltip={localized("Actions")}>
                  <EllipsisHIcon />
                </IconButton>
              </Dropdown>
            </Flex>
            <Box marginTop={`-${spacing[7]}`} width="90%">
              <BundleArrayGroupValues {...{ groupValue }} properties={nestedProperties} />
            </Box>
          </WidgetStyledBox>
        )
      })}
    </BundleSection>
  )
}
