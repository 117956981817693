import qs from "qs"

import { downloadFile, fetch, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

export async function csvDownload({ url, body, fetchAsJson = false }) {
  try {
    const response = await fetch(url, {
      options: {
        method: "POST",
        body: fetchAsJson ? JSON.stringify(body) : qs.stringify(body),
      },
      ...(fetchAsJson ? {} : { headers: { "Content-Type": "application/x-www-form-urlencoded" } }),
    })

    if (!response.ok) {
      throw new Error(`Could not retrieve CSV data. HTTP status: ${response.status}`)
    }

    const data = await response.text()
    const fileName = `${body.exportName}.csv`
    const BOMChar = "\uFEFF"
    downloadFile(
      `data:text/csv;charset=utf-8,${encodeURIComponent(`${data.startsWith(BOMChar) ? "" : BOMChar}${data}`)}`,
      fileName,
    )
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error downloading CSV file"))
  }
}
