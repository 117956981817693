import CommonHashTable from "./CommonHashTable"
import { DeviceInfoModel } from "js/infrastructure/backbone/InfoModels"
import { compose, reject as ramdaReject, find, forEach, propEq } from "ramda"
import { fetchDevicesByIds } from "js/includes/common/client"

export default class DeviceList extends CommonHashTable {
  updateDeviceOnChange(device) {
    const { attributes } = device
    this.set(attributes.id, attributes)
  }

  get(id) {
    const _id = parseInt(id, 10)
    const device = DeviceInfoModel.findOrCreate(super.get(_id))
    device && device.once("change", this.updateDeviceOnChange)
    return device
  }

  fetchById(nodeId, includeParentsAndChildren = false) {
    return this._fetch([nodeId], includeParentsAndChildren)
  }

  fetchByIds(nodeIds, includeParentsAndChildren = false) {
    return this._fetch(nodeIds, includeParentsAndChildren)
  }

  //Private method
  _fetch(nodeIds = [], includeParentsAndChildren) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!nodeIds.length) {
          resolve({ nodes: [] })
          return
        }

        const { nodes, cacheExpiration } = await fetchDevicesByIds(
          nodeIds,
          includeParentsAndChildren,
          this.cacheExpiration,
        )

        nodes.forEach(n => {
          const currentNode = this.getRaw(n.id) || {}
          this.set(n.id, Object.assign(currentNode, n))
        })

        //Check for nodeIds that were not returned by the call to the endpoint and delete them from the deviceList
        //This helps keep the deviceList in sync between different instances of the webapp
        compose(
          forEach(deletedNodeId => window.deviceList.delete(deletedNodeId)),
          ramdaReject(nodeId => find(propEq("id", nodeId), nodes)),
        )(nodeIds)

        this.cacheExpiration = cacheExpiration
        resolve({ nodes })
      } catch (error) {
        reject(error)
      }
    })
  }
}
