import styled from "@emotion/styled"
import { AppleIcon, GoogleStore } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { Flex } from "js/includes/components/Styled"

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${tokens.spacing[2]};
  height: 32px;
  border: 0;
  border-radius: ${tokens.borderRadius[1]};
  padding: ${tokens.spacing[3]};
  font-size: ${tokens.typography.fontSize.body};
  font-weight: ${tokens.typography.fontWeight.medium};
  text-decoration: none;
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colorTextStrong};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const DownloadMobileAppIcons = () => (
  <>
    <StyledLink
      data-ninja-app-store-link=""
      href="https://apps.apple.com/us/app/ninjaone-mobile/id1416138607"
      rel="noreferrer"
      target="_blank"
    >
      <Flex maxWidth="12px">
        <AppleIcon />
      </Flex>

      {"App Store"}
    </StyledLink>

    <StyledLink
      data-ninja-google-play-link=""
      href="https://play.google.com/store/apps/details?id=com.ninjarmm.mobile.dashboard"
      rel="noreferrer"
      target="_blank"
    >
      <Flex maxWidth="16px">
        <GoogleStore />
      </Flex>

      {"Google Play"}
    </StyledLink>
  </>
)

export default DownloadMobileAppIcons
