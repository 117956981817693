import { lensPath, set, append, remove, mergeDeepRight } from "ramda"
import { getDefaultThemeName } from "../../../includes/common/utils"

export const initialState = {
  general: {
    enabled: false,
    phoneSettings: {
      enabled: false,
      number: "",
    },
    emailSettings: {
      enabled: false,
      email: "",
    },
    helpSettings: {
      enabled: false,
      ninjaArticlesEnabled: true,
      submitTicketEnabled: true,
      ninjaOneWorkrampAcademyEnabled: true,
      topics: [],
    },
    helpWebsite: {
      enabled: false,
      website: "",
    },
    browserTab: {
      name: "",
      enabled: false,
    },
  },
  images: {
    loginLogo: "",
    appLogo: "",
    browserIcon: "",
    theme: getDefaultThemeName(),
  },
}

export default function brandingNode(state = initialState, action) {
  switch (action.type) {
    case "INIT_WEBSITE_BRANDING_SETTINGS":
      return mergeDeepRight(initialState, action.payload)
    case "SET_SYSTRAY_INFO":
      return { ...state, systray: action.payload }
    case "SET_ENABLED":
      return set(lensPath(["general", "enabled"]), action.enabled, state)
    case "SET_PHONE_ENABLED":
      return set(lensPath(["general", "phoneSettings", "enabled"]), action.phoneEnabled, state)
    case "SET_PHONE_NUMBER":
      return set(lensPath(["general", "phoneSettings", "number"]), action.phoneNumber, state)
    case "SET_EMAIL_ENABLED":
      return set(lensPath(["general", "emailSettings", "enabled"]), action.emailEnabled, state)
    case "SET_EMAIL":
      return set(lensPath(["general", "emailSettings", "email"]), action.email, state)
    case "SET_HELP_WEBSITE_ENABLED":
      return set(lensPath(["general", "helpWebsite", "enabled"]), action.enableHelpWebsite, state)
    case "SET_HELP_WEBSITE":
      return set(lensPath(["general", "helpWebsite", "website"]), action.website, state)
    case "SET_HELP_ENABLED":
      return set(lensPath(["general", "helpSettings", "enabled"]), action.helpEnabled, state)
    case "SET_HELP_NINJA_ARTICLES_ENABLED":
      return set(lensPath(["general", "helpSettings", "ninjaArticlesEnabled"]), action.ninjaArticlesEnabled, state)
    case "SET_HELP_SUBMIT_TICKET_ENABLED":
      return set(lensPath(["general", "helpSettings", "submitTicketEnabled"]), action.submitTicketEnabled, state)
    case "SET_WORKRAMP_LINK_ENABLED":
      return set(
        lensPath(["general", "helpSettings", "ninjaOneWorkrampAcademyEnabled"]),
        action.ninjaOneWorkrampAcademyEnabled,
        state,
      )
    case "ADD_HELP_TOPIC":
      const topicsAfterAdd = append(action.helpTopic, state.general.helpSettings.topics)
      return set(lensPath(["general", "helpSettings", "topics"]), topicsAfterAdd, state)
    case "REMOVE_HELP_TOPIC":
      const topicsAfterRemove = remove(action.index, 1, state.general.helpSettings.topics)
      return set(lensPath(["general", "helpSettings", "topics"]), topicsAfterRemove, state)
    case "SET_LOGIN_LOGO":
      return set(lensPath(["images", "loginLogo"]), action.loginLogo, state)
    case "SET_APP_LOGO":
      return set(lensPath(["images", "appLogo"]), action.appLogo, state)
    case "SET_BROWSER_ICON":
      return set(lensPath(["images", "browserIcon"]), action.browserIcon, state)
    case "SET_THEME":
      return set(lensPath(["images", "theme"]), action.themeName, state)
    case "SET_BROWSER_TAB_ENABLED":
      return set(lensPath(["general", "browserTab", "enabled"]), action.enableBrowserTab, state)
    case "SET_BROWSER_TAB_NAME":
      return set(lensPath(["general", "browserTab", "name"]), action.browserTabName, state)
    default:
      return state
  }
}
