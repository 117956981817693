import React, { useState } from "react"
import { Col, ControlLabel, Form, FormControl, Row } from "react-bootstrap"
import styled from "@emotion/styled"

import { normalizeInt } from "js/includes/common/backup"
import { useMounted } from "js/includes/common/hooks"
import showModal from "js/includes/common/services/showModal"
import { colors } from "js/includes/common/theme/colors"
import {
  clipboardWriteText,
  fetchJson,
  localizationKey,
  localized,
  ninjaReportError,
  runDeviceSearchToolbarAction,
  showErrorMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"

const StyledFormControl = styled(FormControl)`
  height: 38px;
  padding: 5px;
  border-radius: 4px;
  border-color: ${colors.reactSelectGrey};
  &:focus {
    border-radius: 4px;
    box-shadow: 0 0 0 1px ${colors.ninjaBlueSaturated};
  }
`

const StyledModalContainer = styled.span`
  .modal-dialog {
    width: 400px !important;
    min-width: 400px !important;
  }
`

const getImageRestoreKey = async (node, values) => {
  return await fetchJson(`/backup/lockhart/node/${node.id}/restore-key`, {
    options: {
      method: "POST",
      body: JSON.stringify(values),
    },
  })
}

const GeneratedRestoreKey = ({ restoreKey, unmount, onClose }) => {
  const copyToClipboard = e => {
    e.preventDefault()
    clipboardWriteText(restoreKey.toString())
  }

  return (
    <Form onSubmit={copyToClipboard}>
      <StyledModalContainer>
        <Modal
          title={localizationKey("Image Restore Key")}
          saveText={localizationKey("Copy")}
          submittable
          close={() => {
            onClose?.()
            unmount()
          }}
          showCloseButton={false}
        >
          <Row className="text-align-left m-l-sm m-b-sm">{localized("Please note the restore key now.")}</Row>
          <Row className="m-r-sm m-l-sm">
            <FormControl
              className="text-align-left"
              required
              type="text"
              name="restoreKey"
              value={restoreKey}
              readOnly={true}
            />
          </Row>
        </Modal>
      </StyledModalContainer>
    </Form>
  )
}

const GenerateRestoreKeyModal = ({
  unmount,
  node,
  getImageRestoreKeyStatus,
  refreshDeviceActions,
  onClose,
  fromDeviceSearch = false,
}) => {
  const mounted = useMounted()
  const [disabled, setDisabled] = useState(false)
  const [expirationTimeValue, setExpirationTimeValue] = useState(14)

  const normalizeExpirationValue = (num, min = 1, max = 10000) => {
    const numInt = normalizeInt(num, min)
    if (numInt > max) return expirationTimeValue
    return numInt
  }

  const generateKey = async () => {
    setDisabled(true)
    const values = {
      expirationTimeUnit: "DAYS",
      expirationAmount: expirationTimeValue,
    }
    try {
      const response = fromDeviceSearch
        ? await runDeviceSearchToolbarAction({
            action: "BACKUP_GENERATE_IMAGE_AUTHORIZATION_KEY",
            data: { restoreTokenRequest: values },
          })
        : await getImageRestoreKey(node, values)
      const restoreKey = fromDeviceSearch ? response.data.restoreKey : response.restoreKey

      unmount()
      getImageRestoreKeyStatus?.(true)
      refreshDeviceActions?.()

      showSuccessMessage(localized("Image restore key generated"))
      showModal(<GeneratedRestoreKey {...{ restoreKey, onClose }} />)
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        showErrorMessage(localized("Error generating image restore key"))
        ninjaReportError(error)
      }
      mounted.current && setDisabled(false)
    }
  }

  return (
    <StyledModalContainer>
      <Modal
        title={localizationKey("Image Restore Key Generation")}
        disabledSaveBtn={!expirationTimeValue || disabled}
        saveText={localizationKey("Generate")}
        save={generateKey}
        close={() => {
          onClose?.()
          unmount()
        }}
        overflow
        showCloseButton={false}
      >
        <Row>
          <Col xs={12} className="text-align-left">
            {localized(
              "Choose an expiration based on how long you expect the restore to take. If the image restore key expires during a restore, your restore will not complete successfully",
            )}
          </Col>
        </Row>
        <Row className="m-t-sm m-b-sm display-flex align-items-center">
          <Col xs={3} className="no-margin text-left" componentClass={ControlLabel}>
            {localized("Expires")}
          </Col>
          <Col xs={4} className="no-padding">
            <StyledFormControl
              required
              min="1"
              step="1"
              type="number"
              name="expirationTimeValue"
              value={expirationTimeValue}
              onChange={e => setExpirationTimeValue(normalizeExpirationValue(e.target.value))}
            />
          </Col>
          <Col xs={2} className="no-margin" componentClass={ControlLabel}>
            {localized("day(s)")}
          </Col>
        </Row>
        <Row className="text-left">
          <Col xs={12} className="text-align-left">
            {localized("It is recommended to overestimate the expiration time")}
          </Col>
        </Row>
      </Modal>
    </StyledModalContainer>
  )
}

export default GenerateRestoreKeyModal
