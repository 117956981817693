import { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { softwarePresenceOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData/options"

const SoftwareSelect = memo(({ values, onChange }) => {
  const { softwarePresence } = values
  return (
    <FormGroup controlId="duration">
      <Col componentClass={ControlLabel} sm={4}>
        {localized("editors.policy.conditions.presence")}
      </Col>
      <Col sm={8}>
        <Dropdown
          options={softwarePresenceOptions}
          initialValue={softwarePresence}
          onChange={value => onChange(value)}
        />
      </Col>
    </FormGroup>
  )
})

export default SoftwareSelect
