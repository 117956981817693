import { useMemo } from "react"
import { useStore } from "react-redux"
import styled from "@emotion/styled"
import { either, prop } from "ramda"

import { Tooltip } from "@ninjaone/components"
import { UserSlashIcon, UserXMarkIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"

import { localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"

export const fullNameOrDisplayName = either(prop("name"), prop("displayName"))

const getUserNotFoundLabel = () => localized("User not Found")

function generateLabel(mention) {
  const label = mention ? `${fullNameOrDisplayName(mention)} - ${mention.email}` : getUserNotFoundLabel()
  if (mention?.deleted) return `${label} (${localized("Technician deleted")})`
  if (mention?.permitted === false) return `${label} (${localized("Technician not allowed to view ticket")})`
  return label
}

const StyledMention = styled.span`
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme, isCurrentUser }) => (isCurrentUser ? theme.color.yellow : theme.color.lightGray)};
  padding: ${sizer(0.5, 1)};
  border-radius: ${sizer(1)};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, isCurrentUser }) => (isCurrentUser ? theme.color.yellow : theme.color.gray)};
  }
`

export const MentionElement = ({ attributes, element, children }) => {
  const store = useStore()

  const currentUser = useMemo(() => store.getState().session.user, [store])
  const isCurrentUser = useMemo(() => currentUser?.id === element.mention?.id, [currentUser, element])

  return (
    <span {...attributes} data-cy={`mention-${element.mention?.id || "not-found"}`} contentEditable={false}>
      <Tooltip label={generateLabel(element.mention)}>
        <StyledMention {...{ isCurrentUser }}>
          @{element.mention ? fullNameOrDisplayName(element.mention) : getUserNotFoundLabel()}
          {element.mention?.deleted && (
            <Box as="span" paddingLeft={sizer(1)}>
              <UserXMarkIcon size="xs" />
            </Box>
          )}
          {element.mention?.permitted === false && (
            <Box as="span" paddingLeft={sizer(1)}>
              <UserSlashIcon size="xs" />
            </Box>
          )}
        </StyledMention>
      </Tooltip>
      {children}
    </span>
  )
}
