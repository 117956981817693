import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { durationOptions } from "../../../conditionData"

export default memo(({ values, onChange, validation }) => (
  <FormGroup controlId="duration">
    <Col componentClass={ControlLabel} sm={4}>
      {localized("Duration")}
    </Col>
    <Col sm={8}>
      <Dropdown
        options={durationOptions}
        initialValue={values.duration}
        onChange={value => onChange("duration", parseInt(value))}
      />
    </Col>
  </FormGroup>
))
