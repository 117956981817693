import { EnglishTextToken, localized } from "js/includes/common/utils"

export const COMMON_FILE_OPTIONS = {
  ALL_USERS_PROFILE: "allUsersProfile",
  MY_DESKTOP: "myDesktop",
  MY_DOWNLOADS: "myDownloads",
  MY_MUSIC: "myMusic",
  MY_DOCUMENTS: "myDocuments",
  MY_VIDEOS: "myVideos",
  MY_PICTURES: "myPictures",
  MY_LOCAL_APP_DATA: "myLocalAppData",
  APPLICATION_DATA: "applicationData",
  SHARED_USERS_PROFILE: "sharedUsersProfile",
  SHARED_DESKTOP: "sharedDesktop",
  SHARED_DOCUMENTS: "sharedDocuments",
  SHARED_DOWNLOADS: "sharedDownloads",
  SHARED_MUSIC: "sharedMusic",
  SHARED_PICTURES: "sharedPictures",
  SHARED_VIDEOS: "sharedVideos",
  SHARED_APP_DATA: "sharedAppData",
  START_MENU_PROGRAMS: "startMenuPrograms",
} as const

export const getArrowImagePlanName = () => localized("Image backup plan" as EnglishTextToken)

export const ARROW_IMAGE_PLAN_ID = "17aae2bc-7a05-4079-b03d-da0df3132454"

export type commonFileOptionsType = typeof COMMON_FILE_OPTIONS[keyof typeof COMMON_FILE_OPTIONS]
