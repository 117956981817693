export const setGlobalSearchPageResults = (pageExactResults, pageSuggestedResults) => ({
  type: "SET_GLOBAL_SEARCH_PAGE_RESULTS",
  pageExactResults,
  pageSuggestedResults,
})

export const resetGlobalSearchPageResults = () => ({
  type: "RESET_GLOBAL_SEARCH_PAGE_RESULTS",
})

export const setGlobalSearchPersistedResults = (persistedExactResults, persistedSuggestedResults) => ({
  type: "SET_GLOBAL_SEARCH_PERSISTED_RESULTS",
  persistedExactResults,
  persistedSuggestedResults,
})

export const setGlobalSearchPageQuery = (pageValue, pageFilter) => ({
  type: "SET_GLOBAL_SEARCH_PAGE_QUERY",
  pageValue,
  pageFilter,
})

export const setGlobalSearchPersistedQuery = (persistedValue, persistedFilter) => ({
  type: "SET_GLOBAL_SEARCH_PERSISTED_QUERY",
  persistedValue,
  persistedFilter,
})
