import React, { memo } from "react"
import {
  OperatorSelect,
  ThresholdSlider,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"

export default memo(({ values, onChange, onThresholdChange, validation }) => (
  <>
    <OperatorSelect
      {...{
        values,
        onChange,
        validation,
      }}
    />

    <ThresholdSlider
      {...{
        values,
        onChange: onThresholdChange,
        thresholdType: "number",
        validation,
        maxValue: 9,
      }}
    />
  </>
))
