import moment from "moment"
import { defaultTo, compose, sortWith, prop, ifElse, always, ascend, descend, equals, curry } from "ramda"
import { isValidInteger, localized, validations } from "js/includes/common/utils"

/**
 * Builds a Moment instance from the seconds given.
 *
 * Note that calling getTime(123).hours() will return a value between 0 and 23.
 *
 * @param {number} seconds Seconds.
 * @returns a Moment instance.
 */
export const getTime = seconds => {
  return moment()
    .hour(0)
    .minute(0)
    .second(seconds)
}

const getDuration = seconds => {
  return moment.duration(seconds, "seconds")
}

/**
 * Get the number of hours and minutes from the given seconds.
 *
 * @param {number} seconds Seconds.
 * @returns an object like { hours, seconds }.
 * - hours is greater than or equal to 0
 * - minutes is in the range [0, 59]
 */
export const getHoursAndMinutes = seconds => {
  const time = getTime(seconds)
  const duration = getDuration(seconds - time.minutes() * 60 - time.seconds())
  return {
    hours: duration.asHours(),
    minutes: time.minutes(),
  }
}

const formatDisplaySeconds = seconds => {
  const { hours, minutes } = getHoursAndMinutes(seconds)
  const hoursText = hours === 1 ? localized("1 hour") : localized("{{hours}} hours", { hours })
  const minutesText = minutes === 1 ? localized("1 minute") : localized("{{minutes}} minutes", { minutes })

  return localized("{{hoursText}} and {{minutesText}}", { hoursText, minutesText })
}

export const defaultToZero = defaultTo(0)

/**
 * Formats seconds to hours and minutes. Hours can be any number greater than or equal to 0, minutes is in the range [0, 23].
 *
 * @param {number} seconds Seconds.
 * @returns an message like "100 Hours and 34 Minutes .
 */
export const getFormattedDisplayTime = compose(formatDisplaySeconds, defaultToZero)

export const sortByCreateTime = curry((sortOrder, data) =>
  sortWith([compose(ifElse(equals("DESC"), always(descend), always(ascend))(sortOrder), prop)("createTime")], data),
)

export const validateTime = ({ max, value }) => {
  const min = 0
  const number = parseFloat(value)
  const isValidNumber = isValidInteger(number) && validations.isValidIntegerWithinRange(min, max, number).success

  return {
    success: isValidNumber,
    message: isValidNumber ? "" : localized("Value must be an integer between {{min}} and {{max}}", { min, max }),
  }
}
