import { getPsaConfiguration } from "js/includes/common/client"
import { NinjaResponseError } from "js/includes/common/types"
import { isPsaGenericWebhook, localizationKey, reportErrorAndShowMessage, user } from "js/includes/common/utils"

const setConnection = payload => ({
  type: "SET_GW_CONNECTION",
  payload,
})

export const getGenericWebhookConnection = () => async (dispatch, getState) => {
  try {
    if (!user("canConfigurePsa")) {
      return
    }

    const response = await getPsaConfiguration()

    const { resultCode, content } = response
    if (resultCode !== "SUCCESS") {
      throw new NinjaResponseError(response)
    }

    const { integrationName, integrationDescription, enabled, psaType } = content || {}

    dispatch(
      setConnection({
        ...(isPsaGenericWebhook(psaType) && {
          integrationName,
          integrationDescription,
          enabled,
        }),
      }),
    )
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching PSA/ITSM configuration"))
  }
}
