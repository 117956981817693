import { memo, useState } from "react"
import { NavLink, Link } from "react-router-dom"
import { css } from "@emotion/react"
import { sizer } from "@ninjaone/utils"
import { Tooltip, useAccessibleActionIcon } from "@ninjaone/components"
import SubNavItemContent from "./SubNavItemContent"

export const linkStyle = theme => css`
  display: flex;
  align-items: center;
  padding-left: ${sizer(2)};
  padding-right: ${sizer(2)};
  border-radius: 2px;

  && {
    color: ${theme.colorThemeText};
  }

  &:hover {
    background-color: ${theme.colorThemeBackgroundHover};
  }

  &:not(:focus-visible):not(:focus-within):not(:hover) {
    .action-icons {
      visibility: hidden;
    }
  }

  &.active {
    color: ${theme.colorThemeTextStrong};
  }

  &:focus-visible {
    background-color: ${theme.colorThemeBackgroundHover};
    outline: 2px solid ${theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

export const actionIconWrapperStyle = css`
  display: flex;
  gap: ${sizer(3)};
  margin-left: auto;
  font-size: initial; // reset back so font-awesome sizes work as expected
`

export const actionIconStyle = theme => css`
  display: flex;
  align-items: center;
  height: 16px;

  &:focus-visible {
    outline: 2px solid ${theme.colorForegroundFocus};
  }
`

const contentStyle = css`
  padding-right: ${sizer(2)};
`

const SubNavLink = memo(({ href = "", icon, name, actionIcons, usesNavLink = true, linkRef }) => {
  const to = href.startsWith("#") ? href.substr(1) : href
  const LinkItem = usesNavLink ? NavLink : Link

  const { actionIconsRenderer, parentElementProps } = useAccessibleActionIcon({
    actionIcons,
    actionIconStyle,
    showActionIconOnHover: true,
  })
  const [textOverflows, setTextOverflows] = useState(false)

  const linkContent = (
    <LinkItem {...{ ...parentElementProps, to, css: linkStyle, "aria-label": name, ...(linkRef && { ref: linkRef }) }}>
      <SubNavItemContent {...{ icon, name, contentStyle, actionIcons, onTextOverflow: setTextOverflows }} />
      <div css={actionIconWrapperStyle} className="action-icons">
        {actionIconsRenderer?.()}
      </div>
    </LinkItem>
  )

  return textOverflows ? (
    <Tooltip
      {...{
        label: name,
        sideOffset: 28,
        align: "center",
        position: "left",
      }}
    >
      {linkContent}
    </Tooltip>
  ) : (
    linkContent
  )
})

export default SubNavLink
