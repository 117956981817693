import { ResponsiveContainer } from "recharts"
import { formatWidthOrHeightForRecharts } from "../utils"

export const useAreaChartSizing = ({ width, height }) => {
  const formattedWidth = formatWidthOrHeightForRecharts(width)
  const formattedHeight = formatWidthOrHeightForRecharts(height)
  const usesResponsiveContainer = typeof formattedWidth !== "number" || typeof formattedHeight !== "number"

  return {
    AreaChartSizingContainer: usesResponsiveContainer
      ? ({ children }) => (
          <ResponsiveContainer width={formattedWidth} height={formattedHeight}>
            {children}
          </ResponsiveContainer>
        )
      : ({ children }) => children,
    chartShouldHandleOwnSize: !usesResponsiveContainer,
    chartWidth: formattedWidth,
    chartHeight: formattedHeight,
  }
}
