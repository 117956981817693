import showModal from "js/includes/common/services/showModal"
import GettingStartedVideoModal from "js/includes/GetStartedPage/components/GettingStartedVideoModal"

const showGettingStartedVideoModal = ({
  modalDescriptionToken,
  modalDescriptionRenderer,
  modalSize = "lg",
  modalTitleToken,
  modalTitleRenderer,
  unmount = () => {},
  video,
}) =>
  showModal(
    <GettingStartedVideoModal
      {...{
        modalDescriptionToken,
        modalDescriptionRenderer,
        modalSize,
        modalTitleToken,
        modalTitleRenderer,
        unmount,
        video,
      }}
    />,
  )

export default showGettingStartedVideoModal
