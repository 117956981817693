import MediaPlayer from "@ninjaone/components/src/MediaPlayer"
import { localized } from "js/includes/common/utils"

const mediaUrl = "https://resources.ninjarmm.com/webapp"

export const GetStartedVideo = ({ activeStep, onTaskCompleted }) => (
  <MediaPlayer
    onInitialPlay={() => onTaskCompleted?.(activeStep)}
    poster={`${mediaUrl}/media/GetStarted/get_started_with_ninjaone_thumb.jpg`}
    title={localized("Get started")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/GetStarted/get_started_with_ninjaone_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/GetStarted/get_started_with_ninjaone_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/GetStarted/get_started_with_ninjaone_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/GetStarted/get_started_with_ninjaone_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/GetStarted/get_started_with_ninjaone_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
      {
        src: `${mediaUrl}/media/GetStarted/get_started_with_ninjaone_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)

export const technicianVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/CreateTechnician/create_a_technician_thumb.jpg`}
    title={localized("Create a technician")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/CreateTechnician/create_a_technician_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/CreateTechnician/create_a_technician_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/CreateTechnician/create_a_technician_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/CreateTechnician/create_a_technician_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/CreateTechnician/create_a_technician_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
      {
        src: `${mediaUrl}/media/CreateTechnician/create_a_technician_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)

export const endUserVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/EndUserSharing/end_user_sharing_thumb.jpg`}
    title={localized("End user sharing")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/EndUserSharing/end_user_sharing_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      { src: `${mediaUrl}/media/EndUserSharing/end_user_sharing_240.mp4`, type: "video/mp4", size: 240 },
      { src: `${mediaUrl}/media/EndUserSharing/end_user_sharing_360.mp4`, type: "video/mp4", size: 360 },
      { src: `${mediaUrl}/media/EndUserSharing/end_user_sharing_540.mp4`, type: "video/mp4", size: 540 },
      { src: `${mediaUrl}/media/EndUserSharing/end_user_sharing_720.mp4`, type: "video/mp4", size: 720 },
      {
        src: `${mediaUrl}/media/EndUserSharing/end_user_sharing_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)

export const windowsInstallVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/WindowsInstall/install_ninjaone_on_a_windows_device_thumb.jpg`}
    title={localized("Install on Windows")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/WindowsInstall/install_ninjaone_on_a_windows_device_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/WindowsInstall/install_ninjaone_on_a_windows_device_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/WindowsInstall/install_ninjaone_on_a_windows_device_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/WindowsInstall/install_ninjaone_on_a_windows_device_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/WindowsInstall/install_ninjaone_on_a_windows_device_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
      {
        src: `${mediaUrl}/media/WindowsInstall/install_ninjaone_on_a_windows_device_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)

export const macInstallVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/MacInstall/install_ninjaone_on_a_mac_device_thumb.jpg`}
    title={localized("Install on Mac")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/MacInstall/install_ninjaone_on_a_mac_device_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/MacInstall/install_ninjaone_on_a_mac_device_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/MacInstall/install_ninjaone_on_a_mac_device_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/MacInstall/install_ninjaone_on_a_mac_device_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/MacInstall/install_ninjaone_on_a_mac_device_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
    ]}
  />
)

export const linuxInstallVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/LinuxInstall/install_ninjaone_on_a_linux_device_thumb.jpg`}
    title={localized("Install on Linux")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/LinuxInstall/install_ninjaone_on_a_linux_device_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/LinuxInstall/install_ninjaone_on_a_linux_device_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/LinuxInstall/install_ninjaone_on_a_linux_device_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/LinuxInstall/install_ninjaone_on_a_linux_device_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/LinuxInstall/install_ninjaone_on_a_linux_device_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
    ]}
  />
)

export const appleInstallVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/AppleInstall/apple_mdm_add_an_apple_mobile_device_thumb.jpg`}
    title={localized("Install on iOS")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/AppleInstall/apple_mdm_add_an_apple_mobile_device_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/AppleInstall/apple_mdm_add_an_apple_mobile_device_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/AppleInstall/apple_mdm_add_an_apple_mobile_device_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/AppleInstall/apple_mdm_add_an_apple_mobile_device_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/AppleInstall/apple_mdm_add_an_apple_mobile_device_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
      {
        src: `${mediaUrl}/media/AppleInstall/apple_mdm_add_an_apple_mobile_device_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)

export const androidInstallVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/AndroidInstall/android_mdm_add_an_android_mobile_device_thumb.jpg`}
    title={localized("Install on Android")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/AndroidInstall/android_mdm_add_an_android_mobile_device_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/AndroidInstall/android_mdm_add_an_android_mobile_device_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/AndroidInstall/android_mdm_add_an_android_mobile_device_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/AndroidInstall/android_mdm_add_an_android_mobile_device_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/AndroidInstall/android_mdm_add_an_android_mobile_device_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
      {
        src: `${mediaUrl}/media/AndroidInstall/android_mdm_add_an_android_mobile_device_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)

export const createPolicyVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/CreatePolicy/create_a_policy_thumb.jpg`}
    title={localized("Create a policy")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/CreatePolicy/create_a_policy_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      { src: `${mediaUrl}/media/CreatePolicy/create_a_policy_240.mp4`, type: "video/mp4", size: 240 },
      { src: `${mediaUrl}/media/CreatePolicy/create_a_policy_360.mp4`, type: "video/mp4", size: 360 },
      { src: `${mediaUrl}/media/CreatePolicy/create_a_policy_540.mp4`, type: "video/mp4", size: 540 },
      { src: `${mediaUrl}/media/CreatePolicy/create_a_policy_720.mp4`, type: "video/mp4", size: 720 },
      { src: `${mediaUrl}/media/CreatePolicy/create_a_policy_1080.mp4`, type: "video/mp4", size: 1080 },
    ]}
  />
)

export const createOrgVideo = (
  <MediaPlayer
    poster={`${mediaUrl}/media/CreateOrg/create_an_organization_thumb.jpg`}
    title={localized("Create a policy")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/CreateOrg/create_an_organization_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      { src: `${mediaUrl}/media/CreateOrg/create_an_organization_240.mp4`, type: "video/mp4", size: 240 },
      { src: `${mediaUrl}/media/CreateOrg/create_an_organization_360.mp4`, type: "video/mp4", size: 360 },
      { src: `${mediaUrl}/media/CreateOrg/create_an_organization_540.mp4`, type: "video/mp4", size: 540 },
      { src: `${mediaUrl}/media/CreateOrg/create_an_organization_720.mp4`, type: "video/mp4", size: 720 },
      {
        src: `${mediaUrl}/media/CreateOrg/create_an_organization_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)

export const backupSetup = (
  <MediaPlayer
    poster={`${mediaUrl}/media/BackupSetup/ninjaone_backup_setup_thumb.jpg`}
    title={localized("Backup setup")}
    tracks={[
      {
        label: "English",
        srclang: "en",
        src: `${mediaUrl}/media/BackupSetup/ninjaone_backup_setup_captions_en.vtt`,
        kind: "captions",
      },
    ]}
    sources={[
      {
        src: `${mediaUrl}/media/BackupSetup/ninjaone_backup_setup_240.mp4`,
        type: "video/mp4",
        size: 240,
      },
      {
        src: `${mediaUrl}/media/BackupSetup/ninjaone_backup_setup_360.mp4`,
        type: "video/mp4",
        size: 360,
      },
      {
        src: `${mediaUrl}/media/BackupSetup/ninjaone_backup_setup_540.mp4`,
        type: "video/mp4",
        size: 540,
      },
      {
        src: `${mediaUrl}/media/BackupSetup/ninjaone_backup_setup_720.mp4`,
        type: "video/mp4",
        size: 720,
      },
      {
        src: `${mediaUrl}/media/BackupSetup/ninjaone_backup_setup_1080.mp4`,
        type: "video/mp4",
        size: 1080,
      },
    ]}
  />
)
