export const macExclusionsList = [
  "/Applications",
  "/Library",
  "/Private",
  "/System",
  "/bin",
  "/dev",
  "/etc",
  "/net",
  "/sbin",
  "/usr",
  "/tmp",
  "/var",
  "/opt",
  "/Network",
  "/Home",
  "/Volumes",
  "/.app",
  "/.DS_Store",
  "/.fseventsd",
  "/.hotfiles.btree",
  "/.Spotlight",
  "/.Trashes",
  "/.vol",
  "/automount",
  "/cores",
  "/Desktop DB",
  "/Desktop DF",
  "/mach",
  "/mach.sym",
  "/mach_kernel",
  "/net",
  "/sbin",
  "/sw/",
  "/Users/*/Library",
  "/Users/*/Applications",
  "/Users/*/Parallels",
  "/Users/*/Virtual Machines",
  "/Users/*/.config/Carbonite",
  "/Users/*/.Trash",
]

export const advancedExcludedApps = [
  "Dropbox",
  "Google Drive",
  "Box",
  "OneDrive",
  "Amazon",
  "IDrive",
  "Egnyte",
  "Wasabi",
  "Citrix Content Collaboration",
  "Sync.community",
  "pCloud",
  "Tresorit",
  "MEGA",
  "Koofr",
  "SpiderOak",
  "BigMind",
  "JungleDisk",
  "Livedrive",
  "Degoo",
  "MediaFire",
]
