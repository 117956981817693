import qs from "qs"

import { fetchJson, localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

export const buildMentionFetch = clientId => async queryString => {
  try {
    const _qs = qs.stringify({
      searchCriteria: queryString,
      pageSize: 20,
      userType: "TECHNICIAN",
      ...(clientId && { clientId }),
    })
    return await fetchJson(`/app-user-contact/search?${_qs}`)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching mentions"))
  }
  return []
}
