import React from "react"
import PropTypes from "prop-types"
import { nanoid } from "nanoid"

const Select = ({
  options,
  defaultOption,
  defaultOptionValue,
  onChange,
  value,
  id,
  name,
  className,
  style,
  disabled,
}) => (
  <select
    {...{ onChange, value, id, className: `form-control ${className}`, style, disabled, name, "aria-label": name }}
  >
    {defaultOption && (
      <option key={nanoid(10)} value={defaultOptionValue || ""}>
        {defaultOption}
      </option>
    )}
    {options.map(o => (
      <option key={nanoid(10)} value={o.id}>
        {typeof o.value === "function" ? o.value() : o.value}
      </option>
    ))}
  </select>
)

export default Select

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.any,
  defaultOption: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
}
