import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"

export const StyledEditorScroller = styled.div`
  min-height: 150px;
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  overflow: auto;
  resize: vertical;
`

export const StyledEditor = styled.div`
  flex: auto;
  position: relative;
  resize: vertical;
  z-index: -1;
`

export const StyledEditorShell = styled.div`
  position: relative;
  line-height: ${tokens.typography.lineHeight};
  font-weight: ${tokens.typography.fontWeight.regular};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-top: none;
  border-bottom-left-radius: ${tokens.borderRadius[1]};
  border-bottom-right-radius: ${tokens.borderRadius[1]};
`

export const StyledEditorContainer = styled.div`
  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  position: relative;
  display: block;

  .editor-block-cursor {
    display: block;
    pointer-events: none;
    position: absolute;
  }

  .editor-block-cursor:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
    border-top: 1px solid ${({ theme }) => theme.colorTextStrong};
    animation: CursorBlink 1.1s steps(2, start) infinite;
  }

  @keyframes CursorBlink {
    to {
      visibility: hidden;
    }
  }

  /* Reset */
  ul,
  ol {
    list-style-type: revert;
  }
  em {
    display: inline;
  }
  a {
    color: ${({ theme }) => theme.colorTextAction};

    &:hover {
      color: ${({ theme }) => theme.colorTextActionStrong};
    }
  }

  h1 {
    font-size: ${tokens.typography.fontSize.headingL};
    font-weight: ${tokens.typography.fontWeight.medium};
  }

  h2 {
    font-size: ${tokens.typography.fontSize.headingM};
    font-weight: ${tokens.typography.fontWeight.medium};
  }

  h3 {
    font-size: ${tokens.typography.fontSize.headingS};
    font-weight: ${tokens.typography.fontWeight.medium};
  }

  h4 {
    font-size: ${tokens.typography.fontSize.headingXs};
    font-weight: ${tokens.typography.fontWeight.medium};
  }

  /* List Items */
  .editor-ol1 {
    padding: 0;
    margin: 0;
    list-style-position: outside;
  }
  .editor-ol2 {
    padding: 0;
    margin: 0;
    list-style-type: upper-alpha;
    list-style-position: outside;
  }
  .editor-ol3 {
    padding: 0;
    margin: 0;
    list-style-type: lower-alpha;
    list-style-position: outside;
  }
  .editor-ol4 {
    padding: 0;
    margin: 0;
    list-style-type: upper-roman;
    list-style-position: outside;
  }
  .editor-ol5 {
    padding: 0;
    margin: 0;
    list-style-type: lower-roman;
    list-style-position: outside;
  }

  .editor-ul {
    padding: 0;
    margin: 0;
    list-style-position: outside;
  }

  .editor-list-item {
    margin: 0 ${tokens.spacing[8]};
  }

  .editor-nested-list-item {
    list-style-type: none;
  }

  .editor-nested-list-item:before,
  .editor-nested-list-item:after {
    display: none;
  }

  /* Image */
  span.editor-image {
    cursor: default;
    display: inline-block;
    position: relative;
    user-select: none;
    z-index: 1;
  }

  .editor-image img {
    max-width: 100%;
    cursor: default;
  }

  .editor-image img.focused {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    user-select: none;
  }

  /* Drag */
  .editor-image img.focused.draggable {
    cursor: grab;
  }

  .editor-image img.focused.draggable:active {
    cursor: grabbing;
  }

  /* Image resizer */
  .editor-image .image-resizer {
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: rgb(60, 132, 244);
    border: 1px solid #fff;
  }

  .editor-image .image-resizer.image-resizer-n {
    top: -6px;
    left: 48%;
    cursor: n-resize;
  }

  .editor-image .image-resizer.image-resizer-ne {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
  }

  .editor-image .image-resizer.image-resizer-e {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
  }

  .editor-image .image-resizer.image-resizer-se {
    bottom: -6px;
    right: -6px;
    cursor: nwse-resize;
  }

  .editor-image .image-resizer.image-resizer-s {
    bottom: -6px;
    left: 48%;
    cursor: s-resize;
  }

  .editor-image .image-resizer.image-resizer-sw {
    bottom: -6px;
    left: -6px;
    cursor: sw-resize;
  }

  .editor-image .image-resizer.image-resizer-w {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
  }

  .editor-image .image-resizer.image-resizer-nw {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
  }

  /* Text Styles */
  .editor-rtl {
    text-align: right;
  }

  .editor-ltr {
    text-align: left;
  }

  .editor-paragraph {
    margin: 0;
    position: relative;
  }

  .editor-text-code {
    padding: 1px 2px;
    border-radius: ${tokens.borderRadius[1]};
    font-size: ${tokens.typography.fontSize.bodyXs};
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
    border: 1px solid ${({ theme }) => theme.colorBorderStrong};
    color: ${({ theme }) => theme.colorTextDanger};
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-strikethrough {
    text-decoration: line-through;
  }

  .editor-text-subscript {
    vertical-align: sub;
    font-size: smaller;
  }

  .editor-text-superscript {
    vertical-align: super;
    font-size: smaller;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-text-underline-strikethrough {
    text-decoration: underline line-through;
  }

  .editor-code {
    display: block;
    color: unset;
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
    font-family: ${tokens.typography.fontFamily.code};
    padding: ${tokens.spacing[2]} ${tokens.spacing[2]} ${tokens.spacing[2]} ${tokens.spacing[9]};
    line-height: ${tokens.typography.lineHeight};
    font-size: ${tokens.typography.fontSize.bodyXs};
    margin: 0;
    margin-top: ${tokens.spacing[2]};
    margin-bottom: ${tokens.spacing[2]};
    overflow-x: auto;
    position: relative;
    white-space: unset;
    tab-size: 2;
  }

  .editor-code:before {
    content: attr(data-gutter);
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};
    position: absolute;
    left: 0;
    top: 0;
    border-right: 1px solid ${({ theme }) => theme.colorBorderStrong};
    padding: ${tokens.spacing[2]};
    color: ${({ theme }) => theme.colorTextStrong};
    white-space: pre-wrap;
    text-align: right;
    min-width: ${tokens.spacing[6]};
  }

  .editor-quote {
    margin: 0;
    margin-left: ${tokens.spacing[5]};
    margin-bottom: ${tokens.spacing[2]};
    font-size: ${tokens.typography.fontSize.body};
    color: ${({ theme }) => theme.colorTextWeakest};
    border-left-color: ${({ theme }) => theme.colorBorderWeakest};
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: ${tokens.spacing[4]};
  }

  .editor-token-comment {
    color: slategray;
  }

  .editor-token-punctuation {
    color: #999;
  }

  .editor-token-property {
    color: #905;
  }

  .editor-token-selector {
    color: #690;
  }

  .editor-token-operator {
    color: #9a6e3a;
  }

  .editor-token-attr {
    color: #07a;
  }

  .editor-token-variable {
    color: #e90;
  }

  .editor-token-function {
    color: #dd4a68;
  }

  /* Table */

  .editor-table {
    border-collapse: collapse;
    border-spacing: 0;
    overflow-y: scroll;
    overflow-x: scroll;
    table-layout: fixed;
    width: max-content;
    margin: ${tokens.spacing[7]} 0;
  }

  .editor-table-selection *::selection {
    background-color: transparent;
  }

  .editor-table-cell {
    border: 1px solid ${({ theme }) => theme.colorBorderWeak};
    width: 75px;
    min-width: 75px;
    vertical-align: top;
    text-align: start;
    padding: ${tokens.spacing[1]} ${tokens.spacing[2]};
    position: relative;
    outline: none;
  }

  .editor-table-cell-header {
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
    text-align: start;
  }
`

export const StyledHr = styled.hr`
  margin: ${tokens.spacing[1]} 0;
  background-color: ${({ theme }) => theme.colorBorderWeakest};
`

export const StyledHiddenInput = styled.input`
  &&& {
    display: none;
  }
`
