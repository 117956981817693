import { compose, evolve, isEmpty, omit } from "ramda"
import { v4 as uuidv4 } from "uuid"

import { getAllStatuses, getAllStatusesForEndUser, getTicketingForms } from "js/includes/common/client"
import {
  fetch,
  fetchJson,
  getLocalStorageWithExpiry,
  isNumber,
  isUserAllowedToViewPendingEmails,
  limitLocalStorageEntries,
  localizationKey,
  ninjaReportError,
  reportErrorAndShowMessage,
  setLocalStorageWithExpiry,
  showSuccessMessage,
} from "js/includes/common/utils"
import { fetchBoardsConfiguration as _fetchBoardsConfiguration } from "js/state/actions/general/boardsConfiguration"
import { fetchTriggerBoardTabs } from "js/state/actions/general/boardTabs"

const ticketingConfigEndpoint = "/divisionconfig/ninja_ticketing"
const ticketingDomainEndpoint = "/division/ticketing-email-domain"
const ticketingSMTPHealthEndpoint = "/divisionconfig/NINJA_TICKETING/status"

export const requestTicketingDomain = () => async dispatch => {
  try {
    const response = await fetchJson(ticketingDomainEndpoint)
    dispatch({
      type: "TICKETING_SET_CONFIGURATIONS",
      payload: response,
    })
  } catch (error) {
    if (error.response.status !== 404) {
      reportErrorAndShowMessage(error)
    }
  }
}

export const requestSMTPHealth = () => async dispatch => {
  try {
    const response = await fetchJson(ticketingSMTPHealthEndpoint)

    dispatch({
      type: "TICKETING_SET_SMTP_HEALTH",
      payload: response,
    })
  } catch (error) {
    reportErrorAndShowMessage(error, "ticketing.errorWhileFetchingSMTPHealthData")
  }
}

export const updateSMTPHealth = status => dispatch => {
  dispatch({
    type: "TICKETING_UPDATE_SMTP_HEALTH_STATUS",
    status,
  })
}

export const updateTicketingDomain = (payload, shouldUpdateTicketingConfigs = false) => async dispatch => {
  const response = await fetch(ticketingDomainEndpoint, {
    options: {
      method: "PATCH",
      body: JSON.stringify(payload),
    },
  })
  if ([200, 204].includes(response.status)) {
    dispatch({
      type: "TICKETING_SET_CONFIGURATIONS",
      payload,
    })
    if (shouldUpdateTicketingConfigs) {
      dispatch(updateTicketingConfigs({ enabled: true }, "PATCH"))
    }
  } else {
    throw response
  }
}

export const requestTicketingConfigs = ({
  fetchBoards = false,
  fetchDomain = false,
  fetchBoardsConfiguration = false,
  fetchSMTPHealth = false,
} = {}) => async dispatch => {
  const response = await fetchJson(ticketingConfigEndpoint)

  if (!isEmpty(response)) {
    dispatch({
      type: "TICKETING_SET_CONFIGURATIONS",
      payload: response,
    })

    if (fetchDomain) {
      dispatch(requestTicketingDomain())
    }

    if (fetchBoardsConfiguration) {
      dispatch(_fetchBoardsConfiguration)
    }

    if (fetchBoards) {
      dispatch(fetchTriggerBoardTabs)
    }

    if (fetchSMTPHealth && response.smtp?.enabled) {
      dispatch(requestSMTPHealth())
    }
  }
}

const cleanUpTicketingConfigs = compose(
  evolve({
    smtp: ({ enabled, smtpSettings }) => ({
      enabled,
      smtpSettings: smtpSettings ? omit(["typeAuthentication"], smtpSettings) : null,
    }),
  }),
  omit(["domain", "fromEmailDomain", "useFromEmailDomain"]),
)

export const updateTicketingConfigs = (content, method = "PUT") => async dispatch => {
  try {
    await fetchJson(ticketingConfigEndpoint, {
      options: {
        method,
        body: JSON.stringify(cleanUpTicketingConfigs(content)),
      },
    })
    showSuccessMessage()
    dispatch(requestTicketingConfigs({ fetchBoards: content.enabled, fetchSMTPHealth: true }))
  } catch (error) {
    throw error
  }
}

export const saveNewTicket = (ticket, editorData) => dispatch => {
  dispatch({
    type: "SAVE_NEW_TICKET",
    ticket,
    editorData,
  })
}

export const removeTempTicket = tempTicketId => dispatch => {
  dispatch({
    type: "REMOVE_NEW_TICKET",
    tempTicketId,
  })
}

export const updateNewTicket = ({ id: tempTicketId, ...payload }) => dispatch => {
  dispatch({
    type: "UPDATE_NEW_TICKET",
    tempTicketId,
    payload,
  })
}

export const updateNewTicketEditorData = (tempTicketId, payload) => dispatch => {
  dispatch({
    type: "UPDATE_NEW_TICKET_EDITOR_DATA",
    tempTicketId,
    payload,
  })
}

export const addNewTicketEditorDataUpload = ({ tempTicketId, ...payload }) => dispatch => {
  dispatch({
    type: "ADD_NEW_TICKET_EDITOR_DATA_UPLOAD",
    tempTicketId,
    payload,
  })
}

export const removeNewTicketEditorDataUpload = ({ tempTicketId, resourceId }) => dispatch => {
  dispatch({
    type: "REMOVE_NEW_TICKET_EDITOR_DATA_UPLOAD",
    tempTicketId,
    resourceId,
  })
}

const SAVED_TICKET_TIMERS_LIMIT = 100
const TICKET_TIMER_TTL_IN_MILLIS = 86400000

export const setTicketTimer = (ticketId, timerSets, isRunning) => (dispatch, getState) => {
  const ticketTimerKey = `ticket-${getState()?.session?.user?.externalUid}-${ticketId}`
  if (isEmpty(timerSets)) {
    window.localStorage.removeItem(ticketTimerKey)
  } else if (isNumber(ticketId)) {
    limitLocalStorageEntries("ticket-", SAVED_TICKET_TIMERS_LIMIT)
    setLocalStorageWithExpiry(
      ticketTimerKey,
      {
        isRunning,
        timerSets: timerSets.map(timerSet => ({ start: timerSet.start.getTime(), stop: timerSet.stop?.getTime() })),
      },
      TICKET_TIMER_TTL_IN_MILLIS,
    )
  }
  dispatch({
    type: "SET_TIMER",
    ticketId,
    timerSets,
    isRunning,
  })
}

export const setRequestUid = () => dispatch => {
  dispatch({
    type: "SET_REQUEST_UID",
    requestUid: uuidv4(),
  })
}

export const addToOpenTickets = (ticket, editorData) => dispatch => {
  dispatch({
    type: "ADD_TO_OPEN_TICKETS",
    ticket,
    editorData,
  })
}

export const updateOpenTicket = ({ id: ticketId, ...payload }, shouldUpdateOriginal) => dispatch => {
  dispatch({
    type: "UPDATE_OPEN_TICKET",
    ticketId,
    payload,
    shouldUpdateOriginal,
  })
}

export const removeOpenTicket = ticketId => dispatch => {
  dispatch({
    type: "REMOVE_OPEN_TICKET",
    ticketId,
  })
}

export const updateEditorData = ({ ticketId, ...payload }) => dispatch => {
  dispatch({
    type: "UPDATE_EDITOR_DATA",
    ticketId,
    payload,
  })
}

export const addEditorDataUpload = ({ ticketId, ...payload }) => dispatch => {
  dispatch({
    type: "ADD_EDITOR_DATA_UPLOAD",
    ticketId,
    payload,
  })
}

export const removeEditorDataUpload = ({ ticketId, resourceId }) => dispatch => {
  dispatch({
    type: "REMOVE_EDITOR_DATA_UPLOAD",
    ticketId,
    resourceId,
  })
}

export const deleteUserFromOpenTickets = payload => ({
  type: "DELETE_USER_FROM_OPEN_TICKETS",
  payload,
})

export const requestPendingEmailsCount = () => async dispatch => {
  if (!isUserAllowedToViewPendingEmails()) return

  try {
    const { ticketCount } = await fetchJson("/ticketing/pending-email/count")

    dispatch({
      type: "TICKETING_SET_PENDING_EMAIL_COUNT",
      ticketCount,
    })
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error refreshing pending emails count"))
  }
}

export const fetchTicketForms = () => async dispatch => {
  try {
    dispatch({
      type: "TICKETING_SET_TICKET_FORMS_LOADING",
      loading: true,
    })

    const response = await getTicketingForms()

    dispatch({
      type: "TICKETING_SET_TICKET_FORMS_LIST",
      list: response ?? [],
    })
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching ticket forms"))
  } finally {
    dispatch({
      type: "TICKETING_SET_TICKET_FORMS_LOADING",
      loading: false,
    })
  }
}

export const fetchTicketStatuses = (isEndUser = false) => async dispatch => {
  try {
    const statusResponse = isEndUser ? await getAllStatusesForEndUser() : await getAllStatuses()

    dispatch({
      type: "TICKETING_SET_TICKET_STATUSES",
      payload: statusResponse,
    })
  } catch (error) {
    reportErrorAndShowMessage(error, "ticketing.errorFetchingStatusList")
  }
}

function parseTicketTimer(data) {
  const parsedTimerSets = []
  data?.timerSets?.forEach((timerSet, index) => {
    const start = timerSet.start && new Date(timerSet.start)
    if (!isNaN(start)) {
      const stop = timerSet.stop && new Date(timerSet.stop)
      parsedTimerSets.push({ start, stop: !isNaN(stop) ? stop : null })
    }
  })
  if (parsedTimerSets.length) {
    data.timerSets = parsedTimerSets
    return data
  }
}

function getInitialTimersState(externalUid) {
  try {
    const timerPrefix = `ticket-${externalUid}-`
    return Object.keys(window.localStorage).reduce((timers, key) => {
      if (key.startsWith(timerPrefix)) {
        const ticketId = key.slice(timerPrefix.length)
        const timer = parseTicketTimer(getLocalStorageWithExpiry(key))
        if (timer) {
          timers[ticketId] = timer
        }
      }
      return timers
    }, {})
  } catch (error) {
    ninjaReportError(error)
  }
}

export const initTimers = externalUid => dispatch =>
  dispatch({
    type: "INIT_TIMERS",
    payload: getInitialTimersState(externalUid),
  })
