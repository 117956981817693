import { useTheme } from "@emotion/react"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import tokens from "@ninjaone/tokens"

const EmptyAreaChartLabel = ({ x, y }) => {
  const theme = useTheme()

  return (
    <text
      {...{
        x,
        y,
        dominantBaseline: "middle",
        dy: ".45em",
        textAnchor: "middle",
        fill: theme.colorTextWeakest,
        fontSize: tokens.typography.fontSize.body,
      }}
    >
      {localized("No data")}
    </text>
  )
}
export default EmptyAreaChartLabel
