import { ninjaReportError, showErrorMessage, fetchJson, localized } from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"

export async function getCredential({ id }) {
  const localizedError = localized("Error loading credentials")

  try {
    const response = await fetchJson(`/credential/${id}`)
    if (response.resultCode === "SUCCESS") {
      return response.credential
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localizedError)
    ninjaReportError(error.errorMessage)
  }
}

export async function getOrganizationCredentials(clientId) {
  const localizedError = localized("Error loading credentials")

  try {
    const response = await fetchJson(`/credential/organization/${clientId}/list`)
    if (response.resultCode === "SUCCESS") {
      return response.credentials
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localizedError)
    ninjaReportError(error.errorMessage)
    return []
  }
}

export const fetchCredentials = clientId => async dispatch => {
  const credentials = await getOrganizationCredentials(clientId)
  dispatch({
    type: "SET_CREDENTIALS",
    credentials,
  })
}

export async function deleteCredential(id) {
  const localizedError = localized("Error deleting credential")

  try {
    const response = await fetchJson(`/credential/${id}`, { options: { method: "DELETE" } })
    if (response.resultCode !== "SUCCESS") {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    showErrorMessage(localizedError)
    ninjaReportError(error.errorMessage)
  }
}

export function saveNmsCredentials(credentials) {
  return async dispatch => {
    const localizedError = localized("nmsDashboard.settings.credentialSectionModel.errorSettingCredential")

    try {
      const response = await fetchJson("/webapp/nodecredentials", {
        options: { method: "POST", body: JSON.stringify({ credentials }) },
      })
      if (response.resultCode !== "SUCCESS") {
        showErrorMessage(localizedError)
      }
    } catch (error) {
      showErrorMessage(localizedError)
      throw error
    }
  }
}
