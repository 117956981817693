import { Modal, Tabs } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import { localizationKey } from "js/includes/common/utils"
import { SearchAppsForm } from "./SearchAppsForm"

export const appTypeLabelTokens = {
  vpp: localizationKey("Apps and Books"),
  store: localizationKey("Public App Store"),
}

export const AddIOSAppModal = ({ unmount, onAddApp, type, showVPP = false }) => {
  return (
    <Modal size="lg" {...{ unmount }} titleGroup={{ titleToken: localizationKey("Add apps") }}>
      {showVPP ? (
        <Tabs
          tabs={[
            {
              key: "vppAppsTab",
              labelToken: appTypeLabelTokens.vpp,
              renderer: () => (
                <Box marginTop={sizer(4)}>
                  <SearchAppsForm {...{ unmount, onAddApp, type }} isVPP />
                </Box>
              ),
            },
            {
              key: "storeAppsTab",
              labelToken: appTypeLabelTokens.store,
              renderer: () => (
                <Box marginTop={sizer(4)}>
                  <SearchAppsForm {...{ unmount, onAddApp, type }} />
                </Box>
              ),
            },
          ]}
        />
      ) : (
        <SearchAppsForm {...{ unmount, onAddApp, type }} />
      )}
    </Modal>
  )
}
