import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { AlertMessage, Heading, Body } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import { ResourceCard } from "js/includes/GetStartedPage/components/ResourceCard"
import LinksColumn from "./LinksColumn"

const StyledWrapper = styled.div`
  display: grid;
  gap: ${spacing[3]};
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 372px), 1fr));
`

const StepContent = ({ titleToken, descriptionToken, externalLinks, resources, permissionRequired }) => {
  const { name, allowed } = permissionRequired
  return (
    <>
      <Heading level={2} type="headingS">
        {localized(titleToken)}
      </Heading>
      <Flex flexDirection="column" gap={!allowed ? spacing[4] : spacing[6]}>
        <Body color="colorTextWeakest" textWrap>
          {localized(descriptionToken)}
        </Body>
        {!allowed && (
          <AlertMessage variant="warning">
            {localized(
              "You do not have permissions to manage {{permissionName}}. Please reach out to your System Admin for help.",
              { permissionName: name },
            )}
          </AlertMessage>
        )}
        {!!resources?.length && (
          <StyledWrapper>
            {resources.map(props => (
              <ResourceCard {...{ key: props?.titleToken, ...props }} />
            ))}
          </StyledWrapper>
        )}
      </Flex>
      {!!externalLinks?.length && (
        <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
          {externalLinks.map(({ titleToken, items }) => (
            <LinksColumn key={titleToken} {...{ titleToken, items }} />
          ))}
        </Flex>
      )}
    </>
  )
}

export default StepContent
StepContent.propTypes = {
  titleToken: PropTypes.string.isRequired,
  descriptionToken: PropTypes.string,
  externalLinks: PropTypes.array,
  resources: PropTypes.array,
  permissionRequired: PropTypes.shape({ name: PropTypes.string.isRequired, allowed: PropTypes.bool.isRequired })
    .isRequired,
}
