const faConnectWiseControl = {
  prefix: "fac",
  iconName: "connectwise-control",
  icon: [
    834,
    834,
    [],
    "0004",
    "M662.43,761.145l-662.43,0l0,-518.416l662.43,0l0,518.416Zm-579.097,-83.333l495.763,0l0,-351.75l-495.763,0l0,351.75Zm750,-89.472l-97.846,0l0,-418.306l-530.484,0l0,-97.846l628.33,0l0,516.152Z",
  ],
}

export default faConnectWiseControl
