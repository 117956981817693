import { useCallback, useEffect } from "react"
import { nanoid } from "nanoid"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils"
import {
  $createParagraphNode,
  $createRangeSelection,
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  createCommand,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
} from "lexical"

import { CAN_USE_DOM, convertBase64ToFile, extractImageTypeFromBase64, isValidImageFile } from "../../utils"
import { $createImageNode, $isImageNode, ImageNode } from "./Node"

const getDOMSelection = targetWindow => (CAN_USE_DOM ? (targetWindow || window).getSelection() : null)

export const ALLOW_IMAGE_PLUGIN_COMMAND = createCommand("ALLOW_IMAGE_PLUGIN_COMMAND")
export const INSERT_IMAGE_COMMAND = createCommand("INSERT_IMAGE_COMMAND")

/**
 * Must be defined AFTER any plugin that has a dependency with it (e.g. ToolbarPlugin, DragDropPastePlugin, etc.)
 */
export const ImagePlugin = ({ cidKey, onUploadImage, onUploadImageError }) => {
  const [editor] = useLexicalComposerContext()

  const handleUploadImage = useCallback(
    async node => {
      try {
        const src = node.getSrc()
        const type = extractImageTypeFromBase64(src)
        const filename = `${nanoid()}.${type}`
        const file = await convertBase64ToFile(src, filename, `image/${type}`)

        if (isValidImageFile(file)) {
          const response = await onUploadImage(file)
          editor.update(() => {
            node.setCid(response[cidKey])
          })
        }
      } catch (error) {
        onUploadImageError?.(error)
      }
    },
    [editor, cidKey, onUploadImage, onUploadImageError],
  )

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) throw new Error("ImagePlugin: ImageNode not registered on editor")

    editor.dispatchCommand(ALLOW_IMAGE_PLUGIN_COMMAND, true)

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        payload => {
          const imageNode = $createImageNode(payload)
          $insertNodes([imageNode])
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
          }
          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(DRAGSTART_COMMAND, event => onDragStart(event), COMMAND_PRIORITY_HIGH),
      editor.registerCommand(DRAGOVER_COMMAND, event => onDragover(event), COMMAND_PRIORITY_LOW),
      editor.registerCommand(DROP_COMMAND, event => onDrop(event, editor), COMMAND_PRIORITY_HIGH),
    )
  }, [editor])

  useEffect(() => {
    return editor.registerMutationListener(ImageNode, mutatedNodes => {
      for (const [nodeKey, mutation] of mutatedNodes) {
        if (mutation === "created") {
          editor.getEditorState().read(() => {
            const node = $getNodeByKey(nodeKey)
            if (node.getCid() || !node.getSrc()) return

            handleUploadImage(node)
          })
        }
      }
    })
  }, [editor, handleUploadImage])

  return null
}

const TRANSPARENT_IMAGE = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
const img = document.createElement("img")
img.src = TRANSPARENT_IMAGE

function onDragStart(event) {
  const node = getImageNodeInSelection()
  if (!node) return false

  const dataTransfer = event.dataTransfer
  if (!dataTransfer) return false

  dataTransfer.setData("text/plain", "_")
  dataTransfer.setDragImage(img, 0, 0)
  dataTransfer.setData(
    "application/x-lexical-drag",
    JSON.stringify({
      data: {
        key: node.getKey(),
        src: node.__src,
        width: node.__width,
        height: node.__height,
        maxWidth: node.__maxWidth,
      },
      type: "image",
    }),
  )

  return true
}

function onDragover(event) {
  const node = getImageNodeInSelection()
  if (!node) return false

  if (!canDropImage(event)) {
    event.preventDefault()
  }

  return true
}

function onDrop(event, editor) {
  const node = getImageNodeInSelection()
  if (!node) return false

  const data = getDragImageData(event)
  if (!data) return false

  event.preventDefault()

  if (canDropImage(event)) {
    const range = getDragSelection(event)
    node.remove()
    const rangeSelection = $createRangeSelection()
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range)
    }
    $setSelection(rangeSelection)
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data)
  }

  return true
}

function getImageNodeInSelection() {
  const selection = $getSelection()
  if (!$isNodeSelection(selection)) return null

  const nodes = selection.getNodes()
  const node = nodes[0]

  return $isImageNode(node) ? node : null
}

function getDragImageData(event) {
  const dragData = event.dataTransfer?.getData("application/x-lexical-drag")
  if (!dragData) return null

  const { type, data } = JSON.parse(dragData)
  if (type !== "image") return null

  return data
}

function canDropImage(event) {
  const target = event.target
  return !!(
    target &&
    target instanceof HTMLElement &&
    !target.closest("code, span.editor-image") &&
    target.parentElement &&
    target.parentElement.closest("div.editor-root")
  )
}

function getDragSelection(event) {
  let range
  const target = event.target
  const targetWindow =
    target == null ? null : target.nodeType === 9 ? target.defaultView : target.ownerDocument.defaultView
  const domSelection = getDOMSelection(targetWindow)

  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY)
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0)
    range = domSelection.getRangeAt(0)
  } else {
    throw Error("Cannot get the selection when dragging")
  }

  return range
}
