import { equals, always, T, compose, pick, omit, cond, reject, propEq } from "ramda"
import { createSelector } from "reselect"
import { getActiveSearch } from "./activeSearch"
import { isPositiveInteger } from "js/includes/common/utils"
import { getSearchSetForEdit } from "./searchForEdit"
import { getAllDevicesIds } from "js/state/selectors/deviceSearch/filteredDevices"

const getAllCriteria = state => state.criteria

export const getCriteriaForSearch = (criteria, activeSearch) => {
  if (!activeSearch) return []
  return activeSearch.id
    ? criteria.filter(({ search }) => search === activeSearch.id)
    : criteria.filter(({ id, search }) => !search && id < 0)
}

export const getActiveCriteria = createSelector([getAllCriteria, getActiveSearch], getCriteriaForSearch)

// getAllCriteria -> gets all of the division's saved criteria in all searches
// getSearchSetForEdit -> gets current search from saved searches
// getCriteraForSearch -> lookup for the saved criteria with the current search's id
export const getActiveCriteriaForSearchSetForEdit = createSelector(
  [getAllCriteria, getSearchSetForEdit],
  getCriteriaForSearch,
)

export const getCriteriaNotDeleted = reject(propEq("action", "DELETE"))

export const getActiveCriteriaNotDeleted = createSelector([getActiveCriteria], getCriteriaNotDeleted)
export const getActiveCriteriaNotDeletedForSearchSetForEdit = createSelector(
  [getActiveCriteriaForSearchSetForEdit],
  getCriteriaNotDeleted,
)

export const isCriterionNew = criterion => !isPositiveInteger(criterion?.id)

export const isCriterionUntouched = criterion => criterion && isCriterionNew(criterion) && !criterion.action
export const isCriterionTouched = !isCriterionUntouched

export const isCriterionAssignedToSearch = criterion => !!criterion.search

export const getCriterionForRequest = criterion => {
  const ignore = ["action", "initialValues"]
  const commonCriterionField = ["type", "search", "title", "name", "id", "createdOn", "updatedOn"]

  const criterionRequest = compose(omit(ignore), pick(commonCriterionField))(criterion)

  criterionRequest.customFields = JSON.stringify(omit([...commonCriterionField, ...ignore], criterion))
  return criterionRequest
}

export const isCriteriaEqual = (a, b) => a.id === b.id

export const getCriteriaFromResponse = responseCriteria =>
  responseCriteria.map(c => {
    const valueObj = JSON.parse(c.customFields)
    const ret = { ...c, ...valueObj }
    delete ret.customFields
    ret.initialValues = { ...ret }
    return ret
  })

export const getPresetCriteria = cond([
  [equals("ALL"), always([])],
  [equals("UP"), always([{ type: "node-status", selectedValues: ["isUp"] }])],
  [equals("DOWN"), always([{ type: "node-status", selectedValues: ["isDown"] }])],
  [equals("WARNING"), always([{ type: "node-status", selectedValues: ["hasWarnings"] }])],
  [
    equals("teamViewerSearch"),
    always([
      {
        type: "software-inventory",
        selectedValues: [{ name: "TeamViewer", operation: "all", mode: "contains" }],
        contain: "dont",
        include: "all",
        timespanMode: "all",
      },
    ]),
  ],
  [equals("diskSpaceSearch"), always([{ type: "volume-free-space", operation: "lt", value: "10" }])],
  [equals("failedpatchesSearch"), always([{ type: "patch-inventory", selectedValues: [], patchStatuses: ["FAILED"] }])],
  [equals("networkDevicesSearch"), always([{ type: "node-type", selectedValues: ["isNms"] }])],
  [equals("devicesRequiringRebootSearch"), always([{ type: "node-status", selectedValues: ["needReboot"] }])],
  [equals("devicesWithInstallIssuesSearch"), always([{ type: "node-status", selectedValues: ["hasInstallIssue"] }])],
  [
    equals("devicesrequiringRebootForInstallSearch"),
    always([{ type: "needs-reboot", selectedValues: ["BDRC_REBOOT_INSTALL_FAIL"] }]),
  ],
  [
    equals("devicesrequiringRebootForUpgradeEngine"),
    always([{ type: "needs-reboot", selectedValues: ["BDRC_REBOOT_PROD_UPDATE", "BDRC_REBOOT_INSTALL_MODIFICATION"] }]),
  ],
  [
    equals("devicesrequiringRebootForDisinfect"),
    always([{ type: "needs-reboot", selectedValues: ["BDRC_REBOOT_DISINFECT"] }]),
  ],
  [T, always([])],
])

export const getAllCriteriaRequiredForRunningASearch = createSelector(
  [getActiveCriteriaNotDeleted, getActiveSearch],
  (activeCriteriaNotDeleted, activeSearch) =>
    activeCriteriaNotDeleted.length ? activeCriteriaNotDeleted : getPresetCriteria(activeSearch),
)

export const getNodesToFetchActivitiesFor = createSelector(
  [getAllCriteriaRequiredForRunningASearch, getAllDevicesIds],
  (activeCriteria, activityNodes) => (!activeCriteria?.length ? null : activityNodes),
)
