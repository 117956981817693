import { always, complement, compose, concat, cond, equals, indexOf, slice, T } from "ramda"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

export function getReadableAntiVirusScanType(value) {
  var result
  switch (value) {
    case "DEEP":
      result = "Deep Scan"
      break
    case "QUICK":
      result = "Quick Scan"
      break
    case "CUSTOM":
      result = "Custom Scan"
      break
    default:
      result = value
      break
  }
  return result
}

export function getReadablePatchManagementSeverity(value) {
  var result
  switch (value) {
    case "CRITICAL":
      result = "Critical"
      break
    case "IMPORTANT":
      result = "Important"
      break
    case "MODERATE":
      result = "Moderate"
      break
    case "LOW":
      result = "Low"
      break
    case "OPTIONAL":
      result = "Optional"
      break
    default:
      result = value
      break
  }
  return result
}

export function getReadableApplicationLicenseState(value, appType, expired) {
  var result
  switch (value) {
    case "NOT_ACTIVATED":
      if (appType === "IMAGEMANAGER") {
        result = "Free"
      } else {
        result = expired ? "Trial Expired" : "Trial"
      }
      break
    case "PROVISIONING_REQUESTED":
      result = "Provisioning Requested"
      break
    case "ACTIVATION_REQUESTED":
      result = "Activation Requested"
      break
    case "ACTIVATED":
      result = "Full"
      break
    case "ACTIVATION_FAILED":
      result = "Activation Failed"
      break
    case "DEACTIVATION_REQUESTED":
      result = "Deactivation Requested"
      break
    case "DEACTIVATED":
      result = "Deactivated"
      break
    case "DEACTIVATION_FAILED":
      result = "Deactivation Failed"
      break
    default:
      result = value
      break
  }
  return result
}

export function getReadablePatchManagementType(value) {
  var result
  switch (value) {
    case "CRITICAL_UPDATES":
      result = "Critical Update"
      break
    case "SERVICE_PACKS":
      result = "Service Pack"
      break
    case "FEATURE_PACKS":
      result = "Feature Pack"
      break
    case "REGULAR_UPDATES":
      result = "Regular Update"
      break
    case "DEFINITION_UPDATES":
      result = "Definition Update"
      break
    case "UPDATE_ROLLUPS":
      result = "Update Rollup"
      break
    case "SECURITY_UPDATES":
      result = "Security Update"
      break
    default:
      result = value
      break
  }
  return result
}

export function enumToReadableValue(value, capitalizeOnlyFirstWord, ignore) {
  let result = ""
  if (value && typeof value === "string") {
    const isCustom = value.includes(" ")
    const valueParts = value.split("_")
    for (let i = 0; i < valueParts.length; i++) {
      if (i) {
        result += " "
      }

      if (valueParts[i] === ignore) {
        result += valueParts[i]
      } else if (capitalizeOnlyFirstWord && i !== 0) {
        result += valueParts[i].toLowerCase()
      } else {
        result += valueParts[i].charAt(0) + (isCustom ? valueParts[i].slice(1) : valueParts[i].slice(1).toLowerCase())
      }
    }
  }
  return result
}

function getReadableValueForNMS(value) {
  return compose(concat("NMS"), enumToReadableValue, slice(value.indexOf("_"), Infinity))(value)
}

export function getReadablePolicyRole(value) {
  return cond([
    [equals("VMWARE_VM_HOST"), always(localized("VMWare Host Policy"))],
    [equals("VMWARE_VM_GUEST"), always(localized("VMWare Virtual Machine Policy"))],
    [equals("HYPERV_VMM_HOST"), always(localized("Hyper-V Host Policy"))],
    [equals("HYPERV_VMM_GUEST"), always(localized("Hyper-V Guest VM Policy"))],
    [equals("WINDOWS_WORKSTATION"), always(localized("Windows Desktops and Laptops"))],
    [equals("MAC"), always(localized("Mac Desktops and Laptops"))],
    [equals("APPLE_IOS"), getReadableNodeRole],
    [equals("APPLE_IPADOS"), getReadableNodeRole],
    [complement(indexOf("NMS")), getReadableValueForNMS],
    [T, enumToReadableValue],
  ])(value)
}

export function getReadableNodeRole(value) {
  if (value.indexOf("NMS") !== -1) {
    return enumToReadableValue(value)
  } else if (value === "WINDOWS_WORKSTATION") {
    return localized("Windows Desktops and Laptops")
  } else if (value === "MAC") {
    return localized("Mac Desktops and Laptops")
  } else if (value === "APPLE_IOS" || value === "IOS") {
    return "Apple iOS"
  } else if (value === "APPLE_IPADOS") {
    return "Apple iPadOS"
  } else {
    return enumToReadableValue(value)
  }
}

export function getReadableNodeType(value) {
  var result
  switch (value) {
    case "AGENT":
      result = "Device"
      break
    case "CLOUD_MONITOR_TARGET":
      result = "Cloud monitor"
      break
    case "CLOUD_MONITOR_TARGET_EMAIL_SERVER":
      result = "Email monitor"
      break
    default:
      result = value
      break
  }
  return result
}

export function getReadableSyslogSeverity(value) {
  switch (value) {
    case 0:
      return "Emergency"
    case 1:
      return "Alert"
    case 2:
      return "Critical"
    case 3:
      return "Error"
    case 4:
      return "Warning"
    case 5:
      return "Notice"
    case 6:
      return "Informational"
    case 7:
      return "Debug"
    default:
      throw new Error("Invalid Syslog Severity Value")
  }
}

export function getReadableSyslogFacility(value) {
  switch (value) {
    case 0:
      return "kernel messages"
    case 1:
      return "user-level messages"
    case 2:
      return "mail system"
    case 3:
      return "system daemons"
    case 4:
      return "security/authorization messages"
    case 5:
      return "messages generated internally by syslogd"
    case 6:
      return "line printer subsystem"
    case 7:
      return "network news subsystem"
    case 8:
      return "UUCP subsystem"
    case 9:
      return "clock daemon"
    case 10:
      return "security/authorization messages"
    case 11:
      return "FTP daemon"
    case 12:
      return "NTP subsystem"
    case 13:
      return "log audit"
    case 14:
      return "log alert"
    case 15:
      return "clock daemon (note 2)"
    case 16:
      return "local use 0 (local0)"
    case 17:
      return "local use 1 (local1)"
    case 18:
      return "local use 2 (local2)"
    case 19:
      return "local use 3 (local3)"
    case 20:
      return "local use 4 (local4)"
    case 21:
      return "local use 5 (local5)"
    case 22:
      return "local use 6 (local6)"
    case 23:
      return "local use 7 (local7)"
    default:
      throw new Error("Invalid Syslog Facility Value")
  }
}
