import { reject } from "ramda"

const isInlineAttachment = upload => !!upload?.metadata?.inline
const containsAttachments = editorData => !!editorData?.uploads?.length
const containsInlineAttachments = editorData => !!editorData?.uploads?.some(isInlineAttachment)
const containsResponse = editorData => !!editorData?.body?.trim?.()?.length

export const getEditorDataInfo = editorData => {
  const hasResponse = containsResponse(editorData)
  return {
    isNotEmpty: hasResponse || containsAttachments(editorData) || containsInlineAttachments(editorData),
    hasResponse,
  }
}

const removeInlineAttachments = reject(isInlineAttachment)

export const removeInlineUploadsIfNeeded = editorData => {
  if (editorData?.htmlBody?.indexOf("<img") === -1 && containsInlineAttachments(editorData)) {
    editorData.uploads = removeInlineAttachments(editorData.uploads)
  }
}
