import { map, compose, pluck, values, propEq, filter, difference, join } from "ramda"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"
import {
  fetchJson,
  localized,
  localizedF,
  localizationKey,
  showSuccessMessage,
  showErrorMessage,
  ninjaReportError,
  user,
} from "js/includes/common/utils"
import { validateDCsWithPendingCandidateNodes, getDiscoveryJobs, rejectNodes } from "js/includes/common/client"
import { setDiscoveryJobsList } from "js/state/actions/customerEditor/discoveryJobs/discoveryJobs"
import { removeRecentTab } from "../general/recentTabs"
import { removeFavoriteTab } from "../general/favoriteTabs"
import DisableLockhartWarningAck from "js/includes/configuration/integrations/backup/lockhart/DisableLockhartWarningAck"

export const getSelectedNodeIds = (selected, nodeApprovalType = "NODE") =>
  compose(pluck("id"), filter(propEq("nodeApprovalType", nodeApprovalType)), values)(selected.rows)

export const getSelectedCandidateNodesIds = selected => getSelectedNodeIds(selected, "CANDIDATE_NODE")
export const getSelectedMDMNodesIds = selected => getSelectedNodeIds(selected, "MDM_NODE")

export const showActionResultMessage = (action, candidateNodes, successCandidateNodes) => {
  const successTokens = {
    approve: localizationKey("Approved device(s)"),
    reject: localizationKey("Rejected device(s)"),
    clear: localizationKey("Cleared device(s)"),
  }
  const errorTokens = {
    approve: localizationKey("Error approving {{count}} device(s)"),
    reject: localizationKey("Error rejecting {{count}} device(s)"),
    clear: localizationKey("Error clearing {{count}} device(s)"),
  }
  const failedDevicesCount = difference(candidateNodes, successCandidateNodes).length
  failedDevicesCount > 0
    ? showErrorMessage(localized(errorTokens[action], { count: failedDevicesCount }))
    : showSuccessMessage(localized(successTokens[action]))
}

const getDCsPendingCandidateNodesMessage = async selected => {
  const nodes = getSelectedNodeIds(selected)
  if (nodes.length) {
    const nodesWithPendingCandidateNodes = await validateDCsWithPendingCandidateNodes(nodes)
    if (nodesWithPendingCandidateNodes.length) {
      const domainControllersNames = compose(
        join(", "),
        pluck("displayName"),
        filter(node => nodesWithPendingCandidateNodes.includes(node.id)),
        values,
      )(selected.rows)
      return localized(
        "Domain Controller(s) {{domainControllersNames}} have associated device(s) pending installation and/or discovery job(s) that will also be deleted.",
        {
          domainControllersNames,
        },
      )
    }
  }
  return ""
}

const getSelectedNodeDisplayNames = (selected, nodeApprovalType = "NODE") =>
  compose(pluck("displayName"), filter(propEq("nodeApprovalType", nodeApprovalType)), values)(selected.rows)

export default function approvalStatus(dispatch) {
  return {
    APPROVE_NODES: {
      id: "approve-nodes",
      token: localizationKey("Approve"),
      action: async (selected, tabName, dispatchCallback) => {
        const nodes = getSelectedNodeIds(selected)
        const candidateNodes = getSelectedCandidateNodesIds(selected)
        // TODO: Remove mdmNodes when backend update the API
        const mdmNodes = getSelectedMDMNodesIds(selected)

        try {
          const { candidateNodes: approvedCandidateNodesIds = [] } = await fetchJson("/node/approval", {
            options: {
              method: "POST",
              body: JSON.stringify({
                action: "APPROVE",
                nodes,
                candidateNodes,
                // TODO: Remove mdmNodes when backend update the API
                mdmNodes,
              }),
            },
          })

          await dispatch({
            type: "NODE_APPROVALS_APPROVE_NODES",
            payload: {
              tabName,
              // TODO: Remove mdmNodes when backend update the API
              nodes: [...nodes, ...mdmNodes],
              candidateNodes: approvedCandidateNodesIds,
            },
          })

          showActionResultMessage("approve", candidateNodes, approvedCandidateNodesIds)
          dispatchCallback({ type: "CLEAR_SELECTION" })
        } catch (error) {
          showErrorMessage(localized("Error approving device"))
          ninjaReportError(error)
        }
      },
    },

    REJECT_NODES: {
      id: "reject-nodes",
      token: localizationKey("Reject"),
      action: async (selected, tabName, dispatchCallback) => {
        const nodes = getSelectedNodeIds(selected)
        const candidateNodes = getSelectedCandidateNodesIds(selected)
        const dcWithCandidateNodesMessage = await getDCsPendingCandidateNodesMessage(selected)
        // TODO: Remove mdmNodes when backend update the API
        const mdmNodes = getSelectedMDMNodesIds(selected)

        const rejectConfirmation = await ShowMessageDialog({
          icon: { icon: faExclamationTriangle, type: "critical" },
          title: localizationKey("Reject device(s)"),
          MessageComponent: () => (
            <div>
              {dcWithCandidateNodesMessage && <div>{dcWithCandidateNodesMessage}</div>}
              <p>
                {localized(
                  "This action can not be reversed and will prevent the selected device(s) from registering again. Are you sure you want to reject {{nodesQty}} device(s)?",
                  {
                    nodesQty: nodes.length + candidateNodes.length + mdmNodes.length,
                  },
                )}
              </p>
            </div>
          ),
          buttons: [
            { id: "NO", label: localizationKey("No") },
            { id: "YES", label: localizationKey("Yes"), type: "critical" },
          ],
        })

        if (rejectConfirmation === "YES") {
          const updateRejectedNodes = async ({ rejectedCandidateNodesIds }) => {
            try {
              const allNodesIds = [...nodes, ...mdmNodes]

              await dispatch({
                type: "NODE_APPROVALS_REJECT_NODES",
                payload: {
                  tabName,
                  // TODO: Remove mdmNodes when backend update the API
                  nodes: allNodesIds,
                  candidateNodes: rejectedCandidateNodesIds,
                },
              })

              showActionResultMessage("reject", candidateNodes, rejectedCandidateNodesIds)
              dispatchCallback({ type: "CLEAR_SELECTION" })

              await Promise.all(
                map(async id => {
                  window.deviceList.delete(id)
                  await dispatch(removeRecentTab({ id, type: "DEVICE" }))
                  await dispatch(removeFavoriteTab({ id, type: "DEVICE" }))
                  return
                }, allNodesIds),
              )

              if (tabName === "approved" && user("canAccessActiveDirectoryDiscovery")) {
                const { clientId } = window.store.getState().nodeApprovals.content
                const discoveryJobsList = await getDiscoveryJobs(clientId)
                dispatch(setDiscoveryJobsList(discoveryJobsList))
              }
            } catch (error) {
              showErrorMessage(localized("Error rejecting device"))
              ninjaReportError(error)
            }
          }

          const result = await rejectNodes({
            nodes,
            candidateNodes,
            deleteLockhartDataAction: "VALIDATE",
            mdmNodes,
          })

          if (result.success) {
            await updateRejectedNodes(result)
          } else if (result.resultCode === "NODES_WITH_LOCKHART_DATA") {
            const { buttonPressed, deleteData } = await DisableLockhartWarningAck({
              title: selected.length === 1 ? getSelectedNodeDisplayNames(selected)[0] : localized("Reject device(s)"),
              message: localizedF(
                "This action can not be reversed and will prevent the selected device(s) from registering again. Are you sure you want to reject {{nodesQty}} device(s)?",
                {
                  nodesQty: nodes.length + candidateNodes.length,
                },
              ),
              lockhartInfoMessage:
                nodes.length > 1
                  ? localizationKey("There may be backup data stored for the selected device(s).")
                  : localizationKey("There may be backup data stored for this device."),
              buttons: [
                { id: "NO", label: localizationKey("No") },
                { id: "YES", label: localizationKey("Reject"), validate: true, type: "critical" },
              ],
              requireEmail: true,
            })

            if (buttonPressed === "YES") {
              const deleteDataResult = await rejectNodes({
                nodes,
                candidateNodes,
                deleteLockhartDataAction: deleteData ? "DELETE" : "NONE",
              })

              if (deleteDataResult.success) {
                await updateRejectedNodes(deleteDataResult)
              }
            }
          }
        }
      },
    },

    CLEAR_NODES: {
      id: "clear-nodes",
      token: localizationKey("Clear"),
      action: async (selected, tabName, dispatchCallback) => {
        const nodes = getSelectedNodeIds(selected)
        const candidateNodes = getSelectedCandidateNodesIds(selected)
        // TODO: Remove mdmNodes when backend update the API
        const mdmNodes = getSelectedMDMNodesIds(selected)

        const rejectConfirmation = await ShowMessageDialog({
          icon: { icon: faExclamationTriangle, type: "critical" },
          title: localizationKey("Clear device(s)"),
          message: () =>
            localized(
              "This action can not be reversed and will allow the selected device(s) to register again. Are you sure you want to clear {{nodesQty}} device(s)?",
              {
                nodesQty: nodes.length + mdmNodes.length + candidateNodes.length,
              },
            ),
          buttons: [
            { id: "YES", label: localizationKey("Yes") },
            { id: "NO", label: localizationKey("No") },
          ],
        })

        if (rejectConfirmation === "YES") {
          try {
            const { candidateNodes: clearedCandidateNodesIds = [] } = await fetchJson("/node/approval", {
              options: {
                method: "POST",
                body: JSON.stringify({
                  action: "CLEAR",
                  nodes,
                  candidateNodes,
                  // TODO: Remove mdmNodes when backend update the API
                  mdmNodes,
                }),
              },
            })

            await dispatch({
              type: "NODE_APPROVALS_CLEAR_NODES",
              payload: {
                tabName,
                // TODO: Remove mdmNodes when backend update the API
                nodes: [...nodes, ...mdmNodes],
                candidateNodes: clearedCandidateNodesIds,
              },
            })

            showActionResultMessage("clear", candidateNodes, clearedCandidateNodesIds)
            dispatchCallback({ type: "CLEAR_SELECTION" })
          } catch (error) {
            showErrorMessage(localized("Error clearing device(s)"))
            ninjaReportError(error)
          }
        }
      },
    },
  }
}
