import qs from "qs"
import { groupBy } from "ramda"
import { defaultUser, fetchJson } from "js/includes/common/utils"

const USER_TYPES_MAPPER = {
  TECHNICIAN: "technicians",
  CONTACT: "contacts",
  END_USER: "endUsers",
}

export const getAppUsers = async (options = {}) => {
  const { shouldGroup = true, userType = "TECHNICIAN", withContacts } = options

  const _qs = qs.stringify({ userType, withContacts })
  const endUserResponse = await fetchJson(`/app-user-contact?${_qs}`)

  return shouldGroup ? groupBy(user => USER_TYPES_MAPPER[user.userType], endUserResponse) : endUserResponse
}

export const getAllAppTechnicians = () => {
  return fetchJson("/ticketing/technician")
}

export const getAppUserContactByUid = uid => fetchJson(`/app-user-contact/${uid}`)

export const searchAppUserContacts = ({ userType, pageSize = 50, clientId, anchorNaturalId, searchCriteria }) => {
  const queryString = qs.stringify(
    {
      ...(USER_TYPES_MAPPER[userType] && { userType }),
      pageSize,
      clientId,
      anchorNaturalId,
      ...(searchCriteria && { searchCriteria }),
    },
    { addQueryPrefix: true },
  )
  return fetchJson(`/app-user-contact/search${queryString}`)
}

export const importTechnicians = technicians => {
  return fetchJson("/appuser/bulk-import", {
    options: {
      method: "POST",
      body: JSON.stringify(
        technicians.map(({ firstName, lastName, email, phone, rolesId, sysadmin }) => ({
          ...defaultUser,
          firstName,
          lastName,
          email,
          phone: phone || "",
          userType: "TECHNICIAN",
          sysadmin,
          roles: rolesId,
        })),
      ),
    },
  })
}
