import { isEmpty, or, includes, toLower, compose, path, defaultTo, __ } from "ramda"

const ninjaProcesses = [
  "ninjarmmagent",
  "ninjarmmagent.exe",
  "ninjarmmagentpatcher.exe",
  "ninjarmmproxyprocess64.exe",
  "ninjarmm-macagen",
  "ninjarmm-linage",
  "ninjarmm-agent.service",
]

export const isEmptyOrIsNinjaAgent = process =>
  or(isEmpty(process), compose(includes(__, ninjaProcesses), toLower, defaultTo(""), path(["name"]))(process))

export const isMacKernelTask = process => process.pid === 0

export const defaultToEmpty = defaultTo("")
