import { isGranted, isGrantedForAtLeastOneEntity } from "js/includes/common/services/userPermissions"
import { isSystemAdmin, userProp } from "js/includes/common/utils"
import { EntityType, VIEW, UPDATE, DELETE, EntitySubType } from "js/includes/editors/User/Permissions/common"

export const canConfigureAppleMDM = () => {
  return isSystemAdmin()
}

export const canConfigureAndroidMDM = () => {
  return isSystemAdmin()
}

export const canViewAtLeastOneConnection = subType => {
  return isGrantedForAtLeastOneEntity({ entityType: EntityType.MDM, permissionName: `${subType}.${VIEW}` })
}

export const canUpdateAtLeastOneConnection = subType => {
  return isGrantedForAtLeastOneEntity({ entityType: EntityType.MDM, permissionName: `${subType}.${UPDATE}` })
}

export const canDeleteAtLeastOneConnection = subType => {
  return isGrantedForAtLeastOneEntity({ entityType: EntityType.MDM, permissionName: `${subType}.${DELETE}` })
}

export const canViewAtLeastOneIndividualConnection = subType => {
  const permissions = userProp("permissions")

  return !!permissions.find(
    ({ entityType, name, entityId, allowed }) =>
      entityType === EntityType.MDM && name === `${subType}.${VIEW}` && entityId !== 0 && !!allowed,
  )
}

export const canViewAtLeastOneAppleConnection = () => {
  return (
    canViewAtLeastOneConnection(EntitySubType.AppleADEConnection) ||
    canViewAtLeastOneConnection(EntitySubType.AppleAPNConnection) ||
    canViewAtLeastOneConnection(EntitySubType.AppleAppsAndBooksConnection)
  )
}

export const canViewMDMConnection = (connectionType, { entityId = 0 } = {}) => {
  return isGranted({
    entityType: "MDM",
    permissionName: `${connectionType}.Connection.View`,
    entityId,
  })
}

export const canUpdateMDMConnection = (connectionType, { entityId = 0 } = {}) => {
  return isGranted({
    entityType: "MDM",
    permissionName: `${connectionType}.Connection.Update`,
    entityId,
  })
}

export const canCreateMDMConnection = (connectionType, { entityId = 0 } = {}) => {
  return isGranted({
    entityType: "MDM",
    permissionName: `${connectionType}.Connection.Create`,
    entityId,
  })
}

export const canDeleteMDMConnection = (connectionType, { entityId } = {}) => {
  return isGranted({
    entityType: "MDM",
    permissionName: `${connectionType}.Connection.Delete`,
    entityId,
  })
}
