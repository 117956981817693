import React from "react"
import { ConfirmationModal, Text } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"

const PermissionActionModal = ({ unmount = () => {}, onConfirm, titleToken, actionToken, description }) => {
  return (
    <ConfirmationModal
      titleToken={titleToken}
      actionToken={actionToken}
      unmount={unmount}
      onConfirm={() => {
        onConfirm()
        unmount()
      }}
    >
      <Box>
        <Text textWrap>{description}</Text>
      </Box>
    </ConfirmationModal>
  )
}

export default PermissionActionModal
