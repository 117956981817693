import { fetchJson, getNinjaBranding, reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"

export function getBrandingSettings() {
  return async dispatch => {
    try {
      const { brandingNode, hostname } = await fetchJson("/branding/data")

      if (brandingNode) {
        dispatch(initBrandingSettings(brandingNode))
      }
      if (hostname) {
        dispatch(initHostname(hostname))
      }
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error fetching branding"))
    }
  }
}

export const initBrandingSettings = payload => ({
  type: "INIT_WEBSITE_BRANDING_SETTINGS",
  payload,
})

const initHostname = payload => ({
  type: "INIT_HOSTNAME",
  payload,
})

export const resetSystraySettings = payload => ({
  type: "SET_SYSTRAY_INFO",
  payload: {
    general: null,
    application: null,
    menu: null,
  },
})

export async function saveBrandingSettings() {
  const { brandingNode, hostname } = window.store.getState().websiteBranding
  const body = JSON.stringify({
    brandingNode: brandingNode,
    hostname: hostname,
  })
  return await fetchJson("/branding/data", { options: { method: "POST", body } })
}

export function setEnabled(enabled) {
  return dispatch => {
    dispatch({ type: "SET_ENABLED", enabled })
  }
}

export function setPhoneEnabled(phoneEnabled) {
  return dispatch => {
    dispatch({ type: "SET_PHONE_ENABLED", phoneEnabled })
  }
}

export function setPhoneNumber(phoneNumber) {
  return dispatch => {
    dispatch({ type: "SET_PHONE_NUMBER", phoneNumber })
  }
}

export function setEmailEnabled(emailEnabled) {
  return dispatch => {
    dispatch({ type: "SET_EMAIL_ENABLED", emailEnabled })
  }
}

export function setEmail(email) {
  return dispatch => {
    dispatch({ type: "SET_EMAIL", email })
  }
}

export function setHelpWebsiteEnabled(enableHelpWebsite) {
  return dispatch => {
    dispatch({ type: "SET_HELP_WEBSITE_ENABLED", enableHelpWebsite })
  }
}

export function setHelpWebsite(website) {
  return dispatch => {
    dispatch({ type: "SET_HELP_WEBSITE", website })
  }
}

export function setHelpEnabled(helpEnabled) {
  return dispatch => {
    dispatch({ type: "SET_HELP_ENABLED", helpEnabled })
  }
}

export function setHelpNinjaArticlesEnabled(ninjaArticlesEnabled) {
  return dispatch => {
    dispatch({ type: "SET_HELP_NINJA_ARTICLES_ENABLED", ninjaArticlesEnabled })
  }
}

export function setHelpSubmitTicketEnabled(submitTicketEnabled) {
  return dispatch => {
    dispatch({ type: "SET_HELP_SUBMIT_TICKET_ENABLED", submitTicketEnabled })
  }
}

export function setNinjaOneWorkrampAcademyEnabled(ninjaOneWorkrampAcademyEnabled) {
  return dispatch => {
    dispatch({ type: "SET_WORKRAMP_LINK_ENABLED", ninjaOneWorkrampAcademyEnabled })
  }
}

export function addNewHelpTopic(helpTopic) {
  return (dispatch, getState) => {
    dispatch({ type: "ADD_HELP_TOPIC", helpTopic })
  }
}

export function removeTopic(index) {
  return dispatch => {
    dispatch({ type: "REMOVE_HELP_TOPIC", index })
  }
}

export function setLoginLogo(loginLogo) {
  return dispatch => {
    dispatch({ type: "SET_LOGIN_LOGO", loginLogo })
  }
}

export function setAppLogo(appLogo) {
  return dispatch => {
    dispatch({ type: "SET_APP_LOGO", appLogo })
  }
}

export function setBrowserIcon(browserIcon) {
  return dispatch => {
    dispatch({ type: "SET_BROWSER_ICON", browserIcon })
  }
}

export function setTheme(themeName) {
  return dispatch => {
    dispatch({ type: "SET_THEME", themeName })
  }
}

export function setAppTheme(theme) {
  return dispatch => {
    dispatch({ type: "SET_APP_THEME", theme })
  }
}

export function setDarkMode(darkMode) {
  return dispatch => {
    dispatch({ type: "SET_DARK_MODE", darkMode })
  }
}

export function setBrowserTabEnabled(enableBrowserTab) {
  return dispatch => {
    dispatch({ type: "SET_BROWSER_TAB_ENABLED", enableBrowserTab })
  }
}

export function setBrowserTabName(browserTabName) {
  return dispatch => {
    dispatch({ type: "SET_BROWSER_TAB_NAME", browserTabName })
  }
}

export function getNinjaBrandingSettings() {
  return dispatch => {
    dispatch(initBrandingSettings(getNinjaBranding()))
  }
}
