import React from "react"
import { Provider } from "react-redux"
import $ from "jquery"
import _ from "underscore"

import {
  addIndicator,
  downloadFile,
  getAttachmentBlob,
  getFileNameFromContentDispositionHeader,
  localized,
  renderReactComponentIfElementExists,
  unmountReactComponentIfElementExists,
  user,
} from "js/includes/common/utils"
import AsyncComponent from "js/includes/components/AsyncComponent"
import {
  policyEditorAndroidTabs,
  policyEditorIOSTabs,
  policyEditorVMTabs,
} from "js/includes/editors/Policy/PolicyEditor/tabs"

import ModelBase from "./includes/editors/Common/ModelBase"
import Router from "./Router"

const PolicyEditorModal = AsyncComponent({
  loader: () =>
    import("js/includes/editors/Policy/PolicyEditor/PolicyEditorModal" /* webpackChunkName: "policyEditorModal" */),
})

const MainController = ModelBase.extend({
  editorContainerId: "full-screen-editor",
  dashboardContainerId: "main",

  initialize() {
    _.bindAll(
      this,
      "unMountReactComponents",
      "renderFullScreenModal",
      "showEditor",
      "hideEditor",
      "getContainer",
      "renderComponent",
    )
  },

  clear(routeType, subType, id) {
    let activeTabLink = window.controller.get("activeTabLink")
    let activeTab = window.controller.get("activeTab")
    const activeEditorTab = window.controller.get("activeEditorTab")

    const tabId = id
      ? "#/" + subType + "/" + id + "/"
      : routeType === Router.RouteType.ConfigurationTab
      ? "#/administration/" + subType
      : "#/" + subType + "/"

    if (activeTab) {
      // If we're navigating to another subTab of an existing tab, deallocate the subTab
      if (activeTabLink && activeTabLink.href.includes(tabId) && activeTab.get("subTab")) {
        activeTab.get("subTab").destroy()
        activeTab.set("subTab", null, true)
      }
      // Sometimes there is an href match but there is no subTab eg. PSA setup uses same href as PSA Tab (destroy the main tab)
      // Sometimes there is a subTab on the activeTabLink but not on the tabModel eg. SuperAdmin (destroy the main tab)
      else {
        activeTab.destroy()
        window.controller.set("activeTabLink", null, true)
        window.controller.set("activeTab", null, true)
        activeTabLink = undefined
      }
    } else if (activeEditorTab) {
      window.controller.set("activeEditorTab", null, true)
    }

    // If user navigates away (eg. back/forward button) with the modal open, close it!
    let $fullScreenModal = $("#full-screen-modal")
    if ($fullScreenModal.data("editor-view")) {
      let editorView = $fullScreenModal.data("editor-view")
      $fullScreenModal.data("navigatedAway", true)
      editorView.model.closeModal()
    }

    const editorRouteType = Router.RouteType.FullScreenModal
    this.unMountReactComponents({
      routeType: editorRouteType,
    })
    this.hideEditor(editorRouteType)
  },

  async show(routeType, subType, id, subTabName, subSubTabName, params, lastRoute, childrenId) {
    let tabModel = this.get("activeTab")
    let tabLink
    let controller = window.controller
    let activeTabLink = controller.get("activeTabLink")

    let editorTabModel = this.get("activeEditorTab")

    id = parseInt(id, 10)

    const tabId =
      routeType === Router.RouteType.GroupTab
        ? `#/${subType}/${id}`
        : id
        ? `#/${subType}/${id}/`
        : routeType === Router.RouteType.ConfigurationTab
        ? `#/administration/${subType}`
        : `#/${subType}/`

    const href = subTabName ? `${tabId}${subTabName}${subSubTabName ? `/${subSubTabName}` : ""}` : tabId

    switch (routeType) {
      case Router.RouteType.DashboardTab:
        switch (subType) {
          case "systemDashboard":
            tabLink = {
              href,
              tabId,
            }
            if (!activeTabLink || activeTabLink.href.indexOf(tabId) !== 0) {
              const { default: SystemDashboardTabModel } = await import(
                "js/includes/systemDashboard/SystemDashboardTabModel" /* webpackChunkName: "systemDashboardTabModel" */
              )
              tabModel = new SystemDashboardTabModel({
                type: "SystemDashboardTabModel",
                name: subType,
              })
            }
            break

          case "customerDashboard":
            tabLink = {
              id,
              href,
              tabId,
            }

            if (!activeTabLink || activeTabLink.href.indexOf(tabId) !== 0) {
              const { default: CustomerDashboardTabModel } = await import(
                "js/includes/customerDashboard/CustomerDashboardTabModel" /* webpackChunkName: "customerDashboardTabModel" */
              )

              tabModel = new CustomerDashboardTabModel({
                id: id,
                type: "CustomerDashboardTabModel",
                name: subType,
              })
            }
            break

          case "deviceDashboard":
            tabLink = {
              id,
              tabId,
              href,
            }
            if (!activeTabLink || activeTabLink.href.indexOf(tabId) !== 0) {
              const { default: DeviceDashboardTabModel } = await import(
                "js/includes/deviceDashboard/DeviceDashboardTabModel" /* webpackChunkName: "deviceDashboardTabModel" */
              )
              tabModel = new DeviceDashboardTabModel({
                id: id,
                type: "DeviceDashboardTabModel",
                name: subType,
              })
            }
            break

          case "cloudMonitorDashboard":
            tabLink = {
              id,
              tabId,
              href: href.replace(/\/$/, ""),
            }
            if (!activeTabLink || activeTabLink.href.indexOf(tabId) !== 0) {
              const { default: CloudMonitorDashboardTabModel } = await import(
                "js/includes/cloudMonitorDashboard/CloudMonitorDashboardTabModel" /* webpackChunkName: "cloudMonitorDashboardTabModel" */
              )
              tabModel = new CloudMonitorDashboardTabModel({
                id,
                href,
                type: "CloudMonitorDashboardTabModel",
              })
            }
            break

          case "nmsDashboard":
            tabLink = {
              id,
              tabId,
              href,
            }
            if (!activeTabLink || activeTabLink.href.indexOf(tabId) !== 0) {
              const { default: NmsDashboardTabModel } = await import(
                "js/includes/nmsDashboard/NmsDashboardTabModel" /* webpackChunkName: "nmsDashboardTabModel" */
              )

              tabModel = new NmsDashboardTabModel({
                id: id,
                href,
                type: "NmsDashboardTabModel",
              })
            }
            break

          default:
            break
        }
        break
      case Router.RouteType.FullScreenModal:
        switch (subType) {
          case "customer":
            if (params !== "endUser") {
              addIndicator($("#main-container"), localized("Loading"))
              const { default: CustomerEditorModel } = await import(
                "js/includes/editors/Customer/CustomerEditorModel" /* webpackChunkName: "customerEditorModel" */
              )
              CustomerEditorModel.setupEditor(id)
            }
            break
          case "psa":
            const { default: PsaSubTabModel } = await import(
              "js/includes/configuration/psa/PsaSubTabModel" /* webpackChunkName: "psaSubTabModel" */
            )
            PsaSubTabModel.setupEditor(params)
            break
          case "shadowProtect":
            const { default: ShadowProtectWizardModel } = await import(
              "js/includes/wizards/ShadowProtect/ShadowProtectWizardModel" /* webpackChunkName: "shadowProtectWizardModel" */
            )
            ShadowProtectWizardModel.setupEditor(id, params)
            break
          case "imageManager":
            const { default: ImageManagerWizardModel } = await import(
              "js/includes/wizards/ImageManager/ImageManagerWizardModel" /* webpackChunkName: "imageManagerWizardModel" */
            )
            ImageManagerWizardModel.setupEditor(id, params)
            break
          case "policy":
            const { default: PolicyEditorModel } = await import(
              "js/includes/editors/Policy/PolicyEditorModel" /* webpackChunkName: "policyEditorModel" */
            )
            editorTabModel = await PolicyEditorModel.setupEditor(id, params)
            break
          case "vmpolicy":
            this.renderFullScreenModal(routeType, id, params, subType)
            break
          case "androidpolicy":
          case "iospolicy":
            this.renderFullScreenModal(routeType, id, params, subType)
            break
          case "cloudMonitor":
            const { default: MonitorWizardModel } = await import(
              "js/includes/wizards/Monitor/MonitorWizardModel" /* webpackChunkName: "monitorWizardModel" */
            )
            MonitorWizardModel.setupEditor(id)
            break
          case "websiteBranding":
            const { default: BrandingEditorModel } = await import(
              "js/includes/configuration/branding/website/BrandingEditorModel" /* webpackChunkName: "brandingEditorModel" */
            )
            new BrandingEditorModel()
            break
          case "templateScript":
          case "script":
            const { default: ScriptEditorModel } = await import(
              "js/includes/configuration/scripting/ScriptsSubTabModel/ScriptEditor" /* webpackChunkName: "scriptEditorModel" */
            )
            new ScriptEditorModel(id, params, subType)
            break
          case "scheduledTask":
            if (user("canCreateScheduledTasks") && user("canRunAtLeastOneAdhocScript")) {
              const { default: ScheduledTaskEditorModel } = await import(
                "js/includes/configuration/scheduledTasks/taskEditor/ScheduledTaskEditorModel" /* webpackChunkName: "scheduledTaskEditorModel" */
              )
              new ScheduledTaskEditorModel(id)
            }
            break
          default:
            break
        }
        break

      case Router.RouteType.Attachment:
        //TODO: Don't load entire app just to view or download an attachment
        //View attachment in standalone page similar to reportViewer.html
        //add nginx rewrite rule to point to new .html file similar to the installer landing page so that existing URLs continue working
        switch (subType) {
          case "view":
            ;(async () => {
              const { objectURL } = await getAttachmentBlob(`${params}?view=original`)
              const element = document.createElement("img")
              element.setAttribute("src", objectURL)
              const overlayContainer = document.getElementById("full-screen-modal")
              overlayContainer.appendChild(element)
              overlayContainer.setAttribute(
                "style",
                `
                        display: block;
                        text-align: center;
                        opacity: 1;
                        background-color: #FFFFFF;
                        overflow: auto;
                      `,
              )
            })()
            break
          case "download":
            ;(async () => {
              const { objectURL, headers } = await getAttachmentBlob(params)
              const filename = getFileNameFromContentDispositionHeader(headers)
              downloadFile(objectURL, filename)
              window.application.reload()
            })()
            break
          default:
            break
        }
        break

      default:
        break
    }

    // If we created a new tabModel, show it
    if (tabModel) {
      controller.set("activeTabLink", tabLink, true)
      controller.set("activeTab", tabModel, true)

      tabModel.show(subTabName, subSubTabName)
    }
    // If we're navingating to a subTab
    else if (activeTabLink && activeTabLink.href.indexOf(tabId) === 0) {
      controller.get("activeTab").show(subTabName, subSubTabName)
    }
    // If we created a new editorTabModel, set it in the controller
    else if (editorTabModel) {
      controller.set("activeEditorTab", editorTabModel, true)
    }
  },

  refreshCurrentTab() {
    let activeTab = window.controller.get("activeTab")
    if (activeTab.get("subTab")) {
      activeTab.get("subTab").refresh()
    } else {
      activeTab.refresh()
    }
  },

  getContainer(routeType) {
    switch (routeType) {
      case Router.RouteType.DashboardTab:
        return document.getElementById(this.dashboardContainerId)
      case Router.RouteType.FullScreenModal:
        return document.getElementById(this.editorContainerId)
      default:
        return null
    }
  },

  showEditor(subType) {
    const editorElement = this.getContainer(subType)
    $(editorElement).css({ display: "flex", opacity: 1 })
  },

  hideEditor(subType) {
    const editorElement = this.getContainer(subType)
    $(editorElement).removeAttr("style")
  },

  unMountReactComponents({ routeType, container }) {
    const editorElement = this.getContainer(routeType)
    unmountReactComponentIfElementExists(editorElement || container)
  },

  renderFullScreenModal(routeType, id, params, subType) {
    switch (subType) {
      case "vmpolicy":
        this.renderComponent(routeType, () => (
          <PolicyEditorModal
            policyId={id}
            deviceId={params}
            newPolicyRoute="#/editor/vmpolicy"
            tabs={policyEditorVMTabs}
            onClose={() => {
              this.unMountReactComponents({ routeType })
              this.hideEditor(routeType)
            }}
            showEditor={() => this.showEditor(routeType)}
          />
        ))
        break
      case "androidpolicy":
        this.renderComponent(routeType, () => (
          <PolicyEditorModal
            policyId={id}
            deviceId={params}
            tabs={policyEditorAndroidTabs()}
            newPolicyRoute="#/editor/androidpolicy"
            onClose={() => {
              this.unMountReactComponents({ routeType })
              this.hideEditor(routeType)
            }}
            showEditor={() => this.showEditor(routeType)}
          />
        ))
        break
      case "iospolicy":
        this.renderComponent(routeType, () => (
          <PolicyEditorModal
            policyId={id}
            deviceId={params}
            tabs={policyEditorIOSTabs()}
            newPolicyRoute="#/editor/iospolicy"
            onClose={() => {
              this.unMountReactComponents({ routeType })
              this.hideEditor(routeType)
            }}
            showEditor={() => this.showEditor(routeType)}
          />
        ))
        break
      default:
        break
    }
  },

  renderComponent(routeType, Component, container) {
    renderReactComponentIfElementExists(
      <Provider store={window.store}>
        <Component key={Date.now()} />
      </Provider>,
      container || this.getContainer(routeType),
    )
  },
})

export default MainController
