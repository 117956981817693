import styled from "@emotion/styled"
import { sidebarWidth, sidebarWidthCollapsed } from "js/includes/application/SideNavBar/common"
import { localized } from "js/includes/common/utils"

const StyledVersionInfo = styled.div`
  position: absolute;
  top: 0;
  left: ${({ collapsed }) => (collapsed ? sidebarWidthCollapsed : sidebarWidth)}px;
  z-index: 999000;
  background-color: ${({ theme }) => theme.color.background};
  border-right: 1px solid ${({ theme }) => theme.colorThemeBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colorThemeBackground};

  div.title {
    text-align: center;
    background-color: ${({ theme }) => theme.colorThemeBackground};
    color: ${({ theme }) => theme.colorThemeText};
    font-weight: 600;
    padding: 10px 15px;
  }

  ul {
    margin: 0;
    padding: 10px 15px;

    li {
      display: flex;
      gap: 20px;
      justify-content: space-between;

      &:not(:last-child) {
        padding-bottom: 10px;
      }

      strong {
        font-weight: 600;
      }
    }
  }
`

const VersionInfo = ({ version, collapsed }) => {
  return (
    <StyledVersionInfo id="version-info" {...{ collapsed }}>
      <div className="title">{localized("Version Information")}</div>
      <ul>
        <li>
          <strong>Server</strong>
          {version.serverVersion}
        </li>

        {version.monitorServers.map(({ serverVersion }, i) => (
          <li key={i}>
            <strong>Monitor</strong>
            {serverVersion}
          </li>
        ))}

        <li>
          <strong>Task</strong>
          {version.taskServerVersion}
        </li>

        <li>
          <strong>Win Agent</strong>
          {version.agentVersion}
        </li>

        <li>
          <strong>Mac Agent</strong>
          {version.macAgentVersion}
        </li>

        <li>
          <strong>Linux Agent</strong>
          {version.linAgentVersion}
        </li>

        <li>
          <strong>NMS Delegate</strong>
          {version.nmsDelegateVersion}
        </li>

        <li>
          <strong>Web</strong>
          {window.application.get("version").get("version")}
        </li>

        <li>
          <strong>VMware Agent</strong>
          {version.vmmDelegateVersion}
        </li>

        <li>
          <strong>NinjaWPM Agent</strong>
          {version.installable.WUINSTALL_WINDOWS}
        </li>

        {version?.androidManagementVersion && (
          <li>
            <strong>Android Management</strong>
            {version.androidManagementVersion}
          </li>
        )}
        {version?.appleManagementVersion && (
          <li>
            <strong>Apple Management</strong>
            {version.appleManagementVersion}
          </li>
        )}
        {version?.appleAdeManagementVersion && (
          <li>
            <strong>Apple ADE Management</strong>
            {version.appleAdeManagementVersion}
          </li>
        )}
      </ul>
    </StyledVersionInfo>
  )
}

export default VersionInfo
