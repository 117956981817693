import { memo, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { omit } from "ramda"
import { connect } from "react-redux"
import { useTheme } from "@emotion/react"
import { Card, DataTable } from "@ninjaone/components"
import { filterTypes } from "@ninjaone/components/src/DataTable"

import {
  localized,
  localizationKey,
  useQueryParams,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { updateTicket } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { Flex } from "js/includes/components/Styled"
import {
  buildTicketEditorRoute as _buildTicketEditorRoute,
  optionsReducer,
} from "js/includes/ticketing/boards/BoardTicketList/utils"
import {
  getStatusBackgroundFromId,
  isClosedStatus,
} from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"
import { getPriorityOptions, getSeverityOptions } from "js/includes/ticketing/common/TicketEditorFields/options"
import { isTicketRequiredAttributeValueError } from "js/includes/ticketing/shared/utils"
import { ReAssignIncidentModal } from "js/includes/ticketing/editor/shared/components"
import { omitTicketStateValues, prepareTicketValuesForServer } from "js/includes/ticketing/editor/shared/utils"
import { setRequestUid as _setRequestUid } from "js/state/actions/ticketing"

const omitNotVisibleFormAttributes = omit([
  "source",
  "createTime",
  "triggeredCondition",
  "totalTimeTracked",
  "deleted",
  "parentTicket",
])

const _RelatedTickets = memo(({ relatedTickets, hasProblem, requestUid, onComplete }) => {
  const theme = useTheme()
  const query = useQueryParams()

  const { buildTicketEditorRouteWithHash } = useMemo(
    () => ({
      buildTicketEditorRouteWithHash: _buildTicketEditorRoute({ boardId: query.get("boardId"), withHash: true }),
    }),
    [query],
  )

  const handleReassignTicket = useCallback(
    async ({ ticket, parentTicket, unmount }) => {
      const { status } = ticket
      const requesterUid = ticket.requester?.uid
      const agreementId = ticket.agreement?.id || null

      const _values = prepareTicketValuesForServer({ ...ticket, type: "INCIDENT", parentTicketId: parentTicket.id })

      try {
        await updateTicket(
          {
            ticket: {
              nodeId: _values.node?.id || null,
              clientId: _values.client?.id || null,
              ...omitTicketStateValues(omitNotVisibleFormAttributes(_values)),
              status,
              requestUid,
              ...(requesterUid && { requesterUid }),
              agreementId,
            },
          },
          ticket.id,
        )
        onComplete?.()
        unmount()
        showSuccessMessage()
      } catch (error) {
        if (isTicketRequiredAttributeValueError(error)) {
          reportErrorAndShowMessage(
            error,
            localizationKey("Error updating ticket. Make sure that all of the required fields have been set."),
          )
        } else {
          reportErrorAndShowMessage(error, localizationKey("Error updating ticket"))
        }
      }
    },
    [onComplete, requestUid],
  )

  const { columns, primaryFilters } = useMemo(() => {
    const severityLabels = optionsReducer(getSeverityOptions())
    const priorityLabels = optionsReducer(getPriorityOptions())
    const ticketRelationshipMapper = {
      PROBLEM: localized("Problem"),
      INCIDENT: localized("Incident"),
    }

    return {
      columns: [
        {
          id: "status",
          Header: localized("Status"),
          accessor: ({ status }) => status?.displayName,
        },
        {
          id: "id",
          Header: localized("Ticket ID"),
          accessor: "id",
        },
        {
          id: "relationship",
          Header: localized("Relationship"),
          accessor: ({ type }) => ticketRelationshipMapper[type] || ticketRelationshipMapper["PROBLEM"],
        },
        {
          id: "summary",
          Header: localized("Subject"),
          accessor: "summary",
          minWidth: "300px",
          maxWidth: "450px",
          ignoreTooltip: true,
        },
        ...(hasProblem
          ? []
          : [
              {
                id: "severity",
                Header: localized("Severity"),
                accessor: ({ severity }) => severityLabels[severity],
              },
              {
                id: "priority",
                Header: localized("Priority"),
                accessor: ({ priority }) => priorityLabels[priority],
              },
            ]),
      ],
      primaryFilters: hasProblem
        ? []
        : [
            {
              name: "severity",
              type: filterTypes.SINGLE_SELECT,
              labelToken: localizationKey("Severity"),
              componentProps: {
                options: getSeverityOptions(),
              },
            },
            {
              name: "priority",
              type: filterTypes.SINGLE_SELECT,
              labelToken: localizationKey("Priority"),
              componentProps: {
                options: getPriorityOptions(),
              },
            },
          ],
    }
  }, [hasProblem])

  return (
    <Card>
      <Flex height="100%" flexDirection="column" position="relative">
        <DataTable
          tableId="related-tickets-table"
          columns={columns}
          getStatusIndicatorColor={({ status }) => getStatusBackgroundFromId(status?.statusId)}
          getStatusIndicatorBorderColor={({ status }) =>
            isClosedStatus(status?.statusId) ? theme.colorBackgroundAccentNeutral : "transparent"
          }
          rows={relatedTickets}
          initialSortBy={[{ id: "id", desc: true }]}
          hideCheckboxes
          getExportButtonProps={() => ({
            action: () => console.log(`TODO: export related tickets`),
          })}
          actions={{
            row: { action: row => window.open(buildTicketEditorRouteWithHash(row.id), "_blank", "noopener") },
            primary: [
              {
                action: ({ selected }) =>
                  showModal(<ReAssignIncidentModal ticketId={selected[0]?.id} onSave={handleReassignTicket} />),
                labelToken: localizationKey("Reassign Incident"),
                hideRowAction: selected => selected.type !== "INCIDENT" || isClosedStatus(selected.status?.statusId),
              },
            ],
          }}
          filters={{
            primary: primaryFilters,
          }}
          showSearchBar
          globalFilterCharLimit={200}
        />
      </Flex>
    </Card>
  )
})

_RelatedTickets.propTypes = {
  hasProblem: PropTypes.bool,
  relatedTickets: PropTypes.arrayOf(PropTypes.object).isRequired,
  onComplete: PropTypes.func,
}

export const RelatedTickets = connect(
  ({ ticketing }) => ({
    requestUid: ticketing.editor.requestUid,
  }),
  {
    setRequestUid: _setRequestUid,
  },
)(_RelatedTickets)
