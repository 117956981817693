import { Checkbox } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import withInheritance from "js/includes/components/withInheritance"
import { getSettings } from "./util"

const AllowRsrCheckbox = ({ inheritableItem: allowRsrInstallationSettings, onChange }) => {
  return (
    <Box marginTop={tokens.spacing[2]}>
      <Checkbox
        label={localized("Allow the user to install Rapid Security Responses")}
        checked={getSettings(allowRsrInstallationSettings, "allowRsrInstallation")}
        onChange={({ isChecked }) => {
          onChange({
            ...allowRsrInstallationSettings,
            allowRsrInstallation: isChecked,
          })
        }}
      />
    </Box>
  )
}

export default withInheritance(AllowRsrCheckbox)
