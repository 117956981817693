import { includes, memoizeWith, identity, pluck, filter } from "ramda"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import showModal from "js/includes/common/services/showModal"
import {
  getMobileData,
  isNilOrEmpty,
  isNotNilOrEmpty,
  localizationKey,
  localized,
  memoize,
  reportErrorAndShowMessage,
  runDeviceSearchToolbarAction,
  showErrorMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import {
  fetchMobileMultipleActions,
  fetchMobileNode,
  fetchMobileNodeDash,
  getMobileActionsByOwnership,
  sendActionMultipleDevices,
} from "js/includes/common/client"
import ResetPasscodeModal from "./ResetPasscodeModal"
import LockDeviceModal from "./LockDeviceModal"
import EraseDeviceModal from "./EraseDeviceModal"
import { NinjaResponseError } from "js/includes/common/types"
import ClearPasscodeModal from "./ClearPasscodeModal"
import { DisownDeviceModal } from "./DisownDeviceModal"
import { RenewProfileModal } from "./RenewProfileModal"

export const ActionId = {
  ERASE_DEVICE: "ERASE_DEVICE",
  SYNC_POLICY: "SYNC_POLICY",
}

const handleOnClose = organizationId => {
  window.location.hash = organizationId ? `/customerDashboard/${organizationId}/overview` : "/systemDashboard/overview"
}

const handleDisownDevice = (mobileDevice, runnerActionName) => {
  const { nodeIds, clientId } = mobileDevice
  showModal(
    <DisownDeviceModal
      {...{ nodeIds, onSuccess: () => handleOnClose(clientId), ...(runnerActionName && { runnerActionName }) }}
    />,
    {
      withProvider: true,
    },
  )
}

const handleEraseDevice = (mobileDevice, runnerActionName) => {
  if (isNilOrEmpty(mobileDevice?.nodeIds)) {
    showErrorMessage(localized("No device selected"))
    return
  }
  const { nodeIds, clientId: organizationId, nodeClass, isMultiDevice } = mobileDevice

  showModal(
    <EraseDeviceModal
      {...{ nodeIds, organizationId, nodeClass, isMultiDevice, ...(runnerActionName && { runnerActionName }) }}
    />,
  )
}

const handleRebootDevice = async (nodeIds, runnerActionName) => {
  if (isNilOrEmpty(nodeIds)) {
    showErrorMessage(localized("No device selected"))
    return
  }

  const confirmReboot = await ShowMessageDialog({
    icon: { icon: faExclamation, type: "critical" },
    title: localizationKey("Warning! You are about to reboot this device"),
    message: localizationKey("This action cannot be undone."),
    buttons: [
      { id: true, label: localizationKey("Reboot"), type: "critical" },
      { id: false, label: localizationKey("Cancel") },
    ],
  })

  if (confirmReboot) {
    try {
      if (runnerActionName) {
        await runDeviceSearchToolbarAction({ action: runnerActionName })
      } else {
        await sendActionMultipleDevices(nodeIds, { type: "REBOOT_DEVICE" })
      }
      showSuccessMessage(localized("The action was sent successfully"))
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("The action could not be sent"))
      }
    }
  }
}

const handleResyncPolicy = async (nodeIds, runnerActionName) => {
  try {
    if (runnerActionName) {
      await runDeviceSearchToolbarAction({ action: runnerActionName })
    } else {
      await sendActionMultipleDevices(nodeIds, { type: ActionId.SYNC_POLICY })
    }
    showSuccessMessage("Resync was successful.")
  } catch (error) {
    if (!error.isDevicesCacheExpiredError) {
      reportErrorAndShowMessage(error, localized("There was an error resyncing the policy, please try again."))
    }
  }
}

const handleRenewEnrollmentProfile = (mobileDevice, runnerActionName) => {
  showModal(<RenewProfileModal {...{ nodeIds: mobileDevice.nodeIds, ...(runnerActionName && { runnerActionName }) }} />)
}

export const getMobileDeviceActions = memoizeWith(identity, async id => {
  const [mobileNodeDash, mobileNode] = await Promise.all([fetchMobileNodeDash(id), fetchMobileNode(id)])

  if (mobileNodeDash?.resultCode !== "SUCCESS") {
    throw new NinjaResponseError(mobileNodeDash)
  }

  const [mobile, allowedActions] = await Promise.all([
    getMobileData(mobileNodeDash, mobileNode),
    getMobileActionsByOwnership(id),
  ])

  return { mobile, allowedActions }
})

export const getCommonActionFromDevices = memoize(async selectedDevices => {
  const nodeIds = pluck("id")(selectedDevices)
  const response = await fetchMobileMultipleActions(nodeIds)
  return response
})

const isActionEnabled = (actions, id) => {
  const action = actions.find(action => action.name === id)
  return action?.enabled ?? false
}

const mobileRunnerActionNames = {
  LOCK_DEVICE: "RUN_SECURITY_ACTION_LOCK_DEVICE",
  RESET_PASSCODE_DEVICE: "RUN_SECURITY_ACTION_RESET_PASSCODE",
  CLEAR_PASSCODE_DEVICE: "RUN_SECURITY_ACTION_CLEAR_PASSCODE",
  REBOOT_DEVICE: "RUN_SECURITY_ACTION_REBOOT_DEVICE",
  DISOWN: "RUN_SECURITY_ACTION_DISOWN_DEVICE",
  SYNC_POLICY: "RUN_SECURITY_ACTION_RESYNC_POLICIES",
  RENEW_ENROLLMENT_PROFILE: "RUN_SECURITY_ACTION_RENEW_ENROLLMENT_PROFILE",
  ERASE_DEVICE: "RUN_SECURITY_ACTION_ERASE_DEVICE",
}

export const getActions = ({ mobileDevice, disabled, fromDeviceSearch = false }) => {
  return [
    {
      id: "LOCK_DEVICE",
      runnerActionName: mobileRunnerActionNames.LOCK_DEVICE,
      token: localizationKey("Lock device"),
      disabled,
      className: "",
      onClick: () =>
        showModal(
          <LockDeviceModal
            {...{
              nodeIds: mobileDevice?.nodeIds,
              ...(fromDeviceSearch && { runnerActionName: mobileRunnerActionNames.LOCK_DEVICE }),
            }}
          />,
        ),
    },
    {
      id: "RESET_PASSCODE_DEVICE",
      runnerActionName: mobileRunnerActionNames.RESET_PASSCODE_DEVICE,
      token: localizationKey("Reset passcode"),
      disabled,
      className: "",
      onClick: () =>
        showModal(
          <ResetPasscodeModal
            {...{
              nodeIds: mobileDevice?.nodeIds,
              ...(fromDeviceSearch && { runnerActionName: mobileRunnerActionNames.RESET_PASSCODE_DEVICE }),
            }}
          />,
        ),
    },
    {
      id: "CLEAR_PASSCODE_DEVICE",
      runnerActionName: mobileRunnerActionNames.CLEAR_PASSCODE_DEVICE,
      token: localizationKey("Clear passcode"),
      disabled,
      className: "",
      onClick: () =>
        showModal(
          <ClearPasscodeModal
            {...{
              nodeIds: mobileDevice?.nodeIds,
              ...(fromDeviceSearch && { runnerActionName: mobileRunnerActionNames.CLEAR_PASSCODE_DEVICE }),
            }}
          />,
        ),
    },
    {
      id: "REBOOT_DEVICE",
      runnerActionName: mobileRunnerActionNames.REBOOT_DEVICE,
      token: localizationKey("Reboot device"),
      disabled,
      className: "",
      onClick: () =>
        handleRebootDevice(mobileDevice?.nodeIds, fromDeviceSearch && mobileRunnerActionNames.REBOOT_DEVICE),
    },
    {
      id: "DISOWN",
      runnerActionName: mobileRunnerActionNames.DISOWN,
      token: localizationKey("Disown device"),
      disabled,
      onClick: () => handleDisownDevice(mobileDevice, fromDeviceSearch && mobileRunnerActionNames.DISOWN),
    },
    {
      id: ActionId.SYNC_POLICY,
      runnerActionName: mobileRunnerActionNames.SYNC_POLICY,
      token: localizationKey("Resync policies"),
      disabled,
      onClick: () => {
        handleResyncPolicy(mobileDevice?.nodeIds, fromDeviceSearch && mobileRunnerActionNames.SYNC_POLICY)
      },
    },
    {
      id: "RENEW_ENROLLMENT_PROFILE",
      runnerActionName: mobileRunnerActionNames.RENEW_ENROLLMENT_PROFILE,
      token: localizationKey("Renew enrollment profile"),
      disabled,
      onClick: () =>
        handleRenewEnrollmentProfile(
          mobileDevice,
          fromDeviceSearch && mobileRunnerActionNames.RENEW_ENROLLMENT_PROFILE,
        ),
    },
    {
      id: ActionId.ERASE_DEVICE,
      runnerActionName: mobileRunnerActionNames.ERASE_DEVICE,
      token: localizationKey("Erase device"),
      disabled,
      className: !disabled && "text-danger",
      onClick: () => handleEraseDevice(mobileDevice, fromDeviceSearch && mobileRunnerActionNames.ERASE_DEVICE),
    },
  ]
}

export const getCustomActions = (mobileDevice, customOwnershipActions) => {
  const disabled = isNotNilOrEmpty(mobileDevice?.mdmStatus)
  const device = {
    nodeIds: [mobileDevice?.nodeId],
    nodeClass: mobileDevice?.nodeClass,
    organizationId: mobileDevice?.clientId,
  }
  return filter(actionItem => includes(actionItem.id, customOwnershipActions))(
    getActions({ mobileDevice: device, disabled }),
  )
}

export const getCustomMultipleActions = (selectedDevices, ownershipActions) => {
  const mobileDevice = {
    nodeIds: selectedDevices,
    isMultiDevice: true,
  }
  return filter(
    actionItem =>
      includes(actionItem.id, pluck("name", ownershipActions)) && isActionEnabled(ownershipActions, actionItem.id),
  )(getActions({ mobileDevice, disabled: false }))
}
