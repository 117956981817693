import { localized } from "js/includes/common/utils/ssrAndWebUtils"

export function validateHostname(hostname) {
  if (!hostname) {
    return localized("Hostname cannot be empty")
  }
  if (!notValidFormat(hostname)) {
    return localized("Hostname does not match the expected format")
  }
}

function notValidFormat(hostname) {
  // We cannot allow dots on hostnames. Wilcard certs will not work for sub sub hostnames.
  // So only alfanumeric with dashes (not stating or finishing with dash) is allowed.
  const hostnameRegEx = /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])$/
  return hostnameRegEx.test(hostname)
}
