import { fetchJson, localized, ninjaReportError, showErrorMessage } from "js/includes/common/utils"
import { completeStepGettingStarted } from "js/state/actions/session/gettingStarted"

export const updateGettingStartedStepsStatus = async ({ stepsCompleted, stepIdToChange, currentStep }) => {
  const propertyToUpdate = currentStep ? { currentStep } : { [stepIdToChange]: true }
  try {
    await fetchJson("/appuser/getting-started-steps", {
      options: {
        method: "PATCH",
        body: JSON.stringify({ ...stepsCompleted, ...propertyToUpdate }),
      },
    })
    if (!currentStep) window.store.dispatch(completeStepGettingStarted(stepIdToChange))
  } catch (e) {
    showErrorMessage(localized("Error updating get started steps"))
    ninjaReportError(e)
  }
}
