import styled from "@emotion/styled"
import { Heading } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"
import {
  getStartedQuickLinks,
  getStartedAdditionalResourcesForLevelUp,
  getStartedAdditionalResourcesForCriticalSetup,
} from "js/includes/GetStartedPage/gettingStartedData"
import DownloadMobileAppIcons from "./components/DownloadMobileAppIcons"
import Link from "js/includes/components/Link"

const StyledFooter = styled(Box)`
  display: grid;
  grid-template-columns: 30% auto;
  background-color: ${({ theme }) => theme.colorBackgroundSubtle};
  padding: ${spacing[6]};
  margin-top: ${spacing[6]};
  gap: ${spacing[6]};
`

const LinksWrapper = styled(Box)`
  border-left: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  padding-left: ${spacing[6]};
`

const StyledQuickLinks = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(170px, 100%), 50%));
  margin: ${spacing[4]} 0 ${spacing[10]};
`

const LevelUpWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${spacing[1]};

  div:first-of-type {
    grid-column: 1 / span 2;
    margin-bottom: ${spacing[3]};
  }
`

const StyledContent = styled(Flex)`
  align-items: center;
  flex-wrap: nowrap;
  gap: ${spacing[2]};
  margin-bottom: ${spacing[4]};
`

const GetStartedFooter = () => {
  return (
    <StyledFooter>
      <LinksWrapper>
        <Heading level={2} type="headingS">
          {localized("Quick links")}
        </Heading>
        <StyledQuickLinks>
          {getStartedQuickLinks.map(({ labelToken, link, Icon }) => (
            <Link key={labelToken} href={link} increaseContrast>
              <StyledContent id={`fs-getStarted-quickLinks-${labelToken}`}>
                <Icon />
                {localized(labelToken)}
              </StyledContent>
            </Link>
          ))}
        </StyledQuickLinks>

        <Heading level={3} type="headingXs" marginBottom={spacing[2]} color="colorTextWeak">
          {localized("Download our mobile app")}
        </Heading>
        <Flex gap={spacing[2]}>
          <DownloadMobileAppIcons />
        </Flex>
      </LinksWrapper>

      <LinksWrapper>
        <Heading level={2} type="headingS">
          {localized("Additional resources")}
        </Heading>
        <Box display="grid" gridTemplateColumns="1fr 2fr" marginTop={spacing[4]}>
          <Flex flexDirection="column" gap={spacing[1]}>
            <Heading level={3} type="headingXs" marginBottom={spacing[3]}>
              {localized("Critical setup")}
            </Heading>
            {getStartedAdditionalResourcesForCriticalSetup.map(({ labelToken, link }) => (
              <Link key={labelToken} href={link} increaseContrast>
                {localized(labelToken)}
              </Link>
            ))}
          </Flex>

          <div>
            <Heading level={4} marginBottom={spacing[4]}>
              {localized("Level up")}
            </Heading>
            <LevelUpWrapper>
              {getStartedAdditionalResourcesForLevelUp.map(({ labelToken, link }) => (
                <Link key={labelToken} href={link} increaseContrast>
                  {localized(labelToken)}
                </Link>
              ))}
            </LevelUpWrapper>
          </div>
        </Box>
      </LinksWrapper>
    </StyledFooter>
  )
}

export default GetStartedFooter
