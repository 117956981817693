import { useMemo, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "@emotion/styled"
import { borderRadius, spacing, typography } from "@ninjaone/tokens"
import { AlertMessage, Modal, Body, Heading, TextArea, Button } from "@ninjaone/components"
import { DislikeIcon, LikeIcon, PartyPopperIcon, ServerIconLight, LightBulbIcon } from "@ninjaone/icons"
import {
  localizationKey,
  localized,
  localizedWith,
  ninjaReportError,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import { sendAdditionalFeedbackToSalesforce, sendFeedbackToSalesforce } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { backupMarketingPromo } from "js/includes/components/MarketingPromoPopup/promotions/helpers"
import { DAYS, NINETY_ONE_DAYS_THRESHOLD } from "./utils/accountCreation"
import useIsOldAccount from "js/includes/GetStartedPage/utils/useIsOldAccount"

const StyledCard = styled(Box)`
  border-radius: ${borderRadius[1]};
  border: 0;
  padding: ${spacing[6]};
  background-color: ${({ theme }) => theme.colorAlertInfoWeak};
`

const FEEDBACK_CHARACTER_LIMIT = 1000

const ModalFeedback = ({ unmount, isRequired }) => {
  const [feedback, setFeedback] = useState("")
  const [error, setError] = useMountedState("")
  const [isTextAreaEmpty, setIsTextAreaEmpty] = useMountedState(true)

  const submitFeedback = async () => {
    try {
      await sendAdditionalFeedbackToSalesforce(feedback)
      showSuccessMessage(localized("Thank you for providing feedback"))
      unmount()
    } catch (err) {
      setError(localized("Something went wrong on our end. Please try again"))
      ninjaReportError(err)
    }
  }

  const isSubmitDisabled = isRequired && isTextAreaEmpty

  return (
    <Modal
      buttons={[
        {
          labelToken: localizationKey("Submit"),
          variant: "primary",
          onClick: () => {
            submitFeedback()
          },
          disabled: isSubmitDisabled,
        },
      ]}
      cancelable
      titleGroup={{ titleToken: localizationKey("Additional feedback") }}
      unmount={unmount}
      withCloseX
    >
      <Body>
        {localized("Let us know what we can do to improve")}
        {isRequired && <sup>*</sup>}
      </Body>
      <Flex flexDirection="column" marginTop={spacing[1]} width="100%">
        <TextArea
          ariaLabel={localized("Additional feedback")}
          height="122px"
          maxLength={FEEDBACK_CHARACTER_LIMIT}
          value={feedback}
          onChange={e => {
            setFeedback(e.target.value)
            setIsTextAreaEmpty(e.target.value.trim() === "")
            setError("")
          }}
        />

        {error && <AlertMessage labelToken={error} variant="danger" />}
      </Flex>
    </Modal>
  )
}

const FinishTraining = ({ marketingPromo, userInvitationAcceptedTime }) => {
  const backupPromoData = useMemo(() => marketingPromo?.find(({ id }) => id === backupMarketingPromo.id), [
    marketingPromo,
  ])

  const showFeedBack = async wasItHelpful => {
    try {
      await sendFeedbackToSalesforce(wasItHelpful)
      showModal(<ModalFeedback isRequired={!wasItHelpful} />)
    } catch (err) {
      reportErrorAndShowMessage(err, localizationKey("Please try again"))
    }
  }

  const isOldUserAccount = useIsOldAccount(userInvitationAcceptedTime, DAYS, NINETY_ONE_DAYS_THRESHOLD)
  const finishTrainingIcon = isOldUserAccount ? (
    <LightBulbIcon color="colorTextStrong" />
  ) : (
    <PartyPopperIcon color="colorTextStrong" />
  )
  const finishTrainingMessageTitle = isOldUserAccount
    ? localized("Tips for getting started")
    : localized("Congrats on completing your NinjaOne training!")
  const finishTrainingMessageDescription = isOldUserAccount
    ? localizedWith(
        "Review the steps and articles in our checklist to get the most out of NinjaOne. For additional training, see <%bold>Critical setup<%> and <%bold>Level up<%> guides below. As always, you can reach out to your Product Specialists for additional help. Training and support are always free.",
        { bold: ({ localizedText }) => <strong>{localizedText}</strong> },
      )
    : localized(
        "You are officially a ninja. For additional training, see Critical setup and Level up guides below. As always, please reach out to your Product Specialists for additional help. Training and support are always free.",
      )

  return (
    <Flex gap={spacing[4]} flexDirection="column">
      <StyledCard>
        <Flex gap={spacing[3]} alignItems="center" marginBottom={spacing[3]} justifyContent="flex-start">
          {finishTrainingIcon}
          <Heading level={2} type="headingS" fontWeight={typography.fontWeight.semiBold}>
            {finishTrainingMessageTitle}
          </Heading>
        </Flex>

        <Body textWrap>{finishTrainingMessageDescription}</Body>

        <Flex marginTop={spacing[9]} gap={spacing[4]}>
          <Body>{localized("Did you find this guide helpful?")}</Body>
          <Button
            id="fs-getStarted-feedback-thumbsUp-btn"
            onClick={() => showFeedBack(true)}
            Icon={LikeIcon}
            labelText={localized("Yes")}
            type="tertiary"
            variant="default"
            transparent
            compact
            increaseContrast
          />
          <Button
            id="fs-getStarted-feedback-thumbsDown-btn"
            onClick={() => showFeedBack(false)}
            Icon={DislikeIcon}
            labelText={localized("No")}
            type="tertiary"
            variant="default"
            transparent
            compact
            increaseContrast
          />
        </Flex>
      </StyledCard>

      {backupPromoData && (
        <StyledCard>
          <Flex gap={spacing[3]} alignItems="center" marginBottom={spacing[3]} justifyContent="flex-start">
            <ServerIconLight color="colorTextStrong" />
            <Heading level={2} type="headingS">
              {localized(backupPromoData.titleToken)}
            </Heading>
          </Flex>

          <Body textWrap>{localized(backupPromoData.descriptionToken)}</Body>

          <Flex justifyContent="flex-end" marginTop={spacing[4]}>
            <Button type="primary" childIsLink>
              <Link to="/administration/apps/backup" replace>
                {localized("Learn more")}
              </Link>
            </Button>
          </Flex>
        </StyledCard>
      )}
    </Flex>
  )
}

export default connect(state => ({
  marketingPromo: state.marketingPromo,
  userInvitationAcceptedTime: state.session.user.userInvitationAcceptedTime,
}))(FinishTraining)
