import { fetchJson } from "js/includes/common/utils"
import { splitIpTargets } from "js/includes/networkDiscovery/utils"

export const getNetworkProbes = (clientId, locationId) => fetchJson(`/nms/delegates/${clientId}/${locationId}`)

export const installDelegate = probeId =>
  fetchJson(`/nms/install-nm-agent/${probeId}`, {
    options: {
      method: "POST",
    },
  })

export const statusCheck = probeId => window.wamp.call(probeId, "nm.status", [], {})

export const startDiscovery = ({
  org,
  networkProbe,
  ipTargets,
  snmpCredOne,
  protocolOne,
  snmpCredTwo,
  protocolTwo,
  telnetSshCred,
  pingTimeoutMS,
  pingPacketTTL,
  pingsPerNode,
  delayBetweenPingsMS,
}) => {
  const payload = {
    clientId: org.id,
    delegateNodeId: networkProbe.nodeId,
    targets: splitIpTargets(ipTargets),
    firstSnmpCredentialId: snmpCredOne.value,
    firstSnmpCredentialProtocolVersion: protocolOne.value,
    ...(Number(snmpCredTwo?.value) && {
      secondSnmpCredentialId: snmpCredTwo.value,
      secondSnmpCredentialProtocolVersion: protocolTwo.value,
    }),
    ...(Number(telnetSshCred?.value) && { telnetSSHCredentialId: telnetSshCred.value }),
    pingTimeoutMS: Number(pingTimeoutMS),
    pingPacketTTL: Number(pingPacketTTL),
    pingsPerNode: Number(pingsPerNode),
    delayBetweenPingsMS: Number(delayBetweenPingsMS),
  }

  return fetchJson("/nms/start-network-discovery", {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}

export const cancelDiscovery = jobId =>
  fetchJson(`/nms/cancel-network-discovery/${jobId}`, {
    options: {
      method: "POST",
    },
  })

const formatPayload = ({ devices, locationId, nmsDelegateNodeId, telnetSshCred }) =>
  devices.map(
    ({
      ip,
      role = null,
      serialNumber = null,
      hostname = null,
      dnsName = null,
      netbiosName = null,
      mac = null,
      telnet,
      ssh,
      snmpv1,
      snmpv2,
      snmpv3,
    }) => ({
      ip,
      role,
      serialNumber,
      deviceName: hostname,
      dnsName,
      locationId,
      nmsDelegateNodeId,
      netbiosName,
      mac,
      credentialsIds: [
        // TODO: see if Dmitry can return telnet/ssh id instead of boolean
        ...(telnet || ssh ? [telnetSshCred.value] : []),
        ...(snmpv1?.length ? snmpv1 : []),
        ...(snmpv2?.length ? snmpv2 : []),
        ...(snmpv3?.length ? snmpv3 : []),
      ],
    }),
  )

export const addDevices = ({ orgId, locationId, nmsDelegateNodeId, devices, telnetSshCred }) => {
  const payload = formatPayload({ devices, locationId, nmsDelegateNodeId, telnetSshCred })
  return fetchJson(`/nms/import-devices/${orgId}`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}

// finds additional information about device such as if it already exists or is online, etc.
export const getAdditionalDevicesData = ({ orgId, locationId, nmsDelegateNodeId, devices, telnetSshCred }) => {
  const payload = formatPayload({ devices, locationId, nmsDelegateNodeId, telnetSshCred })
  return fetchJson(`/nms/aggregate-known-devices/${orgId}`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}

export const getNmsTargetsById = nodeId => fetchJson(`/nms/legacyTargets/${nodeId}`)

const findV1orV2Credential = credentials =>
  credentials.find(({ protocol }) => protocol === "SNMPV1" || protocol === "SNMPV2")

export function formatNmsTargetsPayload(nmsTargets) {
  return (nmsTargets || []).map(({ nodeId, credentials }) => {
    const v1OrV2Credential = findV1orV2Credential(credentials)
    return {
      nodeId,
      legacySNMPCredentialsForConversion: [
        ...(v1OrV2Credential
          ? [
              {
                credentialId: v1OrV2Credential.id,
                newProtocol: v1OrV2Credential.protocol,
              },
            ]
          : []),
      ],
    }
  })
}

export const migrateNmsTargets = (probeId, payload) => {
  return fetchJson(`/nms/migrateLegacyTargets/${probeId}`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}
