import React, { PureComponent } from "react"
import { FormControl } from "react-bootstrap"

export default class Dropdown extends PureComponent {
  constructor(props) {
    super(props)
    const { options, initialValue } = props
    this.state = {
      selected: initialValue || options.value,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.setState({ selected: e.target.value }, () => {
      this.props.onChange(this.state.selected || null)
    })
  }

  render() {
    const { options, initialValue, disabled, onBlur } = this.props

    return (
      <FormControl
        componentClass="select"
        onChange={this.handleChange}
        defaultValue={initialValue || ""}
        disabled={disabled}
        {...{ onBlur }}
      >
        {options.items &&
          options.items.map(item => {
            const text = typeof item.text === "function" ? item.text() : item.text
            return (
              <option key={item.value} value={item.value || ""}>
                {text}
              </option>
            )
          })}
      </FormControl>
    )
  }
}
