import { useEffect, useMemo } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { find, propEq, mapObjIndexed, reject, isEmpty } from "ramda"

import tokens from "@ninjaone/tokens"
import { Input, Modal, Select, Text, ButtonGroup, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import { StyledLabel } from "@ninjaone/components/src/Input"

import { setAndroidCriticalApps } from "js/state/actions/mdm/mdmConfiguration"
import {
  localizationKey,
  localized,
  validations,
  showErrorMessage,
  ninjaReportError,
  isNotNilOrEmpty,
  applyMultipleValidations,
} from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { getAndroidCriticalApps, getApplicationByPackageName } from "js/includes/common/client"

import { Box, Flex } from "js/includes/components/Styled"
import Loading from "js/includes/components/Loading"
import {
  AndroidPolicyAppsInstallTypes as InstallTypes,
  AndroidPolicyAppsInstallTypeOptions as installTypeOptions,
  AndroidPolicyAppsApplicationSources as ApplicationSources,
  isValidPackageName,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import MobileApplicationsSelectorModal from "js/includes/editors/Policy/PolicyEditor/formComponents/MobileApplicationsSelectorModal"

const systemAppInstallTypes = [
  InstallTypes.PREINSTALLED,
  InstallTypes.FORCE_INSTALLED,
  InstallTypes.BLOCKED,
  InstallTypes.KIOSK,
]

const ExtraModal = ({ unmount, titleToken, textContentToken, buttonRenderer }) => {
  return (
    <Modal
      titleGroup={{
        titleToken,
        descriptionToken: textContentToken,
        textWrapLineLimit: 10,
      }}
      cancelable={!buttonRenderer}
      unmount={unmount}
      buttonRenderer={buttonRenderer && buttonRenderer(unmount)}
    />
  )
}

const showExtraModal = ({ titleToken, textContentToken, buttonRenderer }) => {
  showModal(<ExtraModal {...{ titleToken, textContentToken, buttonRenderer }} />)
}

const CreateLabel = ({ id, text, info, required = false }) => {
  return () => (
    <Flex>
      <StyledLabel htmlFor={id}>
        {text}
        {required && <span aria-hidden="true"> *</span>}
      </StyledLabel>

      {info && (
        <Box marginLeft={tokens.spacing[1]} marginTop="2px">
          <Tooltip label={info}>
            <RegularInfoCircleIcon size="sm" />
          </Tooltip>
        </Box>
      )}
    </Flex>
  )
}

const FieldWrapper = ({ children, tooltipTextToken }) => {
  return (
    <Box marginBottom={tokens.spacing[4]}>
      {!!tooltipTextToken ? <Tooltip label={localized(tooltipTextToken)}>{children}</Tooltip> : children}
    </Box>
  )
}

const validatePackageNameFormat = packageName => {
  const success = isNotNilOrEmpty(packageName) && isValidPackageName(packageName)
  return {
    success,
    message: success
      ? ""
      : localized(
          "The package name must have at least two segments, each segment must start with a letter and all characters must be alphanumeric or an underscore.",
        ),
  }
}

const SystemAppModal = ({
  unmount,
  onSave,
  policyAppsList = [],
  app = {},
  addViaGooglePlay,
  criticalAppsList,
  setAndroidCriticalApps,
  androidEnterpriseConnections,
}) => {
  const [loadingPlayStoreCheck, setLoadingPlayStoreCheck] = useMountedState(false)

  const policyAppsPackageNameList = useMemo(() => policyAppsList.map(app => app.packageName), [policyAppsList])

  const isEditAction = !!app.packageName

  useEffect(() => {
    const fetchCriticalApps = async () => {
      try {
        const data = await getAndroidCriticalApps()

        setAndroidCriticalApps(data)
      } catch (error) {
        ninjaReportError(error)
        showErrorMessage(localized("Error getting Android critical system apps"))
      }
    }

    // Fetch critical apps if not part of store yet
    if (!criticalAppsList) {
      fetchCriticalApps()
    }
  }, [criticalAppsList, setAndroidCriticalApps])

  const { packageName, installType, name, publisher } = app

  const { values, validation, onChange, validateForm } = useForm({
    validateOnChange: true,
    fields: {
      packageName: packageName || "",
      installType: installType || "",
      name: name || "",
      publisher: publisher || "",
    },
    validate: {
      packageName: applyMultipleValidations([validations.required, validatePackageNameFormat]),
      installType: validations.required,
    },
  })

  const fieldIds = mapObjIndexed((value, key) => `system-app-${key}`, values)

  const systemAppInstallTypeOptions = useMemo(
    () => installTypeOptions.filter(option => systemAppInstallTypes.includes(option.value)),
    [],
  )

  const saveApp = () => {
    onSave({ ...reject(isEmpty, values), applicationSource: ApplicationSources.SYSTEM_APP })
    unmount()
  }

  const handleConfirm = async () => {
    if (!validateForm()) return
    const { installType, packageName } = values

    // Error - attempting to block an Android critical app
    if (criticalAppsList?.includes(packageName) && installType === InstallTypes.BLOCKED) {
      showExtraModal({
        titleToken: localizationKey("App cannot be added"),
        textContentToken: localizationKey(
          "This package name is associated with an application vital to the stability of Android. It cannot be blocked.",
        ),
      })
      return
    }

    // Save edit - no need for extra checks because edit doesn't allow changing the package name
    if (isEditAction) {
      saveApp()
      return
    }

    // Error - app already part of policy
    if (policyAppsPackageNameList.includes(packageName)) {
      showExtraModal({
        titleToken: localizationKey("App cannot be added"),
        textContentToken: localizationKey(
          "This package name is already associated with this policy. Apps cannot be duplicated.",
        ),
      })
      return
    }

    try {
      setLoadingPlayStoreCheck(true)
      const connectionId = androidEnterpriseConnections[0]?.id

      await getApplicationByPackageName(values.packageName, connectionId)
      // App exists in Play Store, give option to add it that way
      showExtraModal({
        titleToken: localizationKey("App was found in Play Store"),
        textContentToken: localizationKey(
          "Adding a Play Store app as a System app will have limited capabilities. For full capabilities, please add it through the Play Store.",
        ),
        buttonRenderer: unmountModal => () => (
          <ButtonGroup
            buttons={[
              {
                onClick: () => {
                  openGooglePlayIFrame()
                  unmountModal?.()
                },
                labelToken: localizationKey("Open Play Store"),
                variant: "secondary",
              },
              {
                type: "save",
                onClick: () => {
                  saveApp()
                  unmountModal?.()
                },
                labelToken: localizationKey("Add app"),
              },
            ]}
          />
        ),
      })
    } catch (error) {
      // App not in Play Store, add directly
      if (error.response?.status === 404) {
        saveApp()
        return
      }
      showErrorMessage(localized("Error during Play Store check, please try again"))
    } finally {
      setLoadingPlayStoreCheck(false)
    }
  }

  const openGooglePlayIFrame = () => {
    showModal(
      <MobileApplicationsSelectorModal
        applicationPolicy={policyAppsList}
        search={values.packageName}
        onApplicationSelected={data => {
          unmount()
          addViaGooglePlay(data)
        }}
        androidEnterpriseConnections={androidEnterpriseConnections}
      />,
      {
        withProvider: true,
      },
    )
  }

  return (
    <Modal
      titleGroup={{
        titleToken: isEditAction ? localizationKey("Edit applications policy") : localizationKey("Add system app"),
      }}
      buttonRenderer={() => (
        <Flex justifyContent="space-around">
          {loadingPlayStoreCheck && <Loading loadingText={localizationKey("Checking in Play Store")} withExtraSpace />}

          <ButtonGroup
            buttons={[
              { type: "close", labelToken: localizationKey("Cancel"), onClick: unmount, variant: "secondary" },
              {
                type: "save",
                onClick: handleConfirm,
                labelToken: localizationKey("Confirm"),
                disabled: !isEditAction && (!validation.success.packageName || !validation.success.installType),
              },
            ]}
          />
        </Flex>
      )}
      size="md"
      unmount={unmount}
      cancelable
    >
      <FieldWrapper {...(isEditAction && { tooltipTextToken: localizationKey("Can't edit package name") })}>
        <Input
          id={fieldIds.packageName}
          value={values.packageName}
          required
          labelToken={localized("Package name")}
          tooltipText={
            !isEditAction
              ? localized(
                  "Insert package name for the preinstalled system app you wish to manage (ex. com.samsung.android.da.daagent)",
                )
              : null
          }
          disabled={isEditAction || loadingPlayStoreCheck}
          placeholder="Ex: com.samsung.android.da.daagent"
          onChange={e => onChange("packageName", e.target.value)}
          errorMessage={validation.message.packageName}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Select
          id={fieldIds.installType}
          labelId={fieldIds.installType}
          value={values.installType}
          options={systemAppInstallTypeOptions}
          required
          triggerAriaLabel={localized("Assignment type")}
          titleRenderer={CreateLabel({
            id: fieldIds.installType,
            text: localized("Assignment type"),
            required: true,
          })}
          labelRenderer={() =>
            (values.installType &&
              localized(find(propEq("value", values.installType))(installTypeOptions)?.labelToken)) || (
              <Text color="colorTextWeakest" size="sm">
                {localized("Select")}
              </Text>
            )
          }
          disabled={loadingPlayStoreCheck}
          alignRight={false}
          onChange={e => onChange("installType", e)}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Input
          id={fieldIds.name}
          value={values.name}
          labelToken={localized("Name")}
          tooltipText={localized(
            "This field is optional and will be used as the Name or Display Name for the app in the Applications list within this policy.",
          )}
          disabled={loadingPlayStoreCheck}
          placeholder={localizationKey("Enter app name")}
          onChange={e => onChange("name", e.target.value)}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Input
          id={fieldIds.publisher}
          value={values.publisher}
          labelToken={localized("Publisher")}
          tooltipText={localized(
            "This field is optional and will be used as the Publisher for the app in the Applications list within this policy.",
          )}
          disabled={loadingPlayStoreCheck}
          placeholder={localizationKey("Enter publisher name")}
          onChange={e => onChange("publisher", e.target.value)}
        />
      </FieldWrapper>
    </Modal>
  )
}

export default connect(({ mdmConfiguration }) => ({ criticalAppsList: mdmConfiguration.criticalApps }), {
  setAndroidCriticalApps,
})(SystemAppModal)

const AppShape = PropTypes.shape({
  packageName: PropTypes.string.isRequired,
  installType: PropTypes.oneOf(systemAppInstallTypes).isRequired,
  name: PropTypes.string,
  publisher: PropTypes.string,
  appliactionSource: PropTypes.oneOf(Object.values(ApplicationSources)),
})

SystemAppModal.propTypes = {
  unmount: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  policyAppsList: PropTypes.arrayOf(AppShape),
  app: AppShape,
  addViaGooglePlay: PropTypes.func,
}
