import React, { memo } from "react"
import { filter, includes } from "ramda"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { operatorOptions } from "../../../conditionData"

export default memo(({ values, onChange, validation, filterOptions = [] }) => (
  <FormGroup controlId="operator">
    <Col componentClass={ControlLabel} sm={4}>
      {localized("Operator")}
    </Col>
    <Col sm={8}>
      <Dropdown
        options={
          filterOptions.length
            ? { ...operatorOptions, items: filter(item => includes(item.value, filterOptions), operatorOptions.items) }
            : operatorOptions
        }
        initialValue={values.operator}
        onChange={value => onChange("operator", value)}
      />
    </Col>
  </FormGroup>
))
