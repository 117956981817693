import { always } from "ramda"

const nodeType = (types = []) => ({
  "node-type": {
    selectedValues: types,
  },
})

const nodeStatus = (statuses = [], include = "some") => ({
  "node-status": {
    selectedValues: statuses,
    include,
  },
})

const osPatches = (statuses = [], contain = true) => ({
  "patch-inventory": {
    contain,
    patchStatuses: statuses,
    selectedValues: [],
  },
})

const softwarePatches = (statuses = [], contain = true) => ({
  "patch-software-inventory": {
    contain,
    patchStatuses: statuses,
    selectedValues: [],
  },
})

const devicesWithInstallIssues = always({
  "devices-with-install-issues": {},
})

const monitorsWithTriggeredConditions = {
  ...nodeType(["isMonitorServer"]),
  ...nodeStatus(["hasWarnings"]),
}

const devicesWithTriggeredConditions = always({
  "any-condition-triggered": {},
})

const needsReboot = (causes = []) => ({
  "needs-reboot": {
    rebootCauses: causes,
  },
})

const serverDown = {
  ...nodeType(["isLinuxServer", "isMacServer", "isWindowsServer"]),
  ...nodeStatus(["isDown"]),
}

const nmsDown = {
  ...nodeType(["isNms"]),
  ...nodeStatus(["isDown"]),
}

const downVmHosts = {
  ...nodeType(["isVMWareHost"]),
  ...nodeStatus(["isDown"]),
}

const downHyperVHosts = {
  ...nodeType(["isHyperVHost"]),
  ...nodeStatus(["isDown"]),
}

const maintenanceMode = (modes = []) => ({
  "maintenance-mode": {
    selectedValues: modes,
  },
})

const deviceThreats = (threats = []) => ({
  "device-threats": {
    selectedValues: threats,
  },
})

const vulnerabilitySeverity = (severity = []) => ({
  "vulnerability-severity": {
    selectedValues: severity,
  },
})

const customerCriteria = clientId =>
  clientId
    ? {
        customer: { selectedValues: [clientId] },
      }
    : {}

const locationCriteria = locationId =>
  locationId
    ? {
        location: { selectedValues: [locationId] },
      }
    : {}

const withClientId = criteria => (clientId, locationId) => ({
  ...criteria,
  ...customerCriteria(clientId),
  ...locationCriteria(locationId),
})

export const clickThroughCriteria = {
  serverDown: withClientId(serverDown),
  nmsDown: withClientId(nmsDown),
  installIssues: withClientId(devicesWithInstallIssues()),
  devicesWithTriggeredConditions: withClientId(devicesWithTriggeredConditions()),
  monitorsWithTriggeredConditions: withClientId(monitorsWithTriggeredConditions),
  needsReboot: withClientId(needsReboot([])),
  downVmHosts: withClientId(downVmHosts),
  downHyperVHosts: withClientId(downHyperVHosts),
  maintenanceMode: withClientId(maintenanceMode(["configured", "inProgress", "failed"])),
  healthy: withClientId(nodeStatus(["isHealthy"])),
  unHealthy: withClientId(nodeStatus(["isUnhealthy"])),
  hasWarnings: withClientId(nodeStatus(["hasWarnings"])),
  isUnknown: withClientId(nodeStatus(["isUnknown"])),
  quarantinedThreats: withClientId(deviceThreats(["quarantine"])),
  activeThreats: withClientId(deviceThreats(["active", "blocked"])),
  activeAndQuarantinedThreats: withClientId(deviceThreats(["active", "quarantine"])),
  failedOsPatches: withClientId(osPatches(["FAILED"])),
  pendingOsPatches: withClientId(osPatches(["MANUAL"])),
  failedAndPendingOsPatches: withClientId(osPatches(["FAILED", "MANUAL"])),
  failedSoftwarePatches: withClientId(softwarePatches(["FAILED"])),
  pendingSoftwarePatches: withClientId(softwarePatches(["MANUAL"])),
  failedAndPendingSoftwarePatches: withClientId(softwarePatches(["FAILED", "MANUAL"])),
  allVulnerabilities: withClientId(vulnerabilitySeverity(["NONE", "LOW", "MEDIUM", "HIGH", "CRITICAL"])),
  criticalHighVulnerabilities: withClientId(vulnerabilitySeverity(["CRITICAL", "HIGH"])),
  mediumLowVulnerabilities: withClientId(vulnerabilitySeverity(["MEDIUM", "LOW"])),
  lowVulnerabilities: withClientId(vulnerabilitySeverity(["LOW"])),
  mediumVulnerabilities: withClientId(vulnerabilitySeverity(["MEDIUM"])),
  highVulnerabilities: withClientId(vulnerabilitySeverity(["HIGH"])),
  criticalVulnerabilities: withClientId(vulnerabilitySeverity(["CRITICAL"])),
}
