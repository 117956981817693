import { getSAMLConfig } from "js/includes/common/client"
import { ninjaReportError } from "js/includes/common/utils"

export const setScimStatus = enabled => ({
  type: "SET_SCIM_STATUS",
  enabled,
})

export const requestSamlConfig = () => async dispatch => {
  try {
    const response = await getSAMLConfig()

    dispatch({
      type: "SET_SAML_CONFIGURATION",
      payload: response,
    })
  } catch (error) {
    ninjaReportError(error)
  }
}
