import { showErrorMessage, localized, localizationKey, ninjaReportError } from "js/includes/common/utils"

export const reportErrorAndShowMessage = (error, messageToken = localizationKey("Error"), options) => {
  showErrorMessage(localized(messageToken, options))
  ninjaReportError(error)
}

export function reportErrorWhenNotPolling(error, isPolling, message, options) {
  if (isPolling) {
    console.log("Ignoring when polling error. Not reporting to Sentry:", error)
    return
  }
  reportErrorAndShowMessage(error, message, options)
}

export function ignoreError(error) {
  console.log("Ignoring error:", error)
}
