import { useEffect } from "react"
import { debugLog } from "js/includes/common/utils"
import { useMounted } from "js/includes/common/hooks"

const enableWampLiveProcedure = (nodeId, jobUID) => {
  return window.wamp.call(nodeId, "lh.live.enable", [jobUID], {
    interval: 5000,
  })
}

const continueWampLiveProcedure = (nodeId, jobUID) => {
  return window.wamp.call(nodeId, "lh.live.continue", [jobUID], {
    interval: 120000,
  })
}

const flushJobData = async (nodeId, jobUID) => {
  return window.wamp.call(nodeId, "lh.live.job.flush", [jobUID], {})
}

export const useWampProcedures = ({ node, jobUID, setError }) => {
  const mounted = useMounted()

  useEffect(() => {
    const initializeWampProcedures = async () => {
      try {
        const response = await enableWampLiveProcedure(node.id, jobUID)

        if (response?.args[0] === "OK") {
          setError("")
          mounted.current && continueWampProcedurePolling()
        }
      } catch (error) {
        console.error("enableWampLiveProcedure error >>>> ", error)
        debugLog("Error initializing wamp procedure", error)

        setError("enableWampLiveProcedure")
        mounted.current && setTimeout(initializeWampProcedures, 10000)
      }
    }

    const continueWampProcedurePolling = async () => {
      try {
        const response = await continueWampLiveProcedure(node.id, jobUID)

        if (mounted.current && response?.args[0] === "OK") {
          setTimeout(continueWampProcedurePolling, 60000)
        }
      } catch (error) {
        console.error("continueWampLiveProcedure error >>>> ", error)
        debugLog("Error continuing wamp procedure polling", error)

        setError("continueWampLiveProcedure")
        mounted.current && setTimeout(initializeWampProcedures, 10000)
      }
    }

    const askAgentToFlushJobData = async () => {
      try {
        const response = await flushJobData(node.id, jobUID)
        debugLog("Asking agent to flush job data response", response)
      } catch (error) {
        console.error("enableWampLiveProcedure error >>>> ", error)
        debugLog("Error asking agent to flush job data", error)
      }
    }

    initializeWampProcedures()
    return () => {
      askAgentToFlushJobData()
    }
  }, [jobUID, mounted, node.id, setError])
}
