/**
 * Creates a link matcher.
 *
 * It's based on createLinkMatcherWithRegExp from "@lexical/react/LexicalAutoLinkPlugin". The difference is that this one returns attributes.
 *
 */
const createLinkMatcherWithRegExp = (regExp, urlTransformer) => {
  return text => {
    const match = regExp.exec(text)
    if (match === null) {
      return null
    }
    return {
      index: match.index,
      length: match[0].length,
      text: match[0],
      url: urlTransformer(match[0]),
      attributes: {
        target: "_blank",
        rel: "noopener noreferrer",
      },
    }
  }
}

export const techniciansAutoLinkPluginMatchers = [
  createLinkMatcherWithRegExp(/(['"])*#\d{4,}(['"])*/, text => `/#/ticketing/ticket/${text.replace(/[#'"]/g, "")}`),
]

export const endUsersAutoLinkPluginMatchers = [
  createLinkMatcherWithRegExp(/(['"])*#\d{4,}(['"])*/, text => `/eus/#/tickets/update/${text.replace(/[#'"]/g, "")}`),
]
