const faITGlueLink = {
  prefix: "fac",
  iconName: "itgluelink",
  icon: [
    200,
    200,
    [],
    "0005",
    `M200.02,161.5c-7.905-12.306-18.845-29.126-26.529-41.568c-1.079-1.747-0.771-4.472-0.775-6.745
    c-0.048-25.156-0.03-80.501-0.03-83.557c-42.542,0-84.555,0-127.795,0c28.03,43.727,55.566,86.685,83.645,130.488
    c-12.831,0-24.634,0-37.014,0C79.78,142.07,20.727,51.25,3.078,24.218c-3.598-5.511-4.094-11.196-1.21-17.001
    C4.675,1.565,9.797-0.003,15.741,0C71.361,0.031,126.98-0.009,182.6,0.043c10.651,0.01,17.365,5.167,17.38,15.543
    C200.046,61.282,200.435,161.355,200.02,161.5z M25.796,169.781c42.754,0,84.615,0,127.944,0c-28.109-43.87-55.713-86.952-83.821-130.82c12.93,0,24.62,0,36.999,0
    c10.555,16.236,69.588,107.03,88.415,135.771c3.594,5.486,4.543,11.063,1.667,16.976c-2.884,5.93-7.959,8.325-14.19,8.319
    c-55.621-0.05-111.242-0.002-166.863-0.054c-10.359-0.01-15.748-5.754-15.765-15.84C0.104,138.436-0.007,47.5,0.135,40.333
    C8.917,53.96,17.399,66.817,24.934,79.069c1.183,1.923,0.83,4.921,0.831,7.422C25.796,169.781,25.765,86.492,25.796,169.781z`,
  ],
}

export default faITGlueLink
