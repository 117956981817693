import { useEffect, useState } from "react"
import { isOldAccount } from "./accountCreation"

const useIsOldAccount = (userInvitationAcceptedTime, unit, threshold) => {
  const [isOld, setIsOld] = useState(false)

  useEffect(() => {
    setIsOld(isOldAccount(userInvitationAcceptedTime, unit, threshold))
  }, [userInvitationAcceptedTime, unit, threshold])

  return isOld
}

export default useIsOldAccount
