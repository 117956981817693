import { sendTrackingToSalesforce } from "js/includes/common/client"
import { localizationKey, ninjaReportError } from "js/includes/common/utils"
import { getUserNameOrEmail } from "js/includes/ticketing/editor/shared/utils"

export const BackupPromoCodes = {
  demoRequest: "BackupDemoRequest",
  learnMore: "BackupLearnMore",
}

export const backupMarketingPromo = {
  id: "backup",
  coverUrl: "img/backup-dash.png",
  titleToken: localizationKey("Try NinjaOne Backup"),
  descriptionToken: localizationKey(
    "Backup endpoints wherever they are with a flexible solution that meets your data protection, cost, and RTO objectives every time.",
  ),
  onLearnMore: async () => {
    try {
      const { email: userEmail, ...currentUser } = window.application.get("session").attributes
      window.location.href = "#/administration/apps/backup"
      await fetch(`/division/promotion/${BackupPromoCodes.learnMore}`, {
        options: {
          method: "POST",
          body: JSON.stringify({ userName: getUserNameOrEmail(currentUser), userEmail }),
        },
      })
    } catch (error) {
      ninjaReportError(error)
    }
  },
}

export const referralMarketingPromo = {
  id: "referral-program",
  coverUrl: "img/referral-program.png",
  titleToken: localizationKey("Refer NinjaOne. Earn rewards!"),
  descriptionToken: localizationKey(
    "Invite your peers to demo NinjaOne. You'll earn a $20 gift card if your referral attends a demo and up to $3500 if your referral purchases NinjaOne.",
  ),
  onLearnMore: async () => {
    sendTrackingToSalesforce()
    window.open(
      "https://www.ninjaone.com/partner-referral/?utm_source=Ninja&utm_medium=app&utm_campaign=customer-reward&utm_content=customer-reward-picklist.",
      "_blank",
      "noopener noreferrer",
    )
  },
}
