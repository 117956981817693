import { createSelector } from "reselect"
import { selectIsDocumentationEnabledFromSettings } from "js/state/selectors/session/divisionConfig"
import filterTabs from "js/state/helpers/filterTabs"
import { documentationTabTypes } from "js/includes/common/utils"

export const selectRecentTabs = state => state.general.recentTabs

export const selectFilteredRecentTabs = createSelector(
  [selectRecentTabs, selectIsDocumentationEnabledFromSettings],
  (recentTabs, isDocumentationEnabledFromSettings) =>
    recentTabs?.filter(filterTabs({ isDocumentationEnabledFromSettings })) ?? [],
)

export const selectDocumentationRecentTabs = createSelector(
  [selectFilteredRecentTabs],
  favoriteTabs => favoriteTabs?.filter(tab => documentationTabTypes.includes(tab?.type ?? "")) ?? [],
)
