import React from "react"
import { DeleteIcon, DownloadIcon, HistoryIconSolid } from "@ninjaone/icons"
import {
  DownloadFolderModal,
  DownloadFileModal,
  RestoreFileModal,
  RestoreFolderOrBatchModal,
  GenerateRestoreKeyModal,
  ImageBackupToolModal,
  DownloadMountedContentModal,
  RestoreFileRevisionModal,
  ConfirmDeletePlanRevisionsModal,
  ConfirmDeleteRevisionsModal,
  ConfirmSyncToCloudModal,
} from "js/includes/components/Backup/Modals"
import showModal from "js/includes/common/services/showModal"
import { localizationKey, user, isFeatureEnabled } from "js/includes/common/utils"
import { fetchImageRestoreKeyStatus } from "js/includes/common/backup"
import { RestoreMountedContentModal } from "js/includes/components/Backup/Modals/Restore/RestoreMountedContentModal"

export const DOWNLOAD_ACTION_ID = "download"
export const DOWNLOAD_IMAGE_CONTENT_ACTION_ID = "download_image_content"
export const RESTORE_ACTION_ID = "restore"
export const RESTORE_KEY_ACTION_ID = "get_restore_key"
export const IMAGE_BACKUP_TOOL_ACTION_ID = "image_backup_tool"
export const DELETE_PLAN_REVISIONS_ACTION_ID = "delete-plan-revisions"
export const DELETE_REVISIONS_ACTION_ID = "delete-revisions"
export const SYNC_REVISIONS_TO_CLOUD_ID = "sync-revisions-to-cloud"
export const MOUNT_IMAGE_CONTENT_ACTION_ID = "mount-image-content"

const downloadModalTypeList = {
  folder: ({ selected, sourceNode }) => (
    <DownloadFolderModal
      {...{
        nodes: selected,
        sourceNode,
      }}
    />
  ),
  file: ({ selected, sourceNode }) => (
    <DownloadFileModal
      {...{
        nodes: selected,
        sourceNode,
      }}
    />
  ),
  revisions: ({ selected, sourceNode }) => (
    <DownloadFileModal
      {...{
        revision: selected,
        sourceNode,
      }}
    />
  ),
}

export const getDownloadAction = actionProps => ({
  id: DOWNLOAD_ACTION_ID,
  labelToken: localizationKey("Download"),
  action: ({ selected }) => {
    const [{ node: sourceNode, type }] = selected
    downloadModalTypeList[type] && showModal(downloadModalTypeList[type]({ selected, sourceNode }))
  },
  Icon: DownloadIcon,
  ...actionProps,
})

export const getDownloadImageContentAction = actionProps => ({
  id: DOWNLOAD_IMAGE_CONTENT_ACTION_ID,
  labelToken: localizationKey("Download"),
  action: ({ selected }) => {
    const { node: sourceNode } = selected[0]

    showModal(
      <DownloadMountedContentModal
        {...{
          nodes: selected,
          sourceNode,
        }}
      />,
    )
  },
  Icon: DownloadIcon,
  ...actionProps,
})

export const getRestoreKeyAction = (actionProps, node) => {
  let isModalOpen = false

  return {
    id: RESTORE_KEY_ACTION_ID,
    labelToken: localizationKey("Generate Image Authorization Key"),
    action: async ({ selected = [] }) => {
      const { id } = selected[0]?.node ?? node

      if (isModalOpen) {
        return
      }
      isModalOpen = true

      const hasRestoreKey = await fetchImageRestoreKeyStatus(id)
      showModal(
        <GenerateRestoreKeyModal
          {...{
            node: { id },
            hasRestoreKey,
            onClose: () => {
              isModalOpen = false
            },
          }}
        />,
      )
    },
    ...actionProps,
  }
}

export const getImageBackupToolAction = actionProps => ({
  id: IMAGE_BACKUP_TOOL_ACTION_ID,
  labelToken: localizationKey("Download Image Restore Manager"),
  action: () => {
    showModal(<ImageBackupToolModal />)
  },
  Icon: DownloadIcon,
  ...actionProps,
})

const getRestoreModal = nodes => {
  const [{ type }] = nodes

  switch (type) {
    case "revisions":
      return RestoreFileRevisionModal
    case "folder":
      return RestoreFolderOrBatchModal
    case "file":
      return nodes.length > 1 ? RestoreFolderOrBatchModal : RestoreFileModal
    case "mountedFolder":
    case "mountedFile":
      return RestoreMountedContentModal
    default:
      return null
  }
}

export const getRestoreAction = actionProps => ({
  id: RESTORE_ACTION_ID,
  labelToken: localizationKey("Restore"),
  action: ({ selected }) => {
    const nodes = selected.reduce((acc, dir) => {
      return dir.common ? acc.concat(dir.content) : acc.concat([dir])
    }, [])
    const { node: sourceNode, planName } = nodes[0]

    const RestoreModal = getRestoreModal(nodes)
    RestoreModal && showModal(<RestoreModal {...{ nodes, planName, sourceNode }} />)
  },
  Icon: HistoryIconSolid,
  ...actionProps,
})

export const getDeletePlanRevisionsAction = actionProps => ({
  id: DELETE_PLAN_REVISIONS_ACTION_ID,
  labelToken: localizationKey("Delete all data"),
  action: async ({ selected }) => {
    const [
      {
        node: { id: nodeId },
        name,
      },
    ] = selected

    showModal(
      <ConfirmDeletePlanRevisionsModal
        {...{
          name,
          nodeId,
          directories: selected,
        }}
      />,
      { withProvider: true },
    )
  },
  variant: "danger",
  isRed: true,
  ...actionProps,
})

export const getDeleteRevisionsAction = actionProps => ({
  id: DELETE_REVISIONS_ACTION_ID,
  labelToken: localizationKey("Delete"),
  action: async ({ selected, resetData }) => {
    const [
      {
        node: { id: nodeId },
        planId,
        planName,
      },
    ] = selected

    showModal(
      <ConfirmDeleteRevisionsModal
        {...{
          nodeId,
          planId,
          planName,
          directories: selected,
          resetData,
        }}
      />,
      {
        withProvider: true,
      },
    )
  },
  variant: "danger",
  Icon: DeleteIcon,
  isRed: true,
  ...actionProps,
})

export const getSyncToCloudRevisionsAction = actionProps => ({
  id: SYNC_REVISIONS_TO_CLOUD_ID,
  labelToken: localizationKey("Sync to cloud"),
  action: async ({ selected }) => {
    const [
      {
        node: { displayName },
        name,
      },
    ] = selected

    showModal(
      <ConfirmSyncToCloudModal
        {...{
          name,
          displayName,
        }}
      />,
      {
        withProvider: true,
      },
    )
  },
  ...actionProps,
})

export const getMountImageContentAction = onBrowse => ({
  id: MOUNT_IMAGE_CONTENT_ACTION_ID,
  labelToken: localizationKey("Restore files"),
  action: async ({ selected }) => {
    const [rowData] = selected
    onBrowse({ node: rowData })
  },
  hideRowAction: ({ onS3 }) =>
    !(isFeatureEnabled("cloud_image_mounting") && user("canViewAndRestoreBackupData") && onS3),
})

export const getPlansActions = () => [
  getDeletePlanRevisionsAction({
    hideMultiAction: selected =>
      !(selected.length === 1 && (selected[0].disabled || isFeatureEnabled("backup_data_management"))),
    hideRowAction: ({ disabled }) => !(disabled || isFeatureEnabled("backup_data_management")),
  }),
  getImageBackupToolAction({
    hideMultiAction: selected => !selected.some(plan => plan.imagePlan),
    hideRowAction: ({ imagePlan }) => !imagePlan,
  }),
  getRestoreKeyAction({
    hideMultiAction: selected => !selected.some(plan => plan.imagePlan),
    hideRowAction: ({ imagePlan }) => !imagePlan,
  }),
]

export const getVolumesActions = () => [
  getDeleteRevisionsAction({
    hideMultiAction: selected => !(selected.length >= 1 && isFeatureEnabled("backup_data_management")),
    hideRowAction: () => !isFeatureEnabled("backup_data_management"),
  }),
]

export const getVolumesSecondaryGlobalActions = node => [getImageBackupToolAction(), getRestoreKeyAction({}, node)]

export const getRevisionsSecondaryGlobalActions = node => [getImageBackupToolAction(), getRestoreKeyAction({}, node)]

const canBeDeleted = ({ isImagePlanContent } = {}) => !isImagePlanContent

const canBeDownloaded = ({ isImagePlanContent, common, root, destination } = {}) => {
  if (isImagePlanContent) return true
  if (common) return false
  return destination !== "LOCAL"
}

const canBeRestored = ({ isImagePlanContent } = {}) => !isImagePlanContent

export const getFileFoldersActions = ({ isFileFolderBackupAllowed } = {}) => {
  if (!user("canViewAndRestoreBackupData") && !isFileFolderBackupAllowed) return []

  return [
    getDeleteRevisionsAction({
      hideMultiAction: selected => selected.length > 1 || !canBeDeleted(selected[0]),
      hideRowAction: row => !canBeDeleted(row),
    }),
    getRestoreAction({
      hideMultiAction: selected => selected.some(row => !canBeRestored(row)),
      hideRowAction: row => !canBeRestored(row),
    }),
    getDownloadAction({
      hideMultiAction: selected => selected.length > 1 || !canBeDownloaded(selected[0]),
      hideRowAction: row => !canBeDownloaded(row),
    }),
  ]
}

export const getMountedContentActions = () => {
  if (!user("canViewAndRestoreBackupData")) return []

  return [
    getDownloadImageContentAction({
      hideMultiAction: selected => selected.length > 1,
    }),
    getRestoreAction({
      hideMultiAction: selected => selected.length > 1,
    }),
  ]
}

export const getArrowImageRevisionsActions = () => [
  getSyncToCloudRevisionsAction({
    hideRowAction: ({ onNas, onS3 }) => !(onNas && !onS3),
  }),
]
