import { user } from "js/includes/common/utils"

export const handleOpenGuideTooltipOnAppInit = () => (dispatch, getState) => {
  const {
    session: {
      user: {
        content: { showHelpTooltip: showHelpTooltipFromServer },
      },
      isTrialLicense,
    },
  } = getState()
  const showHelpTooltip = showHelpTooltipFromServer || showHelpTooltipFromServer === undefined

  const getTooltipNameToOpen = () => {
    if (isTrialLicense) return

    if (showHelpTooltip) {
      return "helpMenu"
    }
  }

  const tooltipNameToOpen = getTooltipNameToOpen()

  if (tooltipNameToOpen) {
    dispatch({
      type: "OPEN_TOOLTIP",
      name: tooltipNameToOpen,
    })
  }
}

export const handleOpenGuideTooltip = name => (dispatch, getState) => {
  const {
    general: { guideTooltips },
    session: { isTrialLicense },
  } = getState()

  const getIsTooltipOpenable = name => {
    const canOpenHelpTooltip = name === "helpMenu" && isTrialLicense && !guideTooltips.addDeviceMenu
    const canOpenAddDeviceMenuTooltip = name === "addDeviceMenu" && user("canCreateDevices") && !guideTooltips.helpMenu

    if (canOpenHelpTooltip || canOpenAddDeviceMenuTooltip) {
      return true
    }
  }

  if (getIsTooltipOpenable(name)) {
    dispatch({
      type: "OPEN_TOOLTIP",
      name,
    })
  }
}

export const closeGuideTooltip = name => ({
  type: "CLOSE_TOOLTIP",
  name,
})
