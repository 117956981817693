import { localizationKey } from "js/includes/common/utils"

export const qcDeviceStatuses = {
  CONNECTED: "CONNECTED",
  OFFLINE: "OFFLINE",
  ONLINE: "ONLINE",
  ESTABLISHED: "ESTABLISHED",
  TERMINATED: "TERMINATED",
}

export const qcDeviceStatusTokenMap = {
  [qcDeviceStatuses.CONNECTED]: localizationKey("Connected"),
  [qcDeviceStatuses.OFFLINE]: localizationKey("Offline"),
  [qcDeviceStatuses.ONLINE]: localizationKey("Online"),
}

export const qcAgentInstallStatuses = {
  ALREADY_INSTALLED: "ALREADYINSTALLED",
  FAILED: "FAILED",
  IN_PROGRESS: "INPROGRESS",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  UNAVAILABLE: "UNAVAILABLE",
}

export const qcAgentInstallStatusLabelsMap = {
  [qcAgentInstallStatuses.ALREADY_INSTALLED]: localizationKey("Agent already installed"),
  [qcAgentInstallStatuses.FAILED]: localizationKey("Failed"),
  [qcAgentInstallStatuses.IN_PROGRESS]: localizationKey("In progress"),
  [qcAgentInstallStatuses.PENDING]: localizationKey("Pending"),
  [qcAgentInstallStatuses.SUCCESS]: localizationKey("Deployed"),
  [qcAgentInstallStatuses.UNAVAILABLE]: localizationKey("Unavailable"),
}
