import { useCallback } from "react"
import { connect } from "react-redux"
import { head, compose, forEach, prop, any, mergeLeft } from "ramda"
import { sizer } from "@ninjaone/utils"
import { Button, DataTable, Text, TitleGroup } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import { localized, localizationKey, isNotNil } from "js/includes/common/utils/ssrAndWebUtils"
import showModal from "js/includes/common/services/showModal"
import NetworkModal from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/NetworkModal"
import {
  updatePolicyItem as _updatePolicyItem,
  revertPolicySection as _revertPolicySection,
  deletePolicyItem,
} from "js/state/actions/policyEditor/editor"
import {
  defaultInheritance,
  getActiveStatusColumn,
  getStatusActions,
  getOverrideStateColumn,
  isInheritedRow,
  isNotOverriddenRow,
  objectWithUUIDToArray,
  resolveSecurityOptionLabel,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import InheritableRowPolicyItem from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"
import { getParentGlobalProxy } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/GlobalProxySwitchActivation"
import ManualProxySetup from "./proxy/ManualProxySetup"
import ProxyConfig from "./proxy/ProxyConfig"
const {
  typography: { fontWeight },
} = tokens

export const securityOptions = () => [
  { value: "NONE", labelToken: localizationKey("None") },
  { value: "WPAPSK", labelText: "WPA-PSK" },
  { value: "WEPPSK", labelText: "WEP-PSK" },
]

const getProxy = proxy => {
  if (proxy?.direct) {
    return {
      type: "direct",
      proxy: proxy.direct,
    }
  } else if (proxy?.pac) {
    return {
      type: "pac",
      proxy: proxy.pac,
    }
  }
  return {}
}

const NetworkForm = ({ networkPolicy, updatePolicyItem, deletePolicyItem, parentPolicy, revertPolicySection }) => {
  const { proxy, wifi } = networkPolicy

  const isProxyEnabled = proxy?.pac || proxy?.direct
  const currentProxy = getProxy(proxy)

  const isProxyActivationEnabled = currentProxy?.proxy?.active ?? true
  const { isGlobalProxyInherited, isProxyActivationAllowed } = getParentGlobalProxy(
    isProxyActivationEnabled,
    currentProxy?.proxy,
  )

  const openNetworkModal = network => {
    showModal(<NetworkModal {...{ network, securityOptions: securityOptions(), updatePolicyItem, parentPolicy }} />)
  }

  const removeNetwork = network => deletePolicyItem(`network.wifi.${network.guid}`)

  const removeMultipleNetworks = networks => networks.map(removeNetwork)

  const updateProxyConfiguration = proxyConfig => {
    const proxyType = getProxy(proxyConfig).type
    const proxyToDelete = proxyType === "pac" ? "direct" : "pac"
    const newProxy = mergeLeft(proxyConfig[proxyType], proxy[proxyType] ?? defaultInheritance)
    deletePolicyItem(`network.proxy.${proxyToDelete}`)
    updatePolicyItem(`network.proxy.${proxyType}`, parentPolicy, newProxy)
  }

  const removeProxyConfiguration = () => {
    deletePolicyItem(`network.proxy.${currentProxy.type}`)
  }

  const isChildPolicy = isNotNil(parentPolicy)

  const columns = [
    {
      id: "configName",
      Header: localized("Configuration Name"),
      accessor: "configName",
      disableSortBy: false,
    },
    {
      id: "wifiName",
      accessor: "wifiName",
      disableSortBy: false,
      Header: localized("Wi-Fi Name (SSID)"),
    },
    {
      id: "security",
      disableSortBy: false,
      Header: localized("Security Type"),
      accessor: ({ security }) => resolveSecurityOptionLabel(security, securityOptions()),
    },
    ...(isChildPolicy ? [getActiveStatusColumn(), getOverrideStateColumn()] : []),
  ]

  const isNotRevertible = network => !isInheritedRow(network) || isNotOverriddenRow(network)

  const changeNetworkStatus = network => {
    updatePolicyItem(`network.wifi.${network.guid}`, parentPolicy, {
      ...network,
      active: network.active === false,
    })
  }

  const isProxyActive = isGlobalProxyInherited ? isProxyActivationEnabled : true

  const updateActiveProxy = useCallback(
    isActive => {
      updatePolicyItem(
        `network.proxy.${currentProxy.type}`,
        parentPolicy,
        mergeLeft({ active: isActive }, currentProxy.proxy),
      )
    },
    [currentProxy, parentPolicy, updatePolicyItem],
  )

  return (
    <Flex padding={sizer(0, 6, 8)} flex={1} flexDirection="column" overflow="auto">
      <Box marginBottom={tokens.spacing[4]}>
        <TitleGroup
          titleToken={localizationKey("Network")}
          descriptionToken={localizationKey("Manage device network settings.")}
        />
      </Box>
      <InheritableRowPolicyItem
        inheritableItem={proxy[currentProxy.type] ?? { inheritance: {} }}
        pathToItem={`network.proxy.${currentProxy.type}`}
        wrapperProps={{ padding: `${tokens.spacing[3]} 0px`, marginBottom: tokens.spacing[3] }}
        testId="proxy-settings"
        borderColor="colorBorderWeak"
      >
        <Text color="colorTextStrong" token={localizationKey("Proxy settings")} fontWeight={fontWeight.medium} />
      </InheritableRowPolicyItem>
      {isProxyEnabled && (
        <Box marginBottom={tokens.spacing[4]}>
          <ProxyConfig
            {...currentProxy}
            isProxyEnabled={isProxyActivationEnabled}
            isGlobalProxyInherited={isGlobalProxyInherited}
            updateActiveProxy={updateActiveProxy}
          />
        </Box>
      )}
      <Flex>
        {isProxyEnabled && !isInheritedRow(proxy[currentProxy.type]) && (
          <Box marginRight={tokens.spacing[3]}>
            <Button
              type="delete"
              labelToken={localizationKey("Remove")}
              onClick={removeProxyConfiguration}
              disabled={!isProxyActivationAllowed}
            />
          </Box>
        )}
        <Button
          labelToken={isProxyEnabled ? localizationKey("Edit proxy") : localizationKey("Manual proxy setup")}
          onClick={() => {
            showModal(<ManualProxySetup {...{ proxy, updateProxyConfiguration, isProxyActive }} />)
          }}
          disabled={!isProxyActivationAllowed}
        />
      </Flex>
      <Flex padding={`${tokens.spacing[3]} 0px`} marginTop={tokens.spacing[7]}>
        <Text color="colorTextStrong" token={localizationKey("Wi-Fi network list")} fontWeight={fontWeight.medium} />
      </Flex>
      <Box flex={1} minHeight="500px">
        <DataTable
          {...{
            tableId: "wifi-table",
            globalActionsButton: {
              buttonProps: {
                labelToken: localizationKey("Add New Wi-Fi Network"),
                action: () => openNetworkModal({}),
                Icon: null,
                variant: "secondary",
              },
            },
            rows: objectWithUUIDToArray(wifi, "guid"),
            columns,
            actions: {
              primary: [
                {
                  labelToken: localizationKey("Revert overrides"),
                  action: compose(
                    forEach(network => revertPolicySection(`network.wifi.${network.guid}`, network)),
                    prop("selected"),
                  ),
                  hideMultiAction: any(isNotRevertible),
                  hideRowAction: isNotRevertible,
                },
                ...getStatusActions(changeNetworkStatus, isChildPolicy),
                {
                  labelToken: localizationKey("Edit"),
                  action: ({ selected }) => {
                    const network = head(selected)
                    openNetworkModal(network)
                  },
                  hideMultiAction: rows => rows.length > 1,
                  splitAfter: true,
                },
                {
                  labelToken: localizationKey("Remove"),
                  action: ({ selected }) => removeMultipleNetworks(selected),
                  hideMultiAction: any(isInheritedRow),
                  hideRowAction: isInheritedRow,
                  variant: "danger",
                  isRed: true,
                },
              ],
            },
          }}
        />
      </Box>
    </Flex>
  )
}

export default connect(
  ({ policyEditor: { policy, parentPolicy } }) => ({
    networkPolicy: policy.content.network,
    parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
    deletePolicyItem,
    revertPolicySection: _revertPolicySection,
  },
)(NetworkForm)
