import { Button, Select, Text } from "@ninjaone/components"
import { CloseIcon } from "@ninjaone/icons"
import { spacing } from "@ninjaone/tokens"

import { localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { permissionPolicyOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/MobileApplicationsForm"

export const OverridesPermissionList = ({ overrides, onValueChange, onRemovePermission, showSelect = true }) => {
  return (
    <Box padding={spacing[3]} width="100%">
      {overrides?.map(({ policy, permission }) => (
        <Flex key={permission} width="100%" marginBottom={spacing[3]} gap={spacing[1]} alignItems={showSelect ? "end" : "center"}>
          <Box flexGrow={1}>
            {showSelect ? (
              <Select
                labelId={`Override-${permission}`}
                value={policy}
                labelText={permission}
                options={permissionPolicyOptions}
                onChange={newValue => {
                  onValueChange(permission, newValue)
                }}
                triggerMinWidth="100%"
              />
            ) : <Text color="colorTextStrong">{policy}</Text>}
          </Box>
          <Button onClick={() => onRemovePermission(permission)} tooltip={localized("Remove")} tooltipPosition="right" type="tertiary" variant="accessory" transparent>
            <CloseIcon />
          </Button>
        </Flex>
      ))}
    </Box>
  )
}
