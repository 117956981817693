import { useState } from "react"

export function useCounter(initialCount = 0) {
  const [counter, setCounter] = useState(initialCount)

  function incrementBy(amount) {
    setCounter(prevCount => prevCount + amount)
  }

  function decrementBy(amount) {
    setCounter(prevCount => prevCount - amount)
  }

  function increment() {
    incrementBy(1)
  }

  function decrement() {
    decrementBy(1)
  }

  function reset() {
    setCounter(initialCount)
  }

  return [counter, { increment, decrement, incrementBy, decrementBy, reset }]
}
