import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colorTextStrong};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeak};
  padding: 1px ${tokens.spacing[1]};
  border-radius: ${tokens.borderRadius[1]};
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutral};
  }
`

export const MentionComponent = ({ label }) => {
  return <StyledSpan>@{label}</StyledSpan>
}
