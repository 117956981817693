import { __, always, compose, cond, equals, includes, keys, map } from "ramda"
import { memo } from "react"

import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import { localizationKey, localized, sortByFieldNameCaseInsensitive } from "js/includes/common/utils"

import {
  renderAsText,
  renderAttribute,
  renderCCList,
  renderObjectName,
  renderSource,
  renderStatus,
  renderSummary,
  renderTags,
  renderTypePriorityOrSeverity,
} from "./changeLogUtils"

const logTitleTokens = {
  assignedAppUserId: localizationKey("Assignee"),
  source: localizationKey("Source"),
  summary: localizationKey("Subject"),
  status: localizationKey("Status"),
  type: localizationKey("Type"),
  priority: localizationKey("Priority"),
  severity: localizationKey("Severity"),
  tags: localizationKey("Tags"),
  ccList: localizationKey("CC"),
  attributeValues: localizationKey("Ticket Fields"),
  ticketFormId: localizationKey("Form"),
  requesterUid: localizationKey("Requester"),
  nodeId: localizationKey("Device"),
  locationId: localizationKey("Location"),
  clientId: localizationKey("Organization"),
  agreementId: localizationKey("Agreement"),
}

const GridTable = styled.div`
  display: grid;
  grid-template-columns: fit-content(130px) fit-content(250px) 1fr;
`

const Cell = styled.div`
  display: inline-grid;
  align-items: center;
  padding-right: ${tokens.spacing[6]};
  height: 29px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  :nth-of-type(3n) {
    padding-right: 0;
  }

  & > * {
    min-width: 0;
  }
`

const mapKey = map(key => ({
  label: logTitleTokens[key] ? localized(logTitleTokens[key]) : key,
  key,
}))

const LogRow = memo(({ label, log, renderLeft, renderRight }) => {
  return (
    <>
      <Cell>
        <Text type="body" color="colorTextStrong">
          {label}:
        </Text>
      </Cell>
      <Cell>{log.old ? renderLeft(log) : renderAsText({ value: localized("None"), isOld: true })}</Cell>
      <Cell>{log.new ? renderRight(log) : renderAsText({ value: localized("None") })}</Cell>
    </>
  )
})

export const TicketChangeLog = memo(({ logs }) => {
  const renderLogs = () =>
    compose(
      map(({ label, key }) => {
        const log = logs[key]
        const showChange = !!log.old || !!log.new

        return cond([
          [
            equals("source"),
            always(
              showChange && (
                <LogRow
                  {...{
                    key,
                    log,
                    label,
                    renderLeft: ({ old: oldData }) => renderSource({ source: oldData, isOld: true }),
                    renderRight: ({ new: newData }) => renderSource({ source: newData }),
                  }}
                />
              ),
            ),
          ],
          [
            includes(__, ["assignedAppUserId", "requesterUid"]),
            always(
              showChange && (
                <LogRow
                  {...{
                    key,
                    log,
                    label,
                    renderLeft: ({ old: oldData }) => renderObjectName({ object: oldData, isOld: true }),
                    renderRight: ({ new: newData }) => renderObjectName({ object: newData }),
                  }}
                />
              ),
            ),
          ],
          [
            includes(__, ["ticketFormId", "nodeId", "locationId", "clientId", "agreementId"]),
            always(
              showChange && (
                <LogRow
                  {...{
                    key,
                    log,
                    label,
                    renderLeft: ({ old: oldData }) => renderObjectName({ object: oldData, isOld: true }),
                    renderRight: ({ new: newData }) => renderObjectName({ object: newData }),
                  }}
                />
              ),
            ),
          ],
          [
            equals("summary"),
            always(
              showChange && (
                <LogRow
                  {...{
                    key,
                    log,
                    label,
                    renderLeft: ({ old: oldData }) => renderSummary({ summary: oldData, isOld: true }),
                    renderRight: ({ new: newData }) => renderSummary({ summary: newData }),
                  }}
                />
              ),
            ),
          ],
          [
            equals("status"),
            always(
              showChange && (
                <LogRow
                  {...{
                    key,
                    log,
                    label,
                    renderLeft: ({ old: oldData }) => renderStatus({ data: oldData, isOld: true }),
                    renderRight: ({ new: newData }) => renderStatus({ data: newData }),
                  }}
                />
              ),
            ),
          ],
          [
            _key => includes(_key, ["type", "priority", "severity"]),
            always(
              showChange && (
                <LogRow
                  {...{
                    key,
                    log,
                    label,
                    renderLeft: ({ old: oldData, oldParent }) =>
                      renderTypePriorityOrSeverity({ value: oldData, parent: oldParent, isOld: true }),
                    renderRight: ({ new: newData, newParent }) =>
                      renderTypePriorityOrSeverity({ value: newData, parent: newParent }),
                  }}
                />
              ),
            ),
          ],
          [
            equals("tags"),
            always(
              <LogRow
                {...{
                  key,
                  log,
                  label,
                  renderLeft: ({ old: oldData }) => renderTags({ tags: oldData, isOld: true }),
                  renderRight: ({ new: newData }) => renderTags({ tags: newData }),
                }}
              />,
            ),
          ],
          [
            equals("ccList"),
            always(
              showChange && (
                <LogRow
                  {...{
                    key,
                    log,
                    label,
                    renderLeft: ({ old: oldData }) => renderCCList({ data: oldData, isOld: true }),
                    renderRight: ({ new: newData }) => renderCCList({ data: newData }),
                  }}
                />
              ),
            ),
          ],
          [
            equals("attributeValues"),
            () =>
              map(attributeChange => {
                const {
                  attributeId: { name, id, attributeType },
                } = attributeChange
                const showAttributeChange = !!attributeChange.old || !!attributeChange.new
                const isDate = attributeType === "DATE"

                return (
                  showAttributeChange && (
                    <LogRow
                      {...{
                        key: id,
                        log: attributeChange,
                        label: name,
                        renderLeft: ({ old: oldData }) => renderAttribute({ isDate, value: oldData, isOld: true }),
                        renderRight: ({ new: newData }) => renderAttribute({ isDate, value: newData }),
                      }}
                    />
                  )
                )
              }, log),
          ],
        ])(key)
      }),
      sortByFieldNameCaseInsensitive("label", "ASC"),
      mapKey,
      keys,
    )(logs)

  return <GridTable>{renderLogs()}</GridTable>
})
