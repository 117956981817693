import qs from "qs"
import {
  dateToFormat,
  downloadFile,
  localizationKey,
  reportErrorAndShowMessage,
  fetch,
  fetchJson,
  localized,
} from "js/includes/common/utils"

export const exportAgentInstallers = async ({ status, clientIds }) => {
  const url = `/agent-installers/csv?${qs.stringify({
    status,
    clientIds,
  })}`

  try {
    const response = await fetch(url)
    const data = await response.text()

    const fileName = `${dateToFormat("YYYYMMDD", new Date())}-${localized("agent_installers")}.csv`

    downloadFile(`data:text/csv;charset=utf-8,${encodeURIComponent(data)}`, fileName)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error exporting data"))
  }
}

export const fetchAgentInstallers = ({ page, size, status, clientIds }) =>
  fetchJson(
    `/agent-installers?${qs.stringify({
      page,
      size,
      status,
      clientIds,
    })}`,
  )

export const revokeAgentInstallers = payload =>
  fetchJson(`/agent-installers/revoke`, {
    options: {
      body: JSON.stringify(payload),
      method: "POST",
    },
  })

export const getRevocableTokens = ({ clientIds }) => {
  const url = `/agent-installers/revocable?${qs.stringify({
    clientIds,
  })}`

  return fetchJson(url)
}
