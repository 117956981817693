import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { daemonStateOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData/options"

const StatesSelect = ({ values, onChange }) => {
  return (
    <FormGroup controlId="type">
      <Col componentClass={ControlLabel} sm={4}>
        {localized("State")}
      </Col>
      <Col sm={8}>
        <Dropdown
          ariaLabel= {localized("State")}
          options={daemonStateOptions}
          initialValue={values.state}
          onChange={value => onChange("state", value)}
        />
      </Col>
    </FormGroup>
  )
}

export default StatesSelect
