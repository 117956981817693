import { fetchReferralProgramPulse, isPromotionOpen } from "js/includes/common/client"
import { ninjaReportError } from "js/includes/common/utils"
import { showMarketingPromo } from "js/state/actions/marketingPromo/marketingPromo"

import { referralMarketingPromo } from "./helpers"

export const setupReferralMarketingPromo = async () => {
  const { isTrialLicense } = window.application.get("session").attributes
  const currentViewState = isPromotionOpen(referralMarketingPromo.id)

  if (!isTrialLicense && !currentViewState) return

  try {
    const response = await fetchReferralProgramPulse()
    if (response?.deliverPromotions) {
      window.store.dispatch(showMarketingPromo([referralMarketingPromo]))
    }
  } catch (error) {
    ninjaReportError(error)
  }
}
