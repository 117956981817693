import { useQuery } from "urql"
import { reportErrorAndShowMessage, localized, WithGraphql } from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import { ticketRuleById } from "js/includes/configuration/integrations/ticketing/ticketAutomation/ticketRuleset/graphql"

const ConditionRulesetContainer = ({ ticketRulesetId }) => {
  const [{ data, fetching, error }] = useQuery({
    query: ticketRuleById,
    variables: {
      id: ticketRulesetId,
    },
  })

  if (error) {
    reportErrorAndShowMessage(error)
  }

  return fetching ? (
    <Loading />
  ) : (
    <span>
      <div>{localized("Create with ")}</div>
      <div className="small">{data?.ticketRulesetById?.name}</div>
    </span>
  )
}

export const ConditionRuleset = WithGraphql(ConditionRulesetContainer)
