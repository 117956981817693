import { compose, map, keys } from "ramda"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export const scheduleTypeMap = {
  DAILY: localizationKey("Daily"),
  WEEKLY: localizationKey("Weekly"),
  MONTHLY: localizationKey("Monthly"),
  RUN_ONCE: localizationKey("Run Once"),
  RUN_IMMEDIATELY: localizationKey("Run Once Immediately"),
  NO_SCHEDULE: localizationKey("None"),
  INTERVAL: localizationKey("Every"),
  ON_SYSTEM_STARTUP: localizationKey("On System Startup"),
  ON_USER_LOGIN: localizationKey("On User Login"),
}

export const intervalOptions = [
  { value: "HOUR", labelToken: localizationKey("Hours") },
  { value: "MINUTE", labelToken: localizationKey("Minutes") },
]

export const scheduleTypeOptions = compose(
  map(value => ({
    value,
    labelToken: scheduleTypeMap[value],
  })),
  keys,
)(scheduleTypeMap)

export const defaultAgentSchedule = {
  scheduleType: "DAILY",
  weekDays: [],
  dayOfMonth: 0,
  scheduleHour: 17,
  scheduleMinute: 0,
  timeZone: "_Local",
}
