import { test } from "ramda"

// TODO: Use isValidPackageName in SystemAppModal once epic/NJ-83346-mdm-enhancements-7-0-0 is merged to develop

// It must have at least two segments (one or more dots).
// Each segment must start with a letter.
// All characters must be alphanumeric or an underscore [a-zA-Z0-9_].
export const isValidPackageName = test(/^([A-Za-z]{1}\w*\.)+[A-Za-z]\w*$/)

export const isInputEmptyOrHasValidPackageName = packageName =>
  packageName?.length === 0 || isValidPackageName(packageName)
