export const colors = {
  ninjaWhite: "#FAFBFD",
  ninjaLight: "#E1E2E4",
  ninjaMedium: "#949597",
  ninjaDark: "#616264",
  ninjaBlack: "#151618",
  ninjaGrey: "#f9f9f9",

  ninjaRed: "#D53948",
  ninjaYellow: "#FAC905",
  ninjaLightYellow: "#FEF0CF",
  ninjaGreen: "#26A644",

  ninjaBlue: "#2F4050",
  ninjaBlueSaturated: "#337ab7",
  ninjaLightBlue: "#ebf4fd",
  ninjaBlueDark: "#23527c",

  white: "#FFFFFF",
  pink: "#ac5287",
  purple: "#6E587A",
  blue: "#57889C",
  black: "#000",

  lightGrey: "#E1E2E4",
  brightGray: "#EEEEEE",
  lightOrange: "#ff7f50",
  brown: "#8c564b",
  darkBrown: "#181b1e",
  orchid: "#e377c2",

  casal: "#236467",
  keyLimePie: "#bcbd22",
  quincy: "#5a392d",
  java: "#17becf",

  tussock: "#c8993a",
  purplePeart: "#7142c9",
  mantis: "#69ce56",
  fuchsiaPink: "#c953c0",
  wattle: "#c8d748",
  jacarta: "#4f2c6e",
  downy: "#6dd3ae",
  punch: "#d94b31",
  blueMarguerite: "#6c7aca",
  chaletGreen: "#537237",
  cabaret: "#cc3f6f",
  pineGlade: "#c9d39e",
  buccaneer: "#592631",
  glacier: "#6faabe",
  burntUmber: "#8d4326",
  prelude: "#ccafd7",
  limedPpruce: "#364146",
  copperfield: "#de8e6f",
  turkishRose: "#b86f91",

  baliHai: "#8797ad",
  onahau: "#BDE7FB",
  heather: "#D2D2D5",
  portage: "#93aad8",
  seagull: "#83AAD3",
  hawkesBlue: "#dce2f2",
  trialBannerBackground: "#0257ac",
  green: "#2eb75b",

  btnLinkHover: "#2d6da4",

  reactVirtualizedBlue: "#2684FF",
  reactVirtualizedLightBlue: "#DEEBFF",
  reactVirtualizedLightGrey: "#cccccc",
  reactVirtualizedGrey: "#e6e6e6",
  reactVirtualizedRed: "#de350b",
  reactVirtualizedPink: "#ffbdad",

  reactSelectLight: "#F2F2F2",
  reactSelectDarkGrey: "#999999",
  reactSelectGrey: "#cccccc",
  reactSelectMediumGrey: "#b3b3b3",

  scriptEditorFormGrey: "#474747",

  rgbaColor1: "rgba(0,0,0,0.1)",
  rgbaColor2: "rgba(0,0,0,0.2)",
  adDiscoveryHeader: "rgba(72,68,105,0.05)",
}

export const statusColorMap = {
  ARCHIVED: colors.ninjaRed,
  DISABLED: colors.seagull,
  ENABLED: colors.ninjaGreen,
}
