import React, { memo } from "react"
import { Row, Form, Col } from "react-bootstrap"
import Modal from "js/includes/components/Modal"
import {
  conditionControlCode,
  defaultConditionUidsReverse,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"
import { generateConditionName } from "../helpers"
import { conditionUidValidation, sensorStateValidation, sensorNameValidation, guestModeValidation } from "./validation"
import { useForm } from "js/includes/common/hooks"
import { pick } from "ramda"
import { getAdditionalFields } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import { getMultiplierByPrefix } from "js/includes/common/utils"
import { conditionParametersFields } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

import ConditionSelector from "./sections/ConditionSelector"
import CpuCondition from "./sections/CpuCondition"
import MemoryCondition from "./sections/MemoryCondition"
import DiskUsageCondition from "./sections/DiskUsageCondition"
import DataStoreFreeSpaceCondition from "./sections/DataStoreFreeSpaceCondition"
import VMHostUptimeCondition from "./sections/VMHostUptimeCondition"
import BadSensorCountCondition from "./sections/BadSensorCountCondition"
import DeviceDownCondition from "./sections/DeviceDownCondition"
import SensorHealthCondition from "./sections/SensorHealthCondition"

import ProcessorUsageCondition from "./sections/ProcessorUsageCondition"
import GuestOperationModeCondition from "./sections/GuestOperationModeCondition"
import SnapshotSizeCondition from "./sections/SnapshotSizeCondition"
import SnapshotLifespanCondition from "./sections/SnapshotLifespanCondition"

export default memo(({ conditionUid, parameters, unmount: onCloseModal, onChangeCondition, disabled }) => {
  const { values, validation, onChange, validateForm } = useForm({
    fields: {
      selected: conditionControlCode[defaultConditionUidsReverse[conditionUid]] || 0,
      conditionId: conditionUid,
      name: [],
      state: [],
      mode: [],
      ...parameters,
      ...getAdditionalFields(parameters, conditionUid),
    },
    validate: {
      selected: conditionUidValidation,
      name: sensorNameValidation,
      state: sensorStateValidation,
      mode: guestModeValidation,
    },
  })

  function applyCondition(e) {
    e.preventDefault()
    e.stopPropagation()

    const { conditionId } = values
    const isValidForm = validateForm()
    if (isValidForm) {
      const conditionName = generateConditionName(values, conditionId)
      const parameters = pick(conditionParametersFields[conditionId], values)
      onChangeCondition({
        conditionName,
        parameters,
        conditionUid: conditionId,
      })
      onCloseModal()
    }
  }

  function onUnitChange(unit) {
    const { thresholdPercent, thresholdBytes, unitBytes } = values
    switch (unit) {
      case "BYTES":
        const threshold = getMultiplierByPrefix(unitBytes) * thresholdBytes
        onChange({
          threshold,
          unit,
        })
        break
      case "PERCENT":
        onChange({
          threshold: thresholdPercent,
          unit,
        })
        break
      case "DAY":
      case "WEEK":
      case "MONTH":
        onChange("unit", unit)
        break
      default:
        break
    }
  }

  function onThresholdChange(threshold) {
    const { unit } = values
    if (unit === "PERCENT") {
      onChange({
        thresholdPercent: threshold,
        threshold: Number(threshold),
      })
    } else {
      onChange({
        threshold: Number(threshold),
      })
    }
  }
  return (
    <Form horizontal onSubmit={applyCondition}>
      <Modal
        submittable
        title={localizationKey("Condition")}
        close={onCloseModal}
        saveText={localizationKey("Apply")}
        dialogClassName="m-t-xl no-overflow-modal modal-form-groups"
      >
        <Row>
          <Col xs={12}>
            <ConditionSelector
              {...{
                selected: values.selected,
                validation,
                onChange,
                disabled,
                validateForm,
              }}
            />
            {(() => {
              switch (Number(values.selected)) {
                case conditionControlCode.CPU:
                  return (
                    <CpuCondition
                      {...{
                        values,
                        onChange,
                        onThresholdChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.Memory:
                  return (
                    <MemoryCondition
                      {...{
                        values,
                        onChange,
                        onUnitChange,
                        onThresholdChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.DiskUsage:
                  return (
                    <DiskUsageCondition
                      {...{
                        values,
                        onChange,
                        onUnitChange,
                        onThresholdChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.DataStoreFreeSpace:
                  return (
                    <DataStoreFreeSpaceCondition
                      {...{
                        values,
                        onChange,
                        onUnitChange,
                        onThresholdChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.VMHostUptime:
                  return (
                    <VMHostUptimeCondition
                      {...{
                        values,
                        onChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.BadSensorCount:
                  return (
                    <BadSensorCountCondition
                      {...{
                        values,
                        onChange,
                        onThresholdChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.DeviceDown:
                  return (
                    <DeviceDownCondition
                      {...{
                        values,
                        onChange,
                        validation,
                        validateForm,
                        ...(values.unit === "MINUTE" && { minValue: 3 }),
                      }}
                    />
                  )
                case conditionControlCode.SensorHealth:
                  return (
                    <SensorHealthCondition
                      {...{
                        values,
                        onChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.ProcessorUsage:
                  return (
                    <ProcessorUsageCondition
                      {...{
                        values,
                        onChange,
                        onThresholdChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.GuestOperationMode:
                  return (
                    <GuestOperationModeCondition
                      {...{
                        values,
                        onChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.CheckpointSize:
                case conditionControlCode.SnapshotSize:
                  return (
                    <SnapshotSizeCondition
                      {...{
                        values,
                        onChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                case conditionControlCode.CheckpointLifeSpan:
                case conditionControlCode.SnapshotLifeSpan:
                  return (
                    <SnapshotLifespanCondition
                      {...{
                        values,
                        onChange,
                        onUnitChange,
                        validation,
                        validateForm,
                      }}
                    />
                  )
                default:
                  return null
              }
            })()}
          </Col>
        </Row>
      </Modal>
    </Form>
  )
})
