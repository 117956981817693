import { EnglishTextToken, defaultToDash, localizationKey, localized } from "js/includes/common/utils"

export const scheduledDeletionTypeMapper = {
  //TODO: remove explicit typing when localizationKey accepts a string that's not in EnglishTextToken
  DELETE_NODE: localizationKey("Node" as EnglishTextToken),
  DELETE_PLAN: localizationKey("Plan"),
  DELETE_FILE_FOLDER: localizationKey("File/Folder"),
}

export const deletionDetailTypeMapper = {
  PLAN: localizationKey("Plan"),
  FILE: localizationKey("File"),
  //TODO: remove explicit typing when localizationKey accepts a string that's not in EnglishTextToken
  FOLDER: localizationKey("Folder" as EnglishTextToken),
  VOLUME: localizationKey("Volume"),
}

export const scheduledDeleteDetailsColumns = [
  {
    id: "type",
    accessor: ({ detailType }: { detailType: keyof typeof deletionDetailTypeMapper }) =>
      //TODO: remove explicit typing when localizationKey accepts a string that's not in EnglishTextToken
      localized((deletionDetailTypeMapper[detailType] ?? localizationKey("Unknown")) as EnglishTextToken),
    Header: localized("Type"),
    disableSortBy: true,
  },
  {
    id: "planName",
    accessor: ({ name }: { name: string }) => defaultToDash(name),
    Header: localized("Name"),
    disableSortBy: true,
  },
  {
    id: "path",
    accessor: ({ path }: { path: string }) => defaultToDash(path),
    Header: localized("Path"),
    disableSortBy: true,
  },
]
