import React, { memo, useRef } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized, localizationKey } from "js/includes/common/utils"
import NumberStepper from "js/includes/editors/Policy/PolicyEditor/formComponents/NumberStepper"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { everyUnitPicker } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"

export default memo(
  ({
    values,
    onChange,
    options = everyUnitPicker,
    withButtons = true,
    minValue = 1,
    maxValue = 9999,
    prop = "duration",
    unitProp = "unit",
    labelToken = localizationKey("Duration"),
    tooltip,
    resetDuration,
  }) => {
    const durationRef = useRef(null)
    const stepperValue = Math.min(maxValue, values[prop])
    return (
      <FormGroup controlId={`${prop}WithUnit`}>
        <Col componentClass={ControlLabel} sm={4}>
          {labelToken && localized(labelToken)} {tooltip || ""}
        </Col>
        <Col sm={8}>
          <Col sm={6} className="p-l-none p-r-none">
            <NumberStepper
              key={maxValue}
              ref={durationRef}
              minValue={minValue}
              maxValue={maxValue}
              withButtons={withButtons}
              initialValue={stepperValue}
              stepper={1}
              onChange={value => {
                onChange(prop, parseInt(value))
              }}
            />
          </Col>
          <Col sm={6} className="p-l-none p-r-none">
            <Dropdown
              options={options}
              initialValue={values[unitProp]}
              onChange={value => {
                onChange(unitProp, value)
                if (resetDuration) {
                  onChange(prop, minValue)
                  durationRef.current.resetValue()
                } else {
                  onChange(prop, stepperValue)
                }
              }}
            />
          </Col>
        </Col>
      </FormGroup>
    )
  },
)
