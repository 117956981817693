import { useEffect } from "react"
import { COMMAND_PRIORITY_EDITOR, createCommand, $createParagraphNode, $getRoot, $insertNodes } from "lexical"
import { $generateNodesFromDOM } from "@lexical/html"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

export const REPLACE_CONTENT = createCommand("REPLACE_CONTENT")

export function ContentInjectorPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      REPLACE_CONTENT,
      payload => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(payload, "text/html")
        const htmlContentNodes = $generateNodesFromDOM(editor, dom)

        const rootNode = $getRoot().clear()
        const emptyBlock = $createParagraphNode()
        rootNode.append(emptyBlock).select()
        $insertNodes(htmlContentNodes)

        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )
  }, [editor])

  return null
}
