import qs from "qs"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

export function getNetworkStorageLocations(clientId) {
  try {
    return fetchJson(`/networkLocations/clients/${clientId}/list `) // new endpoint, will return all network locations except the ones created with cloudberry
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error loading Network Storage list"))
    return []
  }
}

export function saveNetworkStorageLocation(networkStorage, { setAsDefaultForBackups }) {
  return fetchJson(
    `/networkLocations${networkStorage.id ? `/${networkStorage.id}` : ""}?${qs.stringify({
      setAsDefaultForBackups,
    })}`,
    {
      options: {
        method: networkStorage.id ? "PUT" : "POST",
        body: JSON.stringify(networkStorage),
      },
    },
  )
}

export function deleteNetworkStorageLocation(networkStorageId) {
  return fetchJson(`/networkLocations/${networkStorageId}`, {
    options: {
      method: "DELETE",
    },
  })
}

// Only Cloudberry api calls from this point.

export function getCloudberryNetworkStorageLocations(clientId) {
  return fetchJson(`/cloudberry/list/${clientId}/networkLocation`) // old endpoint, will return only the network locations for cloudberry
}

export function saveCloudberryNetworkStorageLocation(networkLocation) {
  return fetchJson(`/cloudberry/networkLocation`, {
    options: {
      method: "POST",
      body: JSON.stringify(networkLocation),
    },
  })
}

export function deleteCloudberryNetworkStorageLocation(networkLocation) {
  return fetchJson(`/cloudberry/networkLocation/${networkLocation.id}`, {
    options: {
      method: "DELETE",
    },
  })
}

export function setCloudberryDefaultStorageNetworkLocation(networkLocation) {
  return fetchJson(`/cloudberry/networkLocation/${networkLocation.id}/default`, {
    options: {
      method: "POST",
    },
  })
}
