import _ from "underscore"
import Backbone from "backbone"
import Singleton from "../../../../infrastructure/backbone/Singleton"
import { TeamViewerDivisionConfig } from "../../../../infrastructure/backbone/DivisionConfig"
import { isFeatureEnabled } from "js/includes/common/utils"

const TeamViewerSystemConfig = Singleton.extend({
  singletonId: "TeamViewerSystemConfig",

  defaults: _.extend({}, Singleton.prototype.defaults, {
    teamViewerDivisionConfig: {},
  }),

  relations: [
    {
      type: Backbone.HasOne,
      key: "teamViewerDivisionConfig",
      relatedModel: TeamViewerDivisionConfig,
    },
  ],

  load() {
    return isFeatureEnabled("team_viewer") ? this.get("teamViewerDivisionConfig").fetch() : null
  },
})

export default TeamViewerSystemConfig
