import { useEffect } from "react"
import { COMMAND_PRIORITY_EDITOR, createCommand, $getRoot, $insertNodes } from "lexical"
import { $generateNodesFromDOM } from "@lexical/html"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

export const INSERT_HTML_COMMAND = createCommand("INSERT_HTML_COMMAND")

export function HTMLInjectorPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      INSERT_HTML_COMMAND,
      payload => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(payload, "text/html")
        const htmlContentNodes = $generateNodesFromDOM(editor, dom)

        const rootNode = $getRoot().clear()
        rootNode.selectStart()
        $insertNodes(htmlContentNodes)

        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )
  }, [editor])

  return null
}
