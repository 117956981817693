import { createSelector } from "reselect"

const getActiveSearchProps = state => state.activeSearch

export const getActiveSearch = createSelector([getActiveSearchProps], activeSearchProps => activeSearchProps)

export const isExistingGroup = createSelector(
  [getActiveSearch],
  activeSearch => activeSearch !== "ALL" && !!activeSearch?.id,
)
