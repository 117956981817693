import { memo, useMemo } from "react"
import { faDollar } from "@fortawesome/pro-light-svg-icons"
import { localized, isTicketingEnabledFromSettings } from "js/includes/common/utils"
import { ninjaPSARoutes } from "js/includes/dashboards/siteMap"
import SubNavLink from "./SubNavLink"
import SubNav from "./SubNav"
import SubNavDrawer from "./SubNavDrawer"
import SubNavHoverDropdownItem from "./SubNavHoverDropdownItem"

const baseRoute = ninjaPSARoutes.ninjaPSA
const buildNavItems = () => [
  {
    to: ninjaPSARoutes.agreements,
    name: localized("Agreements"),
  },
  {
    to: ninjaPSARoutes.invoices,
    name: localized("Invoices"),
  },
  ...(isTicketingEnabledFromSettings()
    ? [
        {
          to: ninjaPSARoutes.timeEntries,
          name: localized("Time Entries"),
        },
      ]
    : []),
]

const FinanceTabs = memo(({ activeRoute, collapsed }) => {
  const navItems = useMemo(() => buildNavItems(), [])

  const navRenderer = () => (
    <SubNav ariaLabel={localized("Finance navigation")}>
      {navItems.map(({ to, name }) => (
        <SubNavLink key={to} href={to} name={name} />
      ))}
    </SubNav>
  )

  const hoverNavItemsRenderer = () =>
    navItems.map(({ to, name }) => <SubNavHoverDropdownItem key={to} route={`#${to}`} name={name} />)

  return (
    <div id="ninja-psa-finance">
      <SubNavDrawer
        {...{
          collapsed,
          navRenderer,
          hoverNavItemsRenderer,
          icon: faDollar,
          label: localized("Finance"),
          active: activeRoute.includes(baseRoute),
          id: "finance",
        }}
      />
    </div>
  )
})

export default FinanceTabs
