import { Root, Item } from "@radix-ui/react-toggle-group"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

import tokens from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { VARIANTS, getStyles, getVariantStyles, shouldForwardProp } from "./ToggleButton"

export const WEEKDAYS = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

function getWeekdays() {
  return [
    { value: "SUN", labelText: localized("Sunday"), labelTextAbbreviated: localized("Sun") },
    { value: "MON", labelText: localized("Monday"), labelTextAbbreviated: localized("Mon") },
    { value: "TUE", labelText: localized("Tuesday"), labelTextAbbreviated: localized("Tue") },
    { value: "WED", labelText: localized("Wednesday"), labelTextAbbreviated: localized("Wed") },
    { value: "THU", labelText: localized("Thursday"), labelTextAbbreviated: localized("Thu") },
    { value: "FRI", labelText: localized("Friday"), labelTextAbbreviated: localized("Fri") },
    { value: "SAT", labelText: localized("Saturday"), labelTextAbbreviated: localized("Sat") },
  ]
}

function getRearrangedWeekdays(weekdays, startingWeekday = 0) {
  if (startingWeekday < 0 || startingWeekday >= weekdays.length) throw new Error("Invalid starting index")

  return weekdays.slice(startingWeekday).concat(weekdays.slice(0, startingWeekday))
}

const StyledRoot = styled(Root)`
  display: flex;
  gap: ${tokens.spacing[1]};
`

const StyledItem = styled(Item, { shouldForwardProp })`
  ${getStyles}
  ${getVariantStyles}
`

export default function WeekdaysToggle({
  variant = VARIANTS.DEFAULT,
  startingWeekday = WEEKDAYS.SUNDAY,
  abbreviated = false,
  disabled,
  compact,
  fullWidth,
  onValueChange,
  value,
  type = "multiple",
  "data-testid": dataTestid,
}) {
  return (
    <StyledRoot data-testid={dataTestid} {...{ type, value, onValueChange }}>
      {getRearrangedWeekdays(getWeekdays(), startingWeekday).map(weekday => (
        <StyledItem
          key={weekday.value}
          value={weekday.value}
          aria-label={weekday.labelText}
          compact={compact}
          fullWidth={fullWidth}
          disabled={disabled}
          variant={variant}
        >
          {abbreviated ? weekday.labelTextAbbreviated : weekday.labelText}
        </StyledItem>
      ))}
    </StyledRoot>
  )
}

export { VARIANTS }

WeekdaysToggle.propTypes = {
  /**
   * Variants for the button.
   */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /**
   * Determines whether a single or multiple items can be pressed at a time.
   */
  type: PropTypes.oneOf(["single", "multiple"]),
  /**
   * When `type` is equal to `single` value must be a `string`. When `type` is equal to `multiple` value must be a `string[]`.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onValueChange: PropTypes.func,
  /**
   * The disabled state for the component.
   */
  disabled: PropTypes.bool,
  /**
   * Must be a number between `0-6`. `0` being Sunday and `6` being Saturday.
   */
  startingWeekday: PropTypes.oneOf(Object.values(WEEKDAYS)),
  /**
   * Determines whether to show the full text for the weekday (e.g. `Sunday`) or the 3 letter vairant (e.g. `Sun`).
   */
  abbreviated: PropTypes.bool,
  /**
   * A standardized smaller height for the component.
   */
  compact: PropTypes.bool,
  /**
   * Expand the button to the full width of the parent.
   */
  fullWidth: PropTypes.bool,
}
