import { memo } from "react"
import tokens from "@ninjaone/tokens"
import { Input, Checkbox } from "@ninjaone/components"
import { Box, Flex } from "js/includes/components/Styled"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import withInheritance from "js/includes/components/withInheritance"

const delayDaysRange = {
  min: 1,
  max: 90,
}

const DelaySelfInstallUpdates = memo(({ inheritableItem: forceDelayedSoftwareUpdatesSettings, onChange }) => {
  const { forceDelayedSoftwareUpdates, enforcedSoftwareUpdateDelay = 1 } = forceDelayedSoftwareUpdatesSettings

  return (
    <Flex width="100%" flexDirection="column" gap={tokens.spacing[2]} marginTop={tokens.spacing[2]}>
      <Checkbox
        label={localized("Delay when new iOS updates can be self-installed")}
        checked={forceDelayedSoftwareUpdates}
        onChange={({ isChecked }) =>
          onChange({
            ...forceDelayedSoftwareUpdatesSettings,
            forceDelayedSoftwareUpdates: isChecked,
            enforcedSoftwareUpdateDelay: 1,
          })
        }
      />
      {forceDelayedSoftwareUpdates && (
        <Box margin={`0 0 ${tokens.spacing[2]} ${tokens.spacing[7]}`}>
          <Input
            tooltipText={localized(
              "When Apple releases a new update, it will not be made available to the device for the specified amount of days (1-90)",
            )}
            value={enforcedSoftwareUpdateDelay}
            min={delayDaysRange.min}
            max={delayDaysRange.max}
            labelToken={localizationKey("Delay update by days (1-90)")}
            onChange={e => {
              onChange({
                ...forceDelayedSoftwareUpdatesSettings,
                enforcedSoftwareUpdateDelay: e.target.value,
              })
            }}
            onBlur={e => {
              const constrainedValue = Math.max(
                delayDaysRange.min,
                Math.min(delayDaysRange.max, Number(e.target.value)),
              )
              onChange({
                ...forceDelayedSoftwareUpdatesSettings,
                enforcedSoftwareUpdateDelay: constrainedValue,
              })
            }}
            type="number"
          />
        </Box>
      )}
    </Flex>
  )
})

export default withInheritance(DelaySelfInstallUpdates)
