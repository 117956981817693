import { Heading } from "@ninjaone/components"
import { ExternalLinkIcon } from "@ninjaone/icons"
import { spacing } from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import Link from "js/includes/components/Link"

const LinksColumn = ({ titleToken, items }) => {
  return (
    <Flex flexDirection="column" margin={`${spacing[6]} 0`}>
      <Heading level={3} type="headingXs" marginBottom={spacing[2]}>
        {localized(titleToken)}
      </Heading>

      {items?.map(({ labelToken, link }) => (
        <Link key={labelToken} href={link}>
          {localized(labelToken)}
          <ExternalLinkIcon size="xs" />
        </Link>
      ))}
    </Flex>
  )
}

export default LinksColumn
