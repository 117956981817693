import {
  ninjaReportError,
  localized,
  redirectToLogin,
  redirectToApp,
  isCurrentRoutePassThrough,
  logout,
  showApplicationFailedToLoadDialog,
  renderReactComponentIfElementExists,
} from "js/includes/common/utils"
import { initApp } from "initApp"
import Indicator from "js/includes/components/Indicator"
import "./less/index.less"
import "./js/infrastructure/polyfills"
import "icheck/skins/all.css"

import deviceSearch from "js/state/initialStates/devicesState"
import scripting from "js/state/initialStates/scripting"
import initBackbone from "./initMainAppBackbone"
import {
  handleEndUserKnowledgeBaseViewRedirect,
  handleTechnicianKnowledgeBaseViewRedirect,
  isEndUserRedirectToKb,
} from "documentationInit"
;(async function init() {
  let authenticated
  const { authenticated: authenticationResponse, sessionProperties } = await initApp({
    initialReduxState: {
      deviceSearch,
      scripting,
    },
  })

  authenticated = authenticationResponse

  if (authenticated && isCurrentRoutePassThrough()) {
    await logout({ redirectToLogin: false })
    authenticated = false
  }

  if (authenticated) {
    try {
      renderReactComponentIfElementExists(
        <div className="app-loader-container">
          <Indicator />
        </div>,
        document.getElementById("root"),
      )

      const { userType, serverCallPrefix } = sessionProperties
      window.store.dispatch({ type: "SET_SERVER_CALL_PREFIX", prefix: serverCallPrefix || "ws" })

      if (userType === "TECHNICIAN") {
        const shouldInitApp = redirectToApp()
        if (shouldInitApp) {
          initBackbone()
          await window.application.load(sessionProperties)
          await handleTechnicianKnowledgeBaseViewRedirect()
        }
      } else if (userType === "END_USER") {
        if (isEndUserRedirectToKb()) {
          await handleEndUserKnowledgeBaseViewRedirect()
        } else {
          redirectToLogin()
        }
      } else {
        redirectToLogin()
      }
    } catch (error) {
      ninjaReportError(error)
      showApplicationFailedToLoadDialog()
    }
  } else {
    redirectToLogin()
  }
  console.warn(
    `%c 🚫 ${localized("STOP! NEVER PUT CODE IN HERE AS IT WILL COMPROMISE SYSTEM SECURITY")} ❤️ Ninja 🚫`,
    "color: red; font-size: 14px; font-weight: BOLD",
  )
})()

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()
  })
}
