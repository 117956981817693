const faInstallStarted = {
  prefix: "fac",
  iconName: "install-started",
  icon: [
    512,
    512,
    [],
    "0009",
    `M404.946,512L106.565,512C94.754,512 85.252,502.498 85.252,490.687C85.252,478.876 94.754,469.374 106.565,469.374L194.214,469.374L210.732,419.821C212.153,415.47 216.238,412.54 220.855,412.54L290.744,412.54C295.362,412.54 299.447,415.47 300.868,419.821L317.385,469.374L404.946,469.374C416.758,469.375 426.259,478.877 426.259,490.687C426.259,502.497 416.758,511.999 404.946,512ZM166.452,99.95L47.954,99.95C45.023,99.951 42.626,102.348 42.626,105.278L42.626,350.377C42.626,353.307 45.023,355.705 47.954,355.705L171.779,355.705L214.524,398.331L42.626,398.331C19.093,398.331 0,379.238 0,355.705L0,99.95C0,76.417 19.092,57.324 42.626,57.324L166.452,57.324L166.452,99.95ZM468.885,398.331L299.955,398.331L342.183,355.705L463.557,355.705C466.488,355.705 468.885,353.307 468.885,350.377L468.885,105.278C468.885,102.347 466.487,99.95 463.557,99.95L345.13,99.95L345.13,57.324L468.885,57.324C492.418,57.324 511.511,76.417 511.511,99.95L511.511,355.705C511.511,379.237 492.419,398.33 468.885,398.331ZM293.003,-0.181C305.189,-0.18 314.993,9.623 314.993,21.81L314.993,218.357L395.441,218.357C411.751,218.357 419.906,238.056 408.361,249.601L268.813,389.058C261.941,395.93 250.671,395.93 243.799,389.058L104.434,249.601C92.89,238.057 101.044,218.357 117.354,218.357L197.711,218.357L197.711,21.81C197.711,9.623 207.514,-0.18 219.701,-0.181L293.003,-0.181Z`,
  ],
}

export default faInstallStarted
