import {
  ascend,
  complement,
  compose,
  either,
  filter,
  isNil,
  map,
  prepend,
  prop,
  propEq,
  reject,
  sort,
  when,
} from "ramda"

import { transduce } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { isClosedStatus } from "js/includes/configuration/integrations/ticketing/ticketCustomStatus/common"

export const NONE = { value: "NONE", labelToken: localizationKey("None") }

export const getOptions = (list, key = "id", showNone = false) => {
  return compose(
    when(() => showNone, prepend(NONE)),
    map(listItem => ({
      ...listItem,
      value: listItem[key].toString(),
      labelText: listItem.name,
    })),
  )(list || [])
}

export const getSortOptions = () => {
  return [
    { value: "DESC", labelToken: localizationKey("Newest") },
    { value: "ASC", labelToken: localizationKey("Oldest") },
  ]
}

export const getFilterOptions = () => {
  return [
    { value: "PUBLIC", labelToken: localizationKey("Public responses") },
    { value: "PRIVATE", labelToken: localizationKey("Private responses") },
    { value: "SYSTEM", labelToken: localizationKey("System activity") },
  ]
}

export const getTypeOptions = (options = {}) => {
  const { showNone = false } = options

  return [
    ...(showNone ? [NONE] : []),
    { value: "PROBLEM", labelToken: localizationKey("Problem") },
    { value: "INCIDENT", labelToken: localizationKey("Incident") },
    { value: "QUESTION", labelToken: localizationKey("Question") },
    { value: "TASK", labelToken: localizationKey("Task") },
  ]
}

export const getStatusOptions = (list = [], options = {}) => {
  const { hideClose = false, valueAsString = false, parentOnly = false } = options

  return compose(
    map(({ id, displayName, parentId }) => ({
      value: valueAsString ? `${id}` : id,
      labelText: displayName,
      parentId,
    })),
    sort(ascend(prop("id"))),
    when(() => hideClose, reject(compose(isClosedStatus, prop("id")))),
    when(() => parentOnly, reject(compose(complement(isNil), prop("parentId")))),
  )(list)
}

export const getSeverityOptions = (showNone = false) => [
  ...(showNone ? [NONE] : []),
  { value: "CRITICAL", labelToken: localizationKey("Critical") },
  { value: "MAJOR", labelToken: localizationKey("Major") },
  { value: "MODERATE", labelToken: localizationKey("Moderate") },
  { value: "MINOR", labelToken: localizationKey("Minor") },
]

export const getPriorityOptions = (showNone = false) => [
  ...(showNone ? [NONE] : []),
  { value: "HIGH", labelToken: localizationKey("High") },
  { value: "MEDIUM", labelToken: localizationKey("Medium") },
  { value: "LOW", labelToken: localizationKey("Low") },
]

export const getTicketFormsOptions = (ticketForms, ticketFormId) => {
  return transduce(
    compose(
      map(form => {
        if (!form.active) {
          const cleanName = form.name.split("|")[0]
          return {
            ...form,
            name: cleanName,
          }
        }
        return form
      }),
      filter(either(propEq("id", ticketFormId), propEq("active", true))),
      getOptions,
    ),
    ticketForms,
  )
}

export const getTicketFormOptionsForEndUsers = ({ ticketForms = [], idKey = "value", labelKey = "label" }) => {
  return ticketForms.map(tf => ({ [idKey]: tf.id, [labelKey]: tf.endUserTitle || tf.name || "" }))
}
