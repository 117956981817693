import React, { memo, useEffect } from "react"
import styled from "@emotion/styled"
import { faWindows } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useMountedState } from "js/includes/common/hooks"
import { fetchJson, localizationKey, localized, ninjaReportError } from "js/includes/common/utils"
import Indicator from "js/includes/components/Indicator"
import Modal from "js/includes/components/Modal"

const StyledIntructions = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`
const StyledLinkContainer = styled.div`
  display: flex;
  align-items: center;
`

export default memo(({ unmount }) => {
  const [loading, setLoading] = useMountedState(true)
  const [restoreToolDownloadUrl, setRestoreToolDownloadUrl] = useMountedState("")

  useEffect(() => {
    const getRestoreToolDownloadUrl = async () => {
      try {
        const { url_64 } =
          (await fetchJson("/infrastructure/component/LOCKHART_IMAGETOOL_WINDOWS/default-version")) || {}
        if (url_64) {
          setRestoreToolDownloadUrl(url_64)
        }
      } catch (error) {
        ninjaReportError(error)
      } finally {
        setLoading(false)
      }
    }

    getRestoreToolDownloadUrl()
  }, [setLoading, setRestoreToolDownloadUrl])

  return (
    <Modal title={localizationKey("Download Image Restore Manager")} showCloseButton={false} close={unmount}>
      {loading ? (
        <Indicator />
      ) : (
        <div className="text-align-left">
          <StyledIntructions>
            {localized(
              "Download the Image Restore Manager to create bootable media or to download and restore disk images.",
            )}
          </StyledIntructions>
          <StyledLinkContainer>
            <FontAwesomeIcon size="2x" className="device-icon m-r-sm" icon={faWindows} />
            <a download href={restoreToolDownloadUrl} rel="noopener noreferrer">
              {localized("Image Restore Manager For Windows")}
            </a>
          </StyledLinkContainer>
        </div>
      )}
    </Modal>
  )
})
