import styled from "@emotion/styled"
import { Checkbox, HoverTooltip } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import withInheritance from "js/includes/components/withInheritance"
import { Flex } from "js/includes/components/Styled"
import { additionalScheduledUpdatesToken } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/formCommons"
import { getSettings } from "./util"

const StyledFlex = styled(Flex)`
  margin-top: ${tokens.spacing[2]};
  label {
    margin-bottom: 0;
  }
`

const NotificationUpdateCheckbox = ({ inheritableItem: updateNotificationsSettings, onChange }) => {
  return (
    <StyledFlex>
      <Checkbox
        label={localized("Show additional notifications for scheduled updates (iOS 18+)")}
        checked={getSettings(updateNotificationsSettings, "updateNotifications")}
        onChange={({ isChecked }) => {
          onChange({
            ...updateNotificationsSettings,
            updateNotifications: isChecked,
          })
        }}
      />
      <HoverTooltip
        text={localized(additionalScheduledUpdatesToken)}
        triggerColor="colorTextWeakest"
        triggerSize="sm"
      />
    </StyledFlex>
  )
}

export default withInheritance(NotificationUpdateCheckbox)
