import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized, localizedF } from "js/includes/common/utils"
import NumberStepper from "js/includes/editors/Policy/PolicyEditor/formComponents/NumberStepper"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { getMultiplierByPrefix } from "js/includes/common/utils"

const thresholdUnitOptions = {
  value: "PERCENT",
  items: [
    {
      text: localizedF("Percent (%)"),
      value: "PERCENT",
      unitValue: "PERCENT",
    },
    {
      text: localizedF("Kilobyte"),
      value: "KB",
      unitValue: "BYTES",
    },
    {
      text: localizedF("Megabyte"),
      value: "MB",
      unitValue: "BYTES",
    },
    {
      text: localizedF("Gigabyte"),
      value: "GB",
      unitValue: "BYTES",
    },
    {
      text: localizedF("Terabyte"),
      value: "TB",
      unitValue: "BYTES",
    },
  ],
}

export default memo(function UnitBytePercentageSelect({ values, onChange }) {
  const errors = values?.errors?.message

  const getMaxValue = () => {
    if (values.unit === "PERCENT") return 100

    if (values.unitBytes === "TB") return 512

    return 1023
  }

  const getInitialUnitValue = () => {
    if (values.unit === "PERCENT") return values.unit

    return values.unitBytes
  }

  const getInitialThresholdValue = () => {
    if (values.unit === "PERCENT") return values.threshold

    return values.thresholdBytes
  }

  const handleThresholdChange = value => {
    if (values.unit === "PERCENT") {
      onChange({
        thresholdPercent: value,
        threshold: value,
      })
      return
    }

    const multiplier = getMultiplierByPrefix(values.unitBytes)
    const threshold = value * multiplier
    onChange({
      thresholdBytes: value,
      threshold,
    })
  }

  const handleUnitChange = value => {
    /*
      The "absolute" value of the units are stored in different properties, 
      so we need to convert back the value to the correct one when selecting a new unit.
      Otherwise it would be always going back to the old or default value
    */
    if (value === "PERCENT") {
      const multiplier = getMultiplierByPrefix(values.unitBytes)
      const thresholdPercent = values.threshold / multiplier

      onChange({
        unit: value,
        threshold: thresholdPercent,
        thresholdPercent,
      })
      return
    }

    /*
      If the previous unit was a percentage, we need to convert the threshold to the new unit (in bytes),
      by multiplying the value stored on threshold propertie by the multiplier of the new unit.
      If the previous unit was *bytes, we need to use what is stored on thresholdBytes property.
    */
    const previousUnit = values.unit
    const previousThreshold = previousUnit === "PERCENT" ? values.threshold : values.thresholdBytes
    const threshold = getMultiplierByPrefix(value) * previousThreshold

    onChange({
      unit: "BYTES",
      unitBytes: value,
      threshold,
      thresholdBytes: previousThreshold,
    })
  }

  return (
    <>
      <FormGroup controlId="threshold" validationState={errors?.threshold ? "error" : null}>
        <Col componentClass={ControlLabel} sm={4}>
          {localized("Threshold")}
        </Col>
        <Col sm={3}>
          <NumberStepper
            {...{
              stepper: 1,
              minValue: 1,
              maxValue: getMaxValue(),
              initialValue: getInitialThresholdValue(),
              onChange: handleThresholdChange,
            }}
          />

          {errors?.threshold && <em className="invalid m-t-none">{errors?.threshold}</em>}
        </Col>
        <Col sm={5}>
          <Dropdown
            {...{
              options: thresholdUnitOptions,
              initialValue: getInitialUnitValue(),
              onChange: handleUnitChange,
            }}
          />
        </Col>
      </FormGroup>
    </>
  )
})
