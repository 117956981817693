import { fetchJson, rejectInactive } from "js/includes/common/utils"

import { BillingCycle } from "./ninjaPSAAgreements"

export type AgreementTemplateProduct = {
  productId: number,
  content: {
    billingCycles: BillingCycle[],
  },
}

export type AgreementTemplateContent = {
  interval: string,
  billDate: string,
}

export type AgreementTemplate = {
  name: string,
  description: string,
  products: AgreementTemplateProduct[],
  content: AgreementTemplateContent,
}

type AgreementTemplateResponseProduct = Omit<AgreementTemplateProduct, "productId"> & {
  id: number,
  name: string,
}

export type AgreementTemplateResponse = Omit<AgreementTemplate, "products"> & {
  id: number,
  isActive: boolean,
  isArchived: boolean,
  createTime: number,
  updateTime: number,
  products: AgreementTemplateResponseProduct[],
}

const BASE_URL = "/psa/agreement-template"

export function getAgreementTemplateById(agreementTemplateId: number): Promise<AgreementTemplateResponse> {
  return fetchJson(`${BASE_URL}/${agreementTemplateId}`)
}

export function getAllAgreementTemplates(): Promise<AgreementTemplateResponse[]> {
  return fetchJson(BASE_URL)
}

export async function getActiveAgreementTemplates(): Promise<AgreementTemplateResponse[]> {
  const response = await getAllAgreementTemplates()
  return rejectInactive(response)
}

export function createAgreementTemplate(data: AgreementTemplate): Promise<void> {
  return fetchJson(BASE_URL, {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}

export function updateAgreementTemplate(data: AgreementTemplate, templateId: number): Promise<void> {
  return fetchJson(`${BASE_URL}/${templateId}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(data),
    },
  })
}

export function activateAgreementTemplate(templateId: number): Promise<void> {
  return fetchJson(`${BASE_URL}/${templateId}/activate`, {
    options: {
      method: "PATCH",
    },
  })
}

export function deactivateAgreementTemplate(templateId: number): Promise<void> {
  return fetchJson(`${BASE_URL}/${templateId}/deactivate`, {
    options: {
      method: "PATCH",
    },
  })
}
