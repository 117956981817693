import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"
import { Box, StyledFontAwesomeIcon } from "@ninjaone/webapp/src/js/includes/components/Styled"
import Text from "./Text"

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${sizer(3)};
  color: ${({ theme }) => theme.colorTextStrong};
`

const StyledText = styled.div`
  max-width: 730px;

  && > div {
    font-size: 20px;
  }
`

export default function DashboardTitle({ icon, titleText, titleToken, tokenArgs, children }) {
  const showIcon = icon || children

  return (
    <Box display="inline-flex" alignItems="center">
      {showIcon && (
        <StyledIconContainer>
          {children ?? <StyledFontAwesomeIcon icon={icon} fontSize="20px" fixedWidth />}
        </StyledIconContainer>
      )}
      <StyledText>
        <Text type="headingL" color="colorTextStrong" fontWeight={500}>
          {titleToken ? localized(titleToken, tokenArgs) : titleText}
        </Text>
      </StyledText>
    </Box>
  )
}

DashboardTitle.propTypes = {
  icon: PropTypes.object,
  titleText: PropTypes.string,
  titleToken: PropTypes.string,
  tokenArgs: PropTypes.object,
  children: PropTypes.element,
}
