import { ninjaReportError } from "js/includes/common/utils"

export const getLocalStorageParsedObject = key => {
  try {
    const data = window.localStorage.getItem(key)
    return data && JSON.parse(data)
  } catch (error) {
    ninjaReportError(`Error parsing localStorage object with key ${key}`)
    return
  }
}

export function getLocalStorageWithExpiry(key) {
  const item = getLocalStorageParsedObject(key)
  if (item && new Date().getTime() <= item.ts + item.ttl) {
    return item.value
  }
  window.localStorage.removeItem(key)
}

export function limitLocalStorageEntries(keyPrefix, limit) {
  let oldestItemKey = null
  let oldestTimestamp = new Date().getTime()
  let countEntries = 0
  Object.keys(localStorage).forEach(itemKey => {
    if (itemKey.startsWith(keyPrefix)) {
      const item = getLocalStorageParsedObject(itemKey)
      if (item) {
        countEntries++
      }
      if (oldestTimestamp > item?.ts) {
        oldestItemKey = itemKey
        oldestTimestamp = item.ts
      }
    }
  })
  if (countEntries >= limit) {
    localStorage.removeItem(oldestItemKey)
  }
}

export function setLocalStorageWithExpiry(key, value, ttl) {
  localStorage.setItem(key, JSON.stringify({ ts: new Date().getTime(), value, ttl }))
}
