import { Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import { StyledSpan } from "js/includes/components/Styled"
import { getFormattedDisplayTime } from "js/includes/ticketing/editor/shared/utils/time"

export const TimeTracked = ({ disabled, onClick, timeTracked }) => {
  return (
    <StyledSpan
      {...{
        display: "flex",
        alignItems: "center",
        disabled,
        ...(!disabled && { onClick, role: "button", tabIndex: 0 }),
      }}
    >
      <Text
        color={disabled ? "colorTextDisabled" : "colorTextAction"}
        type="body"
        fontWeight={tokens.typography.fontWeight.medium}
      >
        {getFormattedDisplayTime(timeTracked)}
      </Text>
    </StyledSpan>
  )
}
