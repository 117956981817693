import { fetchJson } from "js/includes/common/utils"

export const runADAction = (nodeId, payload) => {
  return fetchJson(`/active-directory/${nodeId}`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}

export const getADUsers = ({ nodeId, pageIndex = 0, pageSize = 250, searchCriteria }) => {
  const urlParamSearchCriteria = searchCriteria?.trim() ? `&searchCriteria=${searchCriteria}` : ""
  return fetchJson(
    `/active-directory/${nodeId}/users?pageIndex=${pageIndex}&pageSize=${pageSize}${urlParamSearchCriteria}`,
  )
}

export const getADGroups = nodeId => fetchJson(`/active-directory/${nodeId}/groups`)

export const getUserGroups = (nodeId, userUid) => fetchJson(`/active-directory/${nodeId}/user/${userUid}/groups`)
