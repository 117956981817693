export const nmsConditionControlCode = {
  SelectCondition: 0,
  Processor: 100,
  Memory: 101,
  PingLatency: 102,
  PingPacketLoss: 103,
  PingResponse: 104,
  NetworkAdapterTrafficSize: 105,
  NetworkAdapterTrafficPercent: 106,
  Http: 107,
  Ports: 108,
  Syslog: 109,
  NetworkAdapterStatus: 110,
  NetworkAdapterStatusChange: 111,
  DeviceDown: 112,
  ConfigurationFile: 113,
  SNMPTraps: 114,
  DiskUsage: 115,
  CustomSnmp: 116,
  SystemReboot: 117,
}

export const defaultNmsConditionUids = {
  DiskUsage: "60ebc4ad-9a5e-4fb4-8430-8343ba31c05c",
  Processor: "1dbe0e38-fa21-4aad-be4e-2ac4b854de42",
  Memory: "e31da871-639f-43a1-a16f-69fa94ca1d22",
  PingLatency: "c26e8214-7c51-48cb-96e9-0dffd1f8a0d5",
  PingPacketLoss: "3bcbc364-e958-4d1c-9cbf-a623b9b1b2f2",
  PingResponse: "ca4bf631-fa22-46ef-95ff-b02dd4fca78e",
  NetworkAdapterTrafficSize: "97ac0f06-30e1-4066-9d04-28c94e0756d0",
  NetworkAdapterTrafficPercent: "b9633d5a-f1b9-4a27-aae5-e51d70b22a6f",
  Http: "b5dd76e1-8ace-44e7-a4a8-496acb1f541e",
  Ports: "fa4eb20e-89eb-4dea-ac47-f432f712b6f8",
  Syslog: "1b901801-314c-42c4-8258-27897a7f955f",
  NetworkAdapterStatus: "635cbdf9-a3cd-4cf5-b4c9-3d9d0c5ae2b9",
  NetworkAdapterStatusChange: "694fac96-b28b-46d7-9746-2b27488555b4",
  DeviceDown: "25e58713-df15-4c52-b79d-2988986d5cd0",
  ConfigurationFile: "c1c29f50-a06f-4569-93af-58c4185eb7c2",
  SNMPTraps: "aacbb747-e49c-4fe2-8f57-1037f1983d6b",
  CustomSnmp: "08300074-cd7e-4e96-9e8e-0219658244fb",
  SystemReboot: "0191815e-aeec-79de-9390-a90ca5f941e9",
}

let defaultNmsConditionUidsReverse = {}
let defaultNmsConditionNames = {}
let defaultNmsConditionUidsByCode = {}

for (var key in defaultNmsConditionUids) {
  if (defaultNmsConditionUids.hasOwnProperty(key)) {
    defaultNmsConditionUidsReverse[defaultNmsConditionUids[key]] = key
    defaultNmsConditionNames[nmsConditionControlCode[key]] = key
    defaultNmsConditionUidsByCode[nmsConditionControlCode[key]] = defaultNmsConditionUids[key]
  }
}

export { defaultNmsConditionUidsReverse, defaultNmsConditionNames, defaultNmsConditionUidsByCode }
