import qs from "qs"
import { batch } from "react-redux"
import {
  fetchJson,
  localized,
  showErrorMessage,
  ninjaReportError,
  isAppOnSearchPage,
  arrayToMapWithKey,
} from "js/includes/common/utils"
import { compose, pluck, flatten, groupBy, prop } from "ramda"
import { getLocalStorageUserSettings } from "@ninjaone/components/src/DataTable/tableSettingsUtils"
import {
  getCriterionForRequest,
  getAllCriteriaRequiredForRunningASearch,
  getCriteriaFromResponse,
} from "js/state/selectors/deviceSearch/criteria"
import { getActiveSearch } from "js/state/selectors/deviceSearch/activeSearch"
import { setGroups } from "js/state/actions/deviceSearch/groups"
import { setCriteria } from "js/state/actions/deviceSearch/criteria"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { setDevicesCacheKey } from "js/state/actions/deviceSearch/actionRunnerParams"
import { sort } from "js/state/actions/common/tableSorting"
import { fetchDeviceById } from "js/includes/common/client"
import {
  CUSTOM_FIELD_COLUMN_PREFIX,
  DEVICE_SEARCH_TABLE_ID,
  SOFTWARE_COLUMN_PREFIX,
} from "js/includes/deviceSearch/constants"

export const PAGESIZE_V2 = 150

export const setDevices = devices => ({
  type: "SET_DEVICES",
  devices,
})

export const addDevices = devices => ({
  type: "ADD_DEVICES",
  devices,
})

export const setDevicesCount = count => ({
  type: "SET_DEVICES_COUNT",
  count,
})

export const updateDeviceDetails = devices => ({
  type: "UPDATE_DEVICE_DETAILS",
  devices,
})

export const setDeviceListSort = sort("deviceListSort")

export const requestGetUpdatedDevicesAndRefreshSearchResults = nodeId => async (dispatch, getState) => {
  const { devices } = getState().deviceSearch
  const { node } = await fetchDeviceById(nodeId)

  const refreshedDevices = devices.map(device => (node.id === device.id ? node : device))
  dispatch(updateDeviceDetails(refreshedDevices))
}

export const requestGetSearchesAndCriteria = () => async dispatch => {
  try {
    const response = await fetchJson("/searches")
    const criteria = compose(flatten, pluck("criteria"))(response)
    const searches = compose(flatten, pluck("search"))(response)

    dispatch(setCriteria(getCriteriaFromResponse(criteria)))
    return dispatch(setGroups(searches))
  } catch (error) {
    showErrorMessage(localized("Error fetching groups"))
    ninjaReportError(error)
  }
}

const buildSearchRunnerUrl = ({ activeSearch, sortProperty, sortDirection, deviceLength = 0, index }) => {
  const query = qs.stringify({
    gid: activeSearch?.id,
    pageSize: PAGESIZE_V2,
    ...(index && { index }),
    sortProperty,
    sortDirection: sortDirection.toLowerCase(),
  })

  return `/search/runner?${query}`
}

const getSoftwareAndCustomFieldsColumns = deviceSearchState => {
  const columns = deviceSearchState.activeSearch?.id
    ? getActiveSearch(deviceSearchState)?.settings?.columns
    : getLocalStorageUserSettings(DEVICE_SEARCH_TABLE_ID).visibleColumnsIds

  return columns?.filter(col => col.startsWith(SOFTWARE_COLUMN_PREFIX) || col.startsWith(CUSTOM_FIELD_COLUMN_PREFIX))
}

export const formatDevicesSoftwareAndCustomFields = devices => {
  return devices?.map(device => {
    return device.software || device.customFields
      ? {
          ...device,
          ...(device.software && {
            softwareMap: groupBy(prop("name"), device.software),
          }),
          ...(device.customFields && {
            customFieldsMap: arrayToMapWithKey("attributeId", device.customFields),
          }),
        }
      : device
  })
}

export const getRequestCriteria = activeCriteria => {
  const hasNoCriteria = !activeCriteria || !activeCriteria.length
  const allCriteria = hasNoCriteria ? [{ type: "all-devices" }] : activeCriteria
  return allCriteria.map(getCriterionForRequest)
}

export const requestRunSearch = (activeCriteria, activeSearch, deviceLength, index) => async (dispatch, getState) => {
  //TODO: REMOVE CONTEXT SEARCH AFTER CLICK-THROUGHS ARE ALL DONE
  if (activeSearch === "CONTEXT") {
    const nodeIds = pluck("id", getState().deviceSearch.devices)
    const { nodes } = await window.deviceList.fetchByIds(nodeIds)
    dispatch(updateDeviceDetails(nodes))
    return
  }

  const requestCriteria = getRequestCriteria(activeCriteria)

  dispatch(startLoading("runningSearch")())
  try {
    const { sortBy: sortProperty, sortDirection } = getState().deviceSearch.deviceListSort
    const currentActiveSearch = getState().deviceSearch.activeSearch
    const { items, count, cacheKey } = await fetchJson(
      buildSearchRunnerUrl({ activeSearch: currentActiveSearch, sortProperty, deviceLength, index, sortDirection }),
      {
        options: {
          method: "POST",
          body: JSON.stringify({
            searchCriteria: requestCriteria,
            columns: getSoftwareAndCustomFieldsColumns(getState().deviceSearch),
          }),
        },
      },
    )

    const devices = formatDevicesSoftwareAndCustomFields(items)

    if (currentActiveSearch === getState().deviceSearch.activeSearch) {
      batch(() => {
        if (!index) {
          dispatch(setDevicesCacheKey(cacheKey))
        }
        dispatch(index ? addDevices(devices) : setDevices(devices))
        dispatch(setDevicesCount(count))
        dispatch(stopLoading("runningSearch")())
      })
    } else {
      dispatch(stopLoading("runningSearch")())
    }
  } catch (error) {
    showErrorMessage(
      error.resultCode === "DEVICE_SEARCH_CACHE_MEMORY_EXPIRED"
        ? localized("The search results are outdated. Please refresh the table.")
        : localized("The requested search failed to run"),
    )
    ninjaReportError(error)
    dispatch(stopLoading("runningSearch")())
  }
}

export const runSearch = () => dispatch => {
  if (!isAppOnSearchPage()) {
    return
  }
  const deviceSearchState = window.store.getState().deviceSearch
  const allCriteriaRequiredForRunningASearch = getAllCriteriaRequiredForRunningASearch(deviceSearchState)
  return dispatch(requestRunSearch(allCriteriaRequiredForRunningASearch, deviceSearchState.activeSearch))
}

export const refreshDevices = () => dispatch => {
  const deviceSearchState = window.store.getState().deviceSearch
  const allCriteriaRequiredForRunningASearch = getAllCriteriaRequiredForRunningASearch(deviceSearchState)

  return dispatch(requestRunSearch(allCriteriaRequiredForRunningASearch, deviceSearchState.activeSearch))
}

export const requestMoreDevices = () => dispatch => {
  const deviceSearchState = window.store.getState().deviceSearch
  const { activeGroup, devices } = deviceSearchState
  const allCriteriaRequiredForRunningASearch = getAllCriteriaRequiredForRunningASearch(deviceSearchState)

  return dispatch(requestRunSearch(allCriteriaRequiredForRunningASearch, activeGroup, devices.length, devices.length))
}

export const sortDevices = ({ sortBy, sortDirection }) => dispatch => {
  const deviceSearchState = window.store.getState().deviceSearch
  const { activeGroup } = deviceSearchState
  const allCriteriaRequiredForRunningASearch = getAllCriteriaRequiredForRunningASearch(deviceSearchState)

  batch(() => {
    dispatch(setDeviceListSort({ sortBy, sortDirection }))
    dispatch(requestRunSearch(allCriteriaRequiredForRunningASearch, activeGroup))
  })
}
