import { useEffect, memo } from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { COMMAND_PRIORITY_EDITOR, BLUR_COMMAND } from "lexical"

export const EditorBlurPlugin = memo(({ onBlur }) => {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    return editor.registerCommand(BLUR_COMMAND, () => onBlur?.(), COMMAND_PRIORITY_EDITOR)
  }, [editor, onBlur])

  return null
})
