import { compose } from "ramda"
import { Base64 } from "js-base64"
import { decodeForCharset, fetchJson, localized, localizationKey, showErrorMessage } from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"

const decodeIncomingScriptCode = compose(decodeForCharset, Base64.decode)

const defaultEditingScript = {
  architecture: ["32", "64"],
  scriptParameters: [],
}

export const setEditingScript = script => ({
  type: "SET_EDITING_SCRIPT",
  script: {
    ...defaultEditingScript,
    ...script,
  },
})

export const setEditingScriptEncoded = script => ({
  type: "SET_EDITING_SCRIPT",
  script: {
    ...defaultEditingScript,
    ...script,
    code: decodeIncomingScriptCode(script.code),
  },
})

export const requestEditingScript = (id, subType) => async dispatch => {
  const templateScriptsEndpoint = "/global-scripting/scripts"
  const clientScriptsEndpoint = "/scripting/scripts"
  const isViewingTemplateScript = subType === "templateScript"

  try {
    const scriptsEndpoint = isViewingTemplateScript ? templateScriptsEndpoint : clientScriptsEndpoint
    const script = await fetchJson(`${scriptsEndpoint}/${id}`)

    let code = script.code

    try {
      code = decodeIncomingScriptCode(script.code)
    } catch (error) {
      if (error.name === "InvalidCharacterError") {
        await ShowMessageDialog({
          icon: { icon: faInfoCircle, type: "critical" },
          title: localizationKey("Error Loading Automation"),
          message: localizationKey(
            "Automation content could not be decoded so it's being shown in its original format",
          ),
          buttons: [{ id: "OK", label: localizationKey("OK") }],
        })
      } else {
        throw error
      }
    }

    const newScript = {
      ...script,
      code,
    }
    dispatch(setEditingScript(newScript))
    return newScript
  } catch (err) {
    showErrorMessage(localized("Error loading automation"))
    throw err
  }
}
