import React from "react"
import Tooltip from "js/includes/components/Tooltip"

export const EllipsisWithTooltip = ({ text, placement }) => {
  return (
    <Tooltip output={text} placement={placement}>
      <span className="text-ellipsis">{text}</span>
    </Tooltip>
  )
}
