import React, { useEffect, useState } from "react"
import { localized, localizationKey, user } from "js/includes/common/utils"
import { Box, Flex, StyledSpan } from "js/includes/components/Styled"
import { Radio, RadioGroup } from "js/includes/components/NinjaReactICheck"
import Disclaimer from "js/includes/components/Disclaimer"

const lockhartKeepBackupData = localizationKey(
  "Keep the data until I delete it manually. Backed up data can be managed from the backup usage tab of the backup dashboard.",
)
const lockhartDeleteBackupData = localizationKey("Delete the data permanently.")
const lockhartBillingDisclaimer = localizationKey(
  "You will be billed according to your sales agreement. If you have any questions, please contact our sales department.",
)

const Label = ({ isDeleteOption, disabled }) => {
  if (isDeleteOption) {
    return disabled ? (
      <>
        <span className="m-l-sm text-muted">{localized(lockhartDeleteBackupData)} </span>
        <StyledSpan color="ninjaLightYellow">
          ({localized("You do not have permission to delete backup data. Please contact your Administrator.")})
        </StyledSpan>
      </>
    ) : (
      <span className="m-l-sm text-danger">{localized(lockhartDeleteBackupData)} </span>
    )
  }
  return (
    <span className="m-l-sm">
      {localized(lockhartKeepBackupData)}
      <br />
      {localized(lockhartBillingDisclaimer)}
    </span>
  )
}

export const LockhartDataAckMessageComponent = ({
  setPermanentDataDeletionState,
  lockhartInfoMessage,
  requireEmail = false,
  setValidation,
}) => {
  const [deleteBackupData, setDeleteBackupData] = useState(false)

  useEffect(() => {
    setValidation(!requireEmail)
  }, [setValidation, requireEmail])

  const canDeleteData = user("canViewAndManageBackupData")

  const onChangeOptions = event => {
    const deletingDataOptionSelected = event.target.value === "YES"
    if (!requireEmail) {
      setValidation(!deletingDataOptionSelected)
    }
    setDeleteBackupData(deletingDataOptionSelected)
    setPermanentDataDeletionState(deletingDataOptionSelected)
  }

  return (
    <>
      <div>
        <Box marginTop="16px">{lockhartInfoMessage}</Box>
        <Flex marginTop="16px">
          <RadioGroup
            className="display-flex flex-column radio-group-label"
            name="Options"
            value={deleteBackupData ? "YES" : "NO"}
            onChange={onChangeOptions}
          >
            <Radio
              value="NO"
              radioClass="iradio_square-blue"
              increaseArea="20%"
              label={<Label isDeleteOption={false} />}
            />
            <Radio
              value="YES"
              radioClass="iradio_square-blue"
              increaseArea="20%"
              disabled={!canDeleteData}
              label={<Label isDeleteOption={true} disabled={!canDeleteData} />}
            />
          </RadioGroup>
        </Flex>
      </div>
      {(requireEmail || deleteBackupData) && (
        <Disclaimer
          {...{
            setValidation,
          }}
        />
      )}
    </>
  )
}
