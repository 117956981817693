import {
  getSecuredFormsOrigin,
  getAuthQuery,
  reportErrorAndShowMessage,
  getOverviewLink,
} from "js/includes/common/utils"
import { promptUserForMfa } from "js/includes/common/services/mfa"

export const initMessageListener = () => {
  window.addEventListener("message", async event => {
    const { name, url, response, error } = event?.data || {}

    if (name === "PROMPT_FOR_MFA") {
      try {
        const securedFormsOrigin = getSecuredFormsOrigin()

        const onSubmit = async ({ mfaCode: mfacode, publicKeyCredentialEncoded }) => {
          const hasQueryStringParams = url.includes("?")
          const queryStringSeparator = hasQueryStringParams ? "&" : "?"
          const urlWithAuth = url + queryStringSeparator + getAuthQuery(response, mfacode, publicKeyCredentialEncoded)

          event?.source?.postMessage({ name: "PROMPT_FOR_MFA_SUCCESS", url: urlWithAuth }, securedFormsOrigin)
        }

        await promptUserForMfa(response, { onSubmit })
      } catch (error) {
        if (!error.isHandledMfaError) {
          reportErrorAndShowMessage(error)
        }
      }
    }

    if (error && name === "ERROR") {
      reportErrorAndShowMessage(error)
    }
  })
}

export const getMessageParts = ({
  activityGroup,
  message,
  appUserName,
  appUserId,
  nodeClass,
  data: {
    message: {
      params: {
        clientName,
        clientId,
        policyName,
        policyId,
        nodeDisplayName,
        nodeId,
        taskName,
        taskId,
        newUserName,
        newUserId,
        updatedUserId,
        updatedUserName,
      },
    },
  },
}) => {
  if (["TECHNICIAN", "ADMIN"].includes(activityGroup) && message?.includes(appUserName)) {
    return {
      messageParts: message.split(appUserName),
      anchorData: { label: appUserName, href: `#/editor/user/${appUserId}` },
    }
  } else if (activityGroup === "TECHNICIAN" && message?.includes(newUserName)) {
    return {
      messageParts: message.split(newUserName),
      anchorData: { label: newUserName, href: `#/editor/user/${newUserId}` },
    }
  } else if (activityGroup === "TECHNICIAN" && message?.includes(updatedUserName)) {
    return {
      messageParts: message.split(updatedUserName),
      anchorData: { label: updatedUserName, href: `#/editor/user/${updatedUserId}` },
    }
  } else if (activityGroup === "GENERAL" && message?.includes(nodeDisplayName)) {
    return {
      messageParts: message.split(nodeDisplayName),
      anchorData: { label: nodeDisplayName, href: nodeClass && getOverviewLink({ id: nodeId, nodeClass }) },
    }
  } else if (activityGroup === "ORGANIZATION" && message?.includes(clientName)) {
    return {
      messageParts: message.split(clientName),
      anchorData: { label: clientName, href: `#/editor/customer/${clientId}` },
    }
  } else if (activityGroup === "POLICY" && message?.includes(policyName)) {
    return {
      messageParts: message.split(policyName),
      anchorData: { label: policyName, href: `#/editor/policy/${policyId}` },
    }
  } else if (activityGroup === "TASK" && message?.includes(taskName)) {
    return {
      messageParts: message.split(taskName),
      anchorData: { label: taskName, href: `#/editor/scheduledTask/${taskId}` },
    }
  }
  return { messageParts: [], anchorData: { label: "", href: null } }
}
