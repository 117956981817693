import { useEffect, useRef } from "react"
import { ReactEditor } from "slate-react"
import { useTheme } from "@emotion/react"

import { sizer } from "@ninjaone/utils"
import { Text } from "@ninjaone/components"

import { Box } from "js/includes/components/Styled"
import { Portal } from "js/includes/ticketing/editor/shared/components"
import { fullNameOrDisplayName } from "js/includes/ticketing/editor/shared/components/wysiwyg/MentionElement"

export const MentionSuggestions = ({
  editor,
  mentionSuggestions,
  mentionSuggestionIndex,
  mentionSuggestionTarget,
  onAddMention,
}) => {
  const ref = useRef()
  const theme = useTheme()

  useEffect(() => {
    if (mentionSuggestionTarget && mentionSuggestions.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, mentionSuggestionTarget)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [mentionSuggestions.length, editor, mentionSuggestionTarget])

  return (
    <Portal>
      <Box
        ref={ref}
        zIndex={1}
        position="absolute"
        border={`1px solid ${theme.color.border}`}
        borderRadius={sizer(1)}
        backgroundColor={theme.color.background}
        maxHeight={500}
        maxWidth={300}
        minWidth={300}
        overflow="auto"
        data-cy="mentions-portal"
      >
        {mentionSuggestions.map((mentionSuggestion, index) => (
          <Box
            key={mentionSuggestion.id}
            padding={sizer(2, 3)}
            color={theme.color.text}
            hoverCursor="pointer"
            hoverBackgroundColor={theme.color.lightGray}
            onClick={() => onAddMention(index, mentionSuggestionTarget)}
            {...(mentionSuggestionIndex === index && {
              backgroundColor: theme.color.lightGray,
            })}
          >
            <Text size="sm" bold>
              {fullNameOrDisplayName(mentionSuggestion)}
            </Text>
            <Text size="xs">{mentionSuggestion.email}</Text>
          </Box>
        ))}
      </Box>
    </Portal>
  )
}
