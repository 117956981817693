export const VM_POWER_STATES = {
  PoweredOn: "PoweredOn",
  PoweredOff: "PoweredOff",
  Suspended: "Suspended",
  Paused: "Paused",
}

export const STATUS_INTERVAL_SECONDS = 10000 //10 seconds

export const VM_GUEST_INTERVAL_SECONDS = 60000 //1 minute

export const SUBSCRIBER_UPDATE_INTERVAL = 60000

export const MAINTENANCE_SCRIPTS_ID = 3
