import React, { useState } from "react"
import { Form } from "react-bootstrap"
import styled from "@emotion/styled"

import { sizer } from "@ninjaone/utils"

import { useMounted } from "js/includes/common/hooks"
import {
  fetch,
  localizationKey,
  localized,
  ninjaReportError,
  runDeviceSearchToolbarAction,
  showErrorMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import { Box } from "js/includes/components/Styled"

const StyledModalContainer = styled.span`
  .modal-dialog {
    width: 400px !important;
    min-width: 400px !important;
  }
`

const invalidateImageRestoreKey = async nodeId => {
  await fetch(`/backup/lockhart/node/${nodeId}/restore-key`, { options: { method: "DELETE" } })
}

const InvalidateRestoreKeyModal = ({ node, unmount, fromDeviceSearch = false, refreshDeviceActions }) => {
  const mounted = useMounted()
  const [disabled, setDisabled] = useState(false)

  const removeKey = async () => {
    setDisabled(true)
    try {
      if (fromDeviceSearch) {
        await runDeviceSearchToolbarAction({
          action: "BACKUP_INVALIDATE_IMAGE_AUTHORIZATION_KEY",
        })
      } else {
        await invalidateImageRestoreKey(node.id)
      }
      refreshDeviceActions?.()
      showSuccessMessage(localized("Image restore key invalidated"))
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        showErrorMessage(localized("Error invalidating image restore key"))
        ninjaReportError(error)
      }
      mounted.current && setDisabled(false)
    }
  }

  return (
    <Form
      horizontal
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        removeKey()
      }}
    >
      <StyledModalContainer>
        <Modal
          title={localizationKey("Invalidate Image Restore Keys")}
          disabledSaveBtn={disabled}
          saveBtnClass="btn-danger"
          saveText={localizationKey("Continue")}
          close={unmount}
          showCloseButton={false}
          submittable
        >
          <Box
            {...{
              display: "grid",
              textAlign: "left",
              margin: [0, sizer(-2)],
              gap: sizer(2),
            }}
          >
            <div>
              {node?.displayName
                ? localized("Are you sure you want to invalidate all restore keys for {{node}}?", {
                    node: node.displayName,
                  })
                : localized("Are you sure you want to invalidate all restore keys for this device?")}
            </div>
            <div>
              {localized("If there are any restore operations for this device they will not complete successfully")}
            </div>
          </Box>
        </Modal>
      </StyledModalContainer>
    </Form>
  )
}

export default InvalidateRestoreKeyModal
