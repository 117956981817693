import { compose, when, gt, __, always } from "ramda"
import { debugLog, parsesToNumber, user } from "js/includes/common/utils"

const maxIdleTimeoutMinutes = 1440

const logUserInactivityMessage = value => {
  debugLog(`User inactivity timeout set to: ${value}`)
}

export const deriveUserInactivityTimeout = when(
  i => parsesToNumber(i),
  compose(when(gt(__, maxIdleTimeoutMinutes), always("OFF")), i => parseInt(i, 10)),
)

export const getInactivityTimeout = () => {
  const userInactivityTimeout = deriveUserInactivityTimeout(user("getUserInactivityTimeout"))
  if (userInactivityTimeout === "OFF") {
    logUserInactivityMessage(userInactivityTimeout)
    return
  }

  return userInactivityTimeout * 60 * 1000
}
