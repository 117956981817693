import { useEffect, useState } from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { eventFiles, DRAG_DROP_PASTE } from "@lexical/rich-text"
import { $generateNodesFromDOM } from "@lexical/html"
import { mergeRegister, mediaFileReader } from "@lexical/utils"
import {
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_LOW,
  PASTE_COMMAND,
  SELECTION_INSERT_CLIPBOARD_NODES_COMMAND,
} from "lexical"

import { IMAGE_FILE_MIME_TYPES } from "@ninjaone/webapp/src/js/includes/common/utils"

import {
  isValidImageFile,
  isMsWord,
  parseHtml,
  extractImageDataFromRtf,
  convertHexToBase64,
  hasTextContent,
} from "../utils"
import { ALLOW_IMAGE_PLUGIN_COMMAND, INSERT_IMAGE_COMMAND } from "./ImagePlugin"

export const DragDropPastePlugin = ({ cidKey, onUploadImage, onUploadImageError }) => {
  const [editor] = useLexicalComposerContext()
  const [allowImages, setAllowImages] = useState(false)

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        ALLOW_IMAGE_PLUGIN_COMMAND,
        payload => {
          setAllowImages(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        DRAG_DROP_PASTE,
        files => {
          if (!allowImages) return false
          ;(async () => {
            try {
              const filesResult = await mediaFileReader(
                files,
                [IMAGE_FILE_MIME_TYPES].flatMap(x => x),
              )
              for (const { file, result } of filesResult) {
                if (isValidImageFile(file)) {
                  const response = await onUploadImage(file)
                  const src = result
                  const cid = response[cidKey]
                  editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                    src,
                    cid,
                  })
                }
              }
            } catch (error) {
              onUploadImageError?.(error)
            }
          })()

          return true
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        PASTE_COMMAND,
        event => {
          const clipboardData = event.clipboardData
          if (!clipboardData) return false

          const htmlString = event.clipboardData.getData("text/html")
          if (!htmlString) return false

          // TODO: Fix list items
          if (isMsWord(htmlString)) {
            const dom = parseHtml(htmlString)

            const images = dom.querySelectorAll("img")
            const imagesRtf = extractImageDataFromRtf(event.clipboardData.getData("text/rtf"))

            if (images.length === imagesRtf.length) {
              for (let i = 0; i < images.length; i++) {
                const image = images[i]
                const type = imagesRtf[i].type

                if (image.src.startsWith("file:///")) {
                  const src = `data:${type};base64,${convertHexToBase64(imagesRtf[i].hex)}`
                  image.setAttribute("src", src)
                }
              }
            }

            const selection = $getSelection()
            const nodes = $generateNodesFromDOM(editor, dom)
            if (
              !editor.dispatchCommand(SELECTION_INSERT_CLIPBOARD_NODES_COMMAND, {
                nodes,
                selection,
              })
            ) {
              selection.insertNodes(nodes)
              return true
            } else {
              return false
            }
          }

          const [hasFiles, files] = eventFiles(event)
          if (hasFiles && !hasTextContent(htmlString)) {
            editor.dispatchCommand(DRAG_DROP_PASTE, files)
            return true
          }

          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [editor, allowImages, cidKey, onUploadImage, onUploadImageError])

  return null
}
