import { useEffect } from "react"
import PropTypes from "prop-types"
import StepContent from "./StepContent"

export const StepCompletedOnVisit = ({
  activeStep,
  titleToken,
  children,
  descriptionToken,
  externalLinks,
  onTaskCompleted,
  resources,
  permissionRequired,
  dojoUrl,
}) => {
  useEffect(() => {
    onTaskCompleted(activeStep)
  }, [onTaskCompleted, activeStep])

  return (
    <>
      <StepContent
        {...{
          activeStep,
          descriptionToken,
          externalLinks,
          resources,
          titleToken,
          permissionRequired,
          dojoUrl,
        }}
      />
      {children}
    </>
  )
}

export default StepCompletedOnVisit
StepCompletedOnVisit.propTypes = {
  activeStep: PropTypes.string.isRequired,
  children: PropTypes.element,
  descriptionToken: PropTypes.string,
  externalLinks: PropTypes.array,
  onTaskCompleted: PropTypes.func.isRequired,
  resources: PropTypes.array,
  titleToken: PropTypes.string.isRequired,
  permissionRequired: PropTypes.shape({ name: PropTypes.string.isRequired, allowed: PropTypes.bool.isRequired })
    .isRequired,
}
