import { connect } from "react-redux"
import { head, map, compose, forEach, prop, any } from "ramda"
import { DataTable, Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"
import {
  updatePolicyItem as _updatePolicyItem,
  revertPolicySection as _revertPolicySection,
  deletePolicyItem,
} from "js/state/actions/policyEditor/editor"
import {
  getActiveStatusColumn,
  getOverrideStateColumn,
  getStatusActions,
  isInheritedRow,
  isNotOverriddenRow,
  objectWithUUIDToArray,
  resolveSecurityOptionLabel,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { isNotNil, localizationKey, localized } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import NetworkModal from "./NetworkModal"

const {
  spacing,
  typography: { fontWeight },
} = tokens

export const securityOptions = () => [
  { value: "WEP", labelText: "WEP-PSK" },
  { value: "WPA", labelText: "WPA-PSK" },
  { value: "WPA2", labelText: "WPA2-PSK" },
  { value: "WPA3", labelText: "WPA3-PSK" },
  { value: "ANY", labelToken: localizationKey("Any") },
  { value: "NONE", labelToken: localizationKey("None") },
]

function WifiDataTable({ wifi, deletePolicyItem, updatePolicyItem, revertPolicySection, parentPolicy }) {
  const wifiListData = objectWithUUIDToArray(wifi, "guid")

  const removeNetwork = network => {
    if (wifiListData.length === 1) {
      deletePolicyItem("network.wifi")
    } else {
      deletePolicyItem(`network.wifi.${network.guid}`)
    }
  }

  const removeMultipleNetworks = map(removeNetwork)

  const openNetworkModal = network => {
    showModal(<NetworkModal {...{ network, encryptionTypeOptions: securityOptions(), updatePolicyItem }} />)
  }

  const isChildPolicy = isNotNil(parentPolicy)

  const columns = [
    {
      id: "configName",
      Header: localized("Configuration Name"),
      disableSortBy: false,
      accessor: "configName",
    },
    {
      id: "ssid",
      accessor: "ssid",
      disableSortBy: false,
      Header: localized("Wi-Fi Name (SSID)"),
    },
    {
      id: "encryptionType",
      disableSortBy: false,
      Header: localized("Security Type"),
      accessor: ({ encryptionType }) => resolveSecurityOptionLabel(encryptionType, securityOptions()),
    },
    ...(isChildPolicy ? [getActiveStatusColumn(), getOverrideStateColumn()] : []),
  ]

  const changeNetworkStatus = network => {
    updatePolicyItem(`network.wifi.${network.guid}`, parentPolicy, {
      ...network,
      active: network.active === false,
    })
  }

  const isNotRevertible = network => !isInheritedRow(network) || isNotOverriddenRow(network)
  return (
    <>
      <Box marginBottom={spacing[3]}>
        <Text color="colorTextStrong" token={localizationKey("Wi-Fi network list")} fontWeight={fontWeight.medium} />
      </Box>
      <DataTable
        {...{
          tableId: "wifi-table",
          hideSettingsButton: true,
          globalActionsButton: {
            buttonProps: {
              labelToken: localizationKey("Add New Wi-Fi Network"),
              action: () => openNetworkModal({}),
              Icon: null,
              variant: "secondary",
            },
          },
          rows: wifiListData,
          columns,
          actions: {
            primary: [
              {
                labelToken: localizationKey("Revert overrides"),
                action: compose(
                  forEach(network => revertPolicySection(`network.wifi.${network.guid}`, network)),
                  prop("selected"),
                ),
                hideMultiAction: any(isNotRevertible),
                hideRowAction: isNotRevertible,
              },
              ...getStatusActions(changeNetworkStatus, isChildPolicy),
              {
                labelToken: localizationKey("Edit"),
                action: ({ selected }) => {
                  const network = head(selected)
                  openNetworkModal(network)
                },
                hideMultiAction: rows => rows.length > 1,
                splitAfter: true,
              },
              {
                labelToken: localizationKey("Remove"),
                action: ({ selected }) => removeMultipleNetworks(selected),
                variant: "danger",
                hideMultiAction: any(isInheritedRow),
                hideRowAction: isInheritedRow,
                isRed: true,
              },
            ],
          },
          noRowsToken: localizationKey("No Wi-Fi networks found"),
        }}
      />
    </>
  )
}

export default connect(
  ({ policyEditor: { policy, parentPolicy } }) => ({
    wifi: policy.content.network.wifi ?? {},
    parentPolicy,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
    revertPolicySection: _revertPolicySection,
    deletePolicyItem,
  },
)(WifiDataTable)
