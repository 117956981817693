const faRebootInstallFails = {
  prefix: "fac",
  iconName: "reboot-install-fail",
  icon: [
    512,
    512,
    [],
    "0025",
    `M461.316,50.835L436.224,75.926C390.053,29.746 326.271,1.176 255.808,1.176C115.197,1.176 0.537,115.924 0.64,256.534C0.743,397.372 114.945,511.511 255.807,511.511C321.58,511.511 381.54,486.625 426.787,445.756C432.048,441.004 432.295,432.832 427.283,427.819L406.975,407.511C402.347,402.883 394.882,402.587 389.997,406.943C354.362,438.712 307.36,458.008 255.807,458.008C144.355,458.008 54.143,367.812 54.143,256.344C54.143,144.892 144.338,54.679 255.807,54.679C311.535,54.679 361.945,77.236 398.427,113.724L374.965,137.187C367.186,144.965 372.695,158.264 383.695,158.264L498.627,190.494C505.446,190.494 510.974,184.966 510.974,178.147L482.393,59.566C482.393,48.566 469.093,43.057 461.316,50.835ZM377.771,180.274L302.32,255.725L377.771,331.176C383.472,336.877 383.472,346.096 377.771,351.797L350.356,379.212C344.655,384.913 335.436,384.913 329.735,379.212L254.284,303.761L178.834,379.212C173.132,384.913 163.913,384.913 158.212,379.212L130.797,351.797C125.096,346.096 125.096,336.877 130.797,331.176L206.248,255.725L130.797,180.274C125.096,174.573 125.096,165.354 130.797,159.653L158.212,132.238C163.913,126.537 173.132,126.537 178.834,132.238L254.284,207.689L329.735,132.238C335.436,126.537 344.655,126.537 350.356,132.238L377.771,159.653C383.472,165.354 383.472,174.572 377.771,180.274Z`,
  ],
}

export default faRebootInstallFails
