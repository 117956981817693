import _ from "underscore"
import Backbone from "backbone"
import { PsaTicketTemplateInfoModel, APIKeyInfoModel } from "./InfoModels"
import { setTicketTemplateList } from "js/state/actions/psa/ticketing"
import { localized, ninjaReportError } from "js/includes/common/utils"
import { incidentTemplateStatusTokenMapper } from "js/includes/configuration/integrations/serviceNow/common/constants"

const InfoCollection = Backbone.Collection.extend({
  refreshSettings: {
    enabled: true,
    interval: 60000,
  },

  initialize() {
    if (!this.comparator) {
      this.comparator = function(model) {
        return model.get("name") ? model.get("name").toLowerCase() : ""
      }
    }

    _.bindAll(this, "fetch")
  },

  fetch({ ignorePauseTimers } = {}) {
    // In case this method was called explicitly to update the collection, cancel all other update timers
    clearTimeout(this.timerId)

    //always call parent fetch when application is loading or timers are running
    if (ignorePauseTimers || !window.application.get("pauseTimers")) {
      try {
        return Backbone.Collection.prototype.fetch.call(this)
      } catch (error) {
        ninjaReportError(error)
      }
    }
    this.setupTimer()
  },

  setupTimer() {
    if (this.refreshSettings.enabled === true && this.refreshSettings.interval > 0) {
      this.timerId = setTimeout(this.fetch, this.refreshSettings.interval)
    }
  },
})

const isServiceNowEnabled = () => !!window.store.getState().psa?.ServiceNow?.connection?.enabled

const mapServiceNowTemplates = templates => {
  const statusLabels = {
    DELETED: localized(incidentTemplateStatusTokenMapper.DELETED),
    INACTIVE: localized(incidentTemplateStatusTokenMapper.INACTIVE),
  }
  return templates.map(({ id, externalName, status, isDefault }) => {
    const statusLabel = statusLabels[status]
    const extraInfo = [...(isDefault ? [localized("Default")] : []), ...(statusLabel ? [statusLabel] : [])]
    return {
      id,
      name: `${externalName}${extraInfo.length ? ` (${extraInfo.join(" - ")})` : ""}`,
    }
  })
}
export const PsaTicketTemplateInfoCollection = InfoCollection.extend({
  model: PsaTicketTemplateInfoModel,

  url: () => {
    return isServiceNowEnabled() ? "/service-now/mapped-templates" : "/tickettemplate/list"
  },

  refreshSettings: {
    enabled: false,
  },

  parse(_ticketTemplates) {
    const ticketTemplates = isServiceNowEnabled() ? mapServiceNowTemplates(_ticketTemplates) : _ticketTemplates
    if (window.psaConnect.isTicketingAvailable()) {
      window.store.dispatch(setTicketTemplateList(ticketTemplates))
    }
    return ticketTemplates
  },
})

export const APIKeyInfoCollection = InfoCollection.extend({
  model: APIKeyInfoModel,

  url: "/apikeypair/list",

  refreshSettings: {
    enabled: false,
  },

  comparator: function(model) {
    return model.get("created")
  },
})
