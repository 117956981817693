import React, { PureComponent } from "react"
import { last } from "ramda"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faServer } from "@fortawesome/pro-solid-svg-icons"
import { AutoSizer, Table, Column } from "react-virtualized"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { noRowsRenderer, localized, localizationKey, headerRenderer } from "js/includes/common/utils"
import { enforceTrailingSlashesInPath, getIcon } from "./common"
import FileFolderBrowserPanel from "./FileFolderBrowserPanel"
import Tooltip from "js/includes/components/Tooltip"
import tableSortSelector from "js/state/selectors/common/tableSort"
import { Breadcrumb } from "@ninjaone/components"

class FileFolderBrowser extends PureComponent {
  static propTypes = {
    columns: PropTypes.func,
    onAction: PropTypes.func,
    onUnmount: PropTypes.func,
    onBrowserReady: PropTypes.func,
    noActionsRenderer: PropTypes.func,
    headerOptionsRenderer: PropTypes.func,
    onGetFolderContent: PropTypes.func.isRequired,
    onGetRootDirectories: PropTypes.func.isRequired,
    separator: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    limit: PropTypes.number.isRequired,
    nameHeaderLabel: PropTypes.string,
  }

  static defaultProps = {
    separator: "/",
    nameHeaderLabel: localizationKey("Name"),
    columns: () => [],
    noActionsRenderer: () => null,
    headerOptionsRenderer: () => null,
    onAction: ({ action }, options) => action(options),
  }

  constructor(props) {
    super(props)
    this.rowRenderer = this.rowRenderer.bind(this)
    this.initialize = this.initialize.bind(this)
    this.onCheckAll = this.onCheckAll.bind(this)
    this.onCheck = this.onCheck.bind(this)
    this.onBrowse = this.onBrowse.bind(this)
    this.onLoadMore = this.onLoadMore.bind(this)
    this.onKeyDownSelect = this.onKeyDownSelect.bind(this)
    this.columns = this.columns.bind(this)
    this.dispatchCallback = this.dispatchCallback.bind(this)
    this.handleRootClick = this.handleRootClick.bind(this)
    this.renderRootIcon = this.renderRootIcon.bind(this)
  }

  rows = {}

  state = {
    loading: true,
    activeRow: 0,
    mode: "browse", // "select" // "deselect"
    content: [],
    history: [],
    paginationAvailable: false,
    checked: {
      directories: {},
      length: 0,
    },
  }

  async componentDidMount(e) {
    this._isMounted = true
    const { onGetInitialState } = this.props
    const initialState = onGetInitialState && (await onGetInitialState())
    this.initialize(initialState)
    document.addEventListener("keydown", this.onKeyDownSelect)
  }

  componentWillUnmount() {
    this._isMounted = false
    document.removeEventListener("keydown", this.onKeyDownSelect)

    if (this.props.onUnmount) {
      this.props.onUnmount(this.state)
    }
  }

  async initialize(initialState) {
    try {
      this.setState({
        loading: true,
      })

      const { onBrowserReady, onGetRootDirectories, sortBy, sortDirection, setSortBy, setSortDirection } = this.props

      const content = (initialState && initialState.content) || (await onGetRootDirectories()) || []
      const history = (initialState && initialState.history) || []

      this._isMounted &&
        this.setState(
          {
            content:
              setSortBy && setSortDirection ? tableSortSelector({ data: content, sortBy, sortDirection }) : content,
            mode: "browse",
            activeRow: 0,
            history,
            checked: {
              directories: {},
              length: 0,
            },
          },
          onBrowserReady,
        )
    } catch (error) {
    } finally {
      this._isMounted && this.setState({ loading: false })
    }
  }

  onKeyDownSelect(e) {
    if (e.target.tagName === "INPUT") {
      return
    }

    const { mode, activeRow, content } = this.state
    const checked = { ...this.state.checked }

    if (!content.length) return

    if (e.keyCode === 13) {
      return (
        ["select", "deselect"].includes(mode) &&
        this.setState(
          {
            activeRow: 0,
            mode: "browse",
            checked: {
              directories: {},
              length: 0,
            },
          },
          () => {
            this.onBrowse({ dir: content[activeRow], dirIndex: activeRow })
          },
        )
      )
    }

    if (e.keyCode === 27) {
      return this.setState({
        activeRow: 0,
        mode: "browse",
        checked: {
          directories: {},
          length: 0,
        },
      })
    }

    if (e.shiftKey && e.keyCode === 38) {
      e.preventDefault()

      const prevRow = mode !== "select" ? activeRow : Math.max(activeRow - 1, 0)
      const nextRow = mode !== "select" ? activeRow : Math.min(activeRow + 1, content.length - 1)

      if (!checked.directories[content[prevRow].path]) {
        checked.directories[content[prevRow].path] = content[prevRow]
        checked.length++
      } else if (activeRow === content.length - 1 || (activeRow && !checked.directories[content[nextRow].path])) {
        delete checked.directories[content[activeRow].path]
        checked.length--
      }

      return this.setState({
        activeRow: prevRow,
        mode: "select",
        checked,
      })
    }

    if (e.shiftKey && e.keyCode === 40) {
      e.preventDefault()

      const nextRow = mode !== "select" ? activeRow : Math.min(activeRow + 1, content.length - 1)
      const prevRow = mode !== "select" ? activeRow : Math.max(activeRow - 1, 0)

      if (!checked.directories[content[nextRow].path]) {
        checked.directories[content[nextRow].path] = content[nextRow]
        checked.length++
      } else if (!activeRow || (activeRow !== content.length - 1 && !checked.directories[content[prevRow].path])) {
        delete checked.directories[content[activeRow].path]
        checked.length--
      }

      return this.setState({
        activeRow: nextRow,
        mode: "select",
        checked,
      })
    }

    if (e.keyCode === 38) {
      e.preventDefault()
      const prevRow = mode !== "select" ? activeRow : activeRow - 1 >= 0 ? activeRow - 1 : 0

      checked.directories = {}
      checked.directories[content[prevRow].path] = content[prevRow]
      checked.length = 1

      return this.setState({
        mode: "select",
        activeRow: prevRow,
        checked,
      })
    }

    if (e.keyCode === 40) {
      e.preventDefault()
      const nextRow =
        mode !== "select" ? activeRow : activeRow + 1 < content.length ? activeRow + 1 : content.length - 1

      checked.directories = {}
      checked.directories[content[nextRow].path] = content[nextRow]
      checked.length = 1

      return this.setState({
        mode: "select",
        activeRow: nextRow,
        checked,
      })
    }
  }

  onCheckAll() {
    const { checked, content } = this.state

    if (checked.length === content.length) {
      this.setState({
        activeRow: 0,
        mode: "browse",
        checked: {
          directories: {},
          length: 0,
        },
      })
    } else {
      this.setState({
        activeRow: 0,
        mode: "select",
        checked: {
          directories: content.reduce((acc, dir) => ({ ...acc, [dir.path]: dir }), {}),
          length: content.length,
        },
      })
    }
  }

  onCheck(dir, activeRow, event) {
    event.persist()

    const { activeRow: currentActiveRow, content } = this.state
    const checked = { ...this.state.checked }
    let mode = "select"

    if (event && event.shiftKey) {
      const dataSection =
        currentActiveRow <= activeRow
          ? content.slice(currentActiveRow, activeRow + 1)
          : content.slice(activeRow, currentActiveRow + 1)

      dataSection.forEach(dir => {
        if (!checked.directories[dir.path]) {
          checked.directories[dir.path] = dir
          checked.length++
        }
      })
    } else {
      if (checked.directories[dir.path]) {
        delete checked.directories[dir.path]
        checked.length--
      } else {
        checked.directories[dir.path] = dir
        checked.length++
      }

      if (event.target.checked && !checked.length) {
        mode = "browse"
      }
    }

    this.setState({
      mode,
      checked,
      activeRow: checked.length ? activeRow : 0,
    })
  }

  async onBrowse({ event, node: dir, dirIndex, historyIndex }) {
    const { onGetFolderContent, limit } = this.props
    const { path, folder, restricted } = dir
    const history = [...this.state.history]
    !this.state.scrollToTop && this.setState({ scrollToTop: true })

    if (!!this.state.checked.length && dirIndex !== undefined) {
      return this.onCheck(dir, dirIndex, event)
    }

    if (!folder || restricted) return

    this.setState({
      loading: true,
    })

    try {
      const content = (await onGetFolderContent(dir)) || []

      if (historyIndex !== undefined) {
        history.splice(historyIndex + 1)
      } else if (!history.some(folder => folder.path === path)) {
        history.push(dir)
      }

      this.setState({
        content,
        history,
        activeRow: 0,
        mode: "browse",
        paginationAvailable: content.length === limit,
        checked: {
          directories: {},
          length: 0,
        },
      })
    } catch (error) {
    } finally {
      this.setState({ loading: false })
    }
  }

  async onLoadMore() {
    if (this.isLoadingMore) return

    try {
      this.isLoadingMore = true
      this.setState({ loading: true })
      const { onGetFolderContent, limit } = this.props
      const { history, content } = this.state
      const dir = last(history)
      const lastItem = last(content)
      const offset = content.length
      const moreContent = (await onGetFolderContent(dir, offset, lastItem)) || []

      this._isMounted &&
        this.setState(
          ({ content: prevContent }) => ({
            content: [...prevContent, ...moreContent],
            paginationAvailable: moreContent.length === limit,
          }),
          () => {
            this.isLoadingMore = false
          },
        )
    } catch (error) {
    } finally {
      this._isMounted && this.setState({ loading: false })
    }
  }

  rowRenderer({ className, columns, index, key, onRowClick, rowData, style }) {
    const { mode, activeRow, checked } = this.state
    const rowProps = { "aria-rowindex": index + 1 }

    if (onRowClick) {
      rowProps["aria-label"] = "row"
      rowProps.tabIndex = 0
      rowProps.onClick = event => onRowClick({ event, index, rowData })
    }

    if (mode !== "browse" && activeRow === index) {
      this.rows[rowData.path] &&
        this.rows[rowData.path].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
    }

    return (
      <div
        ref={r => (this.rows[rowData.path] = r)}
        {...rowProps}
        key={key}
        role="row"
        style={style}
        className={`${className} ${!!checked.directories[rowData.path] ? "checked" : ""} ${
          mode !== "browse" && activeRow === index ? "active-row" : ""
        } ${rowData.disabled ? "no-hover" : ""}`}
      >
        {columns}
      </div>
    )
  }

  async dispatchCallback({ type }) {
    if (!this._isMounted) return

    switch (type) {
      case "REFRESH_ROOT": {
        try {
          this.setState({
            loading: true,
          })

          const content = (await this.props.onGetRootDirectories()) || this.state.content || []

          this._isMounted &&
            this.setState({
              content,
              activeRow: 0,
              mode: "browse",
              checked: {
                directories: {},
                length: 0,
              },
            })
        } catch (error) {
        } finally {
          this._isMounted && this.setState({ loading: false })
        }

        break
      }
      case "REFRESH": {
        try {
          this.setState({
            loading: true,
          })

          const dir = last(this.state.history)
          const content = (await this.props.onGetFolderContent(dir)) || this.state.content || []

          this._isMounted &&
            this.setState({
              content,
              activeRow: 0,
              mode: "browse",
              checked: {
                directories: {},
                length: 0,
              },
            })
        } catch (error) {
        } finally {
          this._isMounted && this.setState({ loading: false })
        }

        break
      }

      default:
        return
    }
  }

  columns() {
    const {
      props: { columns, nameHeaderLabel },
      state: { content, checked },
      onCheckAll,
      onCheck,
    } = this

    return [
      {
        dataKey: "checkbox",
        width: 37,
        className: "file-browser-table-cell file-browser-table-cell-checkbox",
        headerRenderer: ({ header, onChange }) => (
          <Checkbox
            checkboxClass="icheckbox_square-blue"
            checked={content.length > 0 && content.length === checked.length}
            onChange={() => onCheckAll()}
            disabled={!content.length}
          />
        ),
        cellRenderer: ({ rowData, rowIndex }) => (
          <Checkbox
            checkboxClass="icheckbox_square-blue"
            checked={!!checked.directories[rowData.path]}
            onChange={e => onCheck(rowData, rowIndex, e)}
          />
        ),
      },
      {
        headerRenderer,
        dataKey: "name",
        width: 1,
        flexGrow: 1,
        className: "file-browser-table-cell",
        label: localized(nameHeaderLabel),
        cellRenderer: ({ cellData, rowData }) => {
          return (
            <>
              <span className="m-r-sm">{getIcon(rowData)}</span>
              <Tooltip output={cellData}>
                <span className={`${rowData.disabled ? "disabled-dir" : ""} folder-path-name`}>{cellData}</span>
              </Tooltip>
            </>
          )
        },
      },
      ...columns(this),
    ]
  }

  handleRootClick() {
    this.initialize({ history: [] })
  }

  renderRootIcon() {
    return <FontAwesomeIcon icon={faServer} className="server-icon m-r-sm" />
  }

  render() {
    const {
      props: {
        onGetActions,
        noActionsRenderer,
        separator,
        nodeId,
        nodeClass,
        sortBy,
        sortDirection,
        setSortBy,
        setSortDirection,
        onAction,
        headerOptionsRenderer,
        loadingText = "",
      },
      state: { content, history, loading, checked, paginationAvailable, scrollToTop },
      rowRenderer,
      dispatchCallback,
      onLoadMore,
      onBrowse,
      columns,
      handleRootClick,
      renderRootIcon,
    } = this

    return (
      <>
        <div className="file-folder-browser">
          {headerOptionsRenderer(dispatchCallback)}

          <Breadcrumb
            {...{
              separator,
              history,
              handleClick: onBrowse,
              rootRenderer: () => (
                <span className={`btn-link breadcrumb-history-root-icon`} onClick={handleRootClick}>
                  {renderRootIcon()}
                </span>
              ),
              customRenderer: enforceTrailingSlashesInPath,
              colors: {
                link: "#337ab7", // TODO: replace with ninjaOne @ninja-blue-saturated
              },
            }}
          />

          {loading && <div className="data-fetching-overlay">{loadingText}</div>}

          <div className="flex-full">
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  {...{
                    width,
                    height,
                    rowRenderer,
                    sortBy,
                    sortDirection,
                    headerHeight: 45,
                    rowHeight: 45,
                    ...(scrollToTop && { scrollTop: 0 }),
                    rowCount: content.length || 0,
                    noRowsRenderer: noRowsRenderer(localizationKey("Empty Folder"), false, "no-padding"),
                    headerClassName: "file-browser-table-header",
                    rowClassName: "file-browser-table-row",
                    className: "horizontal-tiles list-group",
                    rowGetter: ({ index }) => content[index],
                    onRowClick: ({ event, rowData, index }) => {
                      onBrowse({ event, node: rowData, dirIndex: index })
                    },
                    onScroll: ({ clientHeight, scrollHeight, scrollTop }) => {
                      scrollToTop && this.setState({ scrollToTop: false })
                      const scrollTopCeil = Math.ceil(scrollTop)
                      const height = Math.round(scrollHeight - scrollTopCeil)
                      if (paginationAvailable && scrollTop > 0 && height === clientHeight) {
                        onLoadMore()
                      }
                    },
                    sort: ({ sortBy, sortDirection }) => {
                      setSortBy && setSortBy(sortBy)
                      setSortDirection && setSortDirection(sortDirection)

                      if (setSortBy && setSortDirection) {
                        this.setState({ content: tableSortSelector({ data: content, sortBy, sortDirection }) })
                      }
                    },
                  }}
                >
                  {columns().map(column => (
                    <Column {...{ key: column.dataKey, ...column }} />
                  ))}
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>

        <FileFolderBrowserPanel
          {...{
            nodeId,
            nodeClass,
            onGetActions,
            dispatchCallback,
            checked,
            history,
            content,
            noActionsRenderer,
            onAction,
          }}
        />
      </>
    )
  }
}

export default FileFolderBrowser
