import tokens from "@ninjaone/tokens"
import { Checkbox } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import withInheritance from "js/includes/components/withInheritance"
import { getSettings } from "./util"

const RemoveRsrCheckbox = ({ inheritableItem: allowRsrRemovalSettings, onChange }) => {
  return (
    <Box marginTop={tokens.spacing[2]}>
      <Checkbox
        label={localized("Allow the user to remove Rapid Security Responses")}
        checked={getSettings(allowRsrRemovalSettings, "allowRsrRemoval")}
        onChange={({ isChecked }) => {
          onChange({
            ...allowRsrRemovalSettings,
            allowRsrRemoval: isChecked,
          })
        }}
      />
    </Box>
  )
}

export default withInheritance(RemoveRsrCheckbox)
