import { memo } from "react"
import PropTypes from "prop-types"

import styled from "@emotion/styled/macro"

import tokens from "@ninjaone/tokens"
import { OpenArrowIcon } from "@ninjaone/icons"

import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

const StyledText = styled.div`
  line-height: 1.14;
  white-space: nowrap;
  font-size: ${tokens.typography.fontSize.headingXs};

  color: ${({ theme }) => theme.colorTextWeakest};

  // add hidden pseudo element w/ same text + active font-weight to avoid layout shift
  &::after {
    visibility: hidden;
    overflow: hidden;

    user-select: none;
    pointer-events: none;

    height: 0;
    display: block;

    font-weight: ${tokens.typography.fontWeight.medium};
    content: "${({ text }) => text}";
  }
`

const StyledOpenArrowIcon = styled(OpenArrowIcon)`
  margin-left: ${tokens.spacing[2]};
`

const StyledTabButton = styled.button`
  display: inline-block;
  background: transparent;

  padding-right: ${tokens.spacing[3]};
  padding-left: ${tokens.spacing[3]};

  border: none;

  text-decoration: none;
  height: 38px;

  user-select: none;
  outline: none !important; // override bootstrap

  &.open,
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &.active {
    z-index: 10;
    background-color: ${({ theme }) => theme.colorForegroundSelected};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};

    ${StyledText} {
      color: ${({ theme }) => theme.colorTextActionStrong};
      font-weight: ${tokens.typography.fontWeight.medium};
    }

    ${StyledOpenArrowIcon} {
      color: ${({ theme }) => theme.colorTextActionStrong};
      font-weight: ${tokens.typography.fontWeight.medium};
    }
  }
`

const StyledTabAnchor = StyledTabButton.withComponent("a")

const NavigationTab = ({ isActive, isOpen, labelText, labelToken, showArrow, route, onClick }) => {
  const isAnchor = !!route
  const label = labelToken ? localized(labelToken) : labelText
  const StyledTab = isAnchor ? StyledTabAnchor : StyledTabButton

  return (
    <StyledTab
      {...{
        className: `${isActive ? "active" : ""} ${isOpen ? "open" : ""}`,
        ...(isAnchor && { href: route }),
        ...(onClick && { onClick }),
      }}
    >
      <Flex justifyContent="center" alignItems="center" height="100%">
        <StyledText text={label}>{label}</StyledText>

        {showArrow && <StyledOpenArrowIcon color="darkGrayAlt" size="xs" direction="down" />}
      </Flex>
    </StyledTab>
  )
}

export default memo(NavigationTab)

NavigationTab.propTypes = {
  isOpen: PropTypes.bool,
  route: PropTypes.string,
  isActive: PropTypes.bool,
  showArrow: PropTypes.bool,
  labelText: PropTypes.string,
  labelToken: PropTypes.string,
  onClick: PropTypes.func,
}
