import { useState } from "react"
import PropTypes from "prop-types"
import { localized, localizationKey } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import tokens from "@ninjaone/tokens"
import DatePicker from "../DatePicker"
import Modal from "../../../Modal"

const DatePickerStartEndModal = ({
  bottomRenderer,
  disabledDays = [],
  endDayErrorMessage,
  endDateLabelText = localized("End date"),
  endDateSelectedDay,
  endDateRequired,
  excludeYear,
  fromMonth,
  locale,
  modalDescriptionText,
  modalTitleText,
  modalLabelToken,
  ModalSubheaderComponent = () => null,
  onEndDayChange,
  onSave,
  onStartDayChange,
  renderCustomDropdownNavigation,
  saving,
  startDayErrorMessage,
  startDateLabelText = localized("Start date"),
  startDateRequired,
  startDateSelectedDay,
  toMonth,
  topRenderer,
  updateDisabledDays,
  unmount = () => {},
}) => {
  const [startDate, setStartDate] = useState(startDateSelectedDay)
  const [endDate, setEndDate] = useState(endDateSelectedDay)
  const [startDatesDisabeled, setStartDatesDisabeled] = useState(
    endDateSelectedDay ? { after: endDateSelectedDay } : null,
  )
  const [endDatesDisabled, setEndDatesDisabeled] = useState(
    startDateSelectedDay ? { before: startDateSelectedDay } : null,
  )

  const handleStartDayChange = ({ date }) => {
    updateDisabledDays
      ? setEndDatesDisabeled({
          before: date,
          after: updateDisabledDays({ startDate: date }),
        })
      : setEndDatesDisabeled({
          before: date,
        })

    setStartDate(date)
    onStartDayChange?.({ startDate: date, endDate })
  }

  const handleEndDayChange = ({ date }) => {
    updateDisabledDays
      ? setStartDatesDisabeled({
          before: updateDisabledDays({ endDate: date }),
          after: date,
        })
      : setStartDatesDisabeled({
          after: date,
        })

    setEndDate(date)
    onEndDayChange?.({ startDate, endDate: date })
  }

  return (
    <Modal
      {...{
        unmount,
        heading: modalTitleText,
        description: modalDescriptionText,
        titleGroup: {
          SubheaderComponent: () => ModalSubheaderComponent({ startDate, endDate }),
        },
        buttons: [
          {
            type: "save",
            disabled: saving || (startDateRequired && !startDate) || (endDateRequired && !endDate),
            onClick: () => onSave({ from: startDate, to: endDate, unmount }),
            labelToken: localizationKey("Save"),
          },
        ],
      }}
      cancelable
      withCloseX
    >
      <Flex flexDirection="column" gap={tokens.spacing[4]}>
        {topRenderer?.()}

        <DatePicker
          disabledDays={[...disabledDays, startDatesDisabeled].filter(Boolean)}
          errorMessage={startDayErrorMessage}
          fullWidth
          initialMonth={startDate ?? endDate}
          labelText={startDateLabelText}
          onDayChange={handleStartDayChange}
          selectedDays={startDateSelectedDay}
          required={startDateRequired}
          readOnly
          useDropdownNavigation
          {...{
            excludeYear,
            fromMonth,
            locale,
            renderCustomDropdownNavigation,
            toMonth,
          }}
        />

        <DatePicker
          disabledDays={[...disabledDays, endDatesDisabled].filter(Boolean)}
          errorMessage={endDayErrorMessage}
          fullWidth
          initialMonth={endDate ?? startDate}
          labelText={endDateLabelText}
          onDayChange={handleEndDayChange}
          selectedDays={endDateSelectedDay}
          required={endDateRequired}
          readOnly
          useDropdownNavigation
          {...{
            excludeYear,
            fromMonth,
            locale,
            renderCustomDropdownNavigation,
            toMonth,
          }}
        />

        {bottomRenderer?.({ startDate, endDate })}
      </Flex>
    </Modal>
  )
}

export default DatePickerStartEndModal

DatePickerStartEndModal.propTypes = {
  /**
   * The renderer function used to display content below the DatePickers.
   */
  bottomRenderer: PropTypes.func,
  /**
   * The dates that should be disabled in the calendar. Supports [Matching
   * days](https://react-day-picker-v7.netlify.app/docs/matching-days). Must be
   * passed as an array.
   */
  disabledDays: PropTypes.array,
  /**
   * Sets an error message for the end date.
   */
  endDayErrorMessage: PropTypes.string,
  /**
   * The text for the end date label.
   */
  endDateLabelText: PropTypes.string,
  /**
   * Sets the value of the end date input and passed to the calendar to select
   * the day.
   */
  endDateSelectedDay: PropTypes.instanceOf(Date),
  /**
   * The required state of the end date.
   */
  endDateRequired: PropTypes.bool,
  /**
   * Determines if the year should be excluded from the localized date format
   * when `readOnly` is true, and hides the year dropdown in the calendar
   * navigation.
   */
  excludeYear: PropTypes.bool,
  /**
   * The first allowed month. Users won’t be able to navigate or interact with
   * the days before it. See also `toMonth`.
   */
  fromMonth: PropTypes.instanceOf(Date),
  /**
   * The locale for the component. Defaults to browser's locale.
   */
  locale: PropTypes.string,
  /**
   * The description text for the modal.
   */
  modalDescriptionText: PropTypes.string,
  /**
   * The title for the modal.
   */
  modalTitleText: PropTypes.string,
  /**
   * The label token for the modal.
   */
  modalLabelToken: PropTypes.string,
  /**
   * The subheader component for the modal.
   */
  ModalSubheaderComponent: PropTypes.func,
  /**
   * Event handler run when a new day is selected from the end date calendar.
   */
  onEndDayChange: PropTypes.func,
  /**
   * Event handler run when the save button is clicked.
   */
  onSave: PropTypes.func,
  /**
   * Event handler run when a new day is selected from the start date calendar.
   */
  onStartDayChange: PropTypes.func,
  /**
   * Renders a custom dropdown component.
   */
  renderCustomDropdownNavigation: PropTypes.func,
  /**
   * The saving state of the component.
   */
  saving: PropTypes.bool,
  /**
   * Sets an error message for the start date.
   */
  startDayErrorMessage: PropTypes.string,
  /**
   * The text for the start date label.
   */
  startDateLabelText: PropTypes.string,
  /**
   * The required state of the start date.
   */
  startDateRequired: PropTypes.bool,
  /**
   * Sets the value of the start date input and passed to the calendar to select
   * the day.
   */
  startDateSelectedDay: PropTypes.instanceOf(Date),
  /**
   * The last allowed month. Users won’t be able to navigate or interact with
   * the days after it. See also `fromMonth`.
   */
  toMonth: PropTypes.instanceOf(Date),
  /**
   * The renderer function used to display content above the DatePickers.
   */
  topRenderer: PropTypes.func,
  /**
   * Event handler for recalculating what days to disable in the calendar based
   * on logic from the parent component.
   */
  updateDisabledDays: PropTypes.func,
  /**
   * Event handler run when the component unmounts.
   */
  unmount: PropTypes.func,
}
