import { NinjaResponseError } from "js/includes/common/types"
import { ninjaReportError, showErrorMessage, showSuccessMessage, fetchJson, localized } from "js/includes/common/utils"

const setHealthStatus = healthStatus => ({
  type: "SET_HEALTH_STATUS",
  healthStatus,
})

const setHealthStatuses = healthStatuses => ({
  type: "SET_HEALTH_STATUSES",
  healthStatuses,
})

export const revertToDefault = () => ({
  type: "REVERT_TO_DEFAULT_HEALTH_STATUS",
})

export const fetchHealthStatuses = () => async dispatch => {
  try {
    await getHealthStatus(dispatch)
  } catch (error) {
    ninjaReportError(error)
  }
}

export const saveHealthStatuses = healthStatus => async dispatch => {
  try {
    const response = await fetchJson("/divisionconfig/health_status", {
      options: {
        method: "PATCH",
        body: JSON.stringify(healthStatus),
      },
    })
    if (response.resultCode !== "SUCCESS") throw new NinjaResponseError(response)
    showSuccessMessage(localized("Saving..."))
    dispatch(setHealthStatus(healthStatus))
  } catch (error) {
    showErrorMessage(localized("Error saving custom health statuses"))
    ninjaReportError(error)
  }
}

export const getHealthStatus = () => async dispatch => {
  try {
    const response = await fetchJson("/divisionconfig/health_status")
    const healthStatus = response?.content?.customHealth ?? []
    dispatch(setHealthStatuses(healthStatus))
  } catch (error) {
    ninjaReportError(error)
    showErrorMessage(localized("Failed"))
  }
}
