import { memo } from "react"
import { connect } from "react-redux"
import { Button, Tags } from "@ninjaone/components"
import { UndoRegulartIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { revertPolicySection as _revertPolicySection } from "js/state/actions/policyEditor/editor"

const InheritedTag = ({ overridden, revert, disableRevert, disableRevertText }) => {
  return (
    <Flex>
      {overridden && (
        <Box className="revertButton" marginRight={tokens.spacing[2]}>
          <Button
            variant="tertiary"
            labelToken={localizationKey("Revert")}
            Icon={UndoRegulartIcon}
            onClick={revert}
            compact
            disabled={disableRevert}
            tooltip={disableRevertText}
          />
        </Box>
      )}
      <Tags
        labels={[
          {
            id: "inheritedTag",
            variant: overridden ? "alternate" : "default",
            label: overridden ? localized("Overridden") : localized("Inherited"),
          },
        ]}
      />
    </Flex>
  )
}

const Inheritable = Component => {
  return memo(function Inheritable({ revertPolicySection, pathToItem, inheritableItem, ...props }) {
    const revert = () => revertPolicySection(pathToItem, inheritableItem)

    const {
      inheritance: { overridden, inherited },
    } = inheritableItem

    const { disableRevert = false, disableRevertText = null } = props

    const inheritanceElement = inherited ? (
      <InheritedTag {...{ overridden, revert, disableRevert, disableRevertText }} />
    ) : null

    return (
      <Component
        {...{
          inheritanceElement,
          inheritableItem,
          ...props,
        }}
      />
    )
  })
}

export default function ReduxInheritable(Component) {
  return connect(({ policyEditor: { policy } }) => ({ policy }), { revertPolicySection: _revertPolicySection })(
    Inheritable(Component),
  )
}
