export const color = {
  primary: {
    "100": "#337AB7",
    "075": "#669CC9",
    "050": "#CCDEED",
    "025": "#F2F7FA",
    "015": "#E0EBF4",
    "005": "#F5F8FA",
  },
  secondary: {
    "100": "#484469",
    "050": "#A3A1B4",
    "040": "#B6B4C4",
    "025": "#D1D0D9",
    "005": "#F6F5F7",
  },
  tertiary: {
    "100": "#2F4050",
    "050": "#979FA7",
    "025": "#CBCFD3",
    "005": "#F4F5F6",
  },
  warning: {
    "100": "#FAC905",
    "050": "#FCE482",
    "025": "#FEF1C0",
    "015": "#FDF9E6",
    "005": "#FFFCF2",
  },
  error: {
    "100": "#D53948",
    "075": "#E25562",
    "050": "#EA9CA3",
    "025": "#F4CDD1",
    "015": "#F9EBEC",
    "005": "#FDF5F6",
  },
  success: {
    "100": "#018200",
    "075": "#4C9E4B",
    "050": "#92D2A1",
    "025": "#C9E9D0",
    "015": "#E4F2E5",
    "005": "#F4FAF5",
  },
  black: {
    "100": "#211F33",
    "075": "#595766",
    "065": "#6E6D7A",
    "050": "#908F99",
    "025": "#C7C7CC",
    "015": "#DEDEE1",
    "010": "#E8E8EA",
    "005": "#F4F4F5",
  },
  white: {
    "100": "#FFFFFF",
    "075": "#F8F8F9",
    "050": "#908F99",
    "025": "#585766",
    "005": "#2C2A3D",
  },
  elevation: {
    shadow4: "0px 4px 8px #DEDEE1",
    shadow3: "0px 2px 8px #E8E8EA",
    shadow2: "0px 2px 4px #E8E8EA",
    shadow1: "0px 2px 4px #E8E8EA",
  },
  purple: "#886FC5",
  blueLight: "#77b9e5",
  errorHover: "#BF3340",
  trialBannerBackground: "#0257ac",
  primaryHover: "#2F70A8",
  ninjaBlue: "#2F4050",
  ninjaGreen: "#26A644",
  ninjaMedium: "#949597",
  ninjaBlueDark: "#23527c",
  ninjaLightBlue: "#EBF4FD",
  ninjaDark: "#616264",
  background: "#FFFFFF",
  border: "#E1E2E4",
  listItemActiveBackground: "#FAFBFD",
  text: "#484469",
  yellow: "#FAC905",
  lightYellow: "rgba(255,179,0,0.05)",
  red: "#D13939",
  veryLightGray: "#F4F5FA",
  lightGray: "#F6F5F7", // rgba(72,68,105,0.05)
  gray: "#D1D0DA", // rgba(72,68,105,0.25)
  darkGray: "#A3A1B3", // rgba(72,68,105,0.5)
  brightGray: "#EEEEEE",
  darkGrayAlt: "#76738F", // rgba(72,68,105,0.75)
  boxShadow15: "rgba(50, 50, 105, 0.15)",
  veryDarkGray: "rgba(33,31,51,0.5)",
  linkBackground: "#F2F6FA",
  action: "#337AB7",
  inputText: "#211F33",
  buttonLightGrayBackground: "#f5f5f7",
  divider: "#E7E7E7",
  primaryBlue5: "rgb(51, 122, 183, 0.05)",
  infoBannerBackground: "#F8F6F0",
  widgetBorder: "#DDDDDD",
  indicatorColor: "#0680FF",
  bannerBackground: "#F3F7FB",
  trainingBannerBackground: "#CEE7F0",
}
