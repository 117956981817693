import { useMemo } from "react"
import { splitAt } from "ramda"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import styled from "@emotion/styled"
import { v4 as uuidv4 } from "uuid"
import PropTypes from "prop-types"

import tokens from "@ninjaone/tokens"
import { isRequiredIf } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"

import Body from "./Typography/Body"
import { Label } from "./Form/Label"
import Tooltip from "./Tooltip"

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${tokens.spacing[2]};
  min-height: 38px;
  padding: ${tokens.spacing[2]} ${tokens.spacing[3]};
  border-radius: ${tokens.borderRadius[1]};
  color: ${({ theme }) => `${theme.colorTextWeak}`};
  background-color: ${({ theme }) => `${theme.colorBackgroundAccentNeutralWeakest}`};
`

const StyledListItem = styled.li`
  display: inline;
  list-style: none;
  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => `${theme.colorTextStrong}`};
  cursor: default;
`

const StyledButton = styled.button`
  padding: 0;
  border: 0;
  border-radius: ${tokens.borderRadius[1]};
  background: none;
  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => `${theme.colorTextActionStrong}`};
  user-select: none;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const SelectionEditField = ({
  ariaLabel,
  buttonText: buttonLabelText,
  labelText,
  selection: fieldItems = [],
  onClick,
  placeholder,
  maxItemsDisplayed = 2,
}) => {
  const [items, remainingItems] = splitAt(maxItemsDisplayed || fieldItems.length, fieldItems)
  const buttonText = items.length > 0 ? buttonLabelText : localized("Add")

  const inputId = useMemo(() => {
    return uuidv4()
  }, [])

  const getRemainingItemsLabel = () => {
    const label = remainingItems
      .slice(0, 10)
      .map((item, i) => <span key={item}>{i !== remainingItems.length - 1 ? `${item}, ` : item}</span>)

    if (remainingItems.length > 10) {
      label.push(<span key="more-elements">...</span>)
    }

    return label
  }

  return (
    <div>
      {labelText && <Label labelFor={inputId} {...{ labelText }} />}

      <StyledContainer>
        <VisuallyHidden>
          <input
            aria-label={!labelText ? ariaLabel : undefined}
            disabled
            type="text"
            id={inputId}
            value={[...items, ...remainingItems].join(", ")}
            {...{ placeholder }}
          />
        </VisuallyHidden>

        <div aria-hidden="true" style={{ overflow: "hidden" }}>
          {items.length > 0 ? (
            <Flex as="ul" gap={tokens.spacing[1]} margin={0} padding={0} flexWrap="wrap">
              {items.map((item, i) => (
                <StyledListItem key={item}>
                  {i !== items.length - 1 || remainingItems.length ? `${item}, ` : item}
                </StyledListItem>
              ))}

              {!!remainingItems.length && (
                <StyledListItem>
                  <Tooltip label={getRemainingItemsLabel()} align="center">
                    {localized("+{{remainingItems}} more", { remainingItems: remainingItems.length })}
                  </Tooltip>
                </StyledListItem>
              )}
            </Flex>
          ) : (
            <Body color="colorTextWeakest">{placeholder}</Body>
          )}
        </div>

        <StyledButton
          aria-label={labelText || ariaLabel ? `${buttonText} ${labelText || ariaLabel}` : buttonText}
          {...{ onClick }}
        >
          {buttonText}
        </StyledButton>
      </StyledContainer>
    </div>
  )
}

SelectionEditField.propTypes = {
  /**
   * The label used by assistive technology when `labelText` is not defined.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.labelText,
    "`ariaLabel` is required when `labelText` is undefined",
  ),
  /**
   * The text for the button in the component.
   */
  buttonText: PropTypes.string.isRequired,
  /**
   * The label for the component.
   */
  labelText: PropTypes.string,
  /**
   * The array of selection items displayed in the component.
   */
  selection: PropTypes.arrayOf(PropTypes.string),
  /**
   * The event handler for the button in the component.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * The text displayed when no selection is defined.
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * The maximum items displayed in full before a tooltip with the remaining
   * selection is displayed.
   */
  maxItemsDisplayed: PropTypes.number,
}

export default SelectionEditField
