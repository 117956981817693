import { memo, useCallback, useState } from "react"
import CreatableSelect from "react-select/creatable"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import Tooltip from "js/includes/components/Tooltip"

const DaemonSelect = memo(({ labelToken, tooltipToken, values, options, onChange, prop }) => {
  const [inputValue, setInputValue] = useState("")
  const handleServiceSelected = useCallback(
    value => {
      onChange("selected", value ?? [])
    },
    [onChange],
  )
  return (
    <FormGroup controlId={prop} validationState={values?.errors?.message?.[prop] ? "error" : null}>
      <Col componentClass={ControlLabel} sm={4}>
        {localized(labelToken)}
        {tooltipToken && (
          <Box className="two-rows-ellipsis" marginLeft="2px" display="inline">
            <Tooltip token={tooltipToken}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </Box>
        )}
      </Col>
      <Col sm={8}>
        <CreatableSelect
          isClearable
          autoFocus
          inputValue={inputValue}
          value={values.include[0]}
          placeholder={localized("Select an option")}
          onChange={handleServiceSelected}
          options={options.map(option => ({ value: option.value, label: option.text }))}
          formatCreateLabel={value => `${localized("general.add")} "${value}"`}
          onInputChange={value => setInputValue(value.trim())}
        />
        {values?.errors?.message?.[prop] && <em className="invalid m-t-none"> {values.errors.message[prop]}</em>}
      </Col>
    </FormGroup>
  )
})

export default DaemonSelect
