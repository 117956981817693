import qs from "qs"
import { fetchJson } from "js/includes/common/utils"
import { csvDownload } from "js/includes/common/client"

export const saveDevices = (deviceIds, endUserId) =>
  fetchJson(`/end-user/${endUserId}/devices/addList`, {
    options: {
      method: "POST",
      body: JSON.stringify({ deviceIds }),
    },
  })

export const deviceWolAttempt = nodeId => fetchJson(`/end-user/node/wol/${nodeId}`)

export const searchEndUsers = ({ pageIndex, pageSize, sortBy, searchCriteria }) =>
  fetchJson(
    `/search/enduser/runner?${qs.stringify({
      index: pageIndex * pageSize,
      pageSize,
      ...(sortBy ? { sortProperty: sortBy.id, sortDirection: sortBy.desc ? "DESC" : "ASC" } : {}),
    })}`,
    {
      options: {
        method: "POST",
        body: JSON.stringify({
          searchCriteria,
        }),
      },
    },
  )

export const getEndUsersActions = ({ cacheKey, selectedUserIds, searchCriteria }) => {
  return fetchJson("/search/enduser/user-actions", {
    options: {
      method: "POST",
      body: JSON.stringify({
        cacheKey,
        selectedUserIds,
        searchCriteria,
      }),
    },
  })
}

export const executeEndUserAction = ({ cacheKey, action, selectedUserIds, searchCriteria, data }) => {
  return fetchJson("/search/enduser/execute-action", {
    options: {
      method: "POST",
      body: JSON.stringify({
        cacheKey,
        action,
        selectedUserIds,
        searchCriteria,
        data,
      }),
    },
  })
}

export const exportEndUsersSearchResult = ({ columns, exportName, searchCriteria }) => {
  return csvDownload({
    url: "/search/enduser/export-csv",
    body: {
      exportName,
      searchCriteria,
      ...(columns ? { columns } : {}),
    },
    fetchAsJson: true,
  })
}

export const getEndUser = endUserId => fetchJson(`/search/enduser/${endUserId}/details`)
export const getEndUserTicketSummary = endUserId => fetchJson(`/search/enduser/${endUserId}/ticket-summary`)
export const getEndUserActivities = payload =>
  fetchJson("/activity/search", {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })

export const getEndUserActivityGroups = () => fetchJson("/activityStatuses/groups")

export const getEndUserDevicesSummary = ({ type = "user-assigned", selectedValues = [] }) =>
  fetchJson("/search/runner", {
    options: {
      method: "POST",
      body: JSON.stringify({
        searchCriteria: [
          {
            type,
            id: -1,
            customFields: JSON.stringify({ selectedValues }),
          },
        ],
      }),
    },
  })
