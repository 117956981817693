import { memo } from "react"

import { Tooltip } from "@ninjaone/components"
import { Text } from "@ninjaone/components"
import { InfoCircleIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

import { Box, Flex } from "js/includes/components/Styled"

export const FieldWrapper = memo(
  ({ children, labelToken, labelText, actionRenderer, errorMessage = "", inline = false, labelId, description }) => {
    return (
      <Flex flexDirection={inline ? "row" : "column"} justifyContent={inline ? "space-between" : "flex-start"}>
        <Box display="grid" gridTemplateColumns="1fr auto" gridGap={tokens.spacing[2]}>
          <Box display="grid" gridTemplateColumns="auto 1fr" gridGap={tokens.spacing[2]}>
            <Box
              {...{
                className: "text-ellipsis",
                ...(labelId && {
                  id: labelId,
                }),
              }}
            >
              <Text
                {...{
                  type: "body",
                  color: errorMessage ? "colorTextDanger" : "colorTextStrong",
                  token: labelToken,
                  children: labelText,
                }}
              />
            </Box>
            {description && (
              <>
                {" "}
                <Flex alignItems="center">
                  <Tooltip label={description}>
                    <InfoCircleIcon size="xs" />
                  </Tooltip>
                </Flex>
              </>
            )}
          </Box>
          <Box>{actionRenderer}</Box>
        </Box>
        <Box marginTop={tokens.spacing[1]}>{children}</Box>
      </Flex>
    )
  },
)
