import { isDocumentationTab } from "js/includes/common/utils"

const filterTabs = ({ isDocumentationEnabledFromSettings = false }) => tab => {
  if (isDocumentationTab(tab)) {
    return isDocumentationEnabledFromSettings
  }

  return true
}

export default filterTabs
