import { compose, concat, take } from "ramda"

export const MAX_ACTIVITIES = 1000

const activities = (state = [], action) => {
  switch (action.type) {
    case "SET_ACTIVITIES":
      return action.data
    case "APPEND_ACTIVITIES":
      return compose(take(MAX_ACTIVITIES), concat(state))(action.data)
    case "PREPEND_ACTIVITIES":
      return compose(take(MAX_ACTIVITIES), concat(action.data))(state)
    case "RESET_ACTIVITIES":
      return []
    default:
      return state
  }
}

export default activities
