import {
  always,
  compose,
  cond,
  gt,
  gte,
  includes,
  indexOf,
  isNil,
  lt,
  lte,
  map,
  omit,
  pick,
  T,
  toLower,
  curry,
} from "ramda"

import { MAX_INTEGER_VALUE } from "js/includes/common/_constants"
import {
  applyMultipleValidations,
  isNumber,
  isValidInteger,
  isValidNumber,
  localizationKey,
  localized,
  localizedF,
  normalizeInt,
  precisionRound,
  validations,
} from "js/includes/common/utils"
import { CREATE_PRODUCT, EDIT_PRODUCT } from "js/includes/configuration/integrations/psa/psaProducts/actions"
import {
  getProductTypeMapper,
  productTypes,
} from "js/includes/configuration/integrations/psa/psaProducts/productCommons"

export const MAX_MONETARY_VALUE_ALLOWED = 1000000000000000
export const moneyPrecision = 2
export const multiplierPrecision = 4
const normalizeMoneyValue = value => precisionRound(value, moneyPrecision)
const normalizeMultiplierValue = value => precisionRound(value, multiplierPrecision)
const normalizeInteger = value => +normalizeInt(`${value}`)

const softwareAndHardwareContentMapper = type => ({
  name,
  description,
  price,
  cost,
  taxable,
  quantity,
  licensingType,
  term,
  billing,
}) => ({
  name: name.trim(),
  description: description.trim(),
  type,
  content: {
    price: normalizeMoneyValue(price),
    cost: normalizeMoneyValue(cost),
    taxable,
    quantity: normalizeInteger(quantity),
    licensingType,
    term,
    billing,
  },
})

export const hasErrors = curry((errors, types) => types.some(type => errors[type] === false))

export const managedDevicesGroupOptions = {
  any: "any",
  all: "all",
}

export const anyRoleValue = "__ANY__"
export const specificRoleValue = "__SPECIFIC_ROLE__"

const buildUserProductContentMapper = (type = productTypes.USER_PRODUCT) => ({
  name,
  description,
  price,
  cost,
  taxable,
  billing,
  quantity,
}) => ({
  name: name.trim(),
  description: description.trim(),
  type,
  content: {
    price: normalizeMoneyValue(price),
    cost: normalizeMoneyValue(cost),
    taxable,
    billing,
    quantity: normalizeInteger(quantity),
  },
})

export const contentMapper = {
  [productTypes.USER_PRODUCT]: buildUserProductContentMapper(),
  [productTypes.SOFTWARE]: softwareAndHardwareContentMapper(productTypes.SOFTWARE),
  [productTypes.HARDWARE]: softwareAndHardwareContentMapper(productTypes.HARDWARE),
  [productTypes.PRODUCT_GROUP]: ({ name, description, price, cost, taxable, billing, productIds }) => ({
    name: name.trim(),
    description: description.trim(),
    type: productTypes.PRODUCT_GROUP,
    content: {
      price: normalizeMoneyValue(price),
      cost: normalizeMoneyValue(cost),
      taxable,
      billing,
      productIds,
    },
  }),
  [productTypes.MANAGED_DEVICES]: (
    { name, description, price, cost, taxable, billing, nodeRoleId, anyRole, groupIds, allGroups, quantity },
    { editDynamicQuantity = false } = {},
  ) => ({
    name: name.trim(),
    description: description.trim(),
    type: productTypes.MANAGED_DEVICES,
    content: {
      price: normalizeMoneyValue(price),
      cost: normalizeMoneyValue(cost),
      taxable,
      billing,
      nodeRoleId: anyRole ? null : nodeRoleId,
      anyRole,
      groupIds,
      // TODO remove the tags field if the server won't support it.
      tags: [],
      allGroups: allGroups === managedDevicesGroupOptions.all,
      ...(editDynamicQuantity && { quantity: normalizeInteger(quantity) }),
    },
  }),
  [productTypes.LABOR_BILLED]: ({ name, description, price, cost, taxable, billing, quantity }) => ({
    name: name.trim(),
    description: description.trim(),
    type: productTypes.LABOR_BILLED,
    content: {
      price: normalizeMoneyValue(price),
      cost: normalizeMoneyValue(cost),
      taxable,
      billing,
      quantity: normalizeInteger(quantity),
    },
  }),
  [productTypes.LABOR_TICKET_TIME_ENTRY]: ({
    name,
    description,
    price,
    cost,
    taxable,
    billing,
    autoRoundTimeEntries,
    userRoles,
    laborCodes,
    onSiteMultiplier,
    offHoursMultiplier,
    remoteMultiplier,
    normalHoursMultiplier,
  }) => ({
    name: name.trim(),
    description: description.trim(),
    type: productTypes.LABOR_TICKET_TIME_ENTRY,
    content: {
      price: normalizeMoneyValue(price),
      cost: normalizeMoneyValue(cost),
      taxable,
      billing,
      autoRoundTimeEntries,
      userRoles: map(pick(["id", "type"]), userRoles),
      laborCodes,
      onSiteMultiplier: normalizeMultiplierValue(onSiteMultiplier),
      offHoursMultiplier: normalizeMultiplierValue(offHoursMultiplier),
      remoteMultiplier: normalizeMultiplierValue(remoteMultiplier),
      normalHoursMultiplier: normalizeMultiplierValue(normalHoursMultiplier),
    },
  }),
  [productTypes.CUSTOM_PRODUCT]: buildUserProductContentMapper(productTypes.CUSTOM_PRODUCT),
}

const minMoneyValue = 0
export const minQuantityValue = 1
export const maxQuantityValue = MAX_INTEGER_VALUE

const isInvalidNumber = value => {
  return typeof value !== "number" && !isValidNumber(value.trim().length > 0 ? value : NaN)
}

const containsE = compose(includes("e"), toLower)

const buildMinMaxNumberConditions = ({ minValue, isMinValueExclusive, maxValue, isMaxValueExclusive }) => {
  const conditions = []

  if (isNumber(minValue)) {
    conditions.push(
      isMinValueExclusive
        ? [
            value => !gt(parseFloat(value, 10), minValue),
            always({ success: false, message: localized("Value must be greater than {{min}}", { min: minValue }) }),
          ]
        : [
            value => !gte(parseFloat(value, 10), minValue),
            always({ success: false, message: `${localized("Value must be greater than or equal to")} ${minValue}` }),
          ],
    )
  }

  if (isNumber(maxValue)) {
    conditions.push(
      isMaxValueExclusive
        ? [
            value => !lt(parseFloat(value, 10), maxValue),
            always({ success: false, message: localized("Value must be less than {{max}}", { max: maxValue }) }),
          ]
        : [
            value => !lte(parseFloat(value, 10), maxValue),
            always({ success: false, message: `${localized("Value must be less than or equal to")} ${maxValue}` }),
          ],
    )
  }

  return conditions
}

const buildNumericValidation = ({
  minValue,
  isMinValueExclusive,
  maxValue,
  isMaxValueExclusive,
  shouldBeInteger,
  maxDecimalPlaces = 2,
}) => value =>
  cond([
    [isInvalidNumber, always({ success: false, message: localized("Invalid") })],
    [
      value => containsE(`${value}`),
      always({ success: false, message: localized("Must not be in scientific notation") }),
    ],
    [
      value => {
        if (isNil(maxDecimalPlaces)) {
          return false
        }

        const separatorIndex = indexOf(".", `${value}`)
        return separatorIndex !== -1 && value.length - 1 - separatorIndex > maxDecimalPlaces
      },
      always({ success: false, message: localized("Can contain up to {{n}} decimal places", { n: maxDecimalPlaces }) }),
    ],
    [
      value => shouldBeInteger && !isValidInteger(+value),
      always({ success: false, message: localized("Must contain one integer") }),
    ],
    ...buildMinMaxNumberConditions({ minValue, isMinValueExclusive, maxValue, isMaxValueExclusive }),
    [T, always({ success: true })],
  ])(value)

const commonMoneyFieldValidation = buildNumericValidation({
  minValue: minMoneyValue,
  maxValue: MAX_MONETARY_VALUE_ALLOWED,
  maxDecimalPlaces: moneyPrecision,
})

const timeEntryMultiplierValidation = buildNumericValidation({
  minValue: 0,
  isMinValueExclusive: true,
  maxValue: 100,
  isMaxValueExclusive: true,
  maxDecimalPlaces: multiplierPrecision,
})

const quantityFieldValidation = buildNumericValidation({
  minValue: minQuantityValue,
  maxValue: maxQuantityValue,
  shouldBeInteger: true,
})

const softwareProductValidation = {
  name: validations.required,
  description: validations.required,
  price: commonMoneyFieldValidation,
  cost: commonMoneyFieldValidation,
  quantity: quantityFieldValidation,
  licensingType: validations.required,
  term: validations.required,
}

const userProductValidation = {
  name: validations.required,
  description: validations.required,
  price: commonMoneyFieldValidation,
  cost: commonMoneyFieldValidation,
  billing: validations.required,
  quantity: applyMultipleValidations([quantityFieldValidation, validations.required]),
}

const nodeRoleValidation = (value, { anyRole }) => {
  if (anyRole) return { success: true, message: "" }
  return validations.required(value)
}
const formValidations = {
  [productTypes.USER_PRODUCT]: () => userProductValidation,
  [productTypes.SOFTWARE]: () => softwareProductValidation,
  [productTypes.HARDWARE]: () => softwareProductValidation,
  [productTypes.PRODUCT_GROUP]: () => ({
    ...userProductValidation,
    productIds: validations.required,
  }),
  [productTypes.MANAGED_DEVICES]: ({ editDynamicQuantity }) => ({
    ...(editDynamicQuantity ? userProductValidation : omit(["quantity"], userProductValidation)),
    nodeRoleId: nodeRoleValidation,
  }),
  [productTypes.LABOR_BILLED]: () => ({
    ...userProductValidation,
    quantity: quantityFieldValidation,
  }),
  [productTypes.LABOR_TICKET_TIME_ENTRY]: () => ({
    ...omit(["quantity"], userProductValidation),
    autoRoundTimeEntries: validations.required,
    onSiteMultiplier: timeEntryMultiplierValidation,
    offHoursMultiplier: timeEntryMultiplierValidation,
    remoteMultiplier: timeEntryMultiplierValidation,
    normalHoursMultiplier: timeEntryMultiplierValidation,
  }),
  [productTypes.CUSTOM_PRODUCT]: () => userProductValidation,
}

export const getFormValidations = ({ productType, editDynamicQuantity }) => {
  return formValidations[productType]?.({ editDynamicQuantity }) || {}
}

const autoRoundTimeEntryValues = {
  OFF: "OFF",
  ROUND_UP_5_MIN: "ROUND_UP_5_MIN",
  ROUND_UP_10_MIN: "ROUND_UP_10_MIN",
  ROUND_UP_15_MIN: "ROUND_UP_15_MIN",
  ROUND_UP_20_MIN: "ROUND_UP_20_MIN",
  ROUND_UP_25_MIN: "ROUND_UP_25_MIN",
  ROUND_UP_30_MIN: "ROUND_UP_30_MIN",
  ROUND_UP_1_HOUR: "ROUND_UP_1_HOUR",
  ROUND_UP_1_DAY: "ROUND_UP_1_DAY",
}

export const autoRoundTimeEntryOptions = [
  { value: autoRoundTimeEntryValues.OFF, labelF: localizedF("Off") },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_5_MIN,
    labelF: localizedF("Round up {{duration}}-minute interval", { duration: 5 }),
  },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_10_MIN,
    labelF: localizedF("Round up {{duration}}-minute interval", { duration: 10 }),
  },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_15_MIN,
    labelF: localizedF("Round up {{duration}}-minute interval", { duration: 15 }),
  },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_20_MIN,
    labelF: localizedF("Round up {{duration}}-minute interval", { duration: 20 }),
  },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_25_MIN,
    labelF: localizedF("Round up {{duration}}-minute interval", { duration: 25 }),
  },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_30_MIN,
    labelF: localizedF("Round up {{duration}}-minute interval", { duration: 30 }),
  },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_1_HOUR,
    labelF: localizedF("Round up {{duration}}-hour interval", { duration: 1 }),
  },
  {
    value: autoRoundTimeEntryValues.ROUND_UP_1_DAY,
    labelF: localizedF("Round up {{duration}}-day interval", { duration: 1 }),
  },
]

export const locationOptions = [
  {
    value: true,
    label: localized("Off Site"),
    labelToken: localizationKey("Off Site"),
  },
  {
    value: false,
    label: localized("On Site"),
    labelToken: localizationKey("On Site"),
  },
]

export const billingTypes = {
  BILLABLE: "BILLABLE",
  NOT_BILLABLE: "NOT_BILLABLE",
  NO_CHARGE: "NO_CHARGE",
}

export const getBillingTypeLabels = () => ({
  [billingTypes.BILLABLE]: localized("Billable"),
  [billingTypes.NOT_BILLABLE]: localized("Not Billable"),
  [billingTypes.NO_CHARGE]: localized("No Charge"),
})

export const agreementOriginTypes = {
  SELF: "SELF", // Time entry has a defined agreementId
  TICKET: "TICKET", // Time entry will inherit the agreementId from the ticket
  NONE: "NONE", // Time entry has no agreementId defined and is not inheriting from the ticket
}

export const getBillingOptions = () => [
  {
    value: billingTypes.BILLABLE,
    labelText: localized("Billable"),
  },
  {
    value: billingTypes.NOT_BILLABLE,
    labelText: localized("Not Billable"),
  },
  {
    value: billingTypes.NO_CHARGE,
    labelText: localized("No Charge"),
  },
]

const licensingTypes = {
  PERPETUAL: "PERPETUAL",
  SUPPORT: "SUPPORT",
  SUBSCRIPTION: "SUBSCRIPTION",
  TERM: "TERM",
}

export const getLicensingOptions = () => [
  {
    value: licensingTypes.PERPETUAL,
    labelText: localized("Perpetual"),
  },
  {
    value: licensingTypes.SUBSCRIPTION,
    labelText: localized("Subscription"),
  },
  {
    value: licensingTypes.SUPPORT,
    labelText: localized("Support"),
  },
  {
    value: licensingTypes.TERM,
    labelText: localized("Term"),
  },
]

const termTypes = {
  ANNUAL: "ANNUAL",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  SEMIANNUAL: "SEMIANNUAL",
}

export const getTermOptions = () => [
  {
    value: termTypes.ANNUAL,
    labelText: localized("Annual"),
  },
  {
    value: termTypes.MONTHLY,
    labelText: localized("Monthly"),
  },
  {
    value: termTypes.QUARTERLY,
    labelText: localized("Quarterly"),
  },
  {
    value: termTypes.SEMIANNUAL,
    labelText: localized("Semiannual"),
  },
]

export const getProductTypeOptions = () => {
  const productTypeMapper = getProductTypeMapper()
  return [
    {
      value: productTypes.CUSTOM_PRODUCT,
      labelText: productTypeMapper[productTypes.CUSTOM_PRODUCT],
    },
    {
      value: productTypes.HARDWARE,
      labelText: productTypeMapper[productTypes.HARDWARE],
    },
    {
      value: productTypes.MANAGED_DEVICES,
      labelText: productTypeMapper[productTypes.MANAGED_DEVICES],
    },
    {
      value: productTypes.LABOR_BILLED,
      labelText: productTypeMapper[productTypes.LABOR_BILLED],
    },
    {
      value: productTypes.LABOR_TICKET_TIME_ENTRY,
      labelText: productTypeMapper[productTypes.LABOR_TICKET_TIME_ENTRY],
    },
    {
      value: productTypes.PRODUCT_GROUP,
      labelText: productTypeMapper[productTypes.PRODUCT_GROUP],
    },
    {
      value: productTypes.SOFTWARE,
      labelText: productTypeMapper[productTypes.SOFTWARE],
    },
    {
      value: productTypes.USER_PRODUCT,
      labelText: productTypeMapper[productTypes.USER_PRODUCT],
    },
  ]
}

export const formActions = {
  [CREATE_PRODUCT.id]: {
    titleToken: {
      [productTypes.USER_PRODUCT]: localizationKey("New user product"),
      [productTypes.SOFTWARE]: localizationKey("New software product"),
      [productTypes.HARDWARE]: localizationKey("New hardware product"),
      [productTypes.PRODUCT_GROUP]: localizationKey("New product group product"),
      [productTypes.MANAGED_DEVICES]: localizationKey("New managed devices product"),
      [productTypes.LABOR_BILLED]: localizationKey("New labor billed product"),
      [productTypes.LABOR_TICKET_TIME_ENTRY]: localizationKey("New labor ticket time entry product"),
      [productTypes.CUSTOM_PRODUCT]: localizationKey("New custom product"),
    },
    descriptionToken: {
      [productTypes.USER_PRODUCT]: localizationKey(
        "Create a user product for tracking user-based charges. This requires manual quantity updates.",
      ),
      [productTypes.SOFTWARE]: localizationKey(
        "Create a software product for tracking software-based charges. This requires manual quantity updates.",
      ),
      [productTypes.HARDWARE]: localizationKey(
        "Create a hardware product for invoicing hardware items. This requires manual quantity updates.",
      ),
      [productTypes.PRODUCT_GROUP]: localizationKey("Create a product group for multiple items in a single bundle."),
      [productTypes.MANAGED_DEVICES]: localizationKey(
        "Create a managed devices product for automated billing of device counts.",
      ),
      [productTypes.LABOR_BILLED]: localizationKey(
        "Create a labor product for tracking fixed labor charges. This requires manual quantity updates.",
      ),
      [productTypes.LABOR_TICKET_TIME_ENTRY]: localizationKey(
        "Create a labor ticket time entry product for automated billing of ticket time entries.",
      ),
      [productTypes.CUSTOM_PRODUCT]: localizationKey(
        "Create a custom product for tracking miscellaneous items. This requires manual quantity updates.",
      ),
    },
    errorToken: localizationKey("Error creating product"),
    successToken: localizationKey("Product created"),
  },
  [EDIT_PRODUCT.id]: {
    titleToken: {
      [productTypes.USER_PRODUCT]: localizationKey("Edit user product"),
      [productTypes.SOFTWARE]: localizationKey("Edit software product"),
      [productTypes.HARDWARE]: localizationKey("Edit hardware product"),
      [productTypes.PRODUCT_GROUP]: localizationKey("Edit product group product"),
      [productTypes.MANAGED_DEVICES]: localizationKey("Edit managed devices product"),
      [productTypes.LABOR_BILLED]: localizationKey("Edit labor billed product"),
      [productTypes.LABOR_TICKET_TIME_ENTRY]: localizationKey("Edit labor ticket time entry product"),
      [productTypes.CUSTOM_PRODUCT]: localizationKey("Edit custom product"),
    },
    descriptionToken: {
      [productTypes.USER_PRODUCT]: localizationKey(
        "Edit a user product for tracking user-based charges. This requires manual quantity updates.",
      ),
      [productTypes.SOFTWARE]: localizationKey(
        "Edit a software product for tracking software-based charges. This requires manual quantity updates.",
      ),
      [productTypes.HARDWARE]: localizationKey(
        "Edit a hardware product for invoicing hardware items. This requires manual quantity updates.",
      ),
      [productTypes.PRODUCT_GROUP]: localizationKey("Edit a product group for multiple items in a single bundle."),
      [productTypes.MANAGED_DEVICES]: localizationKey(
        "Edit a managed devices product for automated billing of device counts.",
      ),
      [productTypes.LABOR_BILLED]: localizationKey(
        "Edit a labor product for tracking fixed labor charges. This requires manual quantity updates.",
      ),
      [productTypes.LABOR_TICKET_TIME_ENTRY]: localizationKey(
        "Edit a labor ticket time entry product for automated billing of ticket time entries.",
      ),
      [productTypes.CUSTOM_PRODUCT]: localizationKey(
        "Edit a custom product for tracking miscellaneous items. This requires manual quantity updates.",
      ),
    },
    errorToken: localizationKey("Error updating product"),
    successToken: localizationKey("Product updated"),
  },
}

const commonFormMapper = product => ({
  name: product?.name ?? "",
  description: product?.description ?? "",
  price: product?.content?.price ?? "",
  cost: product?.content?.cost ?? "",
  taxable: product?.content?.taxable ?? true,
})

const softwareFormMapper = product => ({
  ...commonFormMapper(product),
  quantity: product?.content?.quantity ?? 1,
  licensingType: product?.content?.licensingType ?? licensingTypes.PERPETUAL,
  term: product?.content?.term ?? termTypes.ANNUAL,
  billing: product?.content?.billing ?? billingTypes.BILLABLE,
})

const hardwareFormMapper = product => ({
  ...commonFormMapper(product),
  quantity: product?.content?.quantity ?? 1,
  licensingType: product?.content?.licensingType ?? licensingTypes.PERPETUAL,
  term: product?.content?.term ?? termTypes.ANNUAL,
  billing: product?.content?.billing ?? billingTypes.BILLABLE,
})

const userProductFormValuesMapper = product => ({
  ...commonFormMapper(product),
  billing: product?.content?.billing ?? billingTypes.BILLABLE,
  quantity: product?.content?.quantity ?? 1,
})

export const formValuesMapper = {
  [productTypes.USER_PRODUCT]: userProductFormValuesMapper,
  [productTypes.SOFTWARE]: softwareFormMapper,
  [productTypes.HARDWARE]: hardwareFormMapper,
  [productTypes.PRODUCT_GROUP]: product => ({
    ...commonFormMapper(product),
    billing: product?.content?.billing ?? billingTypes.BILLABLE,
    productIds: product?.content?.productIds ?? [],
  }),
  [productTypes.MANAGED_DEVICES]: product => ({
    ...commonFormMapper(product),
    quantity: product?.content?.quantity ?? 1,
    billing: product?.content?.billing ?? billingTypes.BILLABLE,
    nodeRoleId: product?.content?.nodeRoleId ?? "",
    anyRole: !!product?.content?.anyRole,
    groupIds: product?.content?.groupIds ?? [],
    allGroups: !!product?.content?.allGroups ? managedDevicesGroupOptions.all : managedDevicesGroupOptions.any,
  }),
  [productTypes.LABOR_BILLED]: product => ({
    ...commonFormMapper(product),
    billing: product?.content?.billing ?? billingTypes.BILLABLE,
    quantity: product?.content?.quantity ?? 1,
  }),
  [productTypes.LABOR_TICKET_TIME_ENTRY]: product => ({
    ...commonFormMapper(product),
    quantity: product?.content?.quantity ?? 1,
    billing: product?.content?.billing ?? billingTypes.BILLABLE,
    autoRoundTimeEntries: product?.content?.autoRoundTimeEntries ?? autoRoundTimeEntryValues.OFF,
    userRoles: product?.content?.userRoles ?? [],
    laborCodes: product?.content?.laborCodes ?? [],
    onSiteMultiplier: product?.content?.onSiteMultiplier ?? "",
    offHoursMultiplier: product?.content?.offHoursMultiplier ?? "",
    remoteMultiplier: product?.content?.remoteMultiplier ?? "",
    normalHoursMultiplier: product?.content?.normalHoursMultiplier ?? "",
  }),
  [productTypes.CUSTOM_PRODUCT]: userProductFormValuesMapper,
}
