import qs from "qs"
import { is, cond, always, T, isEmpty, identity } from "ramda"

import {
  fetchJson,
  localizationKey,
  isNinjaPSAEnabledFromSettings,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"

import type {
  AgreementTemplate,
  AgreementTemplateContent,
  AgreementTemplateResponse,
} from "./ninjaPSAAgreementTemplates"

export type BillingCycle = "ON_CONTRACT_START" | "ON_CONTRACT_END" | "ON_EVERY_BILL_DATE"

type AgreementProduct = {
  productId: number,
  name: string,
  description: string,
  type: string,
  price: number,
  cost: number,
  taxable: boolean,
  billing: string,
  billingCycles: BillingCycle[],
}

type BusinessHour = {
  allDayClosed: boolean,
  day: string,
  endHour: number,
  startHour: number,
}

export type AgreementContent = AgreementTemplateContent & {
  startDate: string,
  endDate?: string,
  businessHoursSource: "CUSTOM" | "TICKETING",
  businessHours: {
    days: BusinessHour[],
    twentyFourSeven: boolean,
  },
  dueDays: number,
  dueDaysSource: "CUSTOM" | "INHERIT_FROM_CONFIGURATION",
}

export type Agreement = Omit<AgreementTemplate, "content" | "products"> & {
  content: AgreementContent,
  products: AgreementProduct[],
  adHocProducts: any[],
  multiMatchDevices: boolean,
  nextBillingPeriodStartDate: string,
  billEmails: string[],
  billCcEmails: string[],
  billBccEmails: string[],
  invoiceNote: string,
  allowOnlineCreditCardPayments: boolean,
  allowOnlineAchPayments: boolean,
}

export type AgreementResponseProduct = Pick<AgreementProduct, "name" | "description" | "type"> & {
  id: number,
  content: Pick<AgreementProduct, "price" | "cost" | "taxable" | "billing" | "billingCycles">,
}

export type AgreementResponse = AgreementTemplateResponse &
  Omit<Agreement, "products"> & {
    products: AgreementResponseProduct[],
    lastPeriodEndDate: string,
  }

const BASE_URL = "/psa/agreement"

export function getAgreements({
  clientId,
  active,
}: {
  clientId?: number,
  active?: boolean,
}): Promise<AgreementResponse[]> {
  const _qs = qs.stringify({ clientId, active }, { addQueryPrefix: true })
  return fetchJson(`${BASE_URL}${_qs}`)
}

export function getArchivedAgreements(): Promise<AgreementResponse[]> {
  return fetchJson(`${BASE_URL}/archived`)
}

export function getDefaultAgreementForCustomTech(clientId?: number): Promise<AgreementResponse> {
  const _qs = qs.stringify({ clientId }, { addQueryPrefix: true })
  return fetchJson(`/psa/public/agreement${_qs}`)
}

const agreementResponseTransformer = cond([
  [isEmpty, always([])],
  [is(Array), identity],
  [T, response => [response]],
])

export async function getAgreementsAndCheckPermissions(clientId) {
  if (!clientId || !isNinjaPSAEnabledFromSettings()) {
    return []
  }
  try {
    const agreementResponse = isUserAllowedToUseNinjaPSAAdministrativeActions()
      ? await getAgreements({ clientId, active: true })
      : await getDefaultAgreementForCustomTech(clientId)

    return agreementResponseTransformer(agreementResponse)
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error fetching agreements"))
    return []
  }
}

export function getAgreementById(agreementId: number): Promise<AgreementResponse> {
  return fetchJson(`${BASE_URL}/${agreementId}`)
}

export function createAgreement(data: Agreement): Promise<void> {
  return fetchJson(BASE_URL, {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}

export function updateAgreement(data: Agreement, agreementId: number): Promise<void> {
  return fetchJson(`${BASE_URL}/${agreementId}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(data),
    },
  })
}

export function deactivateAgreement(agreementId: number): Promise<void> {
  return fetchJson(`${BASE_URL}/${agreementId}/deactivate`, { options: { method: "PATCH" } })
}

export function activateAgreement(agreementId: number): Promise<void> {
  return fetchJson(`${BASE_URL}/${agreementId}/activate`, { options: { method: "PATCH" } })
}

export function setDefaultTimeAgreement(agreementId: number) {
  return fetchJson(`${BASE_URL}/${agreementId}/set-time-entry-default`, { options: { method: "POST" } })
}

export function unsetDefaultTimeAgreement(agreementId: number) {
  return fetchJson(`${BASE_URL}/${agreementId}/unset-time-entry-default`, { options: { method: "POST" } })
}

export function getAgreementProduct(agreementProductId: number) {
  return fetchJson(`/psa/agreement-product/${agreementProductId}`)
}
