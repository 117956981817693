import { useEffect, useMemo } from "react"
import qs from "qs"
import { useMountedState } from "js/includes/common/hooks"
import { fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"

export const useUserRoles = (skipRequest = false, queryParams = {}) => {
  const [userRoles, setUserRoles] = useMountedState([])
  const [loadingUserRoles, setLoadingUserRoles] = useMountedState(false)

  const _queryParams = useMemo(() => qs.stringify(queryParams, { addQueryPrefix: true, skipNulls: true }), [
    queryParams,
  ])

  useEffect(() => {
    if (skipRequest) {
      return
    }

    const fetchUserRoles = async () => {
      setLoadingUserRoles(true)
      try {
        const response = await fetchJson(`/user-roles${_queryParams}`)
        return setUserRoles(response)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoadingUserRoles(false)
      }
    }

    fetchUserRoles()
  }, [skipRequest, _queryParams, setLoadingUserRoles, setUserRoles])

  return { userRoles, loadingUserRoles }
}
