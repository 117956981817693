import { find, pipe, prop, propEq } from "ramda"

export const scheduledUpdatedTabs = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  ENFORCED: "ENFORCED",
}

export const updateTypes = {
  APPROVAL: "APPROVAL",
  REJECTION: "REJECTION",
}

export const getIdentifierByVersion = (version, versions) =>
  pipe(find(propEq("version", version)), prop("identifier"))(versions)
