export const monitorConditionControlCode = {
  SelectCondition: 0,
  PingFailed: 1,
  PingPacketLoss: 2,
  PingTTL: 3,
  PingResponseTime: 4,
  PortState: 5,
  PortResponse: 6,
  DnsTimeOut: 7,
  DnsResolve: 8,
  DnsRecordType: 9,
  EmailSSL: 10,
  EmailFailed: 11,
  HttpWebsiteFailed: 12,
  HttpResponseTime: 13,
  HttpCode: 14,
  HttpSiteKeyword: 15,
  SSLCertificateExpiration: 16,
  WhoisExpiration: 17,
}

export const defaultMonitorConditionUids = {
  Ping: "167045f5-c1ae-49aa-8733-6314b62cec4d",
  PortScan: "d4860410-ff4e-4871-8e94-67ba0efae628",
  DNS: "85995e00-0d56-4c51-a120-1d38e8f06aa6",
  Http: "e7c39eea-39cc-4077-b9e4-8c441095f454",
  Email: "e5f6ffa0-fa73-4c46-a7ec-ff4e99c760df",
}

let defaultMonitorConditionUidsReverse = {}
let defaultMonitorConditionNames = {}
let defaultMonitorConditionUidsByCode = {}

//Transform properties for all defaultMonitorConditionUids
for (let key in defaultMonitorConditionUids) {
  if (defaultMonitorConditionUids.hasOwnProperty(key)) {
    defaultMonitorConditionUidsReverse[defaultMonitorConditionUids[key]] = key
    defaultMonitorConditionNames[monitorConditionControlCode[key]] = key
    defaultMonitorConditionUidsByCode[monitorConditionControlCode[key]] = defaultMonitorConditionUids[key]
  }
}

export { defaultMonitorConditionUidsReverse, defaultMonitorConditionNames, defaultMonitorConditionUidsByCode }
