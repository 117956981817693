import { createSelector } from "reselect"
import { hide } from "js/state/actions/common/visibility"
import tableSortSelector from "js/state/selectors/common/tableSort"

export function deleteTarget(taskId, targetType, targetId) {
  return dispatch => {
    const target = { scheduledTaskId: taskId, targetType: targetType, targetId: targetId }
    dispatch({ type: "DELETE_SCHEDULED_TASK_TARGET", target })
  }
}

export function deleteAllTargets() {
  return dispatch => {
    dispatch({ type: "DELETE_SCHEDULED_TASK_ALL_TARGETS" })
  }
}

export function addTargets(targets) {
  return dispatch => {
    dispatch({ type: "ADD_SCHEDULED_TASK_TARGETS", targets })
    dispatch(hide("ScheduledTaskAddTargetsModal")())
  }
}

export const getTargetsMappingList = createSelector(
  createSelector(
    [
      scheduledTasks => scheduledTasks.currentTask.targets,
      scheduledTasks => scheduledTasks.targetTypeSort.sortBy,
      scheduledTasks => scheduledTasks.targetTypeSort.sortDirection,
    ],
    (data, sortBy, sortDirection) => ({ ...{ data, sortBy, sortDirection } }),
  ),
  tableSortSelector,
)

export function initPreselectedTargets(targets) {
  return dispatch => {
    dispatch({ type: "INIT_PRESELECTED_TARGETS", targets })
  }
}

export function addPreselectedTargets() {
  return (dispatch, getState) => {
    const targets = getState().scheduledTasks.preselectedTargets
    if (targets) {
      dispatch({ type: "ADD_SCHEDULED_TASK_TARGETS", targets })
      dispatch({ type: "RESET_PRESELECTED_TARGETS" })
    }
  }
}
