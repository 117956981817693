import moment from "moment"
import { compose, find, propEq, prop, replace, toString } from "ramda"
import {
  localized,
  localizationKey,
  time,
  weekDaysOptions,
  weekNumberOptions,
  getWeekDaysText,
  getMonthText,
  monthOptionsDefault,
} from "js/includes/common/utils"
import { COMMON_FILE_OPTIONS } from "../Sections/Backups/common/constants"

export const approvalValues = {
  APPROVED: "APPROVED",
  DELAYED: "DELAYED",
  REJECTED: "REJECTED",
  MANUAL: "MANUAL",
}

export const approvedValues = [approvalValues.APPROVED, approvalValues.DELAYED]

export const approvalOptions = [
  { value: approvalValues.APPROVED, labelToken: localizationKey("Approve") },
  { value: approvalValues.REJECTED, labelToken: localizationKey("Reject") },
  { value: approvalValues.MANUAL, labelToken: localizationKey("Manual") },
]

export const SCHEDULE_TYPE_NONE = "NONE"
export const SCHEDULE_TYPE_STARTUP = "STARTUP"

export const shouldDisableMakeupMissed = scheduleType => {
  return [SCHEDULE_TYPE_NONE, SCHEDULE_TYPE_STARTUP].includes(scheduleType)
}

export const shouldDisableWakeUpSystem = scheduleType => {
  return scheduleType === SCHEDULE_TYPE_STARTUP
}

export const isOsPatchApproved = value => approvedValues.includes(value)
export const processApproval = value => (isOsPatchApproved(value) ? approvalValues.APPROVED : value)
export const getApprovalToken = value =>
  prop("labelToken", find(propEq("value", processApproval(value)), approvalOptions))

const getWeekDayText = weekDay => weekDaysOptions().find(({ id }) => id === weekDay)?.value

const getWeekNumberText = weekNumber => weekNumberOptions.find(({ value }) => value === weekNumber)?.labelToken

export const getScheduleText = (schedule, isManual = false) => {
  let result = ""
  const {
    scheduleType,
    weekDays,
    weekNumber,
    dayOfMonth,
    scheduleHour,
    scheduleMinute,
    timeZone,
    scheduleDurationHours,
    scheduleDurationMinutes,
    scheduleDuration,
    months = monthOptionsDefault,
    scheduleAfterDays = 0,
  } = schedule
  switch (scheduleType) {
    case "NONE":
      result += `${localized("general.none")}`
      if (scheduleDurationHours) {
        result += `, ${scheduleDurationHours} ${localized("Hour(s)")}`
      }
      if (scheduleDurationMinutes) {
        result += `, ${scheduleDurationMinutes} ${localized("Minute(s)")}`
      }
      if (scheduleDurationHours || scheduleDurationMinutes) {
        result += isManual ? localized(" duration for manual scans") : localized(" duration for manual updates")
      }
      break
    case "WEEKLY":
      result += `${localized("Weekly on")} ${getWeekDaysText(weekDays, ", ")}`
      break
    case "MONTHLY_WEEKNUMBER":
    case "CUSTOM":
      const onMonths = months?.length === 12 ? localizationKey("Every Month") : getMonthText(months, ", ")
      result += localized(
        "Custom: {{onMonths}}. Repeats {{scheduleAfterDays}} day(s) after the {{weekNumberText}} {{weekDayText}}",
        {
          onMonths: localized(onMonths),
          scheduleAfterDays,
          weekNumberText: localized(getWeekNumberText(String(weekNumber))).toLowerCase(),
          weekDayText: getWeekDayText(weekDays[0]),
        },
      )
      break
    case "MONTHLY":
      result +=
        dayOfMonth === 0 ? localized("Monthly on last day of month") : `${localized("Monthly on day")} ${dayOfMonth}`
      break
    case "STARTUP":
      result += localized("On system startup")
      break
    default:
      result += localized("Daily")
      break
  }

  if (scheduleType !== "NONE") {
    if (scheduleType !== "STARTUP") {
      result += ` ${localized("at")} `

      const _time = moment({ hour: scheduleHour, minute: scheduleMinute })

      if (_time) {
        result += time(_time)
      }

      if (timeZone) {
        result += timeZone === "_Local" ? ` ${localized("local device time")}` : ` ${moment.tz(timeZone).format("z")}`
      }
    }

    if (scheduleDurationHours || scheduleDurationMinutes) {
      if (scheduleDurationHours) {
        result += `, ${scheduleDurationHours} ${localized("Hour(s)")} `
      }
      if (scheduleDurationMinutes) {
        result += `, ${scheduleDurationMinutes} ${localized("Minute(s)")} `
      }
      result += ` ${localized("duration")}`
    } else if (scheduleDuration === 0) {
      result += `, ${localized("Until complete")}`
    }
  }

  return result
}

export const getScheduleDuration = ({ scheduleDurationHours, scheduleDurationMinutes }) => {
  const numSeconds =
    getScheduleDurationHours(scheduleDurationHours) * 3600 + getScheduleDurationMinutes(scheduleDurationMinutes) * 60
  return parseInt(numSeconds, 10)
}

export const getScheduleDurationHours = scheduleDurationHours => {
  const scheduleDurationHoursInt = parseInt(scheduleDurationHours, 10)
  return scheduleDurationHoursInt ? scheduleDurationHoursInt : 0
}

export const getScheduleDurationMinutes = scheduleDurationMinutes => {
  const scheduleDurationMinutesInt = parseInt(scheduleDurationMinutes, 10)
  return scheduleDurationMinutesInt ? scheduleDurationMinutesInt : 0
}

export const approvalsConfiguration = {
  sectionName: localizationKey("Approvals"),
  infoMessageOutput: (
    <>
      <p>
        {localized(
          "Critical: Vulnerabilities that can be exploited by an unauthenticated remote attacker or vulnerabilities that break guest/host operating system isolation. The exploitation results in the compromise of confidentiality, integrity, availability, user data, or processing resources without user interaction. Exploitation could be leveraged to propagate an Internet worm or execute arbitrary code between virtual machines and the host.",
        )}
      </p>
      <p>{localized("Unassigned: Security patches that have not been assigned a severity level.")}</p>
    </>
  ),
  severities: [
    { label: localizationKey("Critical"), propName: "securityUpdateCritical" },
    { label: localizationKey("Unassigned"), propName: "securityUpdateUnassigned" },
  ],
}
export const getStringFromSet = set => compose(replace(/[[",\]]/g, ""), toString())(set)

// full list of COMMON_FOLDERS on server side:
// ninjarmm/services/agent-service/agent-service-provider/src/main/java/com/ninjarmm/service/agent/division/CloudberryVersionConfig.java
const commonFoldersLocalizationMapper = {
  allUsersProfile: localizationKey("User Profile"),
  applicationData: localizationKey("AppData (Roaming)"),
  commonAppData: localizationKey("ProgramData"),
  commonDesktop: localizationKey("Public Desktop"),
  commonStartMenuPrograms: localizationKey("Common Start Menu"),
  desktop: localizationKey("Desktop"),
  localAppData: localizationKey("AppData (Local)"),
  sharedAppData: localizationKey("ProgramData"),
  sharedDesktop: localizationKey("Public Desktop"),
  myDesktop: localizationKey("Desktop"),
  myLocalAppData: localizationKey("AppData (Local)"),
  myDocuments: localizationKey("Documents"),
  myDownloads: localizationKey("Downloads"),
  myMusic: localizationKey("Music"),
  myVideos: localizationKey("Videos"),
  myMovies: localizationKey("Movies"),
  myPictures: localizationKey("Pictures"),
  programFiles: localizationKey("Program Files"),
  programFilesTooltip: localizationKey("Program Files Tooltip"),
  publicProfile: localizationKey("Public Profile"),
  sharedDocuments: localizationKey("Public Documents"),
  sharedDownloads: localizationKey("Public Downloads"),
  sharedMusic: localizationKey("Public Music"),
  sharedVideos: localizationKey("Public Videos"),
  sharedPictures: localizationKey("Public Pictures"),
  sharedProfile: localizationKey("Public Profile"),
  sharedUsersProfile: localizationKey("Public Profile"),
  startMenuPrograms: localizationKey("Common Start Menu"),
  systemDirectory: localizationKey("System Directory"),
  temp: localizationKey("Temp"),
  userProfile: localizationKey("User Profile"),
  windowsDirectory: localizationKey("Windows Directory"),
}

export const getLocalizedCommonFolderName = (folder, isMacNodeRole) => {
  if (folder === COMMON_FILE_OPTIONS.MY_VIDEOS && isMacNodeRole) return commonFoldersLocalizationMapper.myMovies

  return localized(commonFoldersLocalizationMapper[folder] ?? folder)
}
