import { batch } from "react-redux"
import { fetch, fetchJson, showErrorMessage, localized } from "js/includes/common/utils"
import { updateNewCriteria, requestSaveCriteria } from "./criteria"
import { setActiveSearch } from "./activeSearch"
import { setSearchForEdit } from "./searchForEdit"
import { beginDeleteSearch } from "./deletingSearch"
import {
  getCriterionForRequest,
  getCriteriaFromResponse,
  getCriteriaNotDeleted,
} from "js/state/selectors/deviceSearch/criteria"
import { removeCriteriaBySearch } from "js/state/actions/deviceSearch/criteria"
import { removeRecentTab } from "js/state/actions/general/recentTabs"
import { removeFavoriteTab } from "js/state/actions/general/favoriteTabs"
import { generateDeviceSearchGroupKey } from "./deviceSearchGroupKey"

export const createGroup = filter => ({
  type: "CREATE_GROUP",
  filter,
})

export const updateNewGroup = filter => ({
  type: "UPDATE_NEW_GROUP",
  filter,
})

export const setGroups = searches => ({
  type: "SET_GROUPS",
  searches,
})

const updateGroup = filter => ({
  type: "UPDATE_GROUP",
  filter,
})

export const deleteGroup = filter => ({
  type: "DELETE_GROUP",
  filter,
})

export const cancelGroupChanges = ({ previousCriteria, previousGroup }) => dispatch => {
  batch(() => {
    dispatch({ type: "SET_FULL_CRITERIA", criteria: previousCriteria })
    dispatch(handleUpdateGroup(previousGroup))
    dispatch(generateDeviceSearchGroupKey())
  })
}

const requestCreateGroup = (search, criteria) => async dispatch => {
  const request = { search, criteria }

  try {
    const { search: responseSearch, criteria: responseCriteria } = await fetchJson("/searches", {
      options: {
        method: "POST",
        body: JSON.stringify(request),
      },
    })

    const criteriaForUpdate = getCriteriaFromResponse(responseCriteria)
    batch(() => {
      dispatch(updateNewCriteria(criteriaForUpdate))
      dispatch(createGroup(responseSearch))
      dispatch(setSearchForEdit(responseSearch))
      dispatch(setActiveSearch(responseSearch))
    })

    if (search.id !== responseSearch.id && responseSearch.id > 0) {
      window.location.hash = `#/group/${responseSearch.id}`
    }
  } catch (error) {
    showErrorMessage(localized("The requested search failed to save"))
    throw error
  }
}

export const handleUpdateGroup = updatedGroup => dispatch => {
  dispatch(updateGroup(updatedGroup))
}

const requestUpdateGroup = search => async dispatch => {
  try {
    const response = await fetchJson(`/searches/${search.id}`, {
      options: {
        method: "PUT",
        body: JSON.stringify(search),
      },
    })

    dispatch(updateGroup(response))
    dispatch(setActiveSearch(response))
  } catch (error) {
    showErrorMessage(localized("The requested search failed to save"))
    throw error
  }
}

export const requestSaveGroup = (group, criteria) => async dispatch => {
  if (group.id > 0) {
    await Promise.all([
      dispatch(requestUpdateGroup(group)),
      ...(criteria ? [dispatch(requestSaveCriteria(criteria))] : []),
    ])
  } else {
    const criteriaRequest = getCriteriaNotDeleted(criteria).map(getCriterionForRequest)
    await dispatch(requestCreateGroup(group, criteriaRequest))
  }
}

export const requestDeleteGroup = request => async dispatch => {
  try {
    dispatch(beginDeleteSearch(request))
    await fetch(`/searches/${request.id}`, {
      options: {
        method: "DELETE",
      },
    })
    dispatch(deleteGroup(request))
    dispatch(removeCriteriaBySearch(request.id))
    dispatch(removeRecentTab({ id: request.id, type: "GROUP" }))
    dispatch(removeFavoriteTab({ id: request.id, type: "GROUP" }))
  } catch (error) {
    throw error
  }
}
