import { memo } from "react"
import tokens from "@ninjaone/tokens"
import { Select } from "@ninjaone/components"
import { Box } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"
import withInheritance from "js/includes/components/withInheritance"
import { updateCadenceOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/formCommons.js"

const SelfInstallSelector = memo(({ inheritableItem: recommendedCadenceSettings, onChange }) => {
  const { recommendedCadence = "ALL" } = recommendedCadenceSettings

  return (
    <Box marginBottom={tokens.spacing[4]}>
      <Select
        labelId="self-install-selector"
        labelText={localized("Type of updates that can be self-installed (iOS 18+)")}
        value={recommendedCadence}
        onChange={cadence => onChange({ ...recommendedCadenceSettings, recommendedCadence: cadence })}
        options={updateCadenceOptions}
      />
    </Box>
  )
})

export default withInheritance(SelfInstallSelector)
