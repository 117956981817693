import React, { memo } from "react"

export default memo(({ title, onClickTitle, SubtitleComponent }) => {
  return (
    <div className="header black">
      <div>
        <div
          className="editor-name has-description pointer"
          onClick={() => {
            onClickTitle && onClickTitle()
          }}
        >
          {title}
        </div>
        <div>{SubtitleComponent && <SubtitleComponent />}</div>
      </div>
    </div>
  )
})