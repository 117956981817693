const architectures = [
  { id: "64", value: "64 Bit" },
  { id: "32", value: "32 Bit" },
]

export const languages = [
  {
    id: "batchfile",
    value: "Batch",
    content: "REM Batch file",
    operatingSystems: ["Windows"],
    automationType: "ScriptAutomation",
    icon: { name: "ScriptAutomationIcon" },
  },
  {
    id: "binary_install",
    value: "Install Application",
    content: "",
    operatingSystems: ["Windows", "Mac"],
    automationType: "BinaryInstallAutomation",
    icon: { name: "InstallApplicationAutomationIcon" },
  },
  {
    id: "javascript",
    value: "JavaScript",
    content: "// JavaScript",
    operatingSystems: ["Windows"],
    automationType: "ScriptAutomation",
    icon: { name: "ScriptAutomationIcon" },
  },
  {
    id: "powershell",
    value: "PowerShell",
    content: "# PowerShell",
    operatingSystems: ["Windows"],
    automationType: "ScriptAutomation",
    icon: { name: "ScriptAutomationIcon" },
  },
  {
    id: "native",
    value: "Native",
    os: ["Windows"],
    automationType: "NativeAutomation",
    icon: { name: "NativeAutomationIcon" },
  },
  {
    id: "binary_run",
    value: "Run Application",
    content: "",
    operatingSystems: ["Windows", "Mac"],
    automationType: "BinaryRunAutomation",
    icon: { name: "RunApplicationAutomationIcon" },
  },
  {
    id: "sh",
    value: "ShellScript",
    content: "# ShellScript",
    operatingSystems: ["Mac", "Linux"],
    automationType: "ScriptAutomation",
    icon: { name: "ScriptAutomationIcon" },
  },
  {
    id: "vbscript",
    value: "VBScript",
    content: "' VBScript",
    operatingSystems: ["Windows"],
    automationType: "ScriptAutomation",
    icon: { name: "ScriptAutomationIcon" },
  },
]

export const operatingSystems = [
  { id: "Windows", value: "Windows" },
  { id: "Mac", value: "Mac" },
  { id: "Linux", value: "Linux" },
]

export const automationTypes = [
  { id: "NativeAutomation", labelToken: "Native", icon: { name: "NativeAutomationIcon" } },
  {
    id: "BinaryInstallAutomation",
    labelToken: "Install Application",
    icon: { name: "InstallApplicationAutomationIcon" },
  },
  { id: "BinaryRunAutomation", labelToken: "Run Application", icon: { name: "RunApplicationAutomationIcon" } },
  { id: "ScriptAutomation", labelToken: "Scripts", icon: { name: "ScriptAutomationIcon" } },
]

const scripting = {
  architectures,
  languages,
  operatingSystems,
  automationTypes,
}

export default scripting
