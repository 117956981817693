/** @jsxImportSource @emotion/react */
import { PureComponent } from "react"
import PropTypes from "prop-types"
import { localized } from "js/includes/common/utils"
import { css } from "@emotion/react"

const inheritanceStatusStyle = css`
  flex: 0.1;
  display: flex;
  justify-content: flex-end;
  div,
  span {
    flex: 1;
  }
  margin: 0 0 0 5px;
  &.empty {
    visibility: hidden;
  }
`

export default class InheritanceBox extends PureComponent {
  static propTypes = {
    overridden: PropTypes.bool.isRequired,
    revert: PropTypes.func.isRequired,
  }

  renderOverriden() {
    const { revert } = this.props
    return (
      <div>
        <span className="overridden">{localized("OVERRIDDEN")}</span>
        <span className="revert" onClick={revert}>
          {localized("REVERT")}
        </span>
      </div>
    )
  }

  renderInherited() {
    return <span className="inherited">{localized("INHERITED")}</span>
  }

  render() {
    const { overridden, className } = this.props

    return (
      <div className={`inheritance-status ${className}`} css={inheritanceStatusStyle}>
        {overridden ? this.renderOverriden() : this.renderInherited()}
      </div>
    )
  }
}
