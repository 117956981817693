import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import { requestGetUpdatedDevicesAndRefreshSearchResults, refreshDevices } from "js/state/actions/deviceSearch/search"
import { singlePatchOverrideCall } from "js/state/helpers/patches"
import {
  fetchJson,
  showErrorMessage,
  reportErrorAndShowMessage,
  ninjaReportError,
  localized,
  localizationKey,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { NinjaResponseError } from "js/includes/common/types"
import { fetchCustomerDashboard } from "js/state/actions/dashboard/customerOverview"
import { runDeviceSearchToolbarAction } from "js/includes/common/utils/devices"

export const triggerJob = async ({ node, jobUid, sourceUid, identifier, allowOffline, content = null }) => {
  const { id } = node

  try {
    if (!node.isUp && !allowOffline) {
      return ShowMessageDialog({
        icon: { icon: faInfoCircle, type: "warning" },
        title: () => `${node.name || node.friendlyName || node.systemName} ${localized("is offline")}`,
        message: localizationKey(
          "You can't connect or send commands to an offline device. This action requires the device to be online.",
        ),
        buttons: [{ id: "OK", label: localizationKey("OK") }],
      })
    }

    const body = JSON.stringify({
      uid: jobUid,
      sourceUid,
      identifier,
      content,
    })

    const response = await fetchJson(`/node/${id}/action`, {
      options: {
        method: "POST",
        body,
      },
    })

    if (!response) return showErrorMessage(localized("Error creating/updating jobs"))
    refreshDeviceHealthWarnings(id)
    window.store.dispatch(requestGetUpdatedDevicesAndRefreshSearchResults(id))
    refreshCustomerOverview()
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error creating/updating jobs"))
  }
}

export const patchAction = async (entity, node, patchId, patchName, action, patchType, kbNumber, type) => {
  try {
    const id = entity === "policy" ? node.policyId : node.id
    const response = await singlePatchOverrideCall(entity, id, patchId, patchName, action, patchType, kbNumber, type)
    if (response.resultCode === "SUCCESS") {
      refreshDeviceHealthWarnings(node.id)
      window.store.dispatch(requestGetUpdatedDevicesAndRefreshSearchResults(node.id))
      refreshCustomerOverview()
    } else {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    if (error?.response?.status === 409 && error?.resultCode) {
      showErrorMessage(error.resultCode)
      ninjaReportError(error)
    } else {
      reportErrorAndShowMessage(error)
    }
  }
}

export const refreshDeviceHealthWarnings = nodeId => {
  const activeTab = window.controller.get("activeTab")?.attributes
  if (activeTab?.name === "deviceDashboard" && activeTab?.subTab?.attributes?.type === "DeviceOverviewTabModel") {
    const subTab = activeTab.subTab
    subTab.renderDeviceHealth(nodeId)
  }
}

export const refreshCustomerOverview = async () => {
  if (window.controller.has("activeTab") && window.controller.get("activeTab").get("name") === "customerDashboard") {
    const tab = window.controller.get("activeTab")
    await fetchCustomerDashboard(tab.get("id"), tab.get("subTab").get("location"))
  }
}

export const resetAlerts = async (
  selectedFilteredDevicesIds,
  setPerformingDeviceAction,
  clearAllItems,
  updateDeviceDetails,
  fromDeviceSearch = false,
) => {
  const buttonPressed = await ShowMessageDialog({
    icon: { icon: faExclamation, type: "warning" },
    title: localizationKey("Reset Alerts?"),
    message: () =>
      `${localized("Are you sure you want to reset alerts for")} ${selectedFilteredDevicesIds.length} ${localized(
        "device(s)?",
      )}`,
    buttons: [
      { id: "YES", label: localizationKey("Yes") },
      { id: "NO", label: localizationKey("No") },
    ],
  })

  if (buttonPressed === "YES") {
    setPerformingDeviceAction("Resetting Alerts")
    try {
      if (fromDeviceSearch) {
        await runDeviceSearchToolbarAction({
          action: "RUN_RESET_ALERTS",
        })
        window.store.dispatch(refreshDevices())
      } else {
        const response = await fetchJson("/webapp/resetconditions", {
          options: {
            method: "POST",
            body: JSON.stringify({ nodeIdList: selectedFilteredDevicesIds }),
          },
        })
        if (response.resultCode !== "SUCCESS") {
          throw new NinjaResponseError(response)
        }

        const { nodes } = await window.deviceList.fetchByIds(selectedFilteredDevicesIds)
        updateDeviceDetails(nodes)
        selectedFilteredDevicesIds.forEach(id => refreshDeviceHealthWarnings(id))
      }
    } catch (error) {
      const { isDevicesCacheExpiredError } = error
      if (!isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("Error resetting device alerts"))
      }
    } finally {
      clearAllItems()
      setPerformingDeviceAction(null)
    }
  }
}
