import { is } from "ramda"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

export const yesNo = value => (value ? localized("Yes") : localized("No"))

export const trueFalseStringToBool = value => {
  if (value === "true") {
    return true
  } else if (value === "false") {
    return false
  }
  throw new Error(`'${value}' must be literal 'true' or 'false'`)
}

export const isBoolean = is(Boolean)
