import { compose, prop, sortBy, toLower, filter, map, includes } from "ramda"
import qs from "qs"
import {
  fetchJson,
  reportErrorAndShowMessage,
  localizationKey,
  isMobileDevice,
  isFeatureEnabled,
} from "js/includes/common/utils"

export const fetchPolicyList = async () => {
  try {
    const policyList = await fetchJson("/policy/list")
    return sortBy(compose(toLower, prop("name")))(policyList)
  } catch (error) {
    reportErrorAndShowMessage(error)
    return []
  }
}
export const fetchPolicyListByNodeClassGroup = async nodeClassGroup => {
  try {
    const policyList = await fetchJson(`/policy/list?${qs.stringify({ nodeClassGroup })}`)
    return sortBy(compose(toLower, prop("name")))(policyList)
  } catch (error) {
    reportErrorAndShowMessage(error)
    return []
  }
}

export const getPolicyListByIds = policyIds => {
  const query = qs.stringify(
    {
      ids: policyIds,
      ...(policyIds?.length && { pageSize: policyIds.length }),
    },
    { encode: false, indices: false },
  )
  try {
    return fetchJson(`/query/policies?${query}`)
  } catch (error) {
    reportErrorAndShowMessage(error)
    return []
  }
}

export const getParentPolicyContent = policyId => fetchJson(`/policy/${policyId}/parent-content`)

export const deletePolicy = id =>
  fetchJson(`/policy/${id}`, {
    options: {
      method: "DELETE",
    },
  })

export const getBackupPolicyWarnings = async ({ nodeId, policyFrom, policyTo }) => {
  try {
    if (!nodeId || !policyFrom || !policyTo) {
      throw new Error("node id and policy ids are required.")
    }
    const result = await fetchJson(
      `/backup/lockhart/policy/nodes/${nodeId}/warnings/policy-change?policyFrom=${policyFrom}&policyTo=${policyTo}`,
    )
    const warnings = result?.warnings || []

    return {
      hasLegacyWarning: includes("NON_ARROW_IMAGE", warnings),
      hasArrowImageWarning: includes("ARROW_IMAGE", warnings),
    }
  } catch (error) {
    reportErrorAndShowMessage(error, localizationKey("Error getting policy change information"))
    return { hasLegacyWarning: false, hasArrowImageWarning: false }
  }
}

export const getDevicesWithWarnings = async (devices, policyTo) => {
  const results = await Promise.all(
    map(async device => {
      if (isMobileDevice(device?.nodeClass) || !isFeatureEnabled("backup_policy_override_warning")) return null
      const { hasLegacyWarning = false, hasArrowImageWarning = false } = await getBackupPolicyWarnings({
        nodeId: device?.id,
        policyFrom: device?.policyId,
        policyTo: policyTo?.id,
      })

      if (!hasLegacyWarning && !hasArrowImageWarning) return null

      return {
        name: device.name,
        hasLegacyWarning,
        hasArrowImageWarning,
      }
    }, devices),
  )

  const validResults = filter(Boolean, results)

  const { devicesWithLegacyWarning, devicesWithArrowImageWarning } = validResults.reduce(
    (acc, device) => {
      if (device.hasLegacyWarning) {
        acc.devicesWithLegacyWarning.push(device.name)
      }
      if (device.hasArrowImageWarning) {
        acc.devicesWithArrowImageWarning.push(device.name)
      }
      return acc
    },
    { devicesWithLegacyWarning: [], devicesWithArrowImageWarning: [] },
  )

  return { devicesWithLegacyWarning, devicesWithArrowImageWarning }
}

export const fetchWindowsProcesses = () => fetchJson("/webapp/winprocess")
export const fetchWindowsServices = () => fetchJson("/webapp/winservice")

export const fetchMacProcesses = () => fetchJson("/webapp/macprocess")
export const fetchMacDaemons = () => fetchJson("/webapp/macdaemon")

export const fetchLinuxProcesses = () => fetchJson("/webapp/linuxprocess")
export const fetchLinuxDaemons = () => fetchJson("/webapp/linuxdaemon")
