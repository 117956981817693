import { find, isNil, propEq } from "ramda"
import { date, dateTime, isNilOrEmpty, localizationKey, localized, localizedF, yesNo } from "js/includes/common/utils"
import { EllipsisWithTooltip } from "js/includes/components/EllipsisWithTooltip"
import Tooltip from "js/includes/components/Tooltip"

export const DROPDOWN = "DROPDOWN"
export const MULTI_SELECT = "MULTI_SELECT"
export const CHECKBOX = "CHECKBOX"
export const TEXT = "TEXT"
export const TEXT_MULTILINE = "TEXT_MULTILINE"
export const NUMERIC = "NUMERIC"
export const DECIMAL = "DECIMAL"
export const DATE = "DATE"

export const attributeTypes = [
  { value: DROPDOWN, labelToken: localizationKey("Drop-down") },
  { value: MULTI_SELECT, labelToken: localizationKey("Multi-select") },
  { value: CHECKBOX, labelToken: localizationKey("Checkbox") },
  { value: TEXT, labelToken: localizationKey("Text") },
  { value: TEXT_MULTILINE, labelToken: localizationKey("Multi-line") },
  { value: NUMERIC, labelToken: localizationKey("Integer") },
  { value: DECIMAL, labelToken: localizationKey("Decimal") },
  { value: DATE, labelToken: localizationKey("Date") },
]

export const FOR_END_USER_IS_HIDDEN_VALUE = "HIDDEN"

export const attributeEndUserOptionTypes = [
  { value: FOR_END_USER_IS_HIDDEN_VALUE, label: localizedF("Hidden") },
  { value: "EDITABLE_OPTIONAL", label: localizedF("Optional") },
  { value: "EDITABLE_REQUIRED", label: localizedF("Required") },
  { value: "READ_ONLY", label: localizedF("Read Only") },
]

export const FOR_TECH_IS_OPTIONAL_VALUE = "OPTIONAL"

export const attributeTechnicianOptionTypes = [
  { value: FOR_TECH_IS_OPTIONAL_VALUE, label: localizedF("Optional") },
  { value: "REQUIRED_TO_CREATE", label: localizedF("Required to Create") },
  { value: "REQUIRED_TO_RESOLVE", label: localizedF("Required to Resolve") },
]

export function getAttributeTypeLabel(type) {
  const token = find(propEq("value", type), attributeTypes)?.labelToken
  return token ? localized(token) : token
}

export function getAttributeEndUserOptionLabel(endUserOption) {
  return find(propEq("value", endUserOption), attributeEndUserOptionTypes).label()
}

export function getAttributeTechnicianOptionLabel(technicianOption) {
  return find(propEq("value", technicianOption), attributeTechnicianOptionTypes).label()
}

export const noDataValue = "-"

export const renderCellData = ({ cellData }) => <EllipsisWithTooltip text={cellData} />
export const renderDateCellData = ({ cellData }) => <EllipsisWithTooltip text={date(cellData)} />
export const renderOptionalCellData = ({ cellData }) =>
  isNilOrEmpty(cellData) ? noDataValue : <EllipsisWithTooltip text={cellData} />
export const renderOptionalDateCellData = ({ cellData }) =>
  isNil(cellData) ? noDataValue : <EllipsisWithTooltip text={date(cellData)} />
export const renderOptionalDateTimeCellData = ({ cellData }) =>
  isNil(cellData) ? noDataValue : <EllipsisWithTooltip text={dateTime(cellData)} />

const commonRendered = ({ value }) => renderOptionalCellData({ cellData: value })

export const attributeValueRenderer = {
  CHECKBOX: ({ value }) => (isNil(value) ? noDataValue : <EllipsisWithTooltip text={yesNo(value)} />),
  DATE: ({ value }) => renderOptionalDateCellData({ cellData: value }),
  NUMERIC: commonRendered,
  DECIMAL: commonRendered,
  DROPDOWN: commonRendered,
  MULTI_SELECT: commonRendered,
  TEXT: commonRendered,
  TEXT_MULTILINE: ({ value }) => {
    if (isNil(value)) {
      return noDataValue
    }

    const lines = value.split(/\n|\r/)

    return (
      <Tooltip output={value}>
        <div className="full-width">
          <div className="text-ellipsis">{lines[0]}</div>
          {lines.length > 1 && <div className="text-ellipsis">{lines[1]}</div>}
        </div>
      </Tooltip>
    )
  },
}
