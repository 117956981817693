import { isNil, not, pipe, prop } from "ramda"

/**
 * Retrieves a setting value by key from the given settings object.
 * If the key is not defined, it returns `true` by default.
 * Valid for AllowRsrCheckbox, NotificationUpdateCheckbox and RemoveRsrCheckbox components
 *
 * @param {Object} settings - The settings object containing key-value pairs.
 * @param {string} key - The key whose value is to be retrieved from the settings.
 * @returns {boolean} - The boolean associated with the key, or `true` if the key is not defined.
 */
export const getSettings = (settings, key) => {
  const isPropertyDefined = pipe(prop(key), isNil, not)(settings)
  return isPropertyDefined ? settings[key] : true
}
