import { evolve, mergeLeft, trim } from "ramda"
import { Checkbox, Input, Modal, Select } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import { localizationKey, localized, validations, getValidationSuccess } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { useForm } from "js/includes/common/hooks"
import { createWiFiNetworkPolicy } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

export const proxyOptions = () => [
  { value: "AUTO", labelToken: localizationKey("Auto") },
  { value: "MANUAL", labelToken: localizationKey("Manual") },
  { value: "NONE", labelToken: localizationKey("None") },
]

const MAX_PORT_NUMBER = 65535

const trimNames = evolve({
  configName: trim,
  ssid: trim,
})

export default function NetworkModal({ unmount, network, updatePolicyItem, encryptionTypeOptions }) {
  const { values, validation, onChange, validateForm } = useForm({
    fields: {
      configName: network?.configName || "",
      ssid: network?.ssid || "",
      encryptionType: network?.encryptionType || "ANY",
      password: network?.password || "",
      autoJoin: network?.autoJoin ?? true,
      randomMacAddress: network?.randomMacAddress ?? false,
      enableIPv6: network?.enableIPv6 ?? true,
      hidden: network?.hidden ?? false,
      isHotspot: network?.isHotspot ?? false,
      proxy: {
        type: network?.proxy?.type || "NONE",
        proxyPACFallbackAllowed: network?.proxy?.proxyPACFallbackAllowed ?? false,
        pacUrl: network?.proxy?.pacUrl || "",
        server: network?.proxy?.server || "",
        port: network?.proxy?.port || "",
        username: network?.proxy?.username || "",
        password: network?.proxy?.password || "",
      },
    },
    validate: {
      configName: validations.required,
      ssid: validations.required,
      password: (val, values) =>
        values.encryptionType !== "NONE" ? validations.required(val) : getValidationSuccess(),
      proxy: {
        port: (val, values) =>
          values.proxy.type !== "MANUAL"
            ? getValidationSuccess()
            : validations.isValidIntegerWithinRange(0, MAX_PORT_NUMBER, parseInt(val, 10)),
      },
    },
  })

  const handleSaveNetwork = () => {
    if (!validateForm()) return
    const newNetwork = trimNames(values)
    createWiFiNetworkPolicy(mergeLeft(newNetwork, network), updatePolicyItem)
    unmount()
  }

  const CheckboxItem = ({ value, labelToken, changeValue }) => {
    return (
      <Checkbox
        data-testid={`network-${changeValue}`}
        label={localized(labelToken)}
        checked={value}
        onChange={() => onChange(changeValue, !value)}
      />
    )
  }

  return (
    <Modal
      scrollable
      titleGroup={{
        titleToken: localizationKey("Wi-Fi Network"),
      }}
      unmount={unmount}
      buttons={[
        {
          type: "save",
          onClick: handleSaveNetwork,
          labelToken: network.guid ? localized("Update") : localized("Add"),
        },
      ]}
    >
      <Flex flexDirection="column" gap={spacing[3]} marginBottom={spacing[4]}>
        <Input
          data-testid="network-configName"
          labelToken={localizationKey("Configuration name")}
          value={values.configName}
          onChange={e => onChange("configName", e.target.value)}
          errorMessage={validation.message.configName}
          required
        />
        <Input
          data-testid="network-ssid"
          labelText={localized("Wi-Fi name (SSID)")}
          tooltipText={localized("Please note that Wi-Fi names are case-sensitive")}
          value={values.ssid}
          onChange={e => onChange("ssid", e.target.value)}
          errorMessage={validation.message.ssid}
          required
        />
        <Select
          {...{
            labelId: "networkSecurytyLabel",
            labelToken: localizationKey("Security"),
            options: encryptionTypeOptions,
            value: values.encryptionType,
            defaultValue: values.encryptionType,
            onChange: e => onChange("encryptionType", e),
            alignRight: false,
          }}
        />
        {values.encryptionType !== "NONE" && (
          <Input
            data-testid="network-password"
            labelToken={localizationKey("Password")}
            value={values.password}
            type="password"
            onChange={e => onChange("password", e.target.value)}
            errorMessage={validation.message.password}
            isSecureInput
            required
          />
        )}
        <CheckboxItem changeValue="autoJoin" labelToken={localizationKey("Auto join")} value={values.autoJoin} />
        <CheckboxItem changeValue="enableIPv6" labelToken={localizationKey("Enable IPv6")} value={values.enableIPv6} />
        <CheckboxItem changeValue="hidden" labelToken={localizationKey("Hidden network")} value={values.hidden} />
        <CheckboxItem changeValue="isHotspot" labelToken={localizationKey("Hotspot")} value={values.isHotspot} />
        <CheckboxItem
          changeValue="randomMacAddress"
          labelToken={localizationKey("Random MAC address")}
          value={values.randomMacAddress}
        />
        <Box
          paddingTop={spacing[3]}
          marginBottom={spacing[3]}
          borderTopWidth="1px"
          borderTopStyle="solid"
          borderTopColor="ninjaLight"
        >
          <Select
            {...{
              labelId: "networkProxyLabel",
              labelToken: localizationKey("Proxy"),
              options: proxyOptions(),
              value: values.proxy.type,
              defaultValue: values.proxy.type,
              onChange: e => onChange("proxy.type", e),
              alignRight: false,
            }}
          />
        </Box>
        {values.proxy.type === "AUTO" && (
          <>
            <Input
              data-testid="network-proxy-pacUrl"
              labelToken={localizationKey("Proxy auto-configuration (PAC) URI")}
              value={values.proxy.pacUrl}
              onChange={e => onChange("proxy.pacUrl", e.target.value)}
            />
            <CheckboxItem
              changeValue="proxy.proxyPACFallbackAllowed"
              labelToken={localizationKey("Proxy fallback allowed")}
              value={values.proxy.proxyPACFallbackAllowed}
            />
          </>
        )}

        {values.proxy.type === "MANUAL" && (
          <>
            <Input
              data-testid="network-proxy-server"
              labelToken={localizationKey("Host")}
              value={values.proxy.server}
              onChange={e => onChange("proxy.server", e.target.value)}
            />
            <Input
              data-testid="network-proxy-port"
              labelToken={localizationKey("Port")}
              value={values.proxy.port}
              onChange={e => onChange("proxy.port", e.target.value)}
              errorMessage={validation.message.proxy?.port}
            />
            <Input
              data-testid="network-proxy-username"
              labelToken={localizationKey("Username")}
              value={values.proxy.username}
              onChange={e => onChange("proxy.username", e.target.value)}
            />
            <Input
              data-testid="network-proxy-password"
              labelToken={localizationKey("Password")}
              value={values.proxy.password}
              type="password"
              onChange={e => onChange("proxy.password", e.target.value)}
            />
          </>
        )}
      </Flex>
    </Modal>
  )
}
