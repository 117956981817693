import { nanoid } from "nanoid"
import { localized, localizationKey, dateTime } from "js/includes/common/utils"

export const getNodeSourceFormatted = ({ source, discoveryJob }) => {
  if (!source || source === "MANUAL_INSTALL") {
    return localized("Manual Install")
  }
  const discoveryJobName = discoveryJob?.runOnce
    ? `${localized("Discovery Job")} ${dateTime(discoveryJob?.createTime)}`
    : discoveryJob?.name
  const djToken = source === "AD_HOC" ? localizationKey("Adhoc Discovery") : localizationKey("Scheduled Discovery")
  return `${localized(djToken)} - ${discoveryJobName}`
}

export const setNodesUids = nodes =>
  nodes?.map(node =>
    node.uid
      ? node
      : {
          ...node,
          uid: nanoid(10),
        },
  )
