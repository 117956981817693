import { curry } from "ramda"
import { fetchJson, ninjaReportError, user, reportErrorAndShowMessage } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const getDevices = curry((status, clientId) =>
  fetchJson(
    `/node/approval/${clientId}/${status}${user("canAccessActiveDirectoryDiscovery") ? "?includeCandidates=true" : ""}`,
  ),
)

export const getPendingDevices = getDevices("pending")

export const getApprovedDevices = getDevices("approved")

export const getRejectedDevices = getDevices("rejected")

export const validateDCsWithPendingCandidateNodes = async nodeIds => {
  if (user("canAccessActiveDirectoryDiscovery")) {
    try {
      const nodesWithPendingCandidateNodes = await fetchJson(
        "/node/domain-controllers/has-pending-candidate-nodes-or-discovery-jobs",
        {
          options: {
            method: "POST",
            body: JSON.stringify(nodeIds),
          },
        },
      )
      return nodesWithPendingCandidateNodes
    } catch (error) {
      ninjaReportError(error)
    }
  }
  return []
}

export const hasDCPendingCandidateNodes = async nodeId => {
  const response = await validateDCsWithPendingCandidateNodes([nodeId])
  return !!response.length
}

export async function rejectNodes({ nodes, candidateNodes, deleteLockhartDataAction, mdmNodes }) {
  const body = JSON.stringify({
    action: "REJECT",
    nodes,
    candidateNodes,
    deleteLockhartDataAction,
    mdmNodes,
  })
  try {
    const { candidateNodes: rejectedCandidateNodesIds = [], processedNodes } = await fetchJson("/node/approval", {
      options: {
        method: "POST",
        body,
      },
    })
    return { success: true, rejectedCandidateNodesIds, processedNodes }
  } catch (error) {
    if (error?.resultCode === "NODES_WITH_LOCKHART_DATA") {
      return { success: false, resultCode: error.resultCode }
    } else {
      reportErrorAndShowMessage(error, localizationKey("Error rejecting device"))
      return { success: false, resultCode: error?.resultCode }
    }
  }
}
