import { cond, isNil, always, equals, T, map, applySpec, prop } from "ramda"
import { localized } from "js/includes/common/utils/ssrAndWebUtils"

export const unassignedTicketsBoardId = 1
export const allTicketsBoardId = 2
export const myTicketsBoardId = 3
export const openTicketsBoardId = 4
export const deletedTicketsBoardId = 5
export const organizationsBoardId = 6
export const devicesBoardId = 7

export const unassignedTicketsBoardUrl = `/ticketing/board/${unassignedTicketsBoardId}`

export const getTimersValueLabel = value =>
  cond([
    [isNil, always(localized("Disabled"))],
    [equals("MANUAL"), always(`${localized("Enabled")}; ${localized("Manual")}`)],
    [equals("AUTO_START"), always(`${localized("Enabled")}; ${localized("Auto Start")}`)],
    [T, always(localized("Unknown"))],
  ])(value)

export const getHelpRequestLabel = (autoCreate, isGlobal) => {
  return autoCreate
    ? `${localized("Yes")}; ${isGlobal ? localized("Global") : localized("Organization")}`
    : localized("No")
}

export const getTagValues = map(
  applySpec({
    value: prop("name"),
    label: prop("name"),
  }),
)
