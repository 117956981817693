import { pathOr } from "ramda"
import qs from "qs"
import { NinjaResponseError } from "js/includes/common/types"
import {
  fetchJson,
  showErrorMessage,
  localized,
  user,
  ninjaReportError,
  isAppInQaMode,
  isFeatureEnabled,
  reportErrorAndShowMessage,
  getIsValidNodeClassTypeAV,
} from "js/includes/common/utils"
import { getGravityZoneConfig } from "js/includes/common/client/divisionConfig"
import { isAllowedServerException } from "js/includes/common/services/sentry"

export const sendWakeOnLAN = async deviceId => {
  try {
    await fetchJson(`/node/${deviceId}/wakeup`)
    return { success: deviceId }
  } catch (error) {
    if (error.response.status === 500 && error.resultCode === "NO_USABLE_MAC_ADRESSES") {
      return { fail: deviceId }
    } else {
      showErrorMessage(error.errorMessage || localized("Error sending wake-on-lan"))
      throw error
    }
  }
}

export const fetchDeviceById = async (nodeId, cacheExpiration = 0) => {
  try {
    const response = await fetchDevices([nodeId], false, cacheExpiration)

    const {
      nodes: [node],
    } = response

    return {
      node,
      cacheExpiration: response.cacheExpiration,
    }
  } catch (error) {
    throw error
  }
}

export const fetchDevicesByIds = (nodeIds, includeParentsAndChildren = false, cacheExpiration = 0) => {
  return fetchDevices(nodeIds, includeParentsAndChildren, cacheExpiration)
}

const fetchDevices = (nodeIds, includeParentsAndChildren, cacheExpiration) => {
  if (!nodeIds.length) {
    return {
      nodes: [],
    }
  }

  const body = JSON.stringify({
    nodeIds,
    includeParentsAndChildren,
    cacheExpiration,
    mode: isAppInQaMode() ? "qa" : undefined,
  })

  return fetchJson(`/query/device`, {
    options: {
      method: "POST",
      body,
    },
  })
}

export async function getAVProperties(node) {
  const { id, policyContent } = node
  const antivirusContent = pathOr({}, ["antivirus", "general"], policyContent)
  const { enabled, productCode } = antivirusContent
  if (!(enabled && productCode && getIsValidNodeClassTypeAV(node, productCode) && user("canUpdateDevices", node)))
    return { avEnabled: false }

  const isGravityZoneAvailable = productCode === "GRAVITYZONE" && isFeatureEnabled("gravityzone")
  if (isGravityZoneAvailable) {
    try {
      const [gzConfig, avEnabledResponse, gravityZoneDetail] = await Promise.all([
        getGravityZoneConfig(),
        fetchJson(`/node/${id}/${productCode}`),
        fetchJson(`/integrations/gravityzone/nodes/${id}`),
      ])
      const avEnabled = gzConfig?.content?.enabled && avEnabledResponse?.enabled && gravityZoneDetail?.synchronized

      return { avEnabled, productCode }
    } catch (error) {
      if (!isAllowedServerException(error)) {
        reportErrorAndShowMessage(error)
      }
      return { avEnabled: false }
    }
  }
  if (productCode === "BITDEFENDER") {
    try {
      const avEnabledResponse = await fetchJson(`/node/${id}/${productCode}`)
      return { avEnabled: pathOr(false, ["enabled"], avEnabledResponse), productCode }
    } catch (error) {
      if (error.response.status !== 404) {
        ninjaReportError(error)
      }
      return { avEnabled: false }
    }
  }
  if (productCode === "SENTINEL_ONE") {
    try {
      const response = await fetchJson(`/integrations/sentinelone/nodes/${node.id}`)
      const { installationStatus, activationStatus } = response
      return { avEnabled: installationStatus === "INSTALLED" && activationStatus === "ACTIVATED", productCode }
    } catch (error) {
      ninjaReportError(error)
      return { avEnabled: false }
    }
  }
  if (productCode === "CROWDSTRIKE") {
    try {
      const response = await fetchJson(`/integrations/crowdstrike/nodes/${node.id}`)
      const { installationStatus, activationStatus } = response
      return { avEnabled: installationStatus === "INSTALLED" && activationStatus === "ACTIVATED", productCode }
    } catch (error) {
      ninjaReportError(error)
      return { avEnabled: false }
    }
  }
  return { avEnabled: true, productCode }
}

export async function getNodeProductList(nodeId) {
  try {
    const nodeProductListResponse = await fetchJson(`/webapp/nodeproductlist/${nodeId}`)
    if (nodeProductListResponse.resultCode === "SUCCESS") {
      return nodeProductListResponse.nodeProducts || []
    } else {
      throw new NinjaResponseError(nodeProductListResponse)
    }
  } catch (error) {
    throw error
  }
}

export async function isDelegate(nodeId) {
  return fetchJson(`/virtualization/isVMDelegate/${nodeId}`)
}

export const getNode = nodeId => fetchJson(`/node/${nodeId}`)

export function patchNode(nodeId, values) {
  return fetchJson(`/node/${nodeId}`, {
    options: {
      method: "PATCH",
      body: JSON.stringify(values),
    },
  })
}

export function getNodeCredentialToken({ nodeId, credentialId }) {
  return fetchJson(`/credential-token`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        nodeId,
        credentialId,
        purposeCode: "NETWORK_LOCATION_AUTH_TEST",
      }),
    },
  })
}

export const getNodeClasses = () => fetchJson("/noderole/classes")
export const getNodeClassesByGroup = group => fetchJson(group ? `/nodeclass?nodeClassGroup=${group}` : "/nodeclass")
export const getNodeRoles = () => fetchJson("/noderole/list")
export const getSearches = () => fetchJson("/searches")

// TODO replace this method with setNodesOwner?
export const setNodeOwner = (nodeId, ownerUid) =>
  fetchJson(`/node-owner/set-owner/${nodeId}`, {
    options: {
      method: "PATCH",
      body: JSON.stringify({ ownerUid }),
    },
  })

export const removeNodeOwner = nodeId =>
  fetchJson(`/node-owner/remove-owner/${nodeId}`, {
    options: {
      method: "PATCH",
    },
  })

export const setNodesOwner = (nodeIdList, ownerUid) =>
  fetchJson("/node-owner/set-owner-to-nodes", {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        ownerUid,
        nodeIdList,
      }),
    },
  })

export const getInstallerUrl = ({ organizationId, locationId, installerType, nodeRoleId }) =>
  fetchJson("/api/v2/organization/generate-installer", {
    options: {
      method: "POST",
      body: JSON.stringify({
        usage_limit: 5,
        organization_id: organizationId,
        location_id: locationId,
        installer_type: installerType,
        content: {
          nodeRoleId,
        },
      }),
    },
  })

export const removeDevice = ({ nodeId, deleteBackupDataPermanently = false }) =>
  fetchJson(`/node/${nodeId}${deleteBackupDataPermanently ? "/includeAllBackupData" : ""}`, {
    options: {
      method: "DELETE",
    },
  })

export const addVMHost = host =>
  fetchJson(`/node/vmmtarget`, {
    options: { method: "POST", body: JSON.stringify(host) },
  })

export const getCommonPolicy = selectedDevicesIds =>
  fetchJson("/webapp/getcommonpolicy", {
    options: { method: "POST", body: JSON.stringify(selectedDevicesIds) },
  })

export const searchDevices = ({ queryParams, searchCriteria }) =>
  fetchJson(`/search/runner${qs.stringify(queryParams, { addQueryPrefix: true })}`, {
    options: { method: "POST", body: JSON.stringify({ searchCriteria }) },
  })

export const getDeviceSearchToolbarActions = ({ cacheKey, selectedNodeIds, unselectedNodeIds }) =>
  fetchJson(`/search/runner/device-actions`, {
    options: { method: "POST", body: JSON.stringify({ cacheKey, selectedNodeIds, unselectedNodeIds }) },
  })

export const runDeviceSearchToolbarActions = data =>
  fetchJson("/search/runner/execute-action", {
    options: { method: "POST", body: JSON.stringify(data) },
  })
