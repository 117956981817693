import { connect } from "react-redux"
import styled from "@emotion/styled"
import { Body, UnstyledTabsTrigger } from "@ninjaone/components"
import { ArrowRightIcon, CircleCheckIcon } from "@ninjaone/icons"
import { spacing, borderRadius } from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { getStartedTabs } from "js/includes/GetStartedPage/gettingStartedData"
import { DAYS, NINETY_ONE_DAYS_THRESHOLD } from "js/includes/GetStartedPage/utils/accountCreation"
import useIsOldAccount from "js/includes/GetStartedPage/utils/useIsOldAccount"

const StyledCard = styled(UnstyledTabsTrigger)`
  position: inherit;
  display: flex;
  text-align: start;
  white-space: line-break;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: ${({ theme }) => theme.colorBackgroundAccentCtaWeak};
  border-radius: ${borderRadius[1]};
  cursor: pointer;
  border: 1px solid transparent;
  gap: ${spacing[2]};
  width: 100%;
  margin: 0;
  padding: ${spacing[3]};
  color: ${({ theme }) => theme.colorTextAction};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  &[data-completed="true"] {
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  }
  &[data-selected="true"] {
    border-color: ${({ theme }) => theme.colorForegroundSelectedStrongest};
  }
`

const StyledDottedLine = styled.div`
  z-index: 0;
  position: absolute;
  border-left: ${({ theme }) => `1px dashed ${theme.colorBorderDecorativeStrong}`};
  top: 0;
  bottom: 0;
  height: 450px;
  margin-left: 26px;
`

const GetStartedTabs = ({
  activeStep,
  deselectTabs,
  finishTraining,
  handleActiveSteps,
  steps,
  userInvitationAcceptedTime,
}) => {
  const isOldUserAccount = useIsOldAccount(userInvitationAcceptedTime, DAYS, NINETY_ONE_DAYS_THRESHOLD)
  return (
    <Flex gap={spacing[4]} flexDirection="column" position="relative">
      <StyledDottedLine />
      {Object.entries(getStartedTabs).map(([key, { token, StepNumberSolidIcon, StepNumberLightIcon, StepIcon }]) => {
        const isTabSelected = !deselectTabs && activeStep === key
        const isTabCompleted = steps[key]
        const getIcon = () => {
          if (finishTraining || isOldUserAccount) return <StepIcon />
          else {
            if (isTabCompleted) {
              return <CircleCheckIcon />
            }
            if (isTabSelected) {
              return <StepNumberSolidIcon />
            }
            return <StepNumberLightIcon />
          }
        }
        return (
          <StyledCard
            data-completed={isTabCompleted}
            data-selected={isTabSelected}
            id={`fs-getStarted-${key}-btn`}
            key={key}
            onClick={() => handleActiveSteps(key)}
            value={key}
          >
            <Flex alignItems="center" margin={`${spacing[2]} ${spacing[1]}`}>
              <Box marginRight={spacing[3]}>{getIcon()}</Box>
              <Body type="headingS" color={isTabCompleted ? "colorTextStrong" : "colorTextAction"} textWrap>
                {localized(token)}
              </Body>
            </Flex>
            {isTabSelected && <ArrowRightIcon color="colorTextAction" />}
          </StyledCard>
        )
      })}
    </Flex>
  )
}
export default connect(state => ({
  userInvitationAcceptedTime: state.session.user.userInvitationAcceptedTime,
}))(GetStartedTabs)
