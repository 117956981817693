import { useState, useRef } from "react"
import { Text } from "@ninjaone/components"
import { localizationKey, localized } from "js/includes/common/utils"
import { MAX_INTEGER_VALUE } from "js/includes/common/_constants"
import { colors } from "js/includes/common/theme"
import { Box } from "js/includes/components/Styled"
import { operatorOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"

export const BATTERY_CHARGE = "BATTERY_CHARGE"
export const BATTERY_CAPACITY = "BATTERY_CAPACITY"
export const BATTERY_CYCLES = "BATTERY_CYCLES"
export const BATTERY_MONITORING_OPERATOR_OPTIONS = operatorOptions.items.filter(op => ["LT", "GT"].includes(op.value))

export const useBatteryMonitoringConfig = ({ batteryMonitoringValues, onChange, onThresholdChange }) => {
  const [selectedOption, setSelectedOption] = useState(batteryMonitoringValues.appliedCondition || BATTERY_CHARGE)
  const batteryChargeThresholdRef = useRef(null)
  const batteryCapacityThresholdRef = useRef(null)
  const batteryCyclesThresholdRef = useRef(null)
  const batteryChargeOperatorRef = useRef(null)
  const batteryCapacityOperatorRef = useRef(null)
  const batteryCyclesOperatorRef = useRef(null)

  const operatorProps = {
    values: batteryMonitoringValues,
    onChange: value => onChange("operator", value),
  }
  const thresholdProps = {
    values: batteryMonitoringValues,
    onChange: onThresholdChange,
    stepper: 1,
    suffix: (
      <Box display="flex" alignItems="center" justifyContent="flex-start" width="22px" backgroundColor={colors.white}>
        <Text>%</Text>
      </Box>
    ),
  }

  return {
    selectedOption,
    batteryMonitoringOptions: [
      {
        key: BATTERY_CHARGE,
        titleToken: localizationKey("Monitor Current Battery Charge Level"),
        labelToken: localizationKey("Battery Charge Level"),
        operatorProps: {
          ...operatorProps,
          options: {
            value: "LT",
            items: BATTERY_MONITORING_OPERATOR_OPTIONS,
          },
          initialValue:
            batteryMonitoringValues.appliedCondition === BATTERY_CHARGE ? batteryMonitoringValues.operator : "LT",
          ref: batteryChargeOperatorRef,
        },
        thresholdProps: {
          ...thresholdProps,
          minValue: 0,
          maxValue: 100,
          initialValue:
            batteryMonitoringValues.appliedCondition === BATTERY_CHARGE ? batteryMonitoringValues.threshold : 15,
          ref: batteryChargeThresholdRef,
        },
      },
      {
        key: BATTERY_CAPACITY,
        titleToken: localizationKey("Monitor Battery Maximum Capacity (Now vs New)"),
        labelToken: localizationKey("Battery Capacity (Now vs New)"),
        tooltipText: localized(
          "This is calculated by taking the (Current Capacity now when Fully Charged / Capacity when New) * 100 to give a percentage. This tells you how degraded a batteries capacity is.",
        ),
        operatorProps: {
          ...operatorProps,
          options: {
            value: "LT",
            items: BATTERY_MONITORING_OPERATOR_OPTIONS,
          },
          initialValue:
            batteryMonitoringValues.appliedCondition === BATTERY_CAPACITY ? batteryMonitoringValues.operator : "LT",
          ref: batteryCapacityOperatorRef,
        },
        thresholdProps: {
          ...thresholdProps,
          minValue: 0,
          maxValue: 100,
          initialValue:
            batteryMonitoringValues.appliedCondition === BATTERY_CAPACITY ? batteryMonitoringValues.threshold : 50,
          ref: batteryCapacityThresholdRef,
        },
      },
      {
        key: BATTERY_CYCLES,
        titleToken: localizationKey("Monitor Number of Battery Cycles"),
        labelToken: localizationKey("Battery Cycles"),
        operatorProps: {
          ...operatorProps,
          options: {
            value: "GT",
            items: BATTERY_MONITORING_OPERATOR_OPTIONS,
          },
          initialValue:
            batteryMonitoringValues.appliedCondition === BATTERY_CYCLES ? batteryMonitoringValues.operator : "GT",
          ref: batteryCyclesOperatorRef,
        },
        thresholdProps: {
          ...thresholdProps,
          withButtons: false,
          suffix: "",
          textAlign: "start",
          minValue: 1,
          maxValue: MAX_INTEGER_VALUE,
          initialValue:
            batteryMonitoringValues.appliedCondition === BATTERY_CYCLES ? batteryMonitoringValues.threshold : 1000,
          ref: batteryCyclesThresholdRef,
        },
      },
    ],
    applyCondition: condition => {
      const thresholds = {
        [BATTERY_CHARGE]: {
          operator: batteryChargeOperatorRef.current.state.selected,
          value: batteryChargeThresholdRef.current.state.value,
          unit: "PERCENT",
        },
        [BATTERY_CAPACITY]: {
          operator: batteryCapacityOperatorRef.current.state.selected,
          value: batteryCapacityThresholdRef.current.state.value,
          unit: "PERCENT",
        },
        [BATTERY_CYCLES]: {
          operator: batteryCyclesOperatorRef.current.state.selected,
          value: batteryCyclesThresholdRef.current.state.value,
          unit: "CYCLES",
        },
      }
      setSelectedOption(condition)
      onChange("appliedCondition", condition)
      onChange("unit", thresholds[condition].unit)
      onChange("operator", thresholds[condition].operator)
      onThresholdChange(thresholds[condition].value)
    },
  }
}

export const checkDisabledStatus = (selectedOption, option) =>
  selectedOption !== option ? { disabledClass: { className: "disabled" }, disabledAttribute: { disabled: true } } : {}
