import { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { fetchAvailablePatchVersions } from "js/includes/common/client"
import { useMountedState } from "js/includes/common/hooks"
import { localizationKey, reportErrorAndShowMessage } from "js/includes/common/utils"

const getPlatform = nodeClass => {
  switch (nodeClass) {
    case "APPLE_IOS":
      return "iOS"
    case "APPLE_IPADOS":
      return "iPadOS"
    default:
      throw new Error(`Platform not found for nodeClass: ${nodeClass}`)
  }
}

const useAvailableVersions = takenVersions => {
  const [versions, setVersions] = useMountedState([])
  const [isLoading, setIsLoading] = useMountedState(false)

  const nodeClass = useSelector(({ policyEditor: { policy } }) => policy.nodeClass, shallowEqual)
  const formatVersions = versions =>
    versions.map(productVersion => ({
      ...productVersion,
      labelText: productVersion.version,
      value: productVersion.version,
    }))

  useEffect(() => {
    const getAvailableVersions = async () => {
      setIsLoading(true)
      try {
        const { response } = await fetchAvailablePatchVersions(getPlatform(nodeClass))
        const filteredVersions = response.filter(({ version }) => !takenVersions.includes(version))
        const versionFormatted = formatVersions(filteredVersions)
        setVersions(versionFormatted)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Failed to fetch available versions"))
        setVersions([])
      } finally {
        setIsLoading(false)
      }
    }
    getAvailableVersions()
  }, [setVersions, setIsLoading, takenVersions, nodeClass])
  return { versions, isLoading }
}
export default useAvailableVersions
