import { Fragment, useCallback } from "react"
import { connect } from "react-redux"
import { curry, mergeLeft, path } from "ramda"
import { Button, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import { isNotNil, localizationKey, localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { StyledRow } from "js/includes/components/Styled/Form"
import showModal from "js/includes/common/services/showModal"
import ManualProxySetup, { ProxyTypes } from "./ManualProxySetup"
import { ConfirmRemoveProxy, ProxyTypeTitle } from "./utils"
import InheritableRowPolicyItem from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"
import { defaultInheritance, isInheritedRow } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { ProxyDescriptionRow } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/descriptionItems"
import GlobalProxySwitchActivation, {
  getParentGlobalProxy,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/GlobalProxySwitchActivation"

const {
  spacing,
  typography: { fontWeight },
} = tokens

function ProxyConfig({ globalProxy, updateProxy, parentPolicy }) {
  const parentProxy = path(["content", "network", "globalProxy"], parentPolicy)
  const isGlobalProxySet = isNotNil(globalProxy?.type)
  const isProxyEnabled = globalProxy?.active ?? true
  const { isGlobalProxyInherited, isProxyActivationAllowed } = getParentGlobalProxy(isProxyEnabled, globalProxy)
  const isProxyActive = isGlobalProxyInherited ? isProxyEnabled : true

  const handleRemoveProxy = () =>
    showModal(<ConfirmRemoveProxy onRemoveProxy={() => updateProxy(parentPolicy, defaultInheritance)} />)

  const isParentActive = parentProxy?.active ?? true

  const isInheritedAndParentActive = proxy => isInheritedRow(proxy) && isParentActive

  const updateActiveProxy = useCallback(
    isActive => updateProxy(parentPolicy, mergeLeft({ active: isActive }, globalProxy)),
    [parentPolicy, globalProxy, updateProxy],
  )

  return (
    <Box>
      {/* Need to check the parent proxy active property since the empty child policy 
        has the inheritance object with inherited value as true even if the parent have not set a proxy */}
      {isParentActive && (
        <InheritableRowPolicyItem
          inheritableItem={globalProxy}
          pathToItem={"network.globalProxy"}
          wrapperProps={{ padding: `${spacing[3]} 0px`, marginBottom: spacing[3] }}
          testId="proxy-settings"
          borderColor="colorBorderWeak"
        >
          <Text color="colorTextStrong" token={localizationKey("Proxy settings")} fontWeight={fontWeight.medium} />
        </InheritableRowPolicyItem>
      )}
      {!isParentActive && (
        <Box padding={`${spacing[3]} 0px`} marginBottom={spacing[3]}>
          <Text color="colorTextStrong" token={localizationKey("Proxy settings")} fontWeight={fontWeight.medium} />
        </Box>
      )}
      {isGlobalProxySet && (
        <>
          {globalProxy.type === ProxyTypes.manual && (
            <Box marginTop={spacing[1]}>
              <ProxyTypeTitle token={localizationKey("Direct Proxy")} />
              {isGlobalProxyInherited && isParentActive && (
                <GlobalProxySwitchActivation {...{ isProxyEnabled, updateActiveProxy }} />
              )}
              <ProxyDescriptionRow label={localized("Host")} value={globalProxy.server} />
              <ProxyDescriptionRow label={localized("Port")} value={globalProxy.port} />
              <ProxyDescriptionRow label={localized("Username")} value={globalProxy.username} />
            </Box>
          )}
          {globalProxy.type === ProxyTypes.auto && (
            <Box marginTop={spacing[1]}>
              <ProxyTypeTitle token={localizationKey("Proxy auto-configuration (PAC)")} />
              {isGlobalProxyInherited && isParentActive && (
                <GlobalProxySwitchActivation {...{ isProxyEnabled, updateActiveProxy }} />
              )}
              <ProxyDescriptionRow label={localized("PAC URI")} value={globalProxy.pacUrl} />
            </Box>
          )}
        </>
      )}
      <Box padding={sizer(0, 4)} marginBottom={sizer(8)} marginTop={sizer(3)}>
        <StyledRow marginBottom={sizer(4)}>
          {isGlobalProxySet && !isInheritedAndParentActive(globalProxy) && (
            <Box marginRight={3}>
              <Button
                type="delete"
                labelToken={localizationKey("Remove")}
                onClick={handleRemoveProxy}
                disabled={isInheritedAndParentActive(globalProxy) && !isProxyActivationAllowed}
              />
            </Box>
          )}
          <Box>
            <Button
              labelToken={isGlobalProxySet ? localizationKey("Edit Proxy") : localizationKey("Manual Proxy Setup")}
              disabled={!(isProxyEnabled ?? true)}
              onClick={() => {
                showModal(
                  <ManualProxySetup
                    {...{ globalProxy, isProxyActive }}
                    updateProxy={newProxy => updateProxy(parentPolicy, mergeLeft(newProxy, globalProxy))}
                  />,
                )
              }}
            />
          </Box>
        </StyledRow>
      </Box>
    </Box>
  )
}

export default connect(
  ({ policyEditor }) => ({
    globalProxy: policyEditor.policy.content?.network?.globalProxy,
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updateProxy: curry(updatePolicyItem)("network.globalProxy"),
  },
)(ProxyConfig)
