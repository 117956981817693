const faRebootDisinfect = {
  prefix: "fac",
  iconName: "reboot-disinfect",
  icon: [
    512,
    512,
    [],
    "0024",
    `M461.316,50.835L436.224,75.926C390.053,29.746 326.271,1.176 255.808,1.176C115.197,1.176 0.537,115.924 0.64,256.534C0.743,397.372 114.945,511.511 255.807,511.511C321.58,511.511 381.54,486.625 426.787,445.756C432.048,441.004 432.295,432.832 427.283,427.819L406.975,407.511C402.347,402.883 394.882,402.587 389.997,406.943C354.362,438.712 307.36,458.008 255.807,458.008C144.355,458.008 54.143,367.812 54.143,256.344C54.143,144.892 144.338,54.679 255.807,54.679C311.535,54.679 361.945,77.236 398.427,113.724L374.965,137.187C367.186,144.965 372.695,158.264 383.695,158.264L498.627,190.494C505.446,190.494 510.974,184.966 510.974,178.147L482.393,59.566C482.393,48.566 469.093,43.057 461.316,50.835ZM347.311,134.054C387.003,162.406 412.834,208.776 412.834,261.257C412.834,347.445 343.027,417.315 256.902,417.504C170.903,417.693 100.466,347.571 100.34,261.509C100.277,209.028 126.109,162.532 165.737,134.117C173.109,128.888 183.378,131.093 187.788,138.969L197.743,156.672C201.46,163.288 199.696,171.667 193.584,176.203C167.438,195.608 150.743,226.353 150.743,261.194C150.68,319.345 197.68,367.101 256.587,367.101C314.298,367.101 362.81,320.353 362.432,260.564C362.243,227.928 346.87,196.427 319.527,176.14C313.416,171.604 311.715,163.225 315.432,156.672L325.386,138.969C329.796,131.156 340.003,128.825 347.311,134.054ZM266.668,99.97C275.047,99.97 281.788,106.711 281.788,115.091L281.788,266.297C281.788,274.676 275.047,281.418 266.668,281.418L246.507,281.418C238.127,281.418 231.386,274.676 231.386,266.297L231.386,115.091C231.386,106.711 238.127,99.97 246.507,99.97L266.668,99.97Z`,
  ],
}

export default faRebootDisinfect
