import { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import styled from "@emotion/styled"
import { Checkbox } from "@ninjaone/components"
import { Box, Flex } from "js/includes/components/Styled"
import { localized } from "js/includes/common/utils"

const StyledClickableLabel = styled.label`
  margin: 0;
  font-weight: normal;
  cursor: ${disabled => (disabled ? "auto" : "pointer")};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  display: flex;
  align-items: center;
  color: ${({ disabled, theme }) => (disabled ? theme.color.ninjaMedium : "auto")};
`

const CheckboxCondition = memo(
  ({ labelToken, helperTextToken, tooltip, prop, values, onChange, disabled, alignItemsCenter }) => (
    <FormGroup controlId={prop}>
      <Flex alignItems={alignItemsCenter ? "center" : "flex-end"}>
        <Col componentClass={ControlLabel} sm={4}>
          {labelToken && localized(labelToken)} {tooltip || ""}
        </Col>
        <Col sm={8} className={alignItemsCenter ? "p-t-md" : ""}>
          <Flex alignItems="center" position="relative">
            <Checkbox id={prop} checked={Boolean(values[prop])} onChange={onChange} disabled={disabled} />
            <StyledClickableLabel disabled={disabled} {...(!disabled && { htmlFor: prop })}>
              {helperTextToken && <Box marginLeft={6}>{localized(helperTextToken)}</Box>}
            </StyledClickableLabel>
          </Flex>
        </Col>
      </Flex>
    </FormGroup>
  ),
)

export default CheckboxCondition
