import { cloneElement, forwardRef } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { Root, Trigger, Portal, Content } from "@radix-ui/react-popover"

const StyledContent = styled.div`
  padding: ${({ useDefaultStyles }) => (useDefaultStyles ? "4px" : "0")}; // replace 4px with token
  border-radius: ${({ useDefaultStyles }) => (useDefaultStyles ? "4px" : "0")}; // replace 4px with token
  border: ${({ useDefaultStyles, theme }) => (useDefaultStyles ? `1px solid ${theme.colorBorderWeak}` : "0")};
  background: ${({ theme }) => theme.colorBackgroundWidget};
  box-shadow: ${({ useDefaultStyles }) =>
    useDefaultStyles ? "0px 2px 4px 0px rgba(33, 31, 51, 0.05)" : "none"}; // replace box shadow with token
`

const PopoverTrigger = forwardRef(({ triggerRenderer, triggerAriaLabel, ...rest }, forwardedRef) => (
  <span ref={forwardedRef}>{cloneElement(triggerRenderer(), { ...{ radixProps: { ...rest } } })}</span>
))

const Popover = ({
  align = "start",
  alignOffset = 0,
  avoidCollisions = true,
  collisionBoundary,
  collisionPadding,
  forceMount,
  children,
  onCloseAutoFocus,
  onEscapeKeyDown,
  onFocusOutside,
  onInteractOutside,
  onOpenChange,
  onOpenAutoFocus,
  open,
  popoverCss,
  side = "bottom",
  sideOffset = 4,
  triggerRenderer,
  triggerAriaLabel,
  useDefaultStyles = true,
}) => (
  <Root {...{ open, onOpenChange }}>
    <Trigger asChild>
      <PopoverTrigger {...{ triggerRenderer, triggerAriaLabel }} />
    </Trigger>

    <Portal {...{ forceMount }}>
      <Content
        css={popoverCss}
        {...{
          align,
          alignOffset,
          avoidCollisions,
          collisionBoundary,
          collisionPadding,
          onCloseAutoFocus,
          onEscapeKeyDown,
          onFocusOutside,
          onInteractOutside,
          onOpenAutoFocus,
          side,
          sideOffset,
        }}
      >
        <StyledContent {...{ useDefaultStyles }}>{children}</StyledContent>
      </Content>
    </Portal>
  </Root>
)

Popover.propTypes = {
  /**
   * The horizontal alignment of the Popover against the trigger.
   * May change when collisions occur.
   */
  align: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * An offset in pixels from the "start" or "end" alignment options.
   */
  alignOffset: PropTypes.number,
  /**
   * When `true`, overrides the `side` and `align` preferences
   * to prevent collisions with boundary edges.
   */
  avoidCollisions: PropTypes.bool,
  /**
   * The content for the Popover
   */
  children: PropTypes.any.isRequired,
  /**
   * The element used as the collision boundary. By default this
   * is the viewport, though you can provide additional element(s)
   * to be included in this check.
   */
  collisionBoundary: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  /**
   * The distance in pixels from the boundary edges where collision
   * detection should occur. Accepts a number (same for all sides),
   * or a partial padding object, for example: `{ top: 20, left: 20 }`.
   */
  collisionPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  /**
   * Used to force mounting when more control is needed.
   */
  forceMount: PropTypes.bool,
  /**
   * Event handler called when focus moves to the trigger after
   * closing. It can be prevented by calling `event.preventDefault`.
   */
  onCloseAutoFocus: PropTypes.func,
  /**
   * Event handler called when the escape key is down.
   * It can be prevented by calling `event.preventDefault`.
   */
  onEscapeKeyDown: PropTypes.func,
  /**
   * Event handler called when focus moves outside the bounds of the
   * component. It can be prevented by calling `event.preventDefault`.
   */
  onFocusOutside: PropTypes.func,
  /**
   * Event handler called when an interaction (pointer or focus event)
   * happens outside the bounds of the component. It can be prevented
   * by calling `event.preventDefault`.
   */
  onInteractOutside: PropTypes.func,
  /**
   * Event handler called when the open state of the popover changes.
   * This event handler is called before the component rerenders.
   */
  onOpenChange: PropTypes.func,
  /**
   * Event handler called when focus moves into the component after opening.
   * It can be prevented by calling `event.preventDefault`.
   */
  onOpenAutoFocus: PropTypes.func,
  /**
   * The controlled open state of the Popover.
   */
  open: PropTypes.bool,
  /**
   * Custom css to apply to the inner `<div>` of the Popover.
   */
  portalCss: PropTypes.any,
  /**
   * The preferred side of the trigger to render against when open.
   * Will be reversed when collisions occur and `avoidCollisions` is enabled.
   */
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The distance in pixels from the trigger.
   */
  sideOffset: PropTypes.number,
  /**
   * Function to render the trigger for the Popover.
   */
  triggerRenderer: PropTypes.func.isRequired,
  /**
   * Aria label for the Popover trigger. This should be used when the content
   * of the trigger does not provide suitable context for the action being
   * performed.
   */
  triggerAriaLabel: PropTypes.string,
  /**
   * Provides default styling for the Popover.
   */
  useDefaultStyles: PropTypes.bool,
}

export default Popover
