import { useCallback, useState } from "react"
import { assocPath, isNil } from "ramda"
import { Modal } from "@ninjaone/components"
import { isNotNil, localizationKey, localized } from "js/includes/common/utils"
import { ConfigItem, splitKeys } from "./ManagedConfigurations"
import { Box } from "js/includes/components/Styled"
import { applyDefaultValue, flattenManagedProperties, ManagedConfigType } from "./utils"

const createBundleArrayValues = nestedProperties => {
  const propertiesWithDefault = flattenManagedProperties(nestedProperties, {
    excludeItem: ({ defaultValue, type }) => isNil(defaultValue) && type !== ManagedConfigType.BUNDLE,
  })
  return propertiesWithDefault.reduce(applyDefaultValue, {})
}

export const AddBundleConfigModal = ({ unmount, groupValue, nestedProperties, onSave }) => {
  const [_groupValue, setGroupValue] = useState(groupValue ?? (() => createBundleArrayValues(nestedProperties)))

  const updateField = useCallback((key, value) => {
    setGroupValue(assocPath(splitKeys(key), value))
  }, [])

  const isUpdate = isNotNil(groupValue)
  return (
    <Modal
      {...{ unmount }}
      size="md"
      titleGroup={{ titleText: isUpdate ? localized("Edit item") : localized("Add new item") }}
      buttons={[
        {
          labelToken: isUpdate ? localizationKey("Update") : localizationKey("Add"),
          type: "save",
          onClick: () => {
            onSave(_groupValue)
            unmount()
          },
        },
      ]}
      scrollable
    >
      <Box minHeight="320px">
        {nestedProperties.map(({ key, ...props }, index) => (
          <ConfigItem
            key={key}
            value={_groupValue[key]}
            updateManagedConfigField={updateField}
            configKeys={key}
            hideDivider={nestedProperties.length === index + 1}
            {...props}
          />
        ))}
      </Box>
    </Modal>
  )
}
