import { localizationKey } from "js/includes/common/utils"
import { PasswordPolicyScope } from "./enums"

// Passcode
export const passwordScopeOptions = [
  {
    scope: PasswordPolicyScope.SCOPE_UNSPECIFIED,
    labelToken: localizationKey("Unspecified scope"),
  },
  {
    scope: PasswordPolicyScope.SCOPE_DEVICE,
    labelToken: localizationKey("Device scope"),
  },
  {
    scope: PasswordPolicyScope.SCOPE_PROFILE,
    labelToken: localizationKey("Profile scope"),
  },
]
