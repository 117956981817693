import React, { memo } from "react"
import {
  OperatorSelect,
  UnitSelect,
  ThresholdSelect,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"
import { daysOptions, unitOptionsDWM } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"

export default memo(({ values, onChange, onUnitChange, validation }) => {
  return (
    <>
      <OperatorSelect
        {...{
          values,
          onChange,
          validation,
        }}
      />

      <ThresholdSelect
        {...{
          validation,
          initialValue: values.duration,
          thresholdOptions: daysOptions,
          onChange: value => onChange("duration", parseInt(value, 10)),
        }}
      />

      <UnitSelect
        {...{
          onChange: onUnitChange,
          values,
          validation,
          unitOptions: unitOptionsDWM,
        }}
      />
    </>
  )
})
