import {
  COMPLETE_GETTING_STARTED_STEP,
  SET_GETTING_STARTED_STEP,
  SETUP_GETTING_STARTED,
} from "./gettingStartedActionTypes"

export const completeStepGettingStarted = stepId => ({
  type: COMPLETE_GETTING_STARTED_STEP,
  stepId,
})

export const setGettingStartedStep = stepId => ({
  type: SET_GETTING_STARTED_STEP,
  stepId,
})

export const setupGettingStarted = gettingStartedSteps => ({
  type: SETUP_GETTING_STARTED,
  gettingStartedSteps,
})
