import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import pathParse from "path-parse"

import { attachmentUploadUrl, getDeleteUrl } from "js/includes/common/client"
import {
  bytes,
  fetchJson,
  joinByComma,
  localizationKey,
  localized,
  localizedF,
  noop,
  reportError,
  upload,
} from "js/includes/common/utils"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { twentyMiB } from "js/includes/ticketing/editor/shared/constants"

export const isValidFileType = (file, acceptedFileExtensions) => {
  const { ext } = pathParse(file?.name) || {}

  const isFileAccepted = acceptedFileExtensions.includes(ext?.toLowerCase())

  if (!isFileAccepted) {
    ShowMessageDialog({
      icon: { icon: faInfoCircle, type: "warning" },
      buttons: [{ id: "OK", label: localizationKey("OK") }],
      title: localizationKey("Invalid file type"),
      message: () => `${localized("general.youHaveAttemptedToDrop")} ${joinByComma(acceptedFileExtensions)}`,
    })
  }

  return isFileAccepted
}

export const isValidFileSize = (file, { minSize = 1, maxSize = twentyMiB } = {}) => {
  const belowMinSize = file.size < minSize
  const overMaxSize = file.size > maxSize

  if (belowMinSize || overMaxSize) {
    ShowMessageDialog({
      icon: { icon: faInfoCircle, type: "warning" },
      buttons: [{ id: "OK", label: localizationKey("OK") }],
      ...(belowMinSize && {
        title: "general.fileTooSmall",
        message: localizedF("general.fileSizeMustBeMoreThanBytes", { size: minSize }),
      }),
      ...(overMaxSize && {
        title: "general.fileTooLarge",
        message: () => `${localized("File exceeds the limit of ")} ${bytes(maxSize, 0)}`,
      }),
    })
    return false
  }

  return true
}

export const readLocalFileAsUrl = file => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file)
    reader.onload = e => {
      resolve(e.target.result)
    }
    reader.onerror = reject
  })
}

export const uploadFile = ({ file, onProgress = noop }) => {
  const body = { file }

  return new Promise(async function(resolve) {
    const { xhr } = await upload(attachmentUploadUrl, {
      body,
      onProgressHandler: _progress => {
        const progress = (_progress.loaded * 100) / _progress.total
        onProgress(progress)
      },
    })

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const uploadedFile = JSON.parse(xhr.response)
        resolve(uploadedFile)
      }
    }
  })
}

export const deleteFile = async resourceId => {
  try {
    await fetchJson(getDeleteUrl(resourceId), {
      options: { method: "DELETE" },
    })
  } catch (error) {
    reportError(error)
  }
}
