import { compose, filter, map, pathOr } from "ramda"

import { getIntegrityCheckJob, submitJob } from "js/includes/common/backup"
import { useMountedState } from "js/includes/common/hooks"
import {
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  runDeviceSearchToolbarAction,
  showSuccessMessage,
} from "js/includes/common/utils"
import { sortBackupPlans } from "js/includes/components/Backup/Modals/RunBackupModal"
import Modal from "js/includes/components/Modal"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { isLocalOrHybrid } from "js/includes/editors/Policy/Sections/Backups/common/destination"

const createBackupPlanOption = ({ name: label, identifier: value, ...attributes }) => ({
  label,
  value,
  ...attributes,
})

const PickBackupPlanModal = ({ unmount, node: { id: nodeId }, commonPolicy, fromDeviceSearch = false }) => {
  const [disabled, setDisabled] = useMountedState(false)
  const [backupPlan, setBackupPlan] = useMountedState(null)

  const backupPlansOptions = compose(
    map(createBackupPlanOption),
    sortBackupPlans,
    filter(({ productCode, type, destination }) => productCode === "LOCKHART" && isLocalOrHybrid(destination)),
    pathOr([], ["backup", "backupPlans"]),
  )(commonPolicy)

  const runIntegrityCheck = async () => {
    setDisabled(true)
    try {
      const { value: planId, label: planName, type } = backupPlan

      const integrityCheckJob = getIntegrityCheckJob({ nodeId, planId, planName, type })

      if (fromDeviceSearch) {
        await runDeviceSearchToolbarAction({
          action: "BACKUP_RUN_INTEGRITY_CHECK",
          data: { job: integrityCheckJob.jobs[0] },
        })
      } else {
        await submitJob([integrityCheckJob])
      }

      showSuccessMessage(localized("Integrity check job created successfully"))
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        reportErrorAndShowMessage(error, localizationKey("Error running integrity check job"))
      }
    } finally {
      setDisabled(false)
    }
  }

  return (
    <Modal
      title={localizationKey("Run Integrity Check")}
      disabledSaveBtn={!backupPlan || disabled}
      saveText={localizationKey("Run")}
      save={runIntegrityCheck}
      close={unmount}
      dialogClassName="no-overflow-modal run-backup-plan-modal"
    >
      <SearchableDropDown
        {...{
          value: backupPlan,
          searchPlaceholderToken: localizationKey("Select backup plan"),
          onSelect: setBackupPlan,
          options: backupPlansOptions,
          noRowsRendererToken: localizationKey("No backup plans available"),
          width: "100%",
          ariaAttributes: { "aria-label": localized("Select backup plan") },
        }}
      />
    </Modal>
  )
}

export default PickBackupPlanModal
