import { fetchJson, ninjaReportError } from "js/includes/common/utils"

export async function requestAndShowApplicationAnnouncement() {
  try {
    const announcement = await fetchJson("/application-announcement")
    const content = announcement["content"]
    if (!content) {
      window.store.dispatch({ type: "HIDE_APP_ANNOUNCEMENT" })
      return
    }
    window.store.dispatch({ type: "SET_APP_ANNOUNCEMENT", payload: { id: announcement.id, content } })
  } catch (error) {
    ninjaReportError(error)
  }
}
