import $ from "jquery"
import { includes } from "ramda"
import { fetch, showErrorMessage, localized, isAppInQaMode, redirectToLogin } from "js/includes/common/utils"
import { NinjaNotAuthenticatedError } from "js/includes/common/types"
import { getNinjaResourcesCdnRoute } from "js/includes/common/services/ninjaResourcesCdn"

export * from "./applicationAnnouncement"
export * from "./branding"
export * from "./userInactivityTimeout"

export const isFeatureEnabled = (feature: string) => {
  const enabledFeatures = window.store?.getState()?.session?.features || []
  return includes(feature, enabledFeatures)
}

export function debugLog() {
  if (isAppInQaMode()) {
    console.log(arguments)
  }
}

export function getOs() {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const osxPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"]
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"]
  const iosPlatforms = ["iPhone", "iPad", "iPod"]

  if (osxPlatforms.indexOf(platform) !== -1) return "osx"
  if (windowsPlatforms.indexOf(platform) !== -1) return "windows"
  if (iosPlatforms.indexOf(platform) !== -1) return "apple_ios"
  if (/Android/.test(userAgent)) return "android"
  if (/Linux/.test(platform)) return "linux"

  // could not determine OS
  return null
}

export function preventBackspaceNavigation() {
  //Prevents backspace except in the case of textareas and text inputs to prevent user navigation.
  $(document).keydown(function(e) {
    var preventKeyPress
    if (e.keyCode === 8) {
      var d = e.srcElement || e.target
      switch (d.tagName.toUpperCase()) {
        case "TEXTAREA":
          preventKeyPress = d.readOnly || d.disabled
          break
        case "INPUT":
          preventKeyPress =
            d.readOnly ||
            d.disabled ||
            (d.attributes["type"] &&
              $.inArray(d.attributes["type"].value.toLowerCase(), ["radio", "checkbox", "submit", "button"]) >= 0)
          break
        case "DIV":
          preventKeyPress =
            d.readOnly ||
            d.disabled ||
            // eslint-disable-next-line
            !(d.attributes["contentEditable"] && d.attributes["contentEditable"].value == "true")
          break
        default:
          preventKeyPress = true
          break
      }
    } else preventKeyPress = false

    if (preventKeyPress) e.preventDefault()
  })
}

//For running methods on the currently logged in User BB object
export const user = (prop, ...args) => {
  if (!window.application) {
    return undefined
  }
  return window.application.get("user")[prop](...args)
}

//For getting fields on the currently logged in User BB object
export const userProp = prop => {
  if (!window.application) {
    return undefined
  }

  return window.application.get("user").attributes[prop]
}

export const isSystemAdmin = () => {
  if (window.test_server_permissions) return true
  return userProp("sysadmin") || false
}

export const isCurrentUser = (userId: number) => window.application.get("user").get("id") === userId

export async function getAttachmentBlob(query: string) {
  try {
    const response = await fetch(`/attachment/${query}`)
    if (response.status === 401) {
      redirectToLogin()
      throw new NinjaNotAuthenticatedError()
    } else {
      const imageBlob = await response.blob()
      return {
        objectURL: URL.createObjectURL(imageBlob),
        headers: response.headers,
      }
    }
  } catch (error) {
    showErrorMessage(localized("Error fetching attachment"))
    throw error
  }
}

export async function sendSessionConfig() {
  try {
    const body = JSON.stringify({
      localeId: window.store.getState().application.language,
      timeZoneId: window.application.get("timeZone").get("value"),
    })
    await fetch("/webapp/sessionconfig", { options: { method: "POST", body } })
  } catch (err) {
    throw err
  }
}

export function getContactUsUrl() {
  return `${getNinjaResourcesCdnRoute()}/ninja-contact.html`
}

export const getNodeClasses = () => window.nodeClasses ?? []
