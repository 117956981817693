import MomentLocaleUtils from "react-day-picker/moment"
import { Text } from "@ninjaone/components"
import { localized } from "js/includes/common/utils"

const FreezePeriodCustomDropdown = ({ date, locale, onChange, toMonth }) => {
  const currentYear = new Date().getFullYear()
  const _months = MomentLocaleUtils.getMonths(locale)
  const additionalMonths = toMonth ? toMonth.getMonth() + 1 : 0

  const additionalMonthsArray = Array.from({ length: additionalMonths }, (_, i) => {
    const monthIndex = i % 12
    return `${_months[monthIndex]}`
  })

  const months = [..._months, ...additionalMonthsArray]

  const findMonthIndex = date => {
    return date.getFullYear() > currentYear ? date.getMonth() + 12 : date.getMonth()
  }

  const handleChange = function handleChange(e) {
    const index = e.target.selectedIndex
    const currentMonth = index % 12
    const date = new Date(index > 11 ? currentYear + 1 : currentYear, currentMonth)
    onChange(date)
  }

  return (
    <form className="DayPicker-Caption">
      <Text visuallyHidden>
        <label htmlFor="month">{localized("Month")}</label>
      </Text>

      <select id="month" name="month" onChange={handleChange} value={findMonthIndex(date)}>
        {months.map((month, i) => (
          <option key={`${month}-${i}`} value={i}>
            {month}
          </option>
        ))}
      </select>
    </form>
  )
}

export default FreezePeriodCustomDropdown
