import qs from "qs"
import { fetchJson, localized, reportErrorAndShowMessage } from "js/includes/common/utils"

export const getLocationByIds = async ids => {
  const query = qs.stringify({ id: [ids], pageSize: ids.length }, { indices: false })
  try {
    return await fetchJson(`/query/locations?${query}`)
  } catch (error) {
    reportErrorAndShowMessage(error, localized("Error fetching locations"))
    return []
  }
}

export const getLocation = id => fetchJson(`/location/${id}`)
