export const setDevicesCacheKey = cacheKey => ({
  type: "SET_DEVICES_CACHE_KEY",
  cacheKey,
})

export const setFromDeviceSearch = fromDeviceSearch => ({
  type: "SET_FROM_DEVICE_SEARCH",
  fromDeviceSearch,
})

export const setActionRunnerParams = actionRunnerParams => ({
  type: "SET_ACTION_RUNNER_PARAMS",
  actionRunnerParams,
})

export const clearActionRunnerParams = () => ({
  type: "CLEAR_ACTION_RUNNER_PARAMS",
})
