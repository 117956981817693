import {
  always,
  ascend,
  compose,
  cond,
  curry,
  defaultTo,
  descend,
  equals,
  find,
  findIndex,
  flatten,
  groupBy,
  is,
  isNil,
  length,
  map,
  mergeRight,
  modify,
  move,
  prop,
  propEq,
  propOr,
  reverse,
  sortBy,
  sortWith,
  T,
  toLower,
  update,
  values,
  when,
} from "ramda"
import { SortDirection } from "react-virtualized"
import { localized, propToLower } from "js/includes/common/utils"

const caseInsensitiveSort = (a, b) => {
  return a
    .trimStart()
    .toLowerCase()
    .localeCompare(b.trimStart().toLowerCase(), undefined, { numeric: true, sensitivity: "base" })
}

const standardSort = (a, b) => a - b

// for react-table sortType, you can use it in any column you want to make case-insensitive
export const byColumnSort = (a, b, id) => {
  const valueA = a.values[id]
  const valueB = b.values[id]

  if (typeof valueA === "string" && typeof valueB === "string") {
    return caseInsensitiveSort(valueA, valueB)
  }

  if (typeof valueA === "number" && typeof valueB === "number") {
    return standardSort(valueA, valueB)
  }

  if (valueA instanceof Date && valueB instanceof Date) {
    return standardSort(valueA.getTime(), valueB.getTime())
  }

  if (valueA === null || valueA === undefined) {
    return -1
  } else if (valueB === null || valueB === undefined) {
    return 1
  }

  return 0
}

const sortByColumnCaseInsensitive = (columnName, sortDirection) => {
  const order = sortDirection === "ASC" ? 1 : -1

  return (a, b) => {
    const valA = a[columnName]
    const valB = b[columnName]

    if (isNil(valA)) return order
    if (isNil(valB)) return -1 * order

    if (typeof valA === "string" || typeof valB === "string") {
      const strA = valA?.toLowerCase() ?? ""
      const strB = valB?.toLowerCase() ?? ""
      return strA.localeCompare(strB, undefined, { numeric: true, sensitivity: "base" }) * order
    }

    return (valA < valB ? -1 : valA > valB ? 1 : 0) * order
  }
}

export const sortRowsByColumn = (columnName, sortDirection, data) =>
  data.sort(sortByColumnCaseInsensitive(columnName, sortDirection))

export const sortByFieldNameCaseInsensitive = curry((propName, sortDirection, data) =>
  sortWith([
    cond([
      [always(equals("ASC", sortDirection)), ascend(propToLower(propName))],
      [always(equals("DESC", sortDirection)), descend(propToLower(propName))],
      [
        T,
        () => {
          throw new Error(`Invalid sortDirection: ${sortDirection}`)
        },
      ],
    ])(),
  ])(data),
)

export const sortCaseInsensitive = data => {
  return data.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
}

const cellDataGetter = (rowData, sortBy, columns) => {
  if (!isNil(rowData[sortBy])) return rowData[sortBy]

  const col = find(({ dataKey }) => dataKey === sortBy, columns) || {}
  const { cellDataGetter } = col
  return cellDataGetter ? cellDataGetter({ rowData }) : null
}

export const sortTableList = (list = [], sortBy, sortDirection, columns = []) => {
  const normalize = compose(defaultTo(""), when(is(String), toLower), rowData =>
    cellDataGetter(rowData, sortBy, columns),
  )

  return sortWith([
    cond([
      [() => sortDirection === "ASC", ascend(normalize)],
      [() => sortDirection === "DESC", descend(normalize)],
    ]),
  ])(list)
}

//Overrides the default sort function to sort by the priority field first
export const sortTableListWithPriority = (list, sortBy, sortDirection, columns) =>
  compose(
    flatten,
    map(prioritizedList => sortTableList(prioritizedList, sortBy, sortDirection, columns)),
    when(() => sortDirection === "ASC", reverse),
    values,
    groupBy(propOr(0, "priority")),
  )(list)

export const sortMultiTypeTableList = (list, sortByProp, sortDirection) =>
  compose(
    when(
      _ =>
        // special use case where we calculate a time since _
        // 'since' is a backwards reference, so it is naturally reversed
        // this reverse normalizes it
        ["updateTime", "modified"].includes(sortByProp),
      reverse,
    ),
    when(_ => equals(sortDirection, SortDirection.ASC), reverse),
    sortBy(compose(when(is(Array), length), when(is(String), toLower), when(isNil, always(0)), prop(sortByProp))),
    when(is(Object), values),
  )(list)

export function naturalSort(a, b) {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
}

//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

// sorts alphabetically, appends "(Default)" to the end of specified prop, and moves to top of list
export const sortWithDefaultValue = ({ data = [], prop = "name", defaultId }) => {
  const sortedData = sortByFieldNameCaseInsensitive(prop, "ASC", data)
  if (!defaultId) return sortedData

  const modifiedObj = compose(
    modify(prop, prop => localized("{{name}} (Default)", { name: prop })),
    defaultObj => mergeRight(defaultObj, { originalName: defaultObj?.name }),
    find(propEq("id", defaultId)),
  )(sortedData)

  const updateIndex = findIndex(propEq("id", defaultId))(sortedData)

  if (updateIndex < 0) return sortedData

  return compose(move(updateIndex, 0), update(updateIndex, modifiedObj))(sortedData)
}
