const faRepairShopr = {
  prefix: "fac",
  iconName: "repairshopr",
  icon: [
    200,
    200,
    [],
    "0004",
    `M46.541,78.998c0.002-4.872,0.041-9.745-0.016-14.617c-0.025-2.107,0.602-3.258,2.984-3.262
    c7.97-0.015,12.5,0.295,18.548,1.475C76.902,64.32,81.79,70.083,82.15,77.62c0.383,8.027-4.173,14.536-12.268,17.698
    c-6.772,2.645-13.818,2.199-20.851,2.047c-2.29-0.05-2.505-1.577-2.499-3.343C46.55,89.014,46.539,84.006,46.541,78.998z M199.991,10.642c-0.001-7.941-2.73-10.63-10.8-10.632c-29.641-0.008-59.281-0.003-88.922-0.003
    c-30.047,0-60.093-0.008-90.14,0.006C2.928,0.017,0.015,2.921,0.013,10.103c-0.011,59.823-0.01,119.645-0.001,179.468
    c0.001,7.677,2.804,10.419,10.596,10.42c59.552,0.005,119.104,0.005,178.656,0.001c7.999-0.001,10.727-2.719,10.727-10.693
    C199.996,129.746,199.996,70.194,199.991,10.642z M111,156H84.975c0,0-1.183-2.301-1.685-3.277
    c-6.009-11.671-11.959-23.367-18.297-34.862c-2.29-4.153-2.797-4.402-7.189-4.171c-3.417,0.18-5.675,0.189-8.511,0.063
    c-1.937-0.086-2.744,0.698-2.741,2.562c0.012,6.633,0.016,13.382-0.002,20.015c-0.002,0.633,0,0.67,0.057,1.67H59v1.431v16.114V156
    h-1.223c-0.043,0-0.08,0.007-0.125,0.007C53.773,156.001,49.894,156,46.015,156H14v-18h13.191c-0.062-7-0.07-3.128-0.062-6.869
    c0.049-21.658-0.032-43.374-0.042-65.032c0-0.898-0.088-1.685-0.088-2.283V60H15.241H14V43h49.655c0,0,4.589,0.33,6.282,0.556
    c9.201,1.227,15.691,4.981,22.522,10.892c13.667,11.827,14.931,39.501-7.313,51.901c-3.984,2.221-3.951,2.259-1.711,6.217
    c4.267,7.538,8.625,15.156,12.706,22.794c0.588,1.101,0,0.64,1.441,2.64H111V156z M177.83,146.843
    c-5.117,5.654-11.642,8.798-19.026,10.037c-12.765,2.141-24.881,0.486-35.479-7.501c-4.897-3.691-8.568-8.335-7.92-15.086
    c0.269-2.803,1.578-4.938,4.305-5.826c2.384-0.776,4.606-0.232,6.163,1.866c1.735,2.338,1.882,5.222,0.063,7.161
    c-3.191,3.401-0.987,5.188,1.417,7.059c6.828,5.315,15.239,7.744,23.234,7.49c7.421-0.995,10.051-1.593,14.535-3.554
    c14.957-6.542,18.412-27.748,3.732-37.315c-5.507-3.589-11.671-6.191-17.615-9.079c-5.59-2.716-11.459-4.898-16.899-7.875
    c-10.897-5.961-16.492-15.122-14.689-27.783c1.75-12.286,9.6-19.341,21.097-22.548c10.905-3.041,21.462-1.831,31.359,3.836
    c4.191,2.4,7.791,5.565,9.545,10.161c0.832,2.18,1.386,4.889,0.837,7.046c-0.48,1.884-2.433,4.232-4.192,4.717
    c-1.754,0.483-4.82-0.509-5.94-1.933c-1.126-1.431-1.251-4.427-0.603-6.323c1.642-4.803,1.799-5.47-2.647-8.068
    c-9.583-5.598-19.734-6.728-29.986-2.351c-15.54,6.636-17.785,25.311-4.157,35.22c4.639,3.373,10.167,5.553,15.369,8.112
    c7.266,3.575,14.978,6.392,21.847,10.599C189.659,115.612,187.095,136.606,177.83,146.843z`,
  ],
}

export default faRepairShopr
