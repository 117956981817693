export const formatLinuxInstaller = packageType => {
  let linuxInstaller = "LINUX"
  if (packageType?.architecture.value) linuxInstaller += `_${packageType?.architecture.value}`
  if (packageType?.package.value) linuxInstaller += `_${packageType.package.value}`
  return linuxInstaller
}

export const getInstallerType = (installerType, packageType) => {
  switch (installerType) {
    case "MAC":
      return packageType.value
    case "LINUX":
      return formatLinuxInstaller(packageType)
    default:
      return installerType
  }
}
