import { path, pathEq, compose, when, includes } from "ramda"
import { localizationKey, localized, localizedF } from "js/includes/common/utils/ssrAndWebUtils"

export const hasWampError = pathEq(["args", 0], "ERROR")
export const wampLiveIsDisabled = pathEq(["args", 0], "DISABLED")

const ignorableWampErrors = ["wamp.error.no_such_procedure", "wamp.error.canceled"]

const wampErrorsMapper = {
  access_denied: localizationKey("Access denied"),
  agent_connection_lost: localizationKey("Agent connection lost"),
  errorFetchingBucketsList: localizationKey("Error fetching buckets list"),
  errorWhileFetchingData: localizationKey("Error while fetching data"),
  failed_create_folder: localizationKey("Failed to create folder"),
  failed_create_key: localizationKey("Failed to create key"),
  failed_delete_key: localizationKey("Failed to delete key"),
  failed_delete_param: localizationKey("Failed to delete param"),
  failed_get_dirlist: localizationKey("Failed to get directory"),
  failed_get_key_params: localizationKey("Failed to get key params"),
  failed_get_subkeys: localizationKey("Failed to get subkeys"),
  failed_get_volumes: localizationKey("Failed to get volumes"),
  failed_query_value: localizationKey("Failed to query value"),
  failed_rename_key: localizationKey("Failed to rename key"),
  failed_rename_object: localizationKey("Failed to rename object"),
  failed_set_value: localizationKey("Failed to set value"),
  file_already_exist: localizationKey("File already exists"),
  file_not_found: localizationKey("File not found"),
  folder_already_exist: localizationKey("Folder already exists"),
  folder_not_found: localizationKey("Folder not found"),
  key_already_exist: localizationKey("Key already exists"),
  key_exist: localizationKey("Key already exists"),
  no_such_procedure: localizationKey("Failed to connect to device"),
  param_exist: localizationKey("Param already exists"),
  root_key_empty: localizationKey("Root key empty"),
}

export function isReportableWampException(error) {
  const reportableWampException = !ignorableWampErrors.includes(error?.error) && error?.name !== "SESSION ERROR"
  if (!reportableWampException) {
    console.log("Wamp exception marked as non reportable:", error)
  }
  return reportableWampException
}

export const getLocalizedWampError = error => {
  if (error?.error === "wamp.error.no_such_procedure") {
    switch (window?.controller?.attributes?.activeTab?.attributes?.subTab?.attributes?.type) {
      case "DeviceRemoteToolsTabModel":
        return localized("Remote tools unavailable")
      default:
        return localized("Error")
    }
  } else {
    return compose(
      when(includes("<<"), localizedF("Error")),
      error => localized(wampErrorsMapper[error] ?? error ?? localizationKey("Error")),
      path(["args", 1]),
    )(error)
  }
}

export const getCurrentSubscription = channel =>
  window.wamp.globalSubscriptions.find(({ topic }) => topic.includes(channel))
export const unSubscribeFromWamp = sub => sub && window.wamp.unSubscribeToChannel(sub)
