import { NinjaResponseError } from "js/includes/common/types"
import { fetchJson } from "js/includes/common/utils"

export const submitJob = async ({ jobNodes, nodeId, jobs }) => {
  try {
    const body = JSON.stringify({ nodes: jobNodes || [{ nodeId, jobs }] })
    const response = await fetchJson("/webapp/submit/job", {
      options: {
        method: "POST",
        body,
      },
    })
    if (response.resultCode !== "SUCCESS") {
      throw new NinjaResponseError(response)
    }
  } catch (error) {
    throw error
  }
}

export const getJobByUid = jobUid => fetchJson(`/v2/job/${jobUid}`)
