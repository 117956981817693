import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const statusesKeys = {
  ACTIVATED: localizationKey("Activated"),
  ACTIVATION_FAILED: localizationKey("Activation Failed"),
  ACTIVATION_REQUESTED: localizationKey("Activation Requested"),
  CANCELED: localizationKey("Canceled"),
  COMPLETED: localizationKey("Completed"),
  COMPLETED_WITH_WARNINGS: localizationKey("Completed with Warnings"),
  DEACTIVATED: localizationKey("Deactivated"),
  DEACTIVATION_FAILED: localizationKey("Deactivation Failed"),
  DEACTIVATION_REQUESTED: localizationKey("Deactivation Requested"),
  DELETED: localizationKey("Deleted"),
  DISABLED: localizationKey("Disabled"),
  DOWNLOAD_FAILED: localizationKey("Download Failed"),
  DOWNLOAD_STARTED: localizationKey("Download Started"),
  DOWNLOADED: localizationKey("Downloaded"),
  ENABLED: localizationKey("Enabled"),
  FAILED: localizationKey("Failed"),
  INSTALL_FAILED: localizationKey("Install Failed"),
  INSTALL_STARTED: localizationKey("Install Started"),
  INSTALLED: localizationKey("Installed"),
  NOT_ACTIVATED: localizationKey("Not Activated"),
  PROCESSING: localizationKey("Processing"),
  PROVISIONED: localizationKey("Provisioned"),
  PROVISIONING_FAILED: localizationKey("Provisioning Failed"),
  PROVISIONING_REQUESTED: localizationKey("Provisioning Requested"),
  REQUESTED: localizationKey("Requested"),
  RUNNING: localizationKey("Running"),
  SYNC_FAILURE: localizationKey("Sync Failure"),
  UNINSTALL_FAILED: localizationKey("Uninstall Failed"),
  UNINSTALL_REQUESTED: localizationKey("Uninstall Requested"),
  UNINSTALLED: localizationKey("Uninstalled"),
  SUCCESS: localizationKey("Success"),
  WARNINGS: localizationKey("Warnings"),
}

export const statusesTokenMapper = status => {
  return statusesKeys[status] ?? localizationKey("Unknown")
}
