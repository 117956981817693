import { useCallback, useEffect } from "react"
import { EnglishTextToken, localizationKey, localized, reportErrorAndShowMessage } from "js/includes/common/utils"
import { scheduledDeleteDetailsColumns } from "js/includes/systemDashboard/backups/ScheduledDeletes/common"
import { cancelScheduledDelete, getScheduledDeleteDetail } from "js/includes/common/backup"
import { useMountedState } from "js/includes/common/hooks"
import BackupConfirmationModal from "./BackupConfirmationModal"
import { MfaValidationError } from "js/includes/common/types"
import { ResetDataFnType } from "js/includes/systemDashboard/backups/ScheduledDeletes/types"

type ConfirmCancelBackupDeleteProps = {
  id: number
  resetData: ResetDataFnType
  unmount?: () => void
}

const ConfirmCancelBackupDelete = ({ id, resetData, unmount }: ConfirmCancelBackupDeleteProps) => {
  const [detailsData, setDetailsData] = useMountedState([])
  const [loading, setLoading] = useMountedState(false)

  const handleConfirm = async ({ unmount }: { unmount: () => void }) => {
    try {
      await cancelScheduledDelete(id)
      resetData()
      unmount()
    } catch (error) {
      if (!(error as MfaValidationError).isHandledMfaError) {
        reportErrorAndShowMessage(error, localizationKey("Error cancelling" as EnglishTextToken))
      }
    }
  }

  const fetchScheduledDeleteDetail = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getScheduledDeleteDetail(id)
      setDetailsData(response)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error getting scheduled delete detail" as EnglishTextToken))
    } finally {
      setLoading(false)
    }
  }, [id, setDetailsData, setLoading])

  useEffect(() => {
    fetchScheduledDeleteDetail()
  }, [fetchScheduledDeleteDetail])

  return (
    <BackupConfirmationModal
      {...{
        titleToken: localizationKey("Cancel delete data" as EnglishTextToken),
        descriptionText: localized(
          "This action will stop backup data from being deleted from the following {{quantity}} item(s)" as EnglishTextToken,
          {
            quantity: detailsData.length,
          },
        ),
        actionToken: localizationKey("Cancel Delete" as EnglishTextToken),
        onConfirm: handleConfirm,
        onCancel: ({ unmount }: { unmount: () => void }) => {
          unmount()
        },
        unmount,
        data: detailsData,
        columns: scheduledDeleteDetailsColumns,
        disabled: loading,
      }}
    />
  )
}

export default ConfirmCancelBackupDelete
