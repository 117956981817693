import { fetchJson, fetch, upload } from "js/includes/common/utils/fetch"
import { startLoading, stopLoading } from "js/state/actions/common/loading"

export const setScripts = (scripts, scriptCategories) => ({
  type: "SET_SCRIPTS",
  scripts,
  scriptCategories,
})

const deleteScript = script => ({
  type: "DELETE_SCRIPT",
  script,
})

export const createScript = script => ({
  type: "CREATE_SCRIPT",
  script,
})

export const updateScript = script => ({
  type: "UPDATE_SCRIPT",
  script,
})

export const requestAllScripts = () => async (dispatch, getState) => {
  try {
    dispatch(startLoading("scriptsLoading")())
    const scripts = await fetchJson("/scripting/scripts")
    const {
      scripting: { scriptCategories },
    } = getState()
    return dispatch(setScripts(scripts, scriptCategories))
  } catch (err) {
    throw err
  } finally {
    dispatch(stopLoading("scriptsLoading")())
  }
}

export const saveScript = async script => {
  const newScript = await fetchJson("/scripting/scripts", {
    options: {
      method: "POST",
      body: JSON.stringify(script),
    },
  })

  return newScript
}

export const requestCreateScript = script => async dispatch => {
  try {
    const newScript = await saveScript(script)

    dispatch(createScript(newScript))

    return newScript
  } catch (err) {
    throw err
  }
}

export const requestUpdateScript = script => async dispatch => {
  try {
    const updatedScript = await fetchJson("/scripting/scripts", {
      options: {
        method: "PUT",
        body: JSON.stringify(script),
      },
    })

    dispatch(updateScript(updatedScript))

    return updatedScript
  } catch (err) {
    throw err
  }
}

export const requestDeleteScript = script => async dispatch => {
  try {
    await fetch(`/scripting/scripts/${script.id}`, {
      options: {
        method: "DELETE",
      },
    })

    dispatch(deleteScript(script))
  } catch (err) {
    throw err
  }
}

export const uploadFileAttachmentAutomation = (file, handleProgress, handleSuccess) => {
  const { xhr, promise } = upload(`/attachments/tmp/upload-file/AUTOMATION`, {
    body: { file },
    onProgressHandler: _progress => {
      const progress = (_progress.loaded * 100) / _progress.total
      handleProgress?.(progress)
    },
    onSuccessHandler: handleSuccess,
  })

  return { xhr, promise }
}

export const saveUrlAttachmentAutomation = async url => {
  return await fetchJson("/attachments/attachment/process-url-attachment/AUTOMATION/0", {
    options: {
      method: "POST",
      body: url,
    },
  })
}

export const saveScanAttachmentAutomation = async (resourceId, data) => {
  return await fetchJson(`/attachments/attachment/process-attachment/${resourceId}/AUTOMATION/0`, {
    options: {
      method: "POST",
      body: JSON.stringify(data),
    },
  })
}
