import { fetchJson, getVersionHash } from "js/includes/common/utils"
import { AvailableLocales, DataspecCollection } from "js/includes/common/_models.js"
import { setValue } from "js/state/actions/common/value"

export default async function loadServerDefaults() {
  const {
    security,
    defaultDivisionConfig,
    dataSpecs,
    locales,
    oob,
    policyActivities,
    avKnownExtensions,
  } = await fetchJson(`/webapp/serverdefaults?cacheKey=${getVersionHash()}`)

  const dataspecCollection = new DataspecCollection(dataSpecs)
  dataspecCollection.forEach(dataspec => {
    const dataspecName = dataspec.get("dataspecName")
    window.dataspecs[dataspecName] = dataspec
  })

  window.availableLocales = new AvailableLocales(locales)
  window.avKnownExtensions = avKnownExtensions
  window.oob = oob
  window.policyActivities = policyActivities
  window.store.dispatch(setValue("application.defaultDivisionConfig")(defaultDivisionConfig))
  window.store.dispatch(setValue("application.security")(security))
}
