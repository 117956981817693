import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Box } from "js/includes/components/Styled"
import SectionTitle from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/appleCommon/SectionTitle"
import { localizationKey } from "js/includes/common/utils"
import {
  SelfInstallSelector,
  BetaInstallSelector,
  DelaySelfInstallUpdates,
  AllowRsrCheckbox,
  RemoveRsrCheckbox,
  NotificationUpdateCheckbox,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/ios/OSUpdates/userBehaviorComponents"
import { osTabGridColumns } from "./formCommons"

const basePath = "content.patchManagement.userBehavior"

const UserBehaviorSection = ({ userBehavior, onPolicyChange }) => {
  const {
    recommendedCadenceSettings,
    allowBetaProgramEnrollmentSettings,
    forceDelayedSoftwareUpdatesSettings,
    allowRsrInstallationSettings,
    allowRsrRemovalSettings,
    updateNotificationsSettings,
  } = userBehavior

  const onSettingsChange = ({ setting, settingPath }) => {
    onPolicyChange(`userBehavior.${settingPath}`, setting)
  }

  return (
    <Box padding={tokens.spacing[6]}>
      <SelfInstallSelector
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: recommendedCadenceSettings,
          onChange: setting => {
            onSettingsChange({ setting, settingPath: "recommendedCadenceSettings" })
          },
          itemPath: `${basePath}.recommendedCadenceSettings`,
          LabelComponent: () => (
            <SectionTitle
              titleToken={localizationKey("User behavior")}
              tooltipToken={localizationKey(
                "Control when and how users can self-install iOS updates on their own devices. Requires a supervised device.",
              )}
            />
          ),
        }}
      />
      <BetaInstallSelector
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: allowBetaProgramEnrollmentSettings,
          onChange: setting => onSettingsChange({ setting, settingPath: "allowBetaProgramEnrollmentSettings" }),
          itemPath: `${basePath}.allowBetaProgramEnrollmentSettings`,
        }}
      />
      <DelaySelfInstallUpdates
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: forceDelayedSoftwareUpdatesSettings,
          onChange: setting => onSettingsChange({ setting, settingPath: "forceDelayedSoftwareUpdatesSettings" }),
          itemPath: `${basePath}.forceDelayedSoftwareUpdatesSettings`,
        }}
      />
      <AllowRsrCheckbox
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: allowRsrInstallationSettings,
          onChange: setting => onSettingsChange({ setting, settingPath: "allowRsrInstallationSettings" }),
          itemPath: `${basePath}.allowRsrInstallationSettings`,
        }}
      />
      <RemoveRsrCheckbox
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: allowRsrRemovalSettings,
          onChange: setting => onSettingsChange({ setting, settingPath: "allowRsrRemovalSettings" }),
          itemPath: `${basePath}.allowRsrRemovalSettings`,
        }}
      />
      <NotificationUpdateCheckbox
        {...{
          gridColumns: osTabGridColumns,
          inheritableItem: updateNotificationsSettings,
          onChange: setting => onSettingsChange({ setting, settingPath: "updateNotificationsSettings" }),
          itemPath: `${basePath}.updateNotificationsSettings`,
        }}
      />
    </Box>
  )
}

export default UserBehaviorSection

UserBehaviorSection.propTypes = {
  userBehavior: PropTypes.object.isRequired,
  onPolicyChange: PropTypes.func.isRequired,
}
