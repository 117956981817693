import { useMemo } from "react"
import PropTypes from "prop-types"
import { find, keys, propEq } from "ramda"

import { AlertMessage, ButtonGroup, Modal } from "@ninjaone/components"

import { triggerUpdateMobilePolicy } from "js/state/actions/policyEditor/editor"
import { isAppleMobileDevice, localizationKey, localized } from "js/includes/common/utils"
import showModal from "js/includes/common/services/showModal"
import {
  hasOverridden,
  isNinjaOneAssistAppActive,
  isPasswordUnspecifiedScopeInUse,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { PasswordPolicyScope } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/enums"
import { canViewMDMConnection } from "js/includes/configuration/integrations/mdm/permissions"
import { MDMPermissionType } from "js/includes/configuration/integrations/mdm/constants"
import { isInputEmptyOrHasValidPackageName } from "./android/util"

/**
 * @enum {string}
 */
const AlertTypes = {
  DEPRECATED_UNSPECIFIED_SCOPE: "DEPRECATED_UNSPECIFIED_SCOPE",
  MISSING_AE_PERMISSIONS: "MISSING_AE_PERMISSIONS",
  MISSING_N1A_APP: "MISSING_N1A_APP",
  INVALID_PACKAGE_NAME: "INVALID_PACKAGE_NAME",
}

const alertInfo = () => ({
  [AlertTypes.DEPRECATED_UNSPECIFIED_SCOPE]: {
    titleToken: localizationKey("Unspecified passcode scopes are deprecated"),
    conditionMessages: {
      isParent: localized(
        "This policy's passcode unspecified scope will be removed on save, please make sure to use device or profile scopes instead.",
      ),
      isChildWithoutParentSetting: localized(
        "This child policy's passcode unspecified scope will be removed on save, since the parent doesn't have any unspecified scope, it will be completely removed. Please make sure to use device or profile scopes instead.",
      ),
      isChildWithParentSetting: localized(
        "This child policy's passcode unspecified scope will be removed on save, but it will keep any settings present on the parent policy. Please update the parent policy to ensure removal of deprecated scope.",
      ),
    },
  },
  [AlertTypes.MISSING_AE_PERMISSIONS]: {
    titleToken: localizationKey("Insufficient Android Enterprise permissions"),
    conditionMessages: {
      default: localizationKey(
        "This policy references 1 or more Android Enterprise connections that you do not have access to. This policy cannot be saved at this time. Reach out to your system admin to get access.",
      ),
    },
  },
  [AlertTypes.MISSING_N1A_APP]: {
    titleToken: localizationKey("NinjaOne Assist app is missing"),
    conditionMessages: {
      default: localizationKey(
        "This policy is enabling location tracking while the NinjaOne Assist app is not active. Either add the NinjaOne Assist app or disable location tracking so you are able to save the policy changes.",
      ),
    },
  },

  [AlertTypes.INVALID_PACKAGE_NAME]: {
    titleToken: localizationKey("Invalid package name"),
    conditionMessages: {
      default: localizationKey(
        "The package name for configure always-on VPN package is invalid. Please enter a valid package name.",
      ),
    },
  },
})

/**
 * Get list of alerts from policy, if any.
 * @param {Object} policy - MDM policy
 * @returns {{type: AlertType, blockSave?: boolean, conditions: {[key: string]: boolean}}[]}
 */
const policyAlertsList = policy => {
  const alerts = []

  const isParent = policy.parentPolicyId === null

  // Check for deprecated unspecified scope alerts
  const hasDefinedUnspecifiedScope = isPasswordUnspecifiedScopeInUse(policy?.content?.passcodeRules)

  if (hasDefinedUnspecifiedScope) {
    const isChildWithUnspecifiedScopeDefined =
      !isParent && hasOverridden(policy.content.passcodeRules[PasswordPolicyScope.SCOPE_UNSPECIFIED])

    if (isParent || isChildWithUnspecifiedScopeDefined) {
      const parentPolicy = window.store.getState().policyEditor.parentPolicy
      const parentHasDefinedUnspecified = isPasswordUnspecifiedScopeInUse(parentPolicy?.content?.passcodeRules)

      alerts.push({
        type: AlertTypes.DEPRECATED_UNSPECIFIED_SCOPE,
        conditions: {
          isParent,
          isChildWithParentSetting: isChildWithUnspecifiedScopeDefined && parentHasDefinedUnspecified,
          isChildWithoutParentSetting: isChildWithUnspecifiedScopeDefined && !parentHasDefinedUnspecified,
        },
      })
    }
  }

  const hasFullAEPermissions = Object.values(
    policy.content.applications?.settingsByApplication ?? {},
  ).every(({ connectionId }) =>
    !!connectionId ? canViewMDMConnection(MDMPermissionType.Android, { entityId: connectionId }) : true,
  )

  if (!hasFullAEPermissions) {
    alerts.push({
      type: AlertTypes.MISSING_AE_PERMISSIONS,
      blockSave: true,
      conditions: {
        default: true,
      },
    })
  }

  if (
    isAppleMobileDevice(policy.nodeClass) &&
    policy.content.findDevice.generalSettings.enabled &&
    !isNinjaOneAssistAppActive(policy.content.application.applications)
  ) {
    alerts.push({
      type: AlertTypes.MISSING_N1A_APP,
      blockSave: true,
      conditions: {
        default: true,
      },
    })
  }

  const hasValidPackageName = isInputEmptyOrHasValidPackageName(
    policy.content?.applications?.alwaysOnVpnPackageSettings?.alwaysOnVpnPackage?.packageName ?? "",
  )

  if (!hasValidPackageName) {
    alerts.push({
      type: AlertTypes.INVALID_PACKAGE_NAME,
      blockSave: true,
      conditions: {
        default: true,
      },
    })
  }

  return alerts
}

export const needsOnSaveAlertModal = (policy, dispatch) => {
  const alerts = policyAlertsList(policy)
  const hasAlerts = alerts.length > 0

  if (hasAlerts) {
    showModal(<OnSaveAlertModal {...{ alerts, policy, dispatch }} />)
  }

  return hasAlerts
}

const findTrueCondition = conditions => find(key => propEq(key, true, conditions), keys(conditions))

/**
 * @param {Object} props
 * @param {{type: AlertType, blockSave?: boolean, conditions: {[key: string]: boolean}}[]} props.alerts
 * @param {Object} props.policy
 * @param {Function} props.dispatch
 * @param {Function} props.unmount
 * @returns {JSX.Element}
 */
const OnSaveAlertModal = ({ alerts, policy, dispatch, unmount }) => {
  const hasBlockSaveAlert = useMemo(() => alerts.some(({ blockSave }) => !!blockSave), [alerts])

  const alertMessages = useMemo(() => {
    return alerts.map(alert => {
      const { type, conditions } = alert
      const currentAlertInfo = alertInfo()[type]

      return {
        ...alert,
        title: currentAlertInfo.titleToken,
        message: currentAlertInfo.conditionMessages[findTrueCondition(conditions)],
      }
    })
  }, [alerts])

  return (
    <Modal
      heading={hasBlockSaveAlert ? localized("Unable to save") : localized("Confirm save")}
      cancelable={false}
      withCloseX={false}
      buttonRenderer={() => (
        <ButtonGroup
          buttons={[
            {
              labelToken: hasBlockSaveAlert ? localizationKey("Close") : localizationKey("Cancel"),
              onClick: () => {
                unmount()
              },
              variant: "secondary",
            },
            ...(hasBlockSaveAlert
              ? []
              : [
                  {
                    labelToken: localizationKey("Save"),
                    type: "save",
                    onClick: async () => {
                      await triggerUpdateMobilePolicy(policy, dispatch, unmount)
                    },
                  },
                ]),
          ]}
        />
      )}
    >
      {alertMessages.map(({ type, title, message }) => (
        <AlertMessage key={type} titleToken={title}>
          {message}
        </AlertMessage>
      ))}
    </Modal>
  )
}

OnSaveAlertModal.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.keys(AlertTypes)).isRequired,
      blockSave: PropTypes.bool,
      conditions: PropTypes.objectOf(PropTypes.bool).isRequired,
    }),
  ),
  policy: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  unmount: PropTypes.func,
}

export default OnSaveAlertModal
