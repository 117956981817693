import { reportErrorAndShowMessage, localizationKey } from "js/includes/common/utils"
import { fetchJson } from "js/includes/common/utils"

const RUNBOOKS_KB_VIEW_PATH = "#/knowledgeBase/view"
const SYSTEM_DASHBOARD_KB_PATH = "#/systemDashboard/knowledgeBase"

export function isRunbookKbViewPath() {
  return window.location.hash.startsWith(RUNBOOKS_KB_VIEW_PATH)
}

export function isEndUserRedirectToKb() {
  return window.location.hash.startsWith(SYSTEM_DASHBOARD_KB_PATH)
}

export async function handleTechnicianKnowledgeBaseViewRedirect() {
  let clientId = null

  if (!isRunbookKbViewPath()) return

  try {
    const fileId = window.location.hash.split(`${RUNBOOKS_KB_VIEW_PATH}/`)[1]
    const path = await fetchJson(`/knowledgebase/path/${fileId}?file=true`)
    const folderIds = path.map(folder => folder.id)

    clientId = path[0].clientId

    if (clientId) {
      window.location.href = `#/customerDashboard/${clientId}/documentation/knowledgeBase/${folderIds.join("/")}/file`
    } else {
      window.location.href = `#/systemDashboard/knowledgeBase/${folderIds.join("/")}/file`
    }
  } catch (err) {
    reportErrorAndShowMessage(err, localizationKey("Article not found"))

    if (clientId) {
      window.location.href = `#/customerDashboard/${clientId}/documentation/knowledgeBase`
    } else {
      window.location.href = `#/systemDashboard/knowledgeBase`
    }
  }
}

export async function handleEndUserKnowledgeBaseViewRedirect() {
  try {
    const path = window.location.hash.split(`${SYSTEM_DASHBOARD_KB_PATH}/`)[1]

    window.location.href = `eus/#/documentation/${path}`
  } catch (err) {
    reportErrorAndShowMessage(err, localizationKey("Article not found"))

    window.location.href = "eus/#/documentation"
  }
}
