// We are incorrectly defining the binary measurements (e.g. MB is different from MiB)
export const bytesPrefixMapper = {
  KiB: "KB",
  MiB: "MB",
  GiB: "GB",
  TiB: "TB",
}

export function toBytes(value: number, prefix: "KiB" | "MiB" | "GiB" | "TiB") {
  return getMultiplierByPrefix(bytesPrefixMapper[prefix]) * value
}

export function bytes(value, fractionDigits = 2) {
  const kb = 1024
  const mb = 1024 * 1024
  const gb = 1024 * 1024 * 1024
  if (value < mb) {
    return `${(value / kb).toFixed(fractionDigits)} KB`
  } else if (value < gb) {
    return `${(value / mb).toFixed(fractionDigits)} MB`
  } else {
    return `${(value / gb).toFixed(fractionDigits)} GB`
  }
}

//Convert from bytes number to readable representation with prefix
export function convertBytes(bytes) {
  bytes /= 1024 * 1024 * 1024 * 1024
  if (bytes >= 1) {
    return { prefix: "TB", value: bytes }
  }
  bytes *= 1024
  if (bytes >= 1) {
    return { prefix: "GB", value: bytes }
  }
  bytes *= 1024
  if (bytes >= 1) {
    return { prefix: "MB", value: bytes }
  }
  bytes *= 1024
  return { prefix: "KB", value: bytes }
}

//Convert from bytes number to readable representation with prefix
export function getMultiplierByPrefix(prefix) {
  switch (prefix) {
    case "KB":
      return 1024
    case "MB":
      return 1024 * 1024
    case "GB":
      return 1024 * 1024 * 1024
    case "TB":
      return 1024 * 1024 * 1024 * 1024
    default:
      return 1
  }
}
