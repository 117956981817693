//@flow
import { fetchJson, ninjaReportError, reportErrorAndShowMessage, showErrorMessage } from "js/includes/common/utils"
import { isNotNilOrEmpty, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { NinjaResponseError } from "js/includes/common/types"
import { cond, equals, always, T } from "ramda"
import type { PatchType, Patch } from "js/state/types"

export const getPatchColor = cond([
  [equals("available"), always("info")],
  [equals("pending"), always("warning")],
  [equals("approved"), always("info")],
  [equals("rejected"), always("info")],
  [equals("failed"), always("danger")],
  [equals("installed"), always("success")],
  [
    T,
    patchType => {
      throw new Error(`'${patchType}' is an invalid value`)
    },
  ],
])

export const getNewPatchColorName = cond([
  [equals("pending"), always("colorAlertWarning")],
  [equals("rejected"), always("colorAlertWarningStrong")],
  [equals("failed"), always("colorAlertError")],
  [equals("installed"), always("colorAlertSuccess")],
  [T, always("colorDataViz1")],
])

export const isPatchSelectable = (selectedPatchType: string) =>
  ["pending", "failed", "approved", "rejected", "installed"].includes(selectedPatchType)

export const singlePatchOverrideCall = (
  entity: string,
  entityId: number,
  patchId: string,
  patchName: string,
  action: string,
  patchType: PatchType,
  kbNumber,
  type,
) => patchOverrideCall(entity, entityId, [{ id: patchId, kbNumber, name: patchName, type }], action, patchType)

const getMDMprops = (enforcementDate, approvalDate, rejectionDate) =>
  cond([
    [() => isNotNilOrEmpty(approvalDate), always({ approvalDate, enforcementDate })],
    [() => isNotNilOrEmpty(rejectionDate), always({ rejectionDate })],
    [T, always({})],
  ])()

export const patchOverrideCall = (
  entity: string,
  entityId: number,
  patches: Array<Patch>,
  action: string,
  patchType: PatchType,
) => {
  const patchRequestData = JSON.stringify(
    patches.map(({ id, kbNumber, name, title, type, version, enforcementDate, approvalDate, rejectionDate }) => {
      const isMDMUpdate = isNotNilOrEmpty(rejectionDate) || isNotNilOrEmpty(approvalDate)
      return {
        ...(kbNumber && { kbNumber }),
        ...(id && { identifier: id }),
        name: isMDMUpdate ? name || title : `${name || title}${version ? ` ${version}` : ""}`,
        ...(type && { type }),
        ...(isMDMUpdate && {
          ...getMDMprops(enforcementDate, approvalDate, rejectionDate),
          version,
        }),
      }
    }),
  )
  return fetchJson(`/${entity}/${entityId}/${patchType}/${action}`, {
    options: {
      method: "POST",
      body: patchRequestData,
    },
  })
}

export const approveOrRejectAllSelectedDevices = async (
  selectedOsPatchObjects: Array<Patch>,
  entityId: number,
  startProcessingDevices: () => void,
  stopProcessingDevices: () => void,
  requestSelectPatchType: string => void,
  entity: string,
  action: string,
  selectedPatchType: string,
  patchType: PatchType,
  clearAllItems: () => void,
) => {
  try {
    startProcessingDevices()
    const response = await patchOverrideCall(entity, entityId, selectedOsPatchObjects, action, patchType)
    if (response.resultCode !== "SUCCESS") {
      throw new NinjaResponseError(response)
    }
    requestSelectPatchType(selectedPatchType)
  } catch (error) {
    if (error?.response?.status === 409 && error?.resultCode) {
      showErrorMessage(error.resultCode)
      ninjaReportError(error)
    } else {
      reportErrorAndShowMessage(error, localizationKey("Error saving patch override"))
    }
  } finally {
    stopProcessingDevices()
    clearAllItems()
  }
}
