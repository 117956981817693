export const getDataVizColorNameFromIndex = index => {
  const oneBasedIndex = index + 1
  switch (oneBasedIndex) {
    case 1:
      return "colorDataViz1Background"
    case 2:
      return "colorDataViz2Background"
    case 3:
      return "colorDataViz3Background"
    case 4:
      return "colorDataViz4Background"
    case 5:
      return "colorDataViz5Background"
    case 6:
      return "colorDataViz6Background"
    case 7:
      return "colorDataViz7Background"
    case 8:
      return "colorDataViz8Background"
    case 9:
      return "colorDataViz9Background"
    case 10:
      return "colorDataViz10Background"
    default:
      return "colorDataViz1Background"
  }
}

export const formatWidthOrHeightForRecharts = value => {
  if (value.endsWith("px")) {
    return parseInt(value.replace(/px$/, ""), 10)
  }

  return value
}
