import { fetchJson } from "js/includes/common/utils"

export function getNinjaPSATimeEntries(payload) {
  return fetchJson(`/psa/time-entry`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}

type UpdateNinjaPSATimeEntriesArgs = {
  agreementId: number | null,
  productAgreementId: number | null,
  agreementOriginType: "SELF" | "NONE" | "TICKET",
  ticketLogEntryIds: number[],
}

export function updateNinjaPSATimeEntries({
  agreementId,
  productAgreementId,
  agreementOriginType,
  ticketLogEntryIds,
}: UpdateNinjaPSATimeEntriesArgs) {
  return fetchJson("/psa/time-entry/update-in-bulk", {
    options: {
      method: "POST",
      body: JSON.stringify({
        agreementId,
        productAgreementId,
        agreementOriginType,
        ticketLogEntryIds,
      }),
    },
  })
}

export function recalculateNinjaPSATimeEntryPrices({ ticketLogEntryIds }) {
  return fetchJson("/psa/time-entry/recalculate-price-in-bulk", {
    options: {
      method: "PUT",
      body: JSON.stringify({ ticketLogEntryIds }),
    },
  })
}
