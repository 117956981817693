import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { css } from "@emotion/react"
import { Tooltip, useAccessibleActionIcon } from "@ninjaone/components"
import { getMainNavStyle, mainNavTextStyle } from "./MainNavButton"
import { actionIconWrapperStyle, actionIconStyle } from "./SubNavLink"

const linkStyle = theme => css`
  &.active {
    color: ${theme.colorThemeTextStrong};
    background-color: ${theme.colorThemeBackgroundSelected};
  }

  &:not(.active):hover {
    background-color: ${theme.colorThemeBackgroundHover};
  }

  &:focus-visible {
    background-color: ${theme.colorThemeBackgroundHover};
    outline: 2px solid ${theme.colorForegroundFocus};
    outline-offset: -2px;
  }

  &:not(:focus-visible):not(:focus-within):not(:hover) {
    .action-icons {
      visibility: hidden;
    }
  }
`

const MainNavLink = ({ to, isActive, icon, label, collapsed, actionIcons }) => {
  const mainNavStyle = getMainNavStyle({ collapsed })

  const { actionIconsRenderer, parentElementProps } = useAccessibleActionIcon({
    actionIcons,
    actionIconStyle,
    showActionIconOnHover: true,
  })

  const showActionIcons = !!actionIcons?.length && !collapsed

  const navLink = (
    <NavLink
      {...{
        to,
        isActive,
        css: [mainNavStyle, linkStyle],
        "data-test-sidebar-link": "dashboard-title-link",
        "aria-label": label,
        ...parentElementProps,
      }}
    >
      <FontAwesomeIcon icon={icon} className="main-icon" data-test-sidebar-link="dashboard-title-icon" fixedWidth />
      {!collapsed && <span css={mainNavTextStyle}>{label}</span>}
      {showActionIcons && (
        <div css={actionIconWrapperStyle} className="action-icons">
          {actionIconsRenderer?.()}
        </div>
      )}
    </NavLink>
  )

  return collapsed ? (
    <Tooltip position="left" align="center" label={label}>
      {navLink}
    </Tooltip>
  ) : (
    navLink
  )
}

export default MainNavLink
