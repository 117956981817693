import { assocPath, includes, path } from "ramda"
import { connect } from "react-redux"

import { sizer } from "@ninjaone/utils"
import { Select, Stepper, Switch, TitleGroup } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { Label } from "@ninjaone/components/src/Form/Label"

import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Box, Flex } from "js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import InheritableRowPolicyItem from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"

const MAXIMUM_PASSCODE_AGE_IN_DAYS = 730

const initialValues = {
  allowSimple: false,
  maxFailedAttempts: 11,
  forcePIN: false,
  maxPINAgeInDays: 0,
  requireAlphanumeric: false,
  maxGracePeriod: "0",
  minComplexChars: 0,
  minLength: 0,
  pinHistory: 1,
  maxInactivity: "-1",
}

const getMaxGracePeriodOptions = () => [
  { value: "0", labelText: localized("No grace period") },
  { value: "1", labelText: localized("1 minute") },
  { value: "5", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 5 }) },
  { value: "10", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 10 }) },
  { value: "15", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 15 }) },
]

const maxInactivityOptions = {
  APPLE_IOS: () => [
    { value: "1", labelText: localized("1 minute") },
    { value: "2", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 2 }) },
    { value: "3", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 3 }) },
    { value: "4", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 4 }) },
    { value: "5", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 5 }) },
    { value: "-1", labelText: localized("Never") },
  ],
  APPLE_IPADOS: () => [
    { value: "2", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 2 }) },
    { value: "5", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 5 }) },
    { value: "10", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 10 }) },
    { value: "15", labelText: localized("{{durationInMinutes}} minutes", { durationInMinutes: 15 }) },
    { value: "-1", labelText: localized("Never") },
  ],
}

const PasscodeIOSForm = ({ passcode, updatePolicyItem, parentPolicy, nodeClass }) => {
  const updatePolicy = (entry, value) => {
    const [category, field] = entry.split(".")
    const newPasscodeValues = assocPath([field], value, passcode?.[category])
    updatePolicyItem(`passcode.${category}`, parentPolicy, newPasscodeValues)
  }

  const checkOptionsEnabled = inputField => {
    if (inputField === "requireAlphanumeric") {
      updatePolicy("allowSimpleSettings.allowSimple", false)
    } else if (inputField === "allowSimple") {
      updatePolicy("requireAlphanumericSettings.requireAlphanumeric", false)
    }
  }
  const onChangePasscode = (field, value) => {
    const [category, inputField] = field.split(".")
    if (value === path([category, inputField], passcode)) return
    checkOptionsEnabled(inputField)
    const numericValue = inputField === "maxInactivity" && value === "-1" ? null : Number(value)
    const newValue = includes(inputField, ["maxInactivity", "maxGracePeriod"]) ? numericValue : value
    updatePolicy(field, newValue)
  }

  const allowSimple = passcode.requireAlphanumericSettings?.requireAlphanumeric
    ? false
    : passcode.allowSimpleSettings?.allowSimple ?? initialValues.allowSimple
  const maxFailedAttempts = passcode.maxFailedAttemptsSettings?.maxFailedAttempts ?? initialValues.maxFailedAttempts
  const forcePIN = passcode.forcePINSettings?.forcePIN ?? initialValues.forcePIN
  const maxPINAgeInDays = passcode.maxPINAgeInDaysSettings?.maxPINAgeInDays ?? initialValues.maxPINAgeInDays
  const requireAlphanumeric =
    passcode.requireAlphanumericSettings?.requireAlphanumeric ?? initialValues.requireAlphanumeric
  const maxGracePeriod = (passcode.maxGracePeriodSettings?.maxGracePeriod ?? initialValues.maxGracePeriod).toString()
  const minComplexChars = passcode.minComplexCharsSettings?.minComplexChars ?? initialValues.minComplexChars
  const minLength = passcode.minLengthSettings?.minLength ?? initialValues.minLength
  const pinHistory = passcode.pinHistorySettings?.pinHistory ?? initialValues.pinHistory
  const maxInactivity = (passcode.maxInactivitySettings?.maxInactivity || initialValues.maxInactivity).toString()

  return (
    <>
      <Box padding={sizer(0, 6, 6)}>
        <TitleGroup
          titleToken={localizationKey("Passcode")}
          descriptionToken={localizationKey("Manage and define passcode settings.")}
        />
      </Box>
      <Box paddingBottom={sizer(10)} margin={sizer(2, 0, 0, 6)} flex={1} flexDirection="column" overflow="auto">
        <InheritableRowPolicyItem
          inheritableItem={passcode.forcePINSettings}
          pathToItem="passcode.forcePINSettings"
          wrapperProps={{ padding: `${tokens.spacing[7]} ${tokens.spacing[2]}` }}
        >
          <Switch
            checked={forcePIN}
            onChange={checked => onChangePasscode("forcePINSettings.forcePIN", checked)}
            labelToken={localizationKey("Enabled")}
          />
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.requireAlphanumericSettings}
          pathToItem="passcode.requireAlphanumericSettings"
          testId="requireAlphanumericSettings"
          wrapperProps={{ padding: `${tokens.spacing[7]} ${tokens.spacing[2]}` }}
        >
          <Switch
            checked={requireAlphanumeric}
            onChange={checked => onChangePasscode("requireAlphanumericSettings.requireAlphanumeric", checked)}
            labelToken={localizationKey("Require alphanumeric value")}
            disabled={!forcePIN}
          />
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.allowSimpleSettings}
          pathToItem="passcode.allowSimpleSettings"
          wrapperProps={{ padding: `${tokens.spacing[7]} ${tokens.spacing[2]}` }}
        >
          <Switch
            checked={allowSimple}
            onChange={checked => onChangePasscode("allowSimpleSettings.allowSimple", checked)}
            labelToken={localizationKey("Allow simple passcode")}
            disabled={requireAlphanumeric || !forcePIN}
          />
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.maxFailedAttemptsSettings ?? { inheritance: {} }}
          pathToItem="passcode.maxFailedAttemptsSettings"
          testId="maxFailedAttemptsSettings"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Stepper
              labelText={localized("Maximum number of failed attempts")}
              value={maxFailedAttempts}
              min={2}
              max={11}
              onChange={value => onChangePasscode("maxFailedAttemptsSettings.maxFailedAttempts", value)}
              disabled={!forcePIN}
              compact
            />
          </Flex>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.maxPINAgeInDaysSettings}
          pathToItem="passcode.maxPINAgeInDaysSettings"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Stepper
              labelText={localized("Maximum passcode age (days)")}
              value={maxPINAgeInDays}
              min={0}
              max={MAXIMUM_PASSCODE_AGE_IN_DAYS}
              onChange={value => onChangePasscode("maxPINAgeInDaysSettings.maxPINAgeInDays", value)}
              disabled={!forcePIN}
              compact
            />
          </Flex>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.maxGracePeriodSettings}
          pathToItem="passcode.maxGracePeriodSettings"
          testId="maxGracePeriodSettings"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Label labelText={localized("Maximum grace period for device lock")} labelFor="inputkey_maxGracePeriod" />
            <Select
              key="inputkey_maxGracePeriod"
              labelId="inputkey_maxGracePeriod"
              value={maxGracePeriod}
              onChange={value => onChangePasscode("maxGracePeriodSettings.maxGracePeriod", value)}
              options={getMaxGracePeriodOptions()}
              isSearchable={false}
              disabled={!forcePIN}
            />
          </Flex>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.minComplexCharsSettings}
          pathToItem="passcode.minComplexCharsSettings"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Stepper
              labelText={localized("Minimum number of complex characters")}
              value={minComplexChars}
              min={0}
              max={4}
              onChange={value => onChangePasscode("minComplexCharsSettings.minComplexChars", value)}
              disabled={!forcePIN}
              compact
            />
          </Flex>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem inheritableItem={passcode.minLengthSettings} pathToItem="passcode.minLengthSettings">
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Stepper
              labelText={localized("Minimum passcode length")}
              value={minLength}
              min={0}
              max={16}
              onChange={value => onChangePasscode("minLengthSettings.minLength", value)}
              disabled={!forcePIN}
              compact
            />
          </Flex>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.pinHistorySettings}
          pathToItem="passcode.pinHistorySettings"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Stepper
              labelText={localized("Passcode history")}
              value={pinHistory}
              min={1}
              max={50}
              onChange={value => onChangePasscode("pinHistorySettings.pinHistory", value)}
              disabled={!forcePIN}
              compact
            />
          </Flex>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          inheritableItem={passcode.maxInactivitySettings}
          pathToItem="passcode.maxInactivitySettings"
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Label labelText={localized("Maximum Auto-Lock")} labelFor="inputkey_maxInactivity" />
            <Select
              key="inputkey_maxInactivity"
              labelId="inputkey_maxInactivity"
              value={maxInactivity}
              onChange={value => onChangePasscode("maxInactivitySettings.maxInactivity", value)}
              options={maxInactivityOptions[nodeClass]()}
              isSearchable={false}
              valueSelectorKey="value"
              disabled={!forcePIN}
            />
          </Flex>
        </InheritableRowPolicyItem>
      </Box>
    </>
  )
}

export default connect(
  ({ policyEditor }) => ({
    passcode: policyEditor.policy.content.passcode,
    parentPolicy: policyEditor.parentPolicy,
    nodeClass: policyEditor.policy.nodeClass,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(PasscodeIOSForm)
