import { memo } from "react"
import { AutoSizer, List } from "react-virtualized"

import { sizer } from "@ninjaone/utils"

import {
  fetchJson,
  localizationKey,
  localized,
  reportErrorAndShowMessage,
  showSuccessMessage,
} from "js/includes/common/utils"
import { ConfirmationModal } from "js/includes/components/ConfirmationModal"
import { Box, Flex } from "js/includes/components/Styled"

const rowHeight = 22

export const NodeList = ({ data }) => (
  <AutoSizer disableHeight>
    {({ width }) => (
      <List
        width={width}
        height={Math.min(8, data.length) * rowHeight}
        rowCount={data.length}
        rowHeight={rowHeight}
        rowRenderer={({ index, key, style }) => {
          const row = data[index]
          return (
            <Box key={key} style={style}>
              {row.name}
            </Box>
          )
        }}
      />
    )}
  </AutoSizer>
)

export default memo(function ConfirmDeleteNodeDataModal({ nodeIds, nodeNames, dispatchCallback, unmount }) {
  const handleConfirm = async ({ unmount }) => {
    try {
      await fetchJson(`/backup/lockhart/nodes/delete-revisions`, {
        options: {
          method: "POST",
          body: JSON.stringify(nodeIds),
        },
      })
      showSuccessMessage(localized("Data deletion scheduled successfully"))

      dispatchCallback({ type: "REFRESH" })
      unmount()
    } catch (error) {
      reportErrorAndShowMessage(error)
    }
  }

  return (
    <ConfirmationModal
      {...{
        type: "danger",
        size: "md",
        titleToken: localizationKey("Confirm Delete Data"),
        descriptionToken: localized(
          "This action will permanently delete all backup data for the following {{count}} device(s). Data will be deleted and removed from your calculated usage in 72 hours.",
          {
            count: nodeNames.length,
          },
        ),
        actionToken: localizationKey("Delete"),
        onConfirm: handleConfirm,
        onCancel: ({ unmount }) => {
          unmount()
        },
        unmount,
      }}
    >
      <Flex gap={sizer(2)} flexDirection="column">
        <NodeList data={nodeNames} />
      </Flex>
    </ConfirmationModal>
  )
})
