import { useCallback, useEffect } from "react"
import { EnglishTextToken, localizationKey, localized, reportErrorAndShowMessage } from "js/includes/common/utils"
import { scheduledDeleteDetailsColumns } from "js/includes/systemDashboard/backups/ScheduledDeletes/common"
import { forceDeleteScheduledDelete, getScheduledDeleteDetail } from "js/includes/common/backup"
import { useMountedState } from "js/includes/common/hooks"
import BackupConfirmationModal from "./BackupConfirmationModal"
import { MfaValidationError } from "js/includes/common/types"
import { ResetDataFnType } from "js/includes/systemDashboard/backups/ScheduledDeletes/types"

type ConfirmDeleteImmediatelyProps = {
  id: number
  resetData: ResetDataFnType
  unmount?: () => void
}

//TODO: remove explicit typing when localizationKey accepts a string that's not in EnglishTextToken
const ConfirmDeleteImmediately = ({ id, resetData, unmount }: ConfirmDeleteImmediatelyProps) => {
  const [detailsData, setDetailsData] = useMountedState([])
  const [loading, setLoading] = useMountedState(false)

  const handleConfirm = async ({ unmount }: { unmount: () => void }) => {
    try {
      await forceDeleteScheduledDelete(id)
      resetData()
      unmount()
    } catch (error) {
      if (!(error as MfaValidationError).isHandledMfaError) {
        reportErrorAndShowMessage(error, localizationKey("Error deleting" as EnglishTextToken))
      }
    }
  }

  const fetchScheduledDeleteDetail = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getScheduledDeleteDetail(id)
      setDetailsData(response)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error getting scheduled delete detail" as EnglishTextToken))
    } finally {
      setLoading(false)
    }
  }, [id, setDetailsData, setLoading])

  useEffect(() => {
    fetchScheduledDeleteDetail()
  }, [fetchScheduledDeleteDetail])

  return (
    <BackupConfirmationModal
      {...{
        titleToken: localizationKey("Confirm delete data" as EnglishTextToken),
        descriptionText: localized(
          "This action will permanently delete all backup data of the following {{quantity}} item(s). Data will be deleted immediately. This action is not reversible." as EnglishTextToken,
          {
            quantity: detailsData.length,
          },
        ),
        actionToken: localizationKey("Delete"),
        onConfirm: handleConfirm,
        onCancel: ({ unmount }: { unmount: () => void }) => {
          unmount()
        },
        unmount,
        data: detailsData,
        columns: scheduledDeleteDetailsColumns,
        disabled: loading,
      }}
    />
  )
}

export default ConfirmDeleteImmediately
