//@flow
import Backbone from "backbone"
import { compose, filter, join } from "ramda"

import { isNotNilOrEmpty, logout } from "js/includes/common/utils"

const Router = Backbone.Router.extend(
  {
    routes: {
      "": "index",
    },

    getActiveTabName() {
      const activeTab = window.controller.get("activeTab")
      return activeTab && activeTab.get("name")
    },

    getActiveSubTabName() {
      const activeTab = window.controller.get("activeTab")
      const activeSubTab = activeTab && activeTab.get("subTab")
      return activeSubTab && activeSubTab.get("name")
    },

    getEntityId() {
      const activeTab = window.controller.get("activeTab")
      const activeSubTab = activeTab && activeTab.get("subTab")
      return activeSubTab && activeSubTab.get("entityId")
    },

    buildRouteId(tabName, entityId, subTabName) {
      return compose(join("."), filter(isNotNilOrEmpty))([tabName, entityId, subTabName])
    },

    isCustomerDashboardDocumentationRoute(subType, subTabName) {
      const activeTab = window.controller.get("activeTab")
      const isCustomerDashboard = activeTab?.get("name") === "customerDashboard" && subType === "customerDashboard"
      const isDocumentationSubtab = activeTab?.get("subTabName") === "documentation" && subTabName === "documentation"
      return isCustomerDashboard && isDocumentationSubtab
    },

    isGlobalKnowledgeBaseNavigation(subType, subTabName, subSubTabName) {
      const previousPath = window.controller?.get("activeTabLink")?.href

      if (previousPath?.includes("#/systemDashboard/knowledgeBase")) {
        const activeTab = window.controller.get("activeTab")
        const isGlobalKnowledgeBaseDashboard =
          activeTab?.get("name") === "systemDashboard" || subType === "systemDashboard"
        const isGlobalKnowledgeBaseSubTab = subTabName === "knowledgeBase"
        const hasSubSubTab = typeof subSubTabName === "string"
        return isGlobalKnowledgeBaseDashboard && isGlobalKnowledgeBaseSubTab && hasSubSubTab
      }

      return false
    },

    isDeviceDashboardVulnNav(subType, subTabName, subSubTabName) {
      const previousPath = window.controller?.get("activeTabLink")?.href
      if (previousPath?.includes("#/deviceDashboard/")) {
        const activeTab = window.controller.get("activeTab")
        const isVulnerabilityDashboard = activeTab?.get("name") === "deviceDashboard" || subType === "deviceDashboard"
        const isVulnerabilitySubTab = subTabName === "vulnerabilities"
        const hasSubSubTab = typeof subSubTabName === "string"
        return isVulnerabilityDashboard && isVulnerabilitySubTab && hasSubSubTab
      }
      return false
    },

    isCustomerDashboardVulnNav(subType, subTabName, subSubTabName) {
      const previousPath = window.controller?.get("activeTabLink")?.href
      if (previousPath?.includes("#/customerDashboard/")) {
        const activeTab = window.controller.get("activeTab")
        const isVulnerabilityDashboard =
          activeTab?.get("name") === "customerDashboard" || subType === "customerDashboard"
        const isVulnerabilitySubTab = subTabName === "vulnerabilities"
        const hasSubSubTab = typeof subSubTabName === "string"
        return isVulnerabilityDashboard && isVulnerabilitySubTab && hasSubSubTab
      }
      return false
    },

    isSystemDashboardVulnNav(subType, subTabName, subSubTabName) {
      const previousPath = window.controller?.get("activeTabLink")?.href
      if (previousPath === "#/systemDashboard/vulnerabilities") {
        const activeTab = window.controller.get("activeTab")
        const isVulnerabilityDashboard = activeTab?.get("name") === "systemDashboard" || subType === "systemDashboard"
        const isVulnerabilitySubTab = subTabName === "vulnerabilities"
        const hasSubSubTab = typeof subSubTabName === "string"
        return isVulnerabilityDashboard && isVulnerabilitySubTab && hasSubSubTab
      }
      return false
    },

    isActiveRouteChanged(activeTabName, entityId, activeSubTabName, subType, id, subTabName) {
      const activeRouteId = this.buildRouteId(activeTabName, entityId, activeSubTabName).split(".")[0]
      const newRouteId = this.buildRouteId(subType, id, subTabName).split(".")[0]

      return activeRouteId !== newRouteId
    },

    isSubSubTabChanged(subSubTabName) {
      const previousSubSubTabName = window.controller.get("activeTab")?.get("subSubTabName")
      return previousSubSubTabName !== subSubTabName
    },

    isActiveTabIdChanged(activeTabId) {
      const previousId = window.controller
        .get("activeTab")
        ?.get("id")
        ?.toString?.()

      return previousId !== activeTabId
    },

    isPreviousRouteDocumentation() {
      const previousSubTabName = window.controller.get("activeTab")?.get("subTabName")
      return previousSubTabName === "documentation"
    },

    initialize() {
      // Order of routes is critical. Last match wins. Thus, this generic route comes first.
      this.route(
        /^(\w+)\/?(\d+)?\/?(\w+)?\/?(\w+)?(\/\w*)*?(\?.*)?$/,
        "showDashboard",
        (subType, id, subTabName, subSubTabName) => {
          const hasRouteMainPathChanged = this.isActiveRouteChanged(
            this.getActiveTabName(),
            this.getEntityId(),
            this.getActiveSubTabName(),
            subType,
            id,
            subTabName,
          )

          const isCustomerDashboardDocumentationRoute = this.isCustomerDashboardDocumentationRoute(subType, subTabName)
          const isGlobalKnowledgeBaseNavigation = this.isGlobalKnowledgeBaseNavigation(
            subType,
            subTabName,
            subSubTabName,
          )
          const hasSubSubTabChanged = this.isSubSubTabChanged(subSubTabName)
          const hasSubtabIdChanged = this.isActiveTabIdChanged(id)
          const isComingFromWithinDocumentation = this.isPreviousRouteDocumentation()
          const isSystemDashboardVulnNav = this.isSystemDashboardVulnNav(subType, subTabName, subSubTabName)
          const isDeviceDashboardVulnNav = this.isDeviceDashboardVulnNav(subType, subTabName, subSubTabName)
          const isCustomerDashboardVulnNav = this.isCustomerDashboardVulnNav(subType, subTabName, subSubTabName)

          const shouldReloadContent =
            (hasRouteMainPathChanged ||
              (!isCustomerDashboardDocumentationRoute && !isGlobalKnowledgeBaseNavigation) ||
              (isCustomerDashboardDocumentationRoute &&
                (hasSubSubTabChanged || hasSubtabIdChanged) &&
                isComingFromWithinDocumentation)) &&
            !(isSystemDashboardVulnNav || isCustomerDashboardVulnNav || isDeviceDashboardVulnNav)

          if (shouldReloadContent) {
            return this.show(Router.RouteType.DashboardTab, subType, id, subTabName, subSubTabName)
          }
        },
      )

      this.route(/^group\/(\d+)$/, "showGroup", id => {
        this.show(Router.RouteType.GroupTab, "group", id)
      })

      this.route(
        /^administration\/(\w+)\/?(\w+)?\/?(\w+)?(\/\w*)*?$/,
        "showConfiguration",
        (subType, subTabName, subSubTabName) => {
          this.show(Router.RouteType.ConfigurationTab, subType, null, subTabName, subSubTabName)
        },
      )

      this.route(
        /^(editor|wizard)\/(\w+)\/?(\d+)?\/?([\w\d-]+)?\/?([\w\d-]+)?\/?([\w\d-]+)?$/,
        "showFullScreenModal",
        function(type, subType, id, params, childrenId) {
          this.show(Router.RouteType.FullScreenModal, subType, id, null, null, params, childrenId)
        },
      )

      this.route(/^attachment\/(view|download)\/(.*?)$/, "", function(type, attachment) {
        this.show(Router.RouteType.Attachment, type, undefined, undefined, undefined, attachment)
      })

      // this is to handle a number of URLs that were built incorrectly but still stored in external ticketing systems
      this.route(/^(view|download)\/attachment\/(.*?)$/, "", function(type, attachment) {
        this.show(Router.RouteType.Attachment, type, undefined, undefined, undefined, attachment)
      })

      this.route("logout", "logout", () => {
        logout()
      })

      // redirect invalid routes that might be reached through the browser back button
      this.route(/^(noAuth|auth|eus|$)/, "", () => {
        window.history.replaceState({ key: Date.now() + "" }, null, "/#/systemDashboard/overview")
        window.location.reload(true)
      })
    },

    // Usually modals will not be called via the router. In some cases though,
    // we need to destory the active tab. Trigerring the modal via the router allows us
    // to gracefully destroy and re-render the tab before and after the modal.
    async show(routeType, subType, id, subTabName, subSubTabName, params, childrenId) {
      // Add the current route to our history array
      window.routeHistory.push(window.location.hash)

      // Lets clean up some memory (JS and DOM)
      window.controller.clear(routeType, subType, id)

      // Sometimes we navigate from one device details to another and thus not causing an rv-if to unbind on the tab
      // Thus, re-setting the activeRoute is necessary to trigger unbinds on all views
      window.application.unset("activeRoute")

      window.application.set("activeRoute", window.location.hash)

      // Make sure the application has been initialized
      await window.isApplicationReady

      const lastRoute = window.routeHistory.length >= 2 ? window.routeHistory[window.routeHistory.length - 2] : null
      window.controller.show(routeType, subType, id, subTabName, subSubTabName, params, lastRoute, childrenId)
    },
  },
  {
    RouteType: {
      DashboardTab: 2,
      ConfigurationTab: 3,
      FullScreenModal: 4,
      Attachment: 5,
      GroupTab: 6,
    },
  },
)

export default Router
