export const QUICK_MENU_NAMES = ["apps", "add", "user"]
type QuickMenuNames = "apps" | "add" | "user"

export const openQuickMenu = (name: QuickMenuNames) => ({
  type: "OPEN_MENU",
  name,
})

export const closeQuickMenu = (name: QuickMenuNames) => ({
  type: "CLOSE_MENU",
  name,
})
