export function setActiveSearch(activeSearch) {
  return {
    type: "SET_ACTIVE_SEARCH",
    activeSearch: activeSearch,
  }
}

export function clearActiveSearch() {
  return {
    type: "CLEAR_ACTIVE_SEARCH",
  }
}
