import React, { memo } from "react"
import {
  OperatorSelect,
  UnitBytesSelect,
  DurationSelect,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"
import {
  thresholdBytesOptions,
  unitBytesOptionsNapshotShize,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"

export default memo(({ values, onChange, validation }) => {
  return (
    <>
      <OperatorSelect
        {...{
          values,
          onChange,
          validation,
        }}
      />
      <UnitBytesSelect
        {...{
          values,
          onChange,
          validation,
          thresholdBytesOptions,
          unitBytesOptions: unitBytesOptionsNapshotShize,
        }}
      />
      <DurationSelect
        {...{
          values,
          onChange,
          validation,
        }}
      />
    </>
  )
})
