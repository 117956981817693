import styled from "@emotion/styled"
import { Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { StyledButton, StyledButtonDiv } from "./Styled"

const StyledTextButton = styled(StyledButton)`
  padding: 0 ${sizer(3)};
`

const StyledTextButtonDiv = styled(StyledButtonDiv)`
  padding: ${sizer(1, 3)};
`

export const TextButton = ({ children, token, onClick, renderAsDiv, ...rest }) => {
  const StyledTextButtonElement = renderAsDiv ? StyledTextButtonDiv : StyledTextButton

  return (
    <StyledTextButtonElement onClick={onClick}>
      <Text size="sm" color="inputActiveBorder" token={token} {...rest}>
        {children}
      </Text>
    </StyledTextButtonElement>
  )
}
