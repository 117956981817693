import React, { memo } from "react"
import { connect } from "react-redux"
import { any, whereEq } from "ramda"
import { addFavoriteTab, removeFavoriteTab } from "js/state/actions/general/favoriteTabs"
import { colors } from "js/includes/common/theme"
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons"
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons"
import { localized } from "js/includes/common/utils"
import { StyledFontAwesomeIcon } from "./Styled"
import { sizer } from "@ninjaone/utils"

const FavoriteButton = memo(
  ({
    id,
    type,
    name,
    nodeClass,
    variant,
    favoriteTabs,
    addFavoriteTab,
    removeFavoriteTab,
    showTextButton,
    ButtonComponent,
    ...otherProps
  }) => {
    const isFavorite = any(whereEq({ id, type }))(favoriteTabs)
    const onClick = () => {
      if (isFavorite) {
        removeFavoriteTab({ id, type })
      } else {
        addFavoriteTab({
          item: {
            id,
            type,
            name,
            nodeClass,
            ...otherProps,
          },
          type,
        })
      }
    }

    const getColorFromVariant = variant => {
      if (variant === "blue") {
        return colors.ninjaBlueSaturated
      } else {
        return ""
      }
    }

    return ButtonComponent ? (
      <ButtonComponent {...{ isFavorite, onClick }} />
    ) : (
      <span
        className={`flex-full display-flex align-items-center pointer ${showTextButton ? "" : "m-l-sm"}`}
        onClick={onClick}
      >
        {showTextButton ? (
          <button className="btn-link">
            {isFavorite ? localized("Remove From Favorites") : localized("Add To Favorites")}
          </button>
        ) : (
          <StyledFontAwesomeIcon
            fontSize={sizer(4)}
            icon={isFavorite ? faStarSolid : faStarRegular}
            color={isFavorite ? colors.ninjaYellow : getColorFromVariant(variant)}
          />
        )}
      </span>
    )
  },
)

const mapStateToProps = ({ general: { favoriteTabs } }) => ({
  favoriteTabs,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addFavoriteTab: tab => dispatch(addFavoriteTab(tab)),
  removeFavoriteTab: tab => dispatch(removeFavoriteTab(tab)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteButton)
