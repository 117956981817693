import { Fragment } from "react"
import { connect } from "react-redux"
import { path, pathOr, assoc } from "ramda"

import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { sizer } from "@ninjaone/utils"
import { AlertMessage, Button, Select, Tags, Text, TitleGroup, RadioGroup } from "@ninjaone/components"

import { Box, Flex, StyledSpan } from "js/includes/components/Styled"
import { isAppleMobileDevice, localizationKey, localized } from "js/includes/common/utils"
import { defaultInheritance, isNinjaOneAssistAppActive } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { updatePolicyItem as _updatePolicyItem } from "js/state/actions/policyEditor/editor"
import InheritableRowPolicyItem from "./common/InheritableRowPolicyItem"
import {
  LocationTrackingAccuracyType as AccuracyType,
  LocationTrackingFrequencyType as FrequencyType,
} from "./android/enums"

const Separator = styled.div`
  margin: ${sizer(4)} 0;
  border-top: solid 1px ${({ theme }) => theme.colorBorderWeakest};
  width: 100%;
  height: 1px;
`

const RadioOptionLabel = styled(StyledSpan)`
  color: ${({ theme }) => theme.color.inputText};
  font-weight: normal;
  font-size: 14px;
  margin-left: ${({ marginLeft }) => marginLeft || "12px"};
`

const tagStatus = enabled => {
  return enabled
    ? [
        {
          id: "enabled",
          label: localized("Enabled"),
          variant: "enabled",
        },
      ]
    : [
        {
          id: "disabled",
          label: localized("Disabled"),
          variant: "disabled",
        },
      ]
}

const RadioOption = ({ intervalOptions, disabled, onChange, labelId }) => (
  <>
    {intervalOptions?.options?.length > 0 && (
      <Box>
        <RadioOptionLabel marginLeft="0">{intervalOptions?.label}</RadioOptionLabel>
        <Box width="300px" margin={sizer(1, 0, 0)}>
          <Select
            {...{
              options: intervalOptions.options,
              value: intervalOptions?.value || "",
              defaultValue: intervalOptions?.value || "",
              onChange,
              alignRight: false,
              disabled,
              triggerAriaLabel: intervalOptions?.value,
              labelId,
            }}
          />
        </Box>
      </Box>
    )}
  </>
)

const reportingTypeDistanceTraveledOptions = value => ({
  label: localized("Reporting trigger"),
  value,
  options: [
    { labelToken: localizationKey("500 meters"), value: "500" },
    { labelToken: localizationKey("1000 meters"), value: "1000" },
    { labelToken: localizationKey("5000 meters"), value: "5000" },
  ],
})

const reportingTypeTimeBasedOptions = value => ({
  label: localized("Reporting trigger"),
  value,
  options: [
    { labelToken: localizationKey("5 minutes"), value: "5" },
    { labelToken: localizationKey("30 minutes"), value: "30" },
    { labelToken: localizationKey("1 hour"), value: "60" },
    { labelToken: localizationKey("6 hours"), value: "360" },
    { labelToken: localizationKey("12 hours"), value: "720" },
    { labelToken: localizationKey("24 hours"), value: "1440" },
  ],
})

const root = "findDevice"

const defaultValues = {
  generalSettings: { enabled: false, ...defaultInheritance },
  accuracySettings: { accuracyType: AccuracyType.BALANCED, ...defaultInheritance },
  reportingSettings: {
    reportingType: FrequencyType.TIMED,
    reportingTypeDistanceTraveledInterval: "500",
    reportingTypeTimedBasedInterval: "60",
    ...defaultInheritance,
  },
}

const FindDeviceForm = ({ nodeClass, findDevice, updatePolicyItem, parentPolicy, applications }) => {
  const {
    generalSettings: { enabled = defaultValues.generalSettings.enabled } = {},
    accuracySettings: { accuracyType = defaultValues.accuracySettings.accuracyType } = {},
    reportingSettings: {
      reportingType = defaultValues.reportingSettings.reportingType,
      reportingInterval = defaultValues.reportingSettings.reportingInterval,
    } = {},
  } = findDevice ?? {}
  const isDistanceInterval = reportingType === FrequencyType.DISTANCE
  const isTimedInterval = reportingType === FrequencyType.TIMED

  const intervalValues = {
    reportingTypeDistanceTraveledInterval: isDistanceInterval
      ? reportingInterval?.toString()
      : defaultValues.reportingSettings.reportingTypeDistanceTraveledInterval,
    reportingTypeTimedBasedInterval: isTimedInterval
      ? reportingInterval?.toString()
      : defaultValues.reportingSettings.reportingTypeTimedBasedInterval,
  }

  const canEnableLocationTracking = isAppleMobileDevice(nodeClass) ? isNinjaOneAssistAppActive(applications) : true

  const handleFrequencyIntervalChange = value => {
    updatePolicyItem(`${root}.reportingSettings`, parentPolicy, {
      reportingType,
      reportingInterval: value,
      inheritance: findDevice.reportingSettings.inheritance ?? defaultInheritance,
    })
  }

  const handleOnChange = (entry, value) => {
    const [category, field] = entry.split(".")
    const updatedPath = [category, field]
    const currentValue = path(updatedPath, findDevice)

    if (value === currentValue) return

    const defaultValue = path([category], defaultValues)
    const currentCategory = pathOr(defaultValue, [category], findDevice)
    const updatedLocation = assoc(field, value, currentCategory)

    updatePolicyItem(`${root}.${category}`, parentPolicy, { ...updatedLocation })
  }

  return (
    <Flex padding={sizer(0, 6)} flex={1} overflow="auto" flexDirection="column">
      <Flex flexDirection="column" gap={sizer(3)} justifyContent="space-between">
        <Flex justifyContent="space-between" alignItems="end">
          <Box paddingBottom={tokens.spacing[2]}>
            <TitleGroup
              width="100%"
              titleToken={localizationKey("Location tracking")}
              TitleTailComponent={() => <Tags labels={tagStatus(enabled)} />}
            />
          </Box>
          <InheritableRowPolicyItem
            pathToItem={`${root}.generalSettings`}
            inheritableItem={findDevice?.generalSettings ?? defaultValues.generalSettings}
            testId="generalSettings"
            noBorder
            flexChildrenItem
            {...{
              wrapperProps: {
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "end",
                width: "50%",
                padding: parentPolicy ? `${tokens.spacing[2]}}` : "0",
              },
              childrenWrapperProps: { justifyContent: "end" },
              inheritanceComponentWrapperProps: { marginTop: tokens.spacing[4], marginRight: "0" },
            }}
          >
            <Button
              labelToken={enabled ? localizationKey("Disable") : localizationKey("Enable")}
              type={enabled ? "delete" : "primary"}
              flexDirection="row"
              alignSelf="flex-end"
              disabled={!canEnableLocationTracking && !enabled}
              tooltip={
                !canEnableLocationTracking && !enabled
                  ? localized(
                      "The NinjaOne Assist app must be active and added as a managed non-blocked application in this policy first.",
                    )
                  : null
              }
              onClick={() => handleOnChange("generalSettings.enabled", !enabled)}
            />
          </InheritableRowPolicyItem>
        </Flex>
        <Box marginBottom={tokens.spacing[2]} minWidth="492px" maxWidth="1200px">
          <AlertMessage
            variant="info"
            labelToken={
              isAppleMobileDevice(nodeClass)
                ? localizationKey(
                    "Location tracking requires the NinjaOne Assist app to be installed, active on the device, with Location Services enabled.",
                  )
                : localizationKey(
                    "Enabling location tracking automatically installs the NinjaOne Assist mobile application, which users can't remove.",
                  )
            }
          />
        </Box>
      </Flex>
      <Separator />
      <Flex marginTop={sizer(2)}>
        <Box width="200px">
          <Text size="sm" width="200px" color="colorTextStrong" token={localizationKey("Accuracy")} />
        </Box>
        <InheritableRowPolicyItem
          pathToItem={`${root}.accuracySettings`}
          inheritableItem={findDevice?.accuracySettings ?? defaultValues.accuracySettings}
          testId="accuracySettings"
          {...{ wrapperProps: { alignItems: "start", padding: "0" } }}
          noBorder
        >
          <Box maxWidth="600px">
            <RadioGroup
              testId="accuracyType"
              value={accuracyType}
              onValueChange={e => handleOnChange("accuracySettings.accuracyType", e)}
              options={[
                {
                  value: AccuracyType.BALANCED,
                  label: localized("Balanced mode"),
                  description: localized("Accuracy is about 100 meters and consumes less power."),
                  disabled: !enabled,
                },
                {
                  value: AccuracyType.HIGH_ACCURACY,
                  label: localized("High accuracy"),
                  description: localized("Captures finest location available but consumes more battery."),
                  disabled: !enabled,
                },
              ]}
            />
          </Box>
        </InheritableRowPolicyItem>
      </Flex>
      <Separator />
      <Flex marginTop={sizer(2)}>
        <Box width="200px">
          <Text size="sm" width="200px" color="colorTextStrong" token={localizationKey("Frequency")} />
        </Box>
        <InheritableRowPolicyItem
          pathToItem={`${root}.reportingSettings`}
          inheritableItem={findDevice?.reportingSettings ?? defaultValues.reportingSettings}
          testId="reportingSettings"
          noBorder
          {...{ wrapperProps: { alignItems: "start", padding: "0" } }}
        >
          <Box maxWidth="600px">
            <RadioGroup
              value={reportingType}
              onValueChange={e => {
                updatePolicyItem(`${root}.reportingSettings`, parentPolicy, {
                  reportingType: e,
                  reportingInterval:
                    e === FrequencyType.DISTANCE
                      ? intervalValues.reportingTypeDistanceTraveledInterval
                      : intervalValues.reportingTypeTimedBasedInterval,
                  inheritance: findDevice.reportingSettings.inheritance ?? defaultInheritance,
                })
              }}
              options={[
                {
                  value: FrequencyType.DISTANCE,
                  label: localized("Distance traveled"),
                  description: localized(
                    "Frequency at which the location will be sent to the server; will be reported at most every 5 minutes.",
                  ),
                  disabled: !enabled,
                  extraContentRenderer: () => (
                    <RadioOption
                      intervalOptions={reportingTypeDistanceTraveledOptions(
                        intervalValues.reportingTypeDistanceTraveledInterval,
                      )}
                      disabled={!enabled || reportingType !== FrequencyType.DISTANCE}
                      onChange={e => handleFrequencyIntervalChange(e)}
                      labelId="reportingTypeDistanceTraveledInterval"
                    />
                  ),
                },
                {
                  value: FrequencyType.TIMED,
                  label: localized("Time based"),
                  description: localized("Frequency at which the location is sent to the server."),
                  disabled: !enabled,
                  extraContentRenderer: () => (
                    <RadioOption
                      intervalOptions={reportingTypeTimeBasedOptions(intervalValues.reportingTypeTimedBasedInterval)}
                      disabled={!enabled || reportingType !== FrequencyType.TIMED}
                      onChange={e => handleFrequencyIntervalChange(e)}
                      labelId="reportingTypeTimedBasedInterval"
                    />
                  ),
                },
              ]}
            />
          </Box>
        </InheritableRowPolicyItem>
      </Flex>
      <Separator />
    </Flex>
  )
}

export default connect(
  ({
    policyEditor: {
      parentPolicy,
      policy: { content, nodeClass },
    },
  }) => ({
    nodeClass,
    parentPolicy,
    findDevice: content.findDevice,
    applications: content.application?.applications,
  }),
  {
    updatePolicyItem: _updatePolicyItem,
  },
)(FindDeviceForm)
