import { compose, filter, path, pluck, uniq } from "ramda"
import { localizedF } from "js/includes/common/utils"
import { getOrganizationCredentials } from "js/state/actions/credentials"
import { getUsernamePasswordCredentials } from "js/state/selectors/credentials"

export const systemCredential = { label: localizedF("System"), value: "system" }

export const credentialOptionsWindows = [
  systemCredential,
  { label: localizedF("Current Logged on User"), value: "loggedonuser" },
  { label: localizedF("Preferred Windows Local Admin"), value: "SR_LOCAL_ADMINISTRATOR" },
  { label: localizedF("Preferred Windows Domain Admin"), value: "SR_DOMAIN_ADMINISTRATOR" },
]

export const credentialOptionsMac = [
  systemCredential,
  { label: localizedF("Preferred Mac Credential"), value: "SR_MAC_SCRIPT" },
]

export const credentialOptionsLinux = [
  systemCredential,
  { label: localizedF("Preferred Linux Credential"), value: "SR_LINUX_SCRIPT" },
]

export const osCredentialOptions = deviceType => {
  switch (deviceType) {
    case "Windows":
      return credentialOptionsWindows
    case "Mac":
      return credentialOptionsMac
    case "Linux":
      return credentialOptionsLinux
    default:
      return []
  }
}

async function getOrganizationUsernamePasswordCredentials(orgId) {
  const orgCredentials = await getOrganizationCredentials(orgId)
  return getUsernamePasswordCredentials(orgCredentials)
}

export function getCredentials() {
  const deviceDashboardOrgId = path(
    ["attributes", "activeTab", "attributes", "node", "attributes", "clientId"],
    window.controller,
  )
  if (deviceDashboardOrgId) {
    return getOrganizationUsernamePasswordCredentials(deviceDashboardOrgId)
  }

  const isRouteDevicePolicyOverride = RegExp(/^#\/editor\/policy\/\d+\/\d+/)
  if (isRouteDevicePolicyOverride.test(window.location.hash)) {
    const policyOverrideOrgId = window.controller.attributes.activeEditorTab?.attributes.clientId
    return getOrganizationUsernamePasswordCredentials(policyOverrideOrgId)
  }

  const { devices: searchedDevices, selectedDevices } = window.store.getState().deviceSearch
  if (selectedDevices) {
    const commonOrganizationIds = compose(
      uniq,
      pluck("clientId"),
      filter(device => selectedDevices.includes(device.id)),
    )(searchedDevices)
    if (commonOrganizationIds.length === 1) {
      return getOrganizationUsernamePasswordCredentials(commonOrganizationIds)
    }
  }

  const orgDashboardId =
    window.controller.attributes?.activeTabLink?.tabId?.includes("customerDashboard") &&
    window.controller.attributes.activeTab.attributes.id
  if (orgDashboardId) {
    return getOrganizationUsernamePasswordCredentials(orgDashboardId)
  }

  return []
}

export const getCredentialSelectOptions = async (deviceType, labelKey = "label") => {
  const credentials = await getCredentials()
  return [
    ...osCredentialOptions(deviceType).map(({ label, value }) => ({ [labelKey]: label(), value })),
    ...credentials.map(({ name: label, id: value }) => ({ [labelKey]: label, value: value.toString() })),
  ]
}
