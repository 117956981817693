import { map, pick } from "ramda"
import { fetch, fetchJson } from "js/includes/common/utils"
import { runDeviceSearchToolbarAction } from "js/includes/common/utils/devices"

export const getBodyFor = task => {
  return {
    id: task.id,
    name: task.name,
    description: task.description,
    enabled: task.enabled,
    adHoc: task?.adHoc ?? false,
    scripts: task.scripts,
    schedule: task.schedule,
    targets: map(pick(["scheduledTaskId", "targetType", "targetId"]))(task.targets),
    runsOnNodeScheduledTask: task.runsOnNodeScheduledTask,
    ...(task.content && { content: task.content }),
  }
}

export const getAdhocScheduledTasksByDeviceId = id => fetchJson(`/node/${id}/scheduled-tasks-adhoc`)
export const getAdhocScheduledTasksByClientId = id => fetchJson(`/client/${id}/scheduled-tasks-adhoc`)
export const getAllAdhocScheduledTasks = () => fetchJson("/scheduled/task/ad-hoc/list")

export const deleteTask = id => fetch(`/scheduled/task/${id}`, { options: { method: "DELETE" } })

export const createTask = task =>
  fetchJson("/scheduled/task", { options: { method: "POST", body: JSON.stringify(getBodyFor(task)) } })

export const createTaskFromDeviceSearch = async task => {
  const taskDetails = await runDeviceSearchToolbarAction({
    action: "CREATE_SCHEDULED_TASKS",
    data: { scheduledTask: { ...getBodyFor(task), targets: [] } },
  })
  return taskDetails.data.scheduledTask
}

export const copyTask = (id, name) =>
  fetchJson(`/scheduled/task/copy`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        fromId: id,
        name,
      }),
    },
  })
