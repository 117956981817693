import { localizationKey } from "js/includes/common/utils"

export const productNameToken = localizationKey("Warranty Tracking")

export const appDescriptionToken = localizationKey(
  "Automatically update Warranty information on devices for all supported vendors",
)

export const isWarrantyTrackingEnabled = () => {
  return (
    window.store.getState().session?.divisionConfig?.find(({ name }) => name === "WARRANTY_TRACKING")?.enabled ?? false
  )
}
