import { connect } from "react-redux"
import { useCallback } from "react"
import moment from "moment"
import PropTypes from "prop-types"
import { AlertMessage, DataTable } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"
import showModal from "js/includes/common/services/showModal"
import { dateTime, localizationKey, localized, dateTimeFormat, isNotNil } from "js/includes/common/utils"
import { getOverrideStateColumn } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { Box } from "js/includes/components/Styled"
import { scheduledUpdatedTabs } from "./util"
import DeleteModal from "./DeleteModal"

const columnsByType = {
  [scheduledUpdatedTabs.APPROVED]: ["version", "approvalDate", "enforcementDate", "overrides"],
  [scheduledUpdatedTabs.ENFORCED]: ["version", "approvalDate", "enforcementDate"],
  [scheduledUpdatedTabs.REJECTED]: ["version", "rejectionDate", "overrides"],
}

function UpdateModal({ parentPolicy, updates, onDelete, onAdd, type, alertMessageToken, disableAdd }) {
  const isChildPolicy = isNotNil(parentPolicy)
  const getColumns = useCallback(
    isChildPolicy => {
      const columns = [
        {
          id: "version",
          Header: localized("Version"),
          accessor: "version",
        },
        {
          id: "approvalDate",
          Header: localized("Approved date"),
          accessor: ({ approvalDate }) => dateTime(approvalDate),
          sortType: (rowA, rowB) => rowA?.original?.approvalDate - rowB?.original?.approvalDate,
        },
        {
          id: "enforcementDate",
          Header: localized("Enforced date"),
          accessor: ({ enforcementDate }) =>
            moment(enforcementDate)
              .utc()
              .format(dateTimeFormat),
          sortType: (rowA, rowB) => rowA?.original?.enforcementDate - rowB?.original?.enforcementDate,
        },
        {
          id: "rejectionDate",
          Header: localized("Rejected date"),
          accessor: ({ rejectionDate }) => dateTime(rejectionDate),
          sortType: (rowA, rowB) => rowA?.original?.rejectionDate - rowB?.original?.rejectionDate,
        },
        ...(isChildPolicy ? [getOverrideStateColumn()] : []),
      ]
      return columns.filter(({ id }) => columnsByType[type].includes(id))
    },
    [type],
  )

  return (
    <Box paddingLeft={spacing[4]} paddingRight={spacing[4]}>
      <AlertMessage labelToken={alertMessageToken} />
      <Box marginTop={spacing[4]} height="400px" width="630px">
        <DataTable
          tableId={`manual-schedules-${type}-updates-table`}
          columns={getColumns(isChildPolicy)}
          rows={updates ?? []}
          noRowsToken={localizationKey("No updates scheduled")}
          hideCheckboxes
          hideSettingsButton
          showSearchBar={false}
          actions={
            onDelete
              ? {
                  primary: [
                    {
                      labelToken: localizationKey("Delete"),
                      action: ({ selected }) => {
                        showModal(<DeleteModal {...{ version: selected[0].version, updateType: type, onDelete }} />)
                      },
                      variant: "danger",
                      isRed: true,
                    },
                  ],
                }
              : {}
          }
          globalActionsButton={
            onAdd
              ? {
                  buttonProps: {
                    labelToken: localizationKey("Add"),
                    variant: "secondary",
                    action: onAdd,
                  },
                }
              : {}
          }
        />
      </Box>
    </Box>
  )
}

export default connect(({ policyEditor }) => ({
  parentPolicy: policyEditor.parentPolicy,
}))(UpdateModal)

UpdateModal.propTypes = {
  type: PropTypes.oneOf(Object.values(scheduledUpdatedTabs)).isRequired,
  updates: PropTypes.array.isRequired,
  alertMessageToken: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  disableAdd: PropTypes.bool,
}
