import qs from "qs"
import { __, allPass, filter, compose, pluck, gt, propEq, mapObjIndexed } from "ramda"
import { fetch, fetchJson, localized, showErrorMessage, incrementNegativeNumberOfSet } from "js/includes/common/utils"
import {
  getCriterionForRequest,
  isCriterionNew,
  getCriteriaFromResponse,
  getCriteriaNotDeleted,
  getActiveCriteriaNotDeleted,
} from "js/state/selectors/deviceSearch/criteria"
import { startLoading, stopLoading } from "js/state/actions/common/loading"
import { requestRunSearch } from "js/state/actions/deviceSearch/search"
import { getActiveSearch } from "js/state/selectors/deviceSearch/activeSearch"
import { getSelectedCriterion } from "js/state/selectors/deviceSearch/selectedCriterion"
import { setDevices } from "js/state/actions/deviceSearch/search"

const DEPENDENT_CRITERIAS = {
  customer: "location",
  "node-type": "node-role",
}

export const updateFilter = (type, payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: `UPDATE_FILTER_${type}`,
      payload,
    })

    const deviceSearch = getState().deviceSearch
    const criterion = getSelectedCriterion(deviceSearch)
    const activeSearch = getActiveSearch(deviceSearch)

    if (Array.isArray(payload)) {
      // Array filters
      const selectedValues = pluck("value", payload)
      if (!selectedValues.length) {
        dispatch(deleteCriterion(criterion))
      } else {
        dispatch(
          updateCriterion({
            ...criterion,
            selectedValues,
            search: activeSearch && activeSearch.id,
            action: isCriterionNew(criterion) ? "CREATE" : "UPDATE",
          }),
        )
      }
    } else {
      // Object filters
      if (!payload) {
        dispatch(deleteCriterion(criterion))
      } else {
        dispatch(
          updateCriterion({
            ...criterion,
            ...payload,
            search: activeSearch && activeSearch.id,
            action: isCriterionNew(criterion) ? "CREATE" : "UPDATE",
          }),
        )
      }
    }

    const getActiveCriteria = () => getActiveCriteriaNotDeleted(getState().deviceSearch)

    resetDependentCriterias(type, getActiveCriteria, dispatch)
    await dispatch(requestRunSearch(getActiveCriteria()))
  }
}

const resetDependentCriterias = (type, getActiveCriteria, dispatch) => {
  if (type in DEPENDENT_CRITERIAS) {
    const dependentCriteriaName = DEPENDENT_CRITERIAS[type]
    const dependentCriteria = getActiveCriteria().find(propEq("type", dependentCriteriaName))

    if (dependentCriteria) {
      dispatch({ type: `CLEAR_FILTER_${dependentCriteriaName}` })
      dispatch(deleteCriterion(dependentCriteria))
    }
  }
}

export const updateCriteriaForClickThroughSearch = payload => {
  return async (dispatch, getState) => {
    dispatch(setDevices([]))
    dispatch({
      type: "CLEAR_UNSAVED_SEARCH_CRITERIA",
    })
    dispatch({
      type: "CLEAR_ALL_SEARCH_FILTERS",
    })

    mapObjIndexed((value, key) => {
      const criteriaIds = getState().deviceSearch.criteria.map(i => i.id)

      dispatch(
        createCriterion({
          id: incrementNegativeNumberOfSet(criteriaIds),
          type: key,
          ...value,
        }),
      )
    }, payload)
  }
}

export const createCriterion = criterion => ({
  type: "CREATE_CRITERION",
  criterion,
})

export const updateNewCriteria = criteria => ({
  type: "UPDATE_NEW_CRITERIA",
  criteria,
})

export const updateCriterion = criterion => ({
  type: "UPDATE_CRITERION",
  criterion,
})

export const updateCriteria = criteria => ({
  type: "UPDATE_CRITERIA",
  criteria,
})

export const setCriteria = criteria => ({
  type: "SET_CRITERIA",
  criteria,
})

export const revertCriteria = searchId => ({
  type: "REVERT_CRITERIA",
  searchId,
})

export const removeCriteriaBySearch = searchId => ({
  type: "REMOVE_CRITERIA_BY_SEARCH",
  searchId,
})

const requestCreateCriteria = criteria => async dispatch => {
  const requestCriteria = criteria.map(getCriterionForRequest)

  try {
    const response = await fetchJson("/searches/criteria", {
      options: {
        method: "POST",
        body: JSON.stringify(requestCriteria),
      },
    })

    compose(dispatch, updateNewCriteria, getCriteriaFromResponse)(response)
  } catch (error) {
    showErrorMessage(localized("Error saving criteria"))
    throw error
  }
}

const requestUpdateCriteria = criteria => async dispatch => {
  const requestCriteria = criteria.map(getCriterionForRequest)

  try {
    const response = await fetchJson("/searches/criteria", {
      options: {
        method: "PUT",
        body: JSON.stringify(requestCriteria),
      },
    })

    compose(dispatch, updateCriteria, getCriteriaFromResponse)(response)
  } catch (error) {
    showErrorMessage(localized("Error saving criteria"))
    throw error
  }
}

export const deleteCriterion = criterion => ({
  type: "DELETE_CRITERION",
  criterion,
})

export const removeCriteria = criteria => ({
  type: "REMOVE_CRITERIA",
  criteria,
})

export const requestDeleteCriteria = criteria => async dispatch => {
  const ids = compose(filter(gt(__, 0)), pluck("id"))(criteria)

  if (ids.length) {
    try {
      const query = qs.stringify({ ids }, { arrayFormat: "repeat" })
      await fetch(`/searches/criteria?${query}`, {
        options: {
          method: "DELETE",
        },
      })

      dispatch(removeCriteria(criteria))
    } catch (error) {
      showErrorMessage(localized("Error saving criteria"))
      throw error
    }
  }
}

export const requestSaveCriteria = criteria => dispatch => {
  const newCriteria = criteria.filter(
    allPass([isCriterionNew, criterion => getCriteriaNotDeleted([criterion]).length > 0]),
  )
  const updatedCriteria = criteria.filter(c => c.action === "UPDATE")
  const deletedCriteria = criteria.filter(c => c.action === "DELETE")

  return Promise.all([
    newCriteria.length && dispatch(requestCreateCriteria(newCriteria)),
    updatedCriteria.length && dispatch(requestUpdateCriteria(updatedCriteria)),
    deletedCriteria.length && dispatch(requestDeleteCriteria(deletedCriteria)),
  ])
}

export const deleteFilterCriterion = async filterType => {
  const { getState, dispatch } = window.store
  const activeCriteria = () => getActiveCriteriaNotDeleted(getState().deviceSearch)
  const criterion = activeCriteria().find(propEq("type", filterType))
  if (criterion) {
    dispatch({ type: `CLEAR_FILTER_${filterType}` })
    dispatch(deleteCriterion(criterion))

    dispatch(startLoading("runningSearch")())
    await dispatch(requestRunSearch(activeCriteria()))
    dispatch(stopLoading("runningSearch")())
  }
}

export const clearAllUnsavedSearchCriteria = () => ({ type: "CLEAR_UNSAVED_SEARCH_CRITERIA" })
