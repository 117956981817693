import {
  faChartPie,
  faCloud,
  faFile,
  faFileAlt,
  faFileArchive,
  faFolder,
  faListCheck,
  faSitemap,
  faTableLayout,
} from "@fortawesome/pro-solid-svg-icons"
import { slice } from "ramda"
import { validate as uuidValidate } from "uuid"

import {
  getDeviceDashboardRootPath,
  getIcon as getDeviceIcon,
  getOverviewLink as getDeviceOverviewLink,
} from "js/includes/common/utils"

export const isNewTicket = tab => tab.type === "TICKET" && uuidValidate(tab.id)

export function getIcon(tab) {
  const { type, nodeClass } = tab
  if ("DEVICE" === type) {
    return getDeviceIcon(nodeClass)
  } else if (type === "GROUP") {
    return faFolder
  } else if (type === "CUSTOMER") {
    return faSitemap
  } else if (type === "MONITOR") {
    return faCloud
  } else if (type === "TICKET") {
    return faFile
  } else if (type === "REPORT_DASHBOARD") {
    return faChartPie
  } else if (type === "REPORT_GRID") {
    return faTableLayout
  } else if (type === "DOCUMENT") {
    return faFileAlt
  } else if (type === "CHECKLIST") {
    return faListCheck
  } else if (type === "KB_DOCUMENT") {
    return faFileArchive
  }
}

export function getOverviewLink(tab) {
  const { type, id, folderIds, clientId } = tab

  if ("DEVICE" === type) {
    return getDeviceOverviewLink(tab)
  } else if (type === "GROUP") {
    return `#/group/${id}`
  } else if (type === "TICKET") {
    return isNewTicket(tab) ? `#/ticketing/ticket/new/${id}` : `#/ticketing/ticket/${id}`
  } else if (type === "CUSTOMER") {
    return `#/customerDashboard/${id}/overview`
  } else if (type === "REPORT_DASHBOARD") {
    return `#/reporting/reports/${id}`
  } else if (type === "REPORT_GRID") {
    return `#/reporting/reports/${id}`
  } else if (type === "DOCUMENT") {
    return `#/customerDashboard/${tab.clientId}/documentation/appsAndServices/${tab.documentTemplateId}${
      tab.multiple ? `/${tab.id}` : ""
    }`
  } else if (type === "CHECKLIST") {
    return `#/customerDashboard/${tab.clientId}/documentation/checklists/${tab.id}`
  } else if (type === "KB_DOCUMENT") {
    const tabIds = !!folderIds.length ? slice(1, Infinity)(folderIds) : []
    if (clientId) {
      return `#/customerDashboard/${clientId}/documentation/knowledgeBase${
        folderIds.length > 1 ? "/" + tabIds.join("/") : ""
      }/${id}/file`
    }
    return `#/systemDashboard/knowledgeBase${folderIds.length > 1 ? "/" + tabIds.join("/") : ""}/${id}/file`
  }
}

export function getDashboardRootPath(tab) {
  const { type, id, folderIds, clientId } = tab

  if ("DEVICE" === type) {
    return getDeviceDashboardRootPath(tab)
  } else if (type === "GROUP") {
    return `#/group/${id}`
  } else if (type === "TICKET") {
    return `#/ticketing/ticket/${id}`
  } else if (type === "CUSTOMER") {
    return `#/customerDashboard/${id}`
  } else if (type === "REPORT_DASHBOARD") {
    return `#/reporting/reports/${id}`
  } else if (type === "REPORT_GRID") {
    return `#/reporting/reports/${id}`
  } else if (type === "DOCUMENT") {
    return `#/customerDashboard/${tab.clientId}/documentation/appsAndServices/${tab.documentTemplateId}${
      tab.multiple ? `/${tab.id}` : ""
    }`
  } else if (type === "CHECKLIST") {
    return `#/customerDashboard/${tab.clientId}/documentation/checklists/${tab.id}`
  } else if (type === "KB_DOCUMENT") {
    const tabIds = !!folderIds.length ? slice(1, Infinity)(folderIds) : []
    if (clientId) {
      return `#/customerDashboard/${clientId}/documentation/knowledgeBase${
        folderIds.length > 1 ? "/" + tabIds.join("/") : ""
      }/${id}/file`
    }
    return `#/systemDashboard/knowledgeBase${folderIds.length > 1 ? "/" + tabIds.join("/") : ""}/${id}/file`
  }
}

export const getLocalStorageKey = key => `sideNavBar.${key}`

export const sidebarWidth = 250
export const sidebarWidthCollapsed = 50
export const sidebarHoverDropdownWidth = 320
