import { createSelector } from "reselect"
import { compose, filter, map, toLower, reject, when } from "ramda"
import {
  sortByFieldNameCaseInsensitive,
  filterByPropContainsCaseInsensitive,
  user,
  filterByPropIncludes,
} from "js/includes/common/utils"

export const RUN_SCRIPT_UID = "e027f5a2-9182-4ea0-8f01-867eb299afd3"
export const INSTALL_MAC_SCRIPT_UID = "cf405def-5ea5-4a1c-b39d-160e6244d510"
export const INSTALL_WINDOWS_SCRIPT_UID = "73b61bdb-8db2-4f54-8546-a194aef954da"

export const LEGACY_INSTALL_RUN_SCRIPTS_UIDS = [RUN_SCRIPT_UID, INSTALL_MAC_SCRIPT_UID, INSTALL_WINDOWS_SCRIPT_UID]

export const getScriptsFilteredByDeviceType = createSelector(
  [
    ({ scripting, policyEditor }) =>
      policyEditor.scripting.conditionScriptSelector
        ? scripting.scripts.filter(script => script.language !== "native")
        : reject(script => LEGACY_INSTALL_RUN_SCRIPTS_UIDS.includes(script.uid), scripting.scripts),
    ({ scripting: { scriptsSelectorList } }) => scriptsSelectorList.deviceType,
  ],
  (scripts, deviceType) =>
    compose(
      filter(
        i =>
          user("canRunScripts", i.categoriesIds) ||
          i.categoriesIds.some(categoryId => user("getRunOnlyScriptCategories").includes(categoryId)),
      ),
      when(
        () => deviceType,
        filter(script => {
          const _operatingSystems = map(toLower)(script.operatingSystems)
          return _operatingSystems.includes(deviceType.toLowerCase())
        }),
      ),
    )(scripts),
)

export const getScriptsSelectorScripts = createSelector(
  [
    getScriptsFilteredByDeviceType,
    ({ scripting: { scriptsSelectorList } }) => scriptsSelectorList.nameFilter,
    ({ scripting: { scriptsSelectorList } }) => scriptsSelectorList.selectedScriptCategory,
    ({ scripting: { scriptsSelectorList } }) => scriptsSelectorList.sort.sortBy,
    ({ scripting: { scriptsSelectorList } }) => scriptsSelectorList.sort.sortDirection,
  ],
  (scripts, nameFilter, selectedScriptCategory, sortBy, sortDirection) =>
    compose(
      sortByFieldNameCaseInsensitive(sortBy, sortDirection),
      filterByPropIncludes("categoriesIds", selectedScriptCategory),
      filterByPropContainsCaseInsensitive("name", nameFilter),
    )(scripts),
)
