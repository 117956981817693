/** @jsxImportSource @emotion/react */
import { memo, Fragment, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import FilterOption from "./FilterOption"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import PropTypes from "prop-types"
import { localized } from "js/includes/common/utils"
import { space, typography } from "js/includes/components/Styled/system"
import { colors } from "js/includes/common/theme"
import showModal from "js/includes/common/services/showModal"
import SaveFiltersModal from "./SaveFiltersModal"
import fastDeepEqual from "fast-deep-equal"

const filterOptionsContainerStyle = css`
  display: flex;
  flex: 1;
`

export const StyledFilterActions = styled.div`
  margin-left: auto;
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

export const StyledFilterActionButton = styled.div`
  display: relative;
  line-height: 1;
  font-size: 14px;
  margin-left: 10px;
  justify-self: flex-end;
  color: ${colors.ninjaBlueSaturated};

  &:hover {
    cursor: pointer;
    text-decoration: none;
    outline: none;
    color: ${colors.ninjaBlueDark};
  }

  ${typography}
  ${space}
`

const FilterOptions = memo(
  ({
    filters,
    onClearFilters,
    filterOptions,
    onFilterChange,
    saveFilters,
    savedFilters,
    className,
    defaultFilters,
  }) => {
    const [activeFilter, setActiveFilter] = useState("")

    return (
      <OutsideClickAlerter
        {...{ className }}
        handleClickOutside={() => setActiveFilter("")}
        css={filterOptionsContainerStyle}
      >
        <Fragment>
          <div className="filters-container">
            {filterOptions.map(option => {
              return (
                <FilterOption
                  key={option.type}
                  value={filters[option.type]}
                  {...{ ...option, onFilterChange, activeFilter, setActiveFilter, filters, defaultFilters }}
                />
              )
            })}
          </div>

          <StyledFilterActions>
            {!fastDeepEqual(filters, defaultFilters) && !!filterOptions.length && (
              <StyledFilterActionButton
                className="btn-link"
                onClick={e => {
                  e.stopPropagation()
                  setActiveFilter("")
                  onFilterChange(defaultFilters)
                  onClearFilters && onClearFilters()
                }}
              >
                {localized("Clear")}
              </StyledFilterActionButton>
            )}

            {!!saveFilters && !!filterOptions.length && (
              <StyledFilterActionButton
                className="btn-link"
                onClick={e => {
                  e.stopPropagation()
                  setActiveFilter("")

                  showModal(<SaveFiltersModal {...{ saveFilters, savedFilters }} />)
                }}
              >
                {localized("Save")}
              </StyledFilterActionButton>
            )}
          </StyledFilterActions>
        </Fragment>
      </OutsideClickAlerter>
    )
  },
)

FilterOptions.propTypes = {
  filters: PropTypes.object.isRequired,
  filterOptions: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  defaultFilters: PropTypes.object.isRequired,
  clearFilters: PropTypes.func,
  saveFilters: PropTypes.func,
  className: PropTypes.string,
}

export default FilterOptions
