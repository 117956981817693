import { useRef } from "react"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import PropTypes from "prop-types"

import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import tokens from "@ninjaone/tokens"

import ClipboardCopy from "./ClipboardCopy"
import Body from "./Typography/Body"
import Code from "./Typography/Code"

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};

  [data-ninja-clipboard-copy] {
    visibility: hidden;
    background-color: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -${tokens.spacing[1]};
      display: block;
      height: 100%;
      width: ${tokens.spacing[1]};
      background-color: inherit;
    }
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }

  &:hover,
  &:focus-visible,
  &:focus-within {
    [data-ninja-clipboard-copy] {
      visibility: visible;

      &:focus-visible:after {
        width: calc(${tokens.spacing[1]} - 2px); // 2px is the width of the outline visible in this state
      }
    }
  }
`

const StyledCodeContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: ${tokens.spacing[3]};
  overflow-x: auto;
  text-wrap: ${({ textWrap }) => (textWrap ? "wrap" : "nowrap")};

  &:focus-visible {
    outline: 0;
  }
`

const positioningStyles = `
  position: absolute;
  top: ${tokens.spacing[1]};
  right: ${tokens.spacing[1]};
`

const CodeBlock = ({
  code,
  copyTooltipAlignment = "center",
  copyTooltipPosition = "bottom",
  label,
  textWrap,
  width,
}) => {
  const theme = useTheme()
  const ref = useRef()
  const ariaId = crypto.randomUUID()
  const codeArray = Array.isArray(code) ? code : [code]
  const codeString = codeArray.join("\n")
  const isMultiline = codeArray.length > 1

  const handleFocus = () => {
    if (ref?.current) {
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(ref.current)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  const handleBlur = () => {
    if (ref?.current) {
      const selection = window.getSelection()
      if (ref.current.contains(selection.anchorNode)) {
        selection.removeAllRanges()
      }
    }
  }

  return (
    <Flex flexDirection="column" gap={tokens.spacing[1]} minWidth="492px" maxWidth="1200px" {...{ width }}>
      <Body id={ariaId} color={theme.colorTextWeak} textWrap textWrapLineLimit={2}>
        {label}
      </Body>

      <StyledBox position="relative" tabIndex={0} aria-describedby={ariaId}>
        <ClipboardCopy
          {...{
            copyTooltipAlignment,
            copyTooltipPosition,
            positioningStyles,
            value: codeString,
          }}
        />

        <StyledCodeContainer
          as={isMultiline && "pre"}
          tabIndex={0}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...{
            ref,
            textWrap,
          }}
        >
          <Code {...{ textWrap: textWrap || isMultiline }} color="colorTextStrong">
            {codeString}
          </Code>
        </StyledCodeContainer>
      </StyledBox>
    </Flex>
  )
}

CodeBlock.propTypes = {
  /**
   * The text to populate the component with.
   */
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  /**
   * The horizontal alignment of the copy tooltip relative to the copy button.
   */
  copyTooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The position of the copy tooltip relative to the copy button.
   */
  copyTooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The description text for the component.
   */
  label: PropTypes.string.isRequired,
  /**
   * Determines if the text in the code block should wrap.
   */
  textWrap: PropTypes.bool,
  /**
   * The width of the input. Defaults to full width of parent container up to
   * 1200px.
   */
  width: PropTypes.number,
}

export default CodeBlock
