const faDownloadStarted = {
  prefix: "fac",
  iconName: "download-started",
  icon: [
    512,
    512,
    [],
    "0010",
    `M468.926,511.563L43.777,511.563C31.591,511.563 21.787,501.759 21.787,489.572L21.787,386.95C21.787,374.764 31.591,364.96 43.777,364.96L182.978,364.96L241.536,423.556C249.642,431.663 262.937,431.663 271.043,423.556L329.679,364.96L468.926,364.96C481.113,364.96 490.917,374.764 490.917,386.95L490.917,489.572C490.917,501.758 481.113,511.562 468.926,511.563ZM156.283,449.435C155.131,449.208 153.94,449.089 152.722,449.089C142.583,449.089 134.352,457.32 134.352,467.459C134.352,477.285 142.085,485.32 151.792,485.805C152.429,485.872 153.075,485.907 153.73,485.907C163.809,485.907 172.055,477.661 172.055,467.582C172.055,458.369 165.165,450.687 156.283,449.435ZM103.197,451.16C100.661,449.837 97.778,449.089 94.722,449.089C84.583,449.089 76.352,457.32 76.352,467.459C76.352,476.405 82.762,483.866 91.235,485.497C92.478,485.766 93.768,485.907 95.088,485.907C105.167,485.907 113.414,477.661 113.414,467.582C113.414,460.41 109.238,454.165 103.197,451.16ZM293.003,-0.181C305.189,-0.18 314.993,9.623 314.993,21.81L314.993,218.357L395.441,218.357C411.751,218.357 419.906,238.056 408.361,249.601L268.813,389.058C261.941,395.93 250.671,395.93 243.799,389.058L104.434,249.601C92.89,238.057 101.044,218.357 117.354,218.357L197.711,218.357L197.711,21.81C197.711,9.623 207.514,-0.18 219.701,-0.181L293.003,-0.181Z`,
  ],
}

export default faDownloadStarted
