import React, { memo } from "react"
import { ModeSelect } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"

export default memo(({ values, onChange, validation, validateForm }) => (
  <ModeSelect
    {...{
      values,
      onChange,
      validation,
      validateForm,
    }}
  />
))
