import { isMimeType } from "@lexical/utils"

import {
  IMAGE_FILE_MIME_TYPES,
  ATTACHMENT_FILE_EXTENSIONS,
  ATTACHMENT_FILE_MIME_TYPES,
  twentyMiB,
  localized,
  showErrorMessage,
} from "@ninjaone/webapp/src/js/includes/common/utils"

function isExtension(file, acceptableExtensions) {
  return acceptableExtensions.includes(`.${file.name.split(".").pop()}`)
}

function isValidFile(file, acceptableMimeTypes, acceptableExtensions) {
  const isBelowMinSize = file.size < 1
  const isOverMaxSize = file.size > twentyMiB

  if (isBelowMinSize) {
    showErrorMessage(localized("Minimum file size is 1 byte"))
  } else if (isOverMaxSize) {
    showErrorMessage(localized("Maximum file size is {{size}} MiB", { size: 20 }))
  }
  return (
    (isMimeType(file, acceptableMimeTypes) || isExtension(file, acceptableExtensions)) &&
    !isBelowMinSize &&
    !isOverMaxSize
  )
}

export function isValidAttachmentFile(file) {
  return isValidFile(file, ATTACHMENT_FILE_MIME_TYPES, ATTACHMENT_FILE_EXTENSIONS)
}

export function isValidImageFile(file) {
  return isValidFile(file, IMAGE_FILE_MIME_TYPES)
}

export function extractImageTypeFromBase64(base64String) {
  const regex = /^data:image\/(jpeg|jpg|png|gif);/
  const match = base64String.match(regex)

  if (match && match[1]) return match[1]

  return null
}

/**
 *  File Reader with:
 *  1. Extension type support
 *  2. Batched results (HistoryPlugin compatibility)
 *  3. Order aware (respects the order when multiple Files are passed)
 *
 *  const filesResult = await mediaFileReader(files, ["image/"], ["log"]);
 *  filesResult.forEach(file => editor.dispatchCommand('INSERT_IMAGE', {
 *    src: file.result,
 *  }));
 */
export function mediaFileReader(files, acceptableMimeTypes = [], acceptableExtensions = []) {
  const filesIterator = files[Symbol.iterator]()
  return new Promise((resolve, reject) => {
    const processed = []
    const handleNextFile = () => {
      const { done, value: file } = filesIterator.next()
      if (done) return resolve(processed)

      const fileReader = new FileReader()
      fileReader.addEventListener("error", reject)
      fileReader.addEventListener("load", () => {
        const result = fileReader.result
        if (typeof result === "string") {
          processed.push({ file, result })
        }
        handleNextFile()
      })
      if (isMimeType(file, acceptableMimeTypes) || isExtension(file, acceptableExtensions)) {
        fileReader.readAsDataURL(file)
      } else {
        handleNextFile()
      }
    }
    handleNextFile()
  })
}

/**
 * Extracts all images HEX representations from a given RTF data.
 *
 * @param rtfData The RTF data from which to extract images HEX representation.
 * @returns Array of found HEX representations. Each array item is an object containing:
 *
 * * hex Image representation in HEX format.
 * * type Type of image, `image/png` or `image/jpeg`.
 */
export function extractImageDataFromRtf(rtfData) {
  if (!rtfData) return []

  const regexPictureHeader = /{\\pict[\s\S]+?\\bliptag-?\d+(\\blipupi-?\d+)?({\\\*\\blipuid\s?[\da-fA-F]+)?[\s}]*?/
  const regexPicture = new RegExp("(?:(" + regexPictureHeader.source + "))([\\da-fA-F\\s]+)\\}", "g")
  const images = rtfData.match(regexPicture)

  const result = []

  if (images) {
    for (const image of images) {
      let type

      if (image.includes("\\pngblip")) {
        type = "image/png"
      } else if (image.includes("\\jpegblip")) {
        type = "image/jpeg"
      }

      if (type) {
        result.push({
          hex: image.replace(regexPictureHeader, "").replace(/[^\da-fA-F]/g, ""),
          type,
        })
      }
    }
  }

  return result
}

/**
 * Converts given HEX string to base64 representation.
 *
 * @internal
 * @param hexString The HEX string to be converted.
 * @returns Base64 representation of a given HEX string.
 */
export function convertHexToBase64(hexString) {
  return btoa(
    hexString
      .match(/\w{2}/g)
      .map(char => String.fromCharCode(parseInt(char, 16)))
      .join(""),
  )
}

/**
 * Converts given `src` (e.g. data:...;base64,...) to a `File`
 *
 * @param {string} src The Base64 source
 * @param {string} filename Name of the file without the extension
 * @param {tring} type File type extension (e.g. png, jpeg, etc.)
 * @returns
 */
export async function convertBase64ToFile(src, filename, type) {
  const data = await fetch(src)
  const blob = await data.blob()
  const file = await new File([blob], filename, { type })
  return file
}
