import React, { memo } from "react"
import {
  OperatorSelect,
  ThresholdStepper,
  DurationSelect,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"

export default memo(({ values, onChange, validation, onThresholdChange }) => (
  <>
    <OperatorSelect
      {...{
        values,
        onChange,
        validation,
      }}
    />

    <ThresholdStepper
      {...{
        values,
        onChange: onThresholdChange,
        validation,
      }}
    />

    <DurationSelect
      {...{
        values,
        onChange,
        validation,
      }}
    />
  </>
))
