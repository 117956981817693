//@flow
import {
  reduce,
  curry,
  assoc,
  keys,
  ifElse,
  length,
  dissoc,
  is,
  when,
  compose,
  forEach,
  __,
  toLower,
  prop,
  propOr,
  head,
  last,
  map,
} from "ramda"
import { isNilOrEmpty } from "js/includes/common/utils/ssrAndWebUtils"

export const valueProp = prop("value")

export const getLastValue = compose(valueProp, last)

export const getFirstValue = compose(valueProp, head)

export const renameKey = curry((oldKey, newKey, obj) => assoc(newKey, prop(oldKey, obj), dissoc(oldKey, obj)))

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj)),
)

export const getLengthOfObjOrArray = compose(
  ifElse(Array.isArray, length, a => {
    throw new Error(`${a} is not an array or number`)
  }),
  when(is(Object), keys),
)

export const assignValueToObjProp = curry((obj: Object, prop: string, value: any): void => {
  obj[prop] = value
})

export const assignValueToPropertiesInObj = (obj: Object, properties: Array<string>, value: any): void =>
  compose(forEach(assignValueToObjProp(obj, __, value)))(properties)

export const propToLower = name => compose(toLower, propOr("", name))

export function assignOwnPropertiesInPlace(source, target) {
  for (const propertyName in source) {
    if (source.hasOwnProperty(propertyName)) {
      target[propertyName] = source[propertyName]
    }
  }
}

export const objectWithIdKeysToList = (object, idKey = "id") => {
  if (isNilOrEmpty(object)) {
    return []
  }
  return compose(
    map(key => ({ [idKey]: key, ...object[key] })),
    keys,
  )(object)
}

export const filterMapObject = (obj, shouldIncludeEntry, transformValue, transformKey) => {
  const result = {}
  for (const key in obj) {
    const value = obj[key]
    if (shouldIncludeEntry(value, key, obj)) {
      result[transformKey?.(key) ?? key] = transformValue?.(value) ?? value
    }
  }
  return result
}
