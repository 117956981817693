import PropTypes from "prop-types"
import { compose, join, pluck } from "ramda"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import tokens from "@ninjaone/tokens"
import { BarsFilterIconLight, EllipsisHIconLight, ExclamationCircle, SearchIconLight } from "@ninjaone/icons"
import { isNilOrEmpty, localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import Text from "../../Text"
import Dropdown from "../../Dropdown"
import IconButton from "../../IconButton"
import Select from "../../Selects/Select"
import Body from "../../Typography/Body"

const StyledActionsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: -${tokens.spacing[1]};
`

export const StyledWidget = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight};
  height: ${({ height }) => height};
  grid-row: ${({ gridRow }) => gridRow};
  grid-column: ${({ gridColumn }) => gridColumn};
  padding: ${tokens.spacing[4]} ${tokens.spacing[5]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackground};
  overflow: hidden;

  &:hover,
  &:focus-within {
    .actions-relative-container {
      width: ${({ actionsContainerWidth }) => actionsContainerWidth};
    }
    .show-on-hover {
      opacity: 1;
      width: auto;
      overflow: visible;
    }
  }

  .show-on-hover {
    opacity: 0;
    width: 0;
    overflow: hidden;
  }
`

const DashboardWidget = ({
  titleText,
  secondaryText,
  height,
  minHeight,
  gridColumn,
  gridRow,
  showSearchButton,
  onSearchButtonClick,
  filterOptions = [],
  filterOnChange,
  filterValue,
  filterIsMulti = true,
  actions = [],
  errorMessage,
  helpTooltipRenderer,
  children,
}) => {
  const theme = useTheme()
  const getFilterTooltipText = () => {
    if (isNilOrEmpty(filterValue)) {
      return localized("Filter")
    } else if (filterIsMulti) {
      const text = compose(join(", "), pluck("labelText"))(filterValue)
      return `${localized("Filters")}: ${text}`
    } else return `${localized("Filter")}: ${filterValue.labelText}`
  }
  const actionButtonWidth = 32
  const secondaryTextSeparatorWidth = 16
  const titlesSpacingWidth = 4

  const actionsContainerWidth =
    (secondaryText ? secondaryTextSeparatorWidth + titlesSpacingWidth : 0) +
    (showSearchButton ? actionButtonWidth : 0) +
    (!!filterOptions.length ? actionButtonWidth : 0) +
    (!!actions.length ? actionButtonWidth : 0)

  return (
    <StyledWidget
      height={height}
      minHeight={minHeight}
      gridColumn={gridColumn}
      gridRow={gridRow}
      data-testid={`${titleText}-dashboard-widget`}
      actionsContainerWidth={`${actionsContainerWidth}px`}
    >
      <Flex justifyContent="space-between" marginBottom={tokens.spacing[4]}>
        <Flex
          flex={1}
          minWidth="1px"
          alignItems="center"
          height="24px"
          justifyContent="space-between"
          gap={tokens.spacing[1]}
        >
          <Flex alignItems="center" minWidth="50px">
            <Text type="headingS" color="colorTextStrong" fontWeight={tokens.typography.fontWeight.medium}>
              {titleText}
            </Text>
            {helpTooltipRenderer && (
              <Box minWidth="28px">
                <div className="show-on-hover">{helpTooltipRenderer?.()}</div>
              </Box>
            )}
          </Flex>
          {secondaryText && (
            <Box alignSelf="center" minWidth="50px">
              <Body type="bodyXs" color="colorTextWeakest">
                {secondaryText}
              </Body>
            </Box>
          )}
        </Flex>
        <Flex position="relative" className="actions-relative-container">
          <StyledActionsContainer>
            <Flex className="show-on-hover">
              {secondaryText && (
                <Box padding={[tokens.spacing[1], 0]}>
                  <Box
                    height="100%"
                    margin={[0, tokens.spacing[2]]}
                    borderRight={`1px solid ${theme.colorBorderWeakest}`}
                  ></Box>
                </Box>
              )}
              {showSearchButton && (
                <IconButton size="sm" tooltip={localized("Search")} tooltipPortal={false} onClick={onSearchButtonClick}>
                  <SearchIconLight />
                </IconButton>
              )}
              {!!filterOptions.length && (
                <Select
                  {...{
                    size: "sm",
                    alignRight: true,
                    buttonRenderer: () => (
                      <IconButton size="sm" tooltip={getFilterTooltipText()} tooltipPortal={false} renderAsDiv>
                        <BarsFilterIconLight />
                      </IconButton>
                    ),
                    onChange: filterOnChange,
                    options: filterOptions,
                    value: filterIsMulti ? pluck("value", filterValue ?? []) : filterValue?.value,
                    triggerAriaLabel: localized("Filter"),
                  }}
                />
              )}
              {!!actions.length && (
                <Dropdown variant="compact" alignRight options={actions} portal={false}>
                  <IconButton size="sm" tooltip={localized("Actions")} tooltipPortal={false} renderAsDiv>
                    <EllipsisHIconLight />
                  </IconButton>
                </Dropdown>
              )}
            </Flex>
          </StyledActionsContainer>
        </Flex>
      </Flex>
      {errorMessage ? (
        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={tokens.spacing[2]}
          maxHeight="252px"
        >
          <ExclamationCircle color="colorAlertError" />
          <Text type="body" color="colorTextWeakest" textWrap>
            {errorMessage}
          </Text>
        </Flex>
      ) : (
        children
      )}
    </StyledWidget>
  )
}

export const widgetPropTypes = {
  titleText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string,
  height: PropTypes.string,
  showSearchButton: PropTypes.bool,
  onSearchButtonClick: PropTypes.func,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      labelText: PropTypes.string,
    }),
  ),
  filterOnChange: PropTypes.func,
  filterValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        labelText: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      value: PropTypes.string,
      labelText: PropTypes.string,
    }),
  ]),
  filterIsMulti: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      labelToken: PropTypes.string,
    }),
  ),
  errorMessage: PropTypes.string,
  helpTooltipRenderer: PropTypes.func,
}

DashboardWidget.propTypes = widgetPropTypes

export default DashboardWidget
