export const VM_HOST_RESTART_ACTION = {
  identifier: "f53ada1c-c5c9-497e-b7da-3b67528598fa",
}
export const VM_GUEST_RESTART_ACTION = {
  identifier: "bcc366df-6577-428e-a53a-979f79c148fd",
}
export const VM_GUEST_POWER_ON_ACTION = {
  identifier: "cebdca14-b4e5-43ae-8f89-992d3e4848d2",
}
export const VM_GUEST_START_ACTION = {
  identifier: "b9f8c7db-2acc-412e-a3e4-50c2be52615f",
}
export const VM_GUEST_POWER_OFF_ACTION = {
  identifier: "19ba1235-e926-44d9-a6a5-d71b58858493",
}
export const VM_GUEST_TURN_OFF_ACTION = {
  identifier: "59e55eb0-8d29-4808-8401-742f0f7a4759",
}
export const VM_GUEST_SHUTDOWN_ACTION = {
  identifier: "b5793094-3d31-4b5b-ae2e-5239e676cfd7",
}
export const VM_GUEST_RESET_ACTION = {
  identifier: "86a1870d-0a87-46c2-b86b-0ad397302dca",
}
export const VM_GUEST_SUSPEND_ACTION = {
  identifier: "547e55e1-9afb-4e3e-b724-7049d21b6404",
}
export const VM_GUEST_SAVE_ACTION = {
  identifier: "f6b8577b-1d26-476a-967c-23ae76ff9287",
}
export const VM_GUEST_DELETE_ACTION = {
  identifier: "c8728037-f084-4ff9-9af8-13bde06b0369",
}
export const VM_GUEST_UPDATE_TOOLS_ACTION = {
  identifier: "c588bd0c-7eef-4683-8dfd-c46d00f44d9f",
}
export const VM_GUEST_PAUSE_ACTION = {
  identifier: "74b3e400-f4d5-4f0f-88ab-89dde4b5d4f1",
}
export const VM_GUEST_RESUME_ACTION = {
  identifier: "8fdf31a0-18b5-467a-83e1-46a55ea387f0",
}
export const VM_GUEST_RESTORE_SNAPSHOT_ACTION = {
  identifier: "b51c9dba-618a-43ad-b065-dc46da0ac12b",
}
export const VM_GUEST_CREATE_SNAPSHOT_ACTION = {
  identifier: "01c4a409-39f0-46ed-9a27-a9cf42454d13",
}
export const VM_GUEST_DELETE_SNAPSHOT_ACTION = {
  identifier: "c88cd6c6-e8ea-4bb6-83f8-e5c4683d820f",
}
export const VM_GUEST_RESTORE_CHECKPOINT_ACTION = {
  identifier: "6ee67038-8981-445c-9441-2723312a1f23",
}
export const VM_GUEST_CREATE_CHECKPOINT_ACTION = {
  identifier: "0876a6ec-0626-4f4f-8bbd-73e6b1cc2359",
}
export const VM_GUEST_DELETE_CHECKPOINT_ACTION = {
  identifier: "59168093-808b-48a8-bd54-dd29d89f539b",
}

export const askConfirmationActions = [
  VM_GUEST_TURN_OFF_ACTION,
  VM_GUEST_POWER_OFF_ACTION,
  VM_GUEST_RESET_ACTION,
  VM_GUEST_SHUTDOWN_ACTION,
  VM_GUEST_RESTART_ACTION,
].map(({ identifier }) => identifier)
