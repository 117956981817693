import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { getMultiplierByPrefix } from "js/includes/common/utils"
import NumberStepper from "js/includes/editors/Policy/PolicyEditor/formComponents/NumberStepper"

const UnitBytesStepper = memo(({ values, onChange, unitBytesOptions, stepper = 1, maxValue }) => {
  function handleChange(key, value) {
    const { unitBytes, thresholdBytes } = values
    const prefix = key === "unitBytes" ? value : unitBytes
    const bytes = key === "thresholdBytes" ? value : thresholdBytes
    const multiplier = getMultiplierByPrefix(prefix)
    const threshold = bytes * multiplier

    onChange(key, value)
    onChange("threshold", threshold)
  }

  return (
    <>
      <FormGroup controlId="thresholdBytes">
        <Col componentClass={ControlLabel} sm={4}>
          {localized("Threshold Bytes")}
        </Col>
        <Col sm={8}>
          <NumberStepper
            minValue={1}
            {...(maxValue && { maxValue })}
            initialValue={values.thresholdBytes}
            stepper={stepper}
            onChange={value => handleChange("thresholdBytes", parseInt(value))}
          />
        </Col>
      </FormGroup>

      <FormGroup controlId="unitBytes">
        <Col componentClass={ControlLabel} sm={4}>
          {localized("Unit Bytes")}
        </Col>
        <Col sm={8}>
          <Dropdown
            options={unitBytesOptions}
            initialValue={values.unitBytes}
            onChange={value => handleChange("unitBytes", value)}
          />
        </Col>
      </FormGroup>
    </>
  )
})

export default UnitBytesStepper
