import React from "react"

import showModal from "js/includes/common/services/showModal"
import {
  DOJO_SECTION_PREFIX,
  ZENDESK_DOJO_URL,
  fetchJson,
  initialDefaultLanguage,
  ninjaReportError,
  setWebappDefaultDomTitleValue,
  setWebappTitle,
} from "js/includes/common/utils"
import { getLanguageFromBrowserSettings, localizationKey, localized, setLanguage } from "js/includes/common/utils"
import { brandingDomains } from "js/includes/common/utils/ssrAndWebUtils"
import BrandingErrorPage from "js/includes/configuration/branding/website/BrandingErrorPage"
import {
  getBrandingSettings,
  getNinjaBrandingSettings,
  setAppLogo,
  setAppTheme,
  setBrowserIcon,
  setLoginLogo,
} from "js/state/actions/websiteBranding/brandingNode"

export const ninjaOneDefaultTitle = "NinjaOne"

export const ninjaImages = {
  appLogo: `${process.env.PUBLIC_URL}/img/ninja_logo.svg`,
  appLogoDark: `${process.env.PUBLIC_URL}/img/ninja_logo_dark.svg`,
  appLogoIcon: `${process.env.PUBLIC_URL}/img/ninja_logo_icon.svg`,
  appLogoIconDark: `${process.env.PUBLIC_URL}/img/ninja_logo_icon_dark.svg`,
  browserIcon: `${process.env.PUBLIC_URL}/img/favicon.png`,
  loginLogo: `${process.env.PUBLIC_URL}/img/ninja_logo_dark.svg`,
}

export async function getBrandingData() {
  let brandingData = {}
  let isBranded = false

  if (isBrandingSiteDomain()) {
    try {
      brandingData = await fetchJson("/branding/generalData", { redirectUserToLogin: false })
      isBranded = brandingData.isEnabled ?? false
    } catch (e) {}
    window.store.dispatch({ type: "SET_APP_BRANDED", isBranded })

    if (!isBranded) {
      await setLanguage(getLanguageFromBrowserSettings() ?? initialDefaultLanguage)
      showModal(<BrandingErrorPage />, { isFullScreen: true })
    }
  }

  return brandingData
}

export function isBrandingSiteDomain() {
  const { hostname } = window.location
  return brandingDomains.some(domain => hostname.endsWith(domain))
}

export function isRmmServiceLocation() {
  return window.store.getState().application.isBranded
}

function setImageFromResponse(response) {
  return response ? `data:image/jpeg;base64,${response}` : ""
}

export const setBrowserTab = ({ browserName, browserEnabled }) => {
  setWebappDefaultDomTitleValue(browserEnabled && browserName ? browserName : window.location.host.split(".")[0])
  setWebappTitle()
}

export async function initBranding() {
  const brandingData = await getBrandingData()

  if (!brandingData.isEnabled) {
    window.store.dispatch(setLoginLogo(ninjaImages.loginLogo))
    window.store.dispatch(setAppLogo(ninjaImages.appLogo))
    window.store.dispatch(setBrowserIcon(ninjaImages.browserIcon))
    document.querySelector('link[rel="icon"]').href = ninjaImages.browserIcon
    setWebappTitle()
  } else {
    try {
      const {
        loginLogo: loginLogoResponse,
        browserIcon: browserIconResponse,
        theme,
        browserName,
        browserEnabled,
      } = brandingData

      const loginLogo = setImageFromResponse(loginLogoResponse)
      const browserIcon = setImageFromResponse(browserIconResponse)

      window.store.dispatch(setLoginLogo(loginLogo || ninjaImages.loginLogo))
      document.querySelector('link[rel="icon"]').href = browserIcon || ninjaImages.browserIcon
      setBrowserTab({
        browserName,
        browserEnabled,
      })

      // set theme
      window.store.dispatch(setAppTheme(theme))
    } catch (err) {
      ninjaReportError("Error getting branding logo, icon, and theme name", err)
    }
  }
}

export async function initBrandingState(dispatch) {
  if (isRmmServiceLocation()) {
    await dispatch(getBrandingSettings())
  } else {
    await dispatch(getNinjaBrandingSettings())
  }
}

const DARK_LOGO_THEMES = ["theme-light-grey", "theme-two"]

export function getAppLogoFromBranding() {
  const state = window.store.getState()
  const { brandingNode } = state.websiteBranding
  const { theme } = state.application

  return brandingNode?.general?.enabled && isRmmServiceLocation() && brandingNode.images.appLogo
    ? brandingNode.images.appLogo
    : DARK_LOGO_THEMES.includes(theme)
    ? ninjaImages.appLogoDark
    : ninjaImages.appLogo
}

export function getBrowserIconFromBranding() {
  const state = window.store.getState()
  const { brandingNode } = state.websiteBranding
  const { theme } = state.application

  return brandingNode?.general?.enabled && isRmmServiceLocation() && brandingNode.images.browserIcon
    ? brandingNode.images.browserIcon
    : DARK_LOGO_THEMES.includes(theme)
    ? ninjaImages.appLogoIconDark
    : ninjaImages.appLogoIcon
}

export function getDefaultThemeName() {
  return getAppThemes()[0].name
}

const getClassicThemes = () => [
  {
    id: 1,
    name: "theme-one",
    bgColor: "#2F4050",
    textColor: "#CAD0D6",
    display: localizationKey("Theme 1"),
  },
  {
    id: 2,
    name: "theme-two",
    bgColor: "#F7F8F8",
    textColor: "#363B3E",
    display: localizationKey("Theme 2"),
  },
  {
    id: 3,
    name: "theme-three",
    bgColor: "#CF3343",
    textColor: "#FFFFFF",
    display: localizationKey("Theme 3"),
  },
  {
    id: 4,
    name: "theme-four",
    bgColor: "#0E7E84",
    textColor: "#FFFFFF",
    display: localizationKey("Theme 4"),
  },
  {
    id: 5,
    name: "theme-five",
    bgColor: "#6762A6",
    textColor: "#FFFFFF",
    display: localizationKey("Theme 5"),
  },
  {
    id: 6,
    name: "theme-six",
    bgColor: "#E25331",
    textColor: "#FFFFFF",
    display: localizationKey("Theme 6"),
  },
]

export function getAppThemes() {
  return [
    {
      id: 7,
      name: "theme-ninja-blue",
      bgColor: "#002a42",
      textColor: "#CAD0D6",
      display: localizationKey("Ninja Blue (New)"),
    },
    {
      id: 8,
      name: "theme-light-grey",
      bgColor: "#f0f0f0",
      textColor: "#363B3E",
      display: localizationKey("Light Grey (New)"),
    },
    {
      id: 9,
      name: "theme-dark-grey",
      bgColor: "#1F2122",
      textColor: "#CAD0D6",
      display: localizationKey("Dark Grey (New)"),
    },
    ...getClassicThemes(),
  ]
}

export function getRmmserviceDomainFromRegion() {
  const region = window.store.getState().application.region || ""
  const origin = window.location.origin

  if (origin.endsWith("qa-3.engineering-env.ninja") || origin.endsWith("branding-qa-3.rmmservice.ninja")) {
    return "branding-qa-3.rmmservice.ninja"
  }

  if (origin.endsWith("qa4.engineering-env.ninja") || origin.endsWith("branding-qa4.rmmservice.ninja")) {
    return "branding-qa4.rmmservice.ninja"
  }
  if (origin.endsWith("qa5.engineering-env.ninja") || origin.endsWith("branding-qa5.rmmservice.ninja")) {
    return "branding-qa5.rmmservice.ninja"
  }
  if (origin.endsWith("qa6.engineering-env.ninja") || origin.endsWith("branding-qa6.rmmservice.ninja")) {
    return "branding-qa6.rmmservice.ninja"
  }

  if (origin.endsWith(".engineering-env.ninja")) {
    return "rmmservice.ninja"
  }

  if (region.startsWith("eu-central")) {
    return "rmmservice.eu"
  }

  if (region.startsWith("ap-southeast")) {
    return "rmmservice.com.au"
  }

  if (region.startsWith("ca-central")) {
    return "rmmservice.ca"
  }

  if (region.startsWith("us-east-2")) {
    return "rmmservices.net"
  }

  return "rmmservice.com"
}

export const getNinjaBranding = () => ({
  general: {
    phoneSettings: {
      enabled: true,
      number: localized("Contact us"),
    },
    emailSettings: {
      enabled: true,
      email: "support@ninjarmm.com",
    },
    helpSettings: {
      enabled: true,
      ninjaArticlesEnabled: true,
      submitTicketEnabled: true,
      ninjaOneWorkrampAcademyEnabled: true,
      topics: [
        {
          topic: localized("Dojo / Help center"),
          token: localizationKey("Dojo / Help center"),
          action: () => {},
          zendeskUrl: ZENDESK_DOJO_URL,
        },
        {
          topic: localized("Video library"),
          token: localizationKey("Video library"),
          action: () => {},
          zendeskUrl: "https://www.ninjaone.com/ninjadojovideo/",
        },
        {
          topic: localized("News"),
          token: localizationKey("News"),
          action: () => {},
          zendeskUrl: `${DOJO_SECTION_PREFIX}/115000771372-Announcements-Webinars`,
        },
        {
          topic: localized("Release notes"),
          token: localizationKey("Release notes"),
          action: () => {},
          zendeskUrl: `${DOJO_SECTION_PREFIX}/201742066-Release-Notes`,
        },
        {
          topic: localized("Product roadmap"),
          token: localizationKey("Product roadmap"),
          action: "https://portal.productboard.com/nnzqj6ynxtn4ocxuxr4fefjw/tabs/1-next-release",
        },
        {
          topic: localized("API documentation"),
          token: localizationKey("API documentation"),
          action: "/apidocs/",
        },
      ],
    },
  },
  images: {
    loginLogo: ninjaImages.loginLogo,
    appLogo: ninjaImages.appLogo,
    browserIcon: ninjaImages.browserIcon,
  },
})
