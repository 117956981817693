import { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input } from "@ninjaone/components"
import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { localized } from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import { Box } from "js/includes/components/Styled"
import Tooltip from "js/includes/components/Tooltip"

const StyledTextValueInput = styled(Input)`
  padding: ${sizer(2)};
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.reactVirtualizedBlue};
  }
`
const TextInput = memo(
  ({ labelToken, tooltipToken, tooltipOutput, tooltipDelay, placeholderToken, values, onChange, prop }) => (
    <FormGroup controlId={prop} validationState={values?.errors?.message?.[prop] ? "error" : null}>
      <Col componentClass={ControlLabel} sm={4}>
        {localized(labelToken)}
        {(tooltipToken || tooltipOutput) && (
          <Box marginLeft="2px" display="inline">
            <Tooltip token={tooltipToken} output={tooltipOutput} delay={tooltipDelay}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          </Box>
        )}
      </Col>
      <Col sm={8}>
        <StyledTextValueInput
          onChange={e => onChange(prop, e.target.value)}
          defaultValue={values[prop]}
          placeholder={localized(placeholderToken)}
          invalid={values?.errors?.message?.[prop] ? "error" : null}
        />
        {values?.errors?.message?.[prop] && <em className="invalid m-t-none"> {values.errors.message[prop]}</em>}
      </Col>
    </FormGroup>
  ),
)

export default TextInput
