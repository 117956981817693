import { TitleGroup } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { Box } from "js/includes/components/Styled"

const OSUpdatesHeader = () => (
  <Box padding={`0 ${tokens.spacing[6]}`} marginBottom={tokens.spacing[6]}>
    <TitleGroup
      titleToken={localizationKey("OS updates")}
      descriptionToken={localizationKey(
        "Manage how users can install software updates and define enforced OS versions.",
      )}
    />
  </Box>
)

export default OSUpdatesHeader
