import styled from "@emotion/styled"

import tokens from "@ninjaone/tokens"

export const StyledToolbar = styled.div`
  display: flex;
  padding: 0 ${tokens.spacing[1]};
  background: ${({ theme }) => theme.colorBackgroundAccentNeutralWeakest};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  border-top-left-radius: ${tokens.borderRadius[1]};
  border-top-right-radius: ${tokens.borderRadius[1]};
  vertical-align: middle;
  overflow: auto;
  top: 0;
  z-index: 2;
  min-height: 36px;
  align-items: center;
  gap: ${tokens.spacing[1]};
  ${({ sticky }) => sticky && "position: sticky;"}
`

export const StyledToolbarItem = styled.button`
  border: 0;
  display: flex;
  background: none;
  border-radius: ${tokens.spacing[1]};
  padding: ${tokens.spacing[2]};
  vertical-align: middle;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  gap: ${tokens.spacing[2]};
  height: ${tokens.spacing[7]};
  border: 1px solid transparent;

  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorForegroundHoverStrong};
  }

  &:disabled {
    color: ${({ theme }) => theme.colorTextDisabled};
    cursor: not-allowed;
  }

  ${({ theme, isActive }) =>
    isActive &&
    `
    border: 1px solid ${theme.colorBorderDecorativeStrong};
    background-color: ${theme.colorForegroundSelected};
  `}
`

export const StyledVerticalDivider = styled.div`
  width: 1px;
  height: 28px;
  background-color: ${({ theme }) => theme.colorBorderWeakest};
`

export const StyledHorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBorderWeakest};
`
