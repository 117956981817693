export const DEVICE_SEARCH_TABLE_ID = "device-search-table"
export const initialDeviceSearchColumnIds = ["name", "activities", "clientName", "locationName", "uptime"]
export const initialDeviceSearchSortBy = [{ id: "name", desc: false }]
export const exportDevicesWithDetailedInfoEndpoint = "/search/runner/csv"
export const exportDevicesWithCustomFieldsEndpoint = "/attributes/node/list/csv"
export const exportDevicesWithVisibleColumnsEndpoint = "/search/runner/export/csv"
export const exportDevicesExcludedColumnIds = ["activities"]
export const CUSTOM_FIELD_COLUMN_PREFIX = "customField_"
export const SOFTWARE_COLUMN_PREFIX = "software_"
export const MAX_COLUMNS_COUNT = 20
export const MAX_SOFTWARE_COLUMNS_COUNT = 5
export const MAX_TEXT_MULTILINE_LENGTH = 200
export const allowedCustomFieldTypes = [
  "CHECKBOX",
  "DATE",
  "TIME",
  "DATE_TIME",
  "NUMERIC",
  "DECIMAL",
  "DROPDOWN",
  "NODE_DROPDOWN",
  "CLIENT_DROPDOWN",
  "CLIENT_LOCATION_DROPDOWN",
  "MULTI_SELECT",
  "NODE_MULTI_SELECT",
  "CLIENT_MULTI_SELECT",
  "CLIENT_LOCATION_MULTI_SELECT",
  "TEXT",
  "EMAIL",
  "PHONE",
  "IP_ADDRESS",
  "URL",
  "TEXT_MULTILINE",
]
