import React, { memo } from "react"
import PropTypes from "prop-types"
import { includes, cond, always, T } from "ramda"

import { MAINTENANCE_SCRIPTS_ID, VM_POWER_STATES } from "js/includes/vmDashboard/constants"
import * as actions from "js/includes/vmDashboard/actions"
import { user } from "js/includes/common/utils"
import VMAction from "./VMAction"

export const vmActionNameMapper = {
  restart: "general.restart",
  power_on: "general.powerOn",
  power_off: "general.powerOff",
  shutdown: "general.shutdown",
  reset: "general.reset",
  suspend: "general.suspend",
  save: "general.save",
  delete: "general.delete",
  update_tools: "general.updateTools",
  pause: "general.pause",
  resume: "general.resume",
  start: "general.start",
  turn_off: "general.turnOff",
}

const GUEST_TOOLS_OUTDATED_STATUSES = ["guestToolsNeedUpgrade", "guestToolsSupportedOld"]

const getHyperVActions = (isPoweredOn, isPaused) => {
  return cond([
    [always(isPaused), always(["turn_off", "save", "resume", "reset"])],
    [always(isPoweredOn), always(["turn_off", "shutdown", "save", "pause", "reset"])],
    [T, always(["start", "delete"])],
  ])()
}

const getVmWareActions = (isPoweredOn, isSuspended, isPoweredOff, hasGuestToolsRunning, needsGuestToolsUpgrade) => {
  const actions = []
  if (isPoweredOn && !isSuspended && !isPoweredOff) {
    actions.push("suspend", "reset")

    if (hasGuestToolsRunning) {
      actions.push("restart", "shutdown")
    }

    if (needsGuestToolsUpgrade) {
      actions.push("update_tools")
    }
  }

  if (!isPoweredOff && (isPoweredOn || isSuspended)) {
    actions.push("power_off")
  }

  if (!isPoweredOn && (isSuspended || isPoweredOff)) {
    actions.push("power_on", "delete")
  }

  return actions
}

function getActions(powerStates = [], toolsRunningStatus = [], toolsVersionStatus = [], isVmHyperVGuest, isHostDown) {
  if (!powerStates.length || powerStates.length > 2 || isHostDown) {
    return []
  }

  const hasGuestToolsRunning = toolsRunningStatus.length === 1 && toolsRunningStatus[0] === "guestToolsRunning"
  const needsGuestToolsUpgrade =
    toolsVersionStatus.length === 1 && GUEST_TOOLS_OUTDATED_STATUSES.includes(toolsVersionStatus[0])
  const isPoweredOn = includes(VM_POWER_STATES.PoweredOn, powerStates)
  const isSuspended = includes(VM_POWER_STATES.Suspended, powerStates)
  const isPoweredOff = includes(VM_POWER_STATES.PoweredOff, powerStates)
  const isPaused = includes(VM_POWER_STATES.Paused, powerStates)

  return isVmHyperVGuest
    ? getHyperVActions(isPoweredOn, isPaused)
    : getVmWareActions(isPoweredOn, isSuspended, isPoweredOff, hasGuestToolsRunning, needsGuestToolsUpgrade)
}

export function getVMJobActions(powerStates, toolsRunningStatus, toolsVersionStatus, isHyperVHost, isHostDown) {
  if (!user("canRunScripts", [MAINTENANCE_SCRIPTS_ID])) return []
  const actionNames = getActions(powerStates, toolsRunningStatus, toolsVersionStatus, isHyperVHost, isHostDown)

  return actionNames.map(actionName => ({
    id: actions[`VM_GUEST_${actionName.toUpperCase()}_ACTION`].identifier,
    token: vmActionNameMapper[actionName],
  }))
}

const VMJobActions = memo(
  ({
    powerStates = [],
    toolsRunningStatus = [],
    toolsVersionStatus = [],
    disabled = false,
    onClickAction,
    isVmHyperVGuest,
    isHostDown,
    ButtonComponent,
  }) => {
    const actions = getActions(powerStates, toolsRunningStatus, toolsVersionStatus, isVmHyperVGuest, isHostDown)

    return actions.map(actionName => (
      <VMAction
        {...{
          key: actionName,
          actionName,
          disabled,
          onClickAction,
          ButtonComponent,
        }}
      />
    ))
  },
)

VMJobActions.propTypes = {
  disabled: PropTypes.bool,
  powerStates: PropTypes.arrayOf(PropTypes.string),
  toolsRunningStatus: PropTypes.arrayOf(PropTypes.string),
  toolsVersionStatus: PropTypes.arrayOf(PropTypes.string),
  onClickAction: PropTypes.func.isRequired,
}

export default VMJobActions
