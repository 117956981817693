import qs from "qs"

import { fetchJson } from "js/includes/common/utils"

import { BillingCycle } from "./ninjaPSAAgreements"

const BASE_URL = "/psa/invoice"

type InvoiceParams = { agreementId: number, billingCycle: BillingCycle }
type UpdateInvoicePayload = { products: { id: number, price: number, taxable: boolean }[] }

export function getInvoices(clientId?: number) {
  const _qs = qs.stringify({ clientId }, { addQueryPrefix: true })
  return fetchJson(`${BASE_URL}${_qs}`)
}

export function getArchivedInvoices() {
  return fetchJson(`${BASE_URL}/archived`)
}

export function getInvoiceById(invoiceId: number) {
  return fetchJson(`${BASE_URL}/${invoiceId}`)
}

export function getInvoicePreview({ agreementId, billingCycle }: InvoiceParams) {
  const _qs = qs.stringify({
    agreementId,
    billingCycle,
  })
  return fetchJson(`${BASE_URL}/preview?${_qs}`)
}

export function createInvoice({ agreementId, billingCycle }: InvoiceParams) {
  return fetchJson(BASE_URL, {
    options: {
      method: "POST",
      body: JSON.stringify({
        agreementId,
        billingCycle,
      }),
    },
  })
}

export function bulkApproveInvoices(invoiceIds: number[]) {
  return fetchJson(`${BASE_URL}/approve`, {
    options: {
      method: "POST",
      body: JSON.stringify(invoiceIds),
    },
  })
}

export function updateInvoice(invoiceId: number, payload: UpdateInvoicePayload) {
  return fetchJson(`${BASE_URL}/${invoiceId}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(payload),
    },
  })
}

export function resetInvoices(invoiceIds: number[]) {
  return fetchJson(`${BASE_URL}/reset`, {
    options: {
      method: "PUT",
      body: JSON.stringify(invoiceIds),
    },
  })
}

export function archiveInvoices(invoiceIds: number[]) {
  return fetchJson(`${BASE_URL}/archive`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        invoiceIds,
      }),
    },
  })
}

export function unarchiveInvoices(invoiceIds: number[]) {
  return fetchJson(`${BASE_URL}/unarchive`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        invoiceIds,
      }),
    },
  })
}
