import { concat, keys, range } from "ramda"
import { localizationKey, localized } from "js/includes/common/utils"
import { getReadableBytes } from "js/includes/common/_conversions"
import { defaultToZeroIfNotPositiveInt, isAnyImagePlan } from "js/includes/common/backup"

export const getSummary = ({ uploadedBytes, uploadedFiles, planType }) => {
  const uploadedBytesValue = defaultToZeroIfNotPositiveInt(uploadedBytes)
  const isSingular = uploadedFiles === 1

  const getLocalizedText = () => {
    if (isAnyImagePlan(planType)) {
      return isSingular ? localized("Volume") : localized("Volumes")
    }
    return isSingular ? localized("File") : localized("Files")
  }

  return (
    <span className="text-ellipsis">
      {`${getReadableBytes(uploadedBytesValue)}, ${uploadedFiles} ${getLocalizedText().toLowerCase()}`}
    </span>
  )
}

export const errorCodeShortMessageTokenMap = {
  1: localizationKey("({{errorCode}}) Network Storage error"),
  5: localizationKey("({{errorCode}}) IO Error"),
  20: localizationKey("({{errorCode}}) File/Folder error"),
  131: localizationKey("({{errorCode}}) Communication error"),
  301: localizationKey("({{errorCode}}) Failure threshold reached"),
  303: localizationKey("({{errorCode}}) Network Storage not found"),
  305: localizationKey("({{errorCode}}) Backup location missing"),
  306: localizationKey("({{errorCode}}) VSS snapshot deleted"),
  307: localizationKey("({{errorCode}}) Low volume disk space"),
  308: localizationKey("({{errorCode}}) Unable to query volume"),
  309: localizationKey("({{errorCode}}) No full disk access"),
  312: localizationKey("({{errorCode}}) Root folder not found"),
  315: localizationKey("({{errorCode}}) Credential issue"),
  316: localizationKey("({{errorCode}}) Credential issue"),
  317: localizationKey("({{errorCode}}) Credential issue"),
  318: localizationKey("({{errorCode}}) Configuration issue"),
  319: localizationKey("({{errorCode}}) Connection timed out"),
  321: localizationKey("({{errorCode}}) Volume size limit"),
  335: localizationKey("({{errorCode}}) Pre-script failure"),
  336: localizationKey("({{errorCode}}) Post-script failure"),
  360: localizationKey("({{errorCode}}) Cloud communication error"),
  1231: localizationKey("({{errorCode}}) Unable to connect"),
  1326: localizationKey("({{errorCode}}) Credential issue"),
  10053: localizationKey("({{errorCode}}) Connection error"),
  10054: localizationKey("({{errorCode}}) Connection error"),
}

export const getShortErrorCodeMessage = errorCode =>
  localized(errorCodeShortMessageTokenMap[errorCode] ?? localizationKey("({{errorCode}}) Internal Error"), {
    errorCode,
  })

export const internalErrorValueList = range(1, 132)
export const allErrorCodesList = concat(internalErrorValueList, keys(errorCodeShortMessageTokenMap))

export const getVolumePath = ({ id, name, location, fileSystem, size } = {}) =>
  (name ? `${name} ` : "") +
  `(${id})` +
  (location ? ` ${location}` : "") +
  (fileSystem || size ? "," : "") +
  (fileSystem ? ` ${fileSystem}` : "") +
  (size ? ` ${getReadableBytes(size)}` : "")
