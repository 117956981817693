import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import { resourceOptions } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData/options"

const ResourceSelect = ({ values, onChange }) => {
  return (
    <FormGroup controlId="type">
      <Col componentClass={ControlLabel} sm={4}>
        {localized("editors.policy.conditions.resource")}
      </Col>
      <Col sm={8}>
        <Dropdown
          options={resourceOptions}
          initialValue={values.resource}
          onChange={value => onChange("resource", value)}
        />
      </Col>
    </FormGroup>
  )
}

export default ResourceSelect
