import "./js/infrastructure/namespace"
import Backbone from "backbone"
import rivets from "rivets"
import "backbone-relational"
import "rivets-backbone-adapter"
import "@ninjamsp/ninja-backbone"
import "./js/infrastructure/rivets/setupRivetsOverrides"
import "@ninjamsp/ninja-chosen"
import "@ninjamsp/ninja-scroll-table-body"
import "@ninjamsp/ninja-smart-notification"
import "./js/infrastructure/rivetsExtensions/index"

import { extend } from "underscore"
import { ajaxSetup } from "jquery"

import { redirectToLogin, ninjaReportError, getPrefixedUrl } from "js/includes/common/utils"

import PsaConnect from "./js/includes/configuration/psa/PsaConnect"
import TeamViewerSystemConfig from "./js/includes/configuration/integrations/teamViewer/TeamViewerSystemConfig"
import MainApplication from "./js/MainApplication"
import MainController from "./js/MainController"
import Router from "./js/Router"
import DeviceHashTable from "js/infrastructure/backbone/DeviceHashTable"
import OrganizationHashTable from "js/infrastructure/backbone/OrganizationHashTable"
import templates from "./html/templates.html"
import policyEditorTemplate from "./html/editors/policy.html"
import customerEditorTemplate from "./html/editors/customer.html"
import userEditorTemplate from "./html/editors/user.html"
import UsersHashTable from "js/infrastructure/backbone/UsersHashTable"

export default async function initMainAppBackbone() {
  ajaxSetup({
    // Disable AJAX caching globally
    // If not done, IE will cache backbone fetch responses
    cache: false,
    dataType: "json",
    contentType: "application/json; charset=UTF-8",
    timeout: 60000,
    complete(xhr) {
      if (xhr.status === 401) {
        redirectToLogin()
      }
    },
    error(jqXHR, textStatus, errorThrown) {
      ninjaReportError(errorThrown)
    },
    beforeSend(xhr, settings) {
      settings.url = getPrefixedUrl(settings.url)
    },
  })

  window.application = new MainApplication()
  window.controller = new MainController()
  new Router()

  // Add the system dashboard as the default history in case
  // the app is refreshed on a tab which is then closed
  window.routeHistory = ["#/systemDashboard/overview"]

  // These are global collections for legacy support
  // Best to access them via the application and eliminate these over time
  window.deviceList = new DeviceHashTable()
  window.customerList = new OrganizationHashTable()
  window.userList = new UsersHashTable()
  window.reportList = []
  window.psaTicketTemplateList = window.application.get("infoCollectionContainer").get("psaTicketTemplates")
  window.dataspecs = {}
  window.teamViewerSystemConfig = new TeamViewerSystemConfig()
  window.psaConnect = new PsaConnect()

  // Adding validation to Relational Model
  extend(Backbone.RelationalModel.prototype, Backbone.Validation.mixin)

  // Since rivets 0.9 functions are not automatically executed in expressions.
  // If you need backward compatibilty, set this parameter to true
  rivets.configure({ executeFunctions: true })

  const templateContainer = document.createElement("div")
  templateContainer.innerHTML = templates + policyEditorTemplate + customerEditorTemplate + userEditorTemplate

  document.body.appendChild(templateContainer)
}
