export const cloudberrySectionInitialState = (cloudberry = {}) => ({
  mode: "OFF",
  nodeIds: [],
  consolePassword: "",
  bandwidthThrottleKb: 0,
  ...cloudberry,
})

export const translateLockhartMode = lockhartConfig => {
  switch (lockhartConfig.mode) {
    case "AUTO":
      return { ...lockhartConfig, mode: "ON", deployTo: ["SERVERS", "WORKSTATIONS"] }
    case "MANUAL":
      return { ...lockhartConfig, mode: "ON" }
    default:
      return lockhartConfig
  }
}

export const lockhartSectionInitialState = (lockhart = {}) => ({
  mode: "ON",
  excludedNodeIds: [],
  nodeIds: [],
  deployTo: [],
  networkLocation: {},
  bandwidthThrottle: {
    enabled: false,
    workHoursKbps: 400000,
    workHoursUserUnit: "mbps",
    nonWorkHoursKbps: 400000,
    nonWorkHoursUserUnit: "mbps",
    workSchedule: {
      startHour: 9,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
      weekDays: ["MON", "TUE", "WED", "THU", "FRI"],
    },
  },
  deletesScheduled: false,
  overrides: {
    bucket: null,
  },
  ...translateLockhartMode(lockhart),
})

export const initialState = (backups = {}) => {
  const { productCode, cloudberry, lockhart } = backups
  return {
    productCode: productCode || "LOCKHART",
    cloudberry: cloudberrySectionInitialState(cloudberry),
    lockhart: lockhartSectionInitialState(lockhart),
  }
}
