export * from "./conditionCodes"

const defaults = {
  CPU: {
    duration: 5,
    operator: "GE",
    threshold: 80,
    unit: "PERCENT",
  },
  Memory: {
    duration: 5,
    operator: "GE",
    threshold: 80,
    unit: "PERCENT",
    thresholdPercent: 80,
    thresholdBytes: 1,
    unitBytes: "GB",
  },
  DiskUsage: {
    duration: 30,
    operator: "GE",
    threshold: 80,
    unit: "PERCENT",
    thresholdPercent: 80,
    thresholdBytes: 1,
    unitBytes: "GB",
  },
  DataStoreFreeSpace: {
    duration: 30,
    operator: "GE",
    threshold: 80,
    unit: "PERCENT",
    thresholdPercent: 80,
    thresholdBytes: 1,
    unitBytes: "GB",
  },
  VMHostUptime: {
    days: 30,
  },
  BadSensorCount: {
    operator: "GE",
    threshold: 1,
  },
  DeviceDown: {
    durationAmount: 1,
    unit: "MINUTE",
    duration: 30,
  },
  SensorHealth: {
    name: [],
    state: [],
  },
  ProcessorUsage: {
    duration: 5,
    operator: "GE",
    threshold: 80,
    unit: "PERCENT",
  },
  GuestOperationMode: {
    mode: [],
  },
  SnapshotSize: {
    operator: "GE",
    threshold: 1073741824, //This represents 1GB in bytes
    unit: "BYTES",
    thresholdBytes: 1,
    unitBytes: "GB",
  },
  SnapshotLifeSpan: {
    operator: "GE",
    unit: "DAY",
    duration: 1,
  },
  CheckpointSize: {
    operator: "GE",
    threshold: 1073741824, //This represents 1GB in bytes
    unit: "BYTES",
    thresholdBytes: 1,
    unitBytes: "GB",
  },
  CheckpointLifeSpan: {
    operator: "GE",
    unit: "DAY",
    duration: 1,
  },
  GuestToolsNotRunning: {},
}

export default defaults
