import { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import tokens from "@ninjaone/tokens"
import { getColorFromProps } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import DashboardWidget, { widgetPropTypes } from "./DashboardWidget"
import HoverTooltip from "./HoverTooltip"
import Body from "../../Typography/Body"
import Text from "../../Text"
import Skeleton from "../../Skeleton/Skeleton"

const TooltipContent = ({ labelText, value, percentage, color, theme }) => {
  return (
    <div style={{ borderLeft: `5px solid ${getColorFromProps({}, { color, theme })}` }}>
      <div
        style={{
          padding: `${tokens.spacing[1]} ${tokens.spacing[2]}`,
          borderBottom: `1px solid ${theme.colorBorderWeakest}`,
        }}
      >
        <Body color="colorTextWeak" type="bodyXs" fontWeight={tokens.typography.fontWeight.medium}>
          {labelText}
        </Body>
      </div>
      <div style={{ padding: tokens.spacing[1] }}>
        <div
          style={{
            display: "inline-block",
            borderRight: `1px solid ${theme.colorBorderWeak}`,
            padding: `${tokens.spacing[1]} ${tokens.spacing[2]}`,
          }}
        >
          <Body type="headingS">{percentage}</Body>
        </div>
        <div style={{ display: "inline-block", padding: `${tokens.spacing[1]} ${tokens.spacing[2]}` }}>
          <Body type="headingS">{value}</Body>
        </div>
      </div>
    </div>
  )
}

const StyledLegendTextCell = styled.td`
  padding: 0;
  > span > span {
  // this allows allocating the space for the text bold to avoid legend texts moving when they are bold on hover
  &::after {
    display: block;
    content: "${({ labelText }) => labelText}";
    font-weight: ${tokens.typography.fontWeight.medium};
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
    }
  }
`

const MeterComplexWidget = ({ barTitleText, data = [], isLoading, ...widgetProps }) => {
  const theme = useTheme()
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null)
  const [hoveredLegendItemIndex, setHoveredLegendItemIndex] = useState(null)
  const totalCount = data.reduce((acc, item) => acc + item.value ?? 0, 0)
  const legendData =
    isLoading || !data.length ? [{ labelText: localized("No data"), value: 0, color: "colorAlertNeutralWeak" }] : data

  const getBarStyles = (item, index) => {
    const backgroundColor = getColorFromProps(
      {},
      { color: hoveredItemIndex === index && item.hoverColor ? item.hoverColor : item.color, theme },
    )
    return {
      display: "block",
      padding: 0,
      width: "100%",
      backgroundColor,
      opacity: hoveredLegendItemIndex === null || hoveredLegendItemIndex === index ? 1 : 0.3,
      height: hoveredItemIndex === index ? "24px" : "20px",
      cursor: "pointer",
      border: `1px solid ${backgroundColor}`,
      borderTopLeftRadius: index === 0 ? "2px" : 0,
      borderBottomLeftRadius: index === 0 ? "2px" : 0,
      borderTopRightRadius: index === data.length - 1 ? "2px" : 0,
      borderBottomRightRadius: index === data.length - 1 ? "2px" : 0,
      transition: "all 0.1s ease-in",
      outlineColor: theme.colorForegroundFocus,
    }
  }

  return (
    <DashboardWidget height={tokens.grid.itemHeight.XS} {...widgetProps}>
      <div>
        <Body fontWeight={tokens.typography.fontWeight.medium}>{barTitleText}</Body>
      </div>
      <table style={{ width: "100%", marginTop: tokens.spacing[1], borderCollapse: "collapse", tableLayout: "fixed" }}>
        <tbody>
          <tr>
            {isLoading ? (
              <td width="100%">
                <Skeleton width="100%" />
              </td>
            ) : data.length ? (
              data.map((item, index) => {
                const percentage = ((item.value ?? 0) * 100) / totalCount
                return (
                  <HoverTooltip
                    key={item.labelText}
                    contentRenderer={() => {
                      return (
                        <TooltipContent
                          {...{ ...item, percentage: `${isNaN(percentage) ? "-" : percentage.toFixed(1)}%`, theme }}
                        />
                      )
                    }}
                  >
                    <td
                      style={{
                        width: `${percentage}%`,
                        borderLeft: index === 0 ? 0 : `1px solid ${theme.colorBackgroundWidget}`,
                        height: "24px",
                        padding: 0,
                      }}
                      onMouseEnter={() => setHoveredItemIndex(index)}
                      onMouseLeave={() => setHoveredItemIndex(null)}
                    >
                      {item.action ? (
                        <button style={getBarStyles(item, index)} onClick={item.action} />
                      ) : (
                        <div
                          // we need to show the tooltip when the user tabs throught the bars
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                          tabIndex={0}
                          style={getBarStyles(item, index)}
                        />
                      )}
                    </td>
                  </HoverTooltip>
                )
              })
            ) : (
              <td
                style={{
                  height: "24px",
                }}
              >
                <div
                  style={{
                    height: "20px",
                    borderRadius: "2px",
                    backgroundColor: theme.colorAlertNeutralWeak,
                  }}
                ></div>
              </td>
            )}
          </tr>
        </tbody>
      </table>
      <div style={{ marginTop: tokens.spacing[2] }}>
        <div style={{ textAlign: "center", lineHeight: 0 }}>
          {legendData.map((item, index) => (
            <div
              key={item.labelText}
              style={{ display: "inline-block", marginRight: tokens.spacing[3], height: "18px", lineHeight: "18px" }}
            >
              <table style={{ borderCollapse: "collapse", height: "100%" }}>
                <tbody>
                  <tr
                    onMouseEnter={() => setHoveredLegendItemIndex(index)}
                    onMouseLeave={() => setHoveredLegendItemIndex(null)}
                  >
                    <td style={{ padding: 0 }}>
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: getColorFromProps({}, { color: item.color, theme }),
                          borderRadius: "2px",
                          marginRight: tokens.spacing[1],
                        }}
                      />
                    </td>
                    <StyledLegendTextCell labelText={item.labelText}>
                      <Text
                        as="span"
                        type="bodyXs"
                        color="colorTextWeak"
                        fontWeight={
                          hoveredLegendItemIndex === index
                            ? tokens.typography.fontWeight.medium
                            : tokens.typography.fontWeight.regular
                        }
                      >
                        {item.labelText}
                      </Text>
                    </StyledLegendTextCell>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </DashboardWidget>
  )
}

MeterComplexWidget.propTypes = {
  ...widgetPropTypes,
  isLoading: PropTypes.bool,
  barTitleText: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      labelText: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string,
    }),
  ).isRequired,
}

export default MeterComplexWidget
