import { fetchJson } from "js/includes/common/utils"

function getUrl(deviceId, userType) {
  switch (userType) {
    case "TECHNICIAN":
      return `/control/node/${deviceId}/session/request`
    case "END_USER":
      return `/end-user/connect/ninja-connect/${deviceId}`
    default:
      throw new Error("Invalid user type")
  }
}

const requestNinjaControlBody = {
  session_settings: {
    confirmView: true,
    confirmControl: true,
    confirmTransfer: true,
    recording: "allow",
  },
}
export const requestNinjaControl = (deviceId, userType) => {
  const requestUrl = getUrl(deviceId, userType)
  return fetchJson(requestUrl, { options: { method: "POST", body: JSON.stringify(requestNinjaControlBody) } })
}

export const requestMDMNinjaControl = deviceId => {
  return fetchJson(`/mdm/node/${deviceId}/connect`, {
    options: { method: "POST", body: JSON.stringify(requestNinjaControlBody) },
  })
}
