import { memo } from "react"

import { FileAttachment } from "@ninjaone/components"

import { getSignedUrlFromAttachmentId } from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { downloadFile, IMAGE_EXTENSIONS, localizationKey, localized, ninjaReportError } from "js/includes/common/utils"
import { ImagePreviewModal } from "js/includes/components/ImagePreviewModal"

const variantsIconData = {
  SUSPICIOUS: {
    variant: "ERROR",
    tooltipToken: localizationKey("This attachment has been flagged as suspicious"),
  },
  FAILURE: {
    variant: "ERROR",
    tooltipToken: localizationKey("There was an error while uploading the attachment"),
  },
  PROCESSING: {
    variant: "PROCESSING",
    tooltipToken: localizationKey("Processing"),
  },
}

const getVariantData = uploadStatus => {
  const data = variantsIconData[uploadStatus]
  if (!data) {
    return null
  }
  return {
    variant: data.variant,
    iconTooltip: localized(data.tooltipToken),
  }
}

export const Attachment = memo(({ attachment }) => {
  const {
    id: attachmentId,
    uploadStatus,
    file,
    metadata: { name, extension },
  } = attachment

  const variantData = getVariantData(uploadStatus)
  const canClick = !["PROCESSING", "FAILURE", "SUSPICIOUS"].includes(uploadStatus)

  const onDownload = async () => {
    try {
      const { signedUrl } = await getSignedUrlFromAttachmentId(attachmentId)

      if (signedUrl) {
        downloadFile(signedUrl, name)
      }
    } catch (error) {
      ninjaReportError(error)
    }
  }

  const handleClick = () => {
    if (IMAGE_EXTENSIONS.includes(extension)) {
      showModal(<ImagePreviewModal {...{ onDownload, attachmentId }} />)
    } else {
      onDownload()
    }
  }

  return (
    <FileAttachment
      {...{
        file,
        matchWidth: 1,
        ...(canClick && { onClick: handleClick }),
        ...variantData,
      }}
    />
  )
})
