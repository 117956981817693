export class NinjaResponseError extends Error {
  data

  constructor(data: { errorMessage: string }) {
    super(data.errorMessage)
    this.data = data

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NinjaResponseError)
    }
  }
}

export class NinjaNotAuthenticatedError extends Error {
  constructor() {
    super("User could not be authenticated")

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NinjaNotAuthenticatedError)
    }
  }
}

export class WampSessionNotAvailableError extends Error {
  constructor(message: string) {
    super()
    this.message = message || "Session not available"
    this.name = "WAMP SESSION ERROR"

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, WampSessionNotAvailableError)
    }
  }
}

export class WampError extends Error {
  data

  constructor(data: { error: string }) {
    super(data?.error)
    this.data = data

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, WampError)
    }
  }
}

export type MfaValidationError = Error & {
  isHandledMfaError: boolean
}
