import { useCallback } from "react"
import { $patchStyleText } from "@lexical/selection"
import { $createParagraphNode, $getRoot, $getSelection } from "lexical"
import { is } from "ramda"

import { TextLightIcon, TextSizeLightIcon } from "@ninjaone/icons"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../../../Text"

import { Dropdown, DROPDOWN_TEXT_COLOR, DropdownItem } from "../../../Components"

import { StyledToolbarItem } from "../styled"
import { FONT_SIZE_OPTIONS } from "../utils"

const isFunction = is(Function)

const FONT_FAMILY_OPTIONS = [
  ["Inter", "Inter"],
  ["Arial", "Arial"],
]

export const FontSection = ({ dropdownClassName, editor, value, type, disabled = false }) => {
  const handleClick = useCallback(
    option => {
      editor.update(() => {
        const selection = $getSelection()
        if (selection) {
          $patchStyleText(selection, { [type]: option })
        } else {
          const root = $getRoot()
          root.clear()
          const paragraph = $createParagraphNode()
          root.append(paragraph)
          $patchStyleText(paragraph.select(), { [type]: option })
        }
      })
    },
    [editor, type],
  )

  return (
    <Dropdown
      dropdownClassName={dropdownClassName}
      disabled={disabled}
      ButtonComponent={StyledToolbarItem}
      ButtonIcon={type === "font-family" ? TextLightIcon : TextSizeLightIcon}
      buttonLabel={value}
      buttonAriaLabel={
        type === "font-family"
          ? localized("Formatting options for font family")
          : localized("Formatting options for font size")
      }
    >
      {(type === "font-family" ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(([option, label]) => (
        <DropdownItem key={option} onClick={() => handleClick(option)}>
          <Text color={DROPDOWN_TEXT_COLOR} size="sm">
            {isFunction(label) ? label() : label}
          </Text>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}
