import React, { memo } from "react"
import { useSelector } from "react-redux"
import { Form, FormGroup, Col, Row, ControlLabel, FormControl } from "react-bootstrap"
import { map } from "ramda"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import {
  localized,
  localizationKey,
  getPsaTypeLabel,
  isTicketingEnabledFromSettings,
  isPsaEnabled,
  isVmHyperVGuest,
  isPsaGenericWebhook,
  psaUsesTemplate,
} from "js/includes/common/utils"
import Modal from "js/includes/components/Modal"
import Dropdown from "js/includes/editors/Policy/PolicyEditor/formComponents/Dropdown"
import ConditionSelectModal from "./ConditionSelectModal"
import Tooltip from "js/includes/components/Tooltip"
import {
  ResetThresholdOptions,
  NotificationOptions,
  ResetThresholdOptionsWithoutManual,
  TicketingOptions,
  genericWebhookTicketingOptions,
} from "js/includes/common/_options"
import { severityOptions, priorityOptions } from "../conditionData"
import { useForm } from "js/includes/common/hooks"
import { conditionUidValidation } from "./validation"
import { resetStatuses } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import showModal from "js/includes/common/services/showModal"
import TicketRulesetOptions from "js/includes/editors/Policy/Models/Conditions/TicketRulesetOptions"
import { defaultConditionUids } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/defaults"
import ConditionChannelSelect from "js/includes/components/Notifications/ConditionChannelSelect"

const MAX_LENGTH_CONDITION_NAME = "250"

const ticketingTemplateOptions = value => {
  const options = map(
    template => ({
      value: template.get("id"),
      text: template.get("name"),
    }),
    window.psaTicketTemplateList.models,
  )

  return {
    value,
    items: [{ value: null, text: localized("Default ticket template") }, ...options],
  }
}

export default memo(function ConditionsModal({ onCloseModal, onSaveCondition, nodeRole, condition, editing }) {
  const psaType = useSelector(({ session }) => session.psaType)
  const psaIsEnabled = isPsaEnabled()
  const isGenericWebhookPsaType = isPsaGenericWebhook(psaType)
  const psaTicketingOptions = isGenericWebhookPsaType ? genericWebhookTicketingOptions : TicketingOptions
  const psaTypeLabel = psaIsEnabled ? getPsaTypeLabel() : ""

  const { values, validation, onChange, validateForm } = useForm({
    fields: {
      editing: editing || false,
      enabled: condition.enabled || true,
      displayName: condition.displayName || "",
      conditionName: condition.conditionName || "",
      conditionUid: condition.conditionUid || "",
      inheritance: condition.inheritance || {
        inherited: false,
        overridden: false,
        sourcePolicyId: null,
      },
      notificationAction: condition.notificationAction || NotificationOptions.value,
      severity: condition.severity || severityOptions.value,
      priority: condition.priority || priorityOptions.value,
      ticketingAction: condition.ticketingAction || psaTicketingOptions.value,
      ticketTemplateId: condition.ticketTemplateId || null,
      parameters: condition.parameters || {},
      ticketRulesetId: condition.ticketRulesetId || null,

      resetAfterChecked: true,
      resetIfNotMetForChecked: false,
      resetAfter: ResetThresholdOptionsWithoutManual.value,
      resetIfNotPresent: ResetThresholdOptionsWithoutManual.value,
      resetThreshold: ResetThresholdOptions.value,
      notifyOnReset: condition.notifyOnReset || false,
      channels: condition.channels ?? [],
      ...resetStatuses(condition),
    },
    validate: {
      conditionUid: conditionUidValidation,
    },
  })

  function onSave(e) {
    e.preventDefault()
    e.stopPropagation()

    const isValidForm = validateForm()

    if (isValidForm) {
      onSaveCondition({ ...values, createNinjaTicket: !!values.ticketRulesetId })
    }
  }

  return (
    <Form horizontal onSubmit={onSave}>
      <Modal
        submittable
        title={localizationKey("Condition")}
        close={onCloseModal}
        saveText={localizationKey("Save")}
        dialogClassName="modal-lg modal-form-groups"
      >
        <Row>
          <Col xs={8}>
            <FormGroup
              controlId="formHorizontalEmail"
              validationState={validation.success.conditionUid === false ? "error" : null}
            >
              <Col componentClass={ControlLabel} sm={4}>
                {localized("Condition")}
              </Col>
              <Col sm={8}>
                <p className="form-control-static text-left">
                  <button
                    className="btn-link"
                    onClick={e => {
                      e.preventDefault()
                      showModal(
                        <ConditionSelectModal
                          {...{
                            disabled: values.editing,
                            conditionUid: values.conditionUid,
                            parameters: values.parameters,
                            onChangeCondition: condition => {
                              if (
                                isVmHyperVGuest(nodeRole) &&
                                condition.conditionUid === defaultConditionUids.GuestToolsNotRunning
                              ) {
                                onChange("resetIfNotMetForChecked", true)
                              }
                              onChange(condition)
                            },
                          }}
                        />,
                        { withProvider: true },
                      )
                    }}
                  >
                    {values.conditionName || localized("Select a condition")}
                  </button>
                </p>
                <em className="invalid">{validation.message.conditionUid}</em>
              </Col>
            </FormGroup>

            <FormGroup controlId="displayName">
              <Col componentClass={ControlLabel} sm={4}>
                {localized("Display Name")}
              </Col>
              <Col sm={8}>
                <FormControl
                  value={values.displayName}
                  type="text"
                  maxLength={MAX_LENGTH_CONDITION_NAME}
                  placeholder={localized("(optional)")}
                  className="italic-placeholder"
                  onChange={e => onChange("displayName", e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="severity">
              <Col componentClass={ControlLabel} sm={4}>
                {localized("Severity")}
                <Tooltip
                  token={localizationKey(
                    "Severity is the extent to which a defect can affect the software. In other words it defines the impact that a given defect has on the system.",
                  )}
                />
              </Col>
              <Col sm={8}>
                <Dropdown
                  initialValue={values.severity || severityOptions.value}
                  options={severityOptions}
                  onChange={value => onChange("severity", value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="priority">
              <Col componentClass={ControlLabel} sm={4}>
                {localized("Priority")}
                <Tooltip
                  token={localizationKey(
                    "Priority defines the order in which we should resolve a defect. Should we fix it now, or can it wait?",
                  )}
                />
              </Col>
              <Col sm={8}>
                <Dropdown
                  initialValue={values.priority || priorityOptions.value}
                  options={priorityOptions}
                  onChange={value => onChange("priority", value)}
                />
              </Col>
            </FormGroup>

            <Row>
              <Col componentClass={ControlLabel} sm={4}>
                {localized("Auto-reset")}
              </Col>
              <Col sm={8}>
                <FormGroup controlId="autoReset">
                  <Col sm={8}>
                    <span className="pos-rel display-flex align-items-center">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue"
                        increaseArea="20%"
                        checked={values.resetAfterChecked}
                        onChange={() => onChange("resetAfterChecked", !values.resetAfterChecked)}
                      />
                      <span className="p-l-sm">{localized("After")}</span>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <div className="display-flex">
                      <Dropdown
                        initialValue={values.resetAfter || ResetThresholdOptionsWithoutManual.value}
                        options={ResetThresholdOptionsWithoutManual}
                        onChange={value => onChange("resetAfter", parseFloat(value))}
                      />
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup controlId="resetIfNotMetForChecked">
                  <Col sm={8}>
                    <span className="pos-rel display-flex align-items-center">
                      <Checkbox
                        checkboxClass="icheckbox_square-blue"
                        increaseArea="20%"
                        checked={values.resetIfNotMetForChecked}
                        onChange={() => onChange("resetIfNotMetForChecked", !values.resetIfNotMetForChecked)}
                      />
                      <span className="p-l-sm">{localized("When not met for")}</span>
                    </span>
                  </Col>
                  <Col sm={4}>
                    <div className="display-flex">
                      <Dropdown
                        initialValue={values.resetIfNotPresent || ResetThresholdOptionsWithoutManual.value}
                        options={ResetThresholdOptionsWithoutManual}
                        onChange={value => onChange("resetIfNotPresent", parseFloat(value))}
                      />
                    </div>
                  </Col>
                </FormGroup>
                {!values.resetAfterChecked && !values.resetIfNotMetForChecked ? (
                  <Row style={{ marginTop: 6 }}>
                    <Col sm={12}>
                      <em className="info">
                        {" "}
                        {localized("*Auto-reset options not selected. Manual reset required.")}{" "}
                      </em>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <ConditionChannelSelect
              {...{
                channels: values.channels,
                onChange,
                padding: [0, 3],
              }}
            />
            <hr />
            <FormGroup controlId="notificationsSelect">
              <Col componentClass={ControlLabel} sm={4}>
                {localized("Notify Technicians")}
              </Col>
              <Col sm={8}>
                <Dropdown
                  options={NotificationOptions}
                  initialValue={values.notificationAction || NotificationOptions.value}
                  onChange={value => onChange("notificationAction", value)}
                />
              </Col>
            </FormGroup>

            <FormGroup controlId="notifyReset">
              <Col componentClass={ControlLabel} sm={4}>
                {localized("Notify on Reset")}
              </Col>
              <Col sm={8} className="display-flex">
                <Checkbox
                  checkboxClass="icheckbox_square-blue"
                  increaseArea="20%"
                  checked={values.notifyOnReset}
                  onChange={() => onChange("notifyOnReset", !values.notifyOnReset)}
                />
              </Col>
            </FormGroup>

            {psaIsEnabled && (
              <>
                <FormGroup controlId="ticketing">
                  <Col componentClass={ControlLabel} sm={4} {...(isGenericWebhookPsaType && {
                    className: "text-ellipsis",
                    title: psaTypeLabel,
                  })}>
                    {psaTypeLabel}
                  </Col>
                  <Col sm={8}>
                    <Dropdown
                      initialValue={values.ticketingAction || psaTicketingOptions.value}
                      options={psaTicketingOptions}
                      onChange={value =>
                        onChange({ ticketingAction: value, ...(value === "NONE" && { ticketTemplateId: null }) })
                      }
                    />
                  </Col>
                </FormGroup>

                {values.ticketingAction !== "NONE" && psaUsesTemplate(psaType) && (
                  <FormGroup controlId="ticketingTemplate">
                    <Col componentClass={ControlLabel} sm={4}>
                      {psaTypeLabel} {localized("Template")}
                    </Col>
                    <Col sm={8}>
                      <Dropdown
                        initialValue={values.ticketTemplateId}
                        options={ticketingTemplateOptions(values.ticketTemplateId)}
                        onChange={value => onChange("ticketTemplateId", value ? parseInt(value, 10) : value)}
                      />
                    </Col>
                  </FormGroup>
                )}
              </>
            )}

            {isTicketingEnabledFromSettings() && (
              <FormGroup>
                <TicketRulesetOptions
                  ticketRuleId={values.ticketRulesetId}
                  setTicketRuleId={value => onChange("ticketRulesetId", value)}
                />
              </FormGroup>
            )}
          </Col>
        </Row>
      </Modal>
    </Form>
  )
})
