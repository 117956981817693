import PropTypes from "prop-types"
import { IconButton, Switch } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { localized } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import { isInheritedRow } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"

export const getParentGlobalProxy = (isProxyEnabled, globalProxy) => {
  const isGlobalProxyInherited = isInheritedRow(globalProxy)
  return { isGlobalProxyInherited, isProxyActivationAllowed: !isGlobalProxyInherited || isProxyEnabled }
}

function GlobalProxySwitchActivation({ isProxyEnabled, updateActiveProxy }) {
  return (
    <Flex marginTop={tokens.spacing[2]} alignItems="center">
      <Switch checked={isProxyEnabled} onChange={updateActiveProxy} labelText={localized("Activate proxy settings")} />
      <IconButton
        tooltipAlignRight
        size="sm"
        tooltip={localized(
          "If not enabled, any configured proxy settings are ignored and will not apply to assigned devices",
        )}
      >
        <RegularInfoCircleIcon />
      </IconButton>
    </Flex>
  )
}

GlobalProxySwitchActivation.propTypes = {
  isProxyEnabled: PropTypes.bool,
  updateActiveProxy: PropTypes.func.isRequired,
}

export default GlobalProxySwitchActivation
