import { useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import styled from "@emotion/styled"
import { $isCodeHighlightNode } from "@lexical/code"
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical"

import { BoldLightIcon, CodeLightIcon, ItalicLightIcon, LinkLightIcon, UnderlineLightIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { getDOMRangeRect, getSelectedNode, setFloatingElemPosition } from "../utils"

const StyledPopup = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colorBackground};
  padding: 2px ${tokens.spacing[1]};
  gap: ${tokens.spacing[1]};
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  border-radius: ${tokens.spacing[1]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  height: ${tokens.spacing[9]};
  transition: opacity 0.5s;
  will-change: transform;
  box-shadow: ${({ theme }) => theme.elevationWeak};
`

const StyledPopupItem = styled.button`
  border: 0;
  display: flex;
  background: none;
  border-radius: ${tokens.borderRadius[1]};
  padding: ${tokens.spacing[2]};
  cursor: pointer;
  vertical-align: middle;
  color: ${({ theme }) => theme.colorTextStrong};
  border: 1px solid transparent;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    border: 1px solid ${theme.colorBorderDecorativeStrong};
    background-color: ${theme.colorForegroundSelected};
  `}
`

const TextFormatFloatingToolbar = ({
  editor,
  anchorElem,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  isCode,
  isStrikethrough,
  isSubscript,
  isSuperscript,
  setIsLinkEditMode,
}) => {
  const popupCharStylesEditorRef = useRef(null)

  const insertLink = useCallback(() => {
    if (isLink) {
      setIsLinkEditMode(true)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    } else {
      setIsLinkEditMode(false)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://")
    }
  }, [editor, isLink, setIsLinkEditMode])

  const mouseMoveListener = useCallback(e => {
    if (popupCharStylesEditorRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== "none") {
        const x = e.clientX
        const y = e.clientY
        const elementUnderMouse = document.elementFromPoint(x, y)

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = "none"
        }
      }
    }
  }, [])

  const mouseUpListener = useCallback(e => {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== "auto") {
        popupCharStylesEditorRef.current.style.pointerEvents = "auto"
      }
    }
  }, [])

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener("mousemove", mouseMoveListener)
      document.addEventListener("mouseup", mouseUpListener)

      return () => {
        document.removeEventListener("mousemove", mouseMoveListener)
        document.removeEventListener("mouseup", mouseUpListener)
      }
    }
  }, [mouseMoveListener, mouseUpListener])

  const $updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection()

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current
    const nativeSelection = window.getSelection()

    if (popupCharStylesEditorElem === null) return

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement)

      setFloatingElemPosition(rangeRect, popupCharStylesEditorElem, anchorElem, isLink)
    }
  }, [editor, anchorElem, isLink])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        $updateTextFormatFloatingToolbar()
      })
    }

    window.addEventListener("resize", update)
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update)
    }

    return () => {
      window.removeEventListener("resize", update)
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update)
      }
    }
  }, [editor, $updateTextFormatFloatingToolbar, anchorElem])

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateTextFormatFloatingToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, $updateTextFormatFloatingToolbar])

  return (
    <StyledPopup ref={popupCharStylesEditorRef}>
      {editor.isEditable() && (
        <>
          <StyledPopupItem
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
            }}
            aria-label={localized("Format text as bold")}
            isActive={isBold}
          >
            <BoldLightIcon />
          </StyledPopupItem>
          <StyledPopupItem
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
            }}
            aria-label={localized("Format text as italics")}
            isActive={isItalic}
          >
            <ItalicLightIcon />
          </StyledPopupItem>
          <StyledPopupItem
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
            }}
            aria-label={localized("Format text to underlined")}
            isActive={isUnderline}
          >
            <UnderlineLightIcon />
          </StyledPopupItem>
          <StyledPopupItem type="button" onClick={insertLink} aria-label={localized("Insert link")}>
            <LinkLightIcon />
          </StyledPopupItem>
          <StyledPopupItem
            type="button"
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")
            }}
            aria-label={localized("Insert code block")}
            isActive={isCode}
          >
            <CodeLightIcon />
          </StyledPopupItem>
        </>
      )}
    </StyledPopup>
  )
}

function useFloatingTextFormatToolbar(editor, anchorElem, setIsLinkEditMode) {
  const [isText, setIsText] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isCode, setIsCode] = useState(false)

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) return

      const selection = $getSelection()
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) || rootElement === null || !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false)
        return
      }

      if (!$isRangeSelection(selection)) return

      const node = getSelectedNode(selection)

      // Update text format
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsCode(selection.hasFormat("code"))

      // Update links
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      if (!$isCodeHighlightNode(selection.anchor.getNode()) && selection.getTextContent() !== "") {
        setIsText($isTextNode(node) || $isParagraphNode(node))
      } else {
        setIsText(false)
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, "")
      if (!selection.isCollapsed() && rawTextContent === "") {
        setIsText(false)
        return
      }
    })
  }, [editor])

  useEffect(() => {
    document.addEventListener("selectionchange", updatePopup)
    return () => {
      document.removeEventListener("selectionchange", updatePopup)
    }
  }, [updatePopup])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false)
        }
      }),
    )
  }, [editor, updatePopup])

  if (!isText) return null

  return createPortal(
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElem={anchorElem}
      isLink={isLink}
      isBold={isBold}
      isItalic={isItalic}
      isUnderline={isUnderline}
      isCode={isCode}
      setIsLinkEditMode={setIsLinkEditMode}
    />,
    anchorElem,
  )
}

/**
 * Must be defined before FloatingLinkEditorPlugin
 */
export const FloatingTextFormatToolbarPlugin = ({ anchorElem = document.body, setIsLinkEditMode }) => {
  const [editor] = useLexicalComposerContext()
  return useFloatingTextFormatToolbar(editor, anchorElem, setIsLinkEditMode)
}
