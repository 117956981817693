import { toBytes, joinByComma } from "js/includes/common/utils"
import { flatten, map, concat, compose } from "ramda"

export const XML = "xml"
export const HTML = "html"
export const PDF = "pdf"
export const LOG = "log"
export const CSV = "csv"
export const TXT = "txt"
export const ZIP = "zip"
export const RAR = "rar"
export const TAR = "tar"
export const MS_CABINET_ARCHIVE = "cab"
export const MP3 = "mp3"
export const EML = "eml"
export const RTF = "rtf"

export const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif"]

export const WORD_EXTENSIONS = ["dot", "wbk", "doc", "docx", "rtf"]

export const EXCEL_EXTENSIONS = ["xls", "xlsx", "ods"]

export const POWER_POINT_EXTENSIONS = ["ppt", "pptx", "pps", "ppsx", "sldx"]

export const VISIO_EXTENSIONS = ["vsd", "vsdx"]

export const WEB_EXTENSIONS = [XML, HTML]

export const COMPRESSED_FILE_EXTENSIONS = [ZIP, RAR, TAR]

export const MISCELLANEOUS_EXTENSIONS = [MS_CABINET_ARCHIVE, TXT, LOG, PDF, CSV, MP3, EML]

const generateFileExtensions = compose(map(concat(".")), flatten)

export const ATTACHMENT_FILE_EXTENSIONS = generateFileExtensions([
  IMAGE_EXTENSIONS,
  MISCELLANEOUS_EXTENSIONS,
  WORD_EXTENSIONS,
  EXCEL_EXTENSIONS,
  POWER_POINT_EXTENSIONS,
  VISIO_EXTENSIONS,
  WEB_EXTENSIONS,
  COMPRESSED_FILE_EXTENSIONS,
])

export const INLINE_FILE_EXTENSIONS = generateFileExtensions([IMAGE_EXTENSIONS])

export const twentyMiB = toBytes(20, "MiB")

export const IMAGE_TYPES = ["image/png", "image/jpg", "image/jpeg"]

export const acceptedAttachmentFiles = joinByComma(ATTACHMENT_FILE_EXTENSIONS)
export const acceptedInlineFiles = joinByComma(INLINE_FILE_EXTENSIONS)
