import React, { memo } from "react"
import {
  OperatorSelect,
  ThresholdStepper,
  DurationSelect,
  UnitSelect,
  UnitBytesSelect,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/modals/sections/formGroups"
import {
  thresholdBytesOptions,
  unitBytesOptions,
  unitOptions,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/conditionData"

export default memo(({ values, onChange, onUnitChange, onThresholdChange, validation }) => {
  const { unit } = values

  return (
    <>
      <OperatorSelect
        {...{
          values,
          onChange,
          validation,
        }}
      />

      {unit === "PERCENT" ? (
        <ThresholdStepper
          {...{
            values,
            onChange: onThresholdChange,
            validation,
          }}
        />
      ) : (
        <UnitBytesSelect
          {...{
            values,
            onChange,
            validation,
            unitBytesOptions,
            thresholdBytesOptions,
          }}
        />
      )}

      <UnitSelect
        {...{
          onChange: onUnitChange,
          values,
          validation,
          unitOptions,
        }}
      />

      <DurationSelect
        {...{
          values,
          onChange,
          validation,
        }}
      />
    </>
  )
})
