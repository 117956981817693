import styled from "@emotion/styled"
import { sizer } from "@ninjaone/utils"
import { Box } from "js/includes/components/Styled"
import ChannelSelect from "js/includes/components/Notifications/ChannelSelect"

const StyledBox = styled(Box)`
  .control-label:last-of-type {
    margin-right: ${sizer(4)};
    padding: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
`

const ConditionChannelSelect = ({ channels, onChange, padding = [0, 6] }) => (
  <StyledBox {...{ padding }}>
    <ChannelSelect {...{ channels, onChange, isHorizontal: true }} />
  </StyledBox>
)

export default ConditionChannelSelect
