import { useEffect } from "react"
import { compose, filter, groupBy, map, prop, toPairs } from "ramda"
import styled from "@emotion/styled"
import SelectFormGroup from "js/includes/components/Form/SelectFormGroup"
import { contains, fetchJson, reportErrorAndShowMessage } from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import { useMountedState } from "js/includes/common/hooks"
import { Box } from "js/includes/components/Styled"
import { getChannelIcon, getChannelTypeName } from "./common"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const StyledIcon = styled(Box)`
  display: inline-block;
  margin-right: 8px;
  height: 16px;

  img,
  svg {
    max-height: 16px;
    max-width: 16px;
    color: ${colors.ninjaBlueSaturated};
  }
`

const ChannelSelect = ({
  useSelectStyling,
  channels,
  onChange,
  isHorizontal,
  label = localizationKey("Channel(s)"),
  disabled = false,
  optionsFilter,
}) => {
  const [loading, setLoading] = useMountedState(false)
  const [channelOptions, setChannelOptions] = useMountedState([])

  useEffect(() => {
    const getChannelList = async () => {
      setLoading(true)
      try {
        const channelList = await fetchJson("/channel/list/brief")
        setChannelOptions(channelList)
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setLoading(false)
      }
    }
    getChannelList()
  }, [setChannelOptions, setLoading])

  const getCategorizedChannelOptions = () =>
    compose(
      options => (optionsFilter ? filter(optionsFilter)(options) : options),
      map(([notificationType, options]) => ({
        label: getChannelTypeName(notificationType),
        options,
        id: notificationType,
      })),
      toPairs,
      groupBy(prop("type")),
    )(channelOptions)

  const rowRenderer = ({ type, name }) => {
    const channelIcon = getChannelIcon(type)
    return (
      <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {channelIcon && <StyledIcon>{channelIcon}</StyledIcon>}
        {name}
      </Box>
    )
  }

  return (
    <SelectFormGroup
      {...{
        useSelectStyling,
        value: filter(compose(contains(channels), prop("id")), channelOptions),
        options: getCategorizedChannelOptions(),
        label,
        isMulti: true,
        loading,
        onChange: values => onChange("channels", map(prop("id"), values)),
        isHorizontal,
        searchableDropDownAttributes: {
          rowRenderer,
          keepDropdownInView: true,
          disabled,
        },
      }}
    />
  )
}

export default ChannelSelect
