import { project, compose, whereEq, reject, uniq } from "ramda"
import { fetchJson, ninjaReportError, showErrorMessage, localized } from "js/includes/common/utils"

export const setFavoriteTabs = (tabs = []) => ({
  type: "SET_FAVORITE_TABS",
  tabs,
})

const requestSaveFavoriteTabs = favoriteTabs => {
  const favoritesToSave = compose(uniq, project(["id", "type"]))(favoriteTabs)

  return fetchJson("/appuser/tabs/favorites", {
    options: {
      method: "PUT",
      body: JSON.stringify(favoritesToSave),
    },
  })
}

export const addFavoriteTab = tab => async (dispatch, getState) => {
  const favorites = getState().general.favoriteTabs
  const newFavorite = { type: tab.type, ...tab.item }
  const newFavorites = [newFavorite, ...favorites]

  try {
    await requestSaveFavoriteTabs(newFavorites)
    dispatch({
      type: "SET_FAVORITE_TABS",
      tabs: newFavorites,
    })
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}

export const removeFavoriteTab = tab => async (dispatch, getState) => {
  try {
    const { type, id } = tab
    const favorites = getState().general.favoriteTabs
    const newFavorites = reject(whereEq({ type, id }), favorites)

    await requestSaveFavoriteTabs(newFavorites)
    dispatch({
      type: "SET_FAVORITE_TABS",
      tabs: newFavorites,
    })
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}

export const removeFavoriteBulkTabs = tabs => async (dispatch, getState) => {
  try {
    const favorites = getState().general.favoriteTabs
    const newFavorites = favorites.filter(tab => {
      return !tabs.find(favTab => {
        return favTab.id === tab.id && favTab.type === tab.type
      })
    })
    await requestSaveFavoriteTabs(newFavorites)
    dispatch({
      type: "SET_FAVORITE_TABS",
      tabs: newFavorites,
    })
  } catch (error) {
    showErrorMessage(localized("general.error"))
    ninjaReportError(error)
  }
}

export const removeAllFavoriteTabs = () => async dispatch => {
  try {
    await requestSaveFavoriteTabs([])
    dispatch({
      type: "SET_FAVORITE_TABS",
      tabs: [],
    })
  } catch (error) {
    showErrorMessage(localized("Error"))
    ninjaReportError(error)
  }
}

export const updateFavoriteTab = tab => dispatch => {
  dispatch({
    type: "UPDATE_FAVORITE_TAB",
    tab: {
      id: tab.id,
      type: tab.type,
      payload: {
        name: tab.name,
      },
    },
  })
}
