import qs from "qs"
import CommonHashTable from "./CommonHashTable"
import { fetchJson } from "js/includes/common/utils"
import { CustomerInfoModel } from "js/infrastructure/backbone/InfoModels"

export default class OrganizationHashTable extends CommonHashTable {
  get(id) {
    const _id = parseInt(id, 10)
    const organization = CustomerInfoModel.findOrCreate(super.get(_id))
    return organization
  }

  fetch(ids) {
    return ids ? this.fetchByIds(ids) : this.fetchAll()
  }

  fetchAll() {
    console.warn("Fetching all organizations.")
    return new Promise(async (resolve, reject) => {
      try {
        const organizations = await fetchJson(`/client/list`)
        organizations.forEach(o => {
          const currentOrganization = this.getRaw(o.id) || {}
          this.set(o.id, Object.assign(currentOrganization, o))
        })

        resolve(organizations)
      } catch (error) {
        reject(error)
      }
    })
  }

  fetchByIds(ids) {
    if (!ids) {
      throw new Error("ids must be specified")
    }

    return new Promise(async (resolve, reject) => {
      try {
        const queryString = qs.stringify({ id: ids }, { arrayFormat: "repeat" })
        const organizations = await fetchJson(`/client?${queryString}`)
        organizations.forEach(o => {
          const currentOrganization = this.getRaw(o.id) || {}
          this.set(o.id, Object.assign(currentOrganization, o))
        })

        resolve(organizations)
      } catch (error) {
        reject(error)
      }
    })
  }
}
